/* eslint-disable no-console */
import { onError } from '@apollo/client/link/error'
import _ from 'lodash'

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  console.log(
    `An error occurred in the following query: \n`,
    _.get(operation, 'query.loc.source.body')
  )

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      return console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    })
  }

  if (networkError) console.log(`[Network error]: ${networkError}`)
})

export default errorLink
