import { initializeApp, FirebaseApp } from 'firebase/app'
import {
  getAnalytics,
  logEvent as firebaseLogEvent,
  setUserProperties,
  Analytics,
} from 'firebase/analytics'
import _ from 'lodash'

import type { FirebaseAnalyticsConfig } from 'types/common'
import type { User } from 'types/user'

import { isDevEnvironment } from 'helpers/utils'
import { ANALYTICS_EVENTS } from 'constants/analytics'

let app: FirebaseApp | null = null
let analytics: Analytics | null = null

const isDevEnv = isDevEnvironment()

/** Call this function to initiate analytics once config.json is fetched */
export const initializeAnalytics = (
  firebaseConfig: FirebaseAnalyticsConfig
): void => {
  app = initializeApp(firebaseConfig)
  analytics = getAnalytics(app)
}

/** Should be called once logged user data is received or changed */
const setUserPropertiesForAnalytics = (
  user: User,
  environment: string
): void => {
  // Omit analytics on dev mode
  if (isDevEnv || !analytics) return

  const userInfo = _.pick(user, [
    'id',
    'group',
    'email',
    'identityId',
    'name',
    'phone',
    'role',
    'timezone',
    'username',
  ])
  const metaData = {
    ...userInfo,
    environment,
  }

  setUserProperties(analytics, metaData)
}

/** Logs custom event to google analytics */
const logEvent = (
  eventName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eventParams?: Record<string, any>
): void => {
  // Omit analytics on dev mode
  if (isDevEnv || !analytics) return

  firebaseLogEvent(analytics, eventName, eventParams)
}

export { logEvent, setUserPropertiesForAnalytics, ANALYTICS_EVENTS }
