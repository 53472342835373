import { ReactElement, useMemo } from 'react'
import _ from 'lodash'
import { map as awaityMap } from 'awaity/esm'
import { useAsync } from 'react-use'

// constants
import { WIDGET_TYPES } from 'constants/widget'

// components
import { Loading, ImageGallery } from 'components/common'
import { NoAvailableWidget } from 'components/widget'
import IssueVideoList from 'components/issue/common/IssueVideoList'

import type { Images } from 'components/common/ImageGallery'
import type { DataCollectionFormMediaView } from 'types/issue'
import { Asset, AssetRelationshipMany } from 'types/asset'

import type { DisplayImagesGalleryProp } from './DisplayImagesGallery'
import {
  NO_IMAGES_CONTENT,
  getAssetImageItems,
  getAssetVideoItems,
} from './utils'
import { useMediaCount } from './hooks'

import scss from './index.module.scss'

type DisplayImagesFromRelatedAssetsProp = Omit<
  DisplayImagesGalleryProp,
  'properties' | 'propertiesMetadata'
> & {
  value?: Asset & { related: AssetRelationshipMany }
}

const DisplayImagesFromRelatedAssets = ({
  value,
  visibleProperties,
  titleProperty,
  subtitleProperty,
  type,
  mediaType,
  onWidgetLoad,
  setImageCount,
}: DisplayImagesFromRelatedAssetsProp): ReactElement => {
  const { assets } = value?.related || {}
  const { properties: propertiesMetadata = [] } = value?.assetProfile || {}

  const { value: imageItems = [], loading } = useAsync(async () => {
    const response = await awaityMap(assets, (asset: Asset) =>
      getAssetImageItems({
        ...asset,
        propertiesMetadata,
        visibleProperties,
        titleProperty,
        subtitleProperty,
      })
    )
    return _.flatten(response)
  }, [assets, propertiesMetadata, visibleProperties])

  const videoItems = useMemo(
    () =>
      _.flatten(
        assets?.map(asset =>
          getAssetVideoItems({
            ...asset,
            propertiesMetadata,
            visibleProperties,
          })
        )
      ),
    [assets, propertiesMetadata, visibleProperties]
  )

  const { overallMediaCount } = useMediaCount({
    setMediaCount: setImageCount,
    videoItems,
    imageItems: imageItems as DataCollectionFormMediaView[],
  })

  if (loading) return <Loading />

  return overallMediaCount === 0 ? (
    <div className={scss.center}>
      <NoAvailableWidget
        widgetType={WIDGET_TYPES.image}
        content={NO_IMAGES_CONTENT}
      />
    </div>
  ) : (
    <>
      {imageItems?.length > 0 && (
        <ImageGallery
          images={imageItems as Images}
          type={type}
          mediaType={mediaType}
          containerClassName='w-100'
          onLoad={onWidgetLoad}
        />
      )}

      {videoItems && videoItems.length > 0 && (
        <IssueVideoList
          videoResources={videoItems}
          fieldValue={videoItems}
          containerClassName='pe-2'
          onLoad={onWidgetLoad}
        />
      )}
    </>
  )
}

export default DisplayImagesFromRelatedAssets
