// libraries
import { ReactElement } from 'react'

// components
import { Button } from 'components/common'

import scss from '../index.module.scss'

const SubmitButton = ({
  disabled,
  submitting,
  submittingMessage,
}: {
  disabled: boolean
  submitting: boolean
  submittingMessage: string
}): ReactElement => {
  return (
    <div className='d-flex align-items-center'>
      <div className={scss.submit}>
        <Button
          type='submit'
          disabled={disabled || submitting}
          isLoading={submitting}
          block
          className='extraSmallText'
        >
          RUN
        </Button>
      </div>

      {submitting && (
        <div className='text-secondary smallText ms-2'>
          {submittingMessage} ...
        </div>
      )}
    </div>
  )
}

export default SubmitButton
