// constants
import { MFA_TYPES } from 'constants/common'
import {
  INCORRECT_TOTP_ERROR_MESSAGE_WITH_SCAN,
  INCORRECT_SMS_ERROR_MESSAGE,
} from 'constants/message'

// utils
import { switchcase } from 'helpers/utils'
import { hasNoMfa } from 'helpers/user'

import type { Payload } from 'types/common'
import type { MFATypes } from 'types/user'

export interface MFAValues {
  code: string
  phone?: string
}

export const validateMfa = (
  values: MFAValues,
  preferredMFA?: MFATypes
): Payload => {
  const errors = {} as { code?: string }
  if (!values.code) {
    errors.code =
      preferredMFA === MFA_TYPES.SOFTWARE_TOKEN_MFA
        ? INCORRECT_TOTP_ERROR_MESSAGE_WITH_SCAN
        : INCORRECT_SMS_ERROR_MESSAGE
  }
  return errors
}

export const getMFAWording = (mfaType: string): string => {
  return switchcase({
    [MFA_TYPES.SOFTWARE_TOKEN_MFA]:
      'Next time you sign in, enter the code when prompted from your security app.',
    [MFA_TYPES.SMS_MFA]:
      'Next time you sign in, we will automatically send you a text message with the security code. ',
  })('')(mfaType)
}

export const getSelectedMFA = (preferredMFA: MFATypes): MFATypes =>
  hasNoMfa(preferredMFA) ? MFA_TYPES.SOFTWARE_TOKEN_MFA : preferredMFA
