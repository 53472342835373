import { ReactElement, useCallback } from 'react'
import _ from 'lodash'

import {
  ButtonProps,
  BUTTON_ICON_POSITIONS,
  BUTTON_VARIANTS,
  ButtonVariant,
} from 'components/common/Button'

import scss from './index.module.scss'
import { StyledButton } from './style'

export type ButtonValue = string | number | boolean
export type SelectedButtonGroupValue = ButtonValue | ButtonValue[]
export type ButtonGroupOnChange = (
  selectedValue: SelectedButtonGroupValue
) => void

export type ButtonOption = Pick<
  ButtonProps,
  'className' | 'id' | 'testId' | 'icon' | 'iconSize' | 'disabled' | 'children'
> & {
  value: ButtonValue
}

export type ButtonGroupProps = {
  className?: string
  selectedValue?: SelectedButtonGroupValue
  buttonOptions: ButtonOption[]
  size?: ButtonProps['size']
  showCheckmark?: boolean
  isDisabled?: boolean
  onChange: ButtonGroupOnChange
  variant?: ButtonVariant
}

export const checkIsValueSelected = (
  value: ButtonValue,
  selectedValue: ButtonGroupProps['selectedValue']
): boolean =>
  _.isArray(selectedValue)
    ? selectedValue.includes(value)
    : value === selectedValue

/**
 * @param props.className - additional 'className' that will be applied to the container <div />
 * @param props.selectedValue - array or a single value. If array, the ButtonGroup will behave as 'multi-select'
 * @param props.buttonOptions - array of props for buttons
 * @param props.size - will be applied to all Buttons inside the group
 * @param props.showCheckmark - checkmark will be displayed on the left of the selected button
 * @param props.isDisabled - will be applied to all Buttons inside the group
 * @param props.onChange - callback which accepts single or array value (depending on the initial value)
 */
const ButtonGroup = ({
  className = '',
  selectedValue,
  buttonOptions,
  size,
  showCheckmark,
  isDisabled,
  onChange,
  variant,
}: ButtonGroupProps): ReactElement => {
  const onButtonClick = useCallback(
    (value: ButtonValue) => {
      // If there is a 'checkbox' behavior
      if (_.isArray(selectedValue)) {
        const values = selectedValue.includes(value)
          ? _.without(selectedValue, value)
          : [...selectedValue, value]

        return onChange(values)
      }

      return onChange(value)
    },
    [selectedValue, onChange]
  )

  return (
    <div className={`${scss.buttonGroupContainer} ${className}`}>
      {_.map(buttonOptions, ({ value, disabled, children, ...buttonProps }) => {
        const isSelected = checkIsValueSelected(value, selectedValue)

        return (
          <StyledButton
            {...buttonProps}
            key={value}
            size={size}
            disabled={isDisabled || disabled}
            variant={
              isSelected ? BUTTON_VARIANTS.primary : BUTTON_VARIANTS.secondary
            }
            onClick={() => onButtonClick(value)}
            iconPosition={BUTTON_ICON_POSITIONS.left}
            data-selected={isSelected}
            {...(showCheckmark && { showCheckmark: isSelected })}
            {...(variant === BUTTON_VARIANTS.link && {
              variant,
              className: `${scss.linkButton} ${
                isSelected ? '' : scss.secondaryLinkButtonColour
              }`,
            })}
          >
            {children}
          </StyledButton>
        )
      })}
    </div>
  )
}

export default ButtonGroup
