// libraries
import { useCallback } from 'react'
import { DEFAULT_ISSUE_MAP_LAYER_SETTINGS } from 'constants/issue'

import type { Payload } from 'types/common'
import type { Map } from 'types/map'

const useMapIssueState = ({
  mapId,
  setMap,
  issueSettings = DEFAULT_ISSUE_MAP_LAYER_SETTINGS,
  partialUpdateRemoteMapSettings,
}: {
  mapId: string
  setMap: React.Dispatch<React.SetStateAction<Partial<Map>>>
  issueSettings?: Payload
  partialUpdateRemoteMapSettings: ({
    id,
    payload,
  }: {
    id: string
    payload: Partial<Map>
  }) => Promise<void>
}): {
  updateIssueMapLayerSettings: (issueSettingsPayload: Payload) => void
} => {
  const updateIssueMapLayerSettings = useCallback(
    (issueSettingsPayload: Payload) => {
      const payload = {
        issueSettings: { ...issueSettings, ...issueSettingsPayload },
      }
      setMap(payload)
      partialUpdateRemoteMapSettings({ id: mapId, payload })
    },
    [issueSettings, mapId, partialUpdateRemoteMapSettings, setMap]
  )

  return { updateIssueMapLayerSettings }
}

export default useMapIssueState
