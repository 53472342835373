import { useMemo } from 'react'
import _ from 'lodash'

// constants
import { OPERATORS, FILTER_CONDITIONS } from 'constants/filter'

// utils
import { applyFilters } from 'helpers/filter'

import type { Filters } from 'types/filter'

const propertyPathPrefix = ''

const useProcessList = <T>({
  list = [],
  conditions = {},
  searchPropertyPaths = ['name'],
}: {
  list?: T[]
  conditions?: {
    search?: string
    sortField?: string
    ascOrder?: boolean
    filters?: Filters
  }
  searchPropertyPaths?: string[]
}): T[] => {
  const { search, filters = {} } = conditions

  const nonSearchFilters = useMemo(() => {
    const favoriteValue = filters?.isFavorite
    return favoriteValue
      ? [
          {
            property: 'isFavorite',
            operator: OPERATORS.isTrue,
          },
        ]
      : []
  }, [filters])

  const nonSearchFilteredList = useMemo(() => {
    if (_.isEmpty(list)) return []

    return applyFilters(list, nonSearchFilters, propertyPathPrefix)
  }, [list, nonSearchFilters])

  const searchFilters = useMemo(() => {
    if (_.isNil(search)) return []

    return _.map(searchPropertyPaths, searchPath => ({
      property: searchPath,
      operator: OPERATORS.contains,
      value: search,
    }))
  }, [search, searchPropertyPaths])

  const filteredResults = useMemo(() => {
    const filterCondition = FILTER_CONDITIONS.or
    return applyFilters(
      nonSearchFilteredList,
      searchFilters,
      propertyPathPrefix,
      filterCondition
    )
  }, [nonSearchFilteredList, searchFilters])

  return filteredResults as T[]
}

export default useProcessList
