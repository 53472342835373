// libraries
import { ReactElement, useEffect, useMemo } from 'react'
import { Toaster, useToasterStore } from 'react-hot-toast'

import type { CustomToastOptions } from 'components/common/Toast/type'

// constants
import { NOTIFICATION_CLOSE_TIME } from 'constants/settings'
import { SUCCESS_TYPE, TOAST_LIMIT } from '../constants'

// utils
import { dismissToast } from '../helpers/utils'

const HotToaster = (): ReactElement => {
  const { toasts } = useToasterStore()

  const toastsToDismiss = useMemo(
    () =>
      toasts
        .filter(
          ({ visible, toastCustomType }: CustomToastOptions) =>
            visible && toastCustomType === SUCCESS_TYPE
        )
        .slice(TOAST_LIMIT),
    [toasts]
  )

  useEffect(() => {
    toastsToDismiss.forEach(({ id }) => dismissToast(id))
  }, [toastsToDismiss])

  return (
    <Toaster
      position='top-center'
      toastOptions={{ duration: NOTIFICATION_CLOSE_TIME }}
    />
  )
}

export default HotToaster
