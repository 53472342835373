// libraries
import { ElementType, useCallback } from 'react'
import { useToggle } from 'react-use'

// components
import { CreateEntityModal } from 'components/common'

import type { Payload, ToggleFn } from 'types/common'

type RenderCreateEntityModal = {
  onSubmit: (params: Payload) => Promise<void>
  entity: string
  fields: string[]
  image?: ElementType
}

export type UseCreateEntityProps = {
  showCreateEntityModal: boolean
  toggleCreateEntityModal: ToggleFn
  renderCreateEntityModal: ({
    onSubmit,
    entity,
    fields,
    image,
  }: RenderCreateEntityModal) => JSX.Element
}

const useCreateEntity = (): UseCreateEntityProps => {
  const [showCreateEntityModal, toggleCreateEntityModal] = useToggle(false)

  const renderCreateEntityModal = useCallback(
    ({ onSubmit, entity, fields, image }: RenderCreateEntityModal) => {
      const onFormSubmit = async (values: Payload) => {
        onSubmit(values)
        toggleCreateEntityModal(false)
      }

      return (
        <CreateEntityModal
          isShowing={showCreateEntityModal}
          toggle={toggleCreateEntityModal}
          entity={entity}
          onSubmit={onFormSubmit}
          fields={fields}
          image={image}
        />
      )
    },
    [showCreateEntityModal, toggleCreateEntityModal]
  )

  return {
    showCreateEntityModal,
    toggleCreateEntityModal,
    renderCreateEntityModal,
  }
}

export default useCreateEntity
