import { ReactElement, useCallback } from 'react'

// constants
import {
  TIME_ZONES_OPTIONS_WITH_UTC,
  UTC_TIMEZONE_OPTION,
} from 'constants/datetime'
import { USER_PREFERENCES } from 'constants/settings'

// utils
import { getUserEnableTimezone } from 'helpers/user'

// components
import { MultiSelect } from 'components/common'

import type { User } from 'types/user'

export type UserTimeZoneSettingType = {
  user: User
  onChange: (v: Partial<User>) => void
  className?: string
}

const UserTimeZoneSetting = ({
  user,
  onChange,
  className,
}: UserTimeZoneSettingType): ReactElement => {
  const { timezone, username } = user
  const enableTimezone = getUserEnableTimezone(user)

  const onSubmit = useCallback(
    async (newTimezone: string) => {
      const validUsername = username
      const newPayload = {
        ...(newTimezone !== UTC_TIMEZONE_OPTION.value && {
          timezone: newTimezone,
        }),
        preferences: {
          username: validUsername,
          preference: USER_PREFERENCES.enableTimezone,
          value: newTimezone !== UTC_TIMEZONE_OPTION.value,
        },
        username: validUsername,
      }
      onChange(newPayload as Partial<User>)
    },
    [username, onChange]
  )

  return (
    <MultiSelect
      className={className}
      value={enableTimezone ? timezone : UTC_TIMEZONE_OPTION.value}
      options={TIME_ZONES_OPTIONS_WITH_UTC}
      onChange={onSubmit}
      isMulti={false}
      isClearable={false}
      isDisabled={false}
      useOptionValueOnly
      menuFitContent
      withBorder
    />
  )
}

export default UserTimeZoneSetting
