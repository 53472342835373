// libraries
import React, { useRef, useCallback, useMemo, useEffect } from 'react'
import _ from 'lodash'
import { useGetSet, useMount, useUpdateEffect } from 'react-use'
import { Timeline } from 'vis-timeline/peer'
import { DataSet } from 'vis-data'

// contexts
import { useMapStateValue } from 'contexts'

// constants
import { EXACT_DATE_OPTION_LABEL } from 'components/common/Timeline/HistoricalTimeline/controls/IntervalSetting'

// utils
import {
  getLayersUserSettingDatetimeRange,
  getLayersAvailableDatetimeRange,
} from 'helpers/map'
import {
  isTimeRangeEqual,
  getUtcDateTimeString,
  getDurationAsIntervalUnit,
} from 'helpers/datetime'
import { useMapEditor, useMapLayers } from 'components/map/hooks'
import { useTimezone } from 'hooks'
import {
  snap,
  onMoving,
  getActiveItem,
  selectActiveItem,
  generateBackgroundItem,
  focusBackgroundItem,
  getBackgroundItem,
  updateBackgroundItem,
  updateTimelineOptions,
  getTimelineMomentOption,
  getTimelineDefaultOptions,
} from './utils'

// components
import TimelineControls from './controls/TimelineControls'

// styles
import 'vis-timeline/dist/vis-timeline-graph2d.css'
import './timeline.scss'
import scss from './index.module.scss'

const HistoricalTimeline = () => {
  const {
    map: {
      layers,
      selectedDateTimeRange: currentSelectedDateTimeRange = {},
      timelineIntervalUnit,
    },
    updateMapLayers,
    updateMapConfigs,
  } = useMapStateValue()
  const [getTimelineInstance, setTimelineInstance] = useGetSet()
  const [getTimelineState, setTimelineState] = useGetSet({
    selectedDateTimeRange: currentSelectedDateTimeRange,
    intervalNumber: undefined,
    intervalUnit: timelineIntervalUnit,
    userSettingDataTimeRange: {},
    hasAvailable: false,
  })

  const {
    intervalUnit,
    hasAvailableData,
    userSettingDataTimeRange,
    selectedDateTimeRange,
  } = getTimelineState()

  const { layerSpecParamsHash } = useMapLayers({})

  const updateHisTimelineOptions = useCallback(
    options => {
      const timelineInstance = getTimelineInstance()
      updateTimelineOptions(timelineInstance, options)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getTimelineInstance()]
  )

  const shouldDisplayTimeline = useMemo(
    () => !!(userSettingDataTimeRange?.start && userSettingDataTimeRange?.end),
    [userSettingDataTimeRange]
  )

  const { timezone, timezoneAbbr } = useTimezone()
  const isMapInEditorMode = useMapEditor()

  const timelineThrottleTime = useMemo(
    () => (isMapInEditorMode ? 50 : 0),
    [isMapInEditorMode]
  )

  const updateTimelineState = useCallback(
    payload => {
      setTimelineState({ ...getTimelineState(), ...payload })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setTimelineState, getTimelineState()]
  )

  const timeline = useRef()

  useEffect(() => {
    if (isTimeRangeEqual(selectedDateTimeRange, currentSelectedDateTimeRange))
      return

    const { start, end } = selectedDateTimeRange

    updateMapConfigs({
      selectedDateTimeRange: {
        start: getUtcDateTimeString(start),
        end: getUtcDateTimeString(end),
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateTimeRange])

  const throttledSetRange = _.throttle((item, unit) => {
    const duration = getDurationAsIntervalUnit(item.start, item.end, unit)

    updateTimelineState({
      intervalNumber: duration,
      selectedDateTimeRange: _.pick(item, ['start', 'end']),
    })
  }, timelineThrottleTime)

  const updateTimelineItem = useCallback(
    ({
      start,
      end,
      backgroundDataTimeRange,
      hasData = true,
      shouldFocusSelectedRange = true,
    }) => {
      if (!start || !end) return

      updateTimelineState({ selectedDateTimeRange: { start, end } })
      const timelineInstance = getTimelineInstance()
      const selectedItem = getActiveItem(start, end)
      const backgroundItem = generateBackgroundItem({
        start,
        end,
        timezone,
        backgroundDataTimeRange,
        hasData,
      })

      timelineInstance.setItems([selectedItem, backgroundItem])
      // force the brush selected at the initial stage (always in edit mode)
      selectActiveItem(timelineInstance, shouldFocusSelectedRange)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timezone, updateTimelineState]
  )

  const updateTimeline = useCallback(
    ({
      start,
      end,
      unit,
      dateTimeRange = userSettingDataTimeRange,
      hasData,
      shouldFocusSelectedRange,
    }) => {
      if (!start || !end) return

      const options = {
        ...(shouldFocusSelectedRange ? { start, end } : {}),
        snap: snap(timezone, unit),
        moment: getTimelineMomentOption(timezone),
        onMoving: onMoving(unit, throttledSetRange),
      }
      updateHisTimelineOptions(options)
      updateTimelineItem({
        start,
        end,
        backgroundDataTimeRange: dateTimeRange,
        hasData,
        shouldFocusSelectedRange,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userSettingDataTimeRange, throttledSetRange, timezone, updateTimelineItem]
  )

  useMount(() => {
    const initTimeline = () => {
      const timelineData = new DataSet([])
      const timelineOptions = getTimelineDefaultOptions()
      const newTimeline = new Timeline(
        timeline.current,
        timelineData,
        timelineOptions
      )
      // force the brush selected no matter the user selects
      // or deselects it (always in edit mode)
      newTimeline.on('select', () => {
        selectActiveItem(newTimeline)
      })

      setTimelineInstance(newTimeline)
    }

    initTimeline()
  })

  const shouldUpdateTimeline = useCallback(
    (newAvailableDataTimeRange, hasData) => {
      const hasDataTimeRangeChanged = () => {
        const { startTime, endTime } = newAvailableDataTimeRange
        if (!startTime || !endTime) {
          getTimelineInstance().setItems([])

          updateTimelineState({
            selectedDateTimeRange: {},
            userSettingDataTimeRange: {},
          })
          return false
        }

        if (
          startTime === userSettingDataTimeRange.start &&
          endTime === userSettingDataTimeRange.end
        ) {
          // only refresh timeline component unless the time range
          // of all historical data layers changed
          return false
        }

        return true
      }
      if (hasData !== hasAvailableData) return true

      return hasDataTimeRangeChanged()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userSettingDataTimeRange, updateTimelineState]
  )

  /**
   * get the user specified datetime range
   * update the backgroundDateTimeRange to the user specified datetime range
   * update the selectedDateTimeRange(currentDateTimeRange) to the user specified datetime range
   * reset the interval settings, remove snapping
   * */
  useEffect(() => {
    const onLayerDatetimeRangeChange = () => {
      const userSettingTimeRange = getLayersUserSettingDatetimeRange(layers)
      const availableDataTimeRange = getLayersAvailableDatetimeRange(layers)
      const hasData = !_.isEmpty(availableDataTimeRange)
      if (!shouldUpdateTimeline(userSettingTimeRange, hasData)) return

      const { startTime, endTime } = userSettingTimeRange
      const defaultDateTimeRange = { start: startTime, end: endTime }
      const newSelectedDateTimeRange =
        layerSpecParamsHash || _.isEmpty(selectedDateTimeRange)
          ? defaultDateTimeRange
          : selectedDateTimeRange

      const { start: selectedStart, end: selectedEnd } =
        newSelectedDateTimeRange

      updateTimeline({
        // update the selected timeline item
        start: selectedStart,
        end: selectedEnd,
        unit: intervalUnit,
        // update the whole timeline datetime range (background)
        dateTimeRange: defaultDateTimeRange,
        hasData,
      })

      updateTimelineState({
        ...(layerSpecParamsHash
          ? {
              intervalUnit: undefined,
              intervalNumber: undefined,
            }
          : {
              intervalUnit: timelineIntervalUnit,
              intervalNumber: getDurationAsIntervalUnit(
                selectedStart,
                selectedEnd,
                intervalUnit
              ),
            }),
        selectedDateTimeRange: newSelectedDateTimeRange,
        userSettingDataTimeRange: defaultDateTimeRange,
        hasAvailableData: hasData,
      })

      if (layerSpecParamsHash && timelineIntervalUnit) {
        updateMapConfigs({
          timelineIntervalUnit: EXACT_DATE_OPTION_LABEL,
        })
      }
      focusBackgroundItem(getTimelineInstance())
    }

    onLayerDatetimeRangeChange()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layerSpecParamsHash])

  useUpdateEffect(() => {
    updateHisTimelineOptions({
      snap: snap(timezone, intervalUnit),
      moment: getTimelineMomentOption(timezone),
    })

    const timelineInstance = getTimelineInstance()
    const backgroundItem = getBackgroundItem(timelineInstance)
    if (!backgroundItem) return

    const updatedBackgroundItem = generateBackgroundItem({
      ...backgroundItem,
      timezone,
    })
    updateBackgroundItem(timelineInstance, updatedBackgroundItem)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezone])

  useUpdateEffect(() => {
    updateHisTimelineOptions({
      snap: snap(timezone, intervalUnit),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intervalUnit])

  return (
    <div className={scss.container}>
      {getTimelineInstance() && (
        <TimelineControls
          shouldDisplayTimeline={shouldDisplayTimeline}
          timelineState={getTimelineState()}
          timezone={timezone}
          timezoneAbbr={timezoneAbbr}
          updateTimelineState={updateTimelineState}
          updateTimeline={updateTimeline}
          updateTimelineItem={updateTimelineItem}
          layers={layers}
          updateMapLayers={updateMapLayers}
          updateMapConfigs={updateMapConfigs}
          instance={getTimelineInstance()}
        />
      )}
      <div id='globalTimeFilterTimeline'>
        <div ref={timeline} />
      </div>
    </div>
  )
}

export default HistoricalTimeline
