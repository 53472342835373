import { CSSProperties, ReactElement } from 'react'
import { StyledField } from 'components/common/Form'
import VerificationInput from 'react-verification-input'
import styled from '@emotion/styled'

const Styled = styled.div`
  .vi__wrapper {
    width: 100%;
  }
  .container {
    height: 38px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
  }

  .character {
    line-height: 38px;
    font-size: 22px;
    color: #000;
    border: 1px solid #c6c6c6;
    background-color: transparent;
    border-radius: 3px;
  }

  .character:first-of-type {
    margin-left: 0;
  }

  .character--inactive {
    background-color: transparent;
  }

  .character--selected {
    border: 1px solid ${props => props.theme.primary};
  }
`

const CodeInput = ({
  input,
  label,
  required = false,
  tooltip,
  groupOptionStyle,
  length = 6,
  disabled,
  handleSubmit,
  ...rest
}: {
  input: {
    onChange: (v: string) => void
    name: string
    value: string
  }
  label?: string
  required?: boolean
  tooltip?: string
  groupOptionStyle?: CSSProperties
  labelClassName?: string
  length: number
  disabled?: boolean
  handleSubmit?: (v: string) => void
}): ReactElement => {
  const { onChange, name, value } = input

  return (
    <StyledField
      name={name}
      tooltip={tooltip}
      groupOptionStyle={groupOptionStyle}
      label={label}
      required={required}
      {...rest}
    >
      <Styled>
        <VerificationInput
          value={value}
          length={length}
          onChange={v => {
            onChange(v)
          }}
          {...(handleSubmit && {
            onComplete: v => {
              handleSubmit(v)
            },
          })}
          validChars='0-9'
          placeholder=''
          classNames={{
            container: 'container',
            character: 'character',
            characterInactive: 'character--inactive',
            characterSelected: 'character--selected',
          }}
          autoFocus
        />
      </Styled>
    </StyledField>
  )
}

export default CodeInput
