// libraries
import _ from 'lodash'
import { useState, useCallback, useMemo } from 'react'
import { useUpdateEffect } from 'react-use'

// constants
import { LAYER_TYPES, BASE_LAYER_TYPES } from 'constants/map'

// utils
import { useStateValue, useMapStateValue } from 'contexts'
import {
  isLayerVisible,
  getLayerDatetimeRange,
  getValidSelectedDateTimeRange,
  getLayerProfileTitle,
} from 'helpers/layer'
import { getDeckLayerClass } from 'components/map/layers/deckLayers'
import { getObjectHash, switchcase } from 'helpers/utils'

import type { Payload } from 'types/common'
import type { Timezone } from 'types/datetime'
import type { MapLayer, MapLayerData, MapLayerProfileHandler } from 'types/map'
import type Layer from 'components/map/layers/deckLayers/baseLayer'

const generateTileLayer = ({
  mapLayer,
  timezone,
  currentZoom,
}: {
  mapLayer: MapLayer
  timezone?: Timezone
  currentZoom: number
}) => {
  const newLayer = getDeckLayerClass({ mapLayer, currentZoom })
  return newLayer.renderLayer({ timezone })
}

/**
 * Assign layer's properties to different groups
 * One property can belong to multiple groups
 */
const LAYERS_CONFIGS_TYPE_PROPERTIES_MAPPING = {
  layerPolygonsHash: [`style.${LAYER_TYPES.polygon}.polygon.assetPolygonId`],
  layerStylingsHash: ['type', 'style', 'isVisible'],
  layerSpecParamsHash: [
    'dataset',
    'specParams',
    'isTimeRangeRelative',
    'relativeTime',
    'dateTimeRange',
  ],
  layerAssetProfilesHash: ['profile.assetProfileId'],
  layerFiltersHash: ['filters', 'filterCondition', 'selectedProperties'],
  layerProfileHash: ['profile'],
  layerZoomVisibleHash: ['minZoom', 'maxZoom'],
}

const useMapLayers = ({
  profileHandler,
  timezone,
  currentZoom,
}: {
  profileHandler: MapLayerProfileHandler
  timezone?: Timezone
  currentZoom: number
}): {
  layersList: Layer[]
  updateOverlays: () => void
  layerVisibleHash: string
  layerPolygonsHash: string
  layerStylingsHash: string
  layerSpecParamsHash: string
  layerAssetProfilesHash: string
  layerFiltersHash: string
  layerProfileHash: string
  layerZoomVisibleHash: string
} => {
  const {
    map: { selectedDateTimeRange },
    layersWithExternalConfig: layers,
    getHighlightedObjectIndexFromSelectedSearchFeature,
    getHighlightedAssetIndexFromSelectedSearchFeature,
    getLayerDataById,
  } = useMapStateValue()

  const {
    selectors: {
      unipipeSelectors: { pickedDatasetsMetadata },
    },
  } = useStateValue()

  const [layersList, setLayersList] = useState([])
  const [layersConfigsHashes, setLayersConfigsHashes] = useState({})

  /**
   * Get deck.gl layer specification based on layer configsHash
   * and other necessary data and functions
   */
  const getDeckLayerSpecification = useCallback(
    ({
      layerData,
      useDeckglTimeFilter,
      mapLayer,
      interaction,
      highlightedObjectIndex,
    }: {
      layerData: MapLayerData
      useDeckglTimeFilter?: boolean
      mapLayer: MapLayer
      interaction: MapLayerProfileHandler
      highlightedObjectIndex?: number | null
    }) => {
      const validSelectedDateTimeRange = useDeckglTimeFilter
        ? getValidSelectedDateTimeRange({
            selectedDateTimeRange,
            dateTimeRange: getLayerDatetimeRange(mapLayer),
          })
        : null

      const { identityProperty } =
        _.get(pickedDatasetsMetadata, mapLayer?.dataset) || {}

      const profileTitle = getLayerProfileTitle(mapLayer)

      const newLayer = getDeckLayerClass({
        mapLayer,
        currentZoom,
        identityProperty,
        profileTitle,
      })
      return newLayer.renderLayer({
        layerData,
        highlightedObjectIndex,
        profileHandler: interaction,
        selectedDateTimeRange: validSelectedDateTimeRange,
        timezone,
        currentZoom,
      })
    },
    [selectedDateTimeRange, pickedDatasetsMetadata, currentZoom, timezone]
  )

  const generateAssetLayer = useCallback(
    ({
      mapLayer,
      interaction,
    }: {
      mapLayer: MapLayer
      interaction: MapLayerProfileHandler
    }) => {
      const {
        id,
        profile: { assetProfileId },
      } = mapLayer
      if (!assetProfileId) return undefined

      const { mapLayerData, filteredData } = getLayerDataById(id)
      const layerData = mapLayerData || filteredData
      if (!layerData) return undefined

      const highlightedObjectIndex =
        getHighlightedAssetIndexFromSelectedSearchFeature({
          layerData,
        })

      return getDeckLayerSpecification({
        layerData,
        mapLayer,
        interaction,
        useDeckglTimeFilter: false,
        highlightedObjectIndex,
      })
    },
    [
      getDeckLayerSpecification,
      getHighlightedAssetIndexFromSelectedSearchFeature,
      getLayerDataById,
    ]
  )

  const generateDeckLayer = useCallback(
    ({
      mapLayer,
      interaction,
    }: {
      mapLayer: MapLayer
      interaction: MapLayerProfileHandler
    }) => {
      const { id } = mapLayer
      const {
        mapLayerData,
        filteredData,
        useDeckglTimeFilter = false,
      } = getLayerDataById(id)
      const layerData = mapLayerData || filteredData
      if (!layerData) return undefined

      // for icon layer: will be used on background icon
      const highlightedObjectIndex =
        getHighlightedObjectIndexFromSelectedSearchFeature({
          layerData,
          layerId: id,
        })

      return getDeckLayerSpecification({
        layerData,
        useDeckglTimeFilter,
        mapLayer,
        interaction,
        highlightedObjectIndex,
      })
    },
    [
      getDeckLayerSpecification,
      getHighlightedObjectIndexFromSelectedSearchFeature,
      getLayerDataById,
    ]
  )

  const generateDeckLayers = useCallback(
    (mapLayers: MapLayer[], interaction: MapLayerProfileHandler) =>
      _(mapLayers)
        .map(mapLayer => {
          const generateLayerFn = switchcase({
            [LAYER_TYPES.tile]: generateTileLayer,
            [LAYER_TYPES.tile3D]: generateTileLayer,
            [BASE_LAYER_TYPES.asset]: generateAssetLayer,
          })(generateDeckLayer)(mapLayer.baseType || mapLayer.type)
          return generateLayerFn({
            mapLayer,
            timezone,
            interaction,
            currentZoom,
          })
        })
        .compact()
        .value(),
    [currentZoom, generateAssetLayer, generateDeckLayer, timezone]
  )

  const updateOverlays = useCallback(() => {
    let deckLayers = []
    if (!_.isEmpty(layers)) {
      // last layer render first
      const reversedLayers = layers.slice().reverse()
      deckLayers = generateDeckLayers(reversedLayers, profileHandler)
    }

    setLayersList(deckLayers)
  }, [layers, profileHandler, generateDeckLayers])

  /**
   * Detect what type of layer properties has changed, and then trigger corresponding handling functions
   */
  useUpdateEffect(() => {
    const getLayerConfigs =
      (properties: string[]) =>
      (layer: MapLayer): Payload | undefined => {
        return _.reduce(
          properties,
          (config: Payload | undefined, propertyPath: string) => {
            const value = _.get(layer, propertyPath)
            return _.isNil(value) || _.isEmpty(value)
              ? config
              : { ...(config || {}), [propertyPath]: value }
          },
          undefined
        )
      }

    const newLayerConfigsHashes = _.reduce(
      LAYERS_CONFIGS_TYPE_PROPERTIES_MAPPING,
      (acc, properties, key) => {
        const newConfigs = _(layers)
          .sortBy('id')
          .map(getLayerConfigs(properties))
          .compact()
          .uniqWith(_.isEqual)
          .value()
        return { ...acc, [key]: getObjectHash(newConfigs) }
      },
      {}
    )
    setLayersConfigsHashes(newLayerConfigsHashes)
  }, [layers])

  const layerVisibleHash = useMemo(() => {
    const layerVisible = _.flatMap(layers, isLayerVisible(currentZoom))

    return getObjectHash(layerVisible)
  }, [layers, currentZoom])

  return {
    ...layersConfigsHashes,
    layersList,
    updateOverlays,
    layerVisibleHash,
  }
}

export default useMapLayers
