import _ from 'lodash'
import {
  SITE_INITIAL_LIST,
  SITE_SET_LIST,
  SITE_SET_CATEGORY,
  SITE_SET_USER_PROPERTIES_KEYS,
  SITE_SET_FILTERED_LIST,
} from 'contexts/actions/site'

import { switchcaseF } from 'helpers/utils'

export const siteState = {
  loading: false,
  sitesList: [],
  filteredSiteList: [],
  category: {},
  userPropertiesKeys: {},
}

const siteReducer = (state, action) => {
  return switchcaseF({
    [SITE_INITIAL_LIST]: () => ({
      ...state,
      loading: true,
    }),
    [SITE_SET_LIST]: () => ({
      ...state,
      loading: false,
      sitesList: action.sitesList,
    }),
    [SITE_SET_FILTERED_LIST]: () => ({
      ...state,
      loading: false,
      filteredSiteList: action.filteredSiteList,
    }),
    [SITE_SET_CATEGORY]: () => {
      const category = _.merge({}, state.category, action.category)
      return {
        ...state,
        category,
      }
    },
    [SITE_SET_USER_PROPERTIES_KEYS]: () => {
      const userPropertiesKeys = _.merge(
        {},
        state.userPropertiesKeys,
        action.userPropertiesKeys
      )
      return {
        ...state,
        userPropertiesKeys,
      }
    },
  })(() => state)(action.type)
}

export default siteReducer
