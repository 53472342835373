// libraries
import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useGetSet, useMountedState } from 'react-use'
import isEqual from 'fast-deep-equal'

// utils
import { switchcase, switchcaseF } from 'helpers/utils'
import { isSelectedWidgetActive } from 'helpers/widget'
import { useMapStateValue } from 'contexts'

// constants
import {
  WIDGET_TYPES,
  DEFAULT_CHART_REFRESH_RATE_MS,
  NONE_WIDGET_DATA_AFFECTING_PROPS,
  NOT_MERGE_WIDGET_TYPES,
  DOWNLOADABLE_WIDGETS_TYPES,
} from 'constants/widget'
import { WIDGET_RENDER_DEBOUNCE_DELAY } from 'constants/common'

// components
import { IconButton } from 'components/common'
import NoAvailableWidget from './common/NoAvailableWidget'
import WidgetBase from './common/WidgetBase'
import renderWidgetLine from './common/widgetRenderHelper/line'
import renderWidgetBar from './common/widgetRenderHelper/bar'
import renderWidgetNumeric from './common/widgetRenderHelper/numeric'
import renderWidgetGauge from './common/widgetRenderHelper/gauge'
import renderWidgetScatter from './common/widgetRenderHelper/scatter'
import GaugeWidget from './GaugeWidget'
import LineWidget from './LineWidget'
import BarWidget from './BarWidget'
import ScatterWidget from './ScatterWidget'
import NumericWidget from './NumericWidget'

export {
  BarWidget,
  WidgetBase,
  GaugeWidget,
  LineWidget,
  ScatterWidget,
  NumericWidget,
  NoAvailableWidget,
}

const Widget = props => {
  const {
    id,
    type,
    className,
    title,
    data,
    settings,
    nonLiveDataset,
    timezone,
    propertyOptions,
    visibleProperties,
    selectedDateTimeRange,
    identityProperty,
  } = props
  const isMounted = useMountedState()
  const { maxWidgetSelected, setMaxWidgetSelected } = useMapStateValue()

  const [layerWidgetData, setLayerWidgetData] = useState([])

  const [getPreviousData, setPreviousData] = useGetSet([])
  /**
   * The direct props needed to render the widget, which will be passed to the EChart
   */
  const [getWidgetProps, setWidgetProps] = useGetSet()

  /**
   * The internal widget state which helps generate the widget props
   */
  const [widgetState, setWidgetState] = useState({
    widgetType: type,
    widgetSettings: settings,
  })

  const { widgetType, widgetSettings } = widgetState

  const updateWidgetState = useCallback(payload => {
    setWidgetState(oldWidgetState => ({ ...oldWidgetState, ...payload }))
  }, [])

  const updateWidgetProps = useCallback(
    (value, calculateWidgetData, payload) => {
      const widgetProps = getWidgetProps()
      const newWidgetProps = value
        ? _.isEmpty(widgetProps) || calculateWidgetData
          ? { ...payload, ...value, id, title }
          : { ...widgetProps, ...payload, title }
        : null

      if (!isMounted()) return
      setWidgetProps(newWidgetProps)
    },
    [getWidgetProps, id, isMounted, setWidgetProps, title]
  )

  const isWidgetDataEmpty = useMemo(
    () => _.isEmpty(layerWidgetData),
    [layerWidgetData]
  )

  useEffect(
    () => {
      const debouncedUpdateWidgetData = _.debounce(() => {
        if (!data) return
        // for numeric and gauge, do not merge data in any case.
        const processNotMerge = _.includes(NOT_MERGE_WIDGET_TYPES, type)
          ? true
          : nonLiveDataset
        const shouldNotMerge = processNotMerge || isWidgetDataEmpty
        const diffData = shouldNotMerge
          ? []
          : _.differenceWith(data, getPreviousData(), isEqual)
        if (!shouldNotMerge && _.isEmpty(diffData)) return

        setPreviousData(data)
        const newData = shouldNotMerge
          ? data
          : [...layerWidgetData, ...diffData]
        if (!isMounted()) return

        setLayerWidgetData(newData)
      }, DEFAULT_CHART_REFRESH_RATE_MS)

      debouncedUpdateWidgetData()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      data,
      nonLiveDataset,
      isMounted,
      isWidgetDataEmpty,
      layerWidgetData,
      setPreviousData,
    ]
  )

  const renderWidget = _.debounce(({ calculateWidgetData = false }) => {
    if (isWidgetDataEmpty) return

    const widgetSetting = settings[type] || {}
    const sharedProps = {
      widgetData: layerWidgetData,
      widgetSetting,
      calculateWidgetData,
      updateWidgetProps,
      title,
      timezone,
      propertyOptions,
      identityProperty,
    }
    const sharedPropsWithDataMerge = {
      ...sharedProps,
      selectedDateTimeRange,
    }
    switchcaseF({
      [WIDGET_TYPES.numeric]: () => renderWidgetNumeric(sharedProps),
      [WIDGET_TYPES.line]: () => renderWidgetLine(sharedPropsWithDataMerge),
      [WIDGET_TYPES.bar]: () => renderWidgetBar(sharedPropsWithDataMerge),
      [WIDGET_TYPES.gauge]: () => renderWidgetGauge(sharedProps),
      [WIDGET_TYPES.scatter]: () =>
        renderWidgetScatter({ ...sharedPropsWithDataMerge, visibleProperties }),
    })(() => renderWidgetLine(sharedPropsWithDataMerge))(type)
  }, WIDGET_RENDER_DEBOUNCE_DELAY)

  const shouldCalculateWidgetData = useCallback(() => {
    const isWidgetTypeChanged = isEqual(type, widgetType)
    return (
      isWidgetTypeChanged ||
      !isEqual(
        _.omit(settings, NONE_WIDGET_DATA_AFFECTING_PROPS),
        _.omit(widgetSettings, NONE_WIDGET_DATA_AFFECTING_PROPS)
      )
    )
  }, [settings, type, widgetSettings, widgetType])

  const updateWidgetWhenSettingAndTypeChanged = useCallback(() => {
    const calculateWidgetData = shouldCalculateWidgetData(
      settings,
      widgetSettings
    )
    renderWidget({ calculateWidgetData })
  }, [renderWidget, settings, shouldCalculateWidgetData, widgetSettings])

  useEffect(() => {
    renderWidget({ calculateWidgetData: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layerWidgetData, timezone, selectedDateTimeRange])

  useEffect(() => {
    updateWidgetWhenSettingAndTypeChanged()
    updateWidgetState({ widgetSettings: settings, widgetType: type })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings, type, title])

  const Component = useMemo(() => {
    setWidgetProps(null)
    return switchcase({
      [WIDGET_TYPES.numeric]: NumericWidget,
      [WIDGET_TYPES.line]: LineWidget,
      [WIDGET_TYPES.bar]: BarWidget,
      [WIDGET_TYPES.scatter]: ScatterWidget,
      [WIDGET_TYPES.gauge]: GaugeWidget,
    })(LineWidget)(type)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  const widgetProps = getWidgetProps()

  const shouldRenderWidget = useMemo(
    () =>
      !isWidgetDataEmpty &&
      !_.isEmpty(_.omit(widgetProps, ['id', 'title', 'label', 'colour'])),
    [isWidgetDataEmpty, widgetProps]
  )

  const toggleWidgetStatus = useCallback(() => {
    const isSelected = isSelectedWidgetActive(id, maxWidgetSelected)

    const enableImageDownload = _.includes(DOWNLOADABLE_WIDGETS_TYPES, type)

    const selectedWidget = isSelected
      ? null
      : { Component, widgetProps, title, enableImageDownload }

    setMaxWidgetSelected(selectedWidget)
  }, [
    Component,
    id,
    maxWidgetSelected,
    setMaxWidgetSelected,
    title,
    type,
    widgetProps,
  ])

  const expandedWidget = useMemo(() => {
    return (
      shouldRenderWidget && (
        <>
          <IconButton
            icon='MdFullscreen'
            size={20}
            onClick={toggleWidgetStatus}
          />
        </>
      )
    )
  }, [shouldRenderWidget, toggleWidgetStatus])

  useEffect(() => {
    if (isSelectedWidgetActive(id, maxWidgetSelected)) {
      setMaxWidgetSelected({ Component, widgetProps })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetProps])

  return (
    <WidgetBase title={title} className={className} element={expandedWidget}>
      {shouldRenderWidget ? (
        <Component {...widgetProps} size='small' />
      ) : (
        <NoAvailableWidget widgetType={type} />
      )}
    </WidgetBase>
  )
}

Widget.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.shape({})]),
  settings: PropTypes.shape({}),
  propertyOptions: PropTypes.arrayOf(
    PropTypes.shape({ format: PropTypes.string })
  ),
  nonLiveDataset: PropTypes.bool.isRequired,
  timezone: PropTypes.string,
  visibleProperties: PropTypes.arrayOf(PropTypes.shape({})),
  selectedDateTimeRange: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  identityProperty: PropTypes.string,
}

Widget.defaultProps = {
  className: '',
  title: '',
  data: {},
  settings: {},
  propertyOptions: [],
  timezone: undefined,
  visibleProperties: undefined,
  selectedDateTimeRange: undefined,
  identityProperty: 'name',
}

export default Widget
