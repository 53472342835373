// libraries
import { ReactElement, useCallback } from 'react'
import { marked } from 'marked'
import { useAsync } from 'react-use'
import DOMPurify from 'dompurify'
import _ from 'lodash'

// components
import { Loading } from 'components/common'

const MdPreviewer = ({
  url,
  onError,
}: {
  url: string
  onError: (v: Error) => void
}): ReactElement => {
  const state = useAsync(async () => {
    return fetch(url)
      .then(r => r.text())
      .catch(e => {
        onError(e)
        throw e
      })
  }, [url])

  const { loading, error, value } = state

  const createMarkup = useCallback(() => {
    if (!value) return {}

    const rawMarkup = marked.parse(value)
    return { __html: DOMPurify.sanitize(rawMarkup) }
  }, [value])

  return (
    <div>
      {loading ? (
        <Loading />
      ) : error ? (
        <div>Error: {error.message}</div>
      ) : (
        // eslint-disable-next-line react/no-danger
        <div dangerouslySetInnerHTML={createMarkup()} />
      )}
    </div>
  )
}

MdPreviewer.defaultProps = {
  onError: _.noop,
}

export default MdPreviewer
