import { ReactElement, FocusEvent, useMemo } from 'react'
import { WidgetProps, asNumber, guessType } from '@rjsf/utils'
import _ from 'lodash'

import WidgetWrapper from 'components/common/JsonForm/WidgetWrapper'

// utils
import { getValueWhenSwitchingBetweenSingleSelectAndMultiSelect } from 'helpers/formBuilder'

// types
import type {
  MultiSelectSchemaAndUiSchema,
  SingleSelectSchemaAndUiSchema,
} from 'types/formBuilder'
import type { Options } from 'types/common'

// components
import MultiSelect from 'components/common/MultiSelect'

const nums = new Set(['number', 'integer'])

/**
 * This is a silly limitation in the DOM where option change event values are
 * always retrieved as strings.
 */
const processValue = (
  schema: Pick<MultiSelectSchemaAndUiSchema, 'schema'> &
    Pick<SingleSelectSchemaAndUiSchema, 'schema'>,
  value: string
) => {
  const { type, items, enum: schemaEnum } = schema

  if (value === '') {
    return undefined
  }

  if (type === 'array' && !!items) {
    if (nums.has(items.type)) {
      return _.map(value, asNumber)
    }
    return _.map(value)
  }

  if (type === 'boolean') {
    return value === 'true'
  }

  if (type === 'number') {
    return asNumber(value)
  }

  // If type is undefined, but an enum is present, try and infer the type from
  // the enum values
  if (schemaEnum) {
    if (schemaEnum.every((x: string | number) => guessType(x) === 'number')) {
      return asNumber(value)
    }
    if (schemaEnum.every((x: string | number) => guessType(x) === 'boolean')) {
      return value === 'true'
    }
  }

  return value
}

const SelectWidget = ({
  className,
  schema,
  name,
  id,
  options,
  label,
  placeholder,
  required,
  disabled,
  readonly,
  value,
  multiple = false,
  rawErrors,
  onChange,
  onBlur,
  onFocus,
  formContext: { isPreview },
}: WidgetProps): ReactElement => {
  const { enumOptions, enumDisabled } = options

  const hasError = rawErrors?.length > 0

  const selectOptions = useMemo(
    () =>
      (enumOptions as Options).map(option => {
        const isDisabled =
          enumDisabled && _.includes(enumDisabled, option.value)
        return { ...option, isDisabled }
      }),
    [enumOptions, enumDisabled]
  )

  const emptyValue = multiple ? [] : ''

  const updatedValue = getValueWhenSwitchingBetweenSingleSelectAndMultiSelect(
    value,
    multiple
  )

  const initialValue = _.isUndefined(updatedValue) ? emptyValue : updatedValue

  const handleChange = (newValue: unknown) =>
    onChange(processValue(schema, newValue))

  const handleBlur = ({
    target: { value: newValue },
  }: FocusEvent<HTMLInputElement>) => onBlur(id, processValue(schema, newValue))

  const handleFocus = ({
    target: { value: newValue },
  }: FocusEvent<HTMLInputElement>) =>
    onFocus(id, processValue(schema, newValue))

  return (
    <WidgetWrapper
      label={label || schema.title}
      description={schema.description}
      required={required}
      name={name}
      rawErrors={rawErrors}
      isLarge={isPreview}
    >
      <MultiSelect
        id={id}
        name={name}
        value={initialValue}
        className={className}
        placeholder={placeholder || schema.placeholder}
        required={required}
        isDisabled={disabled || readonly}
        isMulti={multiple}
        isLarge={isPreview}
        options={selectOptions}
        hasError={hasError}
        useOptionValueOnly
        withBorder
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
    </WidgetWrapper>
  )
}

export default SelectWidget
