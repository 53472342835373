import keymirror from 'keymirror'

/**
 * Default numerical variable aggregation types
 */
export const AGGREGATION_TYPES = keymirror({
  count: null,
  mean: null,
  average: null,
  max: null,
  min: null,
  sum: null,
  median: null,
})

/**
 * Default text variable aggregation types
 */
export const TEXT_AGGREGATION_TYPES = keymirror({
  first: null,
  last: null,
  list: null,
})
/**
 * Default aggregation options
 */
export const DEFAULT_AGGREGATION_OPTIONS = [
  { value: AGGREGATION_TYPES.count, label: AGGREGATION_TYPES.count },
  { value: AGGREGATION_TYPES.sum, label: AGGREGATION_TYPES.sum },
  { value: AGGREGATION_TYPES.mean, label: 'Average' },
  { value: AGGREGATION_TYPES.min, label: AGGREGATION_TYPES.min },
  { value: AGGREGATION_TYPES.max, label: AGGREGATION_TYPES.max },
  { value: AGGREGATION_TYPES.median, label: AGGREGATION_TYPES.median },
]

/**
 * Default text aggregation options
 */
export const DEFAULT_TEXT_AGGREGATION_OPTIONS = [
  { value: TEXT_AGGREGATION_TYPES.first, label: TEXT_AGGREGATION_TYPES.first },
  { value: TEXT_AGGREGATION_TYPES.last, label: TEXT_AGGREGATION_TYPES.last },
  { value: TEXT_AGGREGATION_TYPES.list, label: TEXT_AGGREGATION_TYPES.list },
]

export const DEFAULT_AGGREGATION_KEY_TYPE = {
  key: '',
  type: AGGREGATION_TYPES.count,
}
