// libraries
import _ from 'lodash'

// constants
import { FORM_QUESTION_TYPES } from 'constants/formBuilder'

// utils
import { getFormBuilderWidgetType } from 'helpers/formBuilder'

import type { IconProps, ThemedFormSchemaProps } from './types'

export const getIconProps = ({
  schema,
  uiSchema,
  theme,
}: ThemedFormSchemaProps): IconProps => {
  const widgetType = getFormBuilderWidgetType({ schema, uischema: uiSchema })

  switch (true) {
    case _.get(uiSchema, '[ui:options].smartScan', false):
      return {
        iconName: 'MdOutlineCameraAlt',
        size: 20,
        color: theme.secondary,
      }
    case widgetType === FORM_QUESTION_TYPES.SCANNER:
      return { iconName: 'MdOutlineQrCodeScanner', size: 20 }
    default:
      return {}
  }
}
