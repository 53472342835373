import { ReactElement, ChangeEventHandler, FocusEventHandler } from 'react'
import styled from '@emotion/styled'

const StyledRadio = styled.input`
  width: 24px;
  height: 24px;
  border: 2px solid ${props => props.theme['secondary-500']};
  border-radius: 50%;
  display: inline-block;
  position: relative;
  background-color: white;

  &:checked {
    background-image: none;
    padding: 3px;
    background-clip: content-box;
  }

  &:focus {
    box-shadow: 0 0 0 0.05rem ${props => props.theme['primary-100']};
  }
`
const Radio = ({
  id,
  name,
  label,
  description,
  selected,
  disabled = false,
  reverseLabel,
  className = '',
  containerClassName = '',
  labelClassName = '',
  onChange,
  onBlur,
  onFocus,
}: {
  id?: string
  name?: string
  label?: string
  description?: string
  selected?: boolean
  disabled?: boolean
  reverseLabel?: boolean
  containerClassName?: string
  className?: string
  labelClassName?: string
  descriptionClassName?: string
  onChange: ChangeEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
  onFocus?: FocusEventHandler<HTMLInputElement>
}): ReactElement => {
  return (
    <div
      className={`form-check ${
        reverseLabel ? 'form-check-reverse' : ''
      } ${containerClassName}`}
    >
      {label && (
        <div className='d-inline-block text-start'>
          <label className={`form-check-label ${labelClassName}`} htmlFor={id}>
            {label}
          </label>
          {description && (
            <div className='form-label-description'>{description}</div>
          )}
        </div>
      )}

      <StyledRadio
        className={`form-check-input flex-shrink-0 ${className}`}
        type='radio'
        id={id}
        name={name}
        checked={selected}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </div>
  )
}

export default Radio
