import { ReactElement, useCallback } from 'react'
import { useToggle } from 'react-use'

// types
import type { Option } from 'types/common'
import type { MultiSelectProp } from 'components/common/MultiSelect'

// constants
import { TOOLTIP_PLACEMENT } from 'constants/settings'

// components
import { MultiSelect, ControllableDropdown } from 'components/common'

import type { DropdownProps } from '../Dropdown'
import { SwitchUnitsDefaultToggle } from './components'
import scss from './index.module.scss'

const DROPDOWN_ALIGN_CONFIG = {
  offset: [0, -5],
}

const SwitchUnits = ({
  selectedValue,
  options,
  dropdownClassName,
  onChange,
  toggleRenderer: Toggle,
  ...rest
}: Omit<DropdownProps, 'onChange'> & {
  selectedValue: string
  options: Option[]
  dropdownClassName?: string
  onChange: (v: string) => void
  toggleRenderer?: (props: {
    selectedValue: string
    options: Option[]
  }) => ReactElement
}): ReactElement => {
  const [menuIsOpen, setMenuIsOpen] = useToggle(false)

  const renderToggle = useCallback(() => {
    const ToggleComponent = Toggle || SwitchUnitsDefaultToggle
    return <ToggleComponent selectedValue={selectedValue} options={options} />
  }, [Toggle, selectedValue, options])

  const onSelect: MultiSelectProp['onChange'] = useCallback(
    v => {
      onChange(v as string)
      setMenuIsOpen(false)
    },
    [onChange, setMenuIsOpen]
  )

  return (
    <ControllableDropdown
      isVisible={menuIsOpen}
      toggleListVisible={setMenuIsOpen}
      placement={TOOLTIP_PLACEMENT.bottom}
      trigger='click'
      align={DROPDOWN_ALIGN_CONFIG}
      toggleComponent={renderToggle}
      {...rest}
    >
      <MultiSelect
        className={dropdownClassName}
        options={options}
        value={selectedValue}
        onChange={onSelect}
        isMulti={false}
        hideSelectedOptions={false}
        dropdownOnly
        removeControl
        useOptionValueOnly
        menuIsOpen
      />
    </ControllableDropdown>
  )
}

SwitchUnits.defaultProps = {
  dropdownClassName: scss.select,
}

export default SwitchUnits
