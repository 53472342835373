import {
  getPropertyValueByName,
  getPropertyScale,
  getPropertyColour,
} from 'helpers/map'
import { getDefaultLayerStyle } from 'helpers/layerStyle'
import { isValueInRange } from 'helpers/utils'
import { getColourState } from 'components/map/layers/deckLayers/utils'
import { LAYER_VIS_CONFIGS } from 'components/map/layers/deckLayers/layerFactory'
import { PROPERTY_VARIABLE_TYPES } from 'constants/filter'
import { COLOUR_TYPES } from 'constants/colour'

export const LAYER_DEFAULT_STYLE = {
  colourRange: LAYER_VIS_CONFIGS.colourRange.defaultValue,
  colourPropertyValue: '',
  valueRangeForColour: LAYER_VIS_CONFIGS.propertyValueRange.defaultValue,
  colourClasses: LAYER_VIS_CONFIGS.colourClasses.defaultValue,
  // elevation
  elevationScale: LAYER_VIS_CONFIGS.elevationScale.defaultValue,
  valueRangeForHeight: [],
  heightPropertyValue: '',
}

export const getUpPolygonLayerData = ({ layer, filteredDataState }) => {
  const { cachedMapLayerData, filteredDataWithoutTimeFilter } =
    filteredDataState

  if (cachedMapLayerData) return cachedMapLayerData
  if (!filteredDataWithoutTimeFilter) return []

  const { type, style } = layer
  const {
    colourRange,
    colourClasses,
    colourPropertyValue,
    valueRangeForColour,
    valueRangeForHeight,
    heightPropertyValue,
  } = getDefaultLayerStyle(style[type], LAYER_DEFAULT_STYLE)

  const colourProperty = {
    type: PROPERTY_VARIABLE_TYPES.number,
    key: colourPropertyValue,
  }
  const {
    isSimpleColour: isSimpleFillColour,
    classification: fillColourClassification,
    crossFilterDimension: fillColourCategoryDimension,
  } = getColourState(
    filteredDataWithoutTimeFilter,
    colourRange,
    COLOUR_TYPES.advanced,
    colourClasses,
    colourProperty,
    valueRangeForColour
  )

  const getHeightScale = getPropertyScale(
    filteredDataWithoutTimeFilter,
    heightPropertyValue
  )

  const getFillColourFn = getPropertyColour(
    isSimpleFillColour,
    colourProperty,
    fillColourClassification,
    fillColourCategoryDimension
  )

  return filteredDataWithoutTimeFilter.reduce((acc, geojson) => {
    const fColour = getFillColourFn(geojson)

    const height = getPropertyValueByName(geojson, heightPropertyValue)

    if (isValueInRange(height, valueRangeForHeight)) {
      acc.push({
        ...geojson,
        fillColour: fColour,
        elevation: getHeightScale(height),
      })
    }

    return acc
  }, [])
}
