import { useMemo } from 'react'

import type { Filters } from 'types/filter'

import { DATE_RANGE_FILTER_VALUES } from 'constants/filter'

/** Adding default filters to existing ones  */
const useDetectionDefaultFilters = (filters?: Filters): Filters =>
  useMemo(
    () => ({ detectedAfter: DATE_RANGE_FILTER_VALUES.today, ...filters }),
    [filters]
  )

export default useDetectionDefaultFilters
