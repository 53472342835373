// libraries
import React from 'react'
import PropTypes from 'prop-types'

// constants
import {
  MAP_LABEL_TEXT_ANCHOR,
  MAP_LABEL_TEXT_ALIGNMENT_BASELINE,
} from 'constants/map'
import { PROPERTY_VARIABLE_TYPES } from 'constants/filter'
import { LAYER_VIS_CONFIGS } from 'components/map/layers/deckLayers/layerFactory'

// components
import {
  TitleWithTooltip,
  PropertyPicker,
  RangeSlider,
  ColourPickerSimple,
  MultiSelect,
  Slider,
} from 'components/common'

const LayerStylingLabelSettings = ({ style, propertyOptions, updateStyle }) => {
  const {
    labelProperty = LAYER_VIS_CONFIGS.labelProperty.defaultValue,
    labelSize = LAYER_VIS_CONFIGS.labelSize.defaultValue,
    labelColour = LAYER_VIS_CONFIGS.labelColour.defaultValue,
    labelAnchor = LAYER_VIS_CONFIGS.labelAnchor.defaultValue,
    labelAlignment = LAYER_VIS_CONFIGS.labelAlignment.defaultValue,
    labelOffsetX = LAYER_VIS_CONFIGS.labelOffsetX.defaultValue,
    labelOffsetY = LAYER_VIS_CONFIGS.labelOffsetY.defaultValue,
  } = style

  return (
    <div className='groupOption'>
      <div className='groupOptionTitle'>
        <TitleWithTooltip title='Label' tooltip='Label based on' />
      </div>
      <div className='groupOptionContent'>
        <div className='groupOptionLabel'>Label Colour</div>
        <ColourPickerSimple
          colour={labelColour}
          onChange={val => updateStyle({ labelColour: val })}
        />
        <div className='groupOptionLabel'>Label based on</div>
        <PropertyPicker
          property={labelProperty}
          onChange={({ value }) => updateStyle({ labelProperty: value })}
          propertyTypes={[
            PROPERTY_VARIABLE_TYPES.number,
            PROPERTY_VARIABLE_TYPES.string,
          ]}
          propertyOptions={propertyOptions}
          isMulti={false}
        />
      </div>
      <RangeSlider
        changeable
        title='Label Size'
        tooltip='Label size (px)'
        value={labelSize}
        step={LAYER_VIS_CONFIGS.labelSize.step}
        range={LAYER_VIS_CONFIGS.labelSize.range}
        onChange={val => updateStyle({ labelSize: val })}
      />
      <div className='groupOptionContent'>
        <div className='row g-3'>
          <div className='col-6'>
            <div className='groupOptionLabel'>Text Anchor</div>

            <MultiSelect
              value={labelAnchor}
              options={MAP_LABEL_TEXT_ANCHOR}
              onChange={option => updateStyle({ labelAnchor: option?.value })}
              isMulti={false}
              isClearable={false}
              placeholder='Anchor'
            />
          </div>
          <div className='col-6'>
            <div className='groupOptionLabel'>Alignment</div>
            <MultiSelect
              value={labelAlignment}
              options={MAP_LABEL_TEXT_ALIGNMENT_BASELINE}
              onChange={option =>
                updateStyle({ labelAlignment: option?.value })
              }
              isMulti={false}
              isClearable={false}
              placeholder='Alignment'
            />
          </div>
        </div>
      </div>
      <div className='groupOptionContent'>
        <div className='row g-3'>
          <div className='col-6'>
            <div className='groupOptionLabel'>X offset</div>
            <Slider
              title='Offset'
              value={labelOffsetX}
              step={LAYER_VIS_CONFIGS.labelOffsetX.step}
              range={LAYER_VIS_CONFIGS.labelOffsetX.range}
              onChange={val => updateStyle({ labelOffsetX: val })}
            />
          </div>
          <div className='col-6'>
            <div className='groupOptionLabel'>Y offset</div>
            <Slider
              title='Offset'
              value={labelOffsetY}
              step={LAYER_VIS_CONFIGS.labelOffsetY.step}
              range={LAYER_VIS_CONFIGS.labelOffsetY.range}
              onChange={val => updateStyle({ labelOffsetY: val })}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

LayerStylingLabelSettings.propTypes = {
  style: PropTypes.shape({
    fillColour: PropTypes.array,
    radius: PropTypes.number,
    labelProperty: PropTypes.string,
    labelSize: PropTypes.number,
    labelColour: PropTypes.arrayOf(PropTypes.number),
    labelAnchor: PropTypes.string,
    labelAlignment: PropTypes.string,
    labelOffsetX: PropTypes.number,
    labelOffsetY: PropTypes.number,
  }),
  updateStyle: PropTypes.func.isRequired,
  propertyOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

LayerStylingLabelSettings.defaultProps = {
  style: {},
}
export default LayerStylingLabelSettings
