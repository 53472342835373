// libraries
import { ReactElement } from 'react'
import _ from 'lodash'
import { ColumnBodyOptions } from 'primereact/column'

// components
import { Badge } from 'components/common'

// utilities
import { getActiveStatus } from 'helpers/utils'

// constants
import { BADGE_TYPES } from 'constants/common'

const ActiveTemplate = (
  rowData: unknown,
  { field }: ColumnBodyOptions
): ReactElement => {
  const isActive = _.get(rowData, field)

  return (
    <Badge
      content={getActiveStatus(isActive)}
      type={isActive ? BADGE_TYPES.success : BADGE_TYPES.default}
    />
  )
}

export default ActiveTemplate
