// libraries
import { ReactElement, ComponentProps } from 'react'
import ReactGridLayout from 'react-grid-layout'
import { SizeMe } from 'react-sizeme'

const GridLayout = ({
  children,
  ...rest
}: ComponentProps<typeof ReactGridLayout>): ReactElement => {
  return (
    <SizeMe>
      {({ size }) => {
        return (
          <ReactGridLayout {...rest} width={size.width || 1280}>
            {children}
          </ReactGridLayout>
        )
      }}
    </SizeMe>
  )
}

export default GridLayout
