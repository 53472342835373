import _ from 'lodash'
import { FormValidation, UiSchema } from '@rjsf/utils'

import { FORM_QUESTION_TYPES } from 'constants/formBuilder'

import type { DateTimeSchema, JSONFormSchema } from 'types/formBuilder'

import { switchcaseF } from 'helpers/utils'
import { getAllJSONFormProperties } from 'helpers/issue'
import {
  getFormBuilderWidgetType,
  isJsonFormWidgetHidden,
} from 'helpers/formBuilder'
import validateJsonFormDateTimeWidget from 'components/common/JsonForm/DatetimeWidget/validator'
import { Payload } from 'types/common'

export const additionalJsonFormFieldsValidation = ({
  formPayload,
  schema,
  uiSchema,
  errors,
  timezone,
}: {
  formPayload: Payload
  schema: JSONFormSchema
  uiSchema?: UiSchema
  errors: FormValidation
  timezone: string
}): FormValidation => {
  _.forEach(uiSchema, (fieldUiSchema, fieldId) => {
    const properties = getAllJSONFormProperties({ schema, uischema: uiSchema })
    const fieldSchema = properties[fieldId]
    const widgetType = getFormBuilderWidgetType({
      schema: fieldSchema as JSONFormSchema,
      uischema: fieldUiSchema,
    })
    const isHidden = isJsonFormWidgetHidden(fieldUiSchema)
    const fieldValue = formPayload[fieldId]

    if (!isHidden && widgetType) {
      // Run an additional (custom) validation for some of the widgets
      switchcaseF({
        [FORM_QUESTION_TYPES.DATETIME]: () => {
          validateJsonFormDateTimeWidget({
            fieldId,
            schema: fieldSchema as DateTimeSchema,
            uiSchema: fieldUiSchema,
            fieldValue: fieldValue as string,
            errors,
            timezone,
          })
        },
      })(_.noop)(widgetType)
    }
  })

  return errors
}
