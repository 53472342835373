import {
  useMemo,
  useCallback,
  useState,
  useRef,
  useEffect,
  ReactElement,
  MouseEvent,
} from 'react'
import Gallery from 'react-photo-gallery'
import { useMeasure, useSet } from 'react-use'
import _ from 'lodash'

// utils
import { switchcaseF, getObjectHash } from 'helpers/utils'

// constants
import { ASSET_PROFILE_MEDIA_TYPES } from 'constants/assets'
import { IMAGE_GALLERY_TYPES } from 'constants/common'

// components
import { Image } from 'components/common'
import { Caption } from 'components/common/Image'
import { ModalCarousel } from 'components/common/Image/ModalCarousel'

import type { ImageGalleryType } from 'types/common'
import type { AssetMediaType } from 'types/asset'

export type BaseMediaType = {
  src: string
  title?: string
  subtitle?: string
}

export type ImageType = BaseMediaType & {
  height: number
  width: number
}

export type Images = ImageType[]

const Slider = ({
  images,
  width,
  height,
  onClick,
}: {
  images: Images
  width: number
  height: number
  onClick?: () => void
}): ReactElement => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const newPhotos = useMemo(() => {
    return images.map(image => {
      const isPortrait = image.height > image.width
      const ratio = isPortrait ? image.height / height : image.width / width
      return {
        ...image,
        width: isPortrait ? image.width / ratio : width,
        height: isPortrait ? height : image.height / ratio,
      }
    })
  }, [height, images, width])

  const current = useMemo(
    () => newPhotos[currentIndex],
    [currentIndex, newPhotos]
  )

  const {
    title,
    subtitle,
    src,
    width: imageWidth,
    height: imageHeight,
  } = current

  return width > 0 && height > 0 ? (
    <>
      <div
        className='w-100 h-100 d-flex justify-content-center align-items-center'
        {...(onClick && {
          onClick: e => onClick(e, currentIndex),
          style: { cursor: 'pointer' },
        })}
      >
        <img height={imageHeight} width={imageWidth} src={src} alt={src} />
        <div className='carousel-caption d-none d-md-block'>
          <h5>{title}</h5>
          <p>{subtitle}</p>
        </div>
      </div>
      <div>
        <div className='carousel-indicators'>
          {newPhotos.map((image, index) => {
            return (
              <button
                key={image.src}
                type='button'
                data-bs-target='#carouselIndicators'
                label={index}
                onClick={() => setCurrentIndex(index)}
                className={index === currentIndex ? 'active' : undefined}
              />
            )
          })}
        </div>
        <button
          className='carousel-control-prev'
          type='button'
          data-bs-target='#carouselIndicators'
          onClick={() => {
            setCurrentIndex(old => {
              return old === 0 ? images.length - 1 : old - 1
            })
          }}
        >
          <span className='carousel-control-prev-icon' />
          <span className='visually-hidden'>Previous</span>
        </button>
        <button
          className='carousel-control-next'
          type='button'
          data-bs-target='#carouselIndicators'
          onClick={() => {
            setCurrentIndex(old => {
              return old === images.length - 1 ? 0 : old + 1
            })
          }}
        >
          <span className='carousel-control-next-icon' />
          <span className='visually-hidden'>Next</span>
        </button>
      </div>
    </>
  ) : (
    <></>
  )
}

const SingleLine = ({
  images,
  height,
  margin,
  onClick,
}: {
  images: Images
  height: number
  margin?: string
  onClick?: () => void
}): ReactElement => {
  return (
    <div className='d-flex' style={{ height, overflowX: 'auto' }}>
      {images.map((image, index) => {
        const { src, title, subtitle } = image
        return (
          <div
            style={{
              margin: `0 ${margin}`,
              cursor: onClick ? 'pointer' : 'default',
            }}
            className='position-relative'
            key={src}
            {...(onClick && {
              onClick: e => onClick(e, index),
            })}
          >
            <img height={height} width='auto' src={src} alt={title} />
            {(title || subtitle) && (
              <Caption title={title} subtitle={subtitle} />
            )}
          </div>
        )
      })}
    </div>
  )
}

const ImageGallery = ({
  images,
  type,
  mediaType,
  onLoad,
  enableModalCarousel = true,
  containerClassName = 'w-100 h-100',
}: {
  images?: Images
  type: ImageGalleryType
  mediaType?: AssetMediaType
  enableModalCarousel?: boolean
  containerClassName?: string
  onLoad?: () => void
}): ReactElement => {
  const [ref, { width, height }] = useMeasure()

  const imageRef = useRef()

  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)
  const [loadedImages, loadedImagesActions] = useSet(new Set([]))

  const openLightBox = useCallback((_event: MouseEvent, index: number) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightBox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  const isPrintable = useMemo(
    () => mediaType === ASSET_PROFILE_MEDIA_TYPES.PRINTABLE,
    [mediaType]
  )

  const renderImage = useCallback(
    ({
      photo,
      index,
      key,
    }: {
      photo: ImageType
      index: number
      key: string
    }) => {
      const enableClick = !isPrintable && enableModalCarousel
      return (
        <Image
          {...photo}
          key={key}
          margin='2px'
          index={index}
          backgroundColor='none'
          style={{
            width: 160,
            height: 120,
            cursor: enableClick ? 'pointer' : 'default',
          }}
          {...(enableClick && {
            onClick: (e: MouseEvent) => openLightBox(e, index),
          })}
        />
      )
    },
    [isPrintable, enableModalCarousel, openLightBox]
  )

  const views = useMemo(() => {
    return _.map(images, img => ({
      ...img,
      caption: img.title,
      subTitle: img.subtitle,
    }))
  }, [images])

  const renderGrid = useCallback(
    () => <Gallery photos={images} renderImage={renderImage} />,
    [images, renderImage]
  )

  const renderSlider = useCallback(() => {
    return (
      <Slider
        images={images}
        width={width}
        height={height}
        {...(enableModalCarousel && { onClick: openLightBox })}
      />
    )
  }, [images, width, height, enableModalCarousel, openLightBox])

  const renderColumn = useCallback(() => {
    return (
      <SingleLine
        images={images}
        height={height}
        margin='5px'
        {...(enableModalCarousel && { onClick: openLightBox })}
      />
    )
  }, [images, height, enableModalCarousel, openLightBox])

  const onImageLoad = useCallback(
    image => {
      loadedImagesActions.add(image)
    },
    [loadedImagesActions]
  )

  const renderPrintableGallery = useCallback(() => {
    const rows = _.chunk(images, 2)
    return (
      <div ref={imageRef} style={{ overflowX: 'hidden' }}>
        {rows.map(cols => (
          <div className='row galleryRow' key={getObjectHash(cols)}>
            {cols.map(({ src, property }) => (
              <div className='col-6' key={src}>
                <div className='card'>
                  <img
                    src={src}
                    className='card-img-top'
                    alt={property}
                    onLoad={() => onImageLoad(src)}
                    onError={() => onImageLoad(src)}
                  />
                  <div className='card-body'>
                    <p className='card-text'>{property}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    )
  }, [onImageLoad, images])

  const renderWebGallery = useCallback(() => {
    return switchcaseF({
      [IMAGE_GALLERY_TYPES.grid]: renderGrid,
      [IMAGE_GALLERY_TYPES.slider]: renderSlider,
      [IMAGE_GALLERY_TYPES.column]: renderColumn,
    })(_.noop)(type)
  }, [renderColumn, renderGrid, renderSlider, type])

  useEffect(() => {
    if (onLoad && loadedImages.size === images.length) {
      onLoad()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedImages])

  return (
    <div className={containerClassName} ref={ref}>
      {isPrintable ? renderPrintableGallery() : renderWebGallery()}

      {enableModalCarousel && (
        <ModalCarousel
          viewerIsOpen={viewerIsOpen}
          closeLightBox={closeLightBox}
          currentImage={currentImage}
          imageResources={views}
        />
      )}
    </div>
  )
}

ImageGallery.defaultProps = {
  images: [],
  mediaType: ASSET_PROFILE_MEDIA_TYPES.WEB,
  onLoad: _.noop,
}

export default ImageGallery
