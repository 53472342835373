// libraries
import keymirror from 'keymirror'
import _ from 'lodash'
import moment from 'moment-timezone'
import { DataFilterExtension } from '@deck.gl/extensions'

// utils
import { uniqueStringId } from 'helpers/utils'
import {
  getPropertyValueByName,
  getSourceFeature,
  getHighlightedObjectIndex,
} from 'helpers/map'
import { isSimpleColourType } from 'helpers/colour'
import { excludeElevation } from 'helpers/geojson'

// constants
import { INTERACTION_TYPES, LAYER_PROFILE_TYPES } from 'constants/profile'
import { PROPERTY_TIME } from 'constants/common'
import { LAYER_VIS_CONFIGS } from './layerFactory'

export const OVERLAY_TYPE = keymirror({
  deckgl: null,
  mapboxgl: null,
})

export const getOpacity = opacity => opacity * 0.01

export const getPosition = (d, elevationExcluded = true) => {
  const newGeojson = elevationExcluded ? excludeElevation(d) : d
  return _.get(newGeojson, 'geometry.coordinates')
}
/**
 * The "Layer" is a core concept of deck.gl. A deck.gl layer is a packaged
 * visualization type that takes a collection of datums, associate each with
 * positions, colors, extrusions, etc., and renders them on a map.
 * deck.gl provides an extensive layer catalog and is designed to compose many
 * layers together to form complex visuals.
 * https://deck.gl/#/documentation/developer-guide/using-layers
 *
 * This Layer class is implemented based on the kepler.gl base-layer.js
 * https://github.com/keplergl/kepler.gl/blob/master/src/layers/base-layer.js
 */
export default class Layer {
  constructor(props = {}) {
    const { id, profile, identityProperty, profileTitle } = props
    // The id prop is the unique identifier of this layer among all layers.
    // Constructing a new layer instance in its own does not have any
    // performance impact, as deck.gl only does the expensive calculations
    // when a layer is created (an id appearing for the first time) or updated
    // (different props are passed in for the same id).
    this.layerId = id || uniqueStringId()

    this.id = `${this.type}/${this.layerId}`

    this.visConfigSettings = {}

    this.config = this.getDefaultLayerConfig(props)

    this.profileType = profile?.type

    this.identityProperty = identityProperty

    this.profileTitle = profileTitle
  }

  get type() {
    return 'base'
  }

  get name() {
    return this.type
  }

  get overlayType() {
    return OVERLAY_TYPE.deckgl
  }

  get isAggregated() {
    return false
  }

  get noneLayerDataAffectingProps() {
    return ['opacity', 'visible']
  }

  shouldCalculateLayerData = props => {
    return props.some(p => !this.noneLayerDataAffectingProps.includes(p))
  }

  hasLayerData = () => {
    return !_.isEmpty(this.layerData)
  }

  shouldRenderLayer = data => {
    return this.type && this.config.isVisible && this.hasLayerData(data)
  }

  getLayerBasicProps = ({ layerData } = {}) => {
    const { visible } = this.config
    const dataAccessors = {
      getPosition: d => d.geometry.coordinates,
    }
    return {
      id: this.id,
      layerId: this.layerId,
      visible: !!visible,
      ...(layerData && { data: layerData }),
      ...dataAccessors,
    }
  }

  registerVisConfig = (layerVisConfigs, layerExistingVisStyle = {}) => {
    _.forEach(layerVisConfigs, (defaultKey, key) => {
      if (!_.isNil(layerExistingVisStyle[key])) {
        //  assign the existing visual style
        this.config.visConfig[key] = layerExistingVisStyle[key]
      } else if (LAYER_VIS_CONFIGS[defaultKey]) {
        // assign one of default LAYER_CONFIGS
        this.config.visConfig[key] = LAYER_VIS_CONFIGS[defaultKey].defaultValue
        this.visConfigSettings[key] = LAYER_VIS_CONFIGS[defaultKey]
      }
    })
  }

  getDefaultLayerConfig = ({ style = {}, type, label, isVisible }) => {
    const fillColour = _.get(
      style,
      [type, 'fillColour'],
      LAYER_VIS_CONFIGS.fillColour.defaultValue
    )

    return {
      label,
      fillColour,
      visConfig: {},
      visible: isVisible || false,
    }
  }

  getProfileConfig = profileHandler => {
    const sharedProfileConfigs = { pickable: true }

    const profileType =
      this.profileType ||
      (this.isAggregated
        ? LAYER_PROFILE_TYPES.aggregated
        : LAYER_PROFILE_TYPES.simple)

    return _.isFunction(profileHandler)
      ? {
          ...sharedProfileConfigs,
          onClick: profileHandler(profileType, INTERACTION_TYPES.click),
          onHover: profileHandler(profileType, INTERACTION_TYPES.hover),
        }
      : sharedProfileConfigs
  }

  updateLayerConfig = newConfig => {
    this.config = { ...this.config, ...newConfig }
    return this
  }

  updateLayerData = data => {
    this.layerData = data
  }

  renderLayer = () => {
    return []
  }

  getZoomFactor = x => 2 ** Math.max(14 - x, 0)

  getOpacity = getOpacity

  getBasicProps = ({ visible, layerData }) => ({
    id: this.id,
    visible,
    data: layerData,
  })

  getPosition = getPosition

  getPropertyValueByName = getPropertyValueByName

  isSimpleColourType = isSimpleColourType

  getSourceFeature = getSourceFeature

  /**
   * Filter layer data by a moving time window, without re-generating any
   * attribute, which is based on DataFilterExtension(https://deck.gl/#/documentation/submodule-api-reference/deckgl-extensions/data-filter-extension)
   * The DataFilterExtension adds GPU-based data filtering functionalities to
   * layers. It allows the layer to show/hide objects based on user-defined
   * properties. This extension provides a significantly more performant
   * alternative to filtering the data array on the CPU.
   *
   * It now only supports all non aggregation layers and HeatMapLayer, but not
   * supports HexagonLayer. For the point layer, it must be a layer with the
   * simple colour styling and is not aggregated
   *
   * https://deck.gl/docs/api-reference/extensions/data-filter-extension#filter-precision
   *
   * @param {Object} selectedDateTimeRange the global datetime range
   *
   * @return {Object} {filterEnabled,getFilterValue,filterRange,extensions}
   */
  getTimeFilterProps = (selectedDateTimeRange, offsetTime) => {
    // * By default, both the filter values and the filter range are uploaded to the GPU as 32-bit floats. When using very large filter values, most commonly Epoch timestamps, 32-bit float representation could lead to an error margin of >1 minute.
    const dataStartTimeUnix = moment.utc(offsetTime).unix()
    const filterEnabled = !!selectedDateTimeRange
    const { start, end } = selectedDateTimeRange || {}
    // * Transform each filter value by subtracting a fixed "origin" (offsetTime) value, thus making the numbers smaller
    const filterRange = [
      moment.utc(start).unix() - dataStartTimeUnix,
      moment.utc(end).unix() - dataStartTimeUnix,
    ]

    return {
      filterRange,
      filterEnabled,
      getFilterValue: f =>
        moment.utc(f.properties.time).unix() - dataStartTimeUnix,
      extensions: [new DataFilterExtension({ filterSize: 1 })],
    }
  }

  getTimeFromFirstData = data => {
    return _.get(_.first(data), PROPERTY_TIME)
  }

  getHighlightedObjectIndex = getHighlightedObjectIndex
}
