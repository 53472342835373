// libraries
import { useMemo, useEffect, useState } from 'react'
import _ from 'lodash'

// contexts
import { useMapStateValue } from 'contexts'

// constants
import { PLACEMENT } from 'constants/common'
import {
  BUTTON_VARIANTS,
  BUTTON_ICON_POSITIONS,
} from 'components/common/Button'

// components
import { Button, IconButton, Tooltip } from 'components/common'

// style
import scss from './index.module.scss'

const MapWidget = () => {
  const { maxWidgetSelected, setMaxWidgetSelected, isTimelineExpanded, map } =
    useMapStateValue()
  const { layers } = map

  const [timelineHeight, setTimelineHeight] = useState()

  useEffect(() => {
    if (isTimelineExpanded) {
      const height = document.getElementById('timeline-container')?.clientHeight
      setTimelineHeight(height)
    } else {
      setTimelineHeight(0)
    }
  }, [isTimelineExpanded])

  const layerWidgets = useMemo(() => _.flatMap(layers, 'widgets'), [layers])

  useEffect(() => {
    setMaxWidgetSelected(oldMaxWidgetSelected => {
      const { id } = oldMaxWidgetSelected?.widgetProps || {}
      if (!id) return oldMaxWidgetSelected

      const isWidgetExisted = _.find(layerWidgets, { id })
      return isWidgetExisted ? oldMaxWidgetSelected : null
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layerWidgets])

  const wrapperStyle = useMemo(() => {
    return {
      height: timelineHeight && `calc(100% - ${timelineHeight}px - 45px)`,
    }
  }, [timelineHeight])

  const [widgetInstance, setWidgetInstance] = useState()

  const renderMaxWidget = () => {
    const { Component, widgetProps, enableImageDownload } = maxWidgetSelected

    return widgetProps ? (
      <div className={scss.container}>
        <div
          {...(timelineHeight && { style: wrapperStyle })}
          className={scss.wrapper}
        >
          <div
            className={`${scss.titleLine} d-flex ${
              widgetProps.title
                ? 'justify-content-between'
                : 'justify-content-end'
            } align-items-center`}
          >
            {widgetProps.title && (
              <div className={scss.title}>{widgetProps.title}</div>
            )}
            <div>
              {enableImageDownload && widgetInstance && (
                <Tooltip
                  placement={PLACEMENT.top}
                  trigger={['hover']}
                  overlay='Download Image'
                >
                  <Button
                    onClick={() => {
                      const buffer = widgetInstance.getDataURL({
                        pixelRatio: 2,
                      })
                      const link = document.createElement('a')
                      link.href = buffer
                      link.setAttribute('download', `${widgetProps.title}.png`)
                      document.body.appendChild(link)
                      link.click()
                    }}
                    className={scss.downloadButton}
                    variant={BUTTON_VARIANTS.secondary}
                    icon='FaShareSquare'
                    iconPosition={BUTTON_ICON_POSITIONS.left}
                  >
                    Download Image
                  </Button>
                </Tooltip>
              )}
              <IconButton
                onClick={() => {
                  setMaxWidgetSelected(null)
                }}
                icon='MdClose'
                size={20}
              />
            </div>
          </div>
          <div className={scss.content}>
            <Component {...widgetProps} setWidgetInstance={setWidgetInstance} />
          </div>
        </div>
      </div>
    ) : null
  }

  return maxWidgetSelected ? renderMaxWidget() : null
}

export default MapWidget
