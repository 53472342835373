import update from 'immutability-helper'
import to from 'await-to-js'
import _ from 'lodash'

// utils
import { listAncillaryData, getAncillaryData } from 'services/api/ancillaryData'
import { fetchListData, updateListItem } from 'helpers/utils'
import { showError } from 'helpers/message'
import log from 'helpers/log'

export const ANCILLARY_DATA_INITIAL_LIST = 'ANCILLARY_DATA_INITIAL_LIST'
export const ANCILLARY_DATA_SET_LIST = 'ANCILLARY_DATA_SET_LIST'
export const ANCILLARY_DATA_SET_FILTERED_LIST =
  'ANCILLARY_DATA_SET_FILTERED_LIST'
export const ANCILLARY_DATA_SET_CONTENTS = 'ANCILLARY_DATA_SET_CONTENTS'

const updateAncillaryDataContent = (data, state, dispatch) => {
  const { ancillaryDataContents } = state
  const newAssetsContents = update(ancillaryDataContents, {
    [data.id]: { $set: data },
  })
  dispatch({
    type: ANCILLARY_DATA_SET_CONTENTS,
    ancillaryDataContents: newAssetsContents,
  })
}

const ancillaryDataActions = ({ state, dispatch }) => {
  return {
    getAncillaryDataList: async (queryParams = {}) => {
      dispatch({
        type: ANCILLARY_DATA_INITIAL_LIST,
      })

      const [err, ancillaryDataList] = await to(
        fetchListData(listAncillaryData, queryParams)
      )
      if (err) {
        const message = 'Failed to load ancillary data list'
        showError(message)
        log.error(message, err)
        return
      }

      if (_.isEmpty(queryParams)) {
        dispatch({
          type: ANCILLARY_DATA_SET_LIST,
          ancillaryDataList,
        })
      } else {
        dispatch({
          type: ANCILLARY_DATA_SET_FILTERED_LIST,
          ancillaryDataList,
        })
      }
    },
    updateAncillaryData: data => {
      if (!data) {
        log.warn('The data to be updated is invalid')
        return
      }
      const { ancillaryDataList, filteredAncillaryDataList } = state
      // because there is no redirect action between the list page and add/update item page, update list directly
      const newAncillaryData = updateListItem(
        data.id,
        data,
        ancillaryDataList,
        true
      )
      const newFilteredAncillaryDataList = updateListItem(
        data.id,
        data,
        filteredAncillaryDataList,
        true
      )

      dispatch({
        type: ANCILLARY_DATA_SET_LIST,
        ancillaryDataList: newAncillaryData,
      })

      dispatch({
        type: ANCILLARY_DATA_SET_FILTERED_LIST,
        ancillaryDataList: newFilteredAncillaryDataList,
      })
    },
    updateAncillaryDataList: ancillaryDataList => {
      dispatch({
        type: ANCILLARY_DATA_SET_LIST,
        ancillaryDataList,
      })
    },
    updateFilteredAncillaryDataList: ancillaryDataList => {
      dispatch({
        type: ANCILLARY_DATA_SET_FILTERED_LIST,
        ancillaryDataList,
      })
    },
    getAncillaryDataContent: async dataId => {
      const { ancillaryDataContents } = state
      const cachedAncillaryData = ancillaryDataContents[dataId]
      if (cachedAncillaryData) return cachedAncillaryData

      const data = await getAncillaryData(dataId)
      updateAncillaryDataContent(data, state, dispatch)
      return data
    },
    updateAncillaryDataContent: data =>
      updateAncillaryDataContent(data, state, dispatch),
  }
}

export default ancillaryDataActions
