import { ReactElement, useMemo } from 'react'
import { useAsync } from 'react-use'

// constants
import { WIDGET_TYPES } from 'constants/widget'
import { ASSET_PROFILE_MEDIA_TYPES } from 'constants/assets'

// components
import { Loading, ImageGallery } from 'components/common'
import { NoAvailableWidget } from 'components/widget'
import IssueVideoList from 'components/issue/common/IssueVideoList'

import type { ImageGalleryType } from 'types/common'
import type { AssetMediaType } from 'types/asset'

import {
  NO_IMAGES_CONTENT,
  getAssetImageItems,
  GetAssetImageItems,
  getAssetVideoItems,
} from './utils'

import scss from './index.module.scss'
import { useMediaCount } from './hooks'

export type DisplayImagesGalleryProp = GetAssetImageItems & {
  type: ImageGalleryType
  mediaType?: AssetMediaType
  onWidgetLoad: () => void
  setImageCount: React.Dispatch<React.SetStateAction<number>>
}

const DisplayImagesGallery = ({
  properties,
  propertiesMetadata,
  visibleProperties,
  titleProperty,
  subtitleProperty,
  timezone,
  type,
  mediaType = ASSET_PROFILE_MEDIA_TYPES.WEB,
  onWidgetLoad,
  setImageCount,
}: DisplayImagesGalleryProp): ReactElement => {
  const { value: imageItems = [], loading } = useAsync(
    async () =>
      getAssetImageItems({
        properties,
        propertiesMetadata,
        visibleProperties,
        titleProperty,
        subtitleProperty,
        timezone,
      }),
    [properties, propertiesMetadata, visibleProperties]
  )

  const videoItems = useMemo(
    () =>
      getAssetVideoItems({
        properties,
        propertiesMetadata,
        visibleProperties,
      }),
    [properties, propertiesMetadata, visibleProperties]
  )

  const { overallMediaCount } = useMediaCount({
    setMediaCount: setImageCount,
    videoItems,
    imageItems,
  })

  if (loading) return <Loading />

  return overallMediaCount === 0 ? (
    <div className={scss.center}>
      <NoAvailableWidget
        widgetType={WIDGET_TYPES.image}
        content={NO_IMAGES_CONTENT}
      />
    </div>
  ) : (
    <>
      {imageItems?.length > 0 && (
        <ImageGallery
          images={imageItems}
          type={type}
          mediaType={mediaType}
          containerClassName='w-100'
          onLoad={onWidgetLoad}
        />
      )}

      {videoItems && videoItems.length > 0 && (
        <IssueVideoList
          videoResources={videoItems}
          fieldValue={videoItems}
          containerClassName='pe-2'
          onLoad={onWidgetLoad}
        />
      )}
    </>
  )
}

export default DisplayImagesGallery
