// utils
import {
  getPropertyValueByName,
  getPropertyScale,
  getPropertyColour,
} from 'helpers/map'
import { getDefaultLayerStyle } from 'helpers/layerStyle'

import { isValueInRange } from 'helpers/utils'
import { LAYER_VIS_CONFIGS } from 'components/map/layers/deckLayers/layerFactory'
import { getColourState } from 'components/map/layers/deckLayers/utils'

export const LAYER_DEFAULT_STYLE = {
  fillColour: LAYER_VIS_CONFIGS.fillColour.defaultValue,
  colourType: LAYER_VIS_CONFIGS.colourType.defaultValue,
  colourProperty: LAYER_VIS_CONFIGS.colourProperty.defaultValue,
  colourClasses: LAYER_VIS_CONFIGS.colourClasses.defaultValue,
  range: LAYER_VIS_CONFIGS.propertyValueRange.defaultValue,
  // line width setting
  lineWidth: LAYER_VIS_CONFIGS.lineWidth.defaultValue,
  lineWidthProperty: LAYER_VIS_CONFIGS.lineWidthProperty.defaultValue,
  // height setting
  arcHeight: LAYER_VIS_CONFIGS.arcHeight.defaultValue,
  valueRangeForHeight: LAYER_VIS_CONFIGS.propertyValueRange.defaultValue,
  heightPropertyValue: LAYER_VIS_CONFIGS.heightProperty.defaultValue,
}

export const getArcLayerData = ({ layer, filteredDataState }) => {
  const { cachedMapLayerData, filteredDataWithoutTimeFilter } =
    filteredDataState
  if (cachedMapLayerData) return cachedMapLayerData
  if (!filteredDataWithoutTimeFilter) return []

  const { type, style } = layer
  const {
    fillColour,
    colourType,
    colourProperty,
    colourClasses,
    range,
    lineWidth,
    lineWidthProperty,
    valueRangeForHeight,
    heightPropertyValue,
  } = getDefaultLayerStyle(style[type], LAYER_DEFAULT_STYLE)

  const {
    isSimpleColour: isSimpleFillColour,
    classification: fillColourClassification,
    crossFilterDimension: fillColourCategoryDimension,
  } = getColourState(
    filteredDataWithoutTimeFilter,
    fillColour,
    colourType,
    colourClasses,
    colourProperty,
    range
  )

  const getLineWidthScale = getPropertyScale(
    filteredDataWithoutTimeFilter,
    lineWidthProperty
  )

  const getHeightScale = getPropertyScale(
    filteredDataWithoutTimeFilter,
    heightPropertyValue
  )

  const getFillColourFn = getPropertyColour(
    isSimpleFillColour,
    colourProperty,
    fillColourClassification,
    fillColourCategoryDimension
  )

  if (isSimpleFillColour && !lineWidthProperty && !heightPropertyValue) {
    return filteredDataWithoutTimeFilter
  }

  return filteredDataWithoutTimeFilter.reduce((acc, geojson) => {
    const fColour = getFillColourFn(geojson)

    const lWidth =
      getLineWidthScale(getPropertyValueByName(geojson, lineWidthProperty)) *
      lineWidth

    const height = getPropertyValueByName(geojson, heightPropertyValue)

    if (
      (isSimpleFillColour || fColour) &&
      isValueInRange(height, valueRangeForHeight)
    ) {
      acc.push({
        ...geojson,
        fillColour: fColour,
        lineWidth: lWidth,
        height: height ? getHeightScale(height) : 1,
      })
    }

    return acc
  }, [])
}
