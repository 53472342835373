import _ from 'lodash'

// utils
import log, { reportErrors } from 'helpers/log'

import type { Payload, DatasetMetadata } from 'types/common'

export const getFetchParams = ({
  credentials,
  forceUseSuSession,
  ...rest
}: {
  method: string
  credentials?: Payload
  forceUseSuSession?: string
} & Record<string, unknown>): RequestInit => {
  const headers = {
    'Content-Type': 'application/json',
    ...(credentials && { 'su-session': JSON.stringify(credentials) }),
    ...(forceUseSuSession && { 'su-use-su-session': 'force' }),
  }

  return {
    ...rest,
    headers,
  }
}

export const retryOn =
  (retries = 1) =>
  (attempt: number, error: Error, response: Response): boolean => {
    // retry on any network error, or 4xx or 5xx status codes
    const { status: responseStatus } = response || {}
    if (error || responseStatus >= 400) {
      const messagePrefix =
        responseStatus === 400
          ? ' UP error: could not retrieve data'
          : ` Retrying: no data coming`

      const errorMessage = _.get(error, 'message', '')
      const attempts = attempt + 1
      const message = [
        messagePrefix,
        `response status:${responseStatus || ''}`,
        `attempt number: ${attempts}`,
        errorMessage,
      ].join(';')

      if (attempt > retries) {
        reportErrors(message)
      } else {
        log.warn(message)
      }

      return attempt <= retries
    }
    return false
  }

export const getForceUseSuSession = (metadata: DatasetMetadata): boolean =>
  _.get(metadata, 'hints.up[su-use-su-session]') === 'force'
