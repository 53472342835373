/* eslint-disable prefer-promise-reject-errors */
import { useRef } from 'react'

type CancellablePromise = {
  promise: Promise<unknown>
  cancel: () => void
}

export const createCancellablePromise = (
  promise: Promise<unknown>
): CancellablePromise => {
  let isCanceled = false

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      value => (isCanceled ? reject({ isCanceled, value }) : resolve(value)),
      error => reject({ isCanceled, error })
    )
  })

  return {
    promise: wrappedPromise,
    cancel: () => {
      isCanceled = true
    },
  }
}

export const delay = (ms: number): Promise<unknown> =>
  new Promise(resolve => {
    setTimeout(resolve, ms)
  })

/**
 * Provides a necessary logic for a useClickPreventionOnDoubleClick hook.
 * https://medium.com/trabe/prevent-click-events-on-double-click-with-react-with-and-without-hooks-6bf3697abc40
 */
const useCancellablePromises = (): {
  appendPendingPromise: (promise: CancellablePromise) => void
  removePendingPromise: (promise: CancellablePromise) => void
  clearPendingPromises: () => void
} => {
  const pendingPromises = useRef<CancellablePromise[]>([])

  const appendPendingPromise = (promise: CancellablePromise) => {
    pendingPromises.current = [...pendingPromises.current, promise]
  }

  const removePendingPromise = (promise: CancellablePromise) => {
    pendingPromises.current = pendingPromises.current.filter(p => p !== promise)
  }

  const clearPendingPromises = () =>
    pendingPromises.current.map(p => p.cancel())

  const api = {
    appendPendingPromise,
    removePendingPromise,
    clearPendingPromises,
  }

  return api
}

export default useCancellablePromises
