import { ReactElement, ForwardRefExoticComponent } from 'react'
import { UniqueIdentifier } from '@dnd-kit/core'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

export type SortableListItemProps = {
  id: UniqueIdentifier
  itemComponent: ForwardRefExoticComponent<
    Omit<SortableListItemProps, 'itemComponent'>
  >
  isDragging?: boolean
  [key: string]: unknown
}

/** Makes a list item draggable with @dnd-kit */
const SortableListItem = ({
  itemComponent: ItemComponent,
  isDragging,
  ...props
}: SortableListItemProps): ReactElement => {
  const { id } = props
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    ...(isDragging && { opacity: 0.4 }),
  }

  return (
    <ItemComponent
      {...props}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    />
  )
}

export default SortableListItem
