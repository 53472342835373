import _ from 'lodash'
import booleanPointInPolygon from '@turf/boolean-point-in-polygon'
import * as d3 from 'd3'
import { AGGREGATION_TYPES } from 'constants/aggregation'
import {
  switchcaseF,
  getValidPropertyValues,
  isCountAggregation,
} from 'helpers/utils'
import { createFeatureCollectionFromGeojsonObj } from 'helpers/map'

export const getPolygonsArray = polygons => {
  const inputPolygons = _.get(polygons, 'polygon') || polygons
  const transformedPolygons =
    createFeatureCollectionFromGeojsonObj(inputPolygons)
  return transformedPolygons.features
}

export const assignPointsToPolygon = (polygons, applySpatialFilter) => {
  if (_.isEmpty(polygons)) return undefined
  return polygons.map(polygon => {
    const candidatePoints = applySpatialFilter(polygon.bbox)
    const foundPoints = candidatePoints.filter(point => {
      return booleanPointInPolygon(point, polygon)
    })
    return { ...polygon, features: foundPoints }
  })
}

/**
 * Returns the aggregated value in the given array
 * It's relied on the ds-array(https://github.com/d3/d3-array/blob/v1.2.4/README.md#statistics)
 * @param {Array} data
 * @param {String} aggregationType
 *
 * @return {number}
 */
export const getAggregationValues = (data, aggregationType) =>
  switchcaseF({
    [AGGREGATION_TYPES.mean]: () => d3.mean(data),
    [AGGREGATION_TYPES.average]: () => d3.mean(data),
    [AGGREGATION_TYPES.max]: () => d3.max(data),
    [AGGREGATION_TYPES.min]: () => d3.min(data),
    [AGGREGATION_TYPES.median]: () => d3.median(data),
    [AGGREGATION_TYPES.sum]: () => d3.sum(data),
  })(() => data.length)(aggregationType)

export const getAggregatedValue = (points, aggregation) => {
  const { key = '', type } = aggregation
  return isCountAggregation(type) || !key
    ? points.length
    : getAggregationValues(
        getValidPropertyValues(points, `properties.${key}`),
        type
      )
}

export default getAggregationValues
