// libraries
import { useState } from 'react'
import moment from 'moment-timezone'
import { useInterval } from 'react-use'

// utils
import { useTimezone } from 'hooks'
import { displayTime } from 'helpers/utils'
import { DEFAULT_DATE_TIME_FORMAT } from 'constants/datetime'

const useNow = ({
  timeFormat = DEFAULT_DATE_TIME_FORMAT,
  delay = 1000,
  enable = true,
}: {
  timeFormat?: string
  enable?: boolean
  delay?: number | null
} = {}): string | undefined => {
  const [now, setNow] = useState<string>()
  const { timezone } = useTimezone()

  useInterval(
    () => {
      const utcNow = moment().utc()
      setNow(
        displayTime({
          datetime: utcNow,
          timezone,
          timeFormat,
        })
      )
    },
    enable ? delay : null
  )

  return now
}

export default useNow
