import keymirror from 'keymirror'

import type { DetectionType } from 'app/MissionControlMethaneSolution/types/detection'
import { DETECTION_TYPES } from './common'

export const VFB_EVENT_TYPE = 'VFB'

export const EMISSION_OBSERVATION_TYPE_ICONS_MAPPING = {
  [DETECTION_TYPES.DETECTION_TYPE_CMS]: 'MethaneCMSIcon',
  [DETECTION_TYPES.DETECTION_TYPE_DRONE]: 'MethaneDroneIcon',
  [DETECTION_TYPES.DETECTION_TYPE_FLYOVER]: 'MethaneFlyOverIcon',
  [DETECTION_TYPES.DETECTION_TYPE_SATELLITE]: 'MethaneSatelliteIcon',
  [DETECTION_TYPES.DETECTION_TYPE_OGI]: 'MethaneOGIIcon',
  [DETECTION_TYPES.DETECTION_TYPE_OWD]: 'FaWalking',
  [DETECTION_TYPES.DETECTION_TYPE_MANUAL]: 'MethaneManualIcon',
  [DETECTION_TYPES.DETECTION_TYPE_UNKNOWN]: 'MethaneUnknownIcon',
  [VFB_EVENT_TYPE]: 'MethaneVFBIcon',
}

export const METHANE_OBSERVATIONS_ICONS_MAPPING_PUBLIC_URL = {
  [DETECTION_TYPES.DETECTION_TYPE_CMS]: '/assets/icons/methane/icon_CMS.svg',
  [DETECTION_TYPES.DETECTION_TYPE_DRONE]:
    '/assets/icons/methane/icon_drone.svg',
  [DETECTION_TYPES.DETECTION_TYPE_FLYOVER]:
    '/assets/icons/methane/icon_flyover.svg',
  [DETECTION_TYPES.DETECTION_TYPE_SATELLITE]:
    '/assets/icons/methane/icon_satellite.svg',
  [DETECTION_TYPES.DETECTION_TYPE_OGI]: '/assets/icons/methane/icon_OGI.svg',
  [DETECTION_TYPES.DETECTION_TYPE_OWD]:
    '/assets/icons/methane/walking-solid.svg',
  [DETECTION_TYPES.DETECTION_TYPE_MANUAL]:
    '/assets/icons/methane/icon_form.svg',
  [DETECTION_TYPES.DETECTION_TYPE_VENTING_FLARING_BLOWDOWN]:
    '/assets/icons/methane/icon_vfb.svg',
  [DETECTION_TYPES.DETECTION_TYPE_UNKNOWN]:
    '/assets/icons/methane/icon_unknown.svg',
}

export const EMISSION_OBSERVATION_TYPE_TOOLTIPS = {
  [DETECTION_TYPES.DETECTION_TYPE_CMS]: 'Continuous Monitoring System',
  [DETECTION_TYPES.DETECTION_TYPE_DRONE]: 'Drone',
  [DETECTION_TYPES.DETECTION_TYPE_OGI]: 'Optical Gas Imaging',
  [DETECTION_TYPES.DETECTION_TYPE_OWD]: 'On-site Walk Down',
  [DETECTION_TYPES.DETECTION_TYPE_FLYOVER]: 'Flyover',
  [DETECTION_TYPES.DETECTION_TYPE_SATELLITE]: 'Satellite',
  [DETECTION_TYPES.DETECTION_TYPE_MANUAL]: 'Manual',
  [DETECTION_TYPES.DETECTION_TYPE_UNKNOWN]: 'Unknown',
  [VFB_EVENT_TYPE]: 'VFB',
}

export const EMISSION_OBSERVATION_ATTRIBUTION_TYPES = keymirror({
  SITE: null,
  EQUIPMENT: null,
})

export const DETECTION_TYPES_TO_OMIT_ON_MAP: DetectionType[] = [
  DETECTION_TYPES.DETECTION_TYPE_OGI,
]
