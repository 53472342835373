import { ReactElement } from 'react'

import type { Widget } from 'types/asset'

// components
import { Page } from 'components/assets/assetsProfile/viewer/Printable'
import scss from './index.module.scss'

const BuilderPrintableLayout = ({
  configs,
  assetId,
}: {
  configs: Widget[]
  assetId: string
}): ReactElement => {
  return (
    <div className={scss.page}>
      <Page assetId={assetId} profileConfigs={configs} printFlag={false} />
    </div>
  )
}

export default BuilderPrintableLayout
