import keymirror from 'keymirror'

export const TIME_RANGE_MODES = keymirror({
  relativeTime: null,
  absoluteTime: null,
  now: null,
})

export const FILTER_CONDITIONS = keymirror({
  and: null,
  or: null,
})

export const PROPERTY_VARIABLE_TYPES = keymirror({
  string: null,
  number: null,
  boolean: null,
  array: null,
  object: null,
})

export const PROPERTY_VARIABLE_FORMATS = keymirror({
  pom: null,
  time: null,
  image: null,
  url: null,
})

export const NOT_ALLOWED_FILTER_PROPERTY_FORMAT = new Set([
  PROPERTY_VARIABLE_FORMATS.image,
  PROPERTY_VARIABLE_FORMATS.time,
  PROPERTY_VARIABLE_FORMATS.pom,
])

export const SUPPORTED_FILTER_PROPERTY_TYPES: string[] = [
  PROPERTY_VARIABLE_TYPES.string,
  PROPERTY_VARIABLE_TYPES.number,
  PROPERTY_VARIABLE_TYPES.boolean,
]

export const DEFAULT_INVISIBLE_PROPERTY_FORMATS = [
  PROPERTY_VARIABLE_FORMATS.pom,
]

export const OPERATORS = {
  contains: 'CONTAINS',
  doesNotContain: 'DOES NOT CONTAIN',
  equals: 'EQUALS',
  doesNotEqual: 'DOES NOT EQUAL',
  isSet: 'IS SET',
  // numeric specific
  isBetween: 'IS BETWEEN',
  isGreaterThan: 'IS GREATER THAN',
  isLessThan: 'IS LESS THAN',
  isGreaterThanOrEquals: 'IS GREATER THAN OR EQUALS',
  isLessThanOrEquals: 'IS LESS THAN OR EQUALS',
  // boolean specific
  isTrue: 'IS TRUE',
  isFalse: 'IS FALSE',
  // time specific
  isTimeBetween: 'IS TIME BETWEEN',
  isTimeEquals: 'IS TIME EQUAL',
  isBefore: 'IS BEFORE',
  isSameOrBefore: 'IS SAME OR BEFORE',
  isAfter: 'IS AFTER',
  isSameOrAfter: 'IS SAME OR AFTER',
  // stateful alerts
  becameGreaterThan: 'BECAME GREATER THAN',
  becameLessThan: 'BECAME LESS THAN',
} as const

export const BOOLEAN_OPERATORS = [
  { value: OPERATORS.isTrue, label: OPERATORS.isTrue },
  { value: OPERATORS.isFalse, label: OPERATORS.isFalse },
]

export const STRING_OPERATORS = [
  { value: OPERATORS.contains, label: OPERATORS.contains },
  {
    value: OPERATORS.doesNotContain,
    label: OPERATORS.doesNotContain,
  },
  { value: OPERATORS.equals, label: OPERATORS.equals },
  { value: OPERATORS.doesNotEqual, label: OPERATORS.doesNotEqual },
  { value: OPERATORS.isSet, label: OPERATORS.isSet },
]

export const NUMERIC_OPERATORS = [
  { value: OPERATORS.equals, label: OPERATORS.equals },
  { value: OPERATORS.doesNotEqual, label: OPERATORS.doesNotEqual },
  { value: OPERATORS.isBetween, label: OPERATORS.isBetween },
  { value: OPERATORS.isGreaterThan, label: OPERATORS.isGreaterThan },
  { value: OPERATORS.isLessThan, label: OPERATORS.isLessThan },
  {
    value: OPERATORS.isGreaterThanOrEquals,
    label: OPERATORS.isGreaterThanOrEquals,
  },
  {
    value: OPERATORS.isLessThanOrEquals,
    label: OPERATORS.isLessThanOrEquals,
  },
]

export const STATEFUL_NUMERIC_OPERATORS = [
  { value: OPERATORS.becameGreaterThan, label: OPERATORS.becameGreaterThan },
  { value: OPERATORS.becameLessThan, label: OPERATORS.becameLessThan },
]

export const DEFAULT_REPLAY_SPEED = {
  defaultValue: 3,
  min: 1,
  max: 10,
  step: 1,
}

export const DEFAULT_GLOBAL_TIME_FILTER_ID = 'globalTimeFilterDatetimeRange'

export const DEFAULT_DATA_AVAILABLE_BACKGROUND_ID =
  'dataAvailableRangeBackground'

export const ISSUE_FORM_FILTER_TYPE = 'form'

export const ASYNC_SELECT_FILTER_TYPE = 'asyncSelect'

export const DATE_RANGE_FILTER_VALUES = keymirror({
  today: null,
  last_seven_days: null,
  this_month: null,
  this_year: null,
  this_quarter: null,
  last_year_current_year: null,
  all: null,
})

export const DATE_RANGE_FILTER_POSSIBLE_VALUES: string[] = Object.values(
  DATE_RANGE_FILTER_VALUES
)

export const DATE_RANGE_FILTER_OPTIONS = [
  { value: DATE_RANGE_FILTER_VALUES.today, label: 'Today' },
  { value: DATE_RANGE_FILTER_VALUES.last_seven_days, label: 'Last 7 days' },
  { value: DATE_RANGE_FILTER_VALUES.this_month, label: 'This month' },
]
