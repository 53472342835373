// libraries
import { ReactElement } from 'react'

// utils
import { getActiveStatus } from 'helpers/utils'

// components
import { Card } from 'components/common'

import type { ListItemProps } from 'types/common'
import type { DataCollectionForm } from 'types/issue'

const FormListItem = ({
  item,
  itemActions,
}: ListItemProps<DataCollectionForm>): ReactElement => {
  const { isActive = false, secondaryName } = item

  return (
    <Card
      {...itemActions}
      item={item}
      enableAvatar={false}
      badgeText={getActiveStatus(isActive)}
      isBadgeActive={isActive}
      secondaryName={secondaryName}
    />
  )
}

export default FormListItem
