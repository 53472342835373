import { ReactElement, useEffect, useState } from 'react'
import { useAsync } from 'react-use'
import { Field, Form } from 'react-final-form'
import _ from 'lodash'
import type { ValidationErrors } from 'final-form'

// constants
import { BLADE_SIZES, STICKY_HEADER_Z_INDEX } from 'constants/common'
import keymirror from 'keymirror'

// constants
import { BUTTON_VARIANTS } from 'components/common/Button'
import { MODAL_SIZES, MODAL_TYPES } from 'constants/settings'

// components
import {
  Blade,
  BladeContent,
  BladeFooter,
  Loading,
  Modal,
  ModalContent,
} from 'components/common'
import { EnumeratedInput, Input, Textarea } from 'components/common/Form'

// utils
import { getFormListById } from 'services/api/form'
import log from 'helpers/log'
import { getEnumItems } from 'helpers/formBuilder'
import { validateRequiredKey } from 'helpers/validators'
import { validationEnums } from 'components/formBuilder/Editor/formValidators'

import type { FormList } from 'types/graphql'

import scss from './index.module.scss'

const FORM_LIST_SAVE_MODAL_MODE = keymirror({
  delete: null,
  cancel: null,
})

type FormListSaveModalMode = keyof typeof FORM_LIST_SAVE_MODAL_MODE | undefined

export type ListValues = FormList & {
  items: {
    enumNames: string[]
    enum: string[]
  }
}

const validateForm = (values: ListValues) => {
  const errors = {} as ValidationErrors

  validateRequiredKey('name', 'List name')(values, errors)

  const enumValues = _.get(values, 'items.enum')
  const enumLabels = _.get(values, 'items.enumNames')

  validationEnums({ enumValues, enumLabels, errors })

  return errors
}

const FormListPropertyPanelContent = ({
  id,
  onSave,
  onClose,
  currentSaveModalMode,
  setCurrentSaveModalMode,
}: {
  id: string
  onSave: (v: ListValues) => void
  onClose: () => void
  currentSaveModalMode: FormListSaveModalMode
  setCurrentSaveModalMode: (v: FormListSaveModalMode) => void
}) => {
  const formListState = useAsync(async () => {
    if (!id) {
      log.error('Invalid form list id provided.', id)
      return undefined
    }
    const result = await getFormListById(id)
    if (!result) return undefined
    const { items, ...rest } = result

    return { ...rest, items: getEnumItems(items) }
  }, [id])

  const { value, loading } = formListState

  const renderSaveModal = (values: ListValues) => {
    const FORM_LIST_SAVE_MODAL_CONFIGS = {
      [FORM_LIST_SAVE_MODAL_MODE.delete]: {
        title: 'Delete list',
        content: 'Deleting this list cannot be undone',
        submitContent: 'DELETE',
        onSubmit: () => onSave(values),
        onCancel: onClose,
      },
      [FORM_LIST_SAVE_MODAL_MODE.cancel]: {
        title: 'Unsaved list',
        content: 'Do you want to save before leaving?',
        submitContent: `DON'T SAVE`,
        cancelContent: 'SAVE',
        onSubmit: onClose,
        onCancel: () => onSave(values),
      },
    }

    const { title, ...rest } = currentSaveModalMode
      ? FORM_LIST_SAVE_MODAL_CONFIGS[currentSaveModalMode]
      : {}

    return (
      <Modal
        key={`${id}-${currentSaveModalMode}`}
        isShowing={!!currentSaveModalMode}
        hide={() => setCurrentSaveModalMode(undefined)}
        size={MODAL_SIZES.small}
        title={title}
        modalType={MODAL_TYPES.warning}
        icon='AiFillWarning'
      >
        <ModalContent {...rest} modalType={MODAL_TYPES.warning} />
      </Modal>
    )
  }

  return loading ? (
    <Loading />
  ) : (
    <Form
      onSubmit={onSave}
      initialValues={value}
      validate={validateForm}
      render={({ handleSubmit, submitting, pristine, values }) => {
        return (
          <>
            <BladeContent>
              <form onSubmit={handleSubmit}>
                <div className={scss.row}>
                  <Field<string>
                    component={Input}
                    name='name'
                    label='Label'
                    required
                  />
                </div>
                <div className={scss.row}>
                  <Field<string>
                    component={Textarea}
                    name='description'
                    label='Description'
                  />
                </div>
                <hr />
                <Field component={EnumeratedInput} name='items' required />
              </form>
            </BladeContent>
            <BladeFooter
              buttons={[
                // not implemented yet
                // { id: 'delete', children: 'Delete' },
                {
                  id: 'cancel',
                  children: 'CANCEL',
                  variant: BUTTON_VARIANTS.link,
                  onClick: () => {
                    if (pristine) {
                      onClose()
                    } else {
                      setCurrentSaveModalMode(FORM_LIST_SAVE_MODAL_MODE.cancel)
                    }
                  },
                },
                {
                  id: 'save',
                  children: 'SAVE & CLOSE',
                  variant: BUTTON_VARIANTS.primary,
                  onClick: () => onSave(values),
                  isLoading: submitting,
                  disabled: pristine,
                },
              ]}
            />
            {!!currentSaveModalMode && renderSaveModal(values)}
          </>
        )
      }}
    />
  )
}

const FormListPropertyPanel = ({
  selectedFormListId: formListId,
  className,
  onClose,
  onSave,
}: {
  selectedFormListId?: string
  className?: string
  onClose: () => void
  onSave: (v: ListValues) => Promise<void>
}): ReactElement => {
  const [currentSaveModalMode, setCurrentSaveModalMode] =
    useState<FormListSaveModalMode>()

  useEffect(() => {
    if (!formListId) {
      setCurrentSaveModalMode(undefined)
    }
  }, [formListId])

  return (
    <Blade
      className={className}
      show={!!formListId}
      enableScrollableContent={false}
      customFooter={null}
      size={BLADE_SIZES.large}
      onClose={onClose}
      title='Static List'
      zIndex={STICKY_HEADER_Z_INDEX + 1}
    >
      {formListId && (
        <FormListPropertyPanelContent
          id={formListId}
          currentSaveModalMode={currentSaveModalMode}
          setCurrentSaveModalMode={setCurrentSaveModalMode}
          onClose={onClose}
          onSave={onSave}
        />
      )}
    </Blade>
  )
}

export default FormListPropertyPanel
