import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  ReactElement,
  useRef,
  KeyboardEvent,
} from 'react'
import { SuggestionProps } from '@tiptap/suggestion'
import type { SelectInstance } from 'react-select'

import { MultiSelect } from 'components/common'

import { MultiSelectProp, SelectOption } from 'components/common/MultiSelect'
import type { PropertyMetadata } from 'types/common'

import scss from './index.module.scss'

export const MentionList = forwardRef(
  (
    {
      items,
      command,
      formatOptionLabel,
    }: SuggestionProps<PropertyMetadata> & {
      formatOptionLabel: (item: SelectOption) => ReactElement
    },
    ref
  ): ReactElement => {
    const selectRef = useRef<SelectInstance>()

    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }: { event: KeyboardEvent<HTMLDivElement> }) => {
        // Listen to the particular events and override the editor's handler
        if (['ArrowUp', 'ArrowDown', 'Enter'].includes(event.key)) {
          selectRef.current?.onKeyDown(event)
          return true
        }

        return false
      },
    }))

    useEffect(() => {
      selectRef.current?.menuListRef?.focus()
    }, [])

    return (
      <div className={scss.dropdown}>
        <MultiSelect
          value={[]}
          ref={selectRef}
          onChange={selectedItem => {
            command(selectedItem as PropertyMetadata)
          }}
          options={items}
          formatOptionLabel={
            formatOptionLabel as MultiSelectProp['formatOptionLabel']
          }
          removeControl
          isSearchable={false}
          menuIsOpen
          dropdownOnly
          isMulti={false}
          isClearable={false}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          backspaceRemovesValue={false}
          tabSelectsValue={false}
        />
      </div>
    )
  }
)
