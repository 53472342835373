import {
  useState,
  useContext,
  useCallback,
  createContext,
  ReactElement,
} from 'react'
import { Ability } from '@casl/ability'
import _ from 'lodash'
import { buildAbilityFor } from 'helpers/ability'
import { ACTION_TYPES } from 'constants/user'
import type { Subject } from 'types/entity'

export type AbilityContextValue = {
  ability: Ability
  getValidSubject: (subject: Subject) => Subject & { group?: string }
  checkAbility: (type: keyof typeof ACTION_TYPES, subject: Subject) => boolean
}

export const getValidSubject = (
  subject: Subject
): Subject & { group?: string } => {
  const { group, user } = _.get(subject, 'owner') || {}
  return { ...subject, ...user, group }
}

export const AbilityContext = createContext<AbilityContextValue>({
  getValidSubject,
} as AbilityContextValue)

export const AbilityProvider = ({
  ability,
  children,
}: {
  ability?: Ability
  children: ReactElement
}): ReactElement => {
  const [abilityValue] = useState<Ability>(() => ability || buildAbilityFor())

  const checkAbility = useCallback(
    (type: keyof typeof ACTION_TYPES, subject: Subject): boolean => {
      const newSubject = getValidSubject(subject)
      return abilityValue.can(type, newSubject)
    },
    [abilityValue]
  )

  return (
    <AbilityContext.Provider
      value={{ ability: abilityValue, checkAbility, getValidSubject }}
    >
      {children}
    </AbilityContext.Provider>
  )
}

export const useAbilityStateValue = (): AbilityContextValue =>
  useContext(AbilityContext)
