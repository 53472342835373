import React, { useRef, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useEcharts } from 'hooks'

// constants
import { THEMES } from 'constants/colour'
import {
  DEFAULT_WIDGET_COLOUR_PALETTE,
  DEFAULT_CHART_Y_AXIS_RANGE,
  DEFAULT_CHART_GAUGE_TITLE_SIZE,
  DEFAULT_DARK_THEME_CHART_TEXT_COLOUR,
  DEFAULT_DARK_THEME_CHART_SECONDARY_DARK_COLOUR,
  DEFAULT_LIGHT_THEME_CHART_TEXT_COLOUR,
  DEFAULT_LIGHT_THEME_CHART_SECONDARY_DARK_COLOUR,
} from 'constants/widget'

// utils
import { displayValue, isRangeValid } from 'helpers/utils'
import { isLightTheme } from 'helpers/colour'

const GaugeWidget = ({
  value,
  label,
  yAxisRange,
  colour,
  title,
  size,
  theme,
  ...rest
}) => {
  const chartRef = useRef()

  const lightTheme = useMemo(() => isLightTheme(theme), [theme])

  const range = useMemo(
    () =>
      isRangeValid(yAxisRange, false) ? yAxisRange : DEFAULT_CHART_Y_AXIS_RANGE,
    [yAxisRange]
  )

  const getOptions = useCallback(
    ({ expanded, width, height } = {}) => {
      const autoSize = (ratio = 9) => {
        return Math.min(
          Math.min(width, height) / ratio,
          DEFAULT_CHART_GAUGE_TITLE_SIZE
        )
      }

      const series = [
        {
          type: 'gauge',
          startAngle: 180,
          endAngle: 0,
          min: range[0],
          max: range[1],
          radius: expanded ? '90%' : '140%',
          center: ['50%', '75%'],
          splitNumber: 1,
          itemStyle: {
            color: colour,
          },
          progress: {
            show: true,
            width: 18,
          },
          pointer: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              width: 18,
            },
          },
          axisTick: {
            lineStyle: {
              width: 0,
              color: lightTheme
                ? DEFAULT_LIGHT_THEME_CHART_SECONDARY_DARK_COLOUR
                : DEFAULT_DARK_THEME_CHART_SECONDARY_DARK_COLOUR,
            },
          },
          splitLine: {
            length: 0,
            lineStyle: {
              width: 0,
              color: lightTheme
                ? DEFAULT_LIGHT_THEME_CHART_SECONDARY_DARK_COLOUR
                : DEFAULT_DARK_THEME_CHART_SECONDARY_DARK_COLOUR,
            },
          },
          axisLabel: {
            distance: 15,
            color: lightTheme
              ? DEFAULT_LIGHT_THEME_CHART_SECONDARY_DARK_COLOUR
              : DEFAULT_DARK_THEME_CHART_SECONDARY_DARK_COLOUR,
            offsetCenter: [0, 20],
          },
          title: {
            show: false,
          },
          detail: {
            fontSize: autoSize(6),
            color: lightTheme ? DEFAULT_LIGHT_THEME_CHART_TEXT_COLOUR : '#fff',
            offsetCenter: [0, '-20%'],
            valueAnimation: true,
          },
          data: [
            {
              value: displayValue(value),
            },
          ],
        },
      ]

      return {
        series,
        title: expanded && {
          show: false,
          text: title,
          color: lightTheme
            ? DEFAULT_LIGHT_THEME_CHART_TEXT_COLOUR
            : DEFAULT_DARK_THEME_CHART_TEXT_COLOUR,
          left: 'center',
        },
      }
    },
    [colour, lightTheme, range, title, value]
  )

  const { style } = useEcharts({
    ...rest,
    chartRef,
    getOptions,
    size,
  })

  return <div ref={chartRef} style={style} />
}

GaugeWidget.propTypes = {
  value: PropTypes.number.isRequired,
  colour: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.string,
  ]),
  yAxisRange: PropTypes.arrayOf(PropTypes.number),
  label: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
  theme: PropTypes.oneOf([THEMES.dark, THEMES.light]),
}

GaugeWidget.defaultProps = {
  colour: DEFAULT_WIDGET_COLOUR_PALETTE,
  yAxisRange: [],
  title: '',
  label: '',
  size: 'regular',
  theme: THEMES.dark,
}

export default GaugeWidget
