import { ReactElement } from 'react'
import { FieldTemplateProps } from '@rjsf/utils'

import { isJsonFormWidgetHidden } from 'helpers/formBuilder'

import { JSONFormSchema, JSONFormUiSchema } from 'types/formBuilder'
import { JSON_DATA_TYPES } from 'constants/formBuilder'
import WrapIfAdditional from './WrapIfAdditional'

const getPaddingClass = (
  schema: JSONFormSchema,
  uiSchema: JSONFormUiSchema = {}
) => {
  // Avoid adding padding to a root container
  if (schema.type === JSON_DATA_TYPES.object && uiSchema['ui:order']) return ''
  return 'px-3'
}

const FieldTemplate = ({
  id,
  children,
  classNames,
  disabled,
  hidden,
  label,
  onDropPropertyClick,
  onKeyChange,
  readonly,
  required,
  schema,
  uiSchema,
}: FieldTemplateProps): ReactElement => {
  const isHidden = isJsonFormWidgetHidden(uiSchema) || hidden
  const { disablePadding } = uiSchema || {}
  const paddingClassName = getPaddingClass(schema, uiSchema)

  if (isHidden) {
    return children
  }

  return (
    <WrapIfAdditional
      classNames={classNames}
      disabled={disabled}
      id={id}
      label={label}
      onDropPropertyClick={onDropPropertyClick}
      onKeyChange={onKeyChange}
      readonly={readonly}
      required={required}
      schema={schema}
    >
      <div className={disablePadding ? 'mb-2' : `mb-4 ${paddingClassName}`}>
        {children}
      </div>
    </WrapIfAdditional>
  )
}

export default FieldTemplate
