// libraries
import _ from 'lodash'

// constants
import { LAYER_TYPES } from 'constants/map'
import { LAYER_VIS_CONFIGS } from 'components/map/layers/deckLayers/layerFactory'

// utils
import { getClusteringLayer } from 'components/map/layers/deckLayers/utils'

// components
import Layer from '../baseLayer'
import MultipleLabelsScatterplotLayer from './multipleLabelsScatterplotLayer'

export const pointVisConfigs = {
  radius: 'radius',
  radiusRange: 'radiusRange',
  opacity: 'opacity',
  colourClasses: 'colourClasses',
  colourProperty: 'colourProperty',
  colourType: 'colourType',
  radiusProperty: 'radiusProperty',
  propertyValueRange: 'propertyValueRange',
  aggregationType: 'aggregationType',
  aggregationProperty: 'aggregationProperty',
  arePointsAggregated: 'arePointsAggregated',
  elevationExcluded: 'elevationExcluded',
  propertyLabels: 'propertyLabels',
  enableClustering: 'enableClustering',
}

export default class PointLayer extends Layer {
  constructor(props) {
    super(props)

    this.registerVisConfig(pointVisConfigs, props.style[this.type])
  }

  get type() {
    return LAYER_TYPES.point
  }

  get isAggregated() {
    const {
      visConfig: { arePointsAggregated },
    } = this.config
    return arePointsAggregated
  }

  renderLayer = ({
    layerData,
    profileHandler,
    highlightedObjectIndex,
    selectedDateTimeRange,
    timezone,
    currentZoom,
  }) => {
    const {
      visConfig: {
        radius,
        opacity,
        radiusProperty,
        arePointsAggregated,
        colourType,
        elevationExcluded,
        propertyLabels,
        enableClustering,
      },
      fillColour,
    } = this.config

    const useAggregatedData = !!(arePointsAggregated && !_.isEmpty(layerData))

    const basicProps = this.getLayerBasicProps({ layerData })

    const [radiusMinPixels, radiusMaxPixels] =
      LAYER_VIS_CONFIGS.radius.pixelRange

    const stylingProps = {
      opacity: this.getOpacity(opacity),
      autoHighlight: true,
      radiusScale: 5,
      ...this.getHighlightedObjectIndex(highlightedObjectIndex),
      getTextSize: 1,
      radiusMinPixels: radiusProperty ? radiusMinPixels : radius,
      radiusMaxPixels: radiusProperty ? radiusMaxPixels : radius,
    }

    const profileProps = this.getProfileConfig(profileHandler)
    const isSimpleFillColour = this.isSimpleColourType(colourType)

    const dataAccessors = {
      getPosition: d => this.getPosition(d, elevationExcluded),
      getRadius: radiusProperty ? d => d.radius : radius,
      getFillColor: isSimpleFillColour ? fillColour : d => d.fillColour,
      updateTriggers: {
        getPosition: [elevationExcluded],
      },
    }

    const firstDataTime = this.getTimeFromFirstData(layerData)

    const props = {
      ...basicProps,
      ...stylingProps,
      ...profileProps,
      ...dataAccessors,
      ...this.getTimeFilterProps(
        useAggregatedData ? null : selectedDateTimeRange,
        firstDataTime
      ),
      timezone,
      currentZoom,
      propertyLabels,
    }

    return [
      enableClustering && !arePointsAggregated
        ? getClusteringLayer(
            props,
            this.identityProperty,
            this.profileTitle,
            isSimpleFillColour,
            fillColour
          )
        : new MultipleLabelsScatterplotLayer(props),
    ]
  }
}
