// libraries
import { memo, useMemo, ReactElement, ReactNode } from 'react'

// constants
import { THEMES } from 'constants/colour'

// utils
import { stopEventDefaultAndPropagation } from 'helpers/utils'

// components
import { IconButton } from 'components/common'

import scss from './index.module.scss'

const iconSize = 16

const BuilderGridLayoutItem = memo(
  ({
    children,
    onSelectWidget,
    onRemoveWidget,
    onCloneWidget,
    id,
    theme = THEMES.dark,
    isLayoutWidget = false,
    isPrintable = false,
  }: {
    children: ReactNode
    onSelectWidget?: (wId?: string) => void
    onRemoveWidget?: () => void
    onCloneWidget?: (wId: string) => void
    id: string
    theme?: string
    isLayoutWidget?: boolean
    isPrintable?: boolean
  }): ReactElement => {
    const isLightTheme = useMemo(() => theme === THEMES.light, [theme])

    const widgetStyle = useMemo(
      () => ({
        ...(isLayoutWidget
          ? {}
          : isLightTheme
          ? isPrintable
            ? { backgroundColor: 'white' }
            : { border: '4px solid #eee', backgroundColor: 'white' }
          : { backgroundColor: 'rgba(255, 255, 255, 0.07)' }),
      }),
      [isLayoutWidget, isLightTheme, isPrintable]
    )

    return (
      <div className={`${scss.widget} defaultText`} style={widgetStyle}>
        <div className={`${!isPrintable && 'd-flex flex-column'} h-100`}>
          {children}
        </div>
        <div className={`nonDraggable ${scss.toolbox}`}>
          {onCloneWidget && (
            <IconButton
              icon='AiFillCopy'
              onClick={e => {
                stopEventDefaultAndPropagation(e)
                onCloneWidget(id)
              }}
              size={iconSize}
            />
          )}
          {onSelectWidget && (
            <IconButton
              icon='AiFillEdit'
              onClick={e => {
                stopEventDefaultAndPropagation(e)
                onSelectWidget(id)
              }}
              size={iconSize}
            />
          )}
          {onRemoveWidget && (
            <IconButton
              icon='AiOutlineClose'
              onClick={e => {
                stopEventDefaultAndPropagation(e)
                if (onSelectWidget) {
                  onSelectWidget()
                }
                onRemoveWidget()
              }}
              size={iconSize}
            />
          )}
        </div>
      </div>
    )
  }
)

export default BuilderGridLayoutItem
