// libraries
import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import isEqual from 'fast-deep-equal'

// constants
import {
  PROPERTY_VARIABLE_TYPES,
  PROPERTY_VARIABLE_FORMATS,
} from 'constants/filter'

// component
import { MultiSelect } from 'components/common'
import * as Icons from 'components/icons'

// utils
import {
  toLowerCase,
  isStringValueEqual,
  getConnectedStringFromArray,
} from 'helpers/utils'

// scss
import scss from './index.module.scss'

const getTypeFormatKey = (type: string, format: string) =>
  _.compact([type, format]).join('-')

const TYPE_FORMAT_ICON_MAPPINGS = {
  [PROPERTY_VARIABLE_TYPES.string]: 'StringIcon',
  [getTypeFormatKey(
    PROPERTY_VARIABLE_TYPES.string,
    PROPERTY_VARIABLE_FORMATS.time
  )]: 'StringIcon',
  [getTypeFormatKey(
    PROPERTY_VARIABLE_TYPES.string,
    PROPERTY_VARIABLE_FORMATS.image
  )]: 'StringIcon',
  [getTypeFormatKey(
    PROPERTY_VARIABLE_TYPES.string,
    PROPERTY_VARIABLE_FORMATS.url
  )]: 'StringIcon',
  [PROPERTY_VARIABLE_TYPES.number]: 'NumberIcon',
  [PROPERTY_VARIABLE_TYPES.boolean]: 'BooleanIcon',
  [PROPERTY_VARIABLE_TYPES.array]: 'ArrayIcon',
  [PROPERTY_VARIABLE_TYPES.object]: 'ObjectIcon',
}

export const getPropertyOptionLabel = ({ format, type, label, dataset }) => {
  const filterType = toLowerCase(type)
  const key = getTypeFormatKey(filterType, format)
  const Icon = Icons[TYPE_FORMAT_ICON_MAPPINGS[key]]
  const icon = Icon ? <Icon /> : '🧐'

  return (
    <div style={{ alignItems: 'center', display: 'flex' }} key={label}>
      <span className={scss.optionIcon}>{icon}</span>
      <span className={scss.optionLabel}>{label}</span>
      {dataset && <span className='optionGroup'>({dataset})</span>}
    </div>
  )
}

/**
 * Generate a properties list with numeric properties
 * that will be used for aggregation key
 * @param {Array} keys: property list
 * @param {Array} numeric property list
 */
const getPropertyOptionsByType = (properties, types) => {
  if (_.isEmpty(properties) || _.isEmpty(types)) return properties

  return _.filter(properties, ({ type }) =>
    _.some(types, t => isStringValueEqual(t, type))
  )
}

const PropertyPicker = ({
  property,
  propertyOptions,
  propertyTypes,
  onValidOptionsChange,
  onChange,
  group,
  useOptionValueOnly,
  maxOptionsLength,
  ...rest
}) => {
  const [value, setValue] = useState(property)
  const [options, setOptions] = useState([])

  const onChangeHandler = useCallback(
    newOption => {
      const newValue = useOptionValueOnly
        ? newOption
        : _.isArray(newOption)
        ? _.map(newOption, 'value')
        : _.get(newOption, 'value')

      setValue(newValue)
      onChange(useOptionValueOnly ? newOption || null : newOption || {})
    },
    [onChange, useOptionValueOnly]
  )

  useEffect(() => {
    if (!isEqual(property, value)) {
      setValue(property)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property])

  useEffect(() => {
    const sortedOption = _.sortBy(propertyOptions, ['type', 'label'])
    const filteredOptions = _.isEmpty(propertyTypes)
      ? sortedOption
      : getPropertyOptionsByType(sortedOption, propertyTypes)
    setOptions(filteredOptions)

    if (onValidOptionsChange && !isEqual(filteredOptions, sortedOption)) {
      onValidOptionsChange(filteredOptions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyOptions])

  return _.isEmpty(options) ? (
    <span className='message'>
      Only {getConnectedStringFromArray(propertyTypes)} properties are accepted
      and there is no available properties options for that.
    </span>
  ) : (
    <MultiSelect
      formatOptionLabel={getPropertyOptionLabel}
      placeholder='Select ...'
      onChange={onChangeHandler}
      value={value}
      options={options}
      group={group}
      noDataMessage='No valid property'
      useOptionValueOnly={useOptionValueOnly}
      maxOptionsLength={maxOptionsLength}
      {...rest}
    />
  )
}

PropertyPicker.propTypes = {
  property: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.shape({}),
  ]),
  propertyOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  propertyTypes: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  onValidOptionsChange: PropTypes.func,
  group: PropTypes.string,
  useOptionValueOnly: PropTypes.bool,
  maxOptionsLength: PropTypes.number,
}

PropertyPicker.defaultProps = {
  propertyTypes: [
    PROPERTY_VARIABLE_TYPES.string,
    PROPERTY_VARIABLE_TYPES.number,
    PROPERTY_VARIABLE_TYPES.boolean,
  ],
  property: undefined,
  onValidOptionsChange: undefined,
  group: undefined,
  useOptionValueOnly: false,
  maxOptionsLength: Infinity,
}

export default PropertyPicker
