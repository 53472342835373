// libraries
import { useState, useEffect, ReactElement } from 'react'
import { useMount } from 'react-use'
import { featureCollection } from '@turf/helpers'
import _ from 'lodash'

// components
import { Map } from 'components/common'
import { MapBaseMapStyleSwitcher } from 'components/map/controls'
import { useMapViewState, useMapProfileHandler } from 'components/map/hooks'

// utils
import { reportException } from 'helpers/log'
import { useMapStateValue } from 'contexts'
import { getBounds } from 'helpers/map'
import { getNewAssetLayer } from 'helpers/layer'
import { useTimezone } from 'hooks'
import { generateAssetDeckLayer } from 'helpers/asset'

import type { Asset } from 'types/asset'
import type { MapLayerStyle } from 'types/map'
import scss from './index.module.scss'

type PreviewMapProp = {
  assets: Asset[]
  mapStyle: MapLayerStyle
}

const PreviewMap = ({ assets, mapStyle }: PreviewMapProp): ReactElement => {
  const { map, mapRef, mapCanvasRef } = useMapStateValue()
  const { timezone } = useTimezone()
  const [assetDeckLayerList, setAssetDeckLayerList] = useState([])

  const { profileHandler, renderMapProfiles } = useMapProfileHandler({
    mapRef,
  })

  const {
    viewState,
    currentZoom,
    onViewStateChange,
    fitFeaturesBound,
    setMapLoaded,
  } = useMapViewState({
    mapRef,
    mapCanvasRef,
  })

  useMount(() => {
    if (_.isEmpty(assets)) return

    const mapBounds = getBounds(featureCollection(assets))
    if (_.isEmpty(mapBounds)) return

    fitFeaturesBound(assets, false)
  })

  useEffect(() => {
    const assetLayer = getNewAssetLayer()
    const mapLayer = _.merge({}, assetLayer, mapStyle)
    const assetDeckLayer = generateAssetDeckLayer({
      mapLayer,
      layerData: assets,
      profileHandler,
      timezone,
      currentZoom,
    })
    setAssetDeckLayerList([assetDeckLayer])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapStyle, currentZoom])

  return (
    <Map
      map={map}
      mapRef={mapRef}
      mapCanvasRef={mapCanvasRef}
      layersList={assetDeckLayerList}
      viewState={viewState}
      onViewStateChange={onViewStateChange}
      onLoad={() => setMapLoaded(true)}
      onError={reportException}
      className='position-relative'
    >
      <MapBaseMapStyleSwitcher className={scss.baseMap} />
      {renderMapProfiles()}
    </Map>
  )
}

export default PreviewMap
