// libraries
import { ReactElement } from 'react'

// utils
import { useNow } from 'hooks'

// icon
import { FaCircle } from 'react-icons/fa'

// styles
import scss from './index.module.scss'

const LiveTimeline = (): ReactElement => {
  const now = useNow()

  return (
    <div className={scss.liveBar}>
      <div className={scss.text}>
        <FaCircle className={scss.blink} size={12} /> LIVE
        <span className='ms-1'>{now}</span>
      </div>
    </div>
  )
}

export default LiveTimeline
