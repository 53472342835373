import { PropsWithChildren, ReactElement } from 'react'

import scss from './index.module.scss'

const MainContentLayout = ({
  background = true,
  children,
}: PropsWithChildren<{
  background: boolean
}>): ReactElement => (
  <main
    role='main'
    className={`${scss.content} ${background && scss.background}`}
    id='main'
  >
    {children}
  </main>
)

export default MainContentLayout
