import React from 'react'
import PropTypes from 'prop-types'

import scss from './index.module.scss'

const Navbar = ({ className, children }) => (
  <nav className={`${className} ${scss.navbar}`}>{children}</nav>
)

Navbar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

Navbar.defaultProps = {
  className: '',
}

export default Navbar
