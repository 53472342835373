import { PropsWithChildren, ReactElement } from 'react'

// utils
import { useMapStateValue } from 'contexts'

// component
import { getNewLayerStyle } from 'helpers/map'

import { ThemeType } from 'types/common'
import { THEMES } from 'constants/colour'
import type { MapLayerStyle, MapLayerType } from 'types/map'

import scss from './index.module.scss'
import {
  StyledClusteringToggle,
  StyledVisibilityToggle,
  TitleWrapper,
} from './styles'

const LegendTitle = ({
  id,
  canToggleVisibility = false,
  canToggleClustering = false,
  isVisible,
  enableClustering,
  title,
  action,
  children,
  type,
  style,
  mapTheme,
}: PropsWithChildren<{
  id: string
  canToggleVisibility: boolean
  canToggleClustering: boolean
  isVisible: boolean
  enableClustering: boolean
  title: string
  type: MapLayerType
  action?: ReactElement
  style: MapLayerStyle
  mapTheme: ThemeType
}>): ReactElement => {
  const { updateLayerConfigs } = useMapStateValue()
  const isLightTheme = mapTheme === THEMES.light

  return (
    <div className='my-1 d-flex justify-content-between align-items-center'>
      <TitleWrapper
        isInvisible={!isVisible}
        className='d-flex align-items-start'
      >
        {children && (
          <div className='me-2' style={{ width: '16px' }}>
            {children}
          </div>
        )}
        <span className={`${scss.title} fw-semibold text-capitalize`}>
          {title}
        </span>
      </TitleWrapper>
      <div className='d-flex align-items-center'>
        {canToggleClustering && (
          <StyledClusteringToggle
            isActive={enableClustering}
            isLightTheme={isLightTheme}
            icon={enableClustering ? 'IconClusteringOn' : 'IconClusteringOff'}
            height={11}
            onClick={() => {
              updateLayerConfigs(
                id,
                getNewLayerStyle(style, type, {
                  enableClustering: !enableClustering,
                })
              )
            }}
            label={enableClustering ? 'Clustering Off' : 'Clustering On'}
          />
        )}
        {canToggleVisibility && (
          <StyledVisibilityToggle
            isActive={!isVisible}
            isLightTheme={isLightTheme}
            isVisible={isVisible}
            onChange={() => updateLayerConfigs(id, { isVisible: !isVisible })}
          />
        )}
        {action}
      </div>
    </div>
  )
}

export default LegendTitle
