// libraries
import { useCallback, useMemo } from 'react'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import to from 'await-to-js'

// utils
import { useFetchList, useProcessList } from 'components/common/List'
import { useCreateEntity, useCurrentUser, useIsFavoriteField } from 'hooks'
import { createFormList, getAllFormLists } from 'services/api/form'
import { showCrudResponseMessage } from 'helpers/message'

import type { Payload, UseListProps, UseListState } from 'types/common'
import type { FormList, FormLists } from 'types/formBuilder'

// constants
import { MESSAGE_STATUS } from 'constants/message'
import { IS_FAVORITE_KEY } from 'constants/common'

// components
import { ListErrorHandler, Loading } from 'components/common'
import { FormGalleryView } from 'routers/pages/FormGallery/constants'
import { CardActions } from 'components/common/List/Card/CardOptions'

import useItemActions from './useItemActions'
import FormListsSection from './FormListsSection'
import { PAGE_OPTIONS } from './constants'

const { entityLabel } = PAGE_OPTIONS

const useList = ({
  listConditions,
  filters,
  selectedFormListId,
  setSelectedFormListId,
}: UseListProps<FormList> & {
  selectedView: FormGalleryView
  selectedFormListId?: string
  setSelectedFormListId: (v?: string) => void
}): UseListState<FormList> => {
  const { toggleCreateEntityModal, renderCreateEntityModal } = useCreateEntity()
  const {
    currentUser: {
      preferences: { favoriteFormLists = [] },
    },
  } = useCurrentUser()

  const fetchListState = useFetchList({
    listFn: getAllFormLists,
    filters,
  })

  const { list, setList, listState, onChange } = fetchListState
  const { error, loading } = listState

  const enhancedList = useIsFavoriteField(list, favoriteFormLists)

  const processedList = useProcessList({
    list: enhancedList,
    conditions: listConditions,
  })

  // Prepare 2 lists – 'favorites' and the regular one
  const { starred, other } = useMemo(
    () =>
      processedList.reduce<{ starred: FormList[]; other: FormList[] }>(
        (acc, item) => {
          acc[item[IS_FAVORITE_KEY] ? 'starred' : 'other'].push(item)
          return acc
        },
        { starred: [], other: [] }
      ),
    [processedList]
  )

  const onFormListSubmit = useCallback(
    async (params: Payload) => {
      const formListId = uuidv4()

      // owner not supported yet
      const payload = _.omit(params, 'owner')

      const [err, newFormList] = await to(createFormList(formListId, payload))

      showCrudResponseMessage({
        entity: entityLabel,
        subject: { name: 'New' },
        status: MESSAGE_STATUS.created,
        error: _.isEmpty(newFormList) || err ? err : undefined,
      })

      if (_.isEmpty(newFormList)) return

      setList(
        (oldLists: FormLists): FormLists => [
          newFormList as FormList,
          ...oldLists,
        ]
      )
      setSelectedFormListId(formListId)
    },
    [setList, setSelectedFormListId]
  )

  const itemActions = useItemActions({
    onChange,
    setSelectedFormListId,
  }) as CardActions<FormList>

  const commonListProps = {
    itemActions,
    selectedFormListId,
    setSelectedFormListId,
  }

  const hasStarred = !!starred.length

  const renderContent = () => {
    return (
      <>
        <ListErrorHandler error={error}>
          {loading ? (
            <Loading />
          ) : (
            <>
              {hasStarred && (
                <FormListsSection
                  title='Starred'
                  list={starred}
                  {...commonListProps}
                />
              )}

              <div {...(hasStarred && { className: 'mt-5' })}>
                <FormListsSection
                  title='Lists'
                  list={other}
                  {...commonListProps}
                />
              </div>
            </>
          )}
        </ListErrorHandler>

        {renderCreateEntityModal({
          entity: entityLabel,
          onSubmit: onFormListSubmit,
          // https://sensorup.atlassian.net/browse/SPR-7572
          // 'description'
          fields: ['name', 'description'],
        })}
      </>
    )
  }

  return {
    ...fetchListState,
    renderContent,
    toggleCreateEntityModal,
    list: processedList,
  }
}

export default useList
