import { ComponentType } from 'react'

const PAGE_FORCE_REFRESHED_KEY = 'page-has-been-force-refreshed'

// https://www.codemzy.com/blog/fix-chunkloaderror-react
export const lazyWithRetry = (
  componentImport: () => Promise<{ default: ComponentType<unknown> }>
): Promise<{
  default: ComponentType<unknown>
}> => {
  return new Promise((resolve, reject) => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.sessionStorage.getItem(PAGE_FORCE_REFRESHED_KEY) || 'false'
    )
    componentImport()
      .then(component => {
        window.sessionStorage.setItem(PAGE_FORCE_REFRESHED_KEY, 'false')
        resolve(component)
      })
      .catch((error: Error) => {
        if (!pageHasAlreadyBeenForceRefreshed) {
          // Assuming that the user is not on the latest version of the application.
          window.sessionStorage.setItem(PAGE_FORCE_REFRESHED_KEY, 'true') // we are
          window.location.reload()
        }
        // Assuming that user is already using the latest version of the application.
        reject(error)
      })
  })
}
