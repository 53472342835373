import _ from 'lodash'
import { initialState } from '../state/initialStates'
import unipipeReducer from './unipipe'
import workflowReducer from './workflow'
import userReducer from './user'
import ancillaryDataReducer from './ancillaryData'
import mapReducer from './map'
import siteReducer from './site'
import uiReducer from './ui'

import { RESET_LOCAL_STATE } from '../actions'

const reducer = (state = initialState, action) => {
  const allState = {
    unipipeState: unipipeReducer(state.unipipeState, action),
    workflowState: workflowReducer(state.workflowState, action),
    userState: userReducer(state.userState, action),
    ancillaryDataState: ancillaryDataReducer(state.ancillaryDataState, action),
    mapState: mapReducer(state.mapState, action),
    siteState: siteReducer(state.siteState, action),
    uiState: uiReducer(state.uiState, action),
  }

  return action.type === RESET_LOCAL_STATE
    ? _.isEmpty(action.exclude)
      ? initialState
      : { ...initialState, ..._.pick(allState, action.exclude) }
    : allState
}

export { initialState, reducer }
