import { useState, useCallback } from 'react'
import _ from 'lodash'
import { useMount, useMountedState } from 'react-use'
import { uniqueStringId } from 'helpers/utils'
import makeTrashable from 'trashable'

const useTrashable = () => {
  const isMounted = useMountedState()
  const [promiseStore, setPromiseStore] = useState({})

  const addToStore = useCallback(
    (key, promise) => {
      setPromiseStore({ ...promiseStore, [key]: promise })
    },
    [promiseStore]
  )

  const removeFromStore = useCallback(
    key => {
      if (!isMounted()) return

      setPromiseStore(_.omit(promiseStore, [key]))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [promiseStore, isMounted()]
  )

  useMount(() => {
    _.forEach(promiseStore, (wrappedPromise, key) => {
      wrappedPromise.trash()
      removeFromStore(key)
    })
  })

  const wrapper = useCallback(
    promise => {
      const key = uniqueStringId()
      const wrappedPromise = makeTrashable(promise)
      addToStore(key, wrappedPromise)
      return wrappedPromise.then(
        val => {
          removeFromStore(key)
          return val
        },
        err => {
          removeFromStore(key)
          return err
        }
      )
    },
    [addToStore, removeFromStore]
  )

  return wrapper
}

export default useTrashable
