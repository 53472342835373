import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

// constants
import { TIME_RANGE_MODES } from 'constants/filter'

// utils
import { isValidISODatetimeString } from 'helpers/datetime'
import { TextInput } from 'components/common'
import { getPrettyTimeFromTimeObj, convertTimeToNewModeTime } from '../utils'

// components
import RelativeTimePicker from '../RelativeTimePicker'
import AbsoluteTimePicker from '../AbsoluteTimePicker'

import scss from './index.module.scss'

const TABS = [
  TIME_RANGE_MODES.relativeTime,
  TIME_RANGE_MODES.absoluteTime,
  TIME_RANGE_MODES.now,
]

const getTabIndex = routeName => {
  const index = TABS.indexOf(routeName)
  return index > -1 ? index : 0
}

const getTabName = index => TABS[index]

const DatetimeContent = ({ label, onChange, value, timezone, selectedNow }) => {
  const { mode, value: timeValue } = value
  const defaultTabIndex = useMemo(() => getTabIndex(mode), [mode])
  const [selectedTimeStr, setSelectedTimeStr] = useState(timeValue)
  const [prettyTimeStr, setPrettyTimeStr] = useState()
  const [inputHasError, setInputHasError] = useState()

  const onRelativeTimeChange = useCallback(
    relativeTimeStr => {
      const relativeTimeObj = {
        value: relativeTimeStr,
        mode: TIME_RANGE_MODES.relativeTime,
      }
      setSelectedTimeStr(relativeTimeStr)
      setPrettyTimeStr(getPrettyTimeFromTimeObj(relativeTimeObj, timezone))
      onChange(relativeTimeObj)
    },
    [onChange, timezone]
  )

  const onAbsoluteTimeChange = useCallback(
    absoluteTimeStr => {
      const absoluteTimeObj = {
        value: absoluteTimeStr,
        mode: TIME_RANGE_MODES.absoluteTime,
      }
      setSelectedTimeStr(absoluteTimeStr)
      setPrettyTimeStr(getPrettyTimeFromTimeObj(absoluteTimeObj, timezone))
      onChange(absoluteTimeObj)
    },
    [onChange, timezone]
  )

  const onInputChange = useCallback(
    input => {
      const [date, time] = input.split(' ')
      const isDatetimeValid = isValidISODatetimeString(`${date}T${time}`)
      if (isDatetimeValid) {
        setInputHasError(false)

        const absoluteTimeStr = moment.tz(input, timezone).toISOString()

        const absoluteTimeObj = {
          value: absoluteTimeStr,
          mode: TIME_RANGE_MODES.absoluteTime,
        }
        setSelectedTimeStr(absoluteTimeStr)
        onChange(absoluteTimeObj)
      } else {
        setInputHasError(true)
      }
    },
    [onChange, timezone]
  )

  const onTabChange = useCallback(
    newIndex => {
      const newMode = getTabName(newIndex)
      const result = convertTimeToNewModeTime(value, newMode, timezone)
      setSelectedTimeStr(result.value)
      onChange(result)
    },
    [onChange, timezone, value]
  )

  useEffect(() => {
    setPrettyTimeStr(getPrettyTimeFromTimeObj(value, timezone))
  }, [value, timezone])

  const isAbsoluteTimeMode = useMemo(
    () => mode === TIME_RANGE_MODES.absoluteTime,
    [mode]
  )

  const isNonAbsoluteTimeMode = useMemo(
    () => mode !== TIME_RANGE_MODES.absoluteTime,
    [mode]
  )

  return (
    <div className={scss.container}>
      <Tabs
        defaultIndex={defaultTabIndex}
        onSelect={onTabChange}
        selectedTabClassName={scss.selectedTab}
      >
        <TabList className={scss.tabList}>
          <Tab className={scss.tab}>Relative</Tab>
          <Tab className={scss.tab}>Absolute</Tab>
          {selectedNow && <Tab className={scss.tab}>Now</Tab>}
        </TabList>
        <TabPanel>
          <div data-testid='relative-time-picker'>
            <RelativeTimePicker
              selectedTime={selectedTimeStr}
              onChange={onRelativeTimeChange}
            />
          </div>
        </TabPanel>
        <TabPanel>
          <div className='text-center' data-testid='absolute-time-picker'>
            <AbsoluteTimePicker
              selectedTime={selectedTimeStr}
              onChange={onAbsoluteTimeChange}
              timezone={timezone}
            />
          </div>
        </TabPanel>
        {selectedNow && (
          <TabPanel>
            <div className={scss.now}>
              Setting the time to now means that on every refresh this time will
              be set to the time of the refresh.
            </div>
          </TabPanel>
        )}
      </Tabs>
      <div>
        <span className={scss.text}>{label}:</span>
        {isAbsoluteTimeMode ? (
          <TextInput
            value={prettyTimeStr}
            onChange={onInputChange}
            readOnly={isNonAbsoluteTimeMode}
            className={scss.input}
            testId='display-time-value'
          />
        ) : (
          <span className={scss.text}>{prettyTimeStr}</span>
        )}
      </div>
      {inputHasError && (
        <div className={scss.small}>Expected format: YYYY-MM-DD HH:mm:ss</div>
      )}
    </div>
  )
}

DatetimeContent.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    mode: PropTypes.oneOf([
      TIME_RANGE_MODES.absoluteTime,
      TIME_RANGE_MODES.relativeTime,
      TIME_RANGE_MODES.now,
    ]).isRequired,
    value: PropTypes.string,
  }).isRequired,
  timezone: PropTypes.string,
  selectedNow: PropTypes.bool,
}

DatetimeContent.defaultProps = {
  timezone: null,
  selectedNow: true,
}

export default DatetimeContent
