export const MUTATION_RESPONSE_ERRORS_FIELDS = {
  errors: {
    type: true,
    message: true,
  },
}

export const ENTITY_OWNER_FIELDS = {
  owner: {
    group: true,
    user: {
      username: true,
      group: true,
      avatarUrl: true,
      name: true,
      initials: true,
      role: true,
    },
  },
}

export const ISSUE_USER_ASSIGNEES_FIELDS = {
  id: true,
  username: true,
  group: true,
  familyName: true,
  givenName: true,
  avatarUrl: true,
}

export const AUDITS_FIELDS = {
  audit: {
    updatedTime: true,
    createdTime: true,
    createdBy: {
      userId: true,
    },
    updatedBy: {
      userId: true,
    },
  },
}

export const GEOMETRY_FIELDS = {
  geometry: {
    coordinates: true,
    type: true,
  },
}

export const GEOJSON_GEOMETRY_FIELDS = {
  __on: [
    'GeoJSONPoint',
    'GeoJSONMultiPoint',
    'GeoJSONLineString',
    'GeoJSONMultiLineString',
    'GeoJSONPolygon',
    'GeoJSONMultiPolygon',
  ].map(__typeName => {
    return { __typeName, type: true, coordinates: true }
  }),
}

export const GEOJSON_FEATURE_FIELDS = {
  id: true,
  type: true,
  bbox: true,
  properties: true,
  geometry: GEOJSON_GEOMETRY_FIELDS,
}
