import styled from '@emotion/styled'

const CircleProgressBar = styled.div<{
  percentage: string
  size: number
  innerBgColor?: string
  remainingProgressColor?: string
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;
  background: radial-gradient(
      closest-side,
      ${({ innerBgColor }) => innerBgColor || 'white'} 89%,
      transparent 80% 100%
    ),
    conic-gradient(
      ${({ theme, percentage, remainingProgressColor }) =>
        `${theme['primary-500']} ${percentage}%, ${
          remainingProgressColor || theme['secondary-50']
        } 0`}
    );
`

export default CircleProgressBar
