import { memo, ReactElement } from 'react'

// utils
import { getColourRamp } from 'helpers/colour'

import scss from './index.module.scss'

/**
 * Formats each option label to show a colour bar
 * The colour bar is generated with css styling using linear-gradient
 */
const ColourRampBar = memo(
  ({
    colourStrArr,
    height = 11,
  }: {
    colourStrArr: string[]
    height?: number
  }): ReactElement => {
    const colourRampStyle = colourStrArr.reduce(getColourRamp, [])
    return (
      <div
        className={scss.colourBar}
        style={{
          background: `linear-gradient(to right, ${colourRampStyle.join(',')}`,
          height: `${height}px`,
        }}
      />
    )
  }
)

export default ColourRampBar
