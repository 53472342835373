// libraries
import { useMemo } from 'react'
import { useToggle, useUpdateEffect } from 'react-use'

// constants
import { BUTTON_VARIANTS } from 'components/common/Button'
import { DATE_TIME_COMPARISON_EDITOR_PRECISION } from 'constants/workflow'

// components
import { TimePicker } from 'components/common/DateTime'
import { Button, IconButton } from 'components/common'

// types
import type { UtcISOString } from 'types/datetime'

export type DateTimeType =
  | keyof typeof DATE_TIME_COMPARISON_EDITOR_PRECISION
  | undefined

export type DateTimeValue = {
  value: UtcISOString | null
  type: DateTimeType
}

const useTimePicker = ({
  className,
  utcDateTime,
  selectedTimeType,
  onChange,
  isDisabled,
  minDateTime,
  defaultToCurrent,
  embeddedIntoCalendar,
  onClear,
}: {
  className?: string
  utcDateTime: UtcISOString
  onChange: (v?: UtcISOString) => void
  isDisabled?: boolean
  minDateTime?: UtcISOString
  defaultToCurrent?: boolean
  embeddedIntoCalendar?: boolean
  onClear: () => void
  selectedTimeType: DateTimeType
}): {
  renderTimePicker: () => JSX.Element
  isDate: boolean
  type: DateTimeType
} => {
  const [isTimePickerVisible, toggleTimePickerVisible] = useToggle(
    selectedTimeType !== DATE_TIME_COMPARISON_EDITOR_PRECISION.DAY
  )

  useUpdateEffect(() => {
    toggleTimePickerVisible(
      // Hide the time input if it's no longer needed
      selectedTimeType !== DATE_TIME_COMPARISON_EDITOR_PRECISION.DAY
    )
  }, [selectedTimeType])

  const isDate = !isTimePickerVisible

  const type = useMemo(
    () =>
      isTimePickerVisible
        ? undefined
        : DATE_TIME_COMPARISON_EDITOR_PRECISION.DAY,
    [isTimePickerVisible]
  )

  const renderTimePicker = () => {
    return (
      <div className={className}>
        {isTimePickerVisible ? (
          <div className='d-flex align-items-start'>
            <TimePicker
              utcDateTime={utcDateTime}
              onChange={onChange}
              isDisabled={isDisabled}
              className='skip-errors'
              minDateTime={minDateTime}
              defaultToCurrent={defaultToCurrent}
              embeddedIntoCalendar={embeddedIntoCalendar}
            />
            <IconButton
              icon='MdClose'
              size={16}
              className='d-flex mt-2'
              onClick={() => {
                onClear()
                toggleTimePickerVisible(false)
              }}
            />
          </div>
        ) : (
          <>
            <IconButton
              icon='AiOutlineClockCircle'
              size={16}
              disabled
              style={{ padding: '0 10px 0 0', color: '#222529' }}
            />
            <Button
              variant={BUTTON_VARIANTS.link}
              disabled={isDisabled}
              onClick={() => toggleTimePickerVisible(true)}
            >
              Add time
            </Button>
          </>
        )}
      </div>
    )
  }

  return { renderTimePicker, isDate, type }
}

export default useTimePicker
