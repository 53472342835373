import { useMemo } from 'react'
import _ from 'lodash'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { useConfigStateValue } from 'contexts'

import type { FeatureFlag } from 'types/common'

export const initialEnabledFeatureFlagState = {
  rulesEngine: true,
  tileLayer: false,
  issue: true,
  site: false,
  asset: false,
  maps: true,
  workflowGeofenceAncillaryData: false,
  dynamicScheduling: false,
  jsonForm: true,
  createUser: true,
  ldarSimFileUploader: false,
  jsonFormListBuilder: false,
  siteDeepDive: false,
  loginRedirectMethane: false,
  displayVentingEventLocationOnMapInGems: false,
  displayQubeAlarmLocationOnMapInGems: false,
  createAnIssueFromAnActionOnADetectionInAnInbox: true,
}

const useFeatureFlag = (): FeatureFlag => {
  const { featureFlags } = useConfigStateValue() || {}

  const ldFlags = useFlags()

  return useMemo(() => {
    return _.defaults(
      {},
      // LaunchDarkly flags are in priority
      _.merge({}, featureFlags, ldFlags),
      initialEnabledFeatureFlagState
    )
  }, [featureFlags, ldFlags])
}

export default useFeatureFlag
