// libraries
import {
  ReactElement,
  useMemo,
  useCallback,
  useRef,
  useState,
  useEffect,
} from 'react'
import _ from 'lodash'

// constants
import { GALLERY_LIST_FILTER_TYPES, PROPERTY_TIME } from 'constants/common'

// components
import { ListFilterBuilder } from 'components/common'

// utils
import { sortKeysByDate } from 'helpers/utils'
import { getIssueFiltersSpecs, getIssueStatusFilterValue } from 'helpers/issue'
import { useListFilter } from 'components/common/List'
import {
  getSpatialFilterFn,
  getFilteredDataWithMultiDimensions,
  isTimeBetween,
} from 'helpers/filter'
import { useCurrentUser } from 'hooks'
import { parseRelativeTime } from 'helpers/datetime'

import type { IssueGeojson } from 'types/issue'
import type { ListFilterSpecs, Filters } from 'types/filter'
import type { DateTimeRange, UtcISOString } from 'types/datetime'

import scss from './useIssuesFilters.module.scss'

export const ASSET_ISSUE_FILTERS_SPECS = {
  [GALLERY_LIST_FILTER_TYPES.status]: {
    propertyPath: 'properties.status',
    getFilterValue: getIssueStatusFilterValue,
  },
  [GALLERY_LIST_FILTER_TYPES.severity]: {
    propertyPath: 'severityOption.value',
  },
  assignee: { propertyPath: 'assigneeOption.value' },
  time: {
    propertyPath: PROPERTY_TIME,
    filterFn:
      (datetimeRange: UtcISOString | DateTimeRange) =>
      (d: UtcISOString): boolean => {
        const [startTime, endTime] = datetimeRange
        return startTime && endTime
          ? isTimeBetween(d, startTime, endTime)
          : d >= parseRelativeTime(startTime)
      },
  },
}

export const ASSET_ISSUE_FILTERS_KEYS = _.keys(ASSET_ISSUE_FILTERS_SPECS)

export const useIssueFilterBuilder = ({
  allowedFilters,
  defaultFilters = {},
  vertical = false,
}: {
  allowedFilters?: string[]
  defaultFilters?: Filters
  vertical?: boolean
}): {
  renderIssuesFiltersBuilder: () => ReactElement
  filtersContainerHeight: number
  filterValues: Filters
} => {
  const { issueAssigneesOptions, issueSeverityOptions } = useCurrentUser()

  const { filterValues, setFilterValues } = useListFilter({
    filters: defaultFilters,
    customizedFiltersKeys: allowedFilters,
  })

  const filterRef = useRef<HTMLDivElement | null>(null)

  const [filtersContainerHeight, setFiltersContainerHeight] =
    useState<number>(0)

  const filtersSpecs = useMemo(() => {
    return getIssueFiltersSpecs({
      issueAssigneesOptions,
      issueSeverityOptions,
      allowedFilters,
      keyAliases: {
        [GALLERY_LIST_FILTER_TYPES.assignedToUsername]: 'assignee',
        [GALLERY_LIST_FILTER_TYPES.modifiedDatetime]: 'time',
      },
      customizedSpecs: {
        [GALLERY_LIST_FILTER_TYPES.modifiedDatetime]: { isClearable: true },
      },
    })
  }, [allowedFilters, issueAssigneesOptions, issueSeverityOptions])

  const renderIssuesFiltersBuilder = useCallback(
    ({ className, style } = {}) => {
      return (
        <div ref={filterRef}>
          <ListFilterBuilder
            style={style}
            filters={filterValues}
            filtersSpecs={filtersSpecs}
            isClearable={false}
            vertical={vertical}
            className={`${scss.filters} ${className}`}
            onChange={setFilterValues}
          />
        </div>
      )
    },
    [filterValues, filtersSpecs, setFilterValues, vertical]
  )

  useEffect(() => {
    const currentHeight = filterRef?.current?.getBoundingClientRect().height
    if (currentHeight && filtersContainerHeight !== currentHeight) {
      setFiltersContainerHeight(currentHeight)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues])

  return {
    filterValues,
    filtersContainerHeight,
    renderIssuesFiltersBuilder,
  }
}

export const useFilteredIssues = ({
  issues,
  filters,
  viewportBounds,
  filtersSpecs = ASSET_ISSUE_FILTERS_SPECS,
  isSpatialFilterEnabled = false,
}: {
  issues: IssueGeojson[]
  filters?: Filters
  viewportBounds?: []
  filtersSpecs?: ListFilterSpecs
  isSpatialFilterEnabled?: boolean
}): IssueGeojson[] => {
  const spatialFilteredIssues = useMemo(() => {
    return isSpatialFilterEnabled && viewportBounds
      ? getSpatialFilterFn(issues)(viewportBounds)
      : issues
  }, [isSpatialFilterEnabled, issues, viewportBounds])

  const filterValues = useMemo(
    () =>
      _.reduce(
        filters,
        (acc, val, key) => {
          const { getFilterValue } = filtersSpecs[key]
          return { ...acc, [key]: getFilterValue ? getFilterValue(val) : val }
        },
        {}
      ),
    [filters, filtersSpecs]
  )

  return useMemo(() => {
    const filteredIssues = getFilteredDataWithMultiDimensions({
      data: spatialFilteredIssues,
      filterValues,
      filtersSpecs,
    })

    return filteredIssues.sort((a, b) => {
      return sortKeysByDate(b.properties.time, a.properties.time)
    })
  }, [filterValues, filtersSpecs, spatialFilteredIssues])
}

const useIssuesFilters = ({
  issues,
  viewportBounds,
  isSpatialFilterEnabled = false,
  allowedFilters = ASSET_ISSUE_FILTERS_KEYS,
  defaultFilters,
  vertical = false,
}: {
  issues: IssueGeojson[]
  viewportBounds?: []
  isSpatialFilterEnabled?: boolean
  allowedFilters?: string[]
  defaultFilters?: Filters
  vertical?: boolean
}): {
  renderIssuesFiltersBuilder: () => ReactElement
  filtersContainerHeight: number
  filteredAndSortedIssues: IssueGeojson[]
} => {
  const { filterValues, ...filterBuilderRest } = useIssueFilterBuilder({
    defaultFilters,
    allowedFilters,
    vertical,
  })
  const filteredAndSortedIssues = useFilteredIssues({
    issues,
    filters: filterValues,
    viewportBounds,
    isSpatialFilterEnabled,
  })

  return {
    ...filterBuilderRest,
    filteredAndSortedIssues,
  }
}

export default useIssuesFilters
