import { ReactElement } from 'react'

import { useStaticResourceStateValue } from 'contexts'
import type { Option } from 'types/common'

import scss from '../../index.module.scss'

export type SwitchUnitsToggleProps = {
  selectedValue: string
  options: Option[]
}

const SwitchUnitsDefaultToggle = ({
  selectedValue,
  options,
}: SwitchUnitsToggleProps): ReactElement => {
  const { Icons } = useStaticResourceStateValue()

  const selectedValueLabel = options.find(
    option => option.value === selectedValue
  )?.label

  return (
    <div className={scss.trigger}>
      Units: {selectedValueLabel}{' '}
      <Icons.IoChevronDown className='ms-1' size={14} />
    </div>
  )
}

export default SwitchUnitsDefaultToggle
