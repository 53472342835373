/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  DocumentNode,
  NoInfer,
  ObservableQueryFields,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  useQuery,
} from '@apollo/client'
import { useEffect, useMemo, useRef, useCallback, useState } from 'react'
import { usePrevious } from 'react-use'
import _ from 'lodash'

import type { PageInfo } from 'types/graphql'

/**
 * About the relay-style cursor pagination:
 * https://www.apollographql.com/docs/react/pagination/cursor-based/#relay-style-cursor-pagination
 */

/** Same as apollo-clent's 'useQuery' but enhanced with the necessary features */
const useQueryWithPostProcess = <
  TData = any,
  TVariables extends OperationVariables = OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options: QueryHookOptions<NoInfer<TData>, NoInfer<TVariables>> & {
    dataPath: string
    postProcessFunc: (data: any) => any
  }
): QueryResult<TData, TVariables> & {
  isFirstRequestDone: boolean
  isLoadingMore: boolean
  pageInfo: PageInfo
} => {
  const { dataPath, postProcessFunc } = options
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const isFirstRequestDoneRef = useRef(false)

  const { data, loading, fetchMore, ...rest } = useQuery(query, {
    // Let apollo-client change the 'loading' prop when a user calls 'refetch'
    notifyOnNetworkStatusChange: true,
    ...options,
  })
  const prevLoading = usePrevious(loading)

  useEffect(() => {
    // Track when the first request has been done
    if (prevLoading && !loading) isFirstRequestDoneRef.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  const transformedData = useMemo(
    () => {
      if (!data || _.isEmpty(data)) return data

      const dataToProcess = _.get(data, dataPath)
      // The rely-style list looks like { edges: [{ node: {...} }] }
      const relayStyleData = _.get(dataToProcess, 'edges')
      const isRelayStyleData = _.isArray(relayStyleData)

      if (isRelayStyleData) {
        return _(relayStyleData)
          .map(item => (item.node ? postProcessFunc(item.node) : null))
          .compact()
          .value()
      }

      if (_.isArray(dataToProcess)) return _.map(dataToProcess, postProcessFunc)

      return postProcessFunc(dataToProcess)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  )

  const prevTransformedData = usePrevious(transformedData)
  // While the data is loading, we should return the previous data instead of 'undefined'
  const stabilizedData = useMemo(
    () => (loading ? prevTransformedData : transformedData),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, transformedData]
  )

  const pageInfo = useMemo(
    // eslint-disable-next-line lodash/path-style
    () => _.get(data, `${dataPath}.pageInfo`) || {},
    [data, dataPath]
  )

  const fetchMoreData = useCallback<
    ObservableQueryFields<TData, TVariables>['fetchMore']
  >(
    async (args = {}) => {
      setIsLoadingMore(true)
      return fetchMore({
        ...(pageInfo.endCursor && {
          variables: { after: pageInfo.endCursor } as any,
        }),
        ...args,
      })
    },
    [fetchMore, pageInfo.endCursor]
  )

  useEffect(() => {
    if (!loading) setIsLoadingMore(false)
  }, [loading])

  return {
    ...rest,
    fetchMore: fetchMoreData,
    loading,
    isLoadingMore,
    pageInfo,
    data: stabilizedData,
    isFirstRequestDone: isFirstRequestDoneRef.current,
  }
}

export default useQueryWithPostProcess
