import { useMemo, ReactElement } from 'react'
import { WidgetProps } from '@rjsf/utils'
import _ from 'lodash'

// utils
import {
  isJsonFormWidgetHidden,
  isJsonFormWidgetHideLabel,
  isJsonFormWidgetReadonly,
} from 'helpers/formBuilder'

// constants
import { FORM_BUILDER_GEOLOCATION_WIDGET_DEFAULT_PIN } from 'constants/formBuilder'

import type { Payload } from 'types/common'

import WidgetWrapper from '../WidgetWrapper'

import MapWithMarker from './MapWithMarker'

// The reason we are adding this is because issue: https://github.com/rjsf-team/react-jsonschema-form/issues/3363
// The react-jsonschema-form will add default value to the bbox which is [undefined, undefined, undefined, undefined] because of the minItems: 4
export const sanitizeFormDataForObject = (formData: Payload): Payload => {
  return _.omitBy(
    _.reduce(
      formData,
      (acc, cur, key) => {
        if (_.isNil(cur)) return acc
        if (_.isArray(cur) && _.compact(cur).length !== cur.length) return acc

        return { ...acc, [key]: cur }
      },
      {}
    ),
    _.isNil
  )
}

const CustomGeoPoint = ({
  name,
  schema,
  uiSchema,
  formData,
  required,
  rawErrors,
  onChange,
  formContext,
}: WidgetProps): ReactElement => {
  const { isPreview = false } = formContext

  const { title: label, description } = schema || {}

  const hideLabel = isJsonFormWidgetHideLabel(uiSchema)
  const isHidden = isJsonFormWidgetHidden(uiSchema)
  const readonly = isJsonFormWidgetReadonly(uiSchema)

  const point = useMemo(() => sanitizeFormDataForObject(formData), [formData])

  return isHidden ? (
    <></>
  ) : (
    <>
      <WidgetWrapper
        {...(!hideLabel && { label, description })}
        required={required}
        name={name}
        rawErrors={rawErrors}
        isLarge={isPreview}
      >
        <MapWithMarker
          {...(isPreview
            ? {
                point: FORM_BUILDER_GEOLOCATION_WIDGET_DEFAULT_PIN,
              }
            : {
                onChange,
                point,
              })}
          readonly={readonly}
        />
      </WidgetWrapper>
    </>
  )
}

export default CustomGeoPoint
