// libraries
import { FC, useMemo } from 'react'
import _ from 'lodash'
import { useToggle } from 'react-use'

// utils
import { truncateText } from 'helpers/utils'

import type { ShowMoreTextProps } from '../type'

const ShowMoreText: FC<ShowMoreTextProps> = ({ text = '', limit = 150 }) => {
  const [isExpanded, toggleExpand] = useToggle(false)

  const shouldExpand = _.isString(text) && text.length > limit

  const displayedText = useMemo(() => {
    if (_.isString(text)) {
      return isExpanded || !shouldExpand ? text : truncateText(text, limit)
    }
    return text
  }, [isExpanded, shouldExpand, text, limit])

  const buttonText = isExpanded ? 'Show less' : 'Show more'

  return (
    <>
      {displayedText}
      {shouldExpand && (
        <div>
          <button type='button' onClick={toggleExpand}>
            {buttonText}
          </button>
        </div>
      )}
    </>
  )
}

export default ShowMoreText
