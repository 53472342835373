// libraries
import _ from 'lodash'

// constants
import { TIME_PROPERTY_KEY } from 'constants/unipipe'
import {
  DEFAULT_PROFILE_PROPERTIES_SETTING,
  INTERACTION_TYPES,
} from 'constants/profile'
import { DEFAULT_INVISIBLE_PROPERTY_FORMATS } from 'constants/filter'
import { NON_VISIBLE, DEFAULT_IDENTITY_PROPERTY } from 'constants/common'

// utils
import { displayValue, convertArrayToObject } from 'helpers/utils'
import {
  getDatasetServiceIdentifier,
  getLatestDataByFeatureIdentityProperty,
} from 'helpers/unipipe'
import { getHistoricalProperties } from 'helpers/mapUtils'

import type {
  InteractionType,
  MapLayer,
  MapLayerProfileProperties,
  MapLayerProfileProperty,
  MapLayerProfileType,
  MapLayerProfileSimplifiedProperties,
  MapPickedFeature,
  GeojsonDataProperties,
} from 'types/map'
import type {
  DatasetIdentifier,
  DatasetServices,
  PropertiesMetadata,
  PropertyMetadata,
  PropertyName,
  Value,
} from 'types/common'

export const isEventTriggeredByClicking = (
  pickedType?: InteractionType
): boolean => pickedType === INTERACTION_TYPES.click

export const isEventTriggeredByHovering = (
  pickedType?: InteractionType
): boolean => pickedType === INTERACTION_TYPES.hover

type MapLayerProfilePropertyValueDisplay = PropertyMetadata &
  MapLayerProfileProperty & {
    value: Value
    label: string
  }

type MapLayerProfilePropertyValuesDisplay =
  MapLayerProfilePropertyValueDisplay[]

type MapLayerProfileFeatureUniqName = string

type MapLayerProfileFeature = {
  dataset: DatasetIdentifier
  datasetServices: DatasetServices
  mapLayer: MapLayer
  name: MapLayerProfileFeatureUniqName
  profileProperties: MapLayerProfileSimplifiedProperties | []
  profileType: MapLayerProfileType
  properties: GeojsonDataProperties
  propertiesOptions: PropertiesMetadata
  title: string
}

type PropertiesMetadataKeyByName = Record<PropertyName, PropertyMetadata>

/**
 * Assign the value of property which is associated with the current
 * interaction to the current interaction
 */
export const getLayerProfileWithAssociatedValue =
  (properties: GeojsonDataProperties, propertiesOptions: PropertiesMetadata) =>
  (
    interaction: MapLayerProfileProperty
  ): MapLayerProfilePropertyValueDisplay => {
    const { isVisible = true, value, label } = interaction
    const propertyOption = _.find(propertiesOptions, { value })
    const newLabel = propertyOption?.label || label
    return {
      ...propertyOption,
      ...interaction,
      value: properties[value],
      key: value,
      isVisible,
      label: newLabel,
    }
  }

/**
 * Show all properties list if profileProperties is empty,
 * with the exception of those that should be invisible
 * by default
 */
const getDefaultProfileProperties = (
  propertiesOptions: PropertiesMetadata
): MapLayerProfileProperties => {
  return _.map(propertiesOptions, ({ value, format, ...rest }) => ({
    ...rest,
    value,
    key: value,
    isVisible: !DEFAULT_INVISIBLE_PROPERTY_FORMATS.includes(format),
  }))
}

const getValidProfileProperty =
  (propertyOptionsByPropertyValue: PropertiesMetadataKeyByName) =>
  (property: MapLayerProfileProperty): MapLayerProfileProperty => {
    const { value } = property
    return {
      ...(propertyOptionsByPropertyValue[value] || {}),
      isVisible: true,
      ...property,
      key: value,
    }
  }

export const isAvailableProperty =
  (availablePropertiesKeys: PropertyName[]) =>
  ({ value }: { value: string }): boolean =>
    _.includes(availablePropertiesKeys, value)

const getVisibleProfilePropertiesKeys = ({
  profileProperties,
  propertyIdentifier,
  propertyOptionsObj,
}: {
  profileProperties: MapLayerProfileSimplifiedProperties
  propertyIdentifier: string
  propertyOptionsObj: PropertiesMetadataKeyByName
}): PropertyName[] => {
  const profileOptionsObj = convertArrayToObject(
    profileProperties,
    propertyIdentifier
  )
  return _.reduce(
    propertyOptionsObj,
    (acc, _cur, key) => {
      return _.get(profileOptionsObj, key) ? [...acc, key] : acc
    },
    [] as PropertyName[]
  )
}

const getValidProperties = (props: {
  profileProperties: MapLayerProfileSimplifiedProperties
  propertyIdentifier: string
  propertyOptions: PropertiesMetadata
  propertyOptionsObj: PropertiesMetadataKeyByName
}): MapLayerProfileProperties => {
  const { profileProperties, propertyOptions } = props
  const availablePropertiesKeys = getVisibleProfilePropertiesKeys(props)
  const propertyOptionsByPropertyValue = _.keyBy(propertyOptions, 'value')
  return _(profileProperties)
    .filter(isAvailableProperty(availablePropertiesKeys))
    .map(getValidProfileProperty(propertyOptionsByPropertyValue))
    .value()
}

export const getInvisibleProperties = ({
  propertyOptionsObj,
  sortedPickedProperties,
}: {
  propertyOptionsObj: PropertiesMetadataKeyByName
  sortedPickedProperties: PropertyName[]
}): (PropertyMetadata & { isVisible: false })[] => {
  return _(propertyOptionsObj)
    .omit(sortedPickedProperties)
    .map(property => ({ ...property, isVisible: false }))
    .value()
}

export const getAllProfileProperties = (
  validProperties: MapLayerProfileProperties = [],
  invisibleProperties: MapLayerProfileProperties = []
): MapLayerProfileProperties =>
  _.compact([...validProperties, ...invisibleProperties])

// Transform from simplified profile properties
export const transformProfileProperties = (
  propertyOptions: PropertiesMetadata,
  profileProperties: MapLayerProfileSimplifiedProperties,
  propertyIdentifier = 'value'
): MapLayerProfileProperties => {
  if (_.isEmpty(profileProperties)) {
    return getDefaultProfileProperties(propertyOptions)
  }

  const propertyOptionsObj = convertArrayToObject(
    propertyOptions,
    propertyIdentifier
  )
  const validProperties = getValidProperties({
    profileProperties,
    propertyOptions,
    propertyOptionsObj,
    propertyIdentifier,
  })
  const sortedPickedProperties = _.map(validProperties, propertyIdentifier)

  // add back the properties to profiles that property options may offer
  const missingProperties = getInvisibleProperties({
    propertyOptionsObj,
    sortedPickedProperties,
  })
  return getAllProfileProperties(validProperties, missingProperties)
}

const invisibleTimePropertyIfNotPresent = (
  propertiesKeys: PropertyName[]
):
  | []
  | [
      {
        key: string
        label: string
        isVisible: boolean
      }
    ] =>
  propertiesKeys.includes(TIME_PROPERTY_KEY)
    ? []
    : [
        {
          key: TIME_PROPERTY_KEY,
          label: TIME_PROPERTY_KEY,
          isVisible: false,
        },
      ]

/**
 * Get visible properties data which will be displayed on the map profile based on the interactions and feature data
 */
export const getVisibleProfilesPropertiesList = (
  profileProperties: MapLayerProfileSimplifiedProperties,
  propertiesOptions: PropertiesMetadata,
  featurePropertiesValue: GeojsonDataProperties
): MapLayerProfilePropertyValuesDisplay => {
  if (_.isEmpty(propertiesOptions)) return []

  const properties = _.isEmpty(profileProperties)
    ? getDefaultProfileProperties(propertiesOptions)
    : transformProfileProperties(propertiesOptions, profileProperties)
  const propertiesKeys = _.map(properties, 'value') as PropertyName[]
  return _(properties)
    .reject(NON_VISIBLE)
    .concat(invisibleTimePropertyIfNotPresent(propertiesKeys))
    .map(
      getLayerProfileWithAssociatedValue(
        featurePropertiesValue,
        propertiesOptions
      )
    )
    .compact()
    .value()
}

const getCurrentFeaturePropertiesValues = (
  feature: MapLayerProfileFeature
): GeojsonDataProperties => {
  const { name, dataset, datasetServices, properties } = feature || {}

  let currentFeaturePropertiesValues
  if (_.isEmpty(properties)) {
    // get data from the context
    // there will be at least one piece of data per feature
    const latestFeatureData = getLatestDataByFeatureIdentityProperty(
      dataset,
      datasetServices,
      name
    )
    currentFeaturePropertiesValues = _.isEmpty(latestFeatureData)
      ? {}
      : latestFeatureData.properties
  } else {
    // use the given properties if possible
    currentFeaturePropertiesValues = properties
  }
  return currentFeaturePropertiesValues
}
/**
 * Get latest properties and historical properties for a given feature
 */
export const getFeatureLatestProperties = (
  featureObj: MapLayerProfileFeature,
  oldFeature: MapPickedFeature | null
): {
  name: string
  title: string
  properties: MapLayerProfilePropertyValuesDisplay
  historicalProperties: GeojsonDataProperties[]
} => {
  const {
    title,
    profileProperties,
    propertiesOptions,
    mapLayer,
    datasetServices,
  } = featureObj || {}

  const currentFeaturePropertiesValues =
    getCurrentFeaturePropertiesValues(featureObj)
  const displayProperties = _.isEmpty(currentFeaturePropertiesValues)
    ? []
    : getVisibleProfilesPropertiesList(
        profileProperties,
        propertiesOptions,
        currentFeaturePropertiesValues
      )

  const historyProperties = getHistoricalProperties(
    oldFeature,
    currentFeaturePropertiesValues
  )

  const datasetServiceIdentifier = getDatasetServiceIdentifier(mapLayer)

  const identityProperty = datasetServiceIdentifier
    ? _.get(datasetServices, [
        datasetServiceIdentifier,
        'metadata',
        'identityProperty',
      ])
    : DEFAULT_IDENTITY_PROPERTY

  return {
    name: displayValue(_.get(currentFeaturePropertiesValues, identityProperty)),
    title,
    properties: displayProperties,
    historyProperties,
  }
}

export const getVisiblePropertiesValuesSet = (
  profileProperties: MapLayerProfileProperties,
  identityProperty = 'name',
  withDefaultValues = true
): Set<PropertyName> => {
  const defaultValues = [identityProperty, 'time']
  const newProperties = _.map(profileProperties, properties => {
    return { ...DEFAULT_PROFILE_PROPERTIES_SETTING, ...properties }
  })
  const values = _(newProperties).reject(NON_VISIBLE).map('value').value()
  return new Set(withDefaultValues ? [...defaultValues, ...values] : values)
}
