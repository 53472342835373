// libraries
import { ReactElement } from 'react'

// component
import FieldWrapper, {
  FieldWrapperProps,
} from 'components/common/Form/FieldWrapper'
import { useFieldError } from 'components/common/Form/FieldError'

export type StyleFieldProps = Omit<FieldWrapperProps, 'hasError' | 'metaError'>

const StyledField = ({ name, ...props }: StyleFieldProps): ReactElement => {
  const { hasError, metaError } = useFieldError(name)

  return (
    <FieldWrapper
      name={name}
      hasError={hasError}
      metaError={metaError}
      {...props}
    />
  )
}

export default StyledField
