// libraries
import { ReactElement } from 'react'
import _ from 'lodash'
import { ColumnBodyOptions } from 'primereact/column'

// constants
import { UNASSIGNED_USERNAME } from 'constants/user'

// components
import { IssueAssigneesPicker, UserPlaceholder } from 'components/common'

import type { IssueGeojson } from 'types/issue'

const Template = ({
  rowData,
  options: { field },
}: {
  rowData: IssueGeojson
  options: ColumnBodyOptions
}): ReactElement => {
  const readOnly = true
  const assignee = _.get(rowData, field)
  return (
    <IssueAssigneesPicker
      bgColour='transparent'
      value={assignee}
      readOnly={readOnly}
      placeholder={
        assignee && !readOnly ? undefined : (
          <UserPlaceholder text={UNASSIGNED_USERNAME} />
        )
      }
    />
  )
}

const AssigneeTemplate = (
  rowData: unknown,
  options: ColumnBodyOptions
): ReactElement => {
  return <Template rowData={rowData} options={options} />
}

export default AssigneeTemplate
