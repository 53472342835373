import _ from 'lodash'
import { useConfigStateValue } from 'contexts/ConfigContext'
import { useAuthStateValue } from 'contexts'

const usePlugins = () => {
  let { plugins } = useConfigStateValue()
  const { currentUser } = useAuthStateValue()

  plugins = plugins || {}
  plugins.mapLayerDataset = plugins.mapLayerDataset || []
  plugins.sidebarRoute = plugins.sidebarRoute || []
  plugins.sidebarRoute.forEach(sr => {
    sr.normalizedRoutePath = `/plugins/sr/${sr.routePath}`
    // By default, showInSidebar is true. Only set to false if user role is
    // not "admin" (i.e., not a super admin) or if showForGroups is an array
    // that does not include the current user's group
    sr.showInSidebar =
      _.isArray(sr.showForGroups) && currentUser.role !== 'admin'
        ? sr.showForGroups.includes(currentUser.group)
        : true
  })
  return plugins
}

export default usePlugins
