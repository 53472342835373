// libraries
import React, { useCallback } from 'react'
import { useToggle } from 'react-use'
import PropTypes from 'prop-types'

// components
import { ColourPickerSimple, Toggle } from 'components/common'

const ColourSetting = ({ isSingleColour, colour, updateSettings }) => {
  const [simpleColour, toggleSimpleColour] = useToggle(isSingleColour)

  const toggleHandler = useCallback(() => {
    toggleSimpleColour(!simpleColour)
    updateSettings({ isSingleColour: !simpleColour })
  }, [simpleColour, updateSettings, toggleSimpleColour])

  return (
    <div className='groupOption'>
      <div className='groupOptionTitle d-flex justify-content-between align-items-center'>
        Select colour
        <Toggle
          label={simpleColour ? 'Single Colour' : 'Random Colour'}
          checked={simpleColour}
          onToggle={toggleHandler}
        />
      </div>
      {simpleColour && (
        <div className='groupOptionContent'>
          <ColourPickerSimple
            colour={colour}
            onChange={val => updateSettings({ colour: val })}
          />
        </div>
      )}
    </div>
  )
}

ColourSetting.propTypes = {
  isSingleColour: PropTypes.bool.isRequired,
  colour: PropTypes.arrayOf(PropTypes.number).isRequired,
  updateSettings: PropTypes.func.isRequired,
}

export default ColourSetting
