import React from 'react'
import { CSSTransition } from 'react-transition-group'
import PropTypes from 'prop-types'
import { ListGroupItem } from 'components/common'

import css from './index.module.scss'

const ActionItemDialog = ({
  showActionItemDialog,
  setShowRegularItem,
  timeout,
  children,
}) => {
  return (
    <CSSTransition
      in={showActionItemDialog}
      timeout={timeout}
      classNames='actionSlideIn'
      unmountOnExit
      onEnter={() => setShowRegularItem(false)}
      onExited={() => setShowRegularItem(true)}
    >
      <ListGroupItem bgColour borderLeft={false} className={css.actionItem}>
        {children}
      </ListGroupItem>
    </CSSTransition>
  )
}

ActionItemDialog.propTypes = {
  showActionItemDialog: PropTypes.bool.isRequired,
  setShowRegularItem: PropTypes.func.isRequired,
  timeout: PropTypes.number,
  children: PropTypes.node,
}

ActionItemDialog.defaultProps = {
  timeout: 300,
  children: undefined,
}

export default ActionItemDialog
