// eslint-disable-next-line react/no-deprecated
import { render } from 'react-dom'
import SUExplorer from 'SUExplorer'
import * as serviceWorker from 'serviceWorker'
import 'style/global.scss'
import './index.css'

const { error } = console

// * Temporary solution for hiding warning: Support for defaultProps will be removed from function components in a future major release. Use JavaScript default parameters instead.
// * Dispose of this once all defaultProps have been removed.
// more context: https://sophiabits.com/blog/stop-using-defaultprops
// eslint-disable-next-line no-console
console.error = (...args) => {
  if (/defaultProps/.test(args[0])) return
  error(...args)
}

render(<SUExplorer />, document.getElementById('root'))
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
