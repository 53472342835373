// libraries
import { ReactElement, useMemo, useState, useEffect } from 'react'
import { useDropzone, Accept } from 'react-dropzone'
import _ from 'lodash'
import { saveAs } from 'file-saver'
import styled from '@emotion/styled'

// types
import type { FileTemplate } from 'types/common'

// constants
import {
  FILE_UPLOAD_ERROR_MESSAGES,
  FILE_UPLOAD_STATUS,
} from 'constants/fileUpload'
import { BUTTON_VARIANTS } from 'components/common/Button'

// utils
import { getFileNameAndExt, getReadableBytes } from 'helpers/utils'
import { showError, showWarn } from 'helpers/message'

// components
import { Button, IconButton } from 'components/common'
import { SpreadsheetIcon } from 'components/icons'

import { FileUploadingStatusIcon } from './components'
import scss from './index.module.scss'

const focusedStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

type StyledUploadedFilePreviewProps = {
  hasError?: boolean
}

export type FileUploadingState = Record<string, keyof typeof FILE_UPLOAD_STATUS>

const StyledUploadedFilePreview = styled.div<StyledUploadedFilePreviewProps>`
  padding: 8px;
  margin: 8px;
  background: #fafafa81;
  border: 1px solid
    ${props => (props.hasError ? props.theme.danger : '#eaeaea')};
  border-radius: 5px;
`

const FileUploader = ({
  onChange,
  files = [],
  disabled,
  errorMessage,
  isSingleFile = true,
  accept,
  placeholderText,
  template,
  uploadMessage,
  displayClickHereToUpload,
  fileUploadingState,
  maxFiles,
  maxSize,
}: {
  onChange: (v: File[]) => void
  files?: File[]
  disabled?: boolean
  errorMessage?: string
  isSingleFile?: boolean
  accept: Accept
  placeholderText: string
  template?: FileTemplate
  uploadMessage?: string
  displayClickHereToUpload?: boolean
  fileUploadingState?: FileUploadingState
  maxFiles?: number
  maxSize?: number
}): ReactElement => {
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    open,
    isFocused,
    isDragAccept,
    isDragReject = true,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    ...(maxFiles && { maxFiles }),
    ...(maxSize && { maxSize }),
    ...(isSingleFile
      ? { maxFiles: 1 }
      : {
          multiple: true,
        }),

    onDropRejected: filesInfo =>
      filesInfo.forEach(({ errors, file }) => {
        const origErrorInfo = _.first(errors)
        if (!origErrorInfo) return

        const formattedErrorMessage =
          FILE_UPLOAD_ERROR_MESSAGES[origErrorInfo.code]?.({
            file,
            maxSize,
          }) || `${file.name} – ${origErrorInfo.message}`

        showWarn(formattedErrorMessage)
      }),
    accept,
  })

  const [uploadedFiles, setUploadedFiles] = useState<File[]>(files)

  const currentFiles = _.map(uploadedFiles, ({ path, name, size }) => {
    const [filename, ext] = getFileNameAndExt(name)
    const newName = `${_.truncate(filename, {
      length: 18,
      separator: ' ',
    })}.${ext}`

    const uploadingStatus = fileUploadingState?.[name]

    return (
      <StyledUploadedFilePreview
        className={`${scss.filePreview} d-flex justify-content-between align-items-start`}
        key={path}
        hasError={!!errorMessage}
      >
        <div className='d-flex'>
          <div style={{ marginTop: '5px' }}>
            <SpreadsheetIcon />
          </div>
          <div className={scss.filePreviewName}>
            <div className='defaultText'>{newName}</div>
            <div>{getReadableBytes(size)}</div>
          </div>
        </div>

        <div className='h-100 d-flex flex-column justify-content-between align-items-center'>
          <IconButton
            icon='FiX'
            onClick={() => {
              setUploadedFiles((oldFiles: File[]) =>
                _.reject(oldFiles, { name })
              )
            }}
            disabled={disabled}
          />
          {uploadingStatus && (
            <FileUploadingStatusIcon uploadingStatus={uploadingStatus} />
          )}
        </div>
      </StyledUploadedFilePreview>
    )
  })

  useEffect(() => {
    if (_.isEmpty(acceptedFiles)) return

    if (isSingleFile) {
      setUploadedFiles(acceptedFiles)
    } else {
      setUploadedFiles((oldFiles: File[]) => {
        const duplicatedFiles = _.intersectionBy(
          oldFiles,
          acceptedFiles,
          'name'
        )
        if (_.isEmpty(duplicatedFiles)) {
          return [...oldFiles, ...acceptedFiles]
        }
        showError(
          `${duplicatedFiles.length} file(s) have the same name. Remove them or rename the new files and upload again`
        )
        return oldFiles
      })
    }
  }, [acceptedFiles, isSingleFile])

  useEffect(() => {
    onChange(uploadedFiles)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFiles])

  const style = useMemo(
    () => ({
      ...(isFocused && focusedStyle),
      ...(isDragAccept && acceptStyle),
      ...(isDragReject && rejectStyle),
    }),
    [isFocused, isDragAccept, isDragReject]
  )

  const displayPlaceholder = _.isEmpty(currentFiles)

  return (
    <>
      <div>
        <div
          {...getRootProps({
            className: `${scss.dropZone} ${
              displayPlaceholder ? scss.placeholder : scss.preview
            }`,
            style,
          })}
        >
          <input {...getInputProps()} />
          {displayPlaceholder ? (
            <div>
              {/* https://github.com/react-dropzone/react-dropzone/issues/1239 */}
              <div className='text-secondary smallText semiBold'>
                {placeholderText}
                {displayClickHereToUpload && (
                  <>
                    &nbsp;or&nbsp;
                    <span className={scss.textButton} onClick={open}>
                      click here to upload
                    </span>
                  </>
                )}
              </div>

              {template && (
                <>
                  <div className='text-secondary smallText'>
                    {uploadMessage}
                  </div>
                  <Button
                    variant={BUTTON_VARIANTS.link}
                    onClick={() => {
                      saveAs(
                        `${process.env.PUBLIC_URL}${template.filePath}`,
                        `${template.fileName}`
                      )
                    }}
                  >
                    DOWNLOAD TEMPLATE
                  </Button>
                </>
              )}
            </div>
          ) : (
            <div className='d-flex flex-wrap'>{currentFiles}</div>
          )}
        </div>

        {!!errorMessage && (
          <div className='text-danger'>
            <IconButton
              size={14}
              icon='RiErrorWarningFill'
              className='text-danger'
            />
            <span className='smallText'>{errorMessage}</span>
          </div>
        )}
      </div>
    </>
  )
}

export default FileUploader
