import { selectorFamily } from 'recoil'
import _ from 'lodash'
import { getSiteEquipments } from '../services/api/detection'

export const siteEquipmentsQuery = selectorFamily({
  key: 'SiteEquipments',
  get: (siteId?: string) => async () => {
    if (!siteId) return []

    const response = await getSiteEquipments({
      queryParams: {
        siteId,
      },
    })

    return _(response.data)
      .sortBy('description')
      .map(({ id, description, components }) => ({
        value: id,
        label: description,
        components,
        labelExtras: {
          description: id,
        },
      }))
      .value()
  },
})
