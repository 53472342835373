import { useMemo } from 'react'
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
} from '@apollo/client'
// import { relayStylePagination } from '@apollo/client/utilities'

import { useConfigStateValue } from 'contexts'
import { errorLink } from 'apollo/links'
import { isDevEnvironment } from 'helpers/utils'

const useInitApolloClient = () => {
  const { graphqlApi } = useConfigStateValue() || {}

  return useMemo(() => {
    const isDevEnv = isDevEnvironment()
    return new ApolloClient({
      /**
       * !Note: Let's say we want to use Apollo for 'emissionEvents.inbox' query.
       * This is a relay-style paginated query. That means its structure is as follows: edges: [{ node: {...} }, ...]
       * Apollo can't properly handle such a structure unless we explicitly set a type policy
       * to help the caching engine to read/merge query results.
       * So, if you're starting to use paginated queries with Apollo,
       * uncomment the code below and start defining custom 'typePolicies'.
       */
      // cache: new InMemoryCache({
      //   typePolicies: {
      //     // This is the type of a query domain. For example, if we're working with the query 'emissionEvents.inbox',
      //     // that means that 'emissionEvents' is a domain. You can easily find its type in Apollo Studio.
      //     EmissionEvents: {
      //       fields: {
      //         // Basically the name of the query
      //         inbox: relayStylePagination(),
      //       },
      //     },
      //   },
      // }),
      cache: new InMemoryCache(),
      link: ApolloLink.from([
        errorLink,
        new HttpLink({
          uri: isDevEnv ? 'http://localhost:3000/api/graphql' : graphqlApi,
        }),
      ]),
      connectToDevTools: isDevEnv,
    })
  }, [graphqlApi])
}

export default useInitApolloClient
