import { ReactElement } from 'react'
import { useField } from 'react-final-form'

// scss
import scss from './index.module.scss'

export const Error = ({ error }: { error: string }): ReactElement => (
  <div className={`${scss.error} singleLineTruncate`}>
    <span>{error}</span>
  </div>
)

export const useFieldError = (
  name: string
): { hasError: boolean; metaError?: string } => {
  const field = useField(name)
  if (!name || !field) return { hasError: false }

  const { meta } = field
  const { error, submitError, modified, touched, submitFailed } = meta

  const metaError = error || submitError
  const showError = !!((metaError && (modified || touched)) || submitFailed)
  const hasError = showError && !!metaError
  return { hasError, metaError }
}

const FieldError = ({ name }: { name: string }): ReactElement => {
  const { hasError, metaError } = useFieldError(name)
  return hasError && metaError ? <Error error={metaError} /> : <></>
}

export default FieldError
