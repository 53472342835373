import { ReactElement } from 'react'

import type { Option } from 'types/common'
import type { MultiSelectProp } from 'components/common/MultiSelect'

import scss from './index.module.scss'

/** The default `formatOptionLabel` */
const OptionLabel: MultiSelectProp['formatOptionLabel'] = (
  props,
  { context }
): ReactElement => {
  const {
    label,
    labelExtras: { description, tertiaryDescription, sideComponent } = {},
  } = props as Option
  const isValue = context === 'value'

  // If selected, display just a text
  if (isValue) {
    return <>{label}</>
  }

  return (
    <div className='d-flex justify-content-between align-items-center'>
      <div>
        <div className={scss.optionTitle}>{label}</div>
        {description && (
          <div className={scss.optionSecondary}>{description}</div>
        )}
        {tertiaryDescription && (
          <div className={scss.optionSecondary}>{tertiaryDescription}</div>
        )}
      </div>
      {sideComponent && <div className={scss.sideLabel}>{sideComponent}</div>}
    </div>
  )
}

export default OptionLabel
