// libraries
import _ from 'lodash'

// constants
import { LAYER_TYPES, MULTI_LABELS_LAYER_TYPES } from 'constants/map'
import { LAYER_VIS_CONFIGS } from 'components/map/layers/deckLayers/layerFactory'

// utils
import { isRangeValid, isValueInRange } from 'helpers/utils'
import {
  rgbToColourArr,
  getColourBreaks,
  isSimpleColourType,
  isColourClassesValid,
  isAdvancedColourType,
  getNumericColourClassification,
  getColourFromClassificationByValue,
  getCategoryColourClassifications,
} from 'helpers/colour'
import { isNumericType } from 'helpers/filter'
import { getPropertyDataDomain, getPropertyValueByName } from 'helpers/mapUtils'

import type {
  Range,
  ColourClasses,
  ColourProperty,
  Payload,
  ColourArray,
  NumericColourClass,
} from 'types/common'
import type {
  GeojsonData,
  MapLayer,
  MapLayerStyle,
  MapLayerStylePerType,
  MapLayerType,
} from 'types/map'
import type { CrossFilterDimension } from 'types/filter'

/**
 * generate colour classification based on the given property and colour array
 * the data will be filtered by the range before calculation and will affect the colour classification
 */
export const generateNewNumericClassification = (
  data: [],
  property: string,
  colours: [],
  range: Range
): NumericColourClass[] => {
  // find the min and max value of the given property (use the given range as min and max if range is available and valid)
  const domain = isRangeValid(range)
    ? range
    : getPropertyDataDomain(data, property)

  const breaks = getColourBreaks(domain, colours.length)
  // connect the breaks to the colour array
  return getNumericColourClassification(colours, breaks)
}

export const generateNewColourClassification = ({
  data,
  colours,
  propertyKey,
  propertyType,
  range,
}: {
  data: []
  colours: []
  propertyKey: string
  propertyType: string
  range?: Range
}): ColourClasses | undefined => {
  return isNumericType(propertyType)
    ? generateNewNumericClassification(
        data,
        propertyKey,
        colours,
        range as Range
      )
    : getCategoryColourClassifications(data, propertyKey, colours)
}

export const getColourClassification = (
  data: [],
  colours: [],
  colourProperty: ColourProperty,
  colourClasses?: ColourClasses,
  range?: Range
): ColourClasses | undefined => {
  if (_.isEmpty(colourProperty)) return undefined

  const { key: propertyKey, type: propertyType } = colourProperty
  if (isColourClassesValid(colourClasses, propertyType)) return colourClasses

  return propertyKey
    ? generateNewColourClassification({
        data,
        colours,
        propertyKey,
        propertyType,
        range,
      })
    : undefined
}

export const getPropertyValueMappedColour = (
  geojson: GeojsonData,
  property: ColourProperty,
  classification: ColourClasses,
  classificationCrossFilterDimension?: CrossFilterDimension
): ColourArray | undefined => {
  if (_.isEmpty(property)) return undefined

  const { key, type } = property
  const value = getPropertyValueByName(geojson, key)
  return getColourFromClassificationByValue(
    value,
    classification,
    type,
    classificationCrossFilterDimension
  )
}

export const getPropertyColour =
  (
    isSimpleColour: boolean,
    colourProperty: ColourProperty,
    classification: ColourClasses,
    classificationCrossFilterDimension?: CrossFilterDimension
  ) =>
  (data: GeojsonData): ColourArray | undefined => {
    return isSimpleColour
      ? undefined
      : getPropertyValueMappedColour(
          data,
          colourProperty,
          classification,
          classificationCrossFilterDimension
        ) || rgbToColourArr(_.get(data, 'properties.hints.style.color'))
  }

export const getColourPropertyValue = (
  colourType: string,
  defaultColour: ColourArray,
  colourProperty: string
): ColourArray | ((d: GeojsonData) => ColourArray) => {
  return isSimpleColourType(colourType)
    ? defaultColour
    : (d: GeojsonData) =>
        (d[colourProperty] as ColourArray) ||
        rgbToColourArr(_.get(d, 'properties.hints.style.color'))
}

export const getNewLayerStyle = (
  style: MapLayerStyle,
  layerType: MapLayerType,
  payload: Payload
): { style: MapLayerStyle } => {
  if (_.isNil(layerType)) return { style }
  return {
    style: {
      ...style,
      [layerType]: { ...(style[layerType] || {}), ...payload },
    },
  }
}

export const isNonAggregatedPointLayer = (layer: MapLayer): boolean => {
  const layerType = _.get(layer, 'type')
  if (LAYER_TYPES.point !== layerType) return true

  const layerStyle = _.get(layer, ['style', layerType], {})
  const { arePointsAggregated } = layerStyle
  return !arePointsAggregated
}

export const isCurrentZoomInVisibilityRange = (
  zoomVisibilityRange: number[],
  currentZoom?: number
): boolean => {
  if (!currentZoom) return true

  const [minZoom, maxZoom] =
    zoomVisibilityRange || LAYER_VIS_CONFIGS.zoomVisibilityRange.defaultValue
  return isValueInRange(currentZoom, [minZoom, maxZoom], true)
}

export const getLayerMultiLabelsVisibleValues = (
  layer: MapLayer,
  currentZoom?: number
): boolean | boolean[] => {
  const { type, isVisible = true, style } = layer
  const { propertyLabels } = style[type]
  if (!propertyLabels) return isVisible

  return isVisible
    ? _.map(propertyLabels, property =>
        isCurrentZoomInVisibilityRange(
          property.zoomVisibilityRange,
          currentZoom
        )
      )
    : [false]
}

export const isLayerVisible =
  (currentZoom?: number) =>
  (layer: MapLayer): boolean | boolean[] => {
    const { isVisible = true, type, style } = layer
    if (_.isNil(currentZoom) || !isVisible) return isVisible

    const {
      zoomVisibilityRange: [minZoom, maxZoom] = LAYER_VIS_CONFIGS
        .zoomVisibilityRange.defaultValue,
    } = style[type] || {}
    if (!isValueInRange(currentZoom, [minZoom, maxZoom], true)) return false
    if (!_.includes(MULTI_LABELS_LAYER_TYPES, type)) return true

    return getLayerMultiLabelsVisibleValues(layer, currentZoom)
  }

export const getLayerStyle = (layer: MapLayer): MapLayerStylePerType =>
  _.get(layer, ['style', layer?.type], {})

export const isAdvancedLineColourLayer = (layer: MapLayer): boolean => {
  const { type } = layer
  const { lineColourType } = getLayerStyle(layer)
  return type === LAYER_TYPES.upGeojson && isAdvancedColourType(lineColourType)
}

export const isAdvancedFillColourLayer = (layer: MapLayer): boolean => {
  const { type } = layer
  const { colourType, fillColourType } = getLayerStyle(layer)
  return type === LAYER_TYPES.point || type === LAYER_TYPES.arc
    ? isAdvancedColourType(colourType)
    : type === LAYER_TYPES.upGeojson && isAdvancedColourType(fillColourType)
}

export const getDefaultLayerStyle = (
  style: Payload = {},
  defaultValue: Payload = {}
): Payload => {
  return _.defaults({}, style, defaultValue)
}
