// libraries
import { ReactElement } from 'react'

// components
import { Button, Tooltip } from 'components/common'

// constants
import { PRINT_PDF } from 'constants/assets'
import { PLACEMENT } from 'constants/common'
import {
  BUTTON_VARIANTS,
  BUTTON_ICON_POSITIONS,
} from 'components/common/Button'

// styles
import scss from './index.module.scss'

type AssetProfileDownloadPdfLinkProps = {
  assetId: string
  profileName: string
}

const AssetProfileDownloadPdfLink = ({
  assetId,
  profileName,
}: AssetProfileDownloadPdfLinkProps): ReactElement => {
  return (
    <Tooltip
      placement={PLACEMENT.top}
      trigger={['hover']}
      overlay='Download as pdf'
    >
      <Button
        className={`me-1 ${scss.downloadPdf}`}
        onClick={() =>
          window.open(
            `/assets/profiles/${profileName}/print/${assetId}${PRINT_PDF}`
          )
        }
        variant={BUTTON_VARIANTS.secondary}
        icon='FaFilePdf'
        iconPosition={BUTTON_ICON_POSITIONS.left}
      >
        DOWNLOAD PDF
      </Button>
    </Tooltip>
  )
}

export default AssetProfileDownloadPdfLink
