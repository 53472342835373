import { ReactElement } from 'react'

import { TOOLTIP_PLACEMENT } from 'constants/settings'
import { Tooltip } from 'components/common'
import { IconButtonProps } from 'components/common/IconButton'

import { StyledToolbarButton } from './style'

type ToolbarButtonProps = IconButtonProps & {
  tooltip: string
  isActive?: boolean
  color?: string
  isHeading?: boolean
}

const ToolbarButton = ({
  tooltip,
  ...btnProps
}: ToolbarButtonProps): ReactElement => (
  <Tooltip placement={TOOLTIP_PLACEMENT.top} overlay={<span>{tooltip}</span>}>
    <StyledToolbarButton {...btnProps} />
  </Tooltip>
)

export default ToolbarButton
