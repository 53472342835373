// libraries
import _ from 'lodash'
import * as d3 from 'd3'
import bbox from '@turf/bbox'
import { WebMercatorViewport } from 'react-map-gl'
import { featureCollection, feature } from '@turf/helpers'

// constants
import { TIME_PROPERTY_KEY } from 'constants/unipipe'
import {
  GEOJSON_TYPES,
  GEOMETRY_TYPES,
  MAP_STYLE_TYPES,
  DEFAULT_MAP_NAME,
  MINIMUM_MAP_REFRESH_RATE,
  DEFAULT_LIVE_DATASET_REFRESH_RATE,
  MAP_SCREENSHOT_WIDTH,
  MAP_SCREENSHOT_HEIGHT,
  INITIAL_MAP_STATE,
} from 'constants/map'
import { ENTITIES } from 'constants/common'

// utils
import {
  switchcase,
  isRangeValid,
  numberSort,
  getNewEntityId,
} from 'helpers/utils'
import { isLiveDataset, getDatasetIdentifier } from 'helpers/unipipe'
import { convertGeometriesToFeatureCollection } from 'helpers/ancillaryData'
import log from 'helpers/log'

import type { Catalog } from 'types/unipipe'
import type { Map, MapLayer, GeojsonData, Viewport, MapBounds } from 'types/map'

export const getPropertyKeyByName = (name: string): string =>
  `properties.${name}`

export const getPropertyValueByName = (
  featureObject: GeojsonData,
  name: string
): unknown => _.get(featureObject, getPropertyKeyByName(name), '')

/**
 * Get specific property data domain
 * @param {Array} data: input data
 * @param {String} key: property name
 *
 * @param {Array} [min,max] data domain
 */
export const getPropertyDataDomain = (
  data: [],
  key: string
): [number, number] => {
  const [min, max] = d3.extent(data, d => _.get(d, key))
  return [Number(min), Number(max)]
}

export const getDatasetObject = (layer: MapLayer = {}, catalog: Catalog) => {
  const { dataset, catalogId } = layer
  const datasetIdentifier = getDatasetIdentifier(catalogId, dataset)

  return _.get(catalog, `${datasetIdentifier}`, {})
}

export const getLatLngBounds = (points, idx, limit) => {
  const lats = points
    .map(d => Array.isArray(d) && d[idx])
    .filter(Number.isFinite)
    .sort(numberSort)

  if (!lats.length) return null

  // use 99 percentile to filter out outliers
  // clamp to limit
  return [
    Math.max(lats[Math.floor(0.01 * (lats.length - 1))], limit[0]),
    Math.min(lats[Math.ceil(0.99 * (lats.length - 1))], limit[1]),
  ]
}

const convertBoundsArrayToFeatureBounds = (bounds: number[]): MapBounds => {
  const [minLng, minLat, maxLng, maxLat] = bounds
  // find the target viewport that fits around a LngLat bounding box
  return [
    [minLng, minLat],
    [maxLng, maxLat],
  ]
}
/**
 * returns boundary extent of the given feature in [minX, minY, maxX, maxY] order
 */
export const getBounds = (geoFeature: GeojsonData): MapBounds | undefined => {
  if (!geoFeature) return undefined
  try {
    const bounds = bbox(geoFeature)

    if (bounds.some(value => value === (Infinity || -Infinity)))
      return undefined

    return convertBoundsArrayToFeatureBounds(bounds)
  } catch {
    return undefined
  }
}

type WindowSize = Partial<{
  width: number,
  height: number,
}>

export const getViewportByBounds = (
  bounds?: MapBounds,
  windowSize?: WindowSize
): Viewport => {
  if (_.isEmpty(bounds)) return {}

  const { width = INITIAL_MAP_STATE.width, height = INITIAL_MAP_STATE.height } =
    windowSize || {}
  const projection = new WebMercatorViewport({
    width,
    height,
  })

  return new WebMercatorViewport(projection).fitBounds(bounds)
}
/**
 * Get a new flat viewport that fits around the given features.
 * @param {Object} geoFeature - any GeoJSON object
 * @param {Object} windowSize - the dimensions of map container window {width, height}.
 *
 * @return {Object} a new map viewport
 */
export const getFeaturesViewport = (
  geoFeature: GeojsonData,
  windowSize: WindowSize
): Viewport => {
  const bounds = getBounds(geoFeature)
  return getViewportByBounds(bounds, windowSize)
}

const HISTORY_PROPERTIES_LIST_LENGTH = 10

export const getHistoricalProperties = (oldObject = {}, currentProperties) => {
  let historyProperties

  if (!oldObject || _.isEmpty(oldObject.historyProperties)) {
    historyProperties = [currentProperties]
  } else {
    historyProperties = _.uniqBy(
      [currentProperties, ...oldObject.historyProperties],
      TIME_PROPERTY_KEY
    ).slice(0, HISTORY_PROPERTIES_LIST_LENGTH - 1)
  }
  return historyProperties
}

export const getPropertyScale = (
  data,
  property,
  range = [0, 1],
  propertyValueRange
) => {
  if (!property) return _.noop

  const domain = isRangeValid(propertyValueRange)
    ? propertyValueRange
    : getPropertyDataDomain(data, getPropertyKeyByName(property))

  return d3.scaleLinear().domain(domain).range(range)
}

export const getNewMapId = () => getNewEntityId(ENTITIES.map)

export const getNewMap = ({
  id = getNewMapId(),
  name = DEFAULT_MAP_NAME,
  style = MAP_STYLE_TYPES.dark,
  viewState = {},
  layers = [],
  ...rest
}: Partial<Map> = {}): Map => {
  return {
    ...rest,
    id,
    name,
    layers,
    style,
    viewState,
  }
}

/**
 * Get the minimum refresh rate among all valid layers
 * @param {Object} layers
 * @param {Array} catalog
 */
export const getMapRefreshRate = (layers, catalog) => {
  let mapRefreshRate
  if (!_.isEmpty(layers)) {
    // get refreshRate hints only from visible and live datasets
    const refreshRatesArray = _(layers)
      .filter('isVisible')
      .filter('dataset')
      .map(layer => {
        const hints = _.get(catalog, [layer.dataset, 'hints'])
        let rate
        if (hints) {
          const {
            timeliness,
            refreshRate = DEFAULT_LIVE_DATASET_REFRESH_RATE,
          } = hints
          rate = isLiveDataset(timeliness) ? refreshRate : 0
        }
        return rate
      })
      .compact()
      .value()

    // get the minimum refresh rate
    const minimumRate = d3.min(refreshRatesArray)
    mapRefreshRate = minimumRate
      ? _.clamp(minimumRate, MINIMUM_MAP_REFRESH_RATE, Infinity)
      : null
  }
  return mapRefreshRate
}

export const getBaseMapStyle = styleName =>
  switchcase(MAP_STYLE_TYPES)(MAP_STYLE_TYPES.dark)(styleName)

export const captureScreenshot = (srcData, width = 200, height = 200) => {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  let xStart = 0
  let yStart = 0
  let newWidth
  let newHeight

  canvas.width = width
  canvas.height = height

  let aspectRadio = srcData.height / srcData.width

  if (srcData.height < srcData.width) {
    // horizontal
    aspectRadio = srcData.width / srcData.height
    newHeight = height
    newWidth = aspectRadio * height
    xStart = -(newWidth - width) / 2
  } else {
    // vertical
    newWidth = width
    newHeight = aspectRadio * width
    yStart = -(newHeight - height) / 2
  }

  ctx.drawImage(srcData, xStart, yStart, newWidth, newHeight)

  return canvas.toDataURL('image/png', 0.75)
}

export const captureMapScreenshot = options => {
  const {
    width = MAP_SCREENSHOT_WIDTH,
    height = MAP_SCREENSHOT_HEIGHT,
    scale = 1,
    format,
    quality,
  } = options || {}

  // Only capture the screenshot of the mapbox and deckgl canvas
  // assume there is ONLY ONE map component get the mapboxgl canvas
  const baseMapCanvas = document.getElementsByClassName('mapboxgl-canvas')[0]
  // get the deckgl overlay canvas
  const overlayCanvas = document.getElementById('deckgl-overlay')
  if (!baseMapCanvas) {
    // return null if mapboxgl canvas doesn't exist
    return null
  }

  const elements = [baseMapCanvas, overlayCanvas]

  const newCanvas = document.createElement('canvas')
  const newContext = newCanvas.getContext('2d')

  const ratio = width / height
  newCanvas.width = width * scale
  newCanvas.height = height * scale

  elements.forEach(element => {
    const sourceX = 0
    const sourceY = 0
    const sourceWidth = element.width
    const sourceHeight = sourceWidth / ratio
    const destX = 0
    const destY = 0
    const destWidth = newCanvas.width
    const destHeight = newCanvas.height
    // https://www.html5canvastutorials.com/tutorials/html5-canvas-image-crop/
    newContext.drawImage(
      element,
      sourceX,
      sourceY,
      sourceWidth,
      sourceHeight,
      destX,
      destY,
      destWidth,
      destHeight
    )
  })

  return newCanvas.toDataURL(format, quality)
}

export const getAlignmentBaselineDeck = position =>
  switchcase({
    top: 'bottom',
    bottom: 'top',
    center: 'center',
  })('center')(position)

export const getSourceFeature = d => _.get(d, '__source.object')

/**
 * Create a feature Collection from a GeoJSON object
 * @param {Object} - geojson object
 *
 * @return {FeatureCollection} - FeatureCollection of Feature
 */
export const createFeatureCollectionFromGeojsonObj = (geojsonObj = {}) => {
  const { type } = geojsonObj

  if (type === GEOMETRY_TYPES.GeometryCollection) {
    return convertGeometriesToFeatureCollection(geojsonObj)
  }

  if (GEOMETRY_TYPES[type]) {
    return featureCollection([feature(geojsonObj)])
  }

  if (type === GEOJSON_TYPES.Feature) {
    return featureCollection([geojsonObj])
  }

  if (type === GEOJSON_TYPES.FeatureCollection) {
    return geojsonObj
  }

  throw new Error(`GeoJSON type: ${type} is not supported`)
}

export const getBase64FromUrl = async url => {
  const defaultValue = ''
  if (!url) {
    log.error('getBase64FromUrl', 'Not valid url')
    return Promise.resolve(defaultValue)
  }

  return fetch(url)
    .then(d => d.blob())
    .then(blob => {
      return new Promise(resolve => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
          const { result } = reader
          const isImageData = _.includes(result, 'data:image/')
          resolve(isImageData ? result : '')
        }
        reader.onerror = () => resolve(defaultValue)
      })
    })
    .catch(e => {
      log.error('getBase64FromUrl', e)
      return defaultValue
    })
}

export const generatePolygonBbox = rawPolygons => {
  if (_.isEmpty(rawPolygons)) return undefined

  return rawPolygons.map(polygon => {
    return {
      ...polygon,
      bbox: bbox(polygon),
    }
  })
}

export const getMapZoom = zoom => _.floor(zoom, 1)

/**
 * Sort GeojsonData by time type property, the default property is 'time'
 * @param {Array} geojsonData
 * @param {String} [timePropertyKey='time']
 */
export const sortGeojsonDataByTimeTypeProperty = (
  geojsonData,
  timePropertyKey = TIME_PROPERTY_KEY
) => {
  return _.sortBy(geojsonData, [
    d => Date.parse(_.get(d, ['properties', timePropertyKey])),
  ])
}

export const getHighlightedObjectIndex = highlightedObjectIndex =>
  Number.isInteger(highlightedObjectIndex) ? { highlightedObjectIndex } : {}

export const getCurrentZoomLvlFromMapRef = mapRef =>
  _.get(mapRef, 'current.deck.props.viewState.zoom')
