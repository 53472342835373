// libraries
import { ReactElement } from 'react'
import _ from 'lodash'
import { ColumnBodyOptions } from 'primereact/column'

// utils
import { useAuthStateValue } from 'contexts'
import { getGroupDisplayName } from 'helpers/user'

const Template = ({
  rowData,
  options: { field },
}: {
  rowData: unknown
  options: ColumnBodyOptions
}): ReactElement => {
  const group = _.get(rowData, field)

  const { userGroupsOptions } = useAuthStateValue()

  return (
    <span className='text-capitalize'>
      {getGroupDisplayName({ userGroupsOptions, group })}
    </span>
  )
}

const GroupTemplate = (
  rowData: unknown,
  options: ColumnBodyOptions
): ReactElement => {
  return <Template rowData={rowData} options={options} />
}

export default GroupTemplate
