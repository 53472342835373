import { sanitizeString } from 'helpers/utils'
import prepareHtmlForSave from './prepareHtmlForSave'

/** Removes any HTML from the editor result */
const getPlainText = (html: string, propPrefix?: string): string => {
  const preparedHtml = prepareHtmlForSave(html, propPrefix)
  return sanitizeString(preparedHtml) || ''
}

export default getPlainText
