// libraries
import React, { useCallback, useRef, useEffect, useState, useMemo } from 'react'
import { useMount, useGetSet, useToggle } from 'react-use'
import { useDropzone } from 'react-dropzone'
import _ from 'lodash'
import PropTypes from 'prop-types'
import mapboxgl from 'mapbox-gl'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import { StylesControl } from 'mapbox-gl-controls'

// utils
import { useBranding } from 'hooks'
import { convertGeometryCollectionToFeatureCollection } from 'helpers/ancillaryData'
import { getBounds, captureScreenshot } from 'helpers/map'
import { uniqueStringId } from 'helpers/utils'
import { showError } from 'helpers/message'
import log from 'helpers/log'
import { coordinatesGeocoder } from 'helpers/geojson'

// constants
import { FILE_MIME_TYPE_EXTENSIONS_MAPPING } from 'constants/common'
import { MAP_STYLE_TYPES, INITIAL_MAP_STATE } from 'constants/map'
import { MAPBOX_POLYGON_ICON } from 'constants/ancillaryData'

// components
import { Loading } from 'components/common'

// style
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import 'mapbox-gl-controls/lib/controls.css'

import scss from './index.module.scss'

const StaticMode = require('@mapbox/mapbox-gl-draw-static-mode')

const { longitude, latitude, zoom } = INITIAL_MAP_STATE

const drawStyle = [
  // INACTIVE (static, already drawn)
  // line stroke
  {
    id: 'gl-draw-line-static',
    type: 'line',
    filter: ['all', ['==', '$type', 'LineString'], ['==', 'mode', 'static']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#fafafa',
      'line-width': 3,
    },
  },
  // polygon fill
  {
    id: 'gl-draw-polygon-fill-static',
    type: 'fill',
    filter: ['all', ['==', '$type', 'Polygon'], ['==', 'mode', 'static']],
    paint: {
      'fill-color': '#fafafa',
      'fill-outline-color': '#fafafa',
      'fill-opacity': 0.2,
    },
  },
  // polygon outline
  {
    id: 'gl-draw-polygon-stroke-static',
    type: 'line',
    filter: ['all', ['==', '$type', 'Polygon'], ['==', 'mode', 'static']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#fafafa',
      'line-width': 3,
    },
  },
]

const GeofenceField = ({
  id,
  input,
  className,
  viewOnly,
  enableSearch,
  isLoading,
  enableStylesControl,
}) => {
  const { mapboxAccessToken } = useBranding()

  const geofenceMapId = useMemo(
    () => `geofence-${uniqueStringId()}-${id}`,
    [id]
  )
  const inputGeoJSON = input.value.geojson

  const [getMapInstance, setMapInstance] = useGetSet()
  const [getDrawInstance, setDrawInstance] = useGetSet()
  const [displayUploader, toggleUploader] = useToggle(_.isEmpty(inputGeoJSON))
  const [fileName, setFileName] = useState()
  const [geojson, setGeojson] = useState(inputGeoJSON)

  useEffect(() => setGeojson(inputGeoJSON), [inputGeoJSON])

  const onDropAccepted = useCallback(
    acceptedFiles => {
      acceptedFiles.forEach(asset => {
        const reader = new FileReader()

        reader.onabort = () => log.error('file reading was aborted')
        reader.onerror = () => log.error('file reading has failed')
        reader.onload = () => {
          const { result } = reader
          const { name } = asset
          const newResult = convertGeometryCollectionToFeatureCollection(
            name,
            result
          )
          setGeojson(JSON.parse(newResult))
          setFileName(name)
        }
        reader.readAsBinaryString(asset)
      })
      toggleUploader(false)
    },
    [toggleUploader]
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    disabled: !displayUploader,
    accept: FILE_MIME_TYPE_EXTENSIONS_MAPPING.geojson,
    onDropRejected: () => showError('Only geojson or geojson will be accepted'),
    multiple: false,
  })

  const mapboxDraw = useRef()
  const mapboxGeocoder = useRef()

  const updateArea = useCallback(() => {
    const geojsonData = getDrawInstance().getAll()
    setGeojson(geojsonData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDrawInstance()])

  useMount(() => {
    // Create the map
    mapboxgl.accessToken = mapboxAccessToken

    const map = new mapboxgl.Map({
      container: geofenceMapId, // container id
      style: MAP_STYLE_TYPES.satellite,
      center: [longitude, latitude], // starting position
      zoom, // starting zoom,
      preserveDrawingBuffer: true,
    })

    const styles = [
      {
        label: 'Dark',
        styleName: 'Dark',
        styleUrl: MAP_STYLE_TYPES.dark,
      },
      {
        label: 'Light',
        styleName: 'Light',
        styleUrl: MAP_STYLE_TYPES.light,
      },
      {
        label: 'Streets',
        styleName: 'Streets',
        styleUrl: MAP_STYLE_TYPES.streets,
      },
      {
        label: 'Satellite',
        styleName: 'Satellite',
        styleUrl: MAP_STYLE_TYPES.satellite,
      },
      {
        label: 'SensorUp Light',
        styleName: 'SensorUp Light',
        styleUrl: MAP_STYLE_TYPES.suLight,
      },
      {
        label: 'SensorUp Dark',
        styleName: 'SensorUp Dark',
        styleUrl: MAP_STYLE_TYPES.suDark,
      },
      {
        label: 'SensorUp Monochrome',
        styleName: 'SensorUp Monochrome',
        styleUrl: MAP_STYLE_TYPES.suMonochrome,
      },
      {
        label: 'SensorUp Satellite',
        styleName: 'SensorUp Satellite',
        styleUrl: MAP_STYLE_TYPES.suSatellite,
      },
    ]

    if (enableStylesControl) {
      // with custom styles:
      map.addControl(
        new StylesControl({
          styles,
        }),
        'top-right'
      )
    }

    const { modes } = MapboxDraw
    modes.static = StaticMode

    const draw = viewOnly
      ? new MapboxDraw({
          displayControlsDefault: false,
          controls: {},
          modes,
          styles: drawStyle,
        })
      : new MapboxDraw({
          displayControlsDefault: false,
          controls: {
            polygon: true,
            trash: true,
          },
        })

    map.addControl(draw)

    let geocoder
    if (enableSearch) {
      geocoder = new MapboxGeocoder({
        // Initialize the geocoder
        accessToken: mapboxAccessToken, // Set the access token
        mapboxgl, // Set the mapbox-gl instance
        marker: true, // add marker
        placeholder: 'Search ...', // Placeholder text for the search bar.
        setFlyTo: true,
        localGeocoder: coordinatesGeocoder,
      })

      mapboxGeocoder.current.appendChild(geocoder.onAdd(map))
    }

    map.on('draw.actionable', () => {
      // A workaround to capture user click event polygon button
      // because it seems that is not click event for the control button
      toggleUploader(false)
    })

    map.on('draw.create', updateArea)
    map.on('draw.delete', updateArea)
    map.on('draw.update', updateArea)
    setDrawInstance(draw)
    setMapInstance(map)

    if (enableSearch && geocoder) {
      geocoder.setFlyTo(true)
    }
  })

  useEffect(() => {
    const drawInstance = getDrawInstance()
    if (!drawInstance) return

    drawInstance.deleteAll()
    if (_.isEmpty(geojson)) return

    const mapInstance = getMapInstance()
    if (mapInstance && mapInstance.loaded) {
      try {
        // Note:
        // geometryCollection is not supported in the mapbox draw
        // https://github.com/mapbox/mapbox-gl-draw/pull/903
        // See convertGeometryCollectionToFeatureCollection 'helpers/ancillaryData'
        // when geometryCollection was uploaded or viewed, the GeometryCollection will be converted to a FeatureCollection
        drawInstance.add(geojson)

        if (viewOnly) {
          drawInstance.changeMode('static')
        }

        const bounds = getBounds(geojson)

        if (bounds) {
          mapInstance.fitBounds(bounds, {
            padding: 50,
            linear: true,
          })
          if (!viewOnly) {
            setTimeout(() => {
              const thumbnailData = captureScreenshot(mapInstance.getCanvas())
              input.onChange({
                geojson,
                thumbnail: thumbnailData,
              })
            }, 100)
          }
        } else {
          setGeojson()
          if (!viewOnly) {
            input.onChange({})
          }
        }
      } catch (error) {
        showError(`Sorry, something went wrong: ${error.message}`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMapInstance(), geojson])

  useEffect(() => {
    if (!fileName && !viewOnly)
      input.onChange({
        file: fileName,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileName])

  return (
    <div className={`${scss.container} ${className}`}>
      {isLoading && (
        <div className={scss.overlay}>
          <Loading className='position-absolute' />
        </div>
      )}
      {enableSearch && (
        <div
          ref={mapboxGeocoder}
          id={`geocoder-${geofenceMapId}`}
          className={scss.searchBox}
          onClick={() => toggleUploader(false)}
        />
      )}
      {!viewOnly && displayUploader && (
        <div {...getRootProps({ className: `${scss.dropZone}` })}>
          <input {...getInputProps()} />
          <div className={scss.text}>
            <div className={scss.subtitle}>
              Click
              <img alt='polygon' src={MAPBOX_POLYGON_ICON} />
              to draw a custom geofence
            </div>
            <div className={scss.title}>
              Drag and drop a geojson file here, or click to select a geojson
              file
            </div>
            <div className={scss.small}>
              (Only *.json and *.geojson will be accepted)
            </div>
          </div>
        </div>
      )}

      <div ref={mapboxDraw} id={geofenceMapId} className={scss.map} />
    </div>
  )
}

GeofenceField.propTypes = {
  id: PropTypes.string,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.shape({
        geojson: PropTypes.shape({
          geojson: PropTypes.shape({}),
        }),
      }),
      PropTypes.string,
    ]),
  }).isRequired,
  className: PropTypes.string,
  viewOnly: PropTypes.bool,
  enableSearch: PropTypes.bool,
  isLoading: PropTypes.bool,
  enableStylesControl: PropTypes.bool,
}

GeofenceField.defaultProps = {
  id: '',
  className: undefined,
  viewOnly: false,
  enableSearch: true,
  isLoading: false,
  enableStylesControl: false,
}

export default GeofenceField
