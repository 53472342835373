import { ReactElement, useMemo } from 'react'
import _ from 'lodash'

// constants
import { BUTTON_VARIANTS } from 'components/common/Button'
import { MFA_OPTIONS, NO_AVAILABLE_DATA } from 'constants/common'
import { USER_EDIT_MODE } from 'constants/user'

// utils
import { useUserAbility } from 'hooks'

// types
import { User, UserSettingMode } from 'types/user'

// components
import { Accordion, Button } from 'components/common'
import TwoStepAuthentication from './TwoStepAuthentication'
import TrustedDevices from './TrustedDevices'

// styles
import scss from '../index.module.scss'

const SecurityAndPolicy = ({
  user,
  onModeChange,
}: {
  user: User
  onModeChange: (v: UserSettingMode) => void
}): ReactElement => {
  const { mfaPreferred, consents, phone, devices, hasLoginCredentials } = user
  const { canUpdate } = useUserAbility({ user })

  const rememberedDevicesCount = useMemo(
    () => _.filter(devices, 'remembered').length,
    [devices]
  )

  return (
    <>
      {hasLoginCredentials && (
        <>
          <div className='pillLabel text-secondary'>SECURITY</div>
          <div className={scss.section}>
            <Accordion
              testId='2mfSecurity'
              title={
                <>
                  <span>2-Step authentication: </span>
                  <span className='text-secondary'>
                    {_.find(MFA_OPTIONS, { value: mfaPreferred })?.label ||
                      NO_AVAILABLE_DATA}
                  </span>
                </>
              }
              content={
                <TwoStepAuthentication
                  mfaPreferred={mfaPreferred}
                  phone={phone}
                  onModeChange={onModeChange}
                />
              }
              flush
            />
            <Accordion
              title={
                <>
                  <span>Trusted devices: </span>
                  {rememberedDevicesCount && (
                    <span className='text-secondary'>
                      {rememberedDevicesCount} devices
                    </span>
                  )}
                </>
              }
              content={<TrustedDevices devices={devices} />}
              flush
            />
            {canUpdate && (
              <Accordion
                title='Active sessions'
                collapsible={false}
                addOn={
                  <Button
                    variant={BUTTON_VARIANTS.link}
                    onClick={() =>
                      onModeChange(USER_EDIT_MODE.globalSignOutConfirm)
                    }
                  >
                    End sessions
                  </Button>
                }
                flush
              />
            )}
            {canUpdate && (
              <Accordion
                title='Password'
                collapsible={false}
                addOn={
                  <Button
                    variant={BUTTON_VARIANTS.link}
                    onClick={() =>
                      onModeChange(USER_EDIT_MODE.resetPasswordConfirm)
                    }
                  >
                    Reset
                  </Button>
                }
                flush
              />
            )}
          </div>
        </>
      )}

      <div className='pillLabel text-secondary'>PRIVACY</div>
      <div className={scss.section}>
        <Accordion
          title={
            <>
              <span>Privacy & Term of conditions: </span>
              <span className='text-secondary'>
                {_.isEmpty(consents) ? NO_AVAILABLE_DATA : 'Accepted'}
              </span>
            </>
          }
          collapsible={false}
          addOn={
            <Button
              variant={BUTTON_VARIANTS.link}
              {...(_.isEmpty(consents)
                ? {}
                : { onClick: () => onModeChange(USER_EDIT_MODE.consents) })}
            >
              {_.isEmpty(consents) ? '' : 'view'}
            </Button>
          }
          flush
        />
      </div>
    </>
  )
}

export default SecurityAndPolicy
