import * as Sentry from '@sentry/react'
import _ from 'lodash'
import log from 'loglevel'
import UAParser from 'ua-parser-js'

import { SeverityLevel } from '@sentry/react'

// utils
import Logging from 'services/logging'
import { isDevEnvironment, getNetworkState } from 'helpers/utils'

import type { User } from 'types/user'
import type { Payload } from 'types/common'

import { APPLICATION_NAME } from 'constants/common'

const isDevEnv = isDevEnvironment()

// * [loglevel Docs](https://github.com/pimterry/loglevel#documentation)
const logLevel = isDevEnv ? 'debug' : 'info'
log.setLevel(logLevel)

export const setLogUser = (user?: User): void => {
  if (isDevEnv || _.isEmpty(user)) return

  const caslRules = _.get(user, 'caslRules')
  const filters = _.get(user, 'filters')
  const userInfo = {
    ..._.pick(user, [
      'id',
      'group',
      'email',
      'identityId',
      'name',
      'phone',
      'role',
      'timezone',
      'username',
    ]),
    filters: filters && JSON.stringify(filters),
    caslRules: caslRules && JSON.stringify(caslRules),
  }

  Sentry.setUser(userInfo)
}

export const setNetworkState = (): void => {
  if (isDevEnv) return

  Sentry.setContext('Network State', getNetworkState())
}

export const reportSentryExtraData = (
  extras?: Payload,
  level: SeverityLevel = 'log'
): void => {
  if (_.isEmpty(extras)) return

  Sentry.withScope(scope => {
    _.forEach(extras, (value, key) => {
      scope.setExtra(key, value)
    })
    return scope.setLevel(level)
  })
}

export const reportMessage = (
  message: string,
  level: SeverityLevel = 'log',
  extras = {}
): void => {
  const logger = _.get(log, level, _.noop)
  logger(message)
  if (isDevEnv) return

  reportSentryExtraData(extras, level)
  Sentry.captureMessage(message, level)
}

export const reportException = (
  error: Error | string,
  extras?: Payload
): void => {
  const newError = _.isObject(error) ? error : new Error(error)
  log.error(newError)
  if (isDevEnv && !_.isEmpty(extras)) {
    log.error(extras)
  }
  if (isDevEnv) return

  reportSentryExtraData(extras, 'error')
  Sentry.captureException(newError)
}

export const reportErrors = (error: Error | string, extras?: Payload): void => {
  reportException(error, extras)
  setNetworkState()
}

const setUserAgent = () => {
  if (isDevEnv) return

  const parsedUserAgent = new UAParser(navigator.userAgent).getResult()
  Sentry.setContext('UserAgent', parsedUserAgent)
}

export const initSentry = (user?: User): void => {
  if (isDevEnv) return

  const { environment, sentryDsn, versions, appVersion } = Logging

  if (!sentryDsn) log.error('Sentry DSN is missing')

  Sentry.init({
    environment,
    dsn: sentryDsn,
    release: appVersion,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({ maskAllText: true }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications',
      'The user aborted a request',
      'network error',
      'ChunkLoadError',
      'AbortError: Fetch is aborted',
      'TypeError: Failed to fetch',
      'TypeError: NetworkError when attempting to fetch resource.',
      'TypeError: cancelled',
      'TypeError: Preflight response is not successful',
    ],
    denyUrls: ['localhost'],
  })
  Sentry.setTag('product.release', versions?.[APPLICATION_NAME])
  setLogUser(user)
  setUserAgent()
  setNetworkState()
}

export default log
