import { HexagonLayer as DeckGlHexagonLayer } from '@deck.gl/aggregation-layers/typed'
import { isRangeValid } from 'helpers/utils'
import { LAYER_TYPES } from 'constants/map'

import AggregationLayer from '../aggregationLayer'

export const hexagonVisConfigs = {
  opacity: 'opacity',
  radius: 'hexagonRadius',
  colourRange: 'colourRange',
  valueRangeForColour: 'valueRangeForColour',
  aggregationForColour: 'aggregationForColour',
  elevationScale: 'elevationScale',
  valueRangeForHeight: 'valueRangeForHeight',
  aggregationForHeight: 'aggregationForHeight',
}

export default class HexagonLayer extends AggregationLayer {
  constructor(props) {
    super(props)
    this.registerVisConfig(hexagonVisConfigs, props.style[this.type])
  }

  get type() {
    return LAYER_TYPES.hexagon
  }

  // Different from other layers, the HexagonLayer layer received data which
  // is applied all filters. Because deck.gl DataFilterExtension doesn't
  // support the HexagonLayer. See https://deck.gl/#/documentation/submodule-api-reference/deckgl-extensions/data-filter-extension?section=filtertransformcolor-boolean-optional- for more information
  renderLayer = ({ layerData, profileHandler }) => {
    const {
      visConfig: {
        radius,
        colourRange,
        opacity,
        valueRangeForColour,
        aggregationForColour,
        elevationScale,
        valueRangeForHeight,
        aggregationForHeight,
      },
    } = this.config

    const basicProps = this.getLayerBasicProps({ layerData })

    const isExtruded = elevationScale !== 0

    const stylingProps = {
      radius,
      opacity: this.getOpacity(opacity),
      autoHighlight: true,
      colorRange: colourRange,
      ...(isRangeValid(valueRangeForColour)
        ? { colorDomain: valueRangeForColour }
        : {}),
      extruded: isExtruded,
      elevationScale,
      ...(isRangeValid(valueRangeForHeight)
        ? { elevationDomain: valueRangeForHeight }
        : {}),
    }

    const profileProps = this.getProfileConfig(profileHandler)

    const dataAccessors = {
      getPosition: d => d.geometry.coordinates,
      getColorValue: points =>
        this.getAggregatedValue(points, aggregationForColour),
      getElevationValue: points =>
        this.getAggregatedValue(points, aggregationForHeight),
      updateTriggers: {
        getColorValue: [aggregationForColour],
        getElevationValue: [aggregationForHeight],
      },
    }

    return [
      new DeckGlHexagonLayer({
        ...basicProps,
        ...stylingProps,
        ...profileProps,
        ...dataAccessors,
      }),
    ]
  }
}
