import {
  ReactElement,
  ChangeEvent,
  FocusEventHandler,
  useState,
  useMemo,
} from 'react'
import _ from 'lodash'
import { FieldProps } from '@rjsf/utils'

import { convertToNumber } from 'components/common/Field/NumericInput'
import {
  isJsonFormWidgetReadonly,
  isJsonFormWidgetInline,
} from 'helpers/formBuilder'
import { NUMBER_FIELD_TYPES } from 'constants/formBuilder'

import { RangeSlider } from 'components/common'

import StepperButtons from './StepperButtons'
import { useInputIcons } from './hooks'
import { StyledInput } from './style'

const NumberInputFieldContent = ({
  id = '',
  name,
  className,
  placeholder,
  required,
  autofocus,
  disabled,
  // Default to string
  // so the value can be 'reset' in a preview when a schema is changed
  formData = '',
  onChange,
  onBlur,
  onFocus,
  schema,
  uiSchema,
  idSchema,
  hasError,
  formContext: { isPreview },
}: FieldProps & { hasError?: boolean }): ReactElement => {
  // For some reason some widgets doesn't get 'id' as prop
  const inputId = id || idSchema?.$id
  const { inputPaddings, WidgetIconsManager, ReadonlyIconsManager } =
    useInputIcons(uiSchema, isPreview)
  // Storing a focused state to spread it to the stepper buttons
  const [isFocused, setIsFocused] = useState(false)

  const isReadonly = isJsonFormWidgetReadonly(uiSchema)
  const isInline = isJsonFormWidgetInline(uiSchema)
  const isStepper = uiSchema?.['ui:widget'] === NUMBER_FIELD_TYPES.stepper
  const isSlider = uiSchema?.['ui:widget'] === NUMBER_FIELD_TYPES.slider
  const addon = _.get(uiSchema, '[ui:options].addonLabel.label')

  const sliderRange = useMemo(
    () => [schema.minimum, schema.maximum],
    [schema.minimum, schema.maximum]
  )

  const handleChange = ({
    target: { value: newValue },
  }: ChangeEvent<HTMLInputElement>) => onChange(convertToNumber(newValue, true))

  const handleBlur: FocusEventHandler<HTMLInputElement> = ({
    target: { value: newValue },
  }) => {
    setIsFocused(false)
    onBlur(inputId, convertToNumber(newValue, true))
  }

  const handleFocus: FocusEventHandler<HTMLInputElement> = ({
    target: { value: newValue },
  }) => {
    setIsFocused(true)
    onFocus(inputId, convertToNumber(newValue, true))
  }

  const input = (
    <StyledInput
      id={inputId}
      name={name}
      value={formData}
      type='number'
      paddingLeft={inputPaddings.left}
      paddingRight={inputPaddings.right}
      className={`form-control ${className}`}
      placeholder={placeholder || schema.placeholder}
      isStepper={isStepper}
      inline={isInline}
      required={required}
      disabled={disabled}
      isLarge={isPreview}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autofocus}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
    />
  )

  switch (true) {
    case isReadonly:
      return (
        <ReadonlyIconsManager>
          {_.isNumber(formData) ? formData : '–'}
        </ReadonlyIconsManager>
      )
    case isStepper:
      return (
        <StepperButtons
          formData={formData}
          onChange={onChange}
          disabled={disabled}
          inline={isInline}
          isFocused={isFocused}
          hasError={hasError}
          min={schema.minimum}
          max={schema.maximum}
          isLarge={isPreview}
        >
          {input}
        </StepperButtons>
      )
    case isSlider:
      return (
        <RangeSlider
          range={sliderRange}
          step={1}
          value={formData}
          unit={addon}
          onChange={onChange}
          makeUnitPlural={false}
          showMarks
          hideInput
          noGroupStyle
          isLarge={isPreview}
        />
      )
    default:
      return <WidgetIconsManager>{input}</WidgetIconsManager>
  }
}

export default NumberInputFieldContent
