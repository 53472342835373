// libraries
import {
  useState,
  useMemo,
  useCallback,
  useEffect,
  ReactElement,
  PropsWithChildren,
} from 'react'
import isEqual from 'fast-deep-equal'
import _ from 'lodash'

// constants
import { COLOUR_TYPE_OPTIONS } from 'constants/colour'
import { DEFAULT_MAP_COLOUR, DEFAULT_MAP_COLOUR_RAMP } from 'constants/map'

// utils
import { isRangeValid } from 'helpers/utils'
import { isSimpleColourType } from 'helpers/colour'
import { isNumericType } from 'helpers/filter'

import type {
  Payload,
  ColourClasses,
  Colours,
  PropertiesMetadata,
  ColourType,
  PropertyVariableType,
  ColourProperty,
  ColourArray,
} from 'types/common'

// components
import { TitleWithTooltip, MultiSelect } from 'components/common'
import ColourPickerSimple from '../ColourPickerSimple'
import ColourPickerAdvanced from '../ColourPickerAdvanced'
import ColourClassification from '../ColourClassification'

type ColourStyleProps = {
  colourClasses: ColourClasses
  colourProperty: ColourProperty
  colourPropertyType: PropertyVariableType
  colourType: ColourType
  fillColour: Colours
  range: Range
}

type ColourPickerProp = {
  title?: string
  tooltip?: string
  className?: string
  colourType: ColourType
  colourClasses?: ColourClasses
  range?: Range
  colour: Colours
  onChange: (props: ColourStyleProps) => void
  propertyOptions: PropertiesMetadata
  colourProperty: ColourProperty
}

const ColourPicker = ({
  title = 'Colour',
  tooltip,
  colour: oldColour,
  colourType: oldColourType,
  propertyOptions,
  colourProperty: oldColourProperty,
  range: oldRange,
  colourClasses: oldColourClasses,
  children,
  onChange,
  className = '',
}: PropsWithChildren<ColourPickerProp>): ReactElement => {
  const [colourStyleState, setColourStyleState] = useState(() => ({
    fillColour: oldColour,
    colourType: oldColourType,
    colourProperty: oldColourProperty,
    colourPropertyType: oldColourProperty?.type,
    range: oldRange,
    colourClasses: oldColourClasses,
  }))

  const {
    fillColour: colour,
    colourType,
    colourProperty,
    range,
    colourClasses,
    colourPropertyType,
  } = colourStyleState || {}

  useEffect(() => {
    const newColourStyleState = {
      fillColour: oldColour,
      colourType: oldColourType,
      colourProperty: oldColourProperty,
      colourPropertyType: oldColourProperty?.type,
      range: oldRange,
      colourClasses: oldColourClasses,
    }

    setColourStyleState(oldColourStyleState => {
      return isEqual(oldColourStyleState, newColourStyleState)
        ? oldColourStyleState
        : newColourStyleState
    })
  }, [oldColour, oldColourClasses, oldColourProperty, oldColourType, oldRange])

  const onColourStyleChange = useCallback(
    (payload: Payload) => {
      let newColourStyleState

      setColourStyleState(oldColourStyleState => {
        newColourStyleState = _.omitBy(
          { ...oldColourStyleState, ...payload },
          _.isNil
        )
        return newColourStyleState
      })

      if (!newColourStyleState) return

      onChange(newColourStyleState)
    },
    [onChange]
  )

  // set colour for point layer
  // [r,g,b,a] for a simple point map
  // [[r,g,b,a],...,[r,g,b,a]] for a advanced point map
  const isSimpleActive = useMemo(
    () => isSimpleColourType(colourType),
    [colourType]
  )

  const onColourTypeChange = useCallback(
    ({ value }: { value: ColourType }) => {
      const isColourSimple = isSimpleColourType(value)
      const newFillColour = isColourSimple
        ? DEFAULT_MAP_COLOUR
        : _.isEmpty()
        ? DEFAULT_MAP_COLOUR_RAMP
        : _.map(colourClasses, 'colour')
      const payload = { colourType: value, fillColour: newFillColour }
      onColourStyleChange(payload)
    },
    [colourClasses, onColourStyleChange]
  )

  const onColourPickerAdvancedChange = useCallback(
    (key: string, value: Payload) => {
      const styles = {} as {
        fillColour?: ColourArray
        range?: Range
        colourClasses?: ColourClasses
        colourProperty?: ColourProperty
        colourPropertyType?: PropertyVariableType
      }
      if (key === 'colourRange') {
        styles.fillColour = value
      } else if (key === 'range') {
        styles.range = value
        if (!isRangeValid(value)) {
          styles.colourClasses = []
        }
      } else if (key === 'property') {
        const { value: property, type } = value
        const newColourProperty = { key: property, type }
        styles.colourProperty = newColourProperty
        // keep all the classes information as much as we can and
        // only reset when user change the property type
        if (type !== colourPropertyType) {
          styles.colourClasses = []
          // styles.fillColour = []
          styles.colourPropertyType = type
        }
      }

      onColourStyleChange(styles)
    },
    [colourPropertyType, onColourStyleChange]
  )

  const shouldShowColourClassification = useMemo(() => {
    // Colour Classification will only be shown when
    // 1. Colour type is Advanced
    // 2.1 Selected property is number, range is not empty
    // 2.2 Selected property is string
    let isVisible = false
    if (isSimpleActive) {
      isVisible = false
    } else if (colourPropertyType) {
      isVisible = isNumericType(colourPropertyType) ? isRangeValid(range) : true
    }

    return isVisible
  }, [isSimpleActive, colourPropertyType, range])

  const onColourClassificationChange = useCallback(
    (newClasses: ColourClasses) => {
      onColourStyleChange({ colourClasses: newClasses })
    },
    [onColourStyleChange]
  )

  return (
    <>
      <div className={`${className} groupOption`}>
        <div className='groupOptionTitle d-flex justify-content-between align-items-center'>
          <TitleWithTooltip title={title} tooltip={tooltip} />
          {children}
        </div>
        <div className='groupOptionContent'>
          <div className='disableOverlay' />
          <div>
            <div className='groupOptionLabel'>Type</div>
            <MultiSelect
              value={colourType}
              options={COLOUR_TYPE_OPTIONS}
              onChange={onColourTypeChange}
              isMulti={false}
              isClearable={false}
            />
            {isSimpleActive ? (
              <>
                <div className='groupOptionLabel'>Select Colour</div>
                <ColourPickerSimple
                  colour={colour}
                  onChange={value => onColourStyleChange({ fillColour: value })}
                />
              </>
            ) : (
              <ColourPickerAdvanced
                colourRange={colour}
                propertyOptions={propertyOptions}
                colourProperty={colourProperty}
                range={range}
                onChange={onColourPickerAdvancedChange}
              />
            )}
          </div>
        </div>
      </div>
      {shouldShowColourClassification && (
        <ColourClassification
          colours={colour}
          colourProperty={colourProperty}
          onChange={onColourClassificationChange}
          colourClasses={colourClasses}
          range={range}
          propertyOptions={propertyOptions}
        />
      )}
    </>
  )
}

export default ColourPicker
