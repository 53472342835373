// libraries
import { ReactElement, useMemo } from 'react'
import _ from 'lodash'

// constants
import { LAYER_TYPES, SUPPORT_CLUSTER_LAYER_TYPES } from 'constants/map'
import { COLOUR_STYLE_TYPES, THEMES } from 'constants/colour'
import { LAYER_VIS_CONFIGS } from 'components/map/layers/deckLayers/layerFactory'

// utils
import { getObjectHash } from 'helpers/utils'
import {
  getLayerStyle,
  isAggregatedLayer,
  isAdvancedFillColourLayer,
  isAdvancedLineColourLayer,
} from 'helpers/map'
import type { ColourStyleType, ThemeType } from 'types/common'
import type { MapLayer, MapLayerType, MapStyleType } from 'types/map'

// Components
import SimpleColourLegend from './SimpleColourLegend'
import AdvancedColourLegend from './AdvancedColourLegend'
import IconLegend from './IconLegend'

// styles
import { StyledSection, StyledLayersList } from './styles'

const getSharedLayerLegendProps = ({
  layer,
  canToggleVisibility,
  canToggleClustering,
  colourStyleType = COLOUR_STYLE_TYPES.fill,
  mapTheme,
}: {
  layer: MapLayer
  canToggleVisibility: boolean
  canToggleClustering: boolean
  colourStyleType?: ColourStyleType
  mapTheme: ThemeType
}): {
  name: string
  fillColour: number[]
  id: string
  type: MapLayerType
  canToggleVisibility: boolean
  canToggleClustering: boolean
  isVisible: boolean
  enableClustering: boolean
  colourRange: number[][]
  key: string
  style: MapStyleType
} => {
  const { id, name, type, style, isVisible = true } = layer
  const {
    fillColour,
    colourRange,
    lineColour,
    enableClustering = LAYER_VIS_CONFIGS.enableClustering.defaultValue,
  } = getLayerStyle(layer)
  const isLineColourStyleType = colourStyleType === COLOUR_STYLE_TYPES.line
  const validColourRange = isLineColourStyleType
    ? lineColour
    : _.isEmpty(colourRange)
    ? undefined
    : colourRange

  const legendProps = isLineColourStyleType
    ? { name: `${name} (${COLOUR_STYLE_TYPES.line})`, fillColour: lineColour }
    : {
        name,
        fillColour,
      }

  return {
    ...legendProps,
    id,
    type,
    isVisible,
    canToggleVisibility,
    enableClustering,
    canToggleClustering,
    colourRange: validColourRange,
    key: `${id}-${colourStyleType}-${getObjectHash(validColourRange)}`,
    mapTheme,
    style,
  }
}

const shouldDisplayLineColourLegend = (layer: MapLayer): boolean => {
  const { type } = layer
  return type === LAYER_TYPES.upGeojson
}

const renderIconLegend = (props: {
  layer: MapLayer
  canToggleVisibility: boolean
  canToggleClustering: boolean
}): ReactElement => {
  const { bgColour, iconPosition } = getLayerStyle(props.layer)
  const sharedProps = getSharedLayerLegendProps(props)
  return (
    <IconLegend
      {...sharedProps}
      bgColour={bgColour as number[]}
      iconPosition={iconPosition}
    />
  )
}

const renderAdvancedColourLegend = (props: {
  layer: MapLayer
  id: string
  isVisible: boolean
  enableClustering: boolean
  name: string
  canToggleVisibility?: boolean
  canToggleClustering?: boolean
  colourStyleType: ColourStyleType
}): ReactElement => {
  const { colourStyleType, layer } = props
  const {
    colourProperty,
    colourClasses,
    aggregationForColour,
    lineColourProperty,
    lineColourClasses,
    fillColourClasses,
    fillColourProperty,
  } = getLayerStyle(layer)

  const sharedProps = getSharedLayerLegendProps(props)
  const legendProps =
    colourStyleType === COLOUR_STYLE_TYPES.line
      ? { colourProperty: lineColourProperty, colourClasses: lineColourClasses }
      : {
          colourProperty: colourProperty || fillColourProperty,
          colourClasses: colourClasses || fillColourClasses,
          aggregationForColour,
        }
  return <AdvancedColourLegend {...sharedProps} {...legendProps} />
}

const renderSimpleLegend = (props: {
  layer: MapLayer
  canToggleVisibility: boolean
  canToggleClustering: boolean
  colourStyleType?: ColourStyleType
}): ReactElement => {
  const fillSharedProps = getSharedLayerLegendProps(props)
  return <SimpleColourLegend {...fillSharedProps} />
}

const renderLayerLegend = (props: {
  layer: MapLayer
  canToggleVisibility: boolean
  canToggleClustering: boolean
  mapTheme: ThemeType
}): ReactElement => {
  const { layer, canToggleClustering } = props
  const { type } = layer

  const supportClustering = _.includes(SUPPORT_CLUSTER_LAYER_TYPES, type)

  const sharedProps = {
    ...props,
    type,
    canToggleClustering: canToggleClustering && supportClustering,
  }

  if (type === LAYER_TYPES.icon) {
    return renderIconLegend(sharedProps)
  }

  if (isAggregatedLayer(layer)) {
    return renderAdvancedColourLegend(sharedProps)
  }

  const hasLineColourLegend = shouldDisplayLineColourLegend(layer)

  const fillLegend = isAdvancedFillColourLayer(layer)
    ? renderAdvancedColourLegend(sharedProps)
    : renderSimpleLegend(sharedProps)

  const lineProps = { ...sharedProps, colourStyleType: COLOUR_STYLE_TYPES.line }
  const lineLegend = hasLineColourLegend
    ? isAdvancedLineColourLayer(layer)
      ? renderAdvancedColourLegend(lineProps)
      : renderSimpleLegend(lineProps)
    : null

  return (
    <>
      {fillLegend}
      {lineLegend}
    </>
  )
}

const MapLegend = ({
  layers,
  canToggleVisibility,
  canToggleClustering,
  mapTheme,
}: {
  layers: MapLayer[]
  canToggleVisibility: boolean
  canToggleClustering: boolean
  mapTheme: ThemeType
}): ReactElement => {
  const isLightTheme = useMemo(() => mapTheme === THEMES.light, [mapTheme])

  return (
    <StyledLayersList isLightTheme={isLightTheme}>
      {layers.map(layer => {
        return (
          <StyledSection
            key={layer.id}
            isVisible={layer.isVisible}
            isLightTheme={isLightTheme}
          >
            {renderLayerLegend({
              layer,
              canToggleVisibility,
              canToggleClustering,
              mapTheme,
            })}
          </StyledSection>
        )
      })}
    </StyledLayersList>
  )
}

export default MapLegend
