import { ReactElement } from 'react'

import FieldWrapper, {
  FieldWrapperProps,
} from 'components/common/Form/FieldWrapper'

const WidgetWrapper = ({
  children,
  rawErrors = [],
  ...props
}: FieldWrapperProps & {
  rawErrors?: string[]
}): ReactElement => {
  const hasError = rawErrors?.length > 0
  // For now there is no case when we can get more than 1 error for a field
  const metaError = rawErrors?.[0]

  return (
    <FieldWrapper {...props} hasError={hasError} metaError={metaError}>
      {children}
    </FieldWrapper>
  )
}

export default WidgetWrapper
