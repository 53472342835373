// constants
import { MESSAGE_TYPES } from 'constants/message'

export const TOAST_LIMIT = 3

export const TYPE_NOTIFICATION_CLOSE_TIME = {
  [MESSAGE_TYPES.success]: 5000,
  [MESSAGE_TYPES.error]: 100000,
  [MESSAGE_TYPES.info]: 5000,
  [MESSAGE_TYPES.warning]: 5000,
  [MESSAGE_TYPES.primary]: 5000,
}

export const TOAST_WIDTH = 359

export const SUCCESS_TYPE = 'success'
