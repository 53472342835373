import _ from 'lodash'
import type { Versions, Platform } from 'types/common'
import { getGraphql } from './utils'

export const getVersions = async (): Promise<Versions> => {
  const fnName = 'platform'
  const platform = await getGraphql<Platform>({
    queryDomain: fnName,
    getQueryFn: () => ({
      [fnName]: {
        versions: {
          stage: true,
          release: { name: true, version: true },
          components: { name: true, version: true, commit: true },
        },
      },
    }),
    fnName,
    queryDisplayName: 'GetPlatformVersions',
  })()

  const { stage, components, release } = platform?.versions || {}
  return {
    stage,
    release: release?.version,
    ..._(components)
      .keyBy('name')
      .mapValues(({ commit, version }) => `${version}(${commit})`)
      .value(),
  }
}
