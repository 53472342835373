import { ReactElement } from 'react'
import { ColumnBodyOptions } from 'primereact/column'
import _ from 'lodash'

import scss from './index.module.scss'

const MultilineText = (
  rowData: unknown,
  { field }: ColumnBodyOptions
): ReactElement => {
  const text = _.get(rowData, field)
  return <div className={scss.textContainer}>{text}</div>
}

export default MultilineText
