import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { StyledField } from 'components/common/Form'
import { RangeSlider } from 'components/common'

const Slider = props => {
  const {
    className,
    input,
    label,
    defaultVal,
    required,
    groupOptionStyle,
    inline,
    readOnly,
  } = props

  const inputProps = {
    ..._.pick(props, ['id', 'required', 'range', 'step']),
    ...input,
    className,
  }

  const { name, onChange, onBlur, onFocus, value } = input

  const sliderValue = useMemo(
    () => _.toNumber(_.defaultTo(value, defaultVal)),
    [defaultVal, value]
  )

  return (
    <StyledField
      name={name}
      groupOptionStyle={groupOptionStyle}
      label={label}
      required={required}
      inline={inline}
    >
      <RangeSlider
        {...inputProps}
        disabled={readOnly}
        noGroupStyle
        value={sliderValue}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
      />
    </StyledField>
  )
}

Slider.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    type: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
  }).isRequired,
  defaultVal: PropTypes.number,
  className: PropTypes.string,
  required: PropTypes.bool,
  groupOptionStyle: PropTypes.bool,
  inline: PropTypes.bool,
  readOnly: PropTypes.bool,
}

Slider.defaultProps = {
  label: '',
  type: 'text',
  defaultVal: undefined,
  required: false,
  groupOptionStyle: false,
  inline: false,
  className: undefined,
  readOnly: false,
}

export default Slider
