import { memo, ReactElement } from 'react'

import type { ColourClasses } from 'types/common'

import ColourBar from '../ColourBar'
import scss from './index.module.scss'

const ColourTable = memo(
  ({
    id,
    colourClasses,
    isNumericProperty = true,
  }: {
    id: string
    colourClasses: ColourClasses
    isNumericProperty?: boolean
  }): ReactElement => {
    return (
      <>
        {colourClasses.map(({ colour, range, category }) => (
          <div key={`${id}-${colour}-${range}`}>
            <div className={scss.colourBlock}>
              <ColourBar fillColour={colour} />
            </div>
            <div className={scss.colourLabel}>
              <span>
                {!isNumericProperty
                  ? category || ''
                  : `${range[0]} - ${range[1]}`}
              </span>
            </div>
          </div>
        ))}
      </>
    )
  }
)

export default ColourTable
