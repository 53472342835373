import { ReactElement } from 'react'
import _ from 'lodash'

import { APPLICATION_NAME } from 'constants/common'

// utils
import Logging from 'services/logging'
import { useFeatureFlag } from 'hooks'

// components
import { Accordion } from 'components/common'

// styles
import { FEATURES } from 'constants/settings'
import scss from '../index.module.scss'

const Versions = (): ReactElement => {
  const { versions, appVersion } = Logging
  const featureFlags = useFeatureFlag()

  return (
    <>
      <div className='pillLabel text-secondary'>Versions</div>
      <div className={scss.section}>
        <Accordion
          title='Components'
          content={
            <ul>
              {_.map(versions, (version, key) => {
                const releaseVision =
                  key === APPLICATION_NAME && !_.includes(version, appVersion)
                    ? `[v${appVersion}]${version}`
                    : version

                return (
                  <li key={key} className='text-secondary'>
                    {key}: {releaseVision}
                  </li>
                )
              })}
            </ul>
          }
          flush
        />
        <Accordion
          title='Feature Flags'
          content={
            <ul>
              {_.map(featureFlags, (isFlagged: boolean, key: string) => {
                const featureDisplayName = _.findKey(FEATURES, feature => {
                  return feature === key
                })

                return (
                  <li key={key} className='text-secondary'>
                    {`${
                      _.startCase(_.toLower(featureDisplayName)) || key
                    } Feature`}
                    :
                    <span className='ms-2'>
                      {isFlagged ? 'Enabled' : 'Disabled'}
                    </span>
                  </li>
                )
              })}
            </ul>
          }
          flush
        />
      </div>
    </>
  )
}

export default Versions
