import { ReactElement, useCallback } from 'react'
import { useToggle } from 'react-use'

// components
import { TitleWithTooltip, Toggle, IconButton } from 'components/common'

type Payload = {
  [key: string]: boolean
}

type TitleWithSortProps = {
  title: string
  tooltip?: string
  displaySort?: boolean
  enableSort?: boolean
  isAscendingOrder?: boolean
  enableSortKey: string
  onChange: (payload: Payload) => void
  className?: string
}

const TitleWithSort = ({
  title,
  tooltip,
  isAscendingOrder = true,
  enableSort = false,
  enableSortKey,
  displaySort = true,
  onChange,
  className,
}: TitleWithSortProps): ReactElement => {
  const [isAscending, toggleIsAscending] = useToggle(isAscendingOrder)

  const orderHandler = useCallback(() => {
    toggleIsAscending(!isAscending)
    onChange({ isAscendingOrder: !isAscending })
  }, [isAscending, onChange, toggleIsAscending])

  return (
    <div
      className={`groupOptionTitle d-flex justify-content-between align-items-center ${className}`}
    >
      <TitleWithTooltip title={title} tooltip={tooltip} />
      {displaySort && (
        <div className='d-flex align-items-center'>
          <Toggle
            label='Sort'
            checked={enableSort}
            onToggle={() =>
              onChange({
                [enableSortKey]: !enableSort,
              })
            }
          />
          {enableSort && (
            <IconButton
              icon={isAscending ? 'FaSortNumericDown' : 'FaSortNumericDownAlt'}
              onClick={orderHandler}
              label={isAscending ? 'Ascending' : 'Descending'}
            />
          )}
        </div>
      )}
    </div>
  )
}

TitleWithSort.defaultProps = {
  displaySort: true,
  enableSort: false,
  isAscendingOrder: true,
  tooltip: undefined,
  className: undefined,
}

export default TitleWithSort
