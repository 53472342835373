// libraries
import PropTypes from 'prop-types'
import _ from 'lodash'

// utils
import {
  useAssetWidgetConfigs,
  useBaseAssetWidgetPanel,
} from 'components/assets/assetsProfile/hooks'

import { DEFAULT_AGGREGATION_KEY_TYPE } from 'constants/aggregation'
import { DEFAULT_WIDGET_COLOUR } from 'constants/widget'

// components
import {
  PropertyPicker,
  TextInput,
  DataAggregation,
  ColourPickerSimple,
} from 'components/common'

const SingleValueWidgetPanel = ({
  widget,
  propertyTypes,
  enableAggregationRange,
}) => {
  const {
    enableRelationship,
    renderAssetToggleAndRelatedMultiSelect,
    isAssetRelationshipMany,
    propertyOptions,
  } = useBaseAssetWidgetPanel({ widget })

  const {
    settings: {
      property,
      colour = DEFAULT_WIDGET_COLOUR,
      overridePropertyTitle,
      relatedAssetAggregation = DEFAULT_AGGREGATION_KEY_TYPE,
      relatedAssetAggregationValueRange = [],
    },
  } = widget

  const { updateWidgetConfigsSettings } = useAssetWidgetConfigs(widget)

  const aggregationConfigs = {
    relatedAssetAggregation,
    relatedAssetAggregationValueRange,
  }

  const renderPropertyPicker = () => (
    <div className='groupOptionContent'>
      <PropertyPicker
        property={property}
        onChange={val => {
          updateWidgetConfigsSettings({
            property: val,
          })
        }}
        propertyTypes={propertyTypes}
        propertyOptions={propertyOptions}
        isMulti={false}
        isClearable={false}
        preSelect
        useOptionValueOnly
      />
    </div>
  )

  return (
    <>
      <div className='groupOption'>
        <div className='groupOptionTitle'>Override Property Title</div>
        <div className='groupOptionContent mb-2'>
          <TextInput
            className='form-control'
            value={overridePropertyTitle}
            placeholder='e.g. Speed (Km/h)'
            onChange={val =>
              updateWidgetConfigsSettings({ overridePropertyTitle: val })
            }
          />
        </div>
      </div>
      {renderAssetToggleAndRelatedMultiSelect()}
      <div className='groupOption'>
        {enableRelationship ? (
          _.isEmpty(propertyOptions) ? (
            <></>
          ) : isAssetRelationshipMany ? (
            <div className='groupOptionContent'>
              <DataAggregation
                {...(enableAggregationRange ? {} : { hideMinMax: true })}
                className='mt-0'
                tooltip='Aggregation'
                propertyOptions={propertyOptions}
                valueRangeIdentifier='relatedAssetAggregationValueRange'
                aggregationIdentifier='relatedAssetAggregation'
                aggregationConfigs={aggregationConfigs}
                onAggregationChangeHandler={(p, val) => {
                  updateWidgetConfigsSettings({ [p]: val })
                }}
              />
            </div>
          ) : (
            renderPropertyPicker()
          )
        ) : (
          renderPropertyPicker()
        )}
      </div>
      <div className='groupOption'>
        <div className='groupOptionTitle'>Select colour</div>
        <div className='groupOptionContent'>
          <ColourPickerSimple
            colour={colour}
            onChange={val => updateWidgetConfigsSettings({ colour: val })}
          />
        </div>
      </div>
    </>
  )
}

SingleValueWidgetPanel.propTypes = {
  widget: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    settings: PropTypes.shape({
      assetProfileId: PropTypes.string.isRequired,
      relatedAssetRelationshipId: PropTypes.string,
      property: PropTypes.string,
      colour: PropTypes.arrayOf(PropTypes.number),
      overridePropertyTitle: PropTypes.string,
      relatedAssetAggregation: PropTypes.shape({
        key: PropTypes.string,
        type: PropTypes.string,
      }),
      relatedAssetAggregationValueRange: PropTypes.array,
    }).isRequired,
  }).isRequired,
  propertyTypes: PropTypes.arrayOf(PropTypes.string),
  enableAggregationRange: PropTypes.bool,
}

SingleValueWidgetPanel.defaultProps = {
  propertyTypes: [],
  enableAggregationRange: false,
}

export default SingleValueWidgetPanel
