// libraries
import { useCallback, ReactElement, useState, useEffect } from 'react'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import { useAsync, useToggle, useAsyncFn } from 'react-use'
import _ from 'lodash'
import to from 'await-to-js'

// constants
import { MFA_TYPES } from 'constants/common'
import { BUTTON_VARIANTS } from 'components/common/Button'
import { TOOLTIP_PLACEMENT } from 'constants/settings'

// utils
import AuthService from 'services/authentication'
import { showInfo } from 'helpers/message'
import log, { reportException } from 'helpers/log'
import { useAuthStateValue } from 'contexts'
import {
  MFAValues,
  getMFAWording,
  validateMfa,
  getSelectedMFA,
} from 'components/login/LoginForm/SetupMFA/utils'
import { hasNoMfa } from 'helpers/user'

import type { MFATypes, User, ValidMFATypes } from 'types/user'

// components
import { LoginLayout } from 'routers/layouts'
import { PhoneField, CodeInput } from 'components/common/Form'
import { Loading, Button, IconButton, Tooltip } from 'components/common'
import { SuccessMessage, Title, Subtitle } from 'components/login/common'

import { saveItemsToLocalStorage } from 'helpers/storage'
import MFAOptionsSwitch from './MFAOptionsSwitch'
import scss from '../index.module.scss'

interface SetupMFAProps {
  onSuccess: () => void
  nextStepText: string
  selectedMFAType?: ValidMFATypes
  isLoginLayout?: boolean
}

const SetupMFASuccess = ({
  selectedMFA,
  text,
  onSuccess,
  isLoginLayout,
}: {
  selectedMFA: MFATypes
  text: string
  onSuccess: () => void
  isLoginLayout?: boolean
}): ReactElement => {
  return (
    <div {...(!isLoginLayout && { className: scss.successMessage })}>
      <SuccessMessage
        title='2-Step verification enabled!'
        subtitle={getMFAWording(selectedMFA)}
        nextStep={text}
        onNextStep={onSuccess}
      />
    </div>
  )
}

export const SetupMFA = ({
  onSuccess,
  nextStepText,
  selectedMFAType,
  isLoginLayout,
}: SetupMFAProps): ReactElement => {
  const { currentUser, setCurrentUser } = useAuthStateValue()

  const state = useAsync(async () => {
    const session = await AuthService.getSession()
    const { preferredMFA, username } = session || {}
    return {
      username,
      preferredMFA,
      isFirstMFASetup: hasNoMfa(preferredMFA),
      getCurrentUser: currentUser,
    }
  }, [currentUser])

  const {
    value: {
      isFirstMFASetup,
      preferredMFA = MFA_TYPES.NOMFA,
      getCurrentUser,
    } = {},
    loading,
    error,
  } = state

  const [selectedMFA, setSelectedMFA] = useState<MFATypes | undefined>(() =>
    getSelectedMFA(selectedMFAType || preferredMFA)
  )

  const [rqCodeImage, setRrCodeImage] = useToggle(true)
  const [isSendingCode, setSendingCode] = useToggle(false)
  const [isSetupSucceed, setIsSetupSucceed] = useToggle(false)
  const [isTooltipVisible, setTooltipVisible] = useToggle(false)

  const [totpQR, fetchTotpQR] = useAsyncFn(async () => {
    return AuthService.getTOTPCode()
  }, [])

  const { value: { qrCode, qrImage } = {} } = totpQR
  const isPreferredMFAChanged = preferredMFA !== selectedMFA

  useEffect(() => {
    setSelectedMFA(getSelectedMFA(selectedMFAType || preferredMFA))
  }, [preferredMFA, selectedMFAType])

  useEffect(() => {
    if (selectedMFA === MFA_TYPES.SOFTWARE_TOKEN_MFA && isPreferredMFAChanged) {
      fetchTotpQR()
      log.debug('Fetch TOTP QR Code')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMFA])

  const onSubmit = useCallback(
    async (values: MFAValues) => {
      try {
        const { code = '' } = values

        if (isPreferredMFAChanged) {
          log.debug('Preferred MFA changed. Setup new MFA method.')

          if (selectedMFA === MFA_TYPES.SOFTWARE_TOKEN_MFA) {
            await AuthService.verifyAndSetTOTP({ code })
            log.debug('setPreferredMFA TOTP successfully')
          } else if (selectedMFA === MFA_TYPES.SMS_MFA) {
            await AuthService.verifyAndSetSMS({ code })

            log.debug('setPreferredMFA SMS successfully')
          }
        } else {
          log.debug('Preferred MFA does not change. Skip setup.')
        }

        setIsSetupSucceed(true)
      } catch (e) {
        reportException(e as Error, { values })
        setIsSetupSucceed(false)
        return { [FORM_ERROR]: (e as Error).message }
      }

      return undefined
    },
    [isPreferredMFAChanged, selectedMFA, setIsSetupSucceed]
  )

  const onCodeSend = async (phone: string) => {
    setSendingCode(true)
    try {
      await AuthService.sendCodeToCurrentUserPhone(phone)
      log.debug('onCodeSend-Verified user phone number')
    } catch (e) {
      reportException(e as Error, { phone })
      return { [FORM_ERROR]: 'Something went wrong, please try again' }
    } finally {
      setSendingCode(false)
    }
    return undefined
  }

  return isSetupSucceed && selectedMFA ? (
    <SetupMFASuccess
      selectedMFA={selectedMFA}
      text={nextStepText}
      isLoginLayout={isLoginLayout}
      onSuccess={async () => {
        const [err, newUser] = await to(AuthService.getCurrentUser())

        if (err) {
          reportException(err, { component: 'SetupMFASuccess' })
        } else {
          if (newUser?.username) {
            log.debug('getCurrentUser successfully after SetupMFASuccess')
          }
          setCurrentUser(newUser)
        }

        onSuccess()
      }}
    />
  ) : (
    <>
      {loading ? (
        <div style={{ height: '300px' }}>
          <Loading />
        </div>
      ) : error ? (
        <div className='alert bg-danger d-flex align-items-center text-white'>
          <IconButton
            disabled
            icon='MdWarning'
            size={16}
            style={{ color: '#fff' }}
          />
          <div>{error}</div>
        </div>
      ) : (
        <div
          className={`${
            isLoginLayout ? scss.loginLayoutContent : scss.content
          }`}
        >
          <Title data-testid='mfa-type'>
            {isFirstMFASetup ? 'Enable' : 'Update'} 2-step verification
          </Title>
          <Subtitle>
            Add an extra layer of security to your account by asking for a
            verification code when you sign in
          </Subtitle>
          <Form
            initialValues={{ phone: undefined }}
            validate={values => validateMfa(values, preferredMFA)}
            onSubmit={onSubmit}
            render={({
              handleSubmit,
              submitting,
              submitError,
              hasValidationErrors,
              values,
              form,
              pristine,
              errors,
            }) => {
              const renderSMSSetup = () => (
                <div className={`  text-start ${scss.row}`}>
                  <PhoneField
                    labelClassName={scss.label}
                    data-testid='2mf-phone-number'
                    name='phone'
                    label='Mobile phone number'
                    required
                    style={{ display: 'flex' }}
                    className={`flex-grow-1 form-control unset ${scss.phoneField}`}
                    addon={
                      <Button
                        isLoading={isSendingCode}
                        disabled={
                          !values.phone ||
                          !_.isEmpty(errors?.phone) ||
                          isSendingCode
                        }
                        onClick={() => {
                          onCodeSend(values.phone as string)
                        }}
                      >
                        Send Code
                      </Button>
                    }
                  />
                </div>
              )

              const renderTokenSetup = () => (
                <div className={`  text-start ${scss.row}`}>
                  <div className='form-label'>
                    Scan the barcode with a security app
                  </div>
                  <div className='form-label-description'>
                    Download a mobile{' '}
                    <Tooltip
                      visible={isTooltipVisible}
                      trigger='click'
                      placement={TOOLTIP_PLACEMENT.top}
                      overlayClassName='light'
                      overlay={
                        <div className={scss.tooltip}>
                          <IconButton
                            icon='MdClose'
                            onClick={() => setTooltipVisible(false)}
                            className={scss.tooltipClose}
                            size={16}
                          />
                          <div className='defaultBoldText'>
                            What’s a Security app?
                          </div>
                          <div className={scss.totpDetails}>
                            <p>
                              Also known as an Authenticator app, it generates
                              time-based, one-time passcodes that refresh every
                              30 seconds. You enter or paste this into the
                              security code section below to enable the 2-step
                              verification security feature. Now, every time you
                              sign in, you will be asked to enter the security
                              code from your authenticator app.
                              <p />
                              Time-based means that if a hacker manages to get
                              your one-time passcode, it will not work for them
                              after 30 seconds.
                            </p>
                            <p>
                              Here are some Authenticator apps you can review on
                              your mobile device: Authy, Duo Mobile, LastPass,
                              Google Authenticator, and Microsoft Authenticator.
                            </p>
                          </div>
                        </div>
                      }
                    >
                      <span
                        className={scss.tooltipTrigger}
                        onClick={() => setTooltipVisible()}
                      >
                        Security app
                      </span>
                    </Tooltip>
                    , like Google Authenticator, and scan the barcode below to
                    receive the security code
                  </div>
                  {qrImage && qrCode && (
                    <div className={`text-center ${scss.qr}`}>
                      {rqCodeImage ? (
                        <img src={qrImage} alt='qrcode' />
                      ) : (
                        <input
                          type='text'
                          value={qrCode}
                          className='form-control text-center'
                          readOnly
                          onClick={() => {
                            if (qrCode) {
                              navigator.clipboard.writeText(qrCode)
                              showInfo('Copied to clipboard.')
                            }
                          }}
                        />
                      )}
                      <Button
                        variant={BUTTON_VARIANTS.link}
                        onClick={setRrCodeImage}
                        block
                        className='pt-0'
                      >
                        {rqCodeImage ? 'Switch to code' : 'Scan barcode'}
                      </Button>
                    </div>
                  )}
                </div>
              )

              return (
                <form onSubmit={handleSubmit} className={scss.form}>
                  {submitError && (
                    <div className='alert bg-danger d-flex align-items-center text-white'>
                      <IconButton
                        disabled
                        icon='MdWarning'
                        size={16}
                        style={{ color: '#fff' }}
                      />
                      <div>{submitError}</div>
                    </div>
                  )}
                  {!selectedMFAType && (
                    <div className={`  text-start ${scss.row}`}>
                      <div className='form-label'>
                        Choose verification method
                      </div>
                      <MFAOptionsSwitch
                        selectedMFA={selectedMFA}
                        onChange={v => {
                          form.reset()
                          setSelectedMFA(v as MFATypes)
                        }}
                      />
                    </div>
                  )}
                  {selectedMFA === MFA_TYPES.SMS_MFA && renderSMSSetup()}
                  {selectedMFA === MFA_TYPES.SOFTWARE_TOKEN_MFA &&
                    renderTokenSetup()}
                  <div className={`text-start ${scss.row}`}>
                    <Field
                      labelClassName='form-label'
                      component={CodeInput}
                      name='code'
                      label='Security code'
                      required
                      handleSubmit={handleSubmit}
                    />
                  </div>
                  <Button
                    type='submit'
                    disabled={submitting || hasValidationErrors || pristine}
                    block
                    className='mx-0'
                    isIconFixed
                    isLoading={submitting}
                    icon='ArrowRightNextIcon'
                    testId='Continue'
                  >
                    Continue
                  </Button>
                  {!getCurrentUser?.mfaRequired && isFirstMFASetup && (
                    <Button
                      variant={BUTTON_VARIANTS.link}
                      block
                      className='mx-0 mt-3 mb-2'
                      isIconFixed
                      icon='ArrowRightNextIcon'
                      onClick={async () => {
                        setCurrentUser(getCurrentUser as User)
                        onSuccess()

                        // set flag in localStorage
                        saveItemsToLocalStorage({
                          skipMFASetupForNow: true,
                        })
                      }}
                      testId='Skip for now'
                    >
                      Skip for now
                    </Button>
                  )}
                </form>
              )
            }}
          />
        </div>
      )}
    </>
  )
}

export const LoginSetupMFA = (props: SetupMFAProps): ReactElement => {
  return (
    <LoginLayout>
      <SetupMFA {...props} isLoginLayout />
    </LoginLayout>
  )
}
