import _ from 'lodash'
import { updateListItem } from 'helpers/utils'
import log from 'helpers/log'

export const MAP_SET_LIST = 'MAP_SET_LIST'

const mapActions = ({ state, dispatch }) => {
  return {
    updateMapsList: mapsList => {
      dispatch({
        type: MAP_SET_LIST,
        mapsList,
      })
    },
    updateMap: map => {
      if (!map) {
        log.warn('The map to be updated is invalid')
        return
      }
      const { mapsList } = state

      // because there is a redirect action between the list page and update/edit item page, check if the local list is empty or not
      if (_.isEmpty(mapsList)) return

      const newMapsList = updateListItem(map.id, map, mapsList, true)
      dispatch({
        type: MAP_SET_LIST,
        mapsList: newMapsList,
      })
    },
  }
}

export default mapActions
