import { useCallback, useMemo, useEffect, ReactElement } from 'react'
import { useToggle } from 'react-use'
import _ from 'lodash'

// utils
import {
  getSnappedDatetimeStr,
  getFutureUtcDate,
  isValidDatetimeUnit,
  getSuggestedDateTimeUnitOptions,
  getDurationAsIntervalUnit,
} from 'helpers/datetime'
import type { DateTimeRangeObj, IntervalUnit, Timezone } from 'types/datetime'

// constants
import { THEMES } from 'constants/colour'

// components
import { NumericInput, Dropdown } from 'components/common'
import TimeDisplay from '../TimeDisplay'

import scss from '../../index.module.scss'

export const EXACT_DATE_OPTION_LABEL = 'Exact Date'

const exactDateOption = {
  label: EXACT_DATE_OPTION_LABEL,
  value: EXACT_DATE_OPTION_LABEL,
}

type Props = {
  userSettingDataTimeRange: DateTimeRangeObj
  selectedDateTimeRange: DateTimeRangeObj
  timezone: Timezone
  timezoneAbbr: string
  intervalNumber: number
  intervalUnit: IntervalUnit
  updateTimelineState: ({
    intervalUnit,
    intervalNumber,
    selectedDateTimeRange,
  }: {
    intervalUnit?: IntervalUnit
    intervalNumber?: number
    selectedDateTimeRange?: DateTimeRangeObj
  }) => void
  updateTimelineItem: ({
    start,
    end,
    backgroundDataTimeRange,
    hasData,
    shouldFocusSelectedRange,
  }: Partial<{
    start: string
    end: string
    backgroundDataTimeRange: DateTimeRangeObj
    hasData: boolean
    shouldFocusSelectedRange: boolean
  }>) => void
  updateTimeline: ({
    start,
    newEnd,
    unit,
    dateTimeRange,
    hasData,
    shouldFocusSelectedRange,
  }: Partial<{
    start: string
    newEnd: string
    unit: string
    dateTimeRange: DateTimeRangeObj
    hasData: boolean
    shouldFocusSelectedRange: boolean
  }>) => void
  updateMapConfigs: ({
    timelineIntervalUnit,
  }: {
    timelineIntervalUnit: string
  }) => void
}

const IntervalSetting = ({
  timezone,
  timezoneAbbr,
  intervalNumber,
  intervalUnit = EXACT_DATE_OPTION_LABEL,
  userSettingDataTimeRange,
  selectedDateTimeRange,
  updateTimelineState,
  updateTimelineItem,
  updateTimeline,
  updateMapConfigs,
}: Props): ReactElement => {
  const [isEditorVisible, toggleEditorVisible] = useToggle(false)
  const [showDisplayTime, toggleDisplayTime] = useToggle(true)

  useEffect(() => {
    toggleDisplayTime(!isValidDatetimeUnit(intervalUnit))
  }, [intervalUnit, toggleDisplayTime])

  const validIntervalOptions = useMemo(() => {
    const { start, end } = userSettingDataTimeRange
    const suggestions = getSuggestedDateTimeUnitOptions(start, end)
    return _.reject([...suggestions, exactDateOption], {
      value: intervalUnit || EXACT_DATE_OPTION_LABEL,
    })
  }, [intervalUnit, userSettingDataTimeRange])

  const handleTimeIntervalUnitUpdate = useCallback(
    (newUnit: IntervalUnit) => {
      if (newUnit === intervalUnit) return

      const getNewEnd = (newEnd: Date) => {
        const newSnappedEnd = getSnappedDatetimeStr({
          baseDatetime: getFutureUtcDate({
            interval: 1,
            intervalUnit: newUnit,
            baseDatetime: newEnd,
          }),
          unit: newUnit,
          timezone,
        })
        const shouldUseEnd =
          getDurationAsIntervalUnit(newEnd, newSnappedEnd, newUnit, false) === 1
        return shouldUseEnd ? newEnd : newSnappedEnd
      }

      let intervals
      let timeRange
      const { start, end } = selectedDateTimeRange

      if (isValidDatetimeUnit(newUnit)) {
        toggleDisplayTime(false)
        // the upper and lower limit of the datetime range in new unit
        // make sure all the data is in range of time interval
        const newStart = getSnappedDatetimeStr({
          baseDatetime: start,
          unit: newUnit,
          timezone,
        })
        const newEnd = getSnappedDatetimeStr({
          baseDatetime: getNewEnd(end),
          unit: newUnit,
          timezone,
        })
        timeRange = { start: newStart, end: newEnd }
        intervals = getDurationAsIntervalUnit(newStart, newEnd, newUnit)
      } else {
        toggleDisplayTime(true)
        timeRange = { start, end }
      }

      updateTimeline({
        ...timeRange,
        unit: newUnit,
        shouldFocusSelectedRange: false,
      })

      updateTimelineState({
        intervalUnit: newUnit,
        intervalNumber: intervals,
      })
      updateMapConfigs({
        timelineIntervalUnit: newUnit,
      })
    },
    [
      intervalUnit,
      updateTimelineState,
      updateMapConfigs,
      toggleDisplayTime,
      selectedDateTimeRange,
      timezone,
      updateTimeline,
    ]
  )

  const handleTimeIntervalEdit = useCallback(
    newNumber => {
      const { start } = selectedDateTimeRange
      const newEnd = getFutureUtcDate({
        interval: newNumber,
        intervalUnit,
        baseDatetime: start,
      })?.toISOString()

      updateTimelineState({
        selectedDateTimeRange: { start, end: newEnd },
        intervalNumber: newNumber,
      })

      toggleEditorVisible(false)
      updateTimelineItem({
        start,
        end: newEnd,
        backgroundDataTimeRange: userSettingDataTimeRange,
        shouldFocusSelectedRange: false,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      userSettingDataTimeRange,
      intervalUnit,
      selectedDateTimeRange,
      updateTimelineItem,
      updateTimelineState,
      toggleEditorVisible,
    ]
  )

  return (
    <div className='d-flex align-items-center'>
      <span> Set Interval:</span>
      {!showDisplayTime &&
        (isEditorVisible ? (
          <NumericInput
            value={intervalNumber}
            disabled={!isEditorVisible}
            autoFocus
            onChange={handleTimeIntervalEdit}
            className={scss.intervalNumber}
            min={1}
          />
        ) : (
          <button
            className={scss.intervalText}
            type='button'
            onClick={() => toggleEditorVisible(true)}
          >
            {intervalNumber}
          </button>
        ))}
      <Dropdown
        options={validIntervalOptions}
        onChange={handleTimeIntervalUnitUpdate}
        theme={THEMES.dark}
        toggleComponent={() => (
          <button className={scss.intervalText} type='button'>
            {showDisplayTime ? (
              <TimeDisplay
                selectedDateTimeRange={selectedDateTimeRange}
                intervalUnit={intervalUnit}
                timezone={timezone}
              />
            ) : (
              intervalUnit
            )}
          </button>
        )}
      />
    </div>
  )
}

export default IntervalSetting
