/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ArrayFieldTemplateItemType,
  FormContextType,
  RJSFSchema,
  StrictRJSFSchema,
} from '@rjsf/utils'

// components
import { Button } from 'components/common'

// constants
import { BUTTON_VARIANTS } from 'components/common/Button'

import scss from './index.module.scss'

/** The `ArrayFieldItemTemplate` component is the template used to render an items of an array.
 *
 * @param props - The `ArrayFieldTemplateItemType` props for the component
 */
const ArrayFieldItemTemplate = <
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>(
  props: ArrayFieldTemplateItemType<T, S, F>
) => {
  const {
    children,
    className,
    disabled,
    hasToolbar,
    hasRemove,
    index,
    onDropIndexClick,
    readonly,
  } = props

  return (
    <div className={`${scss.itemContainer} ${className}`}>
      {children}
      {hasToolbar && hasRemove && (
        <Button
          className={scss.removeBtn}
          variant={BUTTON_VARIANTS.link}
          onClick={onDropIndexClick(index)}
          disabled={disabled || readonly}
          icon='MdClose'
          iconSize={16}
          onlyIcon
        />
      )}
    </div>
  )
}

export default ArrayFieldItemTemplate
