// libraries
import { ReactElement } from 'react'
import _ from 'lodash'
import { PortModelAlignment } from '@projectstorm/react-diagrams'

// constants
import {
  RELATIVE_TIME_PRE_DEFINED_OPERATORS,
  WORKFLOW_WIDGET_TYPES,
} from 'constants/workflow'
import { OPERATORS as FILTER_OPERATORS } from 'constants/filter'

// utils
import { toolboxItemFactory } from 'components/workflow/Canvas/Toolbox/items'
import * as icons from 'components/icons'

import type { Option, Options, Payload } from 'types/common'
import type {
  NodeModel,
  DiagramEngine,
  WorkflowWidget,
  WorkflowWidgetNode,
  WorkflowWidgetDataSource,
  NodeConfig,
} from 'types/workflow'
import type { ToolboxItem } from 'components/workflow/Canvas/Toolbox/items'

const getCurrentNodeLinkedNodes = (
  node: NodeModel,
  alignment: string,
  linkedNodes: NodeModel[] = []
): NodeModel[] => {
  const alignmentPort = _.get(node?.getPorts(), [alignment])

  if (_.isEmpty(alignmentPort)) {
    return linkedNodes
  }

  const alignmentPortLinks = alignmentPort.getLinks()
  if (_.isEmpty(alignmentPortLinks)) {
    return linkedNodes
  }

  return _.reduce(
    alignmentPortLinks,
    (acc: NodeModel[], link) => {
      const sourceLinkedNode = link?.getSourcePort()?.getParent()
      const targetLinkedNode = link?.getTargetPort()?.getParent()
      const linkedNode = (
        sourceLinkedNode?.getID() === node?.getID()
          ? targetLinkedNode
          : sourceLinkedNode
      ) as NodeModel

      return [
        ...acc,
        ...(linkedNode?.getID() === node?.getID()
          ? [linkedNode]
          : getCurrentNodeLinkedNodes(linkedNode, alignment, [
              ...linkedNodes,
              linkedNode,
            ])),
      ]
    },
    []
  )
}

export const getLinkedNodes = (
  engine: DiagramEngine,
  node: WorkflowWidgetNode,
  alignment: string = PortModelAlignment.LEFT
): NodeModel[] => {
  if (!engine || !node) return []

  const { id: nodeId } = node
  const nodes = engine.getModel().getNodes()
  const currentNode = _.find(nodes, cur => cur.getID() === nodeId)
  return getCurrentNodeLinkedNodes(currentNode as NodeModel, alignment)
}

export const getWidgetByType =
  (type: string) =>
  (widgets: WorkflowWidget[]): WorkflowWidget | undefined =>
    _.find(widgets, { type }) as WorkflowWidget

export const getDatasourceState = (
  widgets: WorkflowWidget[]
): WorkflowWidgetDataSource['datasetQuery'] => {
  const type = WORKFLOW_WIDGET_TYPES.SOURCE_DATASOURCE
  const datasourceWidget = getWidgetByType(type)(widgets)
  return _.get(datasourceWidget, 'datasetQuery') || {}
}

export const getWidgetToolboxItem = (
  nodeConfig: NodeConfig
): ((props: ToolboxItem) => ReactElement) => {
  const { nodeLabel, nodeType, iconName = '' } = nodeConfig
  return toolboxItemFactory(_.get(icons, iconName), nodeType, nodeLabel)
}

export const isOperator =
  (operator: string) =>
  (operatorOption: Option): boolean =>
    !!(operatorOption && operator && operatorOption.value === operator)

export const isBetweenOperator = isOperator(FILTER_OPERATORS.isBetween)

export const isSetOperator = isOperator(FILTER_OPERATORS.isSet)

export const isBooleanOperator = (operator: Option): boolean =>
  isOperator(FILTER_OPERATORS.isTrue)(operator) ||
  isOperator(FILTER_OPERATORS.isFalse)(operator)

export const isBooleanOrSetOperator = (operator: Option): boolean =>
  isBooleanOperator(operator) || isSetOperator(operator)

export const isPredefinedTimeOperator = (operator: Option): boolean =>
  _.includes(RELATIVE_TIME_PRE_DEFINED_OPERATORS, operator?.value)

export const getPropertyOptions = (
  propertiesOptions: Options,
  filterPayload?: Payload
): Options =>
  _(propertiesOptions)
    .filter(filterPayload)
    .map(({ value, ...rest }) => ({
      ...rest,
      value: `properties.${value}`,
    }))
    .value()
