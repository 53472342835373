import { ReactElement, useMemo } from 'react'
import _ from 'lodash'
import { ColumnBodyOptions } from 'primereact/column'

import type { IssueGeojson } from 'types/issue'
import { TOOLTIP_PLACEMENT } from 'constants/settings'
import { THEMES } from 'constants/colour'

import { Tooltip } from 'components/common'
import { MdChatBubbleOutline } from 'components/icons'
import IssueAnnotations from 'components/issue/IssueItemDetail/IssueAnnotations'

import scss from './index.module.scss'

const AnnotationsTemplate = (
  rowData: IssueGeojson,
  { field }: ColumnBodyOptions
): ReactElement => {
  const { id } = rowData
  const annotations: IssueGeojson['annotations'] = _.get(rowData, field)

  const annotationsCounts = useMemo(() => annotations.length, [annotations])

  return (
    <div className={scss.container}>
      <Tooltip
        placement={TOOLTIP_PLACEMENT.bottom}
        trigger={['hover']}
        theme={THEMES.light}
        overlayClassName={THEMES.light}
        // Do not display popup if there are no annotations
        {...(!annotations.length && { visible: false })}
        overlay={
          <IssueAnnotations
            issueId={id}
            annotations={annotations}
            className={scss.list}
            itemClassName={scss.listItem}
          />
        }
      >
        <span>
          <MdChatBubbleOutline className='me-1' />
          {annotationsCounts}
        </span>
      </Tooltip>
    </div>
  )
}

export default AnnotationsTemplate
