import _ from 'lodash'
import log from 'helpers/log'

import type { Payload } from 'types/common'

export const checkCookiesEnabled = (): boolean => {
  return navigator.cookieEnabled
}

export const checkLocalStorageEnabled = (): boolean => {
  const test = 'test'
  try {
    localStorage.setItem(test, test)
    localStorage.removeItem(test)
    return true
  } catch (e) {
    log.error('Check local storage enabled failed', e)
    return false
  }
}

const getValidKey = (key: string, keyPrefix?: string) =>
  _([keyPrefix, key]).compact().join('.')

export const saveItemsToLocalStorage = (
  items: Payload,
  keyPrefix?: string
): boolean => {
  try {
    _.forEach(items, (value, key: string) => {
      const val = _.isObject(value) ? JSON.stringify(value) : _.toString(value)
      const keyVal = getValidKey(key, keyPrefix)
      localStorage.setItem(keyVal, val)
    })
    return true
  } catch (e) {
    log.error('Save items to local storage failed', e)
    return false
  }
}

export const removeItemsFromLocalStorage = (
  keys: string[],
  keyPrefix?: string
): boolean => {
  try {
    _.forEach(keys, (key: string) => {
      const keyVal = getValidKey(key, keyPrefix)
      localStorage.removeItem(keyVal)
    })
    return true
  } catch (e) {
    log.error('Remove items from local storage failed', e)
    return false
  }
}

export const getItemsToLocalStorage = (
  keys: string[],
  keyPrefix?: string
): Record<string, string> => {
  try {
    return _.reduce(
      keys,
      (acc, key) => {
        const keyVal = getValidKey(key, keyPrefix)
        return { ...acc, [key]: localStorage.getItem(keyVal) }
      },
      {}
    )
  } catch (e) {
    log.error('Get items to local storage failed', e)
    return {}
  }
}
