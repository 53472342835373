import {
  CSSProperties,
  ElementType,
  forwardRef,
  PropsWithChildren,
  ReactNode,
} from 'react'
import { classNames } from 'primereact/utils'
import styled from '@emotion/styled'

import EmptyStateImage from 'svg/empty-state-background.svg'
import { IMAGE_SIZES, SIZES } from './constants'
import { Size } from './types'

import scss from './index.module.scss'

type EmptyStateProps = PropsWithChildren<{
  style?: CSSProperties
  className?: string
  title?: ReactNode
  image?: ElementType
  description?: ReactNode
  hideImage?: boolean
  size?: Size
}>

const Title = styled.div<{ size?: Size; hideImage?: boolean }>`
  margin-top: ${({ hideImage }) => (hideImage ? '0' : '24px')};
  font-weight: 600;
  line-height: 22px;
  font-size: ${({ size }) => (size === SIZES.LARGE ? '20px' : '16px')};
`

const EmptyState = forwardRef<HTMLDivElement, EmptyStateProps>(
  (
    {
      style,
      className,
      title,
      image: Image,
      description,
      children,
      hideImage,
      size,
      ...props
    },
    ref
  ) => {
    const showImage = !hideImage && Image
    const imageSize =
      size === SIZES.LARGE ? IMAGE_SIZES.LARGE : IMAGE_SIZES.MEDIUM

    return (
      <div
        {...props}
        className={classNames(className, scss.container)}
        ref={ref}
        style={style}
      >
        {showImage && (
          <div
            className={scss.imageContainer}
            style={{
              backgroundImage: `url(${EmptyStateImage})`,
              height: imageSize,
            }}
          >
            <Image
              data-testid='empty-state-image'
              height={imageSize}
              width={imageSize}
              className={scss.image}
            />
          </div>
        )}
        {title && (
          <Title size={size} hideImage={hideImage}>
            {title}
          </Title>
        )}
        {description && <div className={scss.description}>{description}</div>}
        {children && <div className={scss.children}>{children}</div>}
      </div>
    )
  }
)

export default Object.assign(EmptyState, {
  SIZES,
})
