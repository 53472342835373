// libraries
import { ReactElement, useMemo, useState } from 'react'
import * as Icons from 'components/icons'

// types
import type { ThemeType } from 'types/common'

// utils
import { useBranding } from 'hooks'

// constants
import { DEFAULT_MAP_THEME, MAP_STYLE_TYPES } from 'constants/map'
import { BUTTON_VARIANTS } from 'components/common/Button'
import { TOOLTIP_PLACEMENT } from 'constants/settings'
import { THEMES } from 'constants/colour'

// style
import { MapStylePickerProps, MapStyleType } from 'types/map'

// constants
import { MAP_STYLE_SIZES } from 'constants/common'

// components
import { Tooltip } from 'components/common/Tooltip'

import { StyledItem, StyledIcon, ExpandButton } from './styles'
import scss from './index.module.scss'

const MapStylePicker = ({
  style = MAP_STYLE_TYPES.dark as MapStyleType,
  isGrid = true,
  size = MAP_STYLE_SIZES.default,
  onChange,
  expandable = false,
  mapTheme = DEFAULT_MAP_THEME,
}: MapStylePickerProps & {
  expandable: boolean
  onChange?: (v: MapStyleType) => void
  style: MapStyleType
  mapTheme: ThemeType
}): ReactElement => {
  const { mapboxStyles } = useBranding()
  const [showMore, setShowMore] = useState(false)
  const isLightTheme = mapTheme === THEMES.light

  const memoizedMapStyles = useMemo(() => {
    const isSmall = size === MAP_STYLE_SIZES.small

    const Icon = Icons.BsCheck

    const renderMapStyle = ({
      value,
      label,
      src,
    }: {
      value: MapStyleType
      label: string
      src: string
    }): ReactElement => (
      <Tooltip
        key={`${label}-${value}`}
        placement={TOOLTIP_PLACEMENT.top}
        overlay={label}
        arrowContent={<div className='rc-tooltip-arrow-inner' />}
      >
        <StyledItem
          key={value}
          value={value}
          onClick={() => onChange && onChange(value)}
          className={`${isGrid ? scss.girdStyle : ''} `}
          size={size}
          isGrid={isGrid}
          active={style === value}
          isLightTheme={isLightTheme}
        >
          <img
            src={src || `/assets/mapstyle/${label}.webp`}
            data-testid='img'
            alt={label}
          />
          {(expandable || !isSmall) && style === value && (
            <StyledIcon isLightTheme={isLightTheme}>
              <Icon size={20} />
            </StyledIcon>
          )}
        </StyledItem>
      </Tooltip>
    )

    return expandable
      ? showMore
        ? mapboxStyles.map(renderMapStyle)
        : mapboxStyles.slice(0, 6).map(renderMapStyle)
      : mapboxStyles.map(renderMapStyle)
  }, [
    size,
    mapboxStyles,
    isGrid,
    style,
    onChange,
    expandable,
    showMore,
    isLightTheme,
  ])

  return (
    <>
      <ul className={scss.mapStylePicker}>{memoizedMapStyles}</ul>
      {expandable && (
        <ExpandButton
          onClick={() => {
            setShowMore(!showMore)
          }}
          variant={BUTTON_VARIANTS.link}
          isLightTheme={isLightTheme}
          className='px-1'
        >
          {showMore ? 'Show less' : 'Show more'}
        </ExpandButton>
      )}
    </>
  )
}

export default MapStylePicker
