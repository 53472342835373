import { ReactElement } from 'react'
import {
  ProgressBar as PrProgressBar,
  ProgressBarProps,
} from 'primereact/progressbar'
import { useTheme } from '@emotion/react'

import scss from './index.module.scss'

const ProgressBar = ({
  pt = {
    root: { style: { backgroundColor: '#F5F9FC', height: 5 } },
  },
  showValue = false,
  ...props
}: ProgressBarProps): ReactElement => {
  const theme = useTheme()

  const color = props.color ?? theme['primary-500']

  return (
    <PrProgressBar
      {...props}
      color={color}
      className={scss.progressBar}
      pt={pt}
      showValue={showValue}
    />
  )
}

export default ProgressBar
