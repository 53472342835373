// libraries
import styled from '@emotion/styled'
import { css } from '@emotion/react'

export const StyledInput = styled.input<{
  isLightTheme: boolean
  isRangeValid: boolean
}>`
  font-size: 10px;
  border: none;
  padding: 0;
  &:focus {
    border-bottom: 1px solid ${props => props.theme.primary};
    outline: none;
  }
  ${({ isLightTheme, isRangeValid }) =>
    isRangeValid
      ? css`
          background-color: transparent;
          color: ${isLightTheme ? '#000' : '#fff'};
        `
      : css`
          background-color: ${isLightTheme ? '#f8e9e9' : '#d3d3d3'};
          color: ${isLightTheme ? '#b4251d' : '#ce354b'};
        `};
`
