import keymirror from 'keymirror'

export const BUTTON_VARIANTS = keymirror({
  primary: null,
  secondary: null,
  link: null,
  danger: null,
  'danger-link': null,
  warning: null,
  success: null,
})

export const DEFAULT_BUTTON_VARIANT = BUTTON_VARIANTS.primary

export const BUTTON_SIZES = keymirror({
  large: null,
  default: null,
  small: null,
})

export const DEFAULT_BUTTON_SIZE = BUTTON_SIZES.default

export const BUTTON_ICON_POSITIONS = keymirror({
  left: null,
  right: null,
})

export const DEFAULT_ICON_POSITION = BUTTON_ICON_POSITIONS.right

export const BUTTON_ICON_SIZES = {
  [BUTTON_SIZES.large]: 20,
  [BUTTON_SIZES.default]: 16,
  [BUTTON_SIZES.small]: 12,
}

export const ICON_MARGIN = '6px'

export const BUTTON_BORDER_RADIUS = '3px'

export const BUTTON_ICON_CLASS_NAME = 'btnIcon'

export const SECONDARY_BUTTON_BORDER_COLOR = '#e5e5e5'
