import { ReactElement, useMemo } from 'react'
import CssFilterConverter from 'css-filter-converter'

// utils
import { colourArrToRgbaBgStyle, colourArrToHex } from 'helpers/colour'

// constants
import { LAYER_VIS_CONFIGS } from 'components/map/layers/deckLayers/layerFactory'
import {
  IconSvgButton,
  DEFAULT_ACTIVE_ICON_CSS_FILTER,
} from 'components/common/IconPicker'

import type { IconPosition } from 'components/common/IconPicker'
import type { ColourArray, ThemeType } from 'types/common'
import LegendTitle from '../LegendTitle'
import scss from './index.module.scss'

export const DisplayLayerIcon = ({
  fillColour = LAYER_VIS_CONFIGS.fillColour.defaultValue,
  bgColour = LAYER_VIS_CONFIGS.bgColour.defaultValue,
  iconPosition = LAYER_VIS_CONFIGS.iconPosition.defaultValue,
}: {
  fillColour: ColourArray
  bgColour: ColourArray
  iconPosition: IconPosition
  iconZoom: string
  bgSize: string
  bgFontSize: string
  bgZoom: string
}): ReactElement => {
  const { x, y } = iconPosition

  const colourFilter = useMemo((): string => {
    const hex = colourArrToHex(fillColour)
    const { color, error } = CssFilterConverter.hexToFilter(hex)
    if (!error) return color as string
    return DEFAULT_ACTIVE_ICON_CSS_FILTER
  }, [fillColour])

  const bgStyle = useMemo(() => colourArrToRgbaBgStyle(bgColour), [bgColour])

  return (
    <div
      style={bgStyle}
      className={`d-flex align-items-center justify-content-center ${scss.bg}`}
    >
      <IconSvgButton
        className={scss.icon}
        colourFilter={colourFilter}
        x={x}
        y={y}
      />
    </div>
  )
}

const IconLegend = ({
  name,
  fillColour = LAYER_VIS_CONFIGS.fillColour.defaultValue,
  bgColour = LAYER_VIS_CONFIGS.bgColour.defaultValue,
  iconPosition = LAYER_VIS_CONFIGS.iconPosition.defaultValue,
  ...rest
}: {
  id: string
  canToggleVisibility: boolean
  canToggleClustering: boolean
  isVisible: boolean
  enableClustering: boolean
  iconPosition: IconPosition
  name: string
  fillColour: ColourArray
  bgColour: ColourArray
  theme: ThemeType
}): ReactElement => (
  <LegendTitle title={name} {...rest}>
    <DisplayLayerIcon
      fillColour={fillColour}
      bgColour={bgColour}
      iconPosition={iconPosition}
      bgSize='16px'
      bgFontSize='100%'
      bgZoom='1'
      iconZoom='0.1'
    />
  </LegendTitle>
)

export default IconLegend
