import type { FFmpeg } from '@ffmpeg/ffmpeg'
import { SET_FFMPEG_INSTANCE } from 'contexts/actions/ui'
import { switchcaseF } from 'helpers/utils'
import type { Payload } from 'types/common'

export const uiState: { ffmpegInstance: null | FFmpeg } = {
  ffmpegInstance: null,
}

const uiReducer = (state: Payload, actionData: { type: string } & Payload) =>
  switchcaseF({
    [SET_FFMPEG_INSTANCE]: () => ({
      ...state,
      ffmpegInstance: actionData.ffmpegInstance,
    }),
  })(() => state)(actionData.type)

export default uiReducer
