/**
 * One intervalUnit (e.g., minutes, hours )
 */
export const TIMELINE_REPLAY_INTERVAL = 1

/**
 * The base interval in milliseconds
 */
export const INTERVAL_TIME = 3000

/**
 * The ratio of each interval change (e.g., if the duration interval is 10
 * minutes and the change ratio is 0.5, it will change 5 minutes every time)
 */
export const CHANGE_RATIO = 0.5
