import { createSelector } from 'reselect'
import type { Payload } from 'types/common'

const getUiState = (state: Payload) => state.uiState

const getFfmpegInstance = createSelector(
  getUiState,
  state => state.ffmpegInstance
)

const uiSelectors = (state: Payload) => ({
  ffmpegInstance: getFfmpegInstance(state),
})

export default uiSelectors
