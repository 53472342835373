import {
  ReactElement,
  useState,
  useCallback,
  useRef,
  Dispatch,
  useEffect,
  useMemo,
  useLayoutEffect,
} from 'react'
import _ from 'lodash'

// constants
import {
  ASSET_PROFILE_MEDIA_TYPES,
  ASSET_PROFILE_RELATIONSHIP_TYPES,
} from 'constants/assets'

// utils
import { switchcaseF } from 'helpers/utils'

import type {
  Asset,
  AssetBaseWidget,
  AssetWidgetSettings,
  AssetTableHeader,
} from 'types/asset'

// components
import { BaseWidgetWithAssetState } from 'components/assets/assetsProfile/widgets/BaseWidget'
import {
  RelatedMultiTables,
  RelatedMultiPagesTable,
  RelatedAssetSinglePageTable,
  AssetSinglePageTable,
  RelatedMultiTablesProps,
  RelatedAssetSinglePageTableProps,
  RelatedMultiPagesTableProps,
} from './PropertiesTables'

type AssetTableSettings = AssetWidgetSettings & {
  summaryProperties?: string[]
  visibleProperties?: string[]
  hideInvalidValues: boolean
  enableSort: boolean
  isAscendingOrder: boolean
  sortByProperty: string
  singlePropertyModeEnabled?: boolean
}

type PropertiesTableProps = {
  value: Asset
  isPrintable?: boolean
  setHeader: Dispatch<AssetTableHeader>
} & AssetTableSettings

const PropertiesTable = ({
  isPrintable,
  setHeader,
  ...rest
}: PropertiesTableProps): ReactElement => {
  const {
    relatedAssetRelationshipType = ASSET_PROFILE_RELATIONSHIP_TYPES.MANY,
    relatedAssetRelationshipId,
    summaryProperties = [],
  } = rest

  const isAssetRelationshipMany =
    relatedAssetRelationshipType === ASSET_PROFILE_RELATIONSHIP_TYPES.MANY

  const relatedMultiPagesTableProps = {
    ...rest,
    relatedAssetRelationshipId,
    summaryProperties,
    setHeader,
  }

  if (!relatedAssetRelationshipId) return <AssetSinglePageTable {...rest} />

  if (!isAssetRelationshipMany) {
    return (
      <RelatedAssetSinglePageTable
        {...(rest as RelatedAssetSinglePageTableProps)}
      />
    )
  }

  return isPrintable ? (
    <RelatedMultiTables {...(rest as RelatedMultiTablesProps)} />
  ) : (
    <RelatedMultiPagesTable
      {...(relatedMultiPagesTableProps as RelatedMultiPagesTableProps)}
    />
  )
}

type AssetPropertiesTableProps = AssetBaseWidget & {
  value: Asset
  setHeader: Dispatch<AssetTableHeader>
} & AssetTableSettings

const AssetPropertiesTable = ({
  onWidgetLoad,
  mediaType,
  ...rest
}: AssetPropertiesTableProps): ReactElement => {
  const tableRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (onWidgetLoad) {
      onWidgetLoad()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableRef?.current])

  return switchcaseF({
    [ASSET_PROFILE_MEDIA_TYPES.PRINTABLE]: () => (
      <div ref={tableRef}>
        <PropertiesTable {...rest} isPrintable />
      </div>
    ),
  })(() => <PropertiesTable {...rest} />)(mediaType)
}

const AssetPropertiesTableWidget = ({
  assetId,
  name,
  settings,
  theme,
  mediaType,
  onWidgetLoad = _.noop,
}: AssetBaseWidget & { settings: AssetTableSettings }): ReactElement => {
  const [header, setHeader] = useState(name)

  useEffect(() => {
    setHeader(name)
  }, [name])

  const isPrintable = useMemo(
    () => mediaType === ASSET_PROFILE_MEDIA_TYPES.PRINTABLE,
    [mediaType]
  )

  const render = useCallback(
    props => {
      const sharedProps = {
        ...props,
        ...settings,
        theme,
        mediaType,
        setHeader,
        onWidgetLoad,
      }

      return <AssetPropertiesTable {...sharedProps} />
    },
    [mediaType, onWidgetLoad, settings, theme]
  )

  return (
    <BaseWidgetWithAssetState
      assetId={assetId}
      settings={settings}
      render={render}
      className=''
      name={header || name}
      theme={theme}
      forceScroll={isPrintable}
      isPrintable={isPrintable}
      // The issue data is important to properly display field values for jsonForms
      withIssues
    />
  )
}

export default AssetPropertiesTableWidget
