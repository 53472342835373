import _ from 'lodash'

import {
  WORKFLOW_INITIAL,
  WORKFLOW_DESELECT_NODE,
  WORKFLOW_SELECT_DATASOURCE,
  WORKFLOW_SELECT_NODE,
  WORKFLOW_SET_LIST,
} from 'contexts/actions/workflow'

import { switchcaseF } from 'helpers/utils'

export const workflowState = {
  selectedNode: undefined,
  datasource: undefined,
  workflowsList: [],
}

const workflowReducer = (state, action) => {
  return switchcaseF({
    [WORKFLOW_INITIAL]: () => ({
      ...state,
      selectedNode: undefined,
      datasource: undefined,
    }),
    [WORKFLOW_SET_LIST]: () => ({
      ...state,
      workflowsList: action.workflowsList,
    }),
    [WORKFLOW_SELECT_DATASOURCE]: () => ({
      ...state,
      datasource: action.datasource,
    }),
    [WORKFLOW_SELECT_NODE]: () => ({
      ...state,
      selectedNode: action.selectedNode,
    }),
    [WORKFLOW_DESELECT_NODE]: () => _.omit(state, 'selectedNode'),
  })(() => state)(action.type)
}

export default workflowReducer
