import { SpecParams } from 'types/common'
// libraries
import _ from 'lodash'
import update from 'immutability-helper'
import isEqual from 'fast-deep-equal'
import to from 'await-to-js'
import * as Comlink from 'comlink'

// constants
import { MESSAGE_TYPES } from 'constants/message'

// utils
import { listCatalogs } from 'services/api/unipipe'
import { getUserGroup } from 'services/api/group'
import {
  isLiveDataset,
  getDatasetName,
  isValidSpecParams,
  getSpecParamsHash,
  getSpecParamValidationMessages,
  getDatasetServiceIdentifier,
  getDatasetIdentifier,
} from 'helpers/unipipe'
import { displayAndLogErrorMessage } from 'helpers/message'
import log, { reportMessage } from 'helpers/log'
import AuthService from 'services/authentication'
import { isSuperAdmin } from 'helpers/user'

// web worker
/* eslint-disable import/no-unresolved,import/no-webpack-loader-syntax */
import DatasetServiceWorker from 'workerize-loader!helpers/workers/datasetService'
/* eslint-enable import/no-unresolved,import/no-webpack-loader-syntax */

import DMSService from 'services/datasetService'
import MetadataService from 'services/metadataService'

import type { Payload } from 'types/common'
import type {
  DatasetRequest,
  CatalogItem,
  UpBaseSpecification,
} from 'types/unipipe'
import type { AuthCredentials } from 'types/auth'
import type { Fields } from 'types/services'
import type { GeojsonData } from 'types/map'

const USE_WORKER = true
export const SET_UNIPIPE_CATALOG = 'SET_UNIPIPE_CATALOG'
export const SET_UNIPIPE_GROUPS = 'SET_UNIPIPE_GROUPS'
export const UPDATE_UNIPIPE_CATALOG_ENTRY = 'UPDATE_UNIPIPE_CATALOG_ENTRY'
export const SET_DATASET_SERVICES = 'SET_DATASET_SERVICES'
export const SET_ACTIVE_DATASETS = 'SET_ACTIVE_DATASETS'
export const SET_UNIPIPE_DATA_INIT = 'SET_UNIPIPE_DATA_INIT'
export const SET_UNIPIPE_DATA_SUCCESS = 'SET_UNIPIPE_DATA_SUCCESS'
export const SET_UNIPIPE_DATA_MESSAGE = 'SET_UNIPIPE_DATA_MESSAGE'
export const ABORT_UNIPIPE_DATASETS_SERVICE = 'ABORT_UNIPIPE_DATASETS_SERVICE'
export const EMPTY_UNIPIPE_DATASETS = 'EMPTY_UNIPIPE_DATASETS'

const dispatchErrorMessage = ({
  dispatch,
  message,
  datasetName,
  datasetServiceIdentifier,
}): void => {
  dispatch({
    message,
    datasetName,
    datasetServiceIdentifier,
    messageType: MESSAGE_TYPES.error,
    type: SET_UNIPIPE_DATA_MESSAGE,
  })
  reportMessage(message)
}

const getDatasetFetchingErrorMessage = (
  displayName: string,
  error: { message: string }
): string => {
  const errorPrefix = `[${displayName}]Error occurred while fetching data.`
  log.error(errorPrefix)
  log.error(error)
  return `${errorPrefix}[error]:${_.get(error, 'message', error)}`
}

const checkDatasetServiceStatus = async (
  datasetService,
  datasetServiceWorker
) => {
  const isServiceCanceled = await datasetService.loadCanceled

  // live dataset service will always be active unless is being canceled
  const isServiceLoading =
    isLiveDataset(await datasetService.timeliness) ||
    (await datasetService.isLoading)

  if (isServiceCanceled || !isServiceLoading) {
    datasetServiceWorker.terminate()
  }
}

const getCredentials = async (
  catalogGroup: string,
  catalogRole: string
): Promise<AuthCredentials | null> => {
  const { role, group: userGroup } = AuthService.currentUser || {}

  const isPublicCatalog = catalogRole === 'public'
  const hasSuperAdminChangeGroup =
    isSuperAdmin(role) && catalogGroup !== userGroup

  if (hasSuperAdminChangeGroup && !isPublicCatalog) {
    if (!catalogGroup) throw new Error('Catalog group is missing from catalog')

    log.info(
      'Getting access token to allow admin user to access another group data'
    )
    const [err, groupInfo] = await to(getUserGroup(catalogGroup))
    if (err)
      throw new Error(
        `Cannot get group information by name ${catalogGroup}. ${err.message}`
      )

    const credential = _.get(groupInfo, 'upAuthSuSession')
    if (!credential)
      throw new Error(
        `Cannot get session token from the group information. Group: ${JSON.stringify(
          groupInfo
        )}`
      )

    return credential
  }

  log.info('Getting access token from the authenticated user')
  return AuthService.getCredentials()
}

const resetPreviousDatasetService = datasetServiceState => {
  if (!datasetServiceState) return
  const { worker } = datasetServiceState
  if (worker) {
    worker.terminate()
  }
}

const fetchDatasetSpecificationData = async ({
  dispatch,
  catalogItem,
  specification,
  datasetServiceIdentifier,
  forceUpdate,
  datasetService: datasetServiceState,
}: {
  specification: UpBaseSpecification
  catalogItem: CatalogItem
  datasetServiceIdentifier: string
  forceUpdate?: boolean
}) => {
  resetPreviousDatasetService(datasetServiceState)
  const { endpoint, group, catalogRoleArn } = catalogItem

  const datasetName = getDatasetName(catalogItem)
  const datasetServiceWorker = USE_WORKER
    ? new DatasetServiceWorker()
    : undefined

  try {
    const credentials = await getCredentials(group, catalogRoleArn)

    const DatasetService = USE_WORKER
      ? Comlink.wrap(datasetServiceWorker)
      : DMSService
    const datasetService = await new DatasetService({
      upBaseUrl: endpoint,
      metadata: catalogItem,
      specification,
      credentials,
    })

    const sharedPayload = {
      ...(forceUpdate ? {} : datasetServiceState || {}),
      catalogItem,
      specification,
      datasetName,
      service: datasetService,
      worker: datasetServiceWorker,
      datasetServiceIdentifier,
    }

    dispatch({
      ...sharedPayload,
      type: SET_UNIPIPE_DATA_INIT,
    })

    const onBatch = async ({
      geojsonRows,
      loadCanceled,
      isLoading,
    }: {
      geojsonRows: GeojsonData[]
      loadCanceled?: boolean
      isLoading?: boolean
    }) => {
      dispatch({
        ...sharedPayload,
        type: SET_UNIPIPE_DATA_SUCCESS,
        geojsonRows,
        isServiceCanceled: loadCanceled,
        isServiceLoading: isLoading,
      })
      if (USE_WORKER) {
        await checkDatasetServiceStatus(datasetService, datasetServiceWorker)
      }
    }

    const onMessage = async (message: string, messageType: string) => {
      dispatch({
        ...sharedPayload,
        type: SET_UNIPIPE_DATA_MESSAGE,
        message,
        messageType,
      })
      if (USE_WORKER) {
        await checkDatasetServiceStatus(datasetService, datasetServiceWorker)
      }
    }

    if (USE_WORKER) {
      datasetService.fetchUnipipeData(
        Comlink.proxy(onBatch),
        Comlink.proxy(onMessage)
      )
    } else {
      datasetService.fetchUnipipeData(onBatch, onMessage)
    }
  } catch (error) {
    dispatchErrorMessage({
      dispatch,
      message: getDatasetFetchingErrorMessage(datasetName, error),
      datasetName,
      datasetServiceIdentifier,
    })

    datasetServiceWorker.terminate()
  }
}

const fetchUpdatedDynamicMetadata = async ({
  dispatch,
  catalogItem,
}: {
  catalogItem: CatalogItem
}) => {
  const { endpoint, group, catalogRoleArn } = catalogItem
  const credentials = await getCredentials(group, catalogRoleArn)

  try {
    const metadataService = await new MetadataService({
      upBaseUrl: endpoint,
      originalMetadata: catalogItem,
      credentials,
    })
    const updatedMetadata = await metadataService.fetchDynamicMetadata()
    if (_.isEmpty(updatedMetadata)) {
      dispatch({
        type: UPDATE_UNIPIPE_CATALOG_ENTRY,
        catalogEntry: catalogItem,
      })
    } else {
      log.info('Found updated dynamic metadata')
      // `updatedMetadata` should not have dynamicMetadata property, but handle defensively
      // and ensure it is removed to prevent infinite loop
      delete updatedMetadata.dynamicMetadata

      dispatch({
        type: UPDATE_UNIPIPE_CATALOG_ENTRY,
        catalogEntry: updatedMetadata,
      })
    }
  } catch (error) {
    reportMessage(JSON.stringify(error))
  }
}

const hasSpecParamsChanged = (oldDatasetService, specParams: SpecParams) => {
  if (!oldDatasetService) return false

  const { specification } = oldDatasetService
  return !isEqual(
    getSpecParamsHash(specification.specParams),
    getSpecParamsHash(specParams)
  )
}

const shouldFetchData = ({
  dispatch,
  catalogItem,
  specification,
  datasetService,
  datasetServiceIdentifier,
}) => {
  const { specParams } = specification
  if (hasSpecParamsChanged(datasetService, specParams)) return true

  const {
    value: datasetName,
    timeliness,
    specificationParameters,
  } = catalogItem
  if (!timeliness) {
    dispatchErrorMessage({
      dispatch,
      message: `Dataset(${datasetName}) doesn't have the timeliness information`,
      datasetName,
      datasetServiceIdentifier,
    })
    return false
  }

  if (isLiveDataset(timeliness)) {
    return true
  }

  if (!isValidSpecParams(specParams, specificationParameters)) {
    dispatchErrorMessage({
      dispatch,
      message: `Dataset(${datasetName}) does not contain required parameters. ${getSpecParamValidationMessages(
        specParams,
        specificationParameters
      )}`,
      datasetName,
      datasetServiceIdentifier,
    })
    return false
  }

  return !datasetService
}

const removeAllDeletedDatasetServices = async ({
  dispatch,
  oldDatasetServices,
  toBeDeletedDatasetServices,
  activeDatasetServiceIdentifies,
  keepDatasetServiceData = false,
}) => {
  const datasetServices = toBeDeletedDatasetServices.reduce(
    (total: Payload, datasetServiceIdentifier: string) => {
      const datasetService = total[datasetServiceIdentifier]
      if (datasetService?.worker) {
        datasetService.worker.terminate()
      }

      // reset the given dataset request status
      return keepDatasetServiceData
        ? update(total, {
            [datasetServiceIdentifier]: {
              $merge: {
                service: undefined,
                specification: {},
                loading: false,
                loadCanceled: true,
              },
            },
          })
        : update(total, { $unset: [datasetServiceIdentifier] })
    },
    oldDatasetServices || {}
  )

  dispatch({
    ...(_.isEmpty(activeDatasetServiceIdentifies) && { loading: false }),
    type: ABORT_UNIPIPE_DATASETS_SERVICE,
    activeDatasetServiceIdentifies,
    datasetServices: await datasetServices,
  })
}

const unipipeActions = ({
  state,
  dispatch,
}: {
  state: Payload
  dispatch: ({ type }: { type: string }) => void
}): {
  getUnipipeCatalog: (omitFields: Fields) => Promise<void>
  updateDatasetGroups: (groups: string[]) => void
  updateDynamicUnipipeCatalogItem: (catalogItem: CatalogItem) => Promise<void>
  dispatchDatasetFetchRequest: ({
    catalogItem,
    specParams,
    forceUpdate,
    useSpecParamsInIdentifier,
  }: DatasetRequest) => void
  abortUnipipeDatasetServices: (
    activeDatasetServiceIdentifies: [],
    keepDatasetServiceData?: boolean
  ) => Promise<void>
  emptyUnipipeDatasets: () => void
  getUnipipeCredentials: (group: string, catalogRoleArn: string) => void
} => ({
  getUnipipeCatalog: async (omitFields: Fields) => {
    const [err, response] = await to(listCatalogs({ omitFields }))
    if (err) {
      displayAndLogErrorMessage(`Failed to load catalog. ${err.message}`)
      return
    }

    const catalog = response.data

    if (_.isEmpty(catalog)) {
      displayAndLogErrorMessage('There is no available catalog')
      return
    }

    dispatch({
      type: SET_UNIPIPE_CATALOG,
      catalog,
    })
  },
  updateDatasetGroups: async (groups: string[]) => {
    dispatch({
      type: SET_UNIPIPE_GROUPS,
      groups,
    })
  },
  updateDynamicUnipipeCatalogItem: async (catalogItem: CatalogItem) => {
    const { catalogId, dataset } = catalogItem
    const datasetIdentifier = getDatasetIdentifier(catalogId, dataset)

    const { hasFetchedBefore } = _.get(state, [
      'catalog',
      `${datasetIdentifier}`,
    ])

    if (!hasFetchedBefore) {
      await fetchUpdatedDynamicMetadata({ dispatch, catalogItem })
    }
  },
  dispatchDatasetFetchRequest: async ({
    catalogItem,
    specParams = {},
    forceUpdate = false,
    useSpecParamsInIdentifier = true,
  }: DatasetRequest) => {
    if (_.isEmpty(catalogItem)) return

    const { dataset, baseSpecification, catalogId } = catalogItem
    const datasetServiceIdentifier = getDatasetServiceIdentifier({
      dataset,
      catalogId,
      ...(useSpecParamsInIdentifier && { specParams }),
    })
    const datasetService = state.datasetServices[datasetServiceIdentifier]
    const sharedPayload = {
      dispatch,
      catalogItem,
      specification: {
        ...baseSpecification,
        specParams,
      },
      datasetServiceIdentifier,
      datasetService,
      forceUpdate,
    }
    try {
      if (forceUpdate || shouldFetchData(sharedPayload)) {
        fetchDatasetSpecificationData(sharedPayload)
      }
    } catch (error) {
      const datasetName = getDatasetName(catalogItem)
      dispatchErrorMessage({
        dispatch,
        message: getDatasetFetchingErrorMessage(datasetName, error),
        datasetName,
        datasetServiceIdentifier,
      })
    }
  },
  abortUnipipeDatasetServices: async (
    activeDatasetServiceIdentifies: [],
    keepDatasetServiceData = false
  ) => {
    const toBeDeletedDatasetServices = _.difference(
      state.activeDatasetServiceIdentifies,
      activeDatasetServiceIdentifies
    )

    if (_.isEmpty(toBeDeletedDatasetServices)) return

    await removeAllDeletedDatasetServices({
      dispatch,
      keepDatasetServiceData,
      toBeDeletedDatasetServices,
      activeDatasetServiceIdentifies,
      oldDatasetServices: state.datasetServices,
    })
  },
  emptyUnipipeDatasets: () => {
    dispatch({
      type: EMPTY_UNIPIPE_DATASETS,
    })
  },
  getUnipipeCredentials: async (group: string, catalogRoleArn: string) => {
    return getCredentials(group, catalogRoleArn)
  },
})

export default unipipeActions
