import type { AppConfig, Versions } from 'types/common'
import { MESSAGE_TYPES } from 'constants/message'

class LoggingService {
  sentryDsn = ''

  releaseVersions = ''

  environment = ''

  level = ''

  appVersion?: string

  versions?: Versions

  configure(config: AppConfig['logging'], versions: Versions) {
    const { sentryDsn, environment, level = MESSAGE_TYPES.error } = config

    this.sentryDsn = sentryDsn

    this.environment = environment

    this.level = level

    this.appVersion = process.env.REACT_APP_VERSION

    this.versions = versions
  }
}

const instance = new LoggingService()

export default instance
