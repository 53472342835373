import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'

// constants
import {
  DATE_UNIT_TYPES,
  getCommonlyUsedOptions,
  DEFAULT_RELATIVE_TIME,
} from 'constants/datetime'
import { TIME_RANGE_MODES } from 'constants/filter'

// utils
import { getSnappedDatetimeStr } from 'helpers/datetime'
import { convertTimeToRelativeTime } from '../utils'

// components
import RelativeTimePicker from '../RelativeTimePicker'

// style
import scss from './index.module.scss'

const QuickMenu = ({ start, onChange, timezone }) => {
  const commonlyOptions = getCommonlyUsedOptions()
  const relativeTimeObj = useMemo(
    () => convertTimeToRelativeTime(start),
    [start]
  )
  const options = useMemo(() => {
    const unit = DATE_UNIT_TYPES.days
    const mode = TIME_RANGE_MODES.absoluteTime
    const now = ''
    const todayOption = {
      label: 'Today only',
      value: {
        startTime: {
          mode,
          value: getSnappedDatetimeStr({ baseDatetime: now, unit, timezone }),
        },
        endTime: {
          mode,
          value: getSnappedDatetimeStr({
            baseDatetime: now,
            unit,
            timezone,
            isStart: false,
          }),
        },
      },
    }
    return [...commonlyOptions, todayOption]
  }, [timezone, commonlyOptions])

  const onRelativeTimeChange = useCallback(
    value => {
      const newTime = {
        startTime: {
          mode: TIME_RANGE_MODES.relativeTime,
          value,
        },
        endTime: { mode: TIME_RANGE_MODES.now, value: TIME_RANGE_MODES.now },
      }
      onChange(newTime)
    },
    [onChange]
  )

  return (
    <div className={scss.container}>
      <div className={scss.title}>Commonly used</div>
      <ul className={scss.list}>
        {options.map(({ label, value }) => (
          <li
            key={label}
            onClick={() => {
              onChange(value, true)
            }}
          >
            {label}
          </li>
        ))}
      </ul>
      <div className={`${scss.title} mt-1`}>Quick select</div>
      <RelativeTimePicker
        selectedTime={relativeTimeObj.value}
        onChange={onRelativeTimeChange}
      />
    </div>
  )
}

QuickMenu.propTypes = {
  start: PropTypes.shape({
    value: PropTypes.string,
    mode: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  timezone: PropTypes.string,
}

QuickMenu.defaultProps = {
  start: {
    mode: TIME_RANGE_MODES.relativeTime,
    value: DEFAULT_RELATIVE_TIME,
  },
  timezone: null,
}

export default QuickMenu
