// libraries
import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'

// constants
import { DATE_UNIT_TYPES, getIntervalUnitOptions } from 'constants/datetime'

// components
import { MultiSelect } from 'components/common'
import {
  getRelativeTimeObjFromTimeStr,
  getRelativeTimeStrFromTimeObj,
} from '../utils'

// style
import scss from './index.module.scss'

const RelativeTimePicker = ({ selectedTime, onChange }) => {
  const [value, setValue] = useState()
  const [unit, setUnit] = useState()

  const updateValueAndUnit = useCallback(
    (newValue, newUnit) => {
      const relativeTimeStr = getRelativeTimeStrFromTimeObj({
        value: newValue,
        unit: newUnit,
      })
      onChange(relativeTimeStr)
    },
    [onChange]
  )

  useEffect(() => {
    const { value: selectedValue, unit: selectedUnit } =
      getRelativeTimeObjFromTimeStr(selectedTime) || {}
    if (selectedValue && selectedUnit) {
      setValue(selectedValue)
      setUnit(selectedUnit)
    } else {
      const newValue = selectedValue || 15
      setValue(newValue)
      const newUnit = selectedUnit || DATE_UNIT_TYPES.minutes
      setUnit(newUnit)
      updateValueAndUnit(newValue, newUnit)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTime])

  return (
    <div className={`row g-3 d-flex align-items-center ${scss.timeRow}`}>
      <div className='col-4'>
        <input
          type='number'
          className={scss.input}
          autoComplete='off'
          min={1}
          step={1}
          defaultValue={value}
          onChange={event => updateValueAndUnit(event.target.value, unit)}
        />
      </div>
      <div className='col-8'>
        <MultiSelect
          options={getIntervalUnitOptions('ago')}
          value={unit}
          onChange={option => updateValueAndUnit(value, option.value)}
          isMulti={false}
          isClearable={false}
          placeholder='Select Time Unit'
        />
      </div>
    </div>
  )
}

RelativeTimePicker.propTypes = {
  selectedTime: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

RelativeTimePicker.defaultProps = {
  selectedTime: '',
}

export default RelativeTimePicker
