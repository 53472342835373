// libraries
import _ from 'lodash'

// constants
import { USER_ROLES, MFA_OPTIONS } from 'constants/user'
import { ENTITIES } from 'constants/common'

import type { Option, Options } from 'types/common'
import type { User } from 'types/user'

export const getNewUserValues = (
  currentUser: User
): Pick<User, 'role' | 'group'> => {
  return {
    role: USER_ROLES.user,
    group: currentUser.group,
  }
}

export const getUsernameFromEmail = (email: string): string => {
  return email.replace(/\s/g, '').split('@').join('_at_')
}

export const getShortUsernameFromEmail = (email: string): string => {
  return email.replace(/\s/g, '').split('@')[0]
}

export const getFirstNameFromEmail = (email: string): string => {
  return getShortUsernameFromEmail(email).split('.')[0]
}

export const getLastNameFromEmail = (email: string): string => {
  const names = getShortUsernameFromEmail(email).split('.')
  return names.length > 1 ? names[1] : 'None'
}

const isRole = (target: string) => (role?: string) => target === role

export const isSuperAdmin = isRole(USER_ROLES.admin)

export const isGroupAdmin = isRole(USER_ROLES.groupAdmin)

export const isUser = isRole(USER_ROLES.user)

export const getUserOption = (
  user: User
): Option<string> & { type: string } & User => {
  const { id, username, givenName, familyName } = user
  const value = username || id
  return {
    value,
    label: givenName && familyName ? `${givenName} ${familyName}` : value,
    ...user,
    type: ENTITIES.user,
  }
}

export const getUsersOptionsByGroup = (
  users: User[],
  group: string
): User[] => {
  const selectedUsers = group ? _.filter(users, { group }) : users
  return _.map(selectedUsers, getUserOption)
}

type UserOption = Partial<{
  avatarUrl: string | null
  value: string | null
  label: string
  name: string
  isValid: boolean
}>

export const getUserOptionByEmail = ({
  email,
  name,
  avatarUrl,
}: Partial<User> = {}): UserOption => ({
  avatarUrl,
  value: email,
  label: name,
  name,
  isValid: true,
})

export const getUserOptionsByEmail = (usersList: User[]): UserOption[] =>
  _(usersList).filter('enabled').map(getUserOptionByEmail).value()

export const getUserOptionBySms = ({
  phone,
  name,
  avatarUrl,
}: Partial<User> = {}): UserOption => ({
  avatarUrl,
  value: phone,
  label: name,
  name,
})

export const getUserOptionsBySms = (usersList: User[]): UserOption[] =>
  _(usersList)
    .filter(({ enabled, phone }) => {
      return enabled && !_.isNil(phone)
    })
    .map(getUserOptionBySms)
    .value()

export const findUserByName = (
  users: User[],
  username: string
): User | Pick<User, 'username'> => {
  return _.find(users, { id: username }) || { username }
}

export const getUserGroupsOptions = (
  groups: {
    name: string
    label: string
  }[]
): Option[] => {
  return _.map(groups, ({ name, label }) => {
    return { value: name, label }
  })
}

export const getUserEnableTimezone = (user: User): boolean => {
  return !!user?.preferences?.enableTimezone || false
}

export const hasNoMfa = (preferredMFA: string): boolean => {
  return !preferredMFA || preferredMFA === MFA_OPTIONS.NOMFA
}

export const getGroupDisplayName = ({
  userGroupsOptions,
  group,
}: {
  userGroupsOptions: Options
  group: string
}): string => _.get(_.find(userGroupsOptions, { value: group }), 'label', group)
