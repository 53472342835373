import { ReactElement, PropsWithChildren } from 'react'

const FieldDescription = ({
  className = '',
  isLarge,
  children,
}: PropsWithChildren<{
  className?: string
  isLarge?: boolean
}>): ReactElement => (
  <div
    className={`form-label-description ${
      isLarge ? 'is-large' : ''
    } ${className}`}
  >
    {children}
  </div>
)

export default FieldDescription
