// libraries
import _ from 'lodash'
import { createSelector } from 'reselect'
import {
  getUsersOptionsByGroup,
  getUserOptionsByEmail,
  getUserOptionsBySms,
} from 'helpers/user'

import { User, Name, Email, PhoneNumber } from 'types/user'
import type { States, UserState } from 'types/states'

const getState = (state: States) => state.userState

const getUsersList = createSelector(getState, state =>
  _.sortBy(state.usersList, ({ name }) => _.upperFirst(name))
)

const getUsersOptions = createSelector(getUsersList, getUsersOptionsByGroup)

const getUsersGroups = createSelector(getUsersList, usersList =>
  _.uniqBy(usersList, 'group')
)

export type UserEmailOption = {
  label: Name
  value: Email
  isValid: boolean
} & Partial<Pick<User, 'name' | 'avatarUrl'>>

export type UserEmailOptions = UserEmailOption[]

const getUserEmailOptions = createSelector(getUsersList, getUserOptionsByEmail)

export type UserPhoneNumberOption = {
  label: Name
  value: PhoneNumber
} & Pick<User, 'name' | 'avatarUrl'>

export type UserPhoneNumberOptions = UserPhoneNumberOption[]

const getUserPhoneNumberOptions = createSelector(
  getUsersList,
  getUserOptionsBySms
)

const selectors = (state: States): UserState => ({
  usersList: getUsersList(state),
  usersOptions: getUsersOptions(state),
  userEmailOptions: getUserEmailOptions(state),
  userPhoneNumberOptions: getUserPhoneNumberOptions(state),
  groups: getUsersGroups(state),
})

export default selectors
