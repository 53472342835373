// libraries
import React, { useState, useCallback } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

// constants
import {
  PROFILE_WIDGET_TYPES,
  DEFAULT_PROFILE_PROPERTIES_SETTING,
} from 'constants/profile'

// components
import { MinMaxRange, IconButton } from 'components/common'
import WidgetIcon from 'components/widget/common/WidgetIcon'
import ActionItemDialog from 'components/common/ActionItemDialog'

import scss from './index.module.scss'

const PropertiesListSwitchItemDialog = ({
  showSwitchItemDialog,
  setShowSwitchItemDialog,
  setShowRegularItem,
  onSwitchItem,
  widgetType,
  range,
  onWidgetRangeChange,
}) => {
  const [showRangeInputs, setShowRangeInputs] = useState(false)

  const onCancelSwitchItemHandler = useCallback(() => {
    setShowSwitchItemDialog(false)
  }, [setShowSwitchItemDialog])

  const onConfirmSwitchItemHandler = useCallback(
    type => {
      onSwitchItem(type)
      switch (type) {
        case PROFILE_WIDGET_TYPES.numeric:
        case PROFILE_WIDGET_TYPES.list:
          setShowSwitchItemDialog(false)
          break
        case PROFILE_WIDGET_TYPES.line:
        case PROFILE_WIDGET_TYPES.bar:
        case PROFILE_WIDGET_TYPES.gauge:
          setShowRangeInputs(true)
          break
        default:
          break
      }
    },
    [onSwitchItem, setShowSwitchItemDialog]
  )

  const onConfirmMinMaxRangeHandler = useCallback(() => {
    onWidgetRangeChange(range)
    setShowSwitchItemDialog(false)
  }, [onWidgetRangeChange, setShowSwitchItemDialog, range])

  const renderRangeInputs = useCallback(
    type => {
      return (
        <div className='d-flex justify-content-between w-100 align-content-center'>
          <IconButton
            icon='MdChevronLeft'
            onClick={() => setShowRangeInputs(false)}
            className={scss.iconButton}
            size={16}
          />
          <div className={scss.widgetIcon}>
            <WidgetIcon className={scss.svgButton} widgetType={type} />
          </div>
          <MinMaxRange
            minValue={range[0]}
            maxValue={range[1]}
            onChange={onWidgetRangeChange}
            label={false}
            isClearable={false}
            className={`row ${scss.range}`}
          />
          <IconButton
            icon='MdCheckCircle'
            onClick={() => onConfirmMinMaxRangeHandler()}
            size={20}
            className={scss.iconButton}
          />
        </div>
      )
    },
    [onConfirmMinMaxRangeHandler, onWidgetRangeChange, range]
  )

  const renderWidgetSelector = useCallback(
    () => (
      <div className='d-flex justify-content-between w-100'>
        <div className='d-flex justify-content-between flex-grow-1'>
          {_.map(PROFILE_WIDGET_TYPES, type => (
            <button
              key={type}
              onClick={() => onConfirmSwitchItemHandler(type)}
              className={`${scss.svgButton} ${
                type === widgetType ? scss.active : ''
              }`}
              type='button'
            >
              <WidgetIcon widgetType={type} />
            </button>
          ))}
        </div>
        <IconButton
          icon='MdChevronRight'
          onClick={onCancelSwitchItemHandler}
          size={16}
          className={`${scss.iconButton} ms-5`}
        />
      </div>
    ),
    [onCancelSwitchItemHandler, onConfirmSwitchItemHandler, widgetType]
  )

  return (
    <ActionItemDialog
      showActionItemDialog={showSwitchItemDialog}
      setShowRegularItem={setShowRegularItem}
    >
      {showRangeInputs ? renderRangeInputs(widgetType) : renderWidgetSelector()}
    </ActionItemDialog>
  )
}

PropertiesListSwitchItemDialog.propTypes = {
  showSwitchItemDialog: PropTypes.bool.isRequired,
  setShowSwitchItemDialog: PropTypes.func.isRequired,
  setShowRegularItem: PropTypes.func.isRequired,
  onSwitchItem: PropTypes.func.isRequired,
  widgetType: PropTypes.string,
  range: PropTypes.arrayOf(PropTypes.number).isRequired,
  onWidgetRangeChange: PropTypes.func.isRequired,
}

PropertiesListSwitchItemDialog.defaultProps = {
  widgetType: DEFAULT_PROFILE_PROPERTIES_SETTING.widgetType,
}

export default PropertiesListSwitchItemDialog
