import { ReactElement } from 'react'
import { IconType } from 'react-icons'
import styled from '@emotion/styled'

import type { BladeProps } from 'components/common/Blade'

import { IconButton } from 'components/common'
import { useStaticResourceStateValue } from 'contexts'
import scss from './index.module.scss'

const StyledContainer = styled.div<{ hasSubtitle?: boolean }>`
  padding: ${({ hasSubtitle }) => (hasSubtitle ? '4px' : '16px')} 16px;
  border-bottom: 1px solid ${({ theme }) => theme['secondary-100']};
`

type Props = Pick<
  BladeProps,
  | 'title'
  | 'titleIconName'
  | 'titleIconSize'
  | 'subtitle'
  | 'actions'
  | 'onBack'
  | 'onClose'
> & { className?: string }

/**
 * @param props.title - panel title
 * @param props.titleIconName - icon name from 'components/icons'
 * @param props.titleIconSize - icon size
 * @param props.subtitle - the text that is displayed under the title
 * @param props.actions - list of props for <IconButton>
 * @param props.className - will be applied to a container
 * @param props.onBack - enables go-back arrow
 * @param props.onClose - enables x-close button
 */
const BladeHeader = ({
  title = '',
  titleIconName,
  titleIconSize = 20,
  subtitle,
  actions,
  className,
  onBack,
  onClose,
}: Props): ReactElement => {
  const isClosable = !!onClose
  const displayDivider = !!actions?.length && isClosable
  const hasSubtitle = !!subtitle

  const { Icons } = useStaticResourceStateValue()
  const Icon = titleIconName
    ? (Icons as Record<string, IconType>)[titleIconName]
    : null

  return (
    <StyledContainer className={className} hasSubtitle={hasSubtitle}>
      <div className={scss.container}>
        <div className={scss.titleContainer}>
          {onBack && (
            <IconButton
              icon='BackArrow'
              className={scss.backArrowBtn}
              onClick={onBack}
              testId='blade-go-back'
            />
          )}
          {Icon && (
            <div className={scss.titleIconContainer}>
              <Icon
                className={scss.titleIcon}
                size={titleIconSize}
                width={titleIconSize}
                height={titleIconSize}
                data-testid='blade-title-icon'
              />
            </div>
          )}
          <div className={scss.textContainer}>
            {title && <div className={scss.title}>{title}</div>}
            {hasSubtitle && <div className={scss.subtitle}>{subtitle}</div>}
          </div>
        </div>
        <div className={scss.actionsContainer}>
          {actions?.map(({ key, ...actionProps }) => (
            <IconButton
              key={`${actionProps.icon}-${key}`}
              className={`${scss.headerActionBtn} ${actionProps.className}`}
              {...actionProps}
            />
          ))}
          {isClosable && (
            <>
              {displayDivider && <div className={scss.divider} />}
              <IconButton
                icon='CloseIcon'
                className={scss.closeButton}
                onClick={onClose}
                testId='blade-close'
              />
            </>
          )}
        </div>
      </div>
    </StyledContainer>
  )
}

export default BladeHeader
