import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FiArrowLeft } from 'react-icons/fi'

import { useBranding } from 'hooks'

import type { BreadCrumbItem } from 'types/common'

import scss from './index.module.scss'

type Props = {
  crumbs: BreadCrumbItem[],
}

const Breadcrumb = React.memo(({ crumbs }: Props) => {
  const {
    colour: { primary },
  } = useBranding()

  const navigate = useNavigate()

  const fontColour = { color: primary }

  return (
    <nav aria-label='breadcrumb'>
      <ol className={`breadcrumb ${scss.breadcrumb} d-flex align-items-center`}>
        <FiArrowLeft
          className={scss.arrowBack}
          size={16}
          {...(crumbs.length === 1 && {
            onClick: () => navigate(crumbs[0].link),
            style: { cursor: 'pointer' },
          })}
        />
        {crumbs.map(({ label, link }) => (
          <li
            key={`${label}${link}`}
            className={`breadcrumb-item ${scss.breadcrumbItem}`}
            style={fontColour}
          >
            <span
              data-testid='arrow-back'
              onClick={() => link && navigate(link)}
            >
              {label}
            </span>
          </li>
        ))}
      </ol>
    </nav>
  )
})

export default Breadcrumb
