import { ReactElement } from 'react'
import keymirror from 'keymirror'
import { useTheme } from '@emotion/react'

// components
import * as Icons from 'components/icons'

import scss from './index.module.scss'

export const ALERT_TYPES = keymirror({
  info: null,
})

export type AlertType = keyof typeof ALERT_TYPES

const ALERT_ICONS = {
  [ALERT_TYPES.info]: 'InfoIcon',
}

const ALERT_COLOURS = {
  [ALERT_TYPES.info]: 'text-secondary',
}

const Alert = ({
  type = ALERT_TYPES.info,
  content,
  className,
}: {
  type: AlertType
  content?: string
  className?: string
}): ReactElement => {
  const theme = useTheme()
  const Icon = Icons[ALERT_ICONS[type]]

  return (
    <div className={`d-flex align-self-start ${className}`}>
      <div className={scss.icon}>
        <Icon size={14} color={theme[ALERT_COLOURS[type]]} />
      </div>
      {content && (
        <div className={scss.content} style={{ color: ALERT_COLOURS[type] }}>
          {content}
        </div>
      )}
    </div>
  )
}

export default Alert
