// libraries
import _ from 'lodash'
import to from 'await-to-js'
import isEqual from 'fast-deep-equal'

// constants
import {
  MESSAGE_TYPES,
  MESSAGE_ENTITIES,
  MESSAGE_STATUS,
} from 'constants/message'
import {
  LAYER_TYPES,
  BASE_LAYER_TYPES,
  MAP_TEMPORAL_SETTING_KEY,
  POLYGONS_WITH_BBOX_KEY,
  MULTI_LABELS_LAYER_TYPES,
} from 'constants/map'

// utils
import { getSimplifiedLayerProperties } from 'helpers/map'
import { isStringContainsCharacters } from 'helpers/utils'
import { showError, showEntityCrudMessage } from 'helpers/message'

import {
  getLayerSpecParams,
  getDatasetOptionByDatasetNameAndCatalogId,
  getValidSpecificationParameters,
} from 'helpers/unipipe'
import log from 'helpers/log'
import { getNewPropertyLabelConfig } from 'components/map/layers/styling/LayerStylingTabs/common/LayerStylingLabelsSettings'

import type { Options } from 'types/common'
import type { Map, MapLayer, GeojsonData, MapLayerStyle } from 'types/map'

type GetPolygonsWithBbox = (polygonId: string) => Promise<GeojsonData>

export const isMapSettingChanged = (newMap: Map, oldMap: Map): boolean => {
  return !isEqual(oldMap, newMap)
}

const omitLayerTemporalSettings = (layer: MapLayer): MapLayer => {
  const { style, ...rest } = layer
  const newStyle = _.reduce(
    style,
    (acc, layerStyle, key) => {
      return { ...acc, [key]: _.omit(layerStyle, [MAP_TEMPORAL_SETTING_KEY]) }
    },
    {}
  )
  return { ..._.omit(rest, ['profileStyle']), style: newStyle }
}

export const omitMapTemporalSettings = (mapSetting: Map): Map => {
  if (_.isEmpty(mapSetting.layers)) return mapSetting

  const layers = _.map(mapSetting.layers, omitLayerTemporalSettings)
  return { ...mapSetting, layers }
}

const getCatalogId = (
  layer: MapLayer,
  mapEligibleDatasetOptions: Options
): string => {
  const { dataset, catalogId } = layer
  return (
    catalogId ||
    getDatasetOptionByDatasetNameAndCatalogId(
      mapEligibleDatasetOptions,
      dataset
    )?.catalogId
  )
}

const getLayerAncillaryData =
  (getPolygonsWithBbox: GetPolygonsWithBbox) =>
  async (layer: MapLayer): Promise<MapLayer> => {
    const { style, type } = layer
    if (type !== LAYER_TYPES.polygon) return layer

    const { polygon: { assetPolygonId } = {} } = style[type]
    if (!assetPolygonId || _.isObject(assetPolygonId)) return layer
    log.debug('Found layer assetPolygonId: ', assetPolygonId)

    const polygonsWithBbox = await getPolygonsWithBbox(assetPolygonId)
    _.set(
      style,
      [type, MAP_TEMPORAL_SETTING_KEY, POLYGONS_WITH_BBOX_KEY],
      polygonsWithBbox
    )
    return { ...layer, style }
  }

export const getLayersWithExternalAncillaryData = async (
  layers: MapLayer[],
  getPolygonsWithBbox: GetPolygonsWithBbox
): Promise<MapLayer[]> => {
  const [err, newLayers] = await to(
    Promise.all(_.map(layers, getLayerAncillaryData(getPolygonsWithBbox)))
  )
  if (err) {
    const message = 'There is error fetching layers external ancillary data'
    log.error(message, err)
    return layers
  }

  return newLayers
}

const migrateMapLayerSingleLabelToMultipleLabels = (
  style: MapLayerStyle
): MapLayerStyle => {
  const { labelProperty } = style

  const labelProperties = [
    'labelProperty',
    'labelSize',
    'labelColour',
    'labelAnchor',
    'labelAlignment',
    'labelOffsetX',
    'labelOffsetY',
  ]

  return labelProperty
    ? _.omit(
        {
          ...style,
          propertyLabels: [
            {
              ...getNewPropertyLabelConfig(),
              ..._.omit(_.pick(style, labelProperties), _.isNil),
            },
          ],
        },
        labelProperties
      )
    : style
}

export const updateMapApiLayerLegacySettings = (
  layers: MapLayer[],
  mapEligibleDatasetOptions?: Options
): MapLayer[] =>
  _.map(layers, layer => {
    const {
      profileTitle,
      interactionList,
      profile,
      profileType,
      style,
      ...restLayer
    } = layer

    const newStyle = _.reduce(
      style,
      (acc, cur, key) => {
        return {
          ...acc,
          [key]: _.includes(MULTI_LABELS_LAYER_TYPES, key)
            ? migrateMapLayerSingleLabelToMultipleLabels(cur)
            : cur,
        }
      },
      {}
    )

    const catalogId = getCatalogId(restLayer, mapEligibleDatasetOptions)

    const specParams = getLayerSpecParams(restLayer)

    const omitProperties = ['alertSettings']
    const baseType = BASE_LAYER_TYPES.asset
    const isAssetLayer =
      restLayer.type === BASE_LAYER_TYPES.asset ||
      restLayer.baseType === BASE_LAYER_TYPES.asset

    const profilePayload = {
      ...(profileType && { type: profileType }),
      title: profileTitle,
      properties: getSimplifiedLayerProperties(interactionList),
      ...(isAssetLayer && _.isString(profile)
        ? { assetProfileId: profile }
        : { ...profile }),
    }

    return _.omit(
      {
        profile: profilePayload,
        ...(isAssetLayer && { baseType }),
        ...restLayer,
        specParams: getValidSpecificationParameters(specParams),
        catalogId,
        style: newStyle,
      },
      omitProperties
    )
  })

export const handleUpdateMapError = (mapErr: Error): void => {
  if (isStringContainsCharacters(mapErr.message, 'not found')) {
    showError('The map to be saved has been deleted.')
  } else {
    showEntityCrudMessage({
      status: MESSAGE_STATUS.saved,
      entity: MESSAGE_ENTITIES.map,
      type: MESSAGE_TYPES.error,
      error: mapErr,
    })
  }
}
