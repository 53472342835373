import { ReactElement } from 'react'
import { WidgetProps, getSubmitButtonOptions } from '@rjsf/utils'
import { Button } from 'components/common'

const SubmitButton = ({ uiSchema }: WidgetProps): ReactElement | null => {
  const {
    submitText,
    norender,
    props: submitButtonProps,
  } = getSubmitButtonOptions(uiSchema)
  if (norender) return null

  return (
    <Button type='submit' {...submitButtonProps}>
      {submitText}
    </Button>
  )
}

export default SubmitButton
