import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

const useUrlMatch = keyword => {
  const { search } = useLocation()

  const isMatched = useMemo(() => {
    const searchParams = new URLSearchParams(search)
    return searchParams.has(keyword)
  }, [search, keyword])

  return isMatched
}

export default useUrlMatch
