import { useCallback } from 'react'

// constants

import { USER_PREFERENCES } from 'constants/settings'

// utils
import { useAuthStateValue } from 'contexts'
import { useCurrentUser } from 'hooks'
import { omitStaleMethaneEntities } from 'helpers/utils'

import type { Payload } from 'types/common'
import { Filters } from 'types/filter'

/** Returns the function that can be used to call API to update filter preferences */
const useUpdateFilterPreferences = ({
  entity,
  onChangeCallback,
}: {
  entity: string
  onChangeCallback?: (filters: Filters) => void
}): {
  updateFilterPreferences: (
    payload: Payload<string | string[]>
  ) => Promise<void>
} => {
  const { currentUser } = useAuthStateValue()
  const { filters = {} } = currentUser.preferences
  const { updateCurrentUserPreference } = useCurrentUser()

  const updateFilterPreferences = useCallback(
    async (newFilterValues: Payload<string | string[]>) => {
      onChangeCallback?.(newFilterValues)

      const payload = {
        preference: USER_PREFERENCES.filters,
        value: {
          ...omitStaleMethaneEntities(filters),
          [entity]: newFilterValues,
        },
      }
      updateCurrentUserPreference(payload, USER_PREFERENCES.filters)
    },
    [onChangeCallback, filters, entity, updateCurrentUserPreference]
  )

  return { updateFilterPreferences }
}

export default useUpdateFilterPreferences
