import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { IconButton } from 'components/common'

export const StyledToolbarButton = styled(IconButton)<{
  isActive?: boolean
  isHeading?: boolean
  color?: string
}>`
  display: flex;
  align-items: center;
  padding: 5px;
  color: ${({ isActive, disabled, color, theme }) =>
    disabled ? theme.dark : color || (isActive ? theme.primary : theme.dark)};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  user-select: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')} !important;

  ${({ disabled, theme }) =>
    // Do not change color on hover if btn is disabled (override defaults)
    disabled
      ? `
        &:hover {
          color: ${theme.dark};
          fill: ${theme.dark};
          opacity: 0.5
        }
  `
      : ''}

  ${({ isHeading }) =>
    isHeading
      ? css`
          justify-content: center;
          min-width: 33px;
          font-size: 17px;
          line-height: 1;
          font-weight: 600;
        `
      : ''}
`
