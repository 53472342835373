// libraries
import {
  useState,
  useEffect,
  useCallback,
  ReactElement,
  PropsWithChildren,
} from 'react'
import isEqual from 'fast-deep-equal'
import _ from 'lodash'

// constants
import { DEFAULT_COLOUR_STEP } from 'constants/map'
import {
  rawColoursOptions,
  rawReversedColoursOptions,
} from 'components/common/Colour/ColourPickerScales/colourOptionsData'

// utils
import {
  areColoursEqual,
  getColourIndex,
  hasEnoughColour,
} from 'helpers/colour'

// components
import ColourRampBar from 'components/map/controls/MapLegend/ColourRampBar'
import {
  MultiSelect,
  NumericInput,
  TitleWithTooltip,
  IconButton,
} from 'components/common'

import type { Options, Colours } from 'types/common'

// style
import './index.scss'

const getStep = (newStep: number) => {
  const { minStep, maxStep } = DEFAULT_COLOUR_STEP
  return _.clamp(newStep, minStep, maxStep)
}

/**
 * It will get options for colours dropdown list based on given length
 * Value will be RGB colours that will be used in deck.gl layer
 * Label will be HEX colours that will be used in colours list for styling
 */
const getFilteredColourOptions = (
  options: Options,
  length: number
): Options => {
  let filteredOptions
  const isLengthWithinValidRange =
    length >= DEFAULT_COLOUR_STEP.minStep &&
    length <= DEFAULT_COLOUR_STEP.maxStep
  if (isLengthWithinValidRange) {
    filteredOptions = _.filter(options, option =>
      hasEnoughColour(option, length)
    )
  }
  return filteredOptions || []
}

type ColourRampProp = {
  colours: Colours
  onChange: (newColours: Colours) => void
  reverse?: boolean
}

export const ColourRamp = ({
  colours,
  onChange,
  reverse = false,
}: ColourRampProp): ReactElement => {
  /**
   * Raw all colours options
   */
  const [allColoursOptions] = useState(rawColoursOptions)
  /**
   * Reversed colours options
   */
  const [allReversedColoursOptions] = useState(rawReversedColoursOptions)
  /**
   * Base colours options. Either allColoursOptions or allReversedColoursOptions
   */
  const [currentOptions, setCurrentColourOptions] = useState([])
  /**
   * Filtered colours options from currentOptions
   */
  const [coloursOptions, setColoursOptions] = useState([])
  /**
   * Colours reverse status
   */
  const [isColoursReversed, setIsColoursReversed] = useState(false)
  const [coloursVal, setColoursVal] = useState(colours)
  const [coloursIndex, setColoursIndex] = useState(0)
  const [step, setStep] = useState(() => getStep(colours.length))

  const onColoursChangeHandler = useCallback(
    ({ value }) => {
      const index = getColourIndex(coloursOptions, value)
      setColoursIndex(index)
    },
    [coloursOptions]
  )

  const handleStepChange = useCallback((newStep: number) => {
    if (_.isNil(newStep)) return
    setStep(getStep(newStep))
  }, [])
  /**
   * Formats each option label to show a colour bar
   * The colour bar is generated with css styling using linear-gradient
   */
  const getSingleColourOptionLabel = ({
    label: labels,
  }: {
    label: string[]
  }) => {
    return <ColourRampBar colourStrArr={labels} height='11' />
  }

  useEffect(() => {
    const currentOpts = !isColoursReversed
      ? allColoursOptions
      : allReversedColoursOptions
    setCurrentColourOptions(currentOpts)
    setIsColoursReversed(!isColoursReversed)
    const reversedColours = [...coloursVal].reverse()
    setColoursVal(reversedColours)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reverse])

  useEffect(() => {
    // determine the colours options group which the input colours belongs to
    const hasGivenColours = allColoursOptions.some(o => {
      return areColoursEqual(o.value, colours)
    })

    const currentOpts = hasGivenColours
      ? allColoursOptions
      : allReversedColoursOptions
    setCurrentColourOptions(currentOpts)
    setIsColoursReversed(hasGivenColours)
    if (!_.isEmpty(currentOpts)) {
      // init the colour index from colour options based on input colours
      const filteredColoursOptions = getFilteredColourOptions(
        currentOpts,
        colours.length
      )
      const index = getColourIndex(filteredColoursOptions, colours)
      setColoursIndex(index)
    }
  }, [allColoursOptions, allReversedColoursOptions, colours])
  /**
   * Init the colour options based on given colour array length
   */
  useEffect(() => {
    if (!_.isEmpty(currentOptions)) {
      const filteredColoursOptions = getFilteredColourOptions(
        currentOptions,
        step
      )
      let newColoursOption
      // use the colour from the same index of the updated options when available
      if (filteredColoursOptions[coloursIndex]) {
        newColoursOption = filteredColoursOptions[coloursIndex]
      } else {
        // otherwise, use the first option as the new colour
        newColoursOption = _.first(filteredColoursOptions)
        setColoursIndex(0)
      }

      setColoursVal(newColoursOption?.value)
      setColoursOptions(filteredColoursOptions)
    }
  }, [coloursIndex, currentOptions, step])
  /**
   * Update the coloursVal when the coloursIndex is changed
   */
  useEffect(() => {
    const onColoursIndexChange = () => {
      if (!_.isEmpty(coloursOptions)) {
        const newColoursVal = coloursOptions[coloursIndex].value
        if (!isEqual(coloursVal, newColoursVal)) {
          setColoursVal(newColoursVal)
        }
        if (!isEqual(newColoursVal, colours)) {
          onChange(newColoursVal)
        }
      }
    }
    onColoursIndexChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coloursOptions, coloursIndex])

  const renderColourPicker = () => (
    <div className='row g-3'>
      <div className='col-8'>
        <MultiSelect
          className='colourScalesPicker'
          formatOptionLabel={getSingleColourOptionLabel}
          value={coloursVal}
          options={coloursOptions}
          onChange={onColoursChangeHandler}
          placeholder='Select a Colour'
          isMulti={false}
          isSearchable={false}
          isClearable={false}
        />
      </div>
      <div className='col-4'>
        <NumericInput
          min={DEFAULT_COLOUR_STEP.minStep}
          max={DEFAULT_COLOUR_STEP.maxStep}
          value={step}
          className='form-control'
          onChange={handleStepChange}
        />
      </div>
    </div>
  )

  return _.isEmpty(coloursOptions) ? <></> : renderColourPicker()
}

type ColourPickerScalesProp = PropsWithChildren<
  Pick<ColourRampProp, 'colours' | 'onChange'>
>

const ColourPickerScales = ({
  colours,
  onChange,
  children,
}: ColourPickerScalesProp): ReactElement => {
  const [reverse, setReverse] = useState(false)
  const colourReverseHandler = () => {
    setReverse(!reverse)
  }

  return (
    <div className='groupOption'>
      <div className='groupOptionTitle d-flex justify-content-between'>
        <TitleWithTooltip title='Colour' />
        <IconButton
          icon='ReverseIcon'
          onClick={colourReverseHandler}
          size={14}
        />
      </div>
      <div className='groupOptionContent'>
        <div className='groupOptionLabel'>
          <TitleWithTooltip title='Select Colour' />
        </div>
        <ColourRamp colours={colours} onChange={onChange} reverse={reverse} />
        {children}
      </div>
    </div>
  )
}

export default ColourPickerScales
