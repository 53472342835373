// libraries
import React, { useMemo, useState, useEffect, useCallback } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useToggle } from 'react-use'

// constants
import { GALLERY_LIST_FILTER_TYPES } from 'constants/common'
import { BUTTON_VARIANTS } from 'components/common/Button'

// components
import { Button } from 'components/common'
import AddFilters from './AddFilters'

import { getFilterKeysByPredicate, getValidFilters } from './utils'

const FilterBuilder = ({
  filters,
  filtersSpecs,
  onChange,
  vertical,
  isClearable,
  className,
  style,
}) => {
  const [expandFilter, toggleFilterExpanded] = useToggle(true)
  const [filterValues, setFilterValues] = useState(filters || {})

  const nonClearableFilterKeys = useMemo(
    () => getFilterKeysByPredicate(filtersSpecs, { isClearable: false }),
    [filtersSpecs]
  )

  useEffect(() => {
    setFilterValues(oldFilters =>
      _.isEqual(filters, oldFilters) ? oldFilters : getValidFilters(filters)
    )
  }, [filters])

  const onClearFilters = useCallback(() => {
    const nonClearableFilters = _.pick(filterValues, nonClearableFilterKeys)
    onChange(getValidFilters(nonClearableFilters))
  }, [filterValues, nonClearableFilterKeys, onChange])

  const onFiltersChange = useCallback((payload, deleteKeys) => {
    setFilterValues(old =>
      deleteKeys ? _.omit(old, deleteKeys) : { ...old, ...payload }
    )
  }, [])

  const validFilters = useMemo(
    () => getValidFilters(filterValues),
    [filterValues]
  )

  const hasFiltersChanged = useMemo(
    () => !_.isEqual(validFilters, filters),
    [validFilters, filters]
  )

  return (
    <div style={style} className={`d-flex align-items-center ${className}`}>
      <span className='extraSmallText text-secondary text-uppercase'>
        FILTERS
      </span>
      {expandFilter && (
        <>
          <AddFilters
            expandFilter={expandFilter}
            toggleFilterExpanded={toggleFilterExpanded}
            filters={filterValues}
            filtersSpecs={filtersSpecs}
            vertical={vertical}
            onChange={onFiltersChange}
          />

          <Button
            variant={BUTTON_VARIANTS.link}
            disabled={!hasFiltersChanged}
            onClick={() => {
              onChange(validFilters)
            }}
            testId='apply-filter-options'
            className='py-0 mx-2'
          >
            Apply
          </Button>
          {!_.isEmpty(filterValues) && isClearable && (
            <Button
              variant={BUTTON_VARIANTS.link}
              onClick={onClearFilters}
              testId='clear-filter-options'
              className='py-0'
            >
              Clear
            </Button>
          )}
        </>
      )}
    </div>
  )
}

FilterBuilder.propTypes = {
  filters: PropTypes.shape({
    [GALLERY_LIST_FILTER_TYPES.group]: PropTypes.arrayOf(PropTypes.string),
    [GALLERY_LIST_FILTER_TYPES.username]: PropTypes.arrayOf(PropTypes.string),
    [GALLERY_LIST_FILTER_TYPES.isPrivate]: PropTypes.arrayOf(PropTypes.bool),
  }),
  filtersSpecs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      enable: PropTypes.bool,
      Component: PropTypes.func,
    })
  ),
  onChange: PropTypes.func,
  isClearable: PropTypes.bool,
  vertical: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.shape({}),
}

FilterBuilder.defaultProps = {
  filters: {},
  filtersSpecs: [],
  isClearable: true,
  vertical: false,
  onChange: _.noop,
  className: undefined,
  style: undefined,
}

export default FilterBuilder
