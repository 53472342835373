import {
  useCallback,
  ChangeEvent,
  ReactElement,
  useState,
  useEffect,
  useMemo,
  useRef,
  MouseEvent,
  KeyboardEventHandler,
} from 'react'
import styled from '@emotion/styled'

// constants
import { TIME_FORMAT, DATE_UNIT_TYPES } from 'constants/datetime'

// utils
import { displayTime, stopEventDefaultAndPropagation } from 'helpers/utils'
import { isTimeBetween } from 'helpers/filter'
import {
  getNowISOString,
  getSnappedDatetimeStr,
  setNewHourMinute,
} from 'helpers/datetime'
import { useTimezone } from 'hooks'

// components
import { IconButton } from 'components/common'
import { DateTimeInputAddons } from 'components/common/DateTime/common'

import type { Time, UtcISOString, Timezone } from 'types/datetime'

import scss from './index.module.scss'

const StyledInput = styled.input`
  font-size: 13px;
  line-height: 18px;
`

const ErrorText = styled.span`
  color: ${({ theme }) => theme.danger};
  font-size: 12px;
  margin-top: 4px;
`

const getTime = ({
  dateTime,
  timezone,
  defaultToCurrent,
}: {
  dateTime?: UtcISOString
  timezone?: Timezone
  defaultToCurrent?: boolean
}): Time =>
  defaultToCurrent || dateTime
    ? displayTime({
        datetime: dateTime || getNowISOString(),
        timezone,
        timeFormat: TIME_FORMAT,
        displayZoneAbbr: false,
      })
    : ''

export const getNewUtcDateTime = ({
  utcDateTime,
  utcTime,
  defaultToCurrent,
}: {
  utcDateTime?: UtcISOString
  utcTime?: Time
  defaultToCurrent?: boolean
}): string => {
  if (!defaultToCurrent && !utcDateTime && !utcTime) {
    return ''
  }

  const today = getSnappedDatetimeStr({
    baseDatetime: getNowISOString(),
    unit: DATE_UNIT_TYPES.minutes,
  })

  if (utcDateTime || !utcTime) {
    return utcDateTime || today
  }

  return setNewHourMinute({
    oldDateTime: today,
    newTime: utcTime,
  })
}

export type TimePickerProps = {
  utcDateTime?: UtcISOString
  utcTime?: Time
  isDisabled?: boolean
  isClearable?: boolean
  addonIcon?: string
  className?: string
  minDateTime?: UtcISOString
  defaultToCurrent?: boolean
  embeddedIntoCalendar?: boolean
  onChange: (v?: string) => void
  onBlur?: (v?: string) => void
}

const TimePicker = ({
  utcDateTime,
  utcTime,
  isDisabled,
  isClearable,
  addonIcon,
  className = '',
  minDateTime,
  defaultToCurrent = true,
  embeddedIntoCalendar,
  onChange,
  onBlur,
}: TimePickerProps): ReactElement => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const { timezone } = useTimezone()

  const [dateTimeValue, setDateTimeValue] = useState<UtcISOString>(() =>
    getNewUtcDateTime({ utcDateTime, utcTime, defaultToCurrent })
  )

  const timeValue = useMemo(
    () => getTime({ dateTime: dateTimeValue, timezone, defaultToCurrent }),
    [dateTimeValue, timezone, defaultToCurrent]
  )

  const isTimeValid = useMemo(
    () =>
      isTimeBetween(dateTimeValue, minDateTime as string, undefined, 'minute'),
    [dateTimeValue, minDateTime]
  )

  const displayError = dateTimeValue && !isTimeValid

  useEffect(() => {
    setDateTimeValue(
      getNewUtcDateTime({ utcDateTime, utcTime, defaultToCurrent })
    )
  }, [timezone, utcDateTime, utcTime, defaultToCurrent])

  const prepareTimeToSave = useCallback(
    (newDateTime?: string) =>
      utcDateTime || embeddedIntoCalendar
        ? getSnappedDatetimeStr({
            baseDatetime: newDateTime,
            unit: DATE_UNIT_TYPES.minutes,
          })
        : getTime({ dateTime: newDateTime }),
    [utcDateTime, embeddedIntoCalendar]
  )

  const onTimeUpdate = useCallback(() => {
    if (!timeValue) return

    const newDateTime = isTimeValid ? dateTimeValue : minDateTime
    const newResult = prepareTimeToSave(newDateTime)
    onChange(newResult)
  }, [
    dateTimeValue,
    isTimeValid,
    minDateTime,
    timeValue,
    onChange,
    prepareTimeToSave,
  ])

  const onKeyDown = useCallback<KeyboardEventHandler>(
    e => {
      if (e.key === 'Enter') {
        stopEventDefaultAndPropagation(e)
        onTimeUpdate()
      }
    },
    [onTimeUpdate]
  )

  const onTimeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      const newUTCDateTime = setNewHourMinute({
        oldDateTime: utcDateTime,
        timezone,
        newTime: value,
      })

      setDateTimeValue(newUTCDateTime)
      onChange(prepareTimeToSave(newUTCDateTime))
    },
    [timezone, utcDateTime, onChange, prepareTimeToSave]
  )

  const onClear = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation()
      onChange(undefined)
      inputRef.current?.focus()
    },
    [onChange]
  )

  const handleBlur = useCallback(() => {
    onTimeUpdate()
    onBlur?.()
  }, [onTimeUpdate, onBlur])

  const displayClearIcon = !!timeValue && !isDisabled && isClearable
  const hasIcons = displayClearIcon || addonIcon

  return (
    <div className='w-100 position-relative'>
      <StyledInput
        id='time'
        className={`form-control me-0 ${className}`}
        type='time'
        step={300}
        value={timeValue}
        onChange={onTimeChange}
        onBlur={handleBlur}
        onKeyDown={onKeyDown}
        disabled={isDisabled}
        ref={inputRef}
      />

      {hasIcons && (
        <DateTimeInputAddons
          className={scss.addonIcons}
          displayClearIcon={displayClearIcon}
          onClear={onClear}
          addonIcon={addonIcon}
        />
      )}

      {displayError && <ErrorText>Pick an earlier time</ErrorText>}
    </div>
  )
}

const TimePickerContainer = (
  props: TimePickerProps & {
    inline?: boolean
  }
): ReactElement => {
  const { inline = false } = props

  return inline ? (
    <TimePicker {...props} />
  ) : (
    <div className='d-flex align-items-start flex-grow-1'>
      <IconButton
        icon='AiOutlineClockCircle'
        size={16}
        disabled
        className={scss.clockIcon}
      />
      <TimePicker {...props} />
    </div>
  )
}

export default TimePickerContainer
