// libraries
import React from 'react'
import PropTypes from 'prop-types'

// components
import ViewStatusBar from '../ViewStatusBar'

const MapViewStatusBar = ({ children }) => {
  return <ViewStatusBar>{children}</ViewStatusBar>
}

MapViewStatusBar.propTypes = { children: PropTypes.node }

MapViewStatusBar.defaultProps = { children: undefined }

export default MapViewStatusBar
