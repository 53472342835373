import { ReactElement } from 'react'
import { Field } from 'react-final-form'

import type { DateTimeFieldFormat } from 'types/formBuilder'

import { DATE_TIME_FIELD_TYPES } from 'constants/formBuilder'
import { DATE_TIME_COMPARISON_EDITOR_PRECISION } from 'constants/workflow'

import { FormDatetimePicker, TimeInput } from 'components/common/Form'

import type { PropertySpec } from '.'

type DateTimeRangeLimitsInputProps = PropertySpec & {
  name: string
  format: DateTimeFieldFormat
}

const DateTimeRangeLimitsInput = ({
  name,
  format,
  disabled,
  ...rest
}: DateTimeRangeLimitsInputProps): ReactElement => {
  const isDateTimeType = format === DATE_TIME_FIELD_TYPES['date-time']

  return format === DATE_TIME_FIELD_TYPES.time ? (
    <Field
      name={name}
      component={TimeInput}
      addonIcon='AiOutlineClockCircle'
      defaultToCurrent={false}
      inline
      isClearable
      isDisabled={disabled}
      {...rest}
    />
  ) : (
    <Field
      name={name}
      component={FormDatetimePicker}
      addonIcon='DatePickerIcon'
      placeholder='Select'
      highlightPlaceholder={false}
      defaultToCurrent={false}
      showTimeSelect={isDateTimeType}
      selectedTimeType={
        isDateTimeType
          ? DATE_TIME_COMPARISON_EDITOR_PRECISION.MINUTE
          : DATE_TIME_COMPARISON_EDITOR_PRECISION.DAY
      }
      isDisabled={disabled}
      {...rest}
    />
  )
}

export default DateTimeRangeLimitsInput
