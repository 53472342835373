// libraries
import _ from 'lodash'
import { EnumType } from 'json-to-graphql-query'

// constants
import {
  ENTITY_OWNER_FIELDS,
  MUTATION_RESPONSE_ERRORS_FIELDS,
} from 'constants/graphql'
import {
  ISSUE_DETAIL_FRAGMENT,
  ISSUE_SEVERITY_TYPES_FIELDS,
  ISSUE_ACTION_TYPES,
} from 'constants/issue'
import { ENTITIES } from 'constants/common'

// utils
import {
  getArgs,
  getQuery,
  getMutationQuery,
  getGraphqlQuery,
  reportGraphqlError,
  getQueryFields,
  getMutationErrorMessage,
} from 'helpers/graphql'
import {
  mutateEntity,
  listRelayStyleData,
  MutateEntity,
  getEntityGraphql,
  getEntityQuery,
  getEntitiesQuery,
  getGraphql,
} from 'services/api/utils'
import { capitalizeFirstLetter } from 'helpers/utils'
import { getIssueFilterConditions, getActiveIssues } from 'helpers/issue'

import type { Payload } from 'types/common'
import type { QueryParams } from 'types/services'
import type {
  Issue,
  IssueDataCollectionFormReferences,
  IssueTask,
} from 'types/issue'

import { PageInfo, IssueType } from 'types/graphql'
import GraphqlApi from './graphql'

const domain = ENTITIES.issue
const queryDomain = `${domain}s`

const ISSUE_IDENTIFIER = 'issueId'

export const reportIssueError = reportGraphqlError(queryDomain)

export const getIssuesQueryFields = getQueryFields(ISSUE_DETAIL_FRAGMENT)

export const getIssuesQuery = getEntitiesQuery<Issue>({
  queryDomain,
  getFieldsFn: getIssuesQueryFields,
  queryName: 'filteredBy',
})

const getJsonForm = () => {
  return {
    jsonFormBody: {
      schema: true,
      uischema: true,
    },
  }
}

const getXFormFields = () => {
  return {
    xForm: {
      model: true,
      body: {
        id: true,
        __on: [
          {
            __typeName: 'XFormBodyControlNode',
            control: true,
            label: true,
            hint: true,
            ref: true,
            mediatype: true,
            accept: true,
            items: {
              label: true,
              value: true,
            },
            itemset: {
              nodeset: true,
              labelRef: true,
              valueRef: true,
            },
          },
          {
            __typeName: 'XFormBodyGroupNode',
            appearance: true,
            childNodeIds: true,
            ref: true,
            nodeset: true,
            label: true,
          },
        ],
      },
      binds: {
        type: true,
        nodeset: true,
        required: true,
        relevant: true,
        readonly: true,
        constraint: true,
        calculate: true,
      },
    },
  }
}

const getSubjectRules = () => {
  return {
    subjectRules: {
      assetFilter: {
        profile: true,
        displayName: true,
      },
    },
  }
}

const ISSUE_TASK_DATA_COLLECTION_FORM_COMMON_FIELDS = {
  id: true,
  title: true,
  formType: true,
  isActive: true,
  description: true,
  subjectAssetProfile: true,
}

const getIssueTaskDataCollectionFormCommon = (formTypes: string[]) => {
  return {
    ...(!_.isEmpty(formTypes) &&
      getArgs({
        formTypes: _.map(formTypes, formType => new EnumType(formType)),
      })),
    ...ISSUE_TASK_DATA_COLLECTION_FORM_COMMON_FIELDS,
  }
}

const getIssueTaskDataCollectionFormDefinition = ({
  formTypes,
}: {
  formTypes: string[]
}) => {
  return {
    issueTask: {
      dataCollectionForms: {
        ...getIssueTaskDataCollectionFormCommon(formTypes),
        ...getXFormFields(),
        ...getJsonForm(),
      },
    },
  }
}

type GetIssueDataCollectionFields = {
  formTypes: string[]
  withFormDefinition?: boolean
}

const getIssueDataCollectionFields = ({
  formTypes,
  withFormDefinition = false,
}: GetIssueDataCollectionFields) => {
  return {
    issueMultitask: {
      procedures: {
        id: true,
        title: true,
        subTasksStaticFormReferences: true,
        subTasksDynamicFormReferences: true,
        ...getSubjectRules(),
      },
    },
    issueTask: {
      dataCollectionForms: {
        ...getIssueTaskDataCollectionFormCommon(formTypes),
        isSubtaskForm: true,
        isTaskForm: true,
        filterQuestions: {
          displayName: true,
          xFormRef: true,
          items: {
            label: true,
            value: true,
          },
        },
        ...ENTITY_OWNER_FIELDS,
        ...getSubjectRules(),
        modifiedAt: true,
        ...(withFormDefinition && { ...getXFormFields(), ...getJsonForm() }),
      },
    },
  }
}

const getIssueQuery = getEntityQuery({
  queryDomain,
  getFieldsFn: getIssuesQueryFields,
  identifier: ISSUE_IDENTIFIER,
})

export const getIssueAssigneesAndSeverityTypes = getGraphql<{
  issueSeverityTypes: string[]
}>({
  queryDomain: 'issueSeverityTypes',
  getQueryFn: () => ({
    issueSeverityTypes: ISSUE_SEVERITY_TYPES_FIELDS,
  }),
  queryDisplayName: 'GetIssueAssigneesAndSeverityTypes',
  queryName: null,
})

const getIssueFormFilter = (filter?: { form: Payload<string[]> }) => {
  const { form } = filter || {}
  return (
    form && {
      formFilter: _.reduce(
        form,
        (acc, cur, key) => {
          return {
            ...acc,
            [key]:
              key === 'taskStatus' ? _.map(cur, d => new EnumType(d)) : cur,
          }
        },
        {}
      ),
    }
  )
}

export const listIssues = async ({
  queryParams = {},
  abortController,
}: {
  queryParams?: QueryParams
  abortController?: AbortController
}): Promise<{
  data: Issue[]
  error?: string
  pageInfo: PageInfo
}> => {
  const fnName = 'issues.filteredBy'
  const { enableLoadMore = true, filter, ...restQueryParams } = queryParams

  const formFilterPayload = getIssueFormFilter(filter)

  // * Now the deleted issues are fetched from the backend and filtered out by the frontend.
  // TODO add deleted:false to fetch non-deleted issues directly from the backend when backend supports
  const newQueryParams = {
    ...restQueryParams,
    filter: getIssueFilterConditions(filter),
    ...formFilterPayload,
  }

  const isSubtasksMode = _.get(filter, 'type[0].value') === IssueType.Subtask

  const { data, ...rest } = await listRelayStyleData({
    fnName,
    queryDomain,
    enableLoadMore,
    abortController,
    queryParams: newQueryParams,
    getQueryFn: getIssuesQuery,
    omitFields: isSubtasksMode ? [] : ['statesData'],
  })

  return { ...rest, data: getActiveIssues(data as Issue[]) }
}

export const listIssueTaskDataCollectionFormDefinition = (variables: {
  formTypes: string[]
}): Promise<{ issueTask: IssueTask } & { error?: string; code?: string }> => {
  const fnName = 'listIssueTaskDataCollectionFormDefinition'
  const query = getQuery(
    getIssueTaskDataCollectionFormDefinition(variables),
    fnName
  )
  return GraphqlApi.fetch({ query, queryDomain, fnName, variables })
}

export const listIssueTaskDataCollectionFormMetadata = (
  variables: GetIssueDataCollectionFields
): Promise<
  IssueDataCollectionFormReferences & { error?: string; code?: string }
> => {
  const fnName = 'listIssueTaskDataCollectionFormMetadata'
  const query = getQuery(getIssueDataCollectionFields(variables), fnName)
  return GraphqlApi.fetch({ query, queryDomain, fnName, variables })
}

export const getIssue = getEntityGraphql<Issue>({
  queryDomain,
  getQueryFn: getIssueQuery,
  queryDisplayName: 'GetIssueById',
  identifier: ISSUE_IDENTIFIER,
})

export const getJsonFormQueryFields = getQueryFields({
  ...ISSUE_TASK_DATA_COLLECTION_FORM_COMMON_FIELDS,
  ...getJsonForm(),
  ...getSubjectRules(),
  ...ENTITY_OWNER_FIELDS,
  modifiedAt: true,
})

const getJsonFormQuery = getEntityQuery({
  queryDomain: 'issueTask',
  queryName: 'dataCollectionForm',
  getFieldsFn: getJsonFormQueryFields,
  identifierFormat: 'XFormReference!',
})

export const getIssueTaskDataCollectionForm = getEntityGraphql<Issue>({
  queryDomain: 'issueTask',
  queryName: 'dataCollectionForm',
  getQueryFn: getJsonFormQuery,
  queryDisplayName: 'GetIssueTaskDataCollectionForm',
})

export const mutateIssueFactory =
  (mutationName: string, inputName: string) =>
  async (argsValue: Payload): Promise<{ error?: string } & Issue> => {
    const variableKey = 'input'
    const mutationInCapitalized = capitalizeFirstLetter(mutationName)
    const fnName = mutationName
    const variableFormat = inputName || `${mutationInCapitalized}Input!`
    const fields = { issue: getIssuesQueryFields() }
    const variables = { [variableKey]: argsValue }
    const query = getMutationQuery({
      fields: { ...MUTATION_RESPONSE_ERRORS_FIELDS, ...fields },
      variableKey,
      mutationName,
      variableFormat,
    })

    const response = await GraphqlApi.fetch({
      query,
      queryDomain,
      variables,
      fnName,
    })

    const data = _.get(response, [fnName]) || {}
    const { issue, errors } = data || {}
    const error = getMutationErrorMessage(errors)

    return { ...issue, error: response.error || error }
  }

const getBulkUpdateIssuesMutationQuery = (variables: Payload) => {
  return getGraphqlQuery({
    mutation: {
      __name: 'BulkUpdateIssues',
      bulkUpdateIssues: {
        ...getArgs(variables),
        foundIssuesCount: true,
        ...MUTATION_RESPONSE_ERRORS_FIELDS,
      },
    },
  })
}

export const bulkUpdateIssues = async ({
  variables,
}: {
  variables: Payload
}): Promise<{ error?: string } & { foundIssuesCount?: number }> => {
  const fnName = ISSUE_ACTION_TYPES.bulkUpdateIssues
  const query = getBulkUpdateIssuesMutationQuery(variables)
  const response = await GraphqlApi.fetch({
    query,
    variables,
    queryDomain,
    fnName,
  })
  const result = _.get(response, [fnName]) || {}
  const { errors } = result || {}
  const error = getMutationErrorMessage(errors)

  return { ...result, error: response.error || error }
}

const mutateIssue = (props: Omit<MutateEntity, 'queryDomain'>) =>
  mutateEntity<Issue>({
    queryDomain,
    responseFields: {
      [domain]: getIssuesQueryFields(),
    },
    identifier: ISSUE_IDENTIFIER,
    responsePath: [domain],
    ...props,
  })

export const updateIssueStateData = mutateIssue({
  fnName: 'updateIssueStateData',
  variableFormat: 'UpdateIssueStateDataInput!',
})

export const triggerIssueTask = mutateIssue({
  fnName: 'triggerIssueTask',
  variableFormat: 'TriggerIssueTaskInput!',
  withIdentifier: false,
  ignoreError: true,
})

export const triggerIssueMultitask = mutateIssue({
  fnName: 'triggerIssueMultitask',
  variableFormat: 'TriggerIssueMultitaskInput!',
  withIdentifier: false,
  ignoreError: true,
})

export const deleteIssue = mutateIssue({
  fnName: 'deleteIssue',
  variableFormat: 'DeleteIssueInput!',
  withIdentifier: false,
  ignoreError: true,
})
