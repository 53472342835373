import { useMemo } from 'react'
import _ from 'lodash'

import { IS_FAVORITE_KEY } from 'constants/common'

/** Enhances a list with the "isFavorite" field */
const useIsFavoriteField = <T>(
  list: (T & { id: string })[],
  favoriteIds: string[]
): (T & { isFavorite: boolean })[] =>
  useMemo(
    () =>
      list.map(listItem => ({
        ...listItem,
        // Adding an 'artificial' field based on user.preferences
        [IS_FAVORITE_KEY]: _.includes(favoriteIds, listItem.id),
      })),
    [list, favoriteIds]
  )

export default useIsFavoriteField
