// libraries
import { ReactElement } from 'react'

// contexts
import { useMapStateValue } from 'contexts'

// components
import { MapStylePicker } from 'components/common/'
import { MapStylePickerProps } from 'types/map'

const MapContextMapStylePicker = ({
  isGrid = true,
  size,
  expandable = false,
}: MapStylePickerProps): ReactElement => {
  const {
    map: { style },
    updateMapConfigs,
    theme,
  } = useMapStateValue()

  return (
    <MapStylePicker
      style={style}
      onChange={val => updateMapConfigs({ style: val })}
      isGrid={isGrid}
      size={size}
      expandable={expandable}
      mapTheme={theme}
    />
  )
}

export default MapContextMapStylePicker
