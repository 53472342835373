import { useState, useEffect } from 'react'
import { useInterval, useNetworkState } from 'react-use'
import { throttledWrapper } from 'helpers/utils'
import { getMapRefreshRate } from 'helpers/map'
import isEqual from 'fast-deep-equal'

/**
 * Responsible for refreshing map based on the specified interval, mainly
 * designed for displaying live data
 */
const useMapPeriodicallyRefresh = ({
  layers,
  catalog,
  updateLayersData,
  updateOverlays,
  layerSpecParamsHash,
}) => {
  const { online } = useNetworkState()
  /**
   * Map refresh interval config
   */
  const [timerDelay, setTimerDelay] = useState(null)

  /**
   * Repeatedly display the latest data of each layer on the map after timeout.
   * The timeout is either minimum value among all valid refresh rates from
   * the metadata or the DEFAULT_LIVE_DATASET_REFRESH_RATE
   */
  useInterval(() => {
    if (online) {
      // only update data for layers with the live dataset
      updateLayersData({ updateLiveDatasetOnly: true, useCachedData: false })
      throttledWrapper(updateOverlays)
    }
  }, timerDelay)

  /**
   * Layers refresh rate change handler
   */
  useEffect(() => {
    const mapRefreshRate = getMapRefreshRate(layers, catalog)
    if (mapRefreshRate) {
      if (!isEqual(mapRefreshRate, timerDelay)) {
        setTimerDelay(mapRefreshRate)
      }
    } else {
      setTimerDelay(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalog, layerSpecParamsHash])

  return { timerDelay, setTimerDelay }
}

export default useMapPeriodicallyRefresh
