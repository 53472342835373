import { useMemo } from 'react'
import { useToggle } from 'react-use'

const useNewDetection = ({ loading }: { loading?: boolean }) => {
  const [showCreateEntityModal, toggleCreateEntityModal] = useToggle(false)

  const newDetectionButtonActions = useMemo(
    () =>
      loading
        ? []
        : [
            {
              label: 'New',
              value: 'new-detection',
              icon: 'AiOutlinePlus',
              iconSize: 20,
              onClick: () => {
                toggleCreateEntityModal(true)
              },
            },
          ],
    [loading, toggleCreateEntityModal]
  )

  return {
    showCreateEntityModal,
    toggleCreateEntityModal,
    newDetectionButtonActions,
  }
}

export default useNewDetection
