import keymirror from 'keymirror'
import _ from 'lodash'
import { LAYER_TYPES } from 'constants/map'
import { WIDGET_TYPES } from './widget'

export const LAYER_PROFILE_TYPES = keymirror({
  simple: null,
  aggregated: null,
  issue: null,
  journey: null,
  asset: null,
})

export const INTERACTION_TYPES = keymirror({
  hover: null,
  click: null,
})

/**
 * Default profile wights types
 */
export const PROFILE_WIDGET_TYPES = _.pick(WIDGET_TYPES, [
  WIDGET_TYPES.list,
  WIDGET_TYPES.line,
  WIDGET_TYPES.bar,
  WIDGET_TYPES.numeric,
  WIDGET_TYPES.gauge,
])

export const ENABLE_PROFILE_LAYER_TYPES = [
  LAYER_TYPES.point,
  LAYER_TYPES.icon,
  LAYER_TYPES.upPolygon,
  LAYER_TYPES.upGeojson,
  LAYER_TYPES.arc,
]

/**
 * Default profile popup modal offset
 */
export const PROFILE_POPUP_OFFSET_X = 20
export const PROFILE_POPUP_OFFSET_Y = 20

export const DEFAULT_PROFILE_PROPERTIES_SETTING = {
  isVisible: true,
  widgetType: PROFILE_WIDGET_TYPES.list,
}
