import { useMemo } from 'react'
import _ from 'lodash'

import type { Filters, FilterSpec } from 'types/filter'

import { useTimezone } from 'hooks'

import { prepareFiltersForDetectionsQuery } from 'app/MissionControlMethaneSolution/helpers/detection'

/** Prepares filters for a query, eg replaces some values with enums, etc  */
const useDetectionQueryFilters = ({
  filters,
  customFiltersSpecs,
  searchQuery,
  selectedSearchFields,
}: {
  filters?: Filters
  customFiltersSpecs?: FilterSpec[]
  searchQuery?: string
  selectedSearchFields?: string[]
}): Filters => {
  const { timezone } = useTimezone()

  const preparedFilters = useMemo(
    () =>
      prepareFiltersForDetectionsQuery({
        filters: _.pick(filters, _.map(customFiltersSpecs, 'key')),
        customFiltersSpecs,
        timezone,
        searchQuery,
        selectedSearchFields,
      }),
    [filters, customFiltersSpecs, timezone, searchQuery, selectedSearchFields]
  )

  return preparedFilters
}

export default useDetectionQueryFilters
