import { useCallback } from 'react'
import _ from 'lodash'
import { useSetRecoilState } from 'recoil'
import { assetProfileWidgetsConfigsState } from 'recoilStore/assetsStore'
import { updateListItem } from 'helpers/utils'

import { ASSET_PROFILE_MEDIA_TYPES } from 'constants/assets'

import type { Payload } from 'types/common'

const useAssetWidgetConfigs = ({
  id,
}: {
  id: string
}): {
  updateAssetWidgetConfigs: (payload: Payload, isDeepMerge?: boolean) => void
  updateWidgetConfigsSettings: (
    settings: Payload,
    isDeepMerge?: boolean
  ) => void
} => {
  const setAssetProfileViewLayoutWidgetConfigs = useSetRecoilState(
    assetProfileWidgetsConfigsState
  )

  const mediaType = ASSET_PROFILE_MEDIA_TYPES.WEB

  const updateAssetWidgetConfigs = useCallback(
    (payload, isDeepMerge = false) => {
      setAssetProfileViewLayoutWidgetConfigs(oldViewLayoutWidgetConfigs => {
        const isSet = false
        const oldWidgetsList = _.get(
          oldViewLayoutWidgetConfigs[mediaType],
          'widgets'
        )
        const widgets = updateListItem(
          id,
          payload,
          oldWidgetsList,
          isSet,
          isDeepMerge
        )
        return {
          ...oldViewLayoutWidgetConfigs,
          [mediaType]: { ...oldViewLayoutWidgetConfigs[mediaType], widgets },
        }
      })
    },
    [id, mediaType, setAssetProfileViewLayoutWidgetConfigs]
  )

  const updateWidgetConfigsSettings = useCallback(
    (settings, isDeepMerge = true) =>
      updateAssetWidgetConfigs({ settings }, isDeepMerge),
    [updateAssetWidgetConfigs]
  )

  return { updateAssetWidgetConfigs, updateWidgetConfigsSettings }
}

export default useAssetWidgetConfigs
