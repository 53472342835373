import { ReactElement, useMemo } from 'react'
import _ from 'lodash'

// components
import { MultiSelect, TitleWithTooltip } from 'components/common'

// utils
import { useBranding } from 'hooks'
import { useAssetWidgetConfigs } from 'components/assets/assetsProfile/hooks'

import type { Widget } from 'types/asset'

const AssetBrandingImageWidgetPanel = ({
  widget,
}: {
  widget: Widget
}): ReactElement => {
  const { logo } = useBranding()

  const { updateWidgetConfigsSettings } = useAssetWidgetConfigs(widget)

  const {
    settings: { imageSrc },
  } = widget

  const brandingImageOptions = useMemo(() => {
    const { fullLogo, logoIconOnly } = logo
    const logoVariations: Record<string, string> = {
      ...fullLogo,
      ...logoIconOnly,
    }

    return Object.keys(logoVariations).reduce((acc, logoKey) => {
      const logoPath = logoVariations[logoKey]
      if (!logoPath) return acc

      return [
        ...acc,
        {
          label: _.startCase(logoKey),
          value: logoVariations[logoKey],
        },
      ]
    }, [] as { label: string; value: string }[])
  }, [logo])

  return (
    <div>
      <TitleWithTooltip className='mb-2' title='Select an image' />
      <MultiSelect
        value={imageSrc}
        options={brandingImageOptions}
        onChange={(val?: string) => {
          updateWidgetConfigsSettings({ imageSrc: val })
        }}
        useOptionValueOnly
        withBorder
        isMulti={false}
        isClearable={false}
      />
    </div>
  )
}

export default AssetBrandingImageWidgetPanel
