/** Replaces <span data-type="mention">propName</span> with {properties.propName} */
const replaceProperties = (el: HTMLElement, propPrefix = 'properties.') => {
  if (el.getAttribute && el.getAttribute('data-type') === 'mention') {
    const textNode = document.createTextNode(
      `{${propPrefix}${el.getAttribute('data-id')}}`
    )
    el.replaceWith(textNode)
  }
  const children: HTMLElement[] = (
    Array.from(el.childNodes || []) as HTMLElement[]
  )
    .map(element => replaceProperties(element, propPrefix))
    .flat()

  return children
}

/**
 * Replaces <span> of mention type with {properties.propName}
 * so backend can recognize it as property
 */
const prepareHtmlForSave = (html: string, propPrefix?: string): string => {
  const parsed = new DOMParser().parseFromString(html, 'text/html')
  replaceProperties(parsed.body, propPrefix)
  return parsed.body.innerHTML
}

export default prepareHtmlForSave
