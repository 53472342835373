import { ReactElement, useMemo, useCallback } from 'react'
import { WidgetProps } from '@rjsf/utils'
import _ from 'lodash'

// utils
import { transformTimeValue } from 'helpers/formBuilder'

// components
import { TimePicker } from 'components/common/DateTime'

const TimePickerWidgetWrapper = ({
  id,
  value,
  disabled,
  readonly,
  isLarge,
  onBlur,
  onChange,
}: Pick<
  WidgetProps,
  'id' | 'value' | 'disabled' | 'readonly' | 'onBlur' | 'onChange'
> & { isLarge?: boolean }): ReactElement => {
  const handleBlur = useCallback(
    (newValue?: string) => onBlur(id, newValue),
    [id, onBlur]
  )

  const handleChange = useCallback(
    (v?: string) => {
      onChange(transformTimeValue(v))
    },
    [onChange]
  )

  const timeValue = useMemo(() => {
    if (_.isUndefined(value)) return ''
    // Transform to a format which is acceptable by TimePicker (without seconds)
    return _.isString(value) && value.length === 8 ? value.slice(0, -3) : value
  }, [value])

  return (
    <TimePicker
      utcTime={timeValue}
      onChange={handleChange}
      onBlur={handleBlur}
      addonIcon='AiOutlineClockCircle'
      defaultToCurrent={false}
      isDisabled={disabled || readonly}
      className={isLarge ? 'isLargeWidget' : 'isRegularWidget'}
      inline
      isClearable
    />
  )
}

export default TimePickerWidgetWrapper
