import _ from 'lodash'
import { getColourClassification, getPropertyKeyByName } from 'helpers/map'
import { isSimpleColourType } from 'helpers/colour'
import { getCrossfilterCategoryDimension } from 'helpers/filter'

// constants
import { CLUSTERING_LAYER_SIZE_SCALE } from 'constants/map'
import { DEFAULT_PRIMARY_COLOUR_ARRAY } from 'constants/common'

// components
import ScatterplotClusterLayer from 'components/map/layers/deckLayers/pointLayer/scatterplotClusterLayer'

export const getColourState = (
  data,
  colour,
  colourType,
  colourClasses,
  colourProperty,
  range
) => {
  const { key, type } = colourProperty || {}
  const classification = getColourClassification(
    data,
    colour,
    { key: getPropertyKeyByName(key), type },
    colourClasses,
    range
  )

  const crossFilterDimension = getCrossfilterCategoryDimension(classification)

  return {
    classification,
    crossFilterDimension,
    isSimpleColour: isSimpleColourType(colourType),
  }
}

export const getClusteringLayer = (
  props,
  identityProperty,
  profileTitle,
  isSimpleFillColour,
  fillColour
) =>
  new ScatterplotClusterLayer({
    ..._.omit(props, ['extensions']),
    sizeScale: CLUSTERING_LAYER_SIZE_SCALE,
    identityProperty,
    profileTitle,
    fillColour: isSimpleFillColour ? fillColour : DEFAULT_PRIMARY_COLOUR_ARRAY,
  })
