import _ from 'lodash'

// constants
import { LAYER_TYPES } from 'constants/map'

// utils
import { getClusteringLayer } from 'components/map/layers/deckLayers/utils'

// components
import LabeledGeojsonIconLayer from './labeledGeojsonIconLayer'
import Layer from '../baseLayer'

export const iconVisConfigs = {
  radius: 'radius',
  opacity: 'opacity',
  iconPosition: 'iconPosition',
  bgIconPosition: 'bgIconPosition',
  bgColour: 'bgColour',
  headingProperty: 'headingProperty',
  propertyLabels: 'propertyLabels',
  enableClustering: 'enableClustering',
}

export default class IconLayer extends Layer {
  constructor(props) {
    super(props)

    this.registerVisConfig(iconVisConfigs, props.style[this.type])
  }

  get type() {
    return LAYER_TYPES.icon
  }

  get noneLayerDataAffectingProps() {
    return [...super.noneLayerDataAffectingProps, 'radius']
  }

  getIconMapping = (x = 0, y = 0) => {
    return {
      marker: {
        x,
        y,
        width: 128,
        height: 128,
        mask: true,
      },
    }
  }

  getIconColor = fillColour => d => {
    return d?.fillColour || this.getSourceFeature(d)?.fillColour || fillColour
  }

  getIconProps = ({
    bgIconPosition,
    iconPosition,
    headingProperty,
    fillColour,
    bgColour,
  }) => {
    return _.isEmpty(bgIconPosition)
      ? {
          iconMapping: this.getIconMapping(iconPosition.x, iconPosition.y),
          bgMapping: headingProperty
            ? this.getIconMapping(0, 0)
            : this.getIconMapping(144, 0),
          getIconColor: this.getIconColor(fillColour),
          getBgColor: bgColour,
        }
      : {
          // issue map is only use bgIcon as icon
          bgMapping: this.getIconMapping(bgIconPosition.x, bgIconPosition.y),
          getBgColor: this.getIconColor(fillColour),
        }
  }

  renderLayer = ({
    layerData,
    profileHandler,
    highlightedObjectIndex,
    selectedDateTimeRange,
    timezone,
    currentZoom,
  }) => {
    const {
      visConfig: {
        opacity,
        iconPosition,
        bgIconPosition,
        radius,
        bgColour,
        headingProperty,
        propertyLabels,
        enableClustering,
      },
      fillColour,
    } = this.config

    const basicProps = this.getLayerBasicProps({ layerData })

    const stylingProps = {
      ...this.getIconProps({
        bgIconPosition,
        iconPosition,
        headingProperty,
        bgColour,
        fillColour,
      }),
      opacity: this.getOpacity(opacity),
      iconAtlas: '/assets/icons/sprite.svg',
      getIconSize: radius,
      autoHighlight: true,
      highlightedObjectIndex,
    }

    const profileProps = this.getProfileConfig(profileHandler)

    const dataAccessors = {
      getIcon: () => 'marker',
      getAngle: d => {
        const angle =
          this.getPropertyValueByName(d, headingProperty) ||
          this.getPropertyValueByName(this.getSourceFeature(d), headingProperty)
        return -angle || 0
      },
      updateTriggers: {
        getAngle: [headingProperty],
        getIcon: [iconPosition, headingProperty],
        getIconColor: [fillColour],
      },
    }

    const firstDataTime = this.getTimeFromFirstData(layerData)
    const props = {
      ...basicProps,
      ...stylingProps,
      ...dataAccessors,
      ...profileProps,
      ...this.getTimeFilterProps(selectedDateTimeRange, firstDataTime),
      propertyLabels,
      timezone,
      currentZoom,
    }

    return [
      enableClustering
        ? getClusteringLayer(
            props,
            this.identityProperty,
            this.profileTitle,
            true,
            fillColour
          )
        : new LabeledGeojsonIconLayer(props),
    ]
  }
}
