import { ReactElement } from 'react'
import { ColumnBodyOptions } from 'primereact/column'
import _ from 'lodash'

import scss from './index.module.scss'

const BoldIfGroupHeader = (
  rowData: unknown,
  { field, body }: ColumnBodyOptions
): ReactElement => {
  const isGroupHeader = !!body
  const text = _.get(rowData, field)
  return isGroupHeader ? (
    <div className={scss.groupHeaderText}>{text}</div>
  ) : (
    text
  )
}

export default BoldIfGroupHeader
