// @flow

// libraries
import React, { useState } from 'react'
import { useToggle, useInterval, useUpdateEffect } from 'react-use'
import { MdTimer } from 'react-icons/md'
import isEqual from 'fast-deep-equal'

import { Slider } from 'components/common'
// constants
import { DEFAULT_REPLAY_SPEED } from 'constants/filter'
import { INTERVAL_TIME } from 'components/common/Timeline/HistoricalTimeline/constants'

// scss
import scss from '../../index.module.scss'

type Props = {
  isReplaying: boolean,
  handleReplay: () => void,
}

const ReplaySpeedSetting = React.memo(
  ({ isReplaying, handleReplay }: Props) => {
    const [isSpeedSliderVisible, toggleSpeedSliderVisible] = useToggle(false)
    const [replaySpeed, setReplaySpeed] = useState(
      DEFAULT_REPLAY_SPEED.defaultValue
    )
    const [timerDelay, setTimerDelay] = useState(null)

    useInterval(() => {
      handleReplay()
    }, timerDelay)

    const onChange = value => {
      setReplaySpeed(value)
      toggleSpeedSliderVisible(false)
    }

    useUpdateEffect(() => {
      const delay = isReplaying ? Math.round(INTERVAL_TIME / replaySpeed) : null
      if (isEqual(delay, timerDelay)) return
      setTimerDelay(delay)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReplaying, replaySpeed])

    return (
      <div className='d-flex align-items-center'>
        {isSpeedSliderVisible ? (
          <div style={{ minWidth: '120px' }}>
            <Slider
              range={[DEFAULT_REPLAY_SPEED.min, DEFAULT_REPLAY_SPEED.max]}
              value={replaySpeed}
              step={DEFAULT_REPLAY_SPEED.step}
              onChange={onChange}
            />
          </div>
        ) : (
          <button
            className={scss.svgButton}
            onClick={() => toggleSpeedSliderVisible(true)}
            type='button'
          >
            <MdTimer />
            {replaySpeed}
          </button>
        )}
      </div>
    )
  }
)

export default ReplaySpeedSetting
