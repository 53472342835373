// libraries
import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import _ from 'lodash'

// constants
import {
  DEFAULT_HIDE_VALUES_OUTSIDE_OF_VALUE_RANGE,
  DEFAULT_REALTIME_RANGE,
  WIDGET_TYPES,
  DEFAULT_WIDGET_COLOUR,
  LINE_CHART_TYPES,
} from 'constants/widget'
import { PROPERTY_VARIABLE_TYPES } from 'constants/filter'
import { DEFAULT_AGGREGATION_KEY_TYPE } from 'constants/aggregation'
import { INTERVAL_UNIT_OPTIONS, DATE_UNIT_TYPES } from 'constants/datetime'

// utils
import { switchcase, isRangeValid } from 'helpers/utils'
import { validNumericProperties } from 'helpers/filter'
import { useUpdateWidgetSettings } from 'hooks'

// components
import {
  BladeTabHeader,
  PropertyPicker,
  MultiSelect,
  DataAggregation,
  MinMaxRange,
  TitleWithTooltip,
  HideValueToggle,
} from 'components/common'
import ColourSetting from 'components/map/layers/widgets/common/ColourSetting'
import TimeRangeSetting from 'components/map/layers/widgets/common/TimeRangeSetting'

const mapTimeRangeSettingKey = key =>
  switchcase({
    intervalRange: 'xAxisIntervalRange',
    intervalUnit: 'xAxisIntervalUnit',
    enabled: 'isXAxisTimeRangeEnabled',
  })('')(key)

const widgetType = WIDGET_TYPES.line

export const LINE_CHART_TABS = [
  LINE_CHART_TYPES.features,
  LINE_CHART_TYPES.group,
]

const getMappedPayload = payload =>
  _.mapKeys(payload, (_value, key) => mapTimeRangeSettingKey(key))

const LineFeaturesOptionsTab = ({
  options,
  propertyOptions,
  updateSettings,
  isLayerDatasetLive,
}) => {
  const lineType = LINE_CHART_TYPES.features
  const {
    yAxisPropertyName,
    yAxisRange = [],
    colour = DEFAULT_WIDGET_COLOUR,
    isSingleColour = true,
    isXAxisTimeRangeEnabled = false,
    xAxisIntervalRange = DEFAULT_REALTIME_RANGE,
    xAxisIntervalUnit = DATE_UNIT_TYPES.minutes,
    hideValuesOutsideOfValueRange = DEFAULT_HIDE_VALUES_OUTSIDE_OF_VALUE_RANGE,
  } = options

  const updateLineFeaturesOptions = useCallback(
    payload => {
      updateSettings({
        [lineType]: { ...options, ...payload },
      })
    },
    [lineType, updateSettings, options]
  )

  const onLineFeaturesTimeRangeSettingChange = useCallback(
    payload => {
      const mappedPayload = getMappedPayload(payload)
      updateLineFeaturesOptions(mappedPayload)
    },
    [updateLineFeaturesOptions]
  )

  return (
    <div className='groupOption'>
      <div className='groupOptionContent'>
        <TitleWithTooltip title='Y-Axis Value' />
        <TitleWithTooltip title='Property' tooltip='The value to plot' />
        <PropertyPicker
          property={yAxisPropertyName}
          onChange={option =>
            updateLineFeaturesOptions({ yAxisPropertyName: option.value })
          }
          propertyTypes={[PROPERTY_VARIABLE_TYPES.number]}
          propertyOptions={propertyOptions}
          isMulti={false}
          isClearable={false}
        />
        <MinMaxRange
          className='mt-1'
          minValue={yAxisRange[0]}
          maxValue={yAxisRange[1]}
          isClearable
          onChange={val => updateLineFeaturesOptions({ yAxisRange: val })}
        />
        <HideValueToggle
          isValid={isRangeValid(yAxisRange)}
          hideValue={hideValuesOutsideOfValueRange}
          onChange={updateLineFeaturesOptions}
        />
      </div>
      {isLayerDatasetLive && (
        <TimeRangeSetting
          intervalRange={xAxisIntervalRange}
          intervalUnit={xAxisIntervalUnit}
          enabled={isXAxisTimeRangeEnabled}
          onChange={onLineFeaturesTimeRangeSettingChange}
        />
      )}
      <ColourSetting
        isSingleColour={isSingleColour}
        colour={colour}
        updateSettings={updateLineFeaturesOptions}
      />
    </div>
  )
}

LineFeaturesOptionsTab.propTypes = {
  options: PropTypes.shape({
    yAxisPropertyName: PropTypes.string,
    yAxisRange: PropTypes.arrayOf(PropTypes.number),
    colour: PropTypes.arrayOf(PropTypes.number),
    isSingleColour: PropTypes.bool,
    isXAxisTimeRangeEnabled: PropTypes.bool,
    xAxisIntervalRange: PropTypes.number,
    xAxisIntervalUnit: PropTypes.string,
    hideValuesOutsideOfValueRange: PropTypes.bool,
  }).isRequired,
  propertyOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateSettings: PropTypes.func.isRequired,
  isLayerDatasetLive: PropTypes.bool.isRequired,
}

const LineGroupOptionsTab = ({
  options,
  propertyOptions,
  updateSettings,
  isLayerDatasetLive,
}) => {
  const lineType = LINE_CHART_TYPES.group
  const {
    aggregation = DEFAULT_AGGREGATION_KEY_TYPE,
    valueRange = [],
    intervalUnit = DATE_UNIT_TYPES.hours,
    colour = DEFAULT_WIDGET_COLOUR,
    isSingleColour = true,
    isXAxisTimeRangeEnabled = false,
    xAxisIntervalRange = DEFAULT_REALTIME_RANGE,
    xAxisIntervalUnit = DATE_UNIT_TYPES.minutes,
    hideValuesOutsideOfValueRange = DEFAULT_HIDE_VALUES_OUTSIDE_OF_VALUE_RANGE,
  } = options

  const aggregationConfigs = {
    aggregation,
    valueRange,
  }

  const updateLineGroupOptions = useCallback(
    payload => {
      updateSettings({
        [lineType]: { ...options, ...payload },
      })
    },
    [lineType, updateSettings, options]
  )

  const onLineGroupTimeRangeSettingChange = useCallback(
    payload => {
      const mappedPayload = getMappedPayload(payload)
      updateLineGroupOptions(mappedPayload)
    },
    [updateLineGroupOptions]
  )

  return (
    <div className='groupOption mt-0'>
      <div className='groupOptionContent'>
        <TitleWithTooltip title='Y-Axis Value' />
        <TitleWithTooltip title='Period' tooltip='Time bucket' />
        <MultiSelect
          value={intervalUnit}
          options={_.reject(INTERVAL_UNIT_OPTIONS, [
            'value',
            DATE_UNIT_TYPES.seconds,
          ])}
          onChange={val => updateLineGroupOptions({ intervalUnit: val })}
          isMulti={false}
          isClearable={false}
          useOptionValueOnly
        />
        <DataAggregation
          className='mt-1'
          tooltip='Aggregation'
          propertyOptions={propertyOptions}
          aggregationConfigs={aggregationConfigs}
          onAggregationChangeHandler={(property, val) =>
            updateLineGroupOptions({ [property]: val })
          }
        />
        <HideValueToggle
          isValid={isRangeValid(valueRange)}
          hideValue={hideValuesOutsideOfValueRange}
          onChange={updateLineGroupOptions}
        />
      </div>
      {isLayerDatasetLive && (
        <TimeRangeSetting
          intervalRange={xAxisIntervalRange}
          intervalUnit={xAxisIntervalUnit}
          enabled={isXAxisTimeRangeEnabled}
          onChange={onLineGroupTimeRangeSettingChange}
        />
      )}
      <ColourSetting
        isSingleColour={isSingleColour}
        colour={colour}
        updateSettings={updateLineGroupOptions}
      />
    </div>
  )
}

LineGroupOptionsTab.propTypes = {
  options: PropTypes.shape({
    aggregation: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    yAxisPropertyName: PropTypes.string,
    valueRange: PropTypes.arrayOf(PropTypes.number),
    colour: PropTypes.arrayOf(PropTypes.number),
    isSingleColour: PropTypes.bool,
    isXAxisTimeRangeEnabled: PropTypes.bool,
    xAxisIntervalRange: PropTypes.number,
    xAxisIntervalUnit: PropTypes.string,
    intervalUnit: PropTypes.string,
    hideValuesOutsideOfValueRange: PropTypes.bool,
  }).isRequired,
  propertyOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateSettings: PropTypes.func.isRequired,
  isLayerDatasetLive: PropTypes.bool.isRequired,
}

export const LineWidgetConfig = ({
  settings,
  selectedIndex,
  onSelect,
  propertyOptions,
  isFeaturesDisabled,
  onChange,
  isLayerDatasetLive,
}) => {
  const {
    [LINE_CHART_TYPES.features]: featuresOptions = {},
    [LINE_CHART_TYPES.group]: groupOptions = {},
  } = settings

  const lineWidgetsTabs = useMemo(
    () => [
      {
        label: LINE_CHART_TYPES.features,
        isDisabled: isFeaturesDisabled,
      },
      {
        label: LINE_CHART_TYPES.group,
        isDisabled: false,
      },
    ],
    [isFeaturesDisabled]
  )

  return (
    <Tabs selectedIndex={selectedIndex} onSelect={onSelect}>
      <TabList>
        {_.map(lineWidgetsTabs, ({ label, isDisabled }, index) => (
          <Tab key={label} disabled={isDisabled}>
            <BladeTabHeader
              label={label}
              isDisabled={isDisabled}
              isActive={index === selectedIndex}
            />
          </Tab>
        ))}
      </TabList>
      <TabPanel>
        <p className='tabDescription'>
          Each feature will represent a line where data points are the
          observations
        </p>
        <LineFeaturesOptionsTab
          options={featuresOptions}
          propertyOptions={propertyOptions}
          updateSettings={onChange}
          isLayerDatasetLive={isLayerDatasetLive}
        />
      </TabPanel>
      <TabPanel>
        <p className='tabDescription'>
          All the observations are being grouped by time period and aggregated
          by user selected aggregation.
        </p>
        <LineGroupOptionsTab
          options={groupOptions}
          propertyOptions={propertyOptions}
          updateSettings={onChange}
          isLayerDatasetLive={isLayerDatasetLive}
        />
      </TabPanel>
    </Tabs>
  )
}

LineWidgetConfig.propTypes = {
  settings: PropTypes.shape({}).isRequired,
  selectedIndex: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  propertyOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isFeaturesDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  isLayerDatasetLive: PropTypes.bool.isRequired,
}

const LineWidgetTab = ({
  settings,
  propertyOptions,
  updateWidgetSettings,
  isLayerDatasetLive,
}) => {
  const currentSettings = settings[widgetType] || {}

  const isFeaturesDisabled = useMemo(
    () => _.isEmpty(validNumericProperties(propertyOptions)),
    [propertyOptions]
  )

  const {
    type = isFeaturesDisabled
      ? LINE_CHART_TYPES.group
      : LINE_CHART_TYPES.features,
  } = currentSettings

  const { selectedIndex, onSelect, updateWidget } = useUpdateWidgetSettings({
    widgetType,
    settings,
    type,
    updateWidgetSettings,
    tabs: LINE_CHART_TABS,
  })

  return (
    <>
      <h6 className='tabTitle d-flex justify-content-between'>
        <div>Line Chart (Time-series)</div>
      </h6>
      <p className='tabDescription'>
        Select a data field to include in your time series chart (X-Axis will be
        time in default)
      </p>
      <LineWidgetConfig
        settings={currentSettings}
        selectedIndex={selectedIndex}
        onSelect={onSelect}
        propertyOptions={propertyOptions}
        isFeaturesDisabled={isFeaturesDisabled}
        onChange={updateWidget}
        isLayerDatasetLive={isLayerDatasetLive}
      />
    </>
  )
}

LineWidgetTab.propTypes = {
  settings: PropTypes.shape({}),
  propertyOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateWidgetSettings: PropTypes.func.isRequired,
  isLayerDatasetLive: PropTypes.bool,
}

LineWidgetTab.defaultProps = {
  settings: {},
  isLayerDatasetLive: false,
}

export default LineWidgetTab
