// libraries
import { ReactElement, useMemo } from 'react'

// constants
import { TIME_RANGE_MODES } from 'constants/filter'
import { THEMES } from 'constants/colour'

// utils
import { displayTime, switchcaseF } from 'helpers/utils'
import { Timezone } from 'types/datetime'
import { ThemeType } from 'types/common'
import { StyledInput } from './style'

type DatetimeAnchorProps = {
  value: {
    mode?: string
    value?: string
  }
  toggleActive?: () => void
  isRangeValid: boolean
  timezone?: Timezone
  theme?: ThemeType
}

const DatetimeAnchor = ({
  value,
  toggleActive,
  isRangeValid,
  timezone,
  theme,
}: DatetimeAnchorProps): ReactElement => {
  const { value: timeValue, mode } = value

  const displayValue = useMemo(
    () =>
      switchcaseF({
        [TIME_RANGE_MODES.relativeTime]: () => timeValue,
        [TIME_RANGE_MODES.absoluteTime]: () =>
          displayTime({
            datetime: timeValue as string,
            timezone,
          }),
        [TIME_RANGE_MODES.now]: () => TIME_RANGE_MODES.now,
      })(() => timeValue)(mode),
    [timezone, mode, timeValue]
  )

  const isLightTheme = useMemo(() => theme === THEMES.light, [theme])

  return (
    <StyledInput
      className='w-50'
      onClick={toggleActive}
      value={displayValue}
      readOnly
      data-testid='time-value'
      type='button'
      data-valid={isRangeValid}
      isLightTheme={isLightTheme}
      isRangeValid={isRangeValid}
    />
  )
}

export default DatetimeAnchor
