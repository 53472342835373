import { useEffect } from 'react'
import { useTimeout } from 'react-use'

const useLoading = ({
  id,
  delayMs = 0,
}: {
  id?: string
  delayMs?: number
}): { showLoader: boolean; cancelLoader: () => void } => {
  const [isReady, cancelLoader, reset] = useTimeout(delayMs)

  // https://sensorup.atlassian.net/browse/OXY-385
  useEffect(() => {
    reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return { showLoader: !!isReady(), cancelLoader }
}

export default useLoading
