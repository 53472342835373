// libraries
import {
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
  CSSProperties,
  MouseEvent,
} from 'react'
import { useClickAway, useToggle, useUpdateEffect } from 'react-use'
import _ from 'lodash'

// constants
import {
  DATE_TIME_PICKER_POPPER_MODIFIERS,
  DATE_UNIT_TYPES,
  DEFAULT_DATE_TIME_FORMAT,
  MONTH_DAY_YEAR_FORMAT,
} from 'constants/datetime'
import { DATE_TIME_COMPARISON_EDITOR_PRECISION } from 'constants/workflow'

// util
import { getSnappedDatetimeStr } from 'helpers/datetime'
import { displayTime } from 'helpers/utils'

// components
import {
  CustomHeaderWrapper,
  DateTimeInputAddons,
} from 'components/common/DateTime/common'
import useTimePicker from 'components/common/DateTime/common/useTimePicker'
import AbsoluteTimePicker from 'components/common/DateTimePicker/AbsoluteTimePicker'
import type { UtcTimeString, UtcISOString } from 'types/datetime'

import scss from './index.module.scss'
import { StyledValue } from './style'

export type DateTimeType =
  | keyof typeof DATE_TIME_COMPARISON_EDITOR_PRECISION
  | undefined

export type DateTimeValue = {
  value?: UtcISOString | null
  type?: DateTimeType
}

type DateTimePickerProps = {
  isDisabled?: boolean
  selectedTime?: UtcISOString
  timeFormat?: string
  timezone?: string
  minDate?: UtcISOString
  maxDate?: UtcISOString
  className?: string
  style?: CSSProperties
  showTimeSelect?: boolean
  isInput?: boolean
  onChange: ({ value, type }: DateTimeValue) => void
  onBlur?: (args?: DateTimeValue) => void
  defaultToCurrent?: boolean
  inputClassName?: string
  placeholder?: string
  isClearable?: boolean
  selectedTimeType: DateTimeType
  addonIcon?: string
  highlightPlaceholder?: boolean
  defaultDateSnapUnit?: string
}

const DateTimePicker = ({
  isDisabled,
  timeFormat,
  selectedTime,
  selectedTimeType,
  timezone,
  minDate,
  maxDate,
  className,
  style,
  showTimeSelect = true,
  onChange,
  onBlur,
  defaultToCurrent = true,
  inputClassName,
  addonIcon,
  placeholder = 'None',
  isClearable = true,
  highlightPlaceholder = true,
  defaultDateSnapUnit = DATE_UNIT_TYPES.days,
  ...rest
}: DateTimePickerProps): ReactElement => {
  const [isDateTimePickerVisible, toggleDateTimePickerVisible] =
    useToggle(false)

  const [selectedDateTimeValue, setSelectedDateTimeValue] =
    useState<UtcTimeString>(selectedTime)

  useUpdateEffect(() => {
    setSelectedDateTimeValue(selectedTime)
  }, [selectedTime])

  const handleBlur = useCallback(() => {
    //  When the picker is no longer visible, consider it as 'blur'
    if (!isDateTimePickerVisible) onBlur?.()
  }, [isDateTimePickerVisible, onBlur])

  useUpdateEffect(() => {
    handleBlur()
  }, [handleBlur])

  const dateTimePickerRef = useRef(null)
  const containerRef = useRef<HTMLDivElement | null>(null)

  const onDateTimeChange = useCallback((utcDateTimeString: UtcTimeString) => {
    setSelectedDateTimeValue(utcDateTimeString)
  }, [])

  const { renderTimePicker, isDate, type } = useTimePicker({
    className: scss.timePicker,
    utcDateTime: selectedDateTimeValue,
    selectedTimeType,
    onChange: onDateTimeChange,
    isDisabled,
    minDateTime: minDate,
    defaultToCurrent,
    embeddedIntoCalendar: true,
    onClear: () =>
      setSelectedDateTimeValue((oldDateTime: UtcISOString): UtcISOString => {
        return getSnappedDatetimeStr({
          baseDatetime: oldDateTime,
          unit: DATE_UNIT_TYPES.days,
          timezone,
        })
      }),
  })

  useClickAway(dateTimePickerRef, () => {
    onChange({
      value: selectedDateTimeValue,
      type,
    })
    toggleDateTimePickerVisible(false)
  })

  const onClear = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation()
      setSelectedDateTimeValue('')
      onChange({ value: null })
      containerRef.current?.focus()
    },
    [setSelectedDateTimeValue, onChange, containerRef]
  )

  const handleClickOnDateDisplay = () => {
    if (
      defaultToCurrent &&
      !selectedDateTimeValue &&
      !isDateTimePickerVisible
    ) {
      const today = getSnappedDatetimeStr({
        // Using 'unit' we can control the default date transformation
        unit: defaultDateSnapUnit,
        timezone,
      })
      setSelectedDateTimeValue(today)
    }

    toggleDateTimePickerVisible()
  }

  const displayedTime = useMemo(() => {
    return selectedDateTimeValue
      ? displayTime({
          datetime: selectedDateTimeValue,
          timezone,
          timeFormat:
            timeFormat ||
            (showTimeSelect
              ? isDate
                ? MONTH_DAY_YEAR_FORMAT
                : DEFAULT_DATE_TIME_FORMAT
              : MONTH_DAY_YEAR_FORMAT),
        })
      : placeholder
  }, [
    selectedDateTimeValue,
    timezone,
    timeFormat,
    showTimeSelect,
    isDate,
    placeholder,
  ])

  const displayClearIcon = !!selectedDateTimeValue && !isDisabled && isClearable
  const hasIcons = displayClearIcon || !!addonIcon
  const isPlaceholder = displayedTime === placeholder

  return (
    <>
      <div
        role='button'
        tabIndex={0}
        className={`d-flex align-items-center ${inputClassName} ${
          isDisabled ? scss.disabledInput : ''
        }`}
        onClick={isDisabled ? _.noop : handleClickOnDateDisplay}
        style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
        onBlur={handleBlur}
        ref={containerRef}
      >
        <StyledValue
          isPlaceholder={isPlaceholder}
          highlightPlaceholder={highlightPlaceholder}
        >
          {displayedTime}
        </StyledValue>

        {hasIcons && (
          <DateTimeInputAddons
            className='d-flex align-items-center'
            displayClearIcon={displayClearIcon}
            onClear={onClear}
            addonIcon={addonIcon}
          />
        )}
      </div>

      {isDateTimePickerVisible && !isDisabled && (
        <div ref={dateTimePickerRef}>
          <div className={`${scss.datePicker} ${className}`} style={style}>
            <AbsoluteTimePicker
              {...rest}
              renderCustomHeader={({
                date,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <CustomHeaderWrapper
                  date={date}
                  changeMonth={changeMonth}
                  decreaseMonth={decreaseMonth}
                  increaseMonth={increaseMonth}
                  prevMonthButtonDisabled={prevMonthButtonDisabled}
                  nextMonthButtonDisabled={nextMonthButtonDisabled}
                />
              )}
              selectedTime={selectedDateTimeValue}
              timezone={timezone}
              onChange={onDateTimeChange}
              popperModifiers={DATE_TIME_PICKER_POPPER_MODIFIERS}
              minDate={minDate}
              maxDate={maxDate}
              showTimeSelect={false}
            />
            {showTimeSelect && renderTimePicker()}
          </div>
        </div>
      )}
    </>
  )
}

export default DateTimePicker
