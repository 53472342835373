import { LAYER_TYPES } from 'constants/map'
import { isLayerVisible } from 'helpers/layerStyle'
import Layer from 'components/map/layers/deckLayers/baseLayer'

import type { MapLayer } from 'types/map'

import PointLayer from './pointLayer'
import IconLayer from './iconLayer'
import HexagonLayer from './hexagonLayer'
import PolygonLayer from './polygonLayer'
import UpPolygonLayer from './upPolygonLayer'
import HeatmapLayer from './heatmapLayer'
import UpGeojsonLayer from './upGeojsonLayer'
import TileLayer from './tileLayer'
import ArcLayer from './arcLayer'
import Tile3DLayer from './tile3DLayer'

export const SensorUpGlLayers = {
  PointLayer,
  IconLayer,
  ArcLayer,
  HexagonLayer,
  PolygonLayer,
  UpPolygonLayer,
  HeatmapLayer,
  UpGeojsonLayer,
  TileLayer,
  Tile3DLayer,
}

export const LayerClasses = {
  [LAYER_TYPES.point]: PointLayer,
  [LAYER_TYPES.icon]: IconLayer,
  [LAYER_TYPES.arc]: ArcLayer,
  [LAYER_TYPES.hexagon]: HexagonLayer,
  [LAYER_TYPES.polygon]: PolygonLayer,
  [LAYER_TYPES.upPolygon]: UpPolygonLayer,
  [LAYER_TYPES.heatmap]: HeatmapLayer,
  [LAYER_TYPES.upGeojson]: UpGeojsonLayer,
  [LAYER_TYPES.tile]: TileLayer,
  [LAYER_TYPES.tile3D]: Tile3DLayer,
  default: UpGeojsonLayer,
}

export const getDeckLayerClass = ({
  mapLayer,
  currentZoom,
  identityProperty,
  profileTitle,
}: {
  mapLayer: MapLayer
  currentZoom: number
  identityProperty: string
  profileTitle: string
}): typeof Layer => {
  const { type } = mapLayer
  const LayerClass = LayerClasses[type] || LayerClasses.default
  const isVisible = isLayerVisible(currentZoom)(mapLayer)
  return new LayerClass({
    ...mapLayer,
    isVisible,
    identityProperty,
    profileTitle,
  })
}
