// libraries
import React from 'react'
import PropTypes from 'prop-types'

// constants
import { PROPERTY_VARIABLE_TYPES } from 'constants/filter'

// components
import SingleValueWidgetPanel from 'components/assets/assetsProfile/widgets/common/SingleValueWidgetPanel'

const AssetGaugeWidgetPanel = ({ widget }) => {
  return (
    <SingleValueWidgetPanel
      widget={widget}
      enableAggregationRange
      propertyTypes={[PROPERTY_VARIABLE_TYPES.number]}
    />
  )
}

AssetGaugeWidgetPanel.propTypes = {
  widget: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    settings: PropTypes.shape({
      assetProfileId: PropTypes.string.isRequired,
      relatedAssetRelationshipId: PropTypes.string,
      property: PropTypes.string,
    }).isRequired,
  }).isRequired,
}

export default AssetGaugeWidgetPanel
