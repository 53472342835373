import { useState, useCallback } from 'react'
import PropTypes from 'prop-types'

const useUpdateWidgetSettings = ({
  widgetType,
  settings,
  tabs = [],
  type,
  updateWidgetSettings,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(() => tabs.indexOf(type))

  const updateWidget = useCallback(
    payload => {
      const newPayload = { ...(type && { type }), ...payload }
      const newSettings = widgetType
        ? {
            [widgetType]: {
              ...(settings[widgetType] || {}),
              ...newPayload,
            },
          }
        : newPayload
      updateWidgetSettings({
        settings: {
          ...settings,
          ...newSettings,
        },
      })
    },
    [settings, updateWidgetSettings, type, widgetType]
  )

  const onSelect = useCallback(
    index => {
      updateWidget({ type: tabs[index] })
      setSelectedIndex(index)
    },
    [updateWidget, tabs]
  )

  return { updateWidget, onSelect, selectedIndex }
}

useUpdateWidgetSettings.propTypes = {
  widgetType: PropTypes.string,
  settings: PropTypes.shape({}),
  tabs: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.string,
  updateWidgetSettings: PropTypes.func.isRequired,
}

useUpdateWidgetSettings.defaultProps = {
  widgetType: undefined,
  settings: {},
  tabs: [],
  type: undefined,
}

export default useUpdateWidgetSettings
