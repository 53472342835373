import _ from 'lodash'
import { updateListItem } from 'helpers/utils'

export const updatePopupPosition = (id, position, popups) =>
  updateListItem(id, { position }, popups)

export const removePopup = (id, popups) => _.reject(popups, ['id', id])

export const removeNonExistingLayerPopups = (layers, popups) =>
  _.isEmpty(layers)
    ? []
    : _.filter(popups, ({ id }) =>
        layers.some(layer => id.startsWith(layer.id))
      )

export const removeLayerPopups = (id, popups) =>
  _.reject(popups, p => p.id.startsWith(id))

const hidePopups = (layerId, popupsList) =>
  _.map(popupsList, p => {
    if (p.id.startsWith(layerId)) {
      p.isVisible = false
    }
    return p
  })

const generatePopup = (layerId, layerPopups, curPop) => {
  const { id, position } = curPop
  let newPopup
  const index = layerPopups.findIndex(pop => pop.id === id)
  if (index >= 0) {
    // update the existing popups in the list
    // prefer to use the previous position
    newPopup = {
      ...layerPopups[index],
      position,
    }
    // remove the pop from the layer popups list
    layerPopups.splice(index, 1)
  } else if (!id.startsWith(layerId)) {
    // add other layers' popups back to the list
    newPopup = curPop
  }
  return newPopup
}

export const generatePopups = (layerId, layerPopups, isVisible, oldPopups) => {
  let newPopups
  if (!isVisible) {
    // hide the popup instead of deleting it from the popup list
    newPopups = hidePopups(layerId, oldPopups)
    return newPopups
  }

  if (_.isEmpty(layerPopups)) {
    // remove all layer's popups from the list
    newPopups = removeLayerPopups(layerId, oldPopups)
    return newPopups
  }

  // add layer popups to the list
  // or update the existing one
  newPopups = _.reduce(
    oldPopups,
    (arr, cur) => {
      const newPopup = generatePopup(layerId, layerPopups, cur)
      // non-existing popups will be removed from the list
      if (newPopup) {
        arr.push(newPopup)
      }

      return arr
    },
    []
  )

  return [...newPopups, ...(layerPopups || [])]
}
