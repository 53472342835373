// component
import { ReactElement } from 'react'

// components
import { Avatar } from 'components/common'
import { formatPhone } from 'components/common/Form/PhoneField'

import type { User } from 'types/user'

// scss
import { BUTTON_VARIANTS } from 'components/common/Button'
import scss from './index.module.scss'
import { EditAvatarBtn } from './style'

type Props = {
  user: User
  avatarSize: string
  onClickAvatar: () => void
}

const UserInformationCard = ({
  user,
  avatarSize,
  onClickAvatar,
}: Props): ReactElement => {
  const { id, username, email, familyName, givenName, phone } = user

  return (
    <div className='d-flex flex-grow-1'>
      <div className='flex-shrink-0'>
        <Avatar user={user} key={username || id} size={avatarSize} />
        <div className={scss.bottomRight}>
          <EditAvatarBtn
            onClick={onClickAvatar}
            variant={BUTTON_VARIANTS.link}
            icon='MdModeEdit'
          />
        </div>
      </div>
      <div className='flex-grow-1 ms-3'>
        <div
          data-testid='user-name'
          data-user-id={username}
          className={`mt-0 ${scss.userName} `}
        >
          {givenName} {familyName}
        </div>
        <div className={scss.userInfo}>{email}</div>
        <div className={scss.userInfo}>{formatPhone(phone)}</div>
      </div>
    </div>
  )
}

export default UserInformationCard
