import PropTypes from 'prop-types'

export const AssetWidgetSettingsPropTypes = PropTypes.shape({
  assetProfileId: PropTypes.string.isRequired,
  relatedAssetRelationshipId: PropTypes.string,
  relatedAssetProfileId: PropTypes.string,
  properties: PropTypes.arrayOf(PropTypes.string),
})

export const AssetWidgetPropTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  assetId: PropTypes.string,
  settings: AssetWidgetSettingsPropTypes.isRequired,
}
