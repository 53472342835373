import { ReactElement, CSSProperties, MouseEvent } from 'react'

import scss from './index.module.scss'

export const Caption = ({
  title,
  subtitle,
}: {
  title: string
  subtitle?: string
}): ReactElement => {
  return (
    <div className={scss.captionContainer}>
      <div className={scss.caption}>
        <div className={scss.title}>{title}</div>
        {subtitle && <div className={scss.subtitle}>{subtitle}</div>}
      </div>
    </div>
  )
}

const Image = ({
  src,
  style,
  title,
  subtitle,
  onClick,
  index,
  className,
  containerClassName,
  // Blurs the images and displays the count on top
  remainingImagesCount,
}: {
  src: string
  width?: number
  height?: number
  backgroundColor?: string
  title?: string
  subtitle?: string
  onClick?: (e: MouseEvent) => void
  index?: number
  margin?: string
  className?: string
  containerClassName?: string
  style?: CSSProperties
  remainingImagesCount?: number
}): ReactElement => {
  return (
    <div
      className={`${scss.photo} ${containerClassName}`}
      style={style}
      {...(onClick && {
        onClick,
      })}
    >
      <img
        alt={title || index}
        className={`${scss.img} ${
          remainingImagesCount && scss.blurredImage
        } ${className}`}
        src={src}
      />
      {title && <Caption title={title} subtitle={subtitle} />}

      {remainingImagesCount && (
        <div className={scss.remainingImagesCountContainer}>
          <span>+{remainingImagesCount}</span>
        </div>
      )}
    </div>
  )
}

export default Image
