import { useMemo } from 'react'
import parse from 'wellknown'
import { feature } from '@turf/helpers'
import _ from 'lodash'

// utils
import { useFeatureFlag } from 'hooks'
import {
  isQubeData,
  isVFBEvent,
} from 'app/MissionControlMethaneSolution/helpers/emissionObservation'

// constants
import { DETECTION_TYPES_TO_OMIT_ON_MAP } from 'app/MissionControlMethaneSolution/constants/emissionObservation'
import { DETECTION_TYPES } from 'app/MissionControlMethaneSolution/constants/common'
import { FEATURES } from 'constants/settings'

// types
import type { EmissionObservationData } from 'app/MissionControlMethaneSolution/types/detection'
import type { GeojsonData } from 'types/map'
import type { UtcISOString } from 'types/datetime'

type EnhancedDetectionData = EmissionObservationData & {
  contributingEmissions?: {
    startTime: UtcISOString
    endTime: UtcISOString
    totalVolume: number
    label: string
    sourceLocation: string
  }[]
}

const useDetectionsGeoJsonFeatures = (
  emissionObservations?: EnhancedDetectionData[]
) => {
  const {
    [FEATURES.DISPLAY_VENTING_EVENT_LOCATION_ON_MAP_IN_GEMS]:
      displayVentingEventLocationOnMapInGems,
    [FEATURES.DISPLAY_QUBE_ALARM_LOCATION_ON_MAP_IN_GEMS]:
      displayQubeAlarmLocationOnMapInGems,
  } = useFeatureFlag()

  return useMemo(() => {
    return (
      emissionObservations?.reduce(
        (acc: GeojsonData[], emissionObservation) => {
          const { geoJsonFeature, detectionType, contributingEmissions } =
            emissionObservation

          if (
            isVFBEvent(emissionObservation) &&
            !displayVentingEventLocationOnMapInGems
          ) {
            return acc
          }

          if (DETECTION_TYPES_TO_OMIT_ON_MAP.includes(detectionType)) {
            return acc
          }

          if (
            detectionType === DETECTION_TYPES.DETECTION_TYPE_CMS &&
            isQubeData(emissionObservation)
          ) {
            const contributingEmissionsFeatures = _(contributingEmissions)
              .filter('sourceLocation')
              .map(emission =>
                feature(parse(emission?.sourceLocation), {
                  ...emission,
                  ..._.pick(emissionObservation, ['priority', 'detectionType']),
                  label: emission.sourceName,
                })
              )
              .value()

            if (!_.isEmpty(contributingEmissionsFeatures)) {
              acc.push(...contributingEmissionsFeatures)
            } else if (
              _.isEmpty(contributingEmissions) &&
              geoJsonFeature &&
              displayQubeAlarmLocationOnMapInGems
            ) {
              acc.push(geoJsonFeature)
            }
          } else if (geoJsonFeature) {
            acc.push(geoJsonFeature)
          }

          return acc
        },
        []
      ) ?? []
    )
  }, [
    displayQubeAlarmLocationOnMapInGems,
    displayVentingEventLocationOnMapInGems,
    emissionObservations,
  ])
}

export default useDetectionsGeoJsonFeatures
