import _ from 'lodash'
import isURL from 'validator/lib/isURL'

// constants
import { DEFAULT_IDENTITY_PROPERTY } from 'constants/common'

// utils
import { getDatasetIdentifier } from 'helpers/unipipe'
import { getQueryFields } from 'helpers/graphql'
import { removeNullValues } from 'helpers/utils'
import { reportErrors } from 'helpers/log'
import {
  getEntitiesQuery,
  getGraphql,
  getGraphqlQuery,
  listEntitiesGraphql,
} from 'services/api/utils'

import type {
  Catalog,
  UpSpecification,
  UpSpecificationParameter,
} from 'types/unipipe'

const queryDomain = 'catalogs'

const DATASET_FIELDS = {
  catalogId: true,
  dataset: true,
  displayName: true,
  baseSpecification: true,
  endpoint: true,
  catalogRoleArn: true,
  dynamicMetadata: true,
  properties: {
    name: true,
    displayName: true,
    type: true,
    format: true,
    terms: { key: true, displayName: true },
    termsCompleteness: true,
  },
  specificationParameters: {
    name: true,
    displayName: true,
    type: true,
    format: true,
    necessity: true,
    options: { key: true, displayName: true },
  },
  timeliness: true,
  hints: true,
  assetProfile: true,
  identityProperty: true,
}

const getDatasetFields = getQueryFields(DATASET_FIELDS)

const getCatalogFields = getQueryFields({
  catalogId: true,
  group: true,
  datasets: DATASET_FIELDS,
})

const getCatalogsQuery = getEntitiesQuery({
  queryDomain,
  getFieldsFn: getCatalogFields,
})

export const getSpecificationParameters = (
  specificationParameters: UpSpecificationParameter[]
): Record<string, UpSpecificationParameter> => {
  return _.reduce(
    specificationParameters,
    (acc, value) => {
      const { name } = value
      return { ...acc, [name]: removeNullValues(value) }
    },
    {}
  )
}

// example catalog item https://des.dev.sensorup.com/catalog/ct-speed-live-concrete
export const deserializeCatalog = ({
  group,
  datasets,
}: Catalog): Record<string, UpSpecification>[] => {
  return _.map(datasets, datasetItem => {
    const { catalogId, dataset, specificationParameters, endpoint } =
      datasetItem

    const isEndpointValid = endpoint && isURL(endpoint)

    if (!isEndpointValid) {
      const errorMessage = `${_.upperFirst(
        queryDomain
      )} Endpoint of the dataset[${dataset}] is not valid and the dataset will be hidden`
      reportErrors(errorMessage, { datasetItem })
    }

    const datasetIdentifier = getDatasetIdentifier(catalogId, dataset)

    return {
      [`${datasetIdentifier}`]: {
        assetProfile: undefined,
        identityProperty: DEFAULT_IDENTITY_PROPERTY,
        ...datasetItem,
        specificationParameters: getSpecificationParameters(
          specificationParameters
        ),
        group,
      },
    }
  })
}

const deserializeCatalogs = (
  catalogs: Catalog[]
): Record<string, UpSpecification> => {
  return _.assign({}, ..._.flatMap(catalogs, deserializeCatalog))
}

export const listCatalogs = listEntitiesGraphql<Catalog>({
  queryDomain,
  getQueryFn: getCatalogsQuery,
  queryDisplayName: 'GetAllCatalogs',
  postProcessFn: deserializeCatalogs,
  isSingleEntityPostProcessFn: false,
})

const DATASET_QUERY_DOMAIN = 'catalogDatasets'
const DATASET_QUERY_NAME = 'byReferences'

const getDatasetsQuery = getGraphqlQuery({
  queryDomain: DATASET_QUERY_DOMAIN,
  getFieldsFn: getDatasetFields,
  variables: {
    upSpecificationReferences: '[UpSpecificationReferenceInput!]!',
  },
  queryName: DATASET_QUERY_NAME,
})

export const getDatasetsByReferences = getGraphql<UpSpecification>({
  queryDomain: DATASET_QUERY_DOMAIN,
  getQueryFn: getDatasetsQuery,
  queryDisplayName: 'GetDatasetsByReferences',
  queryName: DATASET_QUERY_NAME,
})
