import { useMemo, ReactElement, ReactNode } from 'react'
import _ from 'lodash'

// utils
import { useBranding } from 'hooks'

// components
import { Paper } from 'components/common'

import scss from './index.module.scss'

const LoginLayout = ({ children }: { children?: ReactNode }): ReactElement => {
  const { backgroundImageUrl, logo } = useBranding()

  const { fullLogo: { fullColourLogo } = {}, loginLogo } = logo

  const logoURL = useMemo(
    () => _.get(logo, loginLogo, fullColourLogo),
    [logo, loginLogo, fullColourLogo]
  )

  return (
    <div
      className={scss.page}
      style={{ backgroundImage: `url(${backgroundImageUrl})` }}
    >
      <div className={scss.signin}>
        <Paper width='400px'>
          <div className={scss.logoContainer}>
            <img
              alt='logo'
              className={`${scss.logo} mx-auto d-block`}
              src={logoURL}
            />
          </div>
          <hr className={scss.hr} />
          <div className={scss.form}>{children}</div>
          <div className={scss.footer}>
            <a
              href='https://sensorup.com'
              target='_blank'
              rel='noreferrer'
              className={scss.poweredBy}
            >
              Powered by
              <img src='/assets/logo/sensorup-logo-name.svg' alt='SensorUp' />
            </a>
          </div>
        </Paper>
      </div>
    </div>
  )
}

export default LoginLayout
