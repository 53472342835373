import _ from 'lodash'

// types
import type { TextTemplate } from 'types/common'

// constants
import { JSONSchema7, JSONSchema7Type } from 'json-schema'
import {
  CURLY_BRACES_REGEXP,
  EDITOR_PROPERTIES_REGEXP,
  FIND_PROPERTIES_IN_TEXT_REGEX,
} from 'constants/strings'

type Property = { [key: string]: string }

export const replaceWhiteFontColorForPrintable = (
  template: TextTemplate
): string => {
  return template.replace(
    /color: (rgb\(255, 255, 255\)|#ffffff|#fff|white)/gi,
    'color: #000000'
  )
}

export const replacePropertiesWithSpaces = ({
  template,
  propertiesData,
  propertyPrefix,
}: {
  template: string
  propertiesData: { [key: string]: Property }
  propertyPrefix: string
}): {
  sanitizedTemplate: string
  tweakedPropertiesData: Record<string, unknown>
} => {
  const originalData = propertyPrefix
    ? propertiesData[propertyPrefix]
    : propertiesData

  const tweakedData: Record<string, Property> = {}
  let sanitizedTemplate = template

  const matchedStrings = template.match(FIND_PROPERTIES_IN_TEXT_REGEX) || []
  const encounteredProperties = matchedStrings.map(matchedProperty =>
    matchedProperty
      .replace(CURLY_BRACES_REGEXP, '')
      .replace(`${propertyPrefix}.`, '')
  )

  // Iterate through the encountered properties
  encounteredProperties.forEach(propertyKey => {
    // Find those with spaces inside a property key
    if (/\s/.test(propertyKey)) {
      // Transform the key
      const fixedPropertyKey = _.snakeCase(propertyKey)
      // Assign the old value to a new key
      tweakedData[fixedPropertyKey] = originalData[propertyKey] as Property
      // In a template, replace the broken key with the new one
      sanitizedTemplate = sanitizedTemplate.replaceAll(
        propertyKey,
        fixedPropertyKey
      )
    }
  })

  // Combine old properties with the new ones with 'snakeCased' keys
  const combinedData = {
    ...originalData,
    ...tweakedData,
  }

  return {
    sanitizedTemplate,
    tweakedPropertiesData: propertyPrefix
      ? { [propertyPrefix]: combinedData }
      : combinedData,
  }
}

export const getCompiledText = ({
  template,
  data,
  isPrintable,
  propertyPrefix = 'properties',
  defaultNoData = '[no data]',
  interpolate = EDITOR_PROPERTIES_REGEXP,
  displayDefaultNoData = false,
}: {
  template: TextTemplate
  data: { [key: string]: Property }
  isPrintable?: boolean
  propertyPrefix?: string
  defaultNoData?: string
  interpolate?: RegExp
  displayDefaultNoData?: boolean
}): string => {
  let newPropertiesData = data

  if (displayDefaultNoData) {
    const matchedProperties = template.match(interpolate) || []

    const templatePropertyObj = matchedProperties.reduce((acc, cur) => {
      const key = cur.replace(`{${propertyPrefix}.`, '').replace('}', '')
      return { ...acc, [key]: defaultNoData }
    }, {})

    newPropertiesData = _.defaultsDeep({}, data, {
      [propertyPrefix]: templatePropertyObj,
    })
  }

  const newTemplate = isPrintable
    ? replaceWhiteFontColorForPrintable(template)
    : template

  const { sanitizedTemplate, tweakedPropertiesData } =
    replacePropertiesWithSpaces({
      template: newTemplate,
      propertiesData: newPropertiesData,
      propertyPrefix,
    })

  return _.template(sanitizedTemplate, { interpolate })(tweakedPropertiesData)
}

/** If:
 * - data comes from jsonForm
 * - it's a dropdown field
 * - and there is a schema available for that field
 * then:
 * - display the value's label instead of the raw value
 * Example: process_gas => Process Gas
 */
export const getFieldValue = ({
  termsDisplayName,
  value,
  fieldSchema,
}: {
  termsDisplayName?: string
  value?: unknown
  fieldSchema?: JSONSchema7 & { enumNames?: string[] }
}) => {
  const fieldValue = termsDisplayName ?? value
  const { enum: availableEnumValues, enumNames } = fieldSchema ?? {}

  if (!availableEnumValues || !enumNames) return fieldValue

  const fieldIndex = availableEnumValues.indexOf(fieldValue as JSONSchema7Type)
  return fieldIndex !== -1 ? enumNames[fieldIndex] : fieldValue
}
