// libraries
import { FC, ReactElement, useMemo } from 'react'
import styled from '@emotion/styled'
import { CSSTransition } from 'react-transition-group'
import { useToggle, useUpdateEffect } from 'react-use'

// components
import { IconButton } from 'components/common'

// styles
import scss from './index.module.scss'

import type {
  AccordionCollapseDivProps,
  AccordionHeaderProps,
  AccordionProps,
} from './types'

const AccordionHeader = styled.div<AccordionHeaderProps>`
  color: ${({ expanded, theme }) => (expanded ? theme.primary : 'inherit')};

  background-color: ${({ expanded, flush, theme }) =>
    expanded ? theme['primary-50'] : flush ? '#fff' : theme['primary-50']};

  &:hover {
    color: ${({ theme }) => theme.primary};
    background-color: ${({ theme }) => theme['primary-100']};
  }
`

const AccordionCollapseDiv = styled.div<AccordionCollapseDivProps>`
  border-left: ${({ addOn, theme }) =>
    addOn ? `1px solid ${theme['secondary-100']}` : 'none'};
`

const Accordion: FC<AccordionProps> = ({
  title,
  addOn,
  content,
  expanded,
  className = '',
  titleClassName = '',
  bodyClassName = '',
  collapseDivClassName = '',
  chevronIconClassName = '',
  isLoading = false,
  collapsible = true,
  status,
  flush = false,
  toggleExpand = undefined,
  testId = '',
  chevronLeft = true,
}): ReactElement => {
  const [isExpanded, toggleExpanded] = useToggle(expanded || false)

  const icon = useMemo(() => {
    if (isLoading) return 'FaSpinner'
    return isExpanded ? 'MdKeyboardArrowUp' : 'MdKeyboardArrowDown'
  }, [isExpanded, isLoading])

  useUpdateEffect(() => {
    toggleExpanded(expanded)
  }, [expanded])

  const handleToggle = () => {
    if (toggleExpand) {
      toggleExpand(!isExpanded)
    } else {
      toggleExpanded()
    }
  }

  const iconNode = collapsible ? (
    <AccordionCollapseDiv
      addOn={addOn}
      className={`${scss.collapseDiv} ${collapseDivClassName}`}
    >
      <IconButton
        icon={icon}
        size={24}
        className={`${scss.iconButton} ${chevronIconClassName}`}
      />
    </AccordionCollapseDiv>
  ) : null

  return (
    <section className={`${className || scss.container}`}>
      <AccordionHeader
        {...(collapsible && { onClick: handleToggle })}
        className={`${scss.title}  ${titleClassName} ${
          collapsible ? 'cursor-pointer' : ''
        }
          ${isExpanded ? `${scss.expanded} accordionExpanded` : ''}
        `}
        expanded={isExpanded}
        flush={flush}
        data-testid={testId}
      >
        <div className='row g-2'>
          <div className='col-md-7 col-sm-6 align-self-center d-flex align-items-center'>
            {!chevronLeft && iconNode} {title}
          </div>
          <div className='col-md-5 col-sm-6 d-flex align-items-center justify-content-end align-self-center'>
            {status && <div className='status'>{status}</div>}
            {addOn}
            {chevronLeft && iconNode}
          </div>
        </div>
      </AccordionHeader>
      {collapsible && (
        <CSSTransition
          in={isExpanded}
          mountOnEnter
          unmountOnExit
          timeout={300}
          classNames='accToggleableContent'
        >
          <div className={bodyClassName}>
            <div className={scss.body}>{content}</div>
          </div>
        </CSSTransition>
      )}
    </section>
  )
}

export default Accordion
