import moment from 'moment-timezone'
import _ from 'lodash'

// constants
import {
  DATE_UNIT_TYPES,
  DATE_HOUR_MINUTE_SECOND_FORMAT_STANDARD,
  MOMENT_LOCALE_RELATIVE_TIME,
} from 'constants/datetime'
import { TIME_RANGE_MODES } from 'constants/filter'

// utils
import {
  getFromNow,
  getPreviousUtcDate,
  getUtcDateTimeString,
} from 'helpers/datetime'
import { switchcaseF, displayTime } from 'helpers/utils'

import type {
  UtcISOString,
  DatetimePickerTimeMode,
  Timezone,
  IntervalUnit,
} from 'types/datetime'

type RelativeTimePickerTime = {
  value: number
  unit: string
  startOfUnit: IntervalUnit
}

type RelativeTimeString = string

export type DatetimePickerRelativeTime = {
  mode: typeof TIME_RANGE_MODES.relativeTime | typeof TIME_RANGE_MODES.now
  value: RelativeTimeString
}

export type DatetimePickerAbsoluteTime = {
  mode: typeof TIME_RANGE_MODES.absoluteTime
  value: UtcISOString
}

export type DatetimePickerTime =
  | DatetimePickerAbsoluteTime
  | DatetimePickerRelativeTime

export const getRelativeTimeObjFromTimeStr = (
  timeStr: UtcISOString
): RelativeTimePickerTime | null => {
  if (!timeStr || _.isObject(timeStr)) return null

  const [value, unit] = timeStr.split(' ')
  const startOfUnit = value === 'this' ? unit : null
  return _.omitBy(
    { value: Number(value === 'this' ? '0' : value), unit, startOfUnit },
    _.isNil
  ) as RelativeTimePickerTime
}

export const getRelativeTimeStrFromTimeObj = (
  timeObj: RelativeTimePickerTime
): UtcISOString => {
  if (_.isEmpty(timeObj)) return ''

  const { value, unit } = timeObj
  return `${value} ${unit}`
}

export const relativeTimeToAbsoluteTime = (
  timeObj: DatetimePickerRelativeTime,
  timezone: Timezone
): DatetimePickerAbsoluteTime => {
  const { value, unit, startOfUnit } =
    getRelativeTimeObjFromTimeStr(timeObj.value) || {}

  return {
    mode: TIME_RANGE_MODES.absoluteTime,
    value: getPreviousUtcDate({
      interval: value,
      intervalUnit: unit,
      isFullDate: true,
      timezone,
      isStart: true,
      snappedUnit: startOfUnit,
    })?.toISOString(),
  }
}

export const absoluteTimeToRelativeTime = (
  timeObj: DatetimePickerAbsoluteTime
): DatetimePickerRelativeTime => {
  moment.updateLocale('en', {
    relativeTime: MOMENT_LOCALE_RELATIVE_TIME,
  })
  return {
    mode: TIME_RANGE_MODES.relativeTime,
    value: getFromNow(timeObj.value, true),
  }
}

export const normalizeTimeToUtcStr = (
  timeObj?: DatetimePickerTime,
  timezone?: Timezone
): UtcISOString => {
  const { mode, value } = timeObj || {}
  if (!value) return ''
  return switchcaseF({
    [TIME_RANGE_MODES.relativeTime]: () => {
      const { value: relativeTimeValue, unit } =
        getRelativeTimeObjFromTimeStr(value) || {}
      return getPreviousUtcDate({
        interval: relativeTimeValue,
        intervalUnit: unit,
        isFullDate: true,
        timezone,
      })?.toISOString()
    },
    [TIME_RANGE_MODES.absoluteTime]: () => value,
    [TIME_RANGE_MODES.now]: () => getUtcDateTimeString(),
  })(() => value)(mode)
}
/**
 * get display time from the time object and timezone
 * @param {Object} timeObj  { mode, value }
 * @param {String} timezone
 *
 * @return {String} display time
 */
export const getPrettyTimeFromTimeObj = (
  timeObj: DatetimePickerAbsoluteTime,
  timezone: Timezone
): string => {
  return displayTime({
    datetime: normalizeTimeToUtcStr(timeObj, timezone),
    timezone,
    timeFormat: DATE_HOUR_MINUTE_SECOND_FORMAT_STANDARD,
  })
}

/**
 * Convert the absolute object to relative object
 * @param {Object} timeObj the original time object to be converted
 *
 * @return {Object} new relative time object
 */
export const convertTimeToRelativeTime = (
  timeObj: DatetimePickerAbsoluteTime
): DatetimePickerRelativeTime => {
  const { mode } = timeObj
  return switchcaseF({
    [TIME_RANGE_MODES.absoluteTime]: () => absoluteTimeToRelativeTime(timeObj),
    [TIME_RANGE_MODES.now]: () => ({
      value: `0 ${DATE_UNIT_TYPES.seconds}`,
      mode: TIME_RANGE_MODES.relativeTime,
    }),
  })(() => timeObj)(mode)
}

/**
 * Convert the relative object to absolute object
 * @param {Object} timeObj the original time object to be converted
 *
 * @return {Object} new absolute time object
 */
export const convertTimeToAbsoluteTime = (
  timeObj: DatetimePickerRelativeTime,
  timezone: Timezone
): DatetimePickerAbsoluteTime => {
  const { mode } = timeObj
  return switchcaseF({
    [TIME_RANGE_MODES.relativeTime]: () =>
      relativeTimeToAbsoluteTime(timeObj, timezone),
    [TIME_RANGE_MODES.now]: () => ({
      value: getUtcDateTimeString(),
      mode: TIME_RANGE_MODES.absoluteTime,
    }),
  })(() => timeObj)(mode)
}

/**
 * Convert the old time object to the new time object with the target mode
 * @param {Object} timeObj the original time object to be converted
 * @param {String} newMode target time mode
 *
 * @return {Object} new time object
 */
export const convertTimeToNewModeTime = (
  timeObj: DatetimePickerTime,
  newMode: DatetimePickerTimeMode,
  timezone: Timezone
): DatetimePickerTime => {
  return switchcaseF({
    [TIME_RANGE_MODES.relativeTime]: () =>
      convertTimeToRelativeTime(timeObj as DatetimePickerAbsoluteTime),
    [TIME_RANGE_MODES.absoluteTime]: () =>
      convertTimeToAbsoluteTime(
        timeObj as DatetimePickerRelativeTime,
        timezone
      ),
    [TIME_RANGE_MODES.now]: () => ({
      value: getUtcDateTimeString(),
      mode: TIME_RANGE_MODES.now,
    }),
  })(() => timeObj)(newMode)
}
