import type { DataCollectionForm } from 'types/issue'

// utils
import { MutateEntity, mutateEntity } from 'services/api/utils'
import { getJsonFormQueryFields } from './issue'

const domain = 'dataCollectionForm'
const queryDomain = 'dataCollectionForm'

const mutateIssueTaskDataCollectionForm = (
  props: Omit<MutateEntity, 'queryDomain'>
) => {
  return mutateEntity<DataCollectionForm>({
    queryDomain,
    responseFields: {
      [domain]: getJsonFormQueryFields(),
    },
    responsePath: [domain],
    ...props,
  })
}

export const createIssueTaskDataCollectionForm =
  mutateIssueTaskDataCollectionForm({
    fnName: 'createIssueTaskDataCollectionForm',
    variableFormat: 'CreateIssueTaskDataCollectionFormInput!',
  })

export const updateIssueTaskDataCollectionForm =
  mutateIssueTaskDataCollectionForm({
    fnName: 'updateIssueTaskDataCollectionForm',
    variableFormat: 'UpdateIssueTaskDataCollectionFormInput!',
  })

export const activateIssueTaskDataCollectionForm =
  mutateIssueTaskDataCollectionForm({
    fnName: 'activateIssueTaskDataCollectionForm',
    argsKey: null,
    withIdentifier: false,
  })

export const deactivateIssueTaskDataCollectionForm =
  mutateIssueTaskDataCollectionForm({
    fnName: 'deactivateIssueTaskDataCollectionForm',
    argsKey: null,
  })

export const deleteIssueTaskDataCollectionForm =
  mutateIssueTaskDataCollectionForm({
    fnName: 'deleteIssueTaskDataCollectionForm',
    isDelete: true,
    postProcessFn: null,
    responseFields: { [domain]: { id: true } },
  })
