import { ReactElement, useState, useCallback } from 'react'
import { CompactPicker, ColorResult } from 'react-color'

import { Tooltip } from 'components/common'

import scss from './index.module.scss'

const TextColourPicker = ({
  color = 'rgb(20, 20, 20)',
  onChange,
  children,
  disabled,
}: {
  color?: string
  triggerOnHover?: boolean
  onChange: (selectedColor: string) => void
  disabled?: boolean
  children: ReactElement
}): ReactElement => {
  const [displayColourPicker, setDisplayColorPicker] = useState(false)

  const handleColourChange = useCallback(
    (c: ColorResult) => {
      const { r, g, b } = c.rgb
      // Build the rgba(..., ..., ...) string
      onChange(`rgb(${[r, g, b].join(', ')})`)
      setDisplayColorPicker(false)
    },
    [onChange]
  )

  return (
    <Tooltip
      visible={!disabled && displayColourPicker}
      onVisibleChange={() => setDisplayColorPicker(!displayColourPicker)}
      trigger='click'
      placement='bottom'
      overlayClassName={`light ${scss.tooltipDropdown}`}
      overlay={
        <CompactPicker
          color={color}
          onChangeComplete={handleColourChange}
          className={scss.colorPicker}
        />
      }
      showArrow
    >
      {children}
    </Tooltip>
  )
}

export default TextColourPicker
