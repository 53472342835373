import { useCallback, ReactElement, useMemo } from 'react'
import { components, MenuProps, ActionMeta } from 'react-select'
import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'
import _ from 'lodash'

import * as Icons from 'components/icons'
import { SelectOption } from 'components/common/MultiSelect'

import scss from './index.module.scss'

const StyledMenuHeaderText = styled.span<{ isActive: boolean }>`
  color: ${({ theme, isActive }) =>
    isActive ? theme.primary : theme.secondary};
`

const SelectAllDropdownHeader = ({
  children,
  ...props
}: MenuProps<SelectOption>): ReactElement => {
  const theme = useTheme()
  const {
    options,
    selectProps: { onChange, value },
  } = props

  const isAllSelected = useMemo(
    () => _.isArray(value) && value.length === options.length,
    [value, options]
  )
  const Icon = Icons[isAllSelected ? 'MdCheckBox' : 'MdCheckBoxOutlineBlank']

  const onSelectAllClick = useCallback(() => {
    const actionMeta: ActionMeta<SelectOption> = {
      action: 'select-option',
      option: undefined,
    }

    // Deselect all
    if (isAllSelected) return onChange([], actionMeta)

    // Select all
    return onChange(options as SelectOption[], actionMeta)
  }, [options, isAllSelected, onChange])

  return (
    <components.Menu {...props}>
      <div className={scss.dropdownHeader} onClick={onSelectAllClick}>
        <Icon
          className='me-2'
          size={18}
          color={isAllSelected ? theme.primary : theme.secondary}
        />
        <StyledMenuHeaderText isActive={isAllSelected}>
          {isAllSelected ? 'Deselect' : 'Select'} all
        </StyledMenuHeaderText>
      </div>
      {children}
    </components.Menu>
  )
}

export default SelectAllDropdownHeader
