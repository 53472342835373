import _ from 'lodash'
import { TextLayer } from '@deck.gl/layers/typed'

// utils
import { isCurrentZoomInVisibilityRange } from 'helpers/layer'
import { getOpacity } from 'components/map/layers/deckLayers/baseLayer'
import {
  getAlignmentBaselineDeck,
  getPropertyValueByName,
  getSourceFeature,
} from 'helpers/map'
import { displayValue } from 'helpers/utils'

export const getTextSubLayerProps = props => {
  const {
    id,
    data,
    fontFamily = 'Open Sans',
    fontWeight = 400,
    getPosition,
    // Text accessors
    getText = { type: 'accessor', value: x => x.text },
    getTextSize = { type: 'accessor', value: 128 },
    getTextColor = { type: 'accessor', value: [0, 0, 0, 255] },
    // Text properties
    getTextAnchor = { type: 'accessor', value: 'start' },
    getAlignmentBaseline = { type: 'accessor', value: 'center' },
    getPixelOffset = { type: 'accessor', value: [0, 0] },

    getTextSizeScale,
    updateTriggers,
    labelProperty,

    background,
    backgroundPadding,
  } = props

  return {
    // `getSubLayerProps` will concat the parent layer id with this id
    id: `${id}-label-${labelProperty || ''}`,
    data,
    fontFamily,
    fontWeight,
    getPosition,
    getText,
    getSize: getTextSize,
    sizeScale: getTextSizeScale,
    getColor: getTextColor,
    getTextAnchor,
    getAlignmentBaseline,
    getPixelOffset,
    pickable: false,
    autoHighlight: false,
    background,
    backgroundPadding,
    updateTriggers: {
      getPosition: updateTriggers.getPosition,
      getText: updateTriggers.getText,
      getSize: updateTriggers.getTextSize,
      getColor: updateTriggers.getTextColor,
      getTextAnchor: updateTriggers.getTextAnchor,
      getAlignmentBaseline: updateTriggers.getAlignmentBaseline,
      getPixelOffset: updateTriggers.getPixelOffset,
    },
  }
}

export const getTextLayerProps = (config, timezone) => {
  const {
    labelProperty,
    labelColour,
    labelSize,
    labelAnchor,
    labelAlignment,
    labelOffsetX,
    labelOffsetY,
  } = config

  return {
    labelProperty,
    getTextSize: 1,
    getTextSizeScale: labelSize,
    getTextColor: labelColour,
    getTextAnchor: labelAnchor,
    getAlignmentBaseline: getAlignmentBaselineDeck(labelAlignment),
    getPixelOffset: [labelOffsetX, labelOffsetY],
    getText: d => {
      const value =
        getPropertyValueByName(d, labelProperty) ||
        getPropertyValueByName(getSourceFeature(d), labelProperty)
      return displayValue(value, timezone) || ''
    },
    updateTriggers: {
      getText: [labelProperty, timezone],
      getTextAnchor: labelAnchor,
      getAlignmentBaseline: labelAlignment,
    },
  }
}

export const getMultipleLabelsTextLayerSpec = ({
  propertyLabels,
  timezone,
  data,
  getPosition,
  visible,
  currentZoom,
  ...rest
}) => {
  return _.map(propertyLabels, property => {
    return new TextLayer({
      ...rest,
      ...getTextSubLayerProps({
        id: property.id,
        data,
        getPosition,
        ...getTextLayerProps(property, timezone),
      }),
      opacity: getOpacity(property.opacity),
      visible:
        visible &&
        isCurrentZoomInVisibilityRange(
          property.zoomVisibilityRange,
          currentZoom
        ),
    })
  })
}
