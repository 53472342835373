import { ReactElement, ReactNode } from 'react'
import { Field, useField } from 'react-final-form'
import _ from 'lodash'
import { FormState } from 'final-form'

// constants
import { TOOLTIP_PLACEMENT } from 'constants/settings'

// components
import { Input } from 'components/common/Form'
import {
  PageHeader,
  IconButton,
  Button,
  Tooltip,
  Badge,
} from 'components/common'

import { Payload } from 'types/common'
import { getActiveStatus } from 'helpers/utils'
import { BADGE_TYPES } from 'constants/common'
import scss from './index.module.scss'
import Subtitle from './Subtitle'
import type { PageHeaderTabsProps } from '..'

export type EditorPageHeaderProps = {
  onReturn?: () => void
  className?: string
  isDisabled?: boolean
  titleName: string
  titlePlaceholder?: string
  descriptionName?: string
  descriptionPlaceholder?: string
  children?: ReactNode
  displaySubmit?: boolean
  submitProps?: {
    content?: string
    disabled?: boolean
    onSubmit?: () => void
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  }
  returnTooltip?: string
  tabsProps?: PageHeaderTabsProps
  isActive?: boolean
} & FormState<Payload>

const EditorPageHeader = ({
  onReturn,
  className,
  submitting,
  pristine,
  isDisabled,
  titleName,
  titlePlaceholder,
  descriptionName,
  descriptionPlaceholder,
  children,
  displaySubmit = true,
  submitProps,
  returnTooltip,
  tabsProps,
  errors,
  isActive,
}: EditorPageHeaderProps): ReactElement => {
  const titleNameFieldValue = useField(titleName).input.value

  return (
    <PageHeader
      className={className}
      tabsProps={tabsProps}
      {...(onReturn && {
        iconComponent: (
          <>
            {returnTooltip ? (
              <Tooltip
                placement={TOOLTIP_PLACEMENT.right}
                trigger={['hover']}
                overlay={<span>{returnTooltip}</span>}
              >
                <IconButton
                  testId='arrow-return'
                  icon='FiArrowLeft'
                  size={32}
                  onClick={onReturn}
                />
              </Tooltip>
            ) : (
              <IconButton
                testId='arrow-return'
                icon='FiArrowLeft'
                size={32}
                onClick={onReturn}
              />
            )}
          </>
        ),
      })}
      titleComponent={
        <>
          {isDisabled ? (
            <div className='d-flex flex-row'>
              <div>{titleNameFieldValue}</div>
              {!_.isUndefined(isActive) && (
                <Badge
                  className={scss.titleBadge}
                  content={getActiveStatus(isActive)}
                  type={isActive ? BADGE_TYPES.success : BADGE_TYPES.default}
                />
              )}
            </div>
          ) : (
            <Field
              component={Input}
              name={titleName}
              placeholder={titlePlaceholder}
              className={scss.input}
              required
              autoComplete='off'
              disabled={isDisabled}
              data-testid='editor-title'
            />
          )}
        </>
      }
      titleFullWidth
      subtitleComponent={
        <Subtitle
          descriptionName={descriptionName}
          descriptionPlaceholder={descriptionPlaceholder}
          isDisabled={isDisabled}
        />
      }
      {...(displaySubmit && {
        rightComponent: (
          <Button
            type='submit'
            className='ms-2'
            disabled={
              isDisabled || submitting || pristine || !_.isEmpty(errors)
            }
            isLoading={submitting}
            testId='editor-save'
            {...submitProps}
          >
            {submitProps?.content || 'Save'}
          </Button>
        ),
      })}
    >
      {children}
    </PageHeader>
  )
}

export default EditorPageHeader
