import styled from '@emotion/styled'
import { Theme, css, SerializedStyles } from '@emotion/react'

import {
  BUTTON_VARIANTS,
  DEFAULT_BUTTON_VARIANT,
  SECONDARY_BUTTON_BORDER_COLOR,
  BUTTON_SIZES,
} from 'components/common/Button/constants'

import Button from '../Button'
import ActionButton from '../ActionButton'
import { SPLIT_BUTTON_VARIANTS } from './constants'
import { SplitButtonProps } from '.'

const getVariantStyles = ({
  variant,
  theme,
}: {
  theme: Theme
  variant?: keyof typeof SPLIT_BUTTON_VARIANTS
}): SerializedStyles => {
  const variations = {
    [BUTTON_VARIANTS.primary]: css`
      border-right-color: ${theme['primary-200']};
      &:disabled {
        border-right-color: ${theme['primary-100']};
      }
    `,
    [BUTTON_VARIANTS.secondary]: css`
      border-right-color: ${SECONDARY_BUTTON_BORDER_COLOR};
      &:disabled {
        border-right-color: ${SECONDARY_BUTTON_BORDER_COLOR};
      }
    `,
  }

  return variant ? variations[variant] : variations[DEFAULT_BUTTON_VARIANT]
}

export const StyledMainButton = styled(Button)<SplitButtonProps>`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  ${getVariantStyles};
`

const getActionButtonPadding = ({
  size,
}: {
  size?: keyof typeof BUTTON_SIZES
}): SerializedStyles => {
  switch (size) {
    case BUTTON_SIZES.small:
      return css`
        padding-left: 8px;
        padding-right: 8px;
      `
    case BUTTON_SIZES.large:
    case BUTTON_SIZES.default:
    default:
      return css`
        padding-left: 10px;
        padding-right: 10px;
      `
  }
}

export const StyledActionButton = styled(ActionButton)`
  padding-left: 10px;
  padding-right: 10px;
  border-left: none;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  ${getActionButtonPadding}
`
