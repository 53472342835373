// TODO: needs to be fixed

// libraries
import { TileLayer as DeckGLTileLayer } from '@deck.gl/geo-layers/typed'
import { VectorTile } from '@mapbox/vector-tile'
import Protobuf from 'pbf'
import _ from 'lodash'

// constants
import { LAYER_TYPES } from 'constants/map'

// utils
import { uniqueStringId, isDevEnvironment } from 'helpers/utils'
import log from 'helpers/log'
import { getTextLayerProps } from '../textLayerHelper'

// components
import Layer from '../baseLayer'
import LabeledGeoJsonLayer from './labeled-geojson-layer'

export const getTilesetMapSource = ({
  mapboxTilesetId,
  mapboxAccessToken,
  x = 0,
  y = 0,
  z = 0,
}) => {
  if (!mapboxTilesetId || !mapboxAccessToken) return null

  return `https://a.tiles.mapbox.com/v4/${mapboxTilesetId}/${z}/${x}/${y}.vector.pbf?access_token=${mapboxAccessToken}`
}

export const handleTilesetError = ({ response }) => {
  let error

  if (response.status === 401) {
    error = 'Token is invalid'
  } else if (response.status === 404) {
    error = `Resource not found`
  } else {
    error = response.statusText
  }

  return error
}

export const tileVisConfigs = {
  radius: 'radius',
  fillColour: 'tileFillColour',
  lineColour: 'tileLineColour',
  lineWidth: 'lineWidth',
  tileMaxZoom: 'tileMaxZoom',
  tileMinZoom: 'tileMinZoom',
  mapboxTilesetId: 'mapboxTilesetId',
  mapboxAccessToken: 'mapboxAccessToken',
  labelProperty: 'labelProperty',
  labelSize: 'labelSize',
  labelColour: 'labelColour',
  labelAnchor: 'labelAnchor',
  labelAlignment: 'labelAlignment',
  labelOffsetX: 'labelOffsetX',
  labelOffsetY: 'labelOffsetY',
}

export default class TileLayer extends Layer {
  constructor(props) {
    super(props)
    this.registerVisConfig(tileVisConfigs, props.style[this.type])
  }

  get type() {
    return LAYER_TYPES.tile
  }

  renderLayer = ({ timezone }) => {
    const {
      visConfig: {
        radius,
        lineColour,
        lineWidth,
        mapboxTilesetId,
        mapboxAccessToken,
        tileMaxZoom,
        tileMinZoom,
        labelOffsetX,
        labelOffsetY,
      },
      fillColour,
    } = this.config

    if (!mapboxTilesetId || !mapboxAccessToken) return null
    const id = uniqueStringId({
      id: this.id,
      mapboxTilesetId,
      mapboxAccessToken,
    })

    const basicProps = { ...this.getLayerBasicProps(), id }

    const stylingProps = {
      stroked: false,
      maxZoom: tileMaxZoom,
      minZoom: tileMinZoom,
      getLineColor: lineColour,
      getFillColor: fillColour,
      getLineWidth: lineWidth,
      pointRadiusMinPixels: radius,
      pointRadiusMaxPixels: radius,
      getPixelOffset: [labelOffsetX, labelOffsetY],
    }

    const dataAccessors = {
      getPosition: d => d.geometry.coordinates,
      // ! For deck.gl >8.8, getTileData: ({ index: { x, y, z } }) =>
      getTileData: ({ x, y, z }) => {
        const mapSource = getTilesetMapSource({
          mapboxTilesetId,
          mapboxAccessToken,
          x,
          y,
          z,
        })

        return fetch(mapSource)
          .then(response => {
            if (!response.ok) {
              const error = handleTilesetError({
                response,
                tilesetId: `${mapboxTilesetId}(${x},${y},${z})`,
              })
              throw new Error(error)
            }
            return response
          })
          .then(response => response.arrayBuffer())
          .then(buffer => {
            const tile = new VectorTile(new Protobuf(buffer))
            return _.reduce(
              tile.layers,
              (acc, vectorTileLayer) => {
                for (let i = 0, l = vectorTileLayer.length; i < l; i += 1) {
                  const vectorTileFeature = vectorTileLayer.feature(i)
                  const feature = vectorTileFeature.toGeoJSON(x, y, z)
                  acc.push(feature)
                }
                return acc
              },
              []
            )
          })
          .catch(err => {
            if (isDevEnvironment()) {
              log.error(err.message)
            }
            return []
          })
      },
      renderSubLayers: props => new LabeledGeoJsonLayer(props),
      updateTriggers: {
        getTileData: [mapboxTilesetId, mapboxAccessToken],
      },
    }

    return [
      new DeckGLTileLayer({
        ...basicProps,
        ...stylingProps,
        ...dataAccessors,
        ...getTextLayerProps(this.config.visConfig, timezone),
      }),
    ]
  }
}
