// libraries
import {
  ReactElement,
  ChangeEvent,
  FocusEventHandler,
  useRef,
  useEffect,
} from 'react'
import { useTheme } from '@emotion/react'
import _ from 'lodash'

// utils
import { getWidgetInputDefaultClassNames } from 'helpers/formBuilder'

// components
import WidgetWrapper from 'components/common/JsonForm/WidgetWrapper'
import * as Icons from 'components/icons'

import { getIconProps } from './utils'

// styles
import scss from './index.module.scss'

import type { TextWidgetProps } from './types'

const TextWidget = ({
  id,
  name,
  className,
  placeholder,
  required,
  autofocus,
  readonly,
  disabled,
  type,
  label,
  value,
  onChange,
  onBlur,
  onFocus,
  options,
  schema,
  uiSchema,
  registry,
  rawErrors,
  formContext: { isPreview },
}: TextWidgetProps): ReactElement => {
  const handleChange = ({
    target: { value: newValue },
  }: ChangeEvent<HTMLInputElement>) => {
    onChange(newValue === '' ? options.emptyValue : newValue)
  }

  const handleBlur: FocusEventHandler<HTMLInputElement> = ({
    target: { value: newValue },
  }) => onBlur(id, newValue)

  const handleFocus: FocusEventHandler<HTMLInputElement> = ({
    target: { value: newValue },
  }) => onFocus(id, newValue)

  const { schemaUtils } = registry
  const displayLabel =
    schemaUtils.getDisplayLabel(schema, uiSchema) && (!!label || !!schema.title)

  const inputType =
    (type || schema.type) === 'string' ? 'text' : `${type || schema.type}`

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (inputRef.current && autofocus) {
      inputRef.current.focus()
    }
  }, [autofocus])

  const { iconName, ...iconProps } = getIconProps({
    schema,
    uiSchema,
    theme: useTheme(),
  })

  const Icon = iconName ? _.get(Icons, iconName) : null

  return (
    <WidgetWrapper
      label={displayLabel ? label || schema.title : undefined}
      description={schema.description}
      required={required}
      name={name}
      rawErrors={rawErrors}
      isLarge={isPreview}
    >
      <input
        id={id}
        name={name}
        value={value || value === 0 ? value : ''}
        type={inputType}
        className={`
          ${getWidgetInputDefaultClassNames(isPreview)}
          ${className} 
          ${iconName ? scss.iconInclude : ''}`}
        placeholder={placeholder || schema.placeholder}
        readOnly={readonly}
        required={required}
        disabled={disabled}
        ref={inputRef}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
      {iconName && (
        <span className={scss.inputIcon}>
          <Icon {...iconProps} />
        </span>
      )}
    </WidgetWrapper>
  )
}

export default TextWidget
