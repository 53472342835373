// constant
import { MESSAGE_TYPES } from 'constants/message'

export const ANIMATION_DURATION = 200

export const DEFAULT_ICONS = {
  [MESSAGE_TYPES.success]: 'CheckPlainIcon',
  [MESSAGE_TYPES.error]: 'IconDanger',
  [MESSAGE_TYPES.info]: 'InfoIcon',
  [MESSAGE_TYPES.warning]: 'IconWarning',
  [MESSAGE_TYPES.primary]: 'CheckPlainIcon',
}

export const MESSAGE_ICON_SIZE = 20
