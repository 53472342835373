// libraries
import { useCallback, useState, useMemo } from 'react'
import _ from 'lodash'
import { useGetSet } from 'react-use'
import { useRecoilValue } from 'recoil'

// constants
import {
  MESSAGE_TYPES,
  MESSAGE_ENTITIES,
  MESSAGE_STATUS,
} from 'constants/message'
import { BASE_LAYER_TYPES, LAYER_TYPES } from 'constants/map'

// utils
import { cloneMapLayer, isAssetLayer } from 'helpers/map'
import { showEntityCrudMessage } from 'helpers/message'
import { allAssetProfilesState } from 'recoilStore/assetsStore'
import { getAssetLayerConfig } from 'helpers/asset'
import { switchcaseF } from 'helpers/utils'

import type { Payload } from 'types/common'
import type { MapLayer } from 'types/map'

const getValidLayers = (layers: MapLayer[]): MapLayer[] => {
  return _.filter(layers, ({ dataset, type, baseType, style }) => {
    return switchcaseF({
      [LAYER_TYPES.tile]: () =>
        style[type].mapboxTilesetId && style[type].mapboxAccessToken,
      [LAYER_TYPES.tile3D]: () => true,
      [BASE_LAYER_TYPES.asset]: () => true,
    })(() => dataset)(baseType || type)
  })
}

const useMapLayerState = ({
  layers,
  updateMapConfigs,
}: {
  layers: MapLayer[],
}) => {
  /**
   * Zoom to the given layer
   */
  const [zoomToLayer, setZoomToLayer] = useState()
  /**
   * Current active map layer
   */
  const [currentLayerId, setCurrentLayerId] = useState()
  /**
   * All layers filtered data
   */
  const [getLayersFilteredData, setLayersFilteredData] = useGetSet({})

  const assetProfiles = useRecoilValue(allAssetProfilesState)

  const updateMapLayers = useCallback(
    (newLayers, isNew = false) => {
      const validLayers = isNew ? newLayers : getValidLayers(newLayers)
      updateMapConfigs({ layers: validLayers })
    },
    [updateMapConfigs]
  )

  const addLayer = useCallback(
    (newLayer, index) => {
      const newLayers = _.isEmpty(layers) ? [] : [...layers]
      if (index >= 0) {
        newLayers.splice(index, 0, newLayer)
      } else {
        newLayers.unshift(newLayer)
      }
      updateMapLayers(newLayers, true)
    },
    [updateMapLayers, layers]
  )

  const getLayer = useCallback(
    layerId => {
      if (!layerId) return null

      return _.find(layers, { id: layerId })
    },
    [layers]
  )

  const updateLayerConfigs = useCallback(
    (layerId: string, payload: Payload) => {
      if (!layerId || _.isEmpty(layers)) return

      const updatedLayers = layers.map(layer =>
        layer.id === layerId
          ? {
              ...layer,
              ...payload,
            }
          : layer
      )
      updateMapLayers(updatedLayers)
    },
    [updateMapLayers, layers]
  )

  const getLayerIndex = useCallback(
    layerId => _.findIndex(layers, ['id', layerId]),
    [layers]
  )

  const deleteLayer = useCallback(
    async layerId => {
      const layerEntity = MESSAGE_ENTITIES.layer
      const status = MESSAGE_STATUS.deleted
      const mapLayer = getLayer(layerId)
      if (!mapLayer) {
        showEntityCrudMessage({
          status,
          entity: layerEntity,
          type: MESSAGE_TYPES.error,
          error: new Error('The layer to be deleted does not exist'),
        })
        return
      }
      const newLayers = _.reject(layers, { id: layerId })
      updateMapLayers(newLayers)
      showEntityCrudMessage({
        status: MESSAGE_STATUS.deleted,
        entity: layerEntity,
        type: MESSAGE_TYPES.success,
      })
    },
    [getLayer, updateMapLayers, layers]
  )

  const cloneLayer = useCallback(
    async layerId => {
      const originalLayer = getLayer(layerId)
      if (!originalLayer) return

      const clonedLayer = cloneMapLayer(' copy')(originalLayer)
      const index = getLayerIndex(layerId)
      addLayer(clonedLayer, index)
    },
    [addLayer, getLayer, getLayerIndex]
  )

  const getLayerDataById = useCallback(
    layerId => _.get(getLayersFilteredData(), layerId, {}),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getLayersFilteredData()]
  )

  const getLayerFilteredDataById = useCallback(
    (layerId, withTimeFilter) => {
      return _.get(getLayersFilteredData(), [
        layerId,
        withTimeFilter ? 'filteredData' : 'filteredDataWithoutTimeFilter',
      ])
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getLayersFilteredData()]
  )

  const layersWithExternalConfig = useMemo(() => {
    return _.map(layers, layer => {
      if (isAssetLayer(layer)) {
        return getAssetLayerConfig(layer, assetProfiles)
      }
      return layer
    })
  }, [assetProfiles, layers])

  return {
    updateMapLayers,
    zoomToLayer,
    setZoomToLayer,
    currentLayerId,
    setCurrentLayerId,
    getLayer,
    updateLayerConfigs,
    addLayer,
    deleteLayer,
    cloneLayer,
    getLayersFilteredData,
    setLayersFilteredData,
    getLayerDataById,
    getLayerFilteredDataById,
    layersWithExternalConfig,
  }
}

export default useMapLayerState
