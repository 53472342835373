import { ReactElement, ChangeEvent, FocusEvent } from 'react'
import { WidgetProps } from '@rjsf/core'
import _ from 'lodash'

import WidgetWrapper from 'components/common/JsonForm/WidgetWrapper'
import { Checkbox } from 'components/common'

import type { Option, Options } from 'types/common'

import scss from './index.module.scss'

const selectValue = (value: string, selected: string, all: string[]) => {
  const at = all.indexOf(value)
  const updated = selected.slice(0, at).concat(value, selected.slice(at))

  // As inserting values at predefined index positions doesn't work with empty
  // arrays, we need to reorder the updated selection to match the initial order
  return updated.sort((a: string, b: string) => all.indexOf(a) > all.indexOf(b))
}

const deselectValue = (value: string, selected: string) => {
  return selected.filter((v: string) => v !== value)
}

const CheckboxesWidget = ({
  schema,
  label,
  id,
  name,
  disabled,
  options,
  value,
  readonly,
  required,
  rawErrors,
  onChange,
  onBlur,
  onFocus,
  formContext: { isPreview },
}: WidgetProps): ReactElement => {
  const { enumOptions = [], enumDisabled } = options || {}

  const handleChange =
    (option: Option) =>
    ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
      const all = (enumOptions as Options).map(
        ({ value: newValue }) => newValue
      )

      if (checked) {
        onChange(selectValue(option.value, value, all))
      } else {
        onChange(deselectValue(option.value, value))
      }
    }

  const handleBlur = ({ target: { checked } }: FocusEvent<HTMLInputElement>) =>
    onBlur(id, checked)

  const handleFocus = ({ target: { checked } }: FocusEvent<HTMLInputElement>) =>
    onFocus(id, checked)

  return (
    <WidgetWrapper
      name={name}
      label={label || schema.title}
      description={schema.description}
      required={required}
      rawErrors={rawErrors}
      isLarge={isPreview}
    >
      {(enumOptions as Options).map((option: Option, index: number) => {
        const checked = _.includes(value, option.value)

        const itemDisabled =
          enumDisabled && _.includes(enumDisabled as Options, option.value)

        return (
          <Checkbox
            key={`${id}_${option.value}`}
            id={`${id}_${index}`}
            containerClassName={scss.checkboxContainer}
            checked={checked}
            disabled={disabled || itemDisabled || readonly}
            label={option.label}
            reverseLabel
            onChange={handleChange(option)}
            onBlur={handleBlur}
            onFocus={handleFocus}
          />
        )
      })}
    </WidgetWrapper>
  )
}

export default CheckboxesWidget
