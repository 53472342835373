import React, { memo, useMemo } from 'react'
import PropTypes from 'prop-types'

// utils
import { displayValue } from 'helpers/utils'
import { DEFAULT_WIDGET_COLOUR_PALETTE } from 'constants/widget'

// constants
import { THEMES } from 'constants/colour'

// style
import scss from './index.module.scss'

const NumericWidget = memo(({ value, label, colour, theme }) => {
  const isLightTheme = useMemo(() => theme === THEMES.light, [theme])

  const divStyle = {
    color: colour,
  }
  return (
    <div className={scss.center}>
      <div style={divStyle}>{displayValue(value)}</div>
      <div
        className={scss.subTitle}
        style={{ color: isLightTheme ? '#000' : '#fff' }}
      >
        {label}
      </div>
    </div>
  )
})

NumericWidget.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  colour: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  label: PropTypes.string,
  theme: PropTypes.oneOf([THEMES.dark, THEMES.light]),
}

NumericWidget.defaultProps = {
  value: undefined,
  label: undefined,
  colour: DEFAULT_WIDGET_COLOUR_PALETTE,
  theme: THEMES.dark,
}

export default NumericWidget
