import { CSSProperties, ReactElement } from 'react'
import { useToggle } from 'react-use'

// constants
import { TOOLTIP_PLACEMENT } from 'constants/settings'

// components
import { InfoTooltip } from 'components/common/Tooltip'
import { IconButton } from 'components/common/'
import _ from 'lodash'

const TitleWithTooltip = ({
  title,
  style,
  className,
  tooltip,
  checkable,
  onChecked,
}: {
  title: string
  style?: CSSProperties
  className?: string
  tooltip?: string
  checkable?: boolean
  onChecked?: () => void
}): ReactElement => {
  const [isSelect, toggleSelect] = useToggle(false)

  const isTooltipShown = !_.isEmpty(tooltip)
  return (
    <div className={`groupOptionLabel ${className}`} style={style}>
      {checkable && (
        <IconButton
          icon={isSelect ? 'MdCheckBox' : 'MdCheckBoxOutlineBlank'}
          onClick={() => {
            onChecked(!isSelect)
            toggleSelect()
          }}
          size={24}
          className='me-1'
        />
      )}
      {title}
      {isTooltipShown && (
        <InfoTooltip placement={TOOLTIP_PLACEMENT.right} message={tooltip} />
      )}
    </div>
  )
}

TitleWithTooltip.defaultProps = {
  tooltip: undefined,
  checkable: false,
  onChecked: undefined,
  style: undefined,
  className: '',
}

export default TitleWithTooltip
