import { ReactElement, DragEvent } from 'react'
import Color from 'color'
import _ from 'lodash'

import { WORKFLOW_DIAGRAM_NODE_TYPES } from 'constants/workflow'

import type { IconType } from 'react-icons'

import scss from './index.module.scss'

const DEFAULT_COLOUR = '#707070'

export const REACT_DIAGRAMS_NODE_CONFIG = 'react-diagrams-node-type'

const onDragStart = (type: string, label?: string) => (event: DragEvent) => {
  event.dataTransfer.setData(
    REACT_DIAGRAMS_NODE_CONFIG,
    JSON.stringify({
      nodeType: type,
      nodeLabel: label,
    })
  )
}

export type ToolboxItem = {
  draggable?: boolean
  size?: number
  color?: string
  disabled?: boolean
  shouldShowDefaultLabel?: boolean
  className?: string
}

const itemStyle = {
  opacity: 0.4,
  cursor: 'not-allowed',
}

export const toolboxItemFactory =
  (Icon: IconType, type: string, label?: string) =>
  ({
    draggable = true,
    size = 20,
    color,
    disabled,
    shouldShowDefaultLabel = true,
    className,
  }: ToolboxItem): ReactElement => {
    return (
      <div
        className={`${scss.item} ${className}`}
        {...(disabled && { style: itemStyle })}
        draggable={!disabled && draggable}
        onDragStart={onDragStart(type, label)}
      >
        <div style={{ borderColor: color || DEFAULT_COLOUR }}>
          <Icon
            className={scss.icon}
            size={size}
            color={color ? Color(color).darken(0.15).string() : DEFAULT_COLOUR}
          />
        </div>
        {shouldShowDefaultLabel && (
          <div className='mt-1'>{_.startCase(_.lowerCase(label || type))}</div>
        )}
      </div>
    )
  }

export const ToolboxItemSplit = ({
  draggable = true,
  size = 45,
  color = DEFAULT_COLOUR,
}: Pick<ToolboxItem, 'draggable' | 'size' | 'color'>): ReactElement => {
  const type = WORKFLOW_DIAGRAM_NODE_TYPES.FLOW_SPLIT

  return (
    <div
      draggable={draggable}
      onDragStart={onDragStart(type, 'split')}
      className={scss.split}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        height={size}
        width={size}
        viewBox='0 0 24 24'
      >
        <polygon
          stroke={color}
          strokeWidth='1'
          fill='#ffffff'
          points='12,1 23,12 12,23 1,12'
        />
        <line stroke={color} strokeWidth='1' x1='12' y1='9' x2='12' y2='15' />
        <line stroke={color} strokeWidth='1' x1='9' y1='12' x2='15' y2='12' />
      </svg>
    </div>
  )
}
