// libraries
import { useState, useCallback, ReactElement } from 'react'

// constants
import { PROPERTY_VARIABLE_TYPES } from 'constants/filter'

// utils
import { isNumericType } from 'helpers/filter'

// components
import { ColourRamp } from 'components/common/Colour/ColourPickerScales'
import { PropertyPicker, MinMaxRange, IconButton } from 'components/common'

import type { ColourProperty, Options, Range, Colours } from 'types/common'

type ColourPickerAdvancedProp = {
  colourRange: Colours
  propertyOptions: Options
  colourProperty: ColourProperty
  range: Range
  onChange: (key: string, value: unknown) => void
}

/**
 * Filter out boolean property (only keep type === number || type === string
 * as it would have only two colour classes and it is not in the requirement.
 *
 * If selected property is numerical property, render component that can
 * configure specific value ranges and associate each range with a colour
 *
 * If selected property is string property, render component that can configure
 * specific text and associate each range with a colour
 */
const ColourPickerAdvanced = ({
  colourRange,
  propertyOptions,
  colourProperty = {},
  range,
  onChange,
}: ColourPickerAdvancedProp): ReactElement => {
  const [reverse, setReverse] = useState(false)
  const colourReverseHandler = () => {
    setReverse(!reverse)
  }

  // no range (min & max) for string
  const [isRangeVisible, setIsRangeVisible] = useState(
    isNumericType(colourProperty.type)
  )

  const [propertyValue, setPropertyValue] = useState(colourProperty.key || '')

  const onPropertyChangeHandler = useCallback(
    newProperty => {
      let showRange = false
      let propertyVal = ''
      let property = {}
      if (newProperty) {
        const { value, type } = newProperty
        showRange = isNumericType(type)
        propertyVal = value
        property = newProperty
      } else {
        propertyVal = ''
      }

      setIsRangeVisible(showRange)
      setPropertyValue(propertyVal)
      onChange('property', property)
    },
    [onChange]
  )

  return (
    <>
      <div className='groupOptionLabel'>Colour based on</div>
      <PropertyPicker
        property={propertyValue}
        onChange={onPropertyChangeHandler}
        propertyTypes={[
          PROPERTY_VARIABLE_TYPES.string,
          PROPERTY_VARIABLE_TYPES.number,
        ]}
        propertyOptions={propertyOptions}
        isMulti={false}
        isClearable={false}
        preSelect
      />

      {propertyValue && (
        <>
          <div className='groupOptionLabel d-flex justify-content-between'>
            <div>Select Colour</div>
            <IconButton
              icon='ReverseIcon'
              onClick={colourReverseHandler}
              size={14}
            />
          </div>
          <ColourRamp
            colours={colourRange}
            onChange={val => onChange('colourRange', val)}
            reverse={reverse}
          />
          {isRangeVisible && (
            <MinMaxRange
              minValue={range[0]}
              maxValue={range[1]}
              onChange={val => onChange('range', val)}
            />
          )}
        </>
      )}
    </>
  )
}

export default ColourPickerAdvanced
