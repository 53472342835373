// libraries
import { useMemo, useState, useCallback, useEffect } from 'react'
import _ from 'lodash'
import { useAsync, useMountedState } from 'react-use'
import update from 'immutability-helper'
import { useRecoilValue } from 'recoil'

// constants
import { ISSUE_TYPES } from 'constants/issue'
import {
  issueTaskDataCollectionFormDefinitionState,
  IssueTaskDataCollectionForms,
  issueTaskDataCollectionXFormSpecificationList,
} from 'recoilStore/issuesStore'

// utils
import { useTimezone } from 'hooks'
import { getIssue } from 'services/api/issue'
import {
  getDataCollectionStateData,
  getPrettifiedDataCollectionStateData,
} from 'components/issue/hooks/utils'
import { getDataCollectionFormReference } from 'helpers/issue'

import type { AsyncState } from 'types/common'
import type {
  Issue,
  BaseIssue,
  IssueId,
  IssueStateDataCollectionData,
  SubTaskDataCollectionStateData,
  IssueStateSubTasksData,
  SubTasksReference,
  InternalDataCollectionStateData,
} from 'types/issue'
import log, { reportException } from 'helpers/log'

type ProcessedStateData = {
  dataCollectionStateData: (
    | IssueStateDataCollectionData
    | SubTaskDataCollectionStateData
  )[]
  dataCollectionFormName?: string
  dataCollectionFormId?: string
}

const getFormInfo = (issue: BaseIssue, forms: IssueTaskDataCollectionForms) => {
  const { taskType, type, id: issueId } = issue

  const formReference = getDataCollectionFormReference(issue)

  if (type === ISSUE_TYPES.TASK && !forms[formReference]) {
    reportException(
      `Failed to find form [${formReference}] for issue ${issueId}.`
    )
    log.info('Current issue and available forms', { issue, forms })
  }

  const { title, id = formReference, jsonFormBody } = forms[formReference] || {}

  const { schema } = jsonFormBody || {}

  return {
    dataCollectionFormName: title || schema?.title,
    dataCollectionFormId: id,
    taskType,
  }
}

export type StatesProps = {
  statesData: []
  dataCollectionFormName?: string
  dataCollectionFormId: string
  issueId: string
}

export const getProcessedStateData = ({
  statesData,
  dataCollectionFormId,
  issueId,
  taskType,
  ...rest
}: StatesProps): ProcessedStateData => {
  const dataCollectionStateData = getDataCollectionStateData({
    dataCollectionFormReference: dataCollectionFormId,
    issueId,
    statesData,
    taskType,
  })
  return { ...rest, dataCollectionStateData, dataCollectionFormId }
}

const useDataCollectionStateData = ({
  issueId,
  baseIssue,
  isSubTask,
  hideInvalidValues = true,
}: {
  issueId: IssueId
  baseIssue?: BaseIssue
  isSubTask?: boolean
  hideInvalidValues?: boolean
}): {
  updateIssueData: (v?: BaseIssue) => void
  prettifiedDataCollectionStateData: InternalDataCollectionStateData[]
  issueTaskDataCollectionFormsKeyByFormReference: IssueTaskDataCollectionForms
} & AsyncState<Issue> &
  ProcessedStateData => {
  const isMounted = useMountedState()

  const { timezone } = useTimezone()

  const issueTaskDataCollectionFormsKeyByFormReference = useRecoilValue(
    issueTaskDataCollectionFormDefinitionState
  )
  const formsSpecificationParameters = useRecoilValue(
    issueTaskDataCollectionXFormSpecificationList
  )

  const [issueData, setIssueData] = useState<BaseIssue | undefined>(baseIssue)

  const updateIssueData = useCallback(
    (updatedIssue: BaseIssue) => {
      setIssueData(oldIssue => {
        const oldIssueStateData = oldIssue
          ?.statesData?.[0] as IssueStateSubTasksData
        if (!oldIssueStateData) return oldIssue

        if (isSubTask || updatedIssue.type !== ISSUE_TYPES.SUBTASK) {
          return updatedIssue
        }

        const oldSubTasksReferences = oldIssueStateData.subTasksReferences
        const oldSubtaskIndex = _.findIndex(oldSubTasksReferences, [
          'issue.id',
          updatedIssue.id,
        ])
        const subTasksReferences = update(oldSubTasksReferences, {
          [oldSubtaskIndex]: {
            $apply: d => {
              return {
                issue: { ...d.issue, statesData: updatedIssue.statesData },
              } as SubTasksReference
            },
          },
        })
        return {
          ...oldIssue,
          statesData: [{ ...oldIssueStateData, subTasksReferences }],
        }
      })
    },
    [isSubTask]
  )

  const { loading, error } = useAsync(async () => {
    const issue = await getIssue(issueId)
    if (isMounted()) {
      setIssueData(issue as BaseIssue)
    }
    return issue
  }, [issueId])

  useEffect(() => {
    setIssueData(oldIssueData => {
      if (_.isEqual(baseIssue, oldIssueData)) return oldIssueData

      return baseIssue
    })
  }, [baseIssue])

  const issueState = useMemo(() => {
    const { id, statesData } = issueData || {}
    if (!issueTaskDataCollectionFormsKeyByFormReference || !issueData || !id)
      return {} as ProcessedStateData

    const data = {
      ...getFormInfo(issueData, issueTaskDataCollectionFormsKeyByFormReference),
      statesData,
      issueId: id,
    }
    return getProcessedStateData(data)
  }, [issueData, issueTaskDataCollectionFormsKeyByFormReference])

  const result = {
    ...issueState,
    updateIssueData,
    loading,
    error,
  }

  const { dataCollectionStateData } = result

  const prettifiedDataCollectionStateData = useMemo(() => {
    return getPrettifiedDataCollectionStateData({
      dataCollectionStateData:
        dataCollectionStateData as IssueStateDataCollectionData[],
      formsSpecificationParameters,
      timezone,
      hideInvalidValues,
      issueTaskDataCollectionFormsKeyByFormReference,
    })
  }, [
    dataCollectionStateData,
    formsSpecificationParameters,
    hideInvalidValues,
    issueTaskDataCollectionFormsKeyByFormReference,
    timezone,
  ])

  return {
    ...result,
    prettifiedDataCollectionStateData,
    issueTaskDataCollectionFormsKeyByFormReference,
  }
}

export default useDataCollectionStateData
