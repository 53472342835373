// libraries
import { ReactElement } from 'react'

// components
import TextWidget from '../TextWidget'

import type { TextWidgetProps } from '../TextWidget/types'

const ScannerWidget = (props: TextWidgetProps): ReactElement => (
  <TextWidget {...props} readonly disabled />
)

export default ScannerWidget
