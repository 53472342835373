// libraries
import React, { useMemo } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

// constants
import { TOOLTIP_PLACEMENT } from 'constants/settings'

// utils
import { useBranding } from 'hooks'
import { THEMES } from 'constants/colour'

// component
import { IconButton, Dropdown } from 'components/common'

// scss
import scss from './index.module.scss'

const SortButton = ({
  sortOptions,
  ascSortOrder,
  property,
  onChange,
  onOrderChange,
  className,
}) => {
  const {
    colour: { primary },
  } = useBranding()

  const label = useMemo(() => {
    const option = _.find(sortOptions, ['value', property])
    return _.get(option, 'label', '')
  }, [sortOptions, property])

  return (
    <div className={`${scss.toolbox} ${className} d-flex align-items-center`}>
      Sort by
      <Dropdown
        options={sortOptions}
        onChange={onChange}
        placement={TOOLTIP_PLACEMENT.bottom}
        theme={THEMES.dark}
        toggleComponent={() => (
          <button
            className={scss.sortText}
            style={{
              color: primary,
            }}
            type='button'
          >
            {label}
          </button>
        )}
      />
      <IconButton
        icon={ascSortOrder ? 'BsSortUpAlt' : 'BsSortDown'}
        size={18}
        className='px-0'
        onClick={() => onOrderChange(!ascSortOrder)}
        label={ascSortOrder ? 'Ascending' : 'Descending'}
      />
    </div>
  )
}

SortButton.propTypes = {
  sortOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ).isRequired,
  ascSortOrder: PropTypes.bool,
  property: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onOrderChange: PropTypes.func.isRequired,
  className: PropTypes.string,
}

SortButton.defaultProps = {
  ascSortOrder: false,
  className: undefined,
}

export default SortButton
