import { ReactElement } from 'react'
import { Field, useField } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'

import type { DateTimeFieldFormat } from 'types/formBuilder'

import { Toggle, ConditionalField } from 'components/common/Form'
import { getFieldName } from 'helpers/formBuilder'

import DateTimeRangeLimitsInput from './DateTimeRangeLimitsInput'

export type PropertySpec = {
  label: string
  description?: string
  inline?: boolean
  disabled?: boolean
  required?: boolean
}

type RangeLimitsProperties = {
  visible: PropertySpec
  start: PropertySpec
  end: PropertySpec
}

const DateTimeRangeLimits = ({
  name,
  nodeId,
  properties,
  format,
  disabled,
}: {
  name: string
  nodeId: string
  properties: RangeLimitsProperties
  format: DateTimeFieldFormat
  disabled?: boolean
}): ReactElement => {
  const { visible, start, end } = properties
  const visibleFieldName = `${name}.visible`
  const startFieldName = `${name}.start`
  const endFieldName = `${name}.end`
  const formatFieldName = getFieldName(nodeId, 'format')

  const startFieldInput = useField(startFieldName).input
  const endFieldInput = useField(endFieldName).input

  const cleanupRange = () => {
    startFieldInput.onChange(undefined)
    endFieldInput.onChange(undefined)
  }

  const commonInputProps = {
    format,
    disabled,
  }

  return (
    <>
      <Field
        component={Toggle}
        name={visibleFieldName}
        disabled={disabled}
        {...visible}
      />
      <ConditionalField watch={visibleFieldName}>
        <div className='mt-2'>
          <DateTimeRangeLimitsInput
            name={startFieldName}
            {...commonInputProps}
            {...start}
          />
        </div>
        <div className='mt-2'>
          <DateTimeRangeLimitsInput
            name={endFieldName}
            {...commonInputProps}
            {...end}
          />
        </div>
      </ConditionalField>

      <OnChange name={visibleFieldName}>
        {value => {
          if (!value) {
            // Cleanup fields if they are no longer visible
            cleanupRange()
          }
        }}
      </OnChange>

      <OnChange name={formatFieldName}>
        {(currValue, prevValue) => {
          if (currValue !== prevValue) {
            // Cleanup the range when 'format' is changed
            cleanupRange()
          }
        }}
      </OnChange>
    </>
  )
}

export default DateTimeRangeLimits
