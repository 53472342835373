import { ReactElement } from 'react'
import { useRecoilValue } from 'recoil'

import type { IssueGeojson } from 'types/issue'
import { IssueStateDataCollectionParameter } from 'types/graphql'
import { issueTaskDataCollectionFormDefinitionState } from 'recoilStore/issuesStore'

export const FormTitle = ({ stateParameter }: IssueGeojson): ReactElement => {
  const issueTaskDataCollectionFormsKeyByFormReference = useRecoilValue(
    issueTaskDataCollectionFormDefinitionState
  )

  const formReference = (
    stateParameter as Partial<IssueStateDataCollectionParameter>
  )?.dataCollectionFormReference

  const formData = issueTaskDataCollectionFormsKeyByFormReference[formReference]

  return (
    formData?.title ||
    formData?.jsonFormBody?.schema.title ||
    formReference ||
    'Unknown form'
  )
}

const FormTitleTemplate = ({ stateParameter }: IssueGeojson): ReactElement => {
  return (
    <div>
      <FormTitle stateParameter={stateParameter} />
    </div>
  )
}

export default FormTitleTemplate
