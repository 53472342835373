import React from 'react'
import { WIDGET_TYPES } from 'constants/widget'
import PropTypes from 'prop-types'

// utils
import { switchcaseF } from 'helpers/utils'

// components
import {
  MdTextFields,
  AiOutlineFieldNumber,
  AiOutlineTable,
  BsImages,
  LineIcon,
  BarIcon,
  GaugeIcon,
  ListIcon,
  ScatterIcon,
} from 'components/icons'

const WidgetIcon = ({ widgetType, className }) => {
  return switchcaseF({
    [WIDGET_TYPES.list]: () => <ListIcon className={className} />,
    [WIDGET_TYPES.numeric]: () => (
      <AiOutlineFieldNumber size={20} className={className} />
    ),
    [WIDGET_TYPES.line]: () => <LineIcon className={className} />,
    [WIDGET_TYPES.bar]: () => <BarIcon className={className} />,
    [WIDGET_TYPES.gauge]: () => <GaugeIcon className={className} />,
    [WIDGET_TYPES.scatter]: () => <ScatterIcon className={className} />,
    [WIDGET_TYPES.text]: () => <MdTextFields className={className} />,
    [WIDGET_TYPES.table]: () => <AiOutlineTable className={className} />,
    [WIDGET_TYPES.image]: () => <BsImages className={className} />,
  })(() => <ListIcon className={className} />)(widgetType)
}

WidgetIcon.propTypes = {
  widgetType: PropTypes.string,
  className: PropTypes.string,
}

WidgetIcon.defaultProps = {
  widgetType: WIDGET_TYPES.list,
  className: undefined,
}

export default WidgetIcon
