// libraries
import { useMemo, useCallback, ReactElement } from 'react'
import _ from 'lodash'

// utils
import { getFromNow } from 'helpers/datetime'
import { useCurrentUser } from 'hooks'
import { findOption } from 'helpers/utils'
import { reportIssueError } from 'services/api/issue'

// constants
import {
  AVATAR_SIZES,
  DEFAULT_SYSTEM_USER,
  DEFAULT_UNKNOWN_AVATAR_URL,
} from 'constants/user'

// components
import { Avatar, IconButton } from 'components/common'
import IssueTextEditor from 'components/issue/IssueItemDetail/IssueTextEditor'

// types
import type { IssueAnnotations as IssueAnnotationsType } from 'types/issue'

// scss
import scss from './index.module.scss'

type UpdateFn = (
  variables: Record<string, unknown>
) => Promise<string | undefined>

const updateIssueCommentHandler = (
  updateFn: UpdateFn,
  variables: { issueId: string; index: number; text?: string }
) => {
  const { issueId, index } = variables
  if (index >= 0) {
    updateFn(variables)
  } else {
    reportIssueError({ error: `No valid annotation index for ${issueId}` })
  }
}

const IssueAnnotations = ({
  issueId,
  annotations,
  actions = {},
  className = '',
  itemClassName = '',
}: {
  issueId: string
  annotations: IssueAnnotationsType
  actions?: {
    modifyIssueComment?: UpdateFn
    deleteIssueComment?: UpdateFn
  }
  className?: string
  itemClassName?: string
}): ReactElement => {
  const { issueAssigneesOptions } = useCurrentUser()

  const { modifyIssueComment, deleteIssueComment } = actions

  const onIssueCommentChange = useCallback(
    (index: number) => (value: string) => {
      if (!modifyIssueComment || _.get(annotations, [index, 'text']) === value)
        return

      updateIssueCommentHandler(modifyIssueComment, {
        issueId,
        index,
        text: value,
      })
    },
    [annotations, issueId, modifyIssueComment]
  )

  const onIssueCommentDelete = useCallback(
    (index: number) => () => {
      if (!deleteIssueComment) return
      updateIssueCommentHandler(deleteIssueComment, {
        issueId,
        index,
      })
    },
    [issueId, deleteIssueComment]
  )

  const memoizedAnnotations = useMemo(() => {
    return (
      <div className={`${scss.list} ${className}`}>
        {_(annotations)
          .reject({ deleted: true })
          .orderBy('createdAt', 'desc')
          .map(({ text, index, createdAt, modifiedAt, authorReference }) => {
            if (!text) return undefined

            const user = authorReference
              ? findOption(
                  issueAssigneesOptions,
                  'username',
                  authorReference
                ) || {
                  avatarUrl: DEFAULT_UNKNOWN_AVATAR_URL,
                  label: authorReference,
                  username: authorReference,
                }
              : DEFAULT_SYSTEM_USER

            const { username, label, name } = user

            const displayName = name || label || username
            const displayDeleteBtn =
              !!deleteIssueComment && (authorReference || _.isNil(index))
            const editingDisabled =
              !modifyIssueComment || !authorReference || _.isNil(index)

            return (
              <div
                key={`${createdAt}-${username}-${index}`}
                className={`d-flex ${scss.item} ${itemClassName}`}
              >
                <div className='flex-shrink-0'>
                  <Avatar user={user} size={AVATAR_SIZES.small} />
                </div>
                <div className='flex-grow-1 ms-3'>
                  <span className={scss.name}>{displayName} </span>
                  <span>
                    <IssueTextEditor
                      onChange={onIssueCommentChange(index)}
                      value={text}
                      field='textarea'
                      className={scss.textInput}
                      disabled={editingDisabled}
                    />
                  </span>
                  <span className={scss.time}>({getFromNow(createdAt)})</span>
                  {modifiedAt && <span className='ms-1'>(edited)</span>}
                </div>
                {displayDeleteBtn && (
                  <IconButton
                    icon='IconTrash'
                    onClick={onIssueCommentDelete(index)}
                    width={10}
                    height={10}
                    size={10}
                  />
                )}
              </div>
            )
          })
          .compact()
          .value()}
      </div>
    )
  }, [
    annotations,
    issueAssigneesOptions,
    onIssueCommentChange,
    onIssueCommentDelete,
    modifyIssueComment,
    deleteIssueComment,
    className,
    itemClassName,
  ])

  return memoizedAnnotations
}

export default IssueAnnotations
