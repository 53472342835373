// libraries
import React from 'react'
import { useToggle } from 'react-use'
import _ from 'lodash'
import PropTypes from 'prop-types'

// components
import { Toggle, MultiSelect, NumericInput } from 'components/common'

// utils
import { INTERVAL_UNIT_OPTIONS, DATE_UNIT_TYPES } from 'constants/datetime'

const TimeRangeSetting = ({
  enabled,
  intervalRange,
  intervalUnit,
  onChange,
  axis,
}) => {
  const [isEnabled, toggleIsEnabled] = useToggle(enabled)

  const toggleHandler = () => {
    toggleIsEnabled(!isEnabled)
    onChange({ enabled: !isEnabled })
  }

  const renderTimeRange = () => {
    return (
      <div className='row g-3'>
        <div className='col-7'>
          <MultiSelect
            value={intervalUnit}
            options={_.reject(INTERVAL_UNIT_OPTIONS, [
              'value',
              DATE_UNIT_TYPES.seconds,
            ])}
            onChange={option => onChange({ intervalUnit: option.value })}
            isMulti={false}
            isClearable={false}
          />
        </div>
        <div className='col-5'>
          <NumericInput
            value={intervalRange}
            onChange={val => onChange({ intervalRange: val })}
            className='form-control'
            min={1}
          />
        </div>
      </div>
    )
  }

  return (
    <div className='groupOption'>
      <div className='groupOptionContent'>
        <div className='groupOptionTitle d-flex justify-content-between align-items-center'>
          {axis.toUpperCase()}-Axis Time Range
          <Toggle
            label={isEnabled ? 'Set Time Range' : 'No limit'}
            checked={isEnabled}
            onToggle={toggleHandler}
          />
        </div>
        {isEnabled && renderTimeRange()}
      </div>
    </div>
  )
}

TimeRangeSetting.propTypes = {
  enabled: PropTypes.bool.isRequired,
  intervalRange: PropTypes.number.isRequired,
  intervalUnit: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  axis: PropTypes.string,
}

TimeRangeSetting.defaultProps = {
  axis: 'x',
}

export default TimeRangeSetting
