// libraries
import { ReactElement, PropsWithChildren } from 'react'

// components
import { Badge } from 'components/common'
import * as Icons from 'components/icons'
import scss from '../index.module.scss'

const SectionHeader = ({
  number,
  title,
  subTitle,
  icon,
  iconColour = '#53C332',
  className = '',
  children,
}: PropsWithChildren<{
  icon?: string
  number?: number
  title: string | ReactElement
  subTitle?: string | ReactElement
  iconColour?: string
  className?: string
}>): ReactElement => {
  const Icon = Icons[icon] || Icons.AiFillCheckCircle

  return (
    <div className={`${scss.sectionHeader} ${className}`}>
      <div
        className={`d-flex ${
          subTitle ? 'align-items-start' : '"d-flex align-items-center'
        }`}
      >
        {icon ? (
          <Icon color={iconColour} />
        ) : (
          <Badge content={`STEP ${number || 0}`} />
        )}
        <div className='d-flex flex-column ms-2'>
          <div className='largeText'>{title}</div>
          {subTitle}
        </div>
      </div>
      {children}
    </div>
  )
}

export default SectionHeader
