import React, { useRef, useEffect, useState } from 'react'
import { useMount, useUnmount, useGetSet } from 'react-use'
import JSONEditor from 'jsoneditor'
import _ from 'lodash'
import PropTypes from 'prop-types'

// utils
import { validateGeojson } from 'helpers/geojson'

// style
import 'jsoneditor/dist/jsoneditor.css'
import './theme.scss'
import scss from './index.module.scss'

const GeoJSONEditor = ({ input, className }) => {
  const jsonCode = useRef()
  const [getEditorInstance, setEditorInstance] = useGetSet()
  const [errorMessage, setErrorMessages] = useState([])

  useMount(() => {
    const options = {
      mode: 'code',
      onChange: () => {
        if (getEditorInstance()) {
          try {
            const content = getEditorInstance().get()
            const errors = validateGeojson(content)
            if (_.isEmpty(errors)) {
              input.onChange({ geojson: content })
            }
            setErrorMessages(errors)
            // eslint-disable-next-line no-empty
          } catch {}
        }
      },
    }

    const jsoneditor = new JSONEditor(jsonCode.current, options)
    const content = input.value.geojson ? input.value.geojson : {}
    jsoneditor.set(content)
    setEditorInstance(jsoneditor)
  })

  useUnmount(() => {
    const editorInstance = getEditorInstance()
    if (editorInstance) {
      editorInstance.destroy()
    }
  })

  useEffect(() => {
    const editorInstance = getEditorInstance()
    if (editorInstance && input.value.geojson) {
      editorInstance.update(input.value.geojson)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input.value])

  return (
    <>
      <div ref={jsonCode} className={`${scss.container} ${className}`} />
      {!_.isEmpty(errorMessage) && (
        <small className={scss.errors}>
          The changes will be not be saved because:
          {_(errorMessage)
            .uniqBy('message')
            .map(({ message }) => <li key={message}>{message}</li>)
            .value()}
        </small>
      )}
    </>
  )
}

GeoJSONEditor.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.shape({ geojson: PropTypes.shape({}) }),
  }).isRequired,
  className: PropTypes.string,
}

GeoJSONEditor.defaultProps = {
  className: undefined,
}

export default GeoJSONEditor
