import { USER_SET_LIST, USERS_PAGE_SET_LIST } from 'contexts/actions/user'

import { switchcaseF } from 'helpers/utils'

export const userState = {
  // all users the current user can access (without any filtering)
  usersList: [],
  // users for the user gallery page.
  usersPageList: [],
}

const userReducer = (state, action) => {
  const { type, usersList, usersPageList } = action
  return switchcaseF({
    [USER_SET_LIST]: () => ({
      ...state,
      usersList,
    }),
    [USERS_PAGE_SET_LIST]: () => ({
      ...state,
      usersPageList,
    }),
  })(() => state)(type)
}

export default userReducer
