import { useCallback, ReactElement } from 'react'

import type { AssetProfileViewConfigs, Widget } from 'types/asset'

// constants
import { ASSET_PROFILE_MEDIA_TYPES } from 'constants/assets'
import { THEMES } from 'constants/colour'
import Builder from '../Builder'

const mediaType = ASSET_PROFILE_MEDIA_TYPES.WEB

const BuilderWebLayout = (props: {
  onConfigsChange: (payload: AssetProfileViewConfigs) => void
  assetId?: string
  configs?: Widget[]
  className: string
  profileId?: string
}): ReactElement => {
  const { onConfigsChange, assetId } = props

  const onChange = useCallback(
    (widgets: Widget[]) => {
      onConfigsChange({ [mediaType]: { mediaType, widgets } })
    },
    [onConfigsChange]
  )

  return (
    <Builder
      {...props}
      onConfigsChange={onChange}
      theme={assetId ? THEMES.dark : THEMES.light}
      mediaType={mediaType}
    />
  )
}

export default BuilderWebLayout
