// constants
import { ENTITY_OWNER_FIELDS, AUDITS_FIELDS } from 'constants/graphql'
import { ENTITIES } from 'constants/common'

// utils
import { getQueryFields } from 'helpers/graphql'
import {
  getEntityGraphql,
  listEntitiesGraphql,
  mutateEntity,
  getEntityQuery,
  getEntitiesQuery,
  MutateEntity,
} from 'services/api/utils'

import type { FormList as FormListType } from 'types/graphql'
import type { FormList } from 'types/formBuilder'

const domain = ENTITIES.formList
const queryDomain = `${domain}s`

const FORM_LIST_IDENTIFIER = 'formListId'

const getFormListFields = getQueryFields({
  id: true,
  name: true,
  description: true,
  items: {
    label: true,
    value: true,
  },
  ...ENTITY_OWNER_FIELDS,
  ...AUDITS_FIELDS,
})

const getFormListsQuery = getEntitiesQuery<FormListType>({
  queryDomain,
  getFieldsFn: getFormListFields,
})

export const deserializeFormList = (formList: FormListType): FormList => ({
  ...formList,
  type: ENTITIES.formList,
})

const getFormListByIdQuery = getEntityQuery({
  queryDomain,
  getFieldsFn: getFormListFields,
  identifier: FORM_LIST_IDENTIFIER,
})

export const getFormListById = getEntityGraphql<FormListType>({
  queryDomain,
  getQueryFn: getFormListByIdQuery,
  queryDisplayName: 'GetFormListById',
  postProcessFn: deserializeFormList,
  identifier: FORM_LIST_IDENTIFIER,
})

export const getAllFormLists = listEntitiesGraphql<FormListType>({
  queryDomain,
  getQueryFn: getFormListsQuery,
  queryDisplayName: 'GetAllFormLists',
  defaultOmitFields: ['items'],
  postProcessFn: deserializeFormList,
})

const mutateFormList = (props: Omit<MutateEntity, 'queryDomain'>) =>
  mutateEntity<FormListType>({
    queryDomain,
    responseFields: {
      [domain]: getFormListFields(),
    },
    responsePath: [domain],
    postProcessFn: deserializeFormList,
    ...props,
  })

export const createFormList = mutateFormList({
  fnName: 'createFormList',
  variableFormat: 'CreateFormListInput!',
})

export const updateFormList = mutateFormList({
  fnName: 'updateFormList',
  variableFormat: 'UpdateFormListInput!',
})
