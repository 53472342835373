// libraries
import _ from 'lodash'
import { atom, selector } from 'recoil'

// utils
import { Site } from 'types/sites'

export const sitesListState = atom({
  key: 'sitesListState',
  default: [] as Site[],
})

export const activeSitesListState = selector({
  key: 'activeSitesListState',
  get: ({ get }) => {
    const allSites = get(sitesListState)
    return _.filter(allSites, 'active')
  },
})

export const activeSitesOptionsState = selector({
  key: 'activeSitesOptionsState',
  get: ({ get }) => {
    const allActiveSites = get(activeSitesListState)

    return _.map(allActiveSites, ({ properties, id }) => ({
      label: _.trim(properties?.name) || id,
      value: id,
    }))
  },
})
