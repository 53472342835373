// libraries
import to from 'await-to-js'
import _ from 'lodash'

// constants
import { APPLICATION_NAME, AUTH_ERROR_CODES } from 'constants/common'
import { URLS } from 'constants/route'

// utils
import { apiFetch } from 'services/api'
import { isNil, isDevEnvironment } from 'helpers/utils'
import {
  SuGraphqlError,
  reportGraphqlError,
  getGraphqlResponseError,
  getGraphqlResponseErrorCodes,
  hasUnauthenticatedError,
} from 'helpers/graphql'
import log from 'helpers/log'

import type { Payload } from 'types/common'

const post = apiFetch('POST', SuGraphqlError)

type ResponseError = { extensions?: { code: string } }

type Response = { data: Payload; errors: ResponseError[] }
class GraphqlApiService {
  ready = false

  baseUrl = ''

  clientAwarenessHeaders: Payload = {}

  configure(config: {
    baseUrl: string
    suExplorerVersion?: string
    releaseVersion?: string
  }) {
    const { baseUrl, suExplorerVersion, releaseVersion } = config

    if (isNil(baseUrl)) {
      this.ready = false
      reportGraphqlError('config')({
        error: `graphql url is not valid, graphql_url: ${baseUrl}`,
      })
    } else {
      this.baseUrl = baseUrl
      if (isDevEnvironment()) {
        this.baseUrl = '/api/graphql'
      }
      this.ready = true
    }

    this.clientAwarenessHeaders['apollographql-client-name'] = APPLICATION_NAME
    this.clientAwarenessHeaders[
      'apollographql-client-version'
    ] = `${releaseVersion}/${suExplorerVersion}`
  }

  checkReady() {
    if (!this.ready) {
      throw new SuGraphqlError({
        error: 'GraphqlApiService is not configured for use',
      })
    }
  }

  async fetch({
    query,
    variables = {},
    queryDomain,
    fnName = 'fetch',
    abortController,
    ignoreError = false,
  }: {
    query: string
    variables?: Payload
    queryDomain: string
    fnName?: string
    abortController?: AbortController
    ignoreError?: boolean
  }) {
    this.checkReady()
    const payload = { query, variables }
    const params = {
      ...(abortController && { signal: abortController.signal }),
      headersConfig: this.clientAwarenessHeaders,
    }

    const [err, response] = await to(
      post(this.baseUrl, payload, params) as Promise<Response>
    )
    const { data } = response || {}

    if (hasUnauthenticatedError(response as Payload)) {
      if (!_.includes(window.location.pathname, URLS.LOGIN)) {
        window.location.href = URLS.LOGIN
      }

      throw new SuGraphqlError({
        error: AUTH_ERROR_CODES.UNAUTHENTICATED,
      })
    }

    const error = err?.message || getGraphqlResponseError(response as Payload)
    const errorCodes = getGraphqlResponseErrorCodes(response as Payload)

    const firstErrorCode = _.first(errorCodes)

    if (error || _.isNil(data)) {
      if (abortController?.signal) {
        log.debug('[Debug] Request aborted,', fnName)
      } else if (!ignoreError) {
        log.debug('[Debug] Failed to fetch,', query)
        log.debug('[Debug] Failed to fetch, variables', variables)
        reportGraphqlError(queryDomain)({ error, fnName, query, variables })
      }
    }
    return { ...(data || {}), error, code: firstErrorCode }
  }
}

const instance = new GraphqlApiService()

export default instance
