import { ReactElement } from 'react'

import { TOOLTIP_PLACEMENT } from 'constants/settings'

import { ButtonProps } from '../Button'
import { ActionButtonDropdownProps } from '../ActionButton'

import { SPLIT_BUTTON_VARIANTS } from './constants'
import scss from './index.module.scss'
import { StyledMainButton, StyledActionButton } from './style'

export type SplitButtonProps = ButtonProps &
  ActionButtonDropdownProps & { variant?: keyof typeof SPLIT_BUTTON_VARIANTS }

const SplitButton = ({
  variant = SPLIT_BUTTON_VARIANTS.primary,
  options,
  dpTheme,
  dpClassName,
  dpContainerClassName = '',
  dpPlacement,
  dpBody,
  onChange,
  icon,
  children,
  ...buttonProps
}: SplitButtonProps): ReactElement => (
  <div className={scss.splitBtnContainer}>
    <StyledMainButton {...buttonProps} variant={variant}>
      {children}
    </StyledMainButton>
    <StyledActionButton
      className={buttonProps.className}
      variant={variant}
      size={buttonProps.size}
      disabled={buttonProps.disabled}
      options={options}
      dpTheme={dpTheme}
      dpClassName={dpClassName}
      dpContainerClassName={`${scss.actionBtnContainer} ${dpContainerClassName}`}
      dpPlacement={dpPlacement || TOOLTIP_PLACEMENT.bottomRight}
      dpBody={dpBody}
      onChange={onChange}
      icon={icon}
    />
  </div>
)

export default SplitButton
