import React, { useMemo, useCallback } from 'react'

// constants
import { LAYER_VIS_CONFIGS } from 'components/map/layers/deckLayers/layerFactory'

// utils
import { getCurrentZoomLvlFromMapRef } from 'helpers/mapUtils'
import { useMapStateValue } from 'contexts'

// components
import { RangeSlider } from 'components/common'

const useLayerVisibilityControl = ({ zoomVisibilityRange, updateStyle }) => {
  const { mapRef } = useMapStateValue()
  const mapZoom = getCurrentZoomLvlFromMapRef(mapRef)

  const zoomMarker = useMemo(() => {
    if (!mapZoom) return undefined
    const roundedMapZoom = mapZoom.toFixed(1)

    return {
      [roundedMapZoom]: roundedMapZoom,
    }
  }, [mapZoom])

  const renderVisibilityControl = useCallback(() => {
    return (
      <RangeSlider
        title='Visibility'
        isSlider={false}
        step={LAYER_VIS_CONFIGS.zoomVisibilityRange.step}
        rangeValue={zoomVisibilityRange}
        defaultRangeValue={LAYER_VIS_CONFIGS.zoomVisibilityRange.range}
        onChange={val => updateStyle({ zoomVisibilityRange: val })}
        hideInput
        marks={zoomMarker}
        showDots
      />
    )
  }, [updateStyle, zoomMarker, zoomVisibilityRange])

  return { renderVisibilityControl }
}

export default useLayerVisibilityControl
