// libraries
import { ReactElement, ReactNode } from 'react'
import _ from 'lodash'

// components
import * as Icons from 'components/icons'
import { Tooltip } from 'components/common'
import { TOOLTIP_PLACEMENT } from 'constants/settings'
import { StyledWarningIconDiv } from './style'

const NodeWarningIcon = ({
  error,
}: {
  error: string | ReactNode
}): ReactElement => {
  const WarningIcon = _.get(Icons, 'AiFillWarning')
  return (
    <Tooltip
      placement={TOOLTIP_PLACEMENT.right}
      trigger={['hover']}
      overlay={<span>{error}</span>}
    >
      <StyledWarningIconDiv>
        <WarningIcon size={16} width={16} height={16} />
      </StyledWarningIconDiv>
    </Tooltip>
  )
}

export default NodeWarningIcon
