import { ReactElement } from 'react'

import type { ToggleFn } from 'types/common'

import { MdKeyboardArrowDown } from 'components/icons'

import scss from '../index.module.scss'

const FilterTrigger = ({
  title,
  labels,
  toggleDropdown,
}: {
  title: string
  labels: string
  toggleDropdown: ToggleFn
}): ReactElement => (
  <div
    className='d-flex align-items-center'
    type='button'
    onClick={toggleDropdown}
  >
    <span>{title}: </span>
    <span className={scss.buttonSelected}>{labels}</span>
    <MdKeyboardArrowDown className='ms-2' size={16} />
  </div>
)

export default FilterTrigger
