import { Dispatch } from 'react'

import type { SiteFeatureCategories, SiteUserPropertiesKeys } from 'types/sites'

export const SITE_INITIAL_LIST = 'SITE_INITIAL_LIST'
export const SITE_SET_LIST = 'SITE_SET_LIST'
export const SITE_SET_FILTERED_LIST = 'SITE_SET_FILTERED_LIST'
export const SITE_SET_CATEGORY = 'SITE_SET_CATEGORY'
export const SITE_SET_USER_PROPERTIES_KEYS = 'SITE_SET_USER_PROPERTIES_KEYS'

const SiteActions = ({
  dispatch,
}: {
  dispatch: Dispatch<{
    type: string
    category?: SiteFeatureCategories
    userPropertiesKeys?: SiteUserPropertiesKeys
  }>
}): {
  updateSiteFeatureCategory: Dispatch<SiteFeatureCategories>
  updateSiteFeatureUserPropertiesKeys: Dispatch<SiteUserPropertiesKeys>
} => {
  return {
    updateSiteFeatureCategory: (category: SiteFeatureCategories) => {
      dispatch({
        type: SITE_SET_CATEGORY,
        category,
      })
    },
    updateSiteFeatureUserPropertiesKeys: (
      userPropertiesKeys: SiteUserPropertiesKeys
    ) => {
      dispatch({
        type: SITE_SET_USER_PROPERTIES_KEYS,
        userPropertiesKeys,
      })
    },
  }
}

export default SiteActions
