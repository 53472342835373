import { useUpdateEffect } from 'react-use'
import _ from 'lodash'

import type { ListConditions } from 'types/common'
import { ENTITIES } from 'constants/common'
import { USER_PREFERENCES } from 'constants/settings'
import { omitStaleMethaneEntities } from 'helpers/utils'

import { useCurrentUser } from 'hooks'
import { ColumnOptions } from 'components/common/DataTable/useDataTableColumns'

/**
 * Watch for changes inside 'listConditions' and update the user record in DB
 * to preserve gallery configuration
 */
const useGalleryOptionsStorage = ({
  entity,
  listConditions: {
    listType,
    tableGroupedBy,
    sortField,
    ascOrder,
    measurementUnit,
  } = {},
  visibleColumns,
}: {
  entity: keyof typeof ENTITIES
  listConditions: ListConditions
  visibleColumns?: ColumnOptions[]
}): void => {
  const { currentUser, updateCurrentUserPreference } = useCurrentUser()
  const {
    preferences: { galleryOptions },
  } = currentUser

  useUpdateEffect(() => {
    const oldOptions = galleryOptions?.[entity]
    const newOptions = {
      listType,
      tableGroupedBy: tableGroupedBy || null,
      sortField: sortField || null,
      ascOrder: ascOrder || false,
      ...(measurementUnit && { measurementUnit }),
      ...(visibleColumns && {
        initialVisibleColumns: visibleColumns.map(({ field }) => field),
      }),
    }
    // To prevent unnecessary mutations
    if (!_.isEqual(oldOptions, newOptions)) {
      const payload = {
        preference: USER_PREFERENCES.galleryOptions,
        value: {
          ...omitStaleMethaneEntities(
            galleryOptions as Record<string, unknown>
          ),
          [entity]: newOptions,
        },
      }

      updateCurrentUserPreference(payload, USER_PREFERENCES.galleryOptions)
    }
  }, [
    listType,
    tableGroupedBy,
    sortField,
    ascOrder,
    visibleColumns,
    galleryOptions,
    updateCurrentUserPreference,
    entity,
    measurementUnit,
  ])
}

export default useGalleryOptionsStorage
