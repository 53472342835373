import moment from 'moment-timezone'
import _ from 'lodash'
import { DaysOptions, Repeat, WeekDaysType } from './types'

export const DAYS_IN_WEEK = [
  { value: 1, short: 'Su', label: 'Sunday' },
  { value: 2, short: 'M', label: 'Monday' },
  { value: 3, short: 'T', label: 'Tuesday' },
  { value: 4, short: 'W', label: 'Wednesday' },
  { value: 5, short: 'Th', label: 'Thursday' },
  { value: 6, short: 'F', label: 'Friday' },
  { value: 7, short: 'Sa', label: 'Saturday' },
]

export const WEEK_DAYS = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
]

export const ON_DAYS_IN_WEEK_OPTIONS = [
  ...DAYS_IN_WEEK.map(({ value, label }) => ({ value, label })),
  // { value: [2, 3, 4, 5, 6], label: 'Weekday' },
  // { value: [1, 7], label: 'Weekend Day' },
]

export const REPEAT_WEEKLY = 'REPEAT_WEEKLY'
export const REPEAT_MONTHLY = 'REPEAT_MONTHLY'
export const REPEAT_YEARLY = 'REPEAT_YEARLY'
export const REPEAT_DAILY = 'REPEAT_DAILY'
export const REPEAT_CUSTOM = 'REPEAT_CUSTOM'

export const REPEAT_MONTHLY_ON_DAY = 'REPEAT_MONTHLY_ON_DAY'
export const REPEAT_MONTHLY_ON_WEEK = 'REPEAT_MONTHLY_ON_WEEK'

export const REPEAT_MONTHLY_TYPE_FIELDS_DEFAULTS = {
  day: {
    monthlyOnDay: moment().day(),
    monthlyType: REPEAT_MONTHLY_ON_DAY,
  },
  week: {
    monthlyOnWeek: Math.ceil(moment().date() / 7),
    monthlyOnWeekWeekday: moment().format('dddd').toUpperCase(),
    monthlyType: REPEAT_MONTHLY_ON_WEEK,
  },
}

interface Map {
  [key: string]: string | number | null
}

export const REPEAT_FIELD_NAMES: Map = {
  REPEAT_DAILY: 'dailyIncrement',
  REPEAT_WEEKLY: 'weeklyIncrement',
  REPEAT_MONTHLY: 'monthlyIncrement',
  REPEAT_YEARLY: 'yearlyIncrement',
} as const

export const REPEAT_EVERY_TYPE: Map = {
  REPEAT_DAILY: 'Day',
  REPEAT_WEEKLY: 'Week',
  REPEAT_MONTHLY: 'Month',
  REPEAT_YEARLY: 'Year',
} as const

export const REPEAT_INTERVAL_INPUT_MAX: Map = {
  REPEAT_DAILY: 30,
  REPEAT_WEEKLY: null,
  REPEAT_MONTHLY: 12,
  REPEAT_YEARLY: 5,
} as const
interface RepeatEveryTypeDefaultsMap {
  [key: string]: Repeat
}

export const REPEAT_EVERY_TYPE_DEFAULTS: RepeatEveryTypeDefaultsMap = {
  REPEAT_DAILY: {
    dailyIncrement: 1,
    repeatType: 'REPEAT_DAILY',
  },
  REPEAT_WEEKLY: {
    repeatType: 'REPEAT_WEEKLY',
    weeklyWeekdays: [moment().format('dddd').toUpperCase() as WeekDaysType],
  },
  REPEAT_MONTHLY: {
    repeatType: 'REPEAT_MONTHLY',
    monthlyIncrement: 1,
    monthlyType: 'REPEAT_MONTHLY_ON_WEEK',
    monthlyOnWeek: Math.ceil(moment().date() / 7),
    monthlyOnWeekWeekday: moment().format('dddd').toUpperCase() as WeekDaysType,
  },
  REPEAT_YEARLY: {
    repeatType: 'REPEAT_YEARLY',
    yearlyIncrement: 1,
    yearlyOnDate: moment().format('YYYY-MM-DD'),
  },
}

export const REPEAT_TYPE_FIELDS = {
  REPEAT_DAILY: ['repeatType', 'runAtUtcTime', 'dailyIncrement'],
  REPEAT_WEEKLY: ['repeatType', 'runAtUtcTime', 'weeklyWeekdays'],
  REPEAT_MONTHLY_ON_DAY: [
    'repeatType',
    'runAtUtcTime',
    'monthlyIncrement',
    'monthlyType',
    'monthlyOnDay',
  ],
  REPEAT_MONTHLY_ON_WEEK: [
    'repeatType',
    'runAtUtcTime',
    'monthlyIncrement',
    'monthlyType',
    'monthlyOnWeek',
    'monthlyOnWeekWeekday',
  ],
  REPEAT_YEARLY: [
    'repeatType',
    'runAtUtcTime',
    'yearlyIncrement',
    'yearlyOnDate',
  ],
}
export const MONTHLY_TYPE = {
  REPEAT_MONTHLY_ON_DAY: 'day',
  REPEAT_MONTHLY_ON_WEEK: 'week',
}

export const MONTHLY_TYPE_VALUE = {
  day: REPEAT_MONTHLY_ON_DAY,
  week: REPEAT_MONTHLY_ON_WEEK,
}

export const DATEPICKER_MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const DATEPICKER_MONTHS_OPTIONS = _.map(DATEPICKER_MONTHS, value => {
  return {
    key: value,
    value,
  }
})

export const DAYS_OPTIONS: DaysOptions[] = _.map(
  DAYS_IN_WEEK,
  ({ value, short }) => ({
    children: short,
    value,
  })
)

export const YEARLY_ON_DATE_FORMAT = 'YYYY-MM-DD'
