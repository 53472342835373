import { CSSProperties } from 'react'
import { ColumnOptions } from './useDataTableColumns'

export const TABLE_HEADER_HEIGHT = 54
export const TABLE_ROW_HEIGHT = 49

/** Distance to the end of the page (in pixels) from which we should start to load more */
export const INFINITE_SCROLL_THRESHOLD = 100

export const ACTION_COLUMN_COMMON_OPTIONS = {
  header: '',
  field: undefined,
  sortable: false,
  resizeable: false,
}

export const BREAKABLE_TEXT_COLUMN_OPTIONS: Record<string, CSSProperties> = {
  headerStyle: { whiteSpace: 'normal' },
  bodyStyle: { whiteSpace: 'normal' },
}

const renderOrder: ColumnOptions['body'] = (_rowData, { rowIndex }) =>
  rowIndex + 1

export const ORDER_COLUMN = {
  field: 'order',
  header: '#',
  sortable: true,
  body: renderOrder,
  style: { maxWidth: 50 },
}
