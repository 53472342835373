import { ColourArray } from 'types/common'
import { THEMES } from 'constants/colour'
import keymirror from 'keymirror'

export const MAP_STYLE_TYPES = {
  light: 'mapbox://styles/mapbox/light-v9',
  dark: 'mapbox://styles/mapbox/dark-v9',
  streets: 'mapbox://styles/mapbox/streets-v10',
  satellite: 'mapbox://styles/mapbox/satellite-v9',
  suLight: 'mapbox://styles/sensorup/cjyuijx5o04nr1cmmmlz2quye',
  suDark: 'mapbox://styles/sensorup/cjyp9ojx203hq1co176iopvu4',
  suMonochrome: 'mapbox://styles/sensorup/cjzbkmtz01bxd1cpio3tiu0si',
  suSatellite: 'mapbox://styles/sensorup/cjzbkpi0b000k1cp9wkpewdao',
}

export const GEOMETRY_TYPES = keymirror({
  Point: null,
  LineString: null,
  Polygon: null,
  MultiPoint: null,
  MultiLineString: null,
  MultiPolygon: null,
  GeometryCollection: null,
})

export const GEOJSON_TYPES = {
  ...GEOMETRY_TYPES,
  Feature: 'Feature',
  FeatureCollection: 'FeatureCollection',
}

export const BASE_LAYER_TYPES = keymirror({
  asset: null,
})

/**
 * Support map layer types
 */
export const LAYER_TYPES = keymirror({
  // The Point Layer takes in paired latitude and longitude coordinated points
  // and renders them as circles with a certain radius.
  point: null,
  // The Icon Layer renders raster icons at given coordinates
  icon: null,
  // The Hexagon Layer renders a hexagon heatmap based on an array of points
  hexagon: null,
  // The UpPolygon Layer renders filled and/or stroked polygons
  upPolygon: null,
  // Different from the server-aggregated UpPolygon Layer, the Polygon Layer
  // renders client-side aggregated polygons. It assigns input points to
  // corresponding polygon and aggregates points according to user needs
  polygon: null,
  // The Heatmap Layer can be used to visualize spatial distribution of data.
  heatmap: null,
  // The GeoJson Layer takes in GeoJson formatted data and renders it as
  // interactive polygons, lines and points.GeoJsonLayer is a CompositeLayer
  upGeojson: null,
  // The Tile Layer takes in a function getTileData that fetches tiles, and
  // renders it in a GeoJsonLayer
  tile: null,
  arc: null,
  tile3D: null,
})

export const NON_AGGREGATION_LAYER_TYPES = [
  LAYER_TYPES.point,
  LAYER_TYPES.icon,
  LAYER_TYPES.upPolygon,
  LAYER_TYPES.upGeojson,
  LAYER_TYPES.arc,
]

/**
 * layer types that can use deck.gl DataFilterExtension
 */
export const DECK_GL_SUPPORTED_FILTER_LAYER_TYPES = [
  LAYER_TYPES.point,
  LAYER_TYPES.icon,
  LAYER_TYPES.upGeojson,
  LAYER_TYPES.upPolygon,
  LAYER_TYPES.heatmap,
  LAYER_TYPES.arc,
]

export const AGGREGATION_LAYER_TYPES = [
  LAYER_TYPES.hexagon,
  LAYER_TYPES.polygon,
  LAYER_TYPES.heatmap,
]

export const SUPPORT_CLUSTER_LAYER_TYPES = [
  LAYER_TYPES.point,
  LAYER_TYPES.icon,
  LAYER_TYPES.upGeojson,
]

export const MAPBOX_LIMITS = {
  minZoom: 0,
  maxZoom: 30,
  minPitch: 0,
  maxPitch: 60,
}

/**
 * Initial map viewport settings
 */
export const INITIAL_MAP_STATE = {
  ...MAPBOX_LIMITS,
  longitude: -100,
  latitude: 40,
  zoom: 3,
  pitch: 0, // Camera angle in degrees (0 is straight down). Default to `0`.
  bearing: 0, // Map rotation in degrees (0 means north is up). Default to `0`.
  width: 800,
  height: 800,
  isSplit: false,
}

export const DEFAULT_LAYER_TYPE = LAYER_TYPES.point

export const DEFAULT_MAP_COLOUR: ColourArray = [243, 185, 72, 255]

export const DEFAULT_MAP_HIGHLIGHT_COLOUR = [12, 70, 183, 255]

export const DEFAULT_MAP_ICON_BG_COLOUR = [241, 241, 241, 255]

export const DEFAULT_MAP_LABEL_COLOUR = [255, 255, 255, 255]

export const DEFAULT_MAP_COLOUR_RAMP = [
  [240, 249, 232],
  [204, 235, 197],
  [168, 221, 181],
  [123, 204, 196],
  [67, 162, 202],
  [8, 104, 172],
]
/**
 * Default colour ramp step
 */
export const DEFAULT_COLOUR_STEP = {
  minStep: 2,
  maxStep: 8,
}

/**
 * Mapbox supported style
 */
export const MAPBOX_DEFAULT_MAP_STYLES = [
  { label: 'Streets V10', value: MAP_STYLE_TYPES.streets, theme: THEMES.light },
  { label: 'Light V9', value: MAP_STYLE_TYPES.light, theme: THEMES.light },
  { label: 'Dark V9', value: MAP_STYLE_TYPES.dark, theme: THEMES.dark },
  {
    label: 'Satellite V9',
    value: MAP_STYLE_TYPES.satellite,
    theme: THEMES.dark,
  },
  {
    label: 'SensorUp Monochrome',
    value: MAP_STYLE_TYPES.suMonochrome,
    theme: THEMES.light,
  },
  {
    label: 'SensorUp Light',
    value: MAP_STYLE_TYPES.suLight,
    theme: THEMES.light,
  },
  { label: 'SensorUp Dark', value: MAP_STYLE_TYPES.suDark, theme: THEMES.dark },
  {
    label: 'SensorUp Satellite',
    value: MAP_STYLE_TYPES.suSatellite,
    theme: THEMES.dark,
  },
]

export const DEFAULT_MAP_THEME = THEMES.dark

export const DEFAULT_MAP_NAME = 'Untitled map'

export const DEFAULT_MAP_LAYER_NAME = 'Untitled layer'

export const MINIMUM_MAP_REFRESH_RATE = 1000

export const DEFAULT_LIVE_DATASET_REFRESH_RATE = 30 * 1000

export const MAP_SCREENSHOT_WIDTH = 268

export const MAP_SCREENSHOT_HEIGHT = 150

/**
 * The number of milliseconds to delay.
 */
export const MAP_UPDATE_DELAY_MS = 150

export const MAP_LABEL_TEXT_ANCHOR = [
  { value: 'start', label: 'start' },
  { value: 'middle', label: 'middle' },
  { value: 'end', label: 'end' },
]

export const MAP_LABEL_TEXT_ALIGNMENT_BASELINE = [
  { value: 'top', label: 'top' },
  { value: 'center', label: 'center' },
  { value: 'bottom', label: 'bottom' },
]

export const MAP_TEMPORAL_SETTING_KEY = 'cache'

export const POLYGONS_WITH_BBOX_KEY = 'polygonsWithBbox'

export const MAP_VIEW_STATE_ACTIONS = keymirror({ save: null, delete: null })

export const GEOMETRY_TYPE_SUPPORTED_LAYER_TYPE_MAPPING = {
  [GEOMETRY_TYPES.Point]: [
    LAYER_TYPES.point,
    LAYER_TYPES.icon,
    LAYER_TYPES.hexagon,
    LAYER_TYPES.polygon,
    LAYER_TYPES.heatmap,
    LAYER_TYPES.upGeojson,
  ],
  [GEOMETRY_TYPES.MultiPoint]: [LAYER_TYPES.arc, LAYER_TYPES.upGeojson],
  [GEOMETRY_TYPES.LineString]: [LAYER_TYPES.arc, LAYER_TYPES.upGeojson],
  [GEOMETRY_TYPES.MultiLineString]: [LAYER_TYPES.upGeojson],
  [GEOMETRY_TYPES.Polygon]: [LAYER_TYPES.upPolygon, LAYER_TYPES.upGeojson],
  [GEOMETRY_TYPES.MultiPolygon]: [
    LAYER_TYPES.arc,
    LAYER_TYPES.upPolygon,
    LAYER_TYPES.upGeojson,
  ],
  [GEOMETRY_TYPES.GeometryCollection]: [LAYER_TYPES.upGeojson],
}

export const MULTI_LABELS_LAYER_TYPES = [LAYER_TYPES.point, LAYER_TYPES.icon]

export const CLUSTERING_LAYER_SIZE_SCALE = 40
