import {
  useContext,
  createContext,
  useMemo,
  ReactElement,
  PropsWithChildren,
} from 'react'

import type { EmissionRateUnit } from 'app/MissionControlMethaneSolution/types/detection'

export type DetectionGalleryContextValue = {
  measurementUnit?: EmissionRateUnit
}

export const DetectionGalleryContext =
  createContext<DetectionGalleryContextValue>({})

export const DetectionGalleryContextProvider = ({
  measurementUnit,
  children,
}: PropsWithChildren<{
  measurementUnit?: EmissionRateUnit
}>): ReactElement => {
  const contextValue = useMemo(() => ({ measurementUnit }), [measurementUnit])

  return (
    <DetectionGalleryContext.Provider value={contextValue}>
      {children}
    </DetectionGalleryContext.Provider>
  )
}

export const useDetectionGalleryStateValue = (): DetectionGalleryContextValue =>
  useContext(DetectionGalleryContext)
