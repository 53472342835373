// libraries
import { css, Theme } from '@emotion/react'
import styled from '@emotion/styled'

export const StyledListItem = styled.li<{
  theme?: Theme
  bgColour: boolean
  active: boolean
}>`
  border: none;
  border-radius: 4px;
  padding: 0px 3px;
  color: ${props => (props.active ? props.theme.primary : '#000000')};
  font-weight: ${props => (props.active ? 600 : 'normal')};

  ${({ bgColour, theme, active }) =>
    bgColour
      ? css`
          background-color: ${theme.primary};
          color: #fff;
        `
      : css`
          background-color: ${active
            ? theme['secondary-light-500']
            : '#f3f3f3'};

          &:hover,
          &:focus,
          &:active,
          &:disabled {
            background-color: ${theme['secondary-light-500']};
            color: ${active ? theme.primary : '#000000'};
          }
        `};
`

export const StyledSpan = styled.span<{
  theme?: Theme
  active: boolean
}>`
  ${({ theme, active }) =>
    active
      ? css`
          background-color: ${theme.primary};
        `
      : css`
          background-color: transparent;
        `};
`
