import { ReactElement } from 'react'

import type { Asset } from 'types/asset'

// components
import DisplayImagesGallery, {
  DisplayImagesGalleryProp,
} from './DisplayImagesGallery'

type DisplayImagesFromAssetProp = Omit<
  DisplayImagesGalleryProp,
  'properties' | 'propertiesMetadata'
> & {
  value?: Asset
}

const DisplayImagesFromAsset = ({
  value,
  ...rest
}: DisplayImagesFromAssetProp): ReactElement => {
  const { properties } = value || {}
  const { properties: propertiesMetadata } = value?.assetProfile || {}
  return (
    <DisplayImagesGallery
      {...rest}
      properties={properties}
      propertiesMetadata={propertiesMetadata}
    />
  )
}

export default DisplayImagesFromAsset
