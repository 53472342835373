// libraries
import React from 'react'
import PropTypes from 'prop-types'
import { useToggle } from 'react-use'
import _ from 'lodash'

// constants
import {
  DEFAULT_REALTIME_RANGE,
  WIDGET_TYPES,
  DEFAULT_WIDGET_COLOUR,
  DEFAULT_WIDGET_SCATTER_SYMBOL,
  DEFAULT_WIDGET_SCATTER_SYMBOL_RANGE,
  DEFAULT_HIDE_VALUES_OUTSIDE_OF_VALUE_RANGE,
} from 'constants/widget'
import { PROPERTY_VARIABLE_TYPES } from 'constants/filter'
import { DATE_UNIT_TYPES } from 'constants/datetime'

// utils
import {
  switchcase,
  toLowerCase,
  toUpperCase,
  isRangeValid,
} from 'helpers/utils'
import {
  isNumericType,
  isTimePropertyFromPropertyOptionAndValue,
} from 'helpers/filter'
import { useUpdateWidgetSettings } from 'hooks'

// components
import {
  PropertyPicker,
  TitleWithTooltip,
  MinMaxRange,
  RangeSlider,
  HideValueToggle,
} from 'components/common'
import ColourSetting from 'components/map/layers/widgets/common/ColourSetting'
import TimeRangeSetting from 'components/map/layers/widgets/common/TimeRangeSetting'

const widgetType = WIDGET_TYPES.scatter

export const ScatterWidgetConfig = ({
  settings,
  propertyOptions,
  onChange,
  isLayerDatasetLive,
}) => {
  const {
    xAxisPropertyName = '',
    yAxisPropertyName = '',
    xAxisPropertyType = '',
    yAxisPropertyType = '',
    symbolSize = DEFAULT_WIDGET_SCATTER_SYMBOL,
    colour = DEFAULT_WIDGET_COLOUR,
    isSingleColour = true,
    xAxisRange = [],
    yAxisRange = [],
    isXAxisTimeRangeEnabled = true,
    xAxisIntervalRange = DEFAULT_REALTIME_RANGE,
    xAxisIntervalUnit = DATE_UNIT_TYPES.minutes,
    isYAxisTimeRangeEnabled = true,
    yAxisIntervalRange = DEFAULT_REALTIME_RANGE,
    yAxisIntervalUnit = DATE_UNIT_TYPES.minutes,
    hideValuesOutsideOfXAxisRange = DEFAULT_HIDE_VALUES_OUTSIDE_OF_VALUE_RANGE,
    hideValuesOutsideOfYAxisRange = DEFAULT_HIDE_VALUES_OUTSIDE_OF_VALUE_RANGE,
    hideInvalidValuesOnXAxis = false,
    hideInvalidValuesOnYAxis = false,
  } = settings

  const [isXAxisRangeVisible, toggleXAxisRangeVisible] = useToggle(
    isNumericType(xAxisPropertyType)
  )

  const [isXAxisTimeRangeVisible, toggleXAxisTimeRangeVisible] = useToggle(
    isTimePropertyFromPropertyOptionAndValue(xAxisPropertyName, propertyOptions)
  )

  const [isYAxisRangeVisible, toggleYAxisRangeVisible] = useToggle(
    isNumericType(yAxisPropertyType)
  )

  const [isYAxisTimeRangeVisible, toggleYAxisTimeRangeVisible] = useToggle(
    isTimePropertyFromPropertyOptionAndValue(yAxisPropertyName, propertyOptions)
  )

  const mapTimeRangeSettingKey = (key, axis = 'x') => {
    const axisInLowerCase = toLowerCase(axis)
    const axisInUpperCase = toUpperCase(axis)
    return switchcase({
      intervalRange: `${axisInLowerCase}AxisIntervalRange`,
      intervalUnit: `${axisInLowerCase}AxisIntervalUnit`,
      enabled: `is${axisInUpperCase}AxisTimeRangeEnabled`,
    })('')(key)
  }

  const onAxisTimeRangeSettingChange = (payload, axis) => {
    const mappedPayload = _.mapKeys(payload, (_value, key) => {
      return mapTimeRangeSettingKey(key, axis)
    })
    onChange(mappedPayload)
  }

  const xAxisPropertyChange = ({ value, type }) => {
    onChange({
      xAxisPropertyName: value,
      xAxisPropertyType: type,
      xAxisRange: [],
      hideValuesOutsideOfXAxisRange: DEFAULT_HIDE_VALUES_OUTSIDE_OF_VALUE_RANGE,
    })

    toggleXAxisRangeVisible(isNumericType(type))
    toggleXAxisTimeRangeVisible(
      isTimePropertyFromPropertyOptionAndValue(value, propertyOptions)
    )
  }

  const yAxisPropertyChange = ({ value, type }) => {
    onChange({
      yAxisPropertyName: value,
      yAxisPropertyType: type,
      yAxisRange: [],
      hideValuesOutsideOfYAxisRange: DEFAULT_HIDE_VALUES_OUTSIDE_OF_VALUE_RANGE,
    })
    toggleYAxisRangeVisible(isNumericType(type))
    toggleYAxisTimeRangeVisible(
      isTimePropertyFromPropertyOptionAndValue(
        value,
        isTimePropertyFromPropertyOptionAndValue(value, propertyOptions)
      )
    )
  }

  return (
    <>
      <div className='groupOption'>
        <div className='groupOptionContent'>
          <TitleWithTooltip title='X-Axis Value' tooltip='The value to plot' />
          <PropertyPicker
            property={xAxisPropertyName}
            onChange={xAxisPropertyChange}
            propertyTypes={[
              PROPERTY_VARIABLE_TYPES.number,
              PROPERTY_VARIABLE_TYPES.string,
              PROPERTY_VARIABLE_TYPES.boolean,
            ]}
            propertyOptions={propertyOptions}
            isMulti={false}
            isClearable={false}
          />
          {isXAxisRangeVisible ? (
            <>
              <MinMaxRange
                className='mt-1'
                minValue={xAxisRange[0]}
                maxValue={xAxisRange[1]}
                isClearable
                onChange={val => onChange({ xAxisRange: val })}
              />
              <HideValueToggle
                isValid={isRangeValid(xAxisRange)}
                hideValue={hideValuesOutsideOfXAxisRange}
                onChange={onChange}
                onChangeValueKey='hideValuesOutsideOfXAxisRange'
              />
            </>
          ) : (
            <HideValueToggle
              label='Hide empty values'
              hideValue={hideInvalidValuesOnXAxis}
              onChange={onChange}
              onChangeValueKey='hideInvalidValuesOnXAxis'
            />
          )}
        </div>
        {isLayerDatasetLive && isXAxisTimeRangeVisible && (
          <TimeRangeSetting
            intervalRange={xAxisIntervalRange}
            intervalUnit={xAxisIntervalUnit}
            enabled={isXAxisTimeRangeEnabled}
            onChange={payload => onAxisTimeRangeSettingChange(payload, 'x')}
          />
        )}
      </div>
      <div className='groupOption'>
        <div className='groupOptionContent'>
          <TitleWithTooltip title='Y-Axis Value' tooltip='The value to plot' />
          <PropertyPicker
            property={yAxisPropertyName}
            onChange={yAxisPropertyChange}
            propertyTypes={[
              PROPERTY_VARIABLE_TYPES.number,
              PROPERTY_VARIABLE_TYPES.string,
              PROPERTY_VARIABLE_TYPES.boolean,
            ]}
            propertyOptions={propertyOptions}
            isMulti={false}
            isClearable={false}
          />
          {isYAxisRangeVisible ? (
            <>
              <MinMaxRange
                className='mt-1'
                minValue={yAxisRange[0]}
                maxValue={yAxisRange[1]}
                isClearable
                onChange={val => onChange({ yAxisRange: val })}
              />
              <HideValueToggle
                isValid={isRangeValid(yAxisRange)}
                hideValue={hideValuesOutsideOfYAxisRange}
                onChange={onChange}
                onChangeValueKey='hideValuesOutsideOfYAxisRange'
              />
            </>
          ) : (
            <HideValueToggle
              label='Hide empty values'
              hideValue={hideInvalidValuesOnYAxis}
              onChange={onChange}
              onChangeValueKey='hideInvalidValuesOnYAxis'
            />
          )}
        </div>
        {isLayerDatasetLive && isYAxisTimeRangeVisible && (
          <TimeRangeSetting
            intervalRange={yAxisIntervalRange}
            intervalUnit={yAxisIntervalUnit}
            enabled={isYAxisTimeRangeEnabled}
            onChange={payload => onAxisTimeRangeSettingChange(payload, 'y')}
            axis='y'
          />
        )}
      </div>
      <RangeSlider
        title='Symbol Size'
        value={symbolSize}
        range={DEFAULT_WIDGET_SCATTER_SYMBOL_RANGE}
        onChange={val => onChange({ symbolSize: val })}
        changeable
      />
      <ColourSetting
        isSingleColour={isSingleColour}
        colour={colour}
        updateSettings={onChange}
      />
    </>
  )
}

ScatterWidgetConfig.propTypes = {
  settings: PropTypes.shape({
    xAxisPropertyName: PropTypes.string,
    yAxisPropertyName: PropTypes.string,
    xAxisPropertyType: PropTypes.string,
    yAxisPropertyType: PropTypes.string,
    symbolSize: PropTypes.number,
    colour: PropTypes.arrayOf(PropTypes.number),
    isSingleColour: PropTypes.bool,
    xAxisRange: PropTypes.arrayOf(PropTypes.number),
    yAxisRange: PropTypes.arrayOf(PropTypes.number),
    isXAxisTimeRangeEnabled: PropTypes.bool,
    xAxisIntervalRange: PropTypes.number,
    xAxisIntervalUnit: PropTypes.string,
    isYAxisTimeRangeEnabled: PropTypes.bool,
    yAxisIntervalRange: PropTypes.number,
    yAxisIntervalUnit: PropTypes.string,
    hideValuesOutsideOfXAxisRange: PropTypes.bool,
    hideValuesOutsideOfYAxisRange: PropTypes.bool,
    hideInvalidValuesOnXAxis: PropTypes.bool,
    hideInvalidValuesOnYAxis: PropTypes.bool,
  }).isRequired,
  propertyOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired,
  isLayerDatasetLive: PropTypes.bool,
}

ScatterWidgetConfig.defaultProps = {
  isLayerDatasetLive: false,
}

const ScatterWidgetTab = ({
  settings,
  propertyOptions,
  updateWidgetSettings,
  isLayerDatasetLive,
}) => {
  settings[widgetType] = settings[widgetType] || {}

  const currentSetting = settings[widgetType]

  const { updateWidget } = useUpdateWidgetSettings({
    widgetType,
    settings,
    updateWidgetSettings,
  })

  return (
    <>
      <h6 className='tabTitle d-flex justify-content-between'>
        <div>Scatter Chart</div>
      </h6>
      <p className='tabDescription'>
        Select two data fields to include in your scatter chart
      </p>
      <ScatterWidgetConfig
        settings={currentSetting}
        onChange={updateWidget}
        propertyOptions={propertyOptions}
        isLayerDatasetLive={isLayerDatasetLive}
      />
    </>
  )
}

ScatterWidgetTab.propTypes = {
  settings: PropTypes.shape({}),
  propertyOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateWidgetSettings: PropTypes.func.isRequired,
  isLayerDatasetLive: PropTypes.bool,
}

ScatterWidgetTab.defaultProps = {
  settings: {},
  isLayerDatasetLive: false,
}

export default ScatterWidgetTab
