// libraries
import _ from 'lodash'
import moment from 'moment-timezone'
import isEqual from 'fast-deep-equal'
import * as Comlink from 'comlink'

// constants
import { AGGREGATION_TYPES } from 'constants/aggregation'
import { PROPERTY_TIME } from 'constants/common'
import {
  DEFAULT_MAX_CHART_GRID_OPTIONS,
  DEFAULT_MIN_CHART_GRID_OPTIONS,
  DEFAULT_WIDGET_COLOUR_PALETTE,
  ECHARTS_AXIS_TYPE,
} from 'constants/widget'
import { MDColours } from 'constants/colour'
import { PROPERTY_VARIABLE_TYPES } from 'constants/filter'
import { DATE_HOUR_MINUTE_SECOND_FORMAT_STANDARD } from 'constants/datetime'

// utils
import {
  switchcase,
  displayTime,
  isCountAggregation,
  isRangeValid,
  isInvalidValue,
} from 'helpers/utils'
import { colourArrToRgbaStr } from 'helpers/colour'
import getAggregationValues from 'components/map/layers/deckLayers/dataAggregation'
import log from 'helpers/log'

// web worker
/* eslint-disable import/no-webpack-loader-syntax,import/no-unresolved */
import Worker from 'workerize-loader!./workers/widget'

const {
  getSortedFeaturesValuesByProperty,
  getSeriesFeaturesDataForEcharts,
  getSeriesGroupDataForEcharts,
  getDatasetFeaturesDataForEcharts,
  getGroupedValuesByProperty,
  getGroupedValuesByTimeBucket,
  getGroupedValuesByPropertySplitByProperty,
  getGroupedValuesByTimeBucketSplitByProperty,
} = Comlink.wrap(new Worker())

export {
  getSortedFeaturesValuesByProperty,
  getSeriesFeaturesDataForEcharts,
  getSeriesGroupDataForEcharts,
  getDatasetFeaturesDataForEcharts,
  getGroupedValuesByProperty,
  getGroupedValuesByTimeBucket,
  getGroupedValuesByPropertySplitByProperty,
  getGroupedValuesByTimeBucketSplitByProperty,
}

// https://www.echartsjs.com/en/option.html#xAxis.type
export const getAxisType = type => {
  return switchcase({
    [PROPERTY_VARIABLE_TYPES.string]: ECHARTS_AXIS_TYPE.category,
    [PROPERTY_VARIABLE_TYPES.number]: ECHARTS_AXIS_TYPE.value,
    [PROPERTY_VARIABLE_TYPES.boolean]: ECHARTS_AXIS_TYPE.category,
  })(ECHARTS_AXIS_TYPE.category)(type)
}

export const getAxisMinTimestamp = ({
  geojsonRows,
  axisIntervalRange,
  axisIntervalUnit,
}) => {
  // get the max timestamp from the batch data
  const timeValuePath = PROPERTY_TIME
  const maxTimeStamp = _.get(_.maxBy(geojsonRows, timeValuePath), timeValuePath)

  if (!maxTimeStamp) return null

  return moment
    .utc(maxTimeStamp)
    .subtract(axisIntervalRange, axisIntervalUnit)
    .toISOString()
}

const inRange = (value, range) => {
  if (!isRangeValid(range)) return true

  return value > range[0] && value <= range[1]
}

export const getFlattenedDataForScatter = ({
  geojsonRows,
  minTimeStamp,
  xAxisPropertyName,
  yAxisPropertyName,
  timezone,
  xAxisRange,
  yAxisRange,
  hideInvalidValuesOnXAxis,
  hideInvalidValuesOnYAxis,
}) => {
  return geojsonRows.reduce((acc, cur) => {
    if (!minTimeStamp || cur.properties.time > minTimeStamp) {
      if (
        !inRange(cur.properties[xAxisPropertyName], xAxisRange) ||
        !inRange(cur.properties[yAxisPropertyName], yAxisRange)
      )
        return acc

      if (
        _.isObject(cur.properties[xAxisPropertyName]) ||
        _.isObject(cur.properties[yAxisPropertyName])
      )
        return acc

      if (
        (hideInvalidValuesOnXAxis &&
          isInvalidValue(cur.properties[xAxisPropertyName])) ||
        (hideInvalidValuesOnYAxis &&
          isInvalidValue(cur.properties[yAxisPropertyName]))
      )
        return acc

      const newProperties = {
        [xAxisPropertyName]: _.get(
          cur.properties,
          [xAxisPropertyName],
          undefined
        ),
        [yAxisPropertyName]: _.get(
          cur.properties,
          [yAxisPropertyName],
          undefined
        ),
        time: cur.properties.time,
      }

      acc.push({
        ...(timezone
          ? {
              time: displayTime({
                datetime: cur.properties.time,
                timezone,
                timeFormat: DATE_HOUR_MINUTE_SECOND_FORMAT_STANDARD,
              }),
            }
          : {}),
        ...newProperties,
      })
    }
    return acc
  }, [])
}

export const getDatasetSourceForScatter = ({
  geojsonRows,
  isXAxisTimeRangeEnabled,
  xAxisIntervalRange,
  xAxisIntervalUnit,
  isYAxisTimeRangeEnabled,
  yAxisIntervalRange,
  yAxisIntervalUnit,
  xAxisPropertyName,
  yAxisPropertyName,
  timezone,
  xAxisRange,
  yAxisRange,
  hideInvalidValuesOnXAxis,
  hideInvalidValuesOnYAxis,
}) => {
  if (_.isEmpty(geojsonRows) || !xAxisPropertyName || !yAxisPropertyName) {
    log.debug('Scatter chart has missing attributes', {
      geojsonRows,
      xAxisPropertyName,
      yAxisPropertyName,
    })
    return {}
  }

  const xAxisMin = isXAxisTimeRangeEnabled
    ? getAxisMinTimestamp({
        geojsonRows,
        axisIntervalRange: xAxisIntervalRange,
        axisIntervalUnit: xAxisIntervalUnit,
      })
    : null
  const yAxisMin = isYAxisTimeRangeEnabled
    ? getAxisMinTimestamp({
        geojsonRows,
        axisIntervalRange: yAxisIntervalRange,
        axisIntervalUnit: yAxisIntervalUnit,
      })
    : null

  const minTimeStamp = _.min([xAxisMin, yAxisMin])

  return {
    source: getFlattenedDataForScatter({
      geojsonRows,
      minTimeStamp,
      xAxisPropertyName,
      yAxisPropertyName,
      timezone,
      xAxisRange,
      yAxisRange,
      hideInvalidValuesOnXAxis,
      hideInvalidValuesOnYAxis,
    }),
  }
}

export const getSimpleAggregatedResultValueLabel = ({
  geojsonRows,
  aggregation,
  overridePropertyTitle,
  colour,
}) => {
  const { key, type: aggregationType } = aggregation

  const isCount = isCountAggregation(aggregationType) || !key

  if (
    !aggregationType ||
    (aggregationType !== AGGREGATION_TYPES.count && !key)
  ) {
    return {}
  }

  const label = _.isEmpty(overridePropertyTitle)
    ? isCount
      ? AGGREGATION_TYPES.count
      : `${aggregationType} ${key}`
    : overridePropertyTitle

  const widgetColour = colourArrToRgbaStr(colour)

  if (_.isEmpty(geojsonRows)) return { value: undefined, label, widgetColour }

  const rawRata = key
    ? _(geojsonRows)
        .map(`properties.${key}`)
        .without(false, undefined, '', null, NaN)
        .value()
    : geojsonRows

  const value = isCount
    ? geojsonRows.length
    : getAggregationValues(rawRata, aggregationType)

  return { value, label, widgetColour }
}

export const getWidgetColour = ({ isSingleColour, colour, series }) => {
  return isSingleColour || _.isNil(isSingleColour)
    ? [colourArrToRgbaStr(colour)]
    : series.length > DEFAULT_WIDGET_COLOUR_PALETTE.length
    ? [
        ...DEFAULT_WIDGET_COLOUR_PALETTE,
        ..._.sampleSize(
          MDColours,
          series.length - DEFAULT_WIDGET_COLOUR_PALETTE.length
        ),
      ]
    : DEFAULT_WIDGET_COLOUR_PALETTE
}

export const getMemoizedWidgetColour = _.memoize(
  getWidgetColour,
  ({ series, colour, isSingleColour }) =>
    `${series.length}${colour}${isSingleColour}`
)

export const isSelectedWidgetActive = (id, maxWidgetSelected) => {
  const selectedId = _.get(maxWidgetSelected, 'widgetProps.id')
  return isEqual(id, selectedId)
}

export const getGaugeValueRange = (yAxisRange, data) => {
  return !_.isEmpty(yAxisRange)
    ? yAxisRange
    : data === 0
    ? [-50, 50]
    : [_.floor(data / 2), _.floor(data * 2)]
}

export const getWidgetGrid = ({ widgetGrid, expanded }) =>
  widgetGrid || expanded
    ? DEFAULT_MAX_CHART_GRID_OPTIONS
    : DEFAULT_MIN_CHART_GRID_OPTIONS
