import { ReactElement } from 'react'
import { ArrayFieldTemplateProps, getTemplate, getUiOptions } from '@rjsf/utils'

// components
import { Button } from 'components/common'
import {
  BUTTON_ICON_POSITIONS,
  BUTTON_VARIANTS,
} from 'components/common/Button'

import scss from './index.module.scss'

const ArrayFieldTemplate = (props: ArrayFieldTemplateProps): ReactElement => {
  const {
    idSchema,
    uiSchema,
    registry,
    schema,
    title,
    onAddClick,
    canAdd,
    items,
  } = props
  const uiOptions = getUiOptions(uiSchema)
  const ArrayFieldDescriptionTemplate =
    getTemplate<'ArrayFieldDescriptionTemplate'>(
      'ArrayFieldDescriptionTemplate',
      registry,
      uiOptions
    )

  const ArrayFieldItemTemplate = getTemplate<'ArrayFieldItemTemplate'>(
    'ArrayFieldItemTemplate',
    registry,
    uiOptions
  )

  return (
    <div>
      <div className={scss.arrayFieldTitle}>{title}</div>

      <ArrayFieldDescriptionTemplate
        idSchema={idSchema}
        description={uiOptions.description || schema.description}
        schema={schema}
        uiSchema={uiSchema}
        registry={registry}
      />

      <div>
        {items &&
          items.map(({ key, ...itemProps }) => (
            <ArrayFieldItemTemplate key={key} {...itemProps} />
          ))}
      </div>

      {canAdd && (
        <Button
          variant={BUTTON_VARIANTS.secondary}
          iconPosition={BUTTON_ICON_POSITIONS.left}
          icon='AiOutlinePlus'
          onClick={onAddClick}
        >
          Add{items.length > 0 ? ' another' : ''}
        </Button>
      )}
    </div>
  )
}

export default ArrayFieldTemplate
