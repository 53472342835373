// libraries
import _ from 'lodash'

// constants
import { LAYER_VIS_CONFIGS } from 'components/map/layers/deckLayers/layerFactory'

// helpers
import { getDefaultLayerStyle } from 'helpers/layerStyle'
import { excludeElevation } from 'helpers/geojson'

export const LAYER_DEFAULT_STYLE = {
  elevationExcluded: LAYER_VIS_CONFIGS.elevationExcluded.defaultValue,
  opacity: LAYER_VIS_CONFIGS.opacity.defaultValue,
  fillColour: LAYER_VIS_CONFIGS.fillColour.defaultValue,
  bgColour: LAYER_VIS_CONFIGS.bgColour.defaultValue,
  radius: LAYER_VIS_CONFIGS.radius.defaultValue,
  iconPosition: LAYER_VIS_CONFIGS.iconPosition.defaultValue,
  headingProperty: LAYER_VIS_CONFIGS.headingProperty.defaultValue,
}

export const getIconLayerData = ({ layer, filteredDataState }) => {
  const { cachedMapLayerData, filteredDataWithoutTimeFilter } =
    filteredDataState
  if (cachedMapLayerData) return cachedMapLayerData
  if (!filteredDataWithoutTimeFilter) return []

  const { type, style } = layer
  const { elevationExcluded } = getDefaultLayerStyle(
    style[type],
    LAYER_DEFAULT_STYLE
  )

  return elevationExcluded
    ? _.map(filteredDataWithoutTimeFilter, excludeElevation)
    : filteredDataWithoutTimeFilter
}
