import { useCallback, useState, useEffect, useMemo, ReactElement } from 'react'
import _ from 'lodash'

// constants
import {
  ASSET_PROFILE_RELATIONSHIP_TYPES,
  ASSET_PROFILE_MEDIA_TYPES,
} from 'constants/assets'
import { THEMES } from 'constants/colour'
import { IMAGE_GALLERY_TYPES } from 'constants/common'

import type { AssetWidgetSettings } from 'types/asset'
import type { ThemeType } from 'types/common'

// components
import { Badge } from 'components/common'
import { BaseWidgetWithAssetState } from 'components/assets/assetsProfile/widgets/BaseWidget'
import DisplayImagesFromRelatedAssets from './DisplayImagesFromRelatedAssets'
import DisplayImagesFromRelatedAsset from './DisplayImagesFromRelatedAsset'
import DisplayImagesFromAsset from './DisplayImagesFromAsset'

import scss from './index.module.scss'

const AssetImageGalleryWidget = ({
  assetId,
  settings,
  name,
  theme = THEMES.dark,
  mediaType = ASSET_PROFILE_MEDIA_TYPES.WEB,
  onWidgetLoad = _.noop,
}: {
  assetId: string
  settings: AssetWidgetSettings
  name: string
  theme?: ThemeType
  mediaType?: string
  onWidgetLoad?: (id: string) => void
}): ReactElement => {
  const [header, setHeader] = useState<ReactElement | string>(name)
  const [imageCount, setImageCount] = useState(0)

  useEffect(() => {
    setHeader(
      <>
        {name}
        {imageCount > 0 && <Badge content={imageCount} className='ms-2' />}
      </>
    )
  }, [name, imageCount])

  const isPrintable = useMemo(
    () => mediaType === ASSET_PROFILE_MEDIA_TYPES.PRINTABLE,
    [mediaType]
  )

  const render = useCallback(
    props => {
      const {
        properties: visibleProperties = [],
        relatedAssetRelationshipId,
        relatedAssetRelationshipType = ASSET_PROFILE_RELATIONSHIP_TYPES.MANY,
        titleProperty,
        subtitleProperty,
        type = IMAGE_GALLERY_TYPES.grid,
      } = settings

      const sharedProps = {
        ...props,
        type,
        visibleProperties,
        titleProperty,
        subtitleProperty,
        theme,
        mediaType,
        onWidgetLoad,
        setImageCount,
      }

      const isAssetRelationshipMany =
        relatedAssetRelationshipType === ASSET_PROFILE_RELATIONSHIP_TYPES.MANY

      return relatedAssetRelationshipId ? (
        isAssetRelationshipMany ? (
          <DisplayImagesFromRelatedAssets
            {...sharedProps}
            relatedAssetRelationshipId={relatedAssetRelationshipId}
          />
        ) : (
          <DisplayImagesFromRelatedAsset
            {...sharedProps}
            relatedAssetRelationshipId={relatedAssetRelationshipId}
          />
        )
      ) : (
        <DisplayImagesFromAsset {...sharedProps} />
      )
    },
    [settings, theme, mediaType, onWidgetLoad]
  )

  return (
    <BaseWidgetWithAssetState
      assetId={assetId}
      settings={settings}
      render={render}
      className={scss.gallery}
      name={header}
      theme={theme}
      forceScroll={isPrintable}
      isPrintable={isPrintable}
    />
  )
}

export default AssetImageGalleryWidget
