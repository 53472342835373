import { ReactElement, ChangeEvent } from 'react'
import { WidgetProps } from '@rjsf/core'

import WidgetWrapper from 'components/common/JsonForm/WidgetWrapper'

import type { Option, Options } from 'types/common'
import Radio from './Radio'
import scss from './index.module.scss'

const RadioWidget = ({
  schema,
  label,
  id,
  name,
  disabled,
  options,
  value,
  required,
  rawErrors,
  onChange,
  formContext: { isPreview },
}: WidgetProps): ReactElement => {
  const { enumOptions = [] } = options

  const handleChange =
    (option: Option) =>
    ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
      onChange(option.value.toString())
    }

  return (
    <WidgetWrapper
      name={name}
      label={label || schema.title}
      description={schema.description}
      required={required}
      rawErrors={rawErrors}
      isLarge={isPreview}
    >
      {(enumOptions as Options).map((option: Option, index: number) => {
        const selected = value === option.value

        return (
          <Radio
            key={`${id}_${option.value}`}
            id={`${id}_${index}`}
            containerClassName={scss.radioContainer}
            selected={selected}
            disabled={disabled}
            label={option.label}
            reverseLabel
            onChange={handleChange(option)}
          />
        )
      })}
    </WidgetWrapper>
  )
}

export default RadioWidget
