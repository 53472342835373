import React from 'react'
import { BaseControl } from 'react-map-gl'
import { MdRefresh } from 'react-icons/md'

export default class ResetMapControl extends BaseControl {
  _render() {
    const { resetViewState } = this.props

    return (
      <div
        className='mapboxgl-ctrl mapboxgl-ctrl-group'
        ref={this._containerRef}
      >
        <button
          title='Reset Map'
          key='resetMap'
          type='button'
          className='mapboxgl-ctrl-icon '
          onClick={() => resetViewState()}
        >
          <MdRefresh />
        </button>
      </div>
    )
  }
}
