// libraries
import { useMemo, useCallback } from 'react'
import { FiArrowLeft } from 'react-icons/fi'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

// constants
import { URLS } from 'constants/route'
import { FEATURES } from 'constants/settings'
import { AVATAR_SIZES } from 'constants/user'
import { DISPLAY_ISSUE_IN_MAP } from 'constants/common'

// utils
import { getRouteUrlWithValues } from 'helpers/utils'
import { useMapStateValue } from 'contexts'
import { useFeatureFlag, usePlugins } from 'hooks'

// components
import {
  Button,
  Avatar,
  MapViewStatusBar,
  ViewStatusMessage,
  IconButton,
} from 'components/common'
import {
  MapSearchBar,
  MapDataDownloader,
  MapFilterToggle,
  MapLayerDatasetPlugin,
} from 'components/map/controls'

import scss from './index.module.scss'

const MapViewerTopBar = ({
  map,
  isMine,
  currentUser,
  isMapInEditorMode,
  mapViewStatusBarMode,
}) => {
  const navigate = useNavigate()
  const { mapRightPanelExpanded, toggleMapRightPanel } = useMapStateValue()
  const pluginState = usePlugins()

  const { [FEATURES.ASSET]: assetsEnabled } = useFeatureFlag()

  const { name = '' } = map
  const memoizeMapWarning = useMemo(
    () => (
      <div className='text-center'>
        <ViewStatusMessage entity={map} entityLabel='map' isMine={isMine} />
      </div>
    ),
    [map, isMine]
  )

  const memoizedMapViewContent = useMemo(() => {
    if (isMapInEditorMode || !name) return <></>

    return (
      <div className='d-flex align-items-center'>
        <FiArrowLeft
          data-testid='arrow-back'
          className={scss.arrowBack}
          size={16}
          onClick={() => navigate(URLS.MAPS)}
        />
        <div className={scss.title}>{name}</div>
      </div>
    )
  }, [navigate, isMapInEditorMode, name])

  const onQuickClick = useCallback(() => {
    const pathname = getRouteUrlWithValues(
      isMapInEditorMode ? URLS.MAP_VIEW : URLS.MAP_EDIT,
      {
        mapId: map.id,
      }
    )

    navigate(pathname)
  }, [isMapInEditorMode, navigate, map.id])

  return (
    <MapViewStatusBar
      isMapInViewMode={!isMapInEditorMode}
      mode={mapViewStatusBarMode}
    >
      <div className='d-flex align-items-center justify-content-between'>
        {isMapInEditorMode ? memoizeMapWarning : memoizedMapViewContent}
        <MapFilterToggle />
        <div className='d-flex align-items-center me-1'>
          {assetsEnabled && DISPLAY_ISSUE_IN_MAP && (
            <IconButton
              icon='AiOutlineWarning'
              className={`me-2 ${scss.issue}`}
              onClick={() => toggleMapRightPanel()}
            />
          )}
          <MapDataDownloader />
          {pluginState.mapLayerDataset.map((entry, index) => (
            <MapLayerDatasetPlugin
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              iconClassName={scss.download}
              url={entry.url}
              tag={entry.tag}
              iconCode={entry.iconCode}
              pluginParams={entry.pluginParams}
            />
          ))}

          {(isMine || isMapInEditorMode) && (
            <Button type='submit' onClick={onQuickClick} className='me-1'>
              {isMapInEditorMode ? 'View' : 'Edit'}
            </Button>
          )}
          <MapSearchBar
            buttonClassName={scss.buttonClassName}
            iconClassName={scss.iconClassName}
          />
          {!isMapInEditorMode && (
            <Avatar
              className={scss.avatar}
              user={currentUser}
              size={AVATAR_SIZES.small}
              tooltip={false}
            />
          )}
          {!(assetsEnabled && DISPLAY_ISSUE_IN_MAP) && (
            <IconButton
              icon={mapRightPanelExpanded ? 'RiMenu3Line' : 'RiMenu2Line'}
              size={16}
              onClick={() => toggleMapRightPanel()}
            />
          )}
        </div>
      </div>
    </MapViewStatusBar>
  )
}

MapViewerTopBar.propTypes = {
  map: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
  }).isRequired,

  isMine: PropTypes.bool.isRequired,
  currentUser: PropTypes.shape({}).isRequired,
  isMapInEditorMode: PropTypes.bool.isRequired,
  mapViewStatusBarMode: PropTypes.string.isRequired,
}

export default MapViewerTopBar
