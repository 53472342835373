import {
  ANCILLARY_DATA_INITIAL_LIST,
  ANCILLARY_DATA_SET_LIST,
  ANCILLARY_DATA_SET_CONTENTS,
  ANCILLARY_DATA_SET_FILTERED_LIST,
} from 'contexts/actions/ancillaryData'

import { switchcaseF } from 'helpers/utils'

export const ancillaryDataState = {
  loading: false,
  ancillaryDataList: [],
  filteredAncillaryDataList: [],
  ancillaryDataContents: {},
}

const ancillaryDataReducer = (state, action) => {
  const { type } = action
  return switchcaseF({
    [ANCILLARY_DATA_INITIAL_LIST]: () => ({
      ...state,
      loading: true,
    }),
    [ANCILLARY_DATA_SET_LIST]: () => ({
      ...state,
      loading: false,
      ancillaryDataList: action.ancillaryDataList,
    }),
    [ANCILLARY_DATA_SET_FILTERED_LIST]: () => ({
      ...state,
      loading: false,
      filteredAncillaryDataList: action.filteredAncillaryDataList,
    }),
    [ANCILLARY_DATA_SET_CONTENTS]: () => ({
      ...state,
      ancillaryDataContents: action.ancillaryDataContents,
    }),
  })(() => state)(type)
}

export default ancillaryDataReducer
