import { useMemo, ReactElement } from 'react'
import _ from 'lodash'

// components
import { Blade } from 'components/common'

// constants
import { ASSET_PROFILE_WIDGET_CONFIGS } from 'constants/assets'
import { THEMES } from 'constants/colour'
import { BLADE_SIZES } from 'constants/common'

// utils

import type { Widget } from 'types/asset'
import type { SetterOrUpdater } from 'recoil'

// components
import AssetProfilePanelContent from './AssetProfilePanelContent'

const AssetProfilePanel = ({
  selected,
  configs,
  setSelected,
  theme = THEMES.dark,
  mediaType,
}: {
  selected?: string
  configs: Widget[]
  setSelected: SetterOrUpdater<string>
  theme?: string
  mediaType: string
}): ReactElement => {
  const selectedWidget = useMemo(() => {
    return _.find(configs, { id: selected })
  }, [configs, selected])

  const { widget = '', name } = selectedWidget || ({} as Widget)
  const { enablePanel = true } = ASSET_PROFILE_WIDGET_CONFIGS[widget] || {}

  return enablePanel ? (
    <Blade
      show={!!selectedWidget}
      size={BLADE_SIZES.medium}
      title={name}
      customHeight='calc(100vh - 88px)'
      customMargin='16px'
      onClose={() => {
        setSelected('')
      }}
      customFooter={null}
    >
      {selectedWidget && (
        <AssetProfilePanelContent
          selectedWidget={selectedWidget}
          theme={theme}
          mediaType={mediaType}
        />
      )}
    </Blade>
  ) : (
    <></>
  )
}

export default AssetProfilePanel
