import { ReactElement } from 'react'
import { Editor } from '@tiptap/react'
import { IconType } from 'react-icons/lib'

import * as Icons from 'components/icons'
import {
  ALIGNMENTS_DATA,
  HEADERS_MENU,
  DEFAULT_ALIGNMENT_VALUE,
  DEFAULT_ALIGNMENT_ICON,
  ICON_SIZE,
  LIST_TYPES_DATA,
  DEFAULT_LIST_TYPE_ICON,
} from './constants'

type MenuDataItem = {
  label: ReactElement
  key: string
  value: string | number
  onClick: () => void
}

type FormattingStateData = { isActive: boolean; icon: string }

export const getHeadersMenuData = (editor: Editor): MenuDataItem[] => [
  ...HEADERS_MENU.map(({ label, value }) => ({
    label,
    value,
    key: `h${value}`,
    onClick: () =>
      editor
        .chain()
        .focus()
        .toggleHeading({ level: value } as { level: 1 | 2 | 3 | 4 | 5 | 6 })
        .run(),
  })),
  {
    label: <p>Normal</p>,
    key: 'p',
    value: 'p',
    onClick: () => {
      editor.chain().focus().setParagraph().run()
    },
  },
]

/** Returns current active heading lvl */
export const getActiveHeadingState = (
  editor: Editor
): { isHeadingActive: boolean; headingLvl: number | string } => {
  const activeHeader = HEADERS_MENU.find(({ value }) =>
    editor.isActive('heading', { level: value })
  )
  return {
    isHeadingActive: !!activeHeader,
    headingLvl: activeHeader?.value || '',
  }
}

/** Prepares menu items data for the 'alignment' dropdown */
export const getTextAlignMenuData = (editor: Editor): MenuDataItem[] =>
  ALIGNMENTS_DATA.map(({ icon, ...data }) => {
    const Icon = (Icons as Record<string, IconType>)[icon]
    return {
      ...data,
      label: <Icon size={ICON_SIZE} />,
      onClick: () => {
        editor.chain().focus().setTextAlign(data.value).run()
      },
    }
  })

export const getActiveTextAlignmentState = (
  editor: Editor
): FormattingStateData => {
  // Determine which alignment currently active
  const activeAlignment = ALIGNMENTS_DATA.find(({ value }) =>
    editor.isActive({ textAlign: value })
  )
  return {
    // Don't highlight alignment icon if there is the default value
    isActive:
      !!activeAlignment && activeAlignment.value !== DEFAULT_ALIGNMENT_VALUE,
    icon: activeAlignment?.icon || DEFAULT_ALIGNMENT_ICON,
  }
}

export const getListTypesMenuData = (editor: Editor): MenuDataItem[] => [
  ...LIST_TYPES_DATA.map(({ icon, handlerName, ...data }) => {
    const Icon = (Icons as Record<string, IconType>)[icon]
    return {
      ...data,
      label: <Icon size={ICON_SIZE} />,
      onClick: () => {
        const commandsList = editor.chain().focus()
        commandsList[handlerName]().run()
      },
    }
  }),
  {
    label: <Icons.BsList size={ICON_SIZE} />,
    value: 'plainText',
    key: 'plainText',
    onClick: () => {
      editor.chain().focus().clearNodes().run()
    },
  },
]

/** Return an icon of the active list type */
export const getActiveListTypeState = (editor: Editor): FormattingStateData => {
  const activeListType = LIST_TYPES_DATA.find(({ value }) =>
    editor.isActive(value)
  )
  return {
    isActive: !!activeListType,
    icon: activeListType?.icon || DEFAULT_LIST_TYPE_ICON,
  }
}
