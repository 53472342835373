// libraries
import { FC } from 'react'

// constants
import {
  DEFAULT_ICONS,
  MESSAGE_ICON_SIZE,
} from 'components/common/Message/constants'

// components
import * as Icons from 'components/icons'
import ShowMoreText from 'components/common/Message/ShowMoreText'
import {
  MessageButton,
  MessageButtons,
  MessageDescription,
  MessageHeader,
  MessageIcon,
  MessageOptionalItem,
  MessageOptionalList,
  MessageTitle,
} from 'components/common/Message/styles'

import type { MessageContentProps } from 'components/common/Message/type'

const MessageContent: FC<MessageContentProps> = ({
  type,
  icon = DEFAULT_ICONS[type],
  optionalExtraInfo,
  title,
  description,
  buttons,
}) => {
  const IconComponent = Icons[icon as keyof typeof Icons]

  return (
    <>
      <MessageHeader>
        {IconComponent && (
          <MessageIcon type={type}>
            <IconComponent
              size={MESSAGE_ICON_SIZE}
              width={MESSAGE_ICON_SIZE}
              height={MESSAGE_ICON_SIZE}
            />
          </MessageIcon>
        )}

        <div>
          {optionalExtraInfo && (
            <MessageOptionalList>
              {optionalExtraInfo.map(item => (
                <MessageOptionalItem key={item}>{item}</MessageOptionalItem>
              ))}
            </MessageOptionalList>
          )}
          <MessageTitle>{title}</MessageTitle>
        </div>
      </MessageHeader>
      {description && (
        <MessageDescription>
          <ShowMoreText text={description} />
        </MessageDescription>
      )}

      {buttons && (
        <MessageButtons>
          {buttons.map(button => (
            <MessageButton
              typeToast={type}
              type='button'
              key={button.label}
              onClick={button.onClick}
            >
              {button.label}
            </MessageButton>
          ))}
        </MessageButtons>
      )}
    </>
  )
}

export default MessageContent
