import { useMemo, ReactElement } from 'react'
import _ from 'lodash'

// component
import { TitleWithTooltip } from 'components/common'

type TitleProps = {
  name?: string
  label: string
  required?: boolean
  labelClassName?: string
  tooltip?: string
  checkable?: boolean
  isLarge?: boolean
  onChecked?: (name: string, isChecked: boolean) => void
}

const FieldLabel = ({
  label,
  required = false,
  labelClassName = 'form-label',
  onChecked = _.noop,
  checkable = false,
  name,
  isLarge,
  ...rest
}: TitleProps): ReactElement => {
  const largeModifier = isLarge ? 'is-large' : ''

  const title = useMemo(
    () => (
      <>
        {label}{' '}
        {required && <span className={`asterisk ${largeModifier}`}>*</span>}
      </>
    ),
    [label, required, largeModifier]
  )

  return (
    <TitleWithTooltip
      {...rest}
      title={title}
      checkable={checkable}
      className={`${labelClassName} ${largeModifier}`}
      onChecked={(isChecked: boolean) => {
        if (_.isFunction(onChecked) && !_.isNil(name)) {
          onChecked(name, isChecked)
        }
      }}
    />
  )
}

export default FieldLabel
