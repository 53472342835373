import _ from 'lodash'
import { AbilityBuilder, Ability, detectSubjectType } from '@casl/ability'
import { USER_ROLES, ACTION_TYPES } from 'constants/user'
import { SUBJECT_TYPES } from 'constants/casl'

import { capitalizeFirstLetter } from 'helpers/utils'

const defineMultipleRulesWithConditionAliases =
  (can, conditions, value) => (action, subject) => {
    conditions.forEach(condition => {
      can(action, subject, { [condition]: value })
    })
  }

const defineRulesForSameRole = (can, group) => {
  const isSameRoleConditions = ['group', 'user.group']
  return defineMultipleRulesWithConditionAliases(
    can,
    isSameRoleConditions,
    group
  )
}

const definePublicRules = can => {
  const isPublicCondition = { group: 'public' }
  can(ACTION_TYPES.read, SUBJECT_TYPES.map, isPublicCondition)
  can(ACTION_TYPES.read, SUBJECT_TYPES.asset, isPublicCondition)
}

export const defineRulesFor = user => {
  const { can, rules } = new AbilityBuilder()
  if (_.isEmpty(user)) return rules

  const { username, group, role } = user

  const isSelfCondition = {
    username,
  }

  const defineSameGroupRules = defineRulesForSameRole(can, group)

  if (role === USER_ROLES.admin) {
    can(ACTION_TYPES.manage, SUBJECT_TYPES.all)
  } else if (role === USER_ROLES.groupAdmin) {
    can(ACTION_TYPES.manage, SUBJECT_TYPES.all, isSelfCondition)
    defineSameGroupRules(ACTION_TYPES.manage, SUBJECT_TYPES.all)
    can(ACTION_TYPES.manage, SUBJECT_TYPES.user, { group })
  } else if (role === USER_ROLES.user) {
    can(ACTION_TYPES.manage, SUBJECT_TYPES.all, isSelfCondition)
    defineSameGroupRules(ACTION_TYPES.read, SUBJECT_TYPES.all)
  }

  definePublicRules(can)
  return rules
}

/**
 * Read for details: https://stalniy.github.io/casl/v4/en/guide/subject-type-detection
 */
const detectAppSubjectType = subject => {
  if (_.get(subject, 'type')) {
    return capitalizeFirstLetter(subject.type)
  }

  return detectSubjectType(subject)
}

export const buildAbilityFor = user => {
  return new Ability(defineRulesFor(user), {
    detectSubjectType: detectAppSubjectType,
  })
}
