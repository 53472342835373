/**
 * The list contains only American time zones.
 * The list was created by ChatGPT so it could have some mistakes.
 * I (Artem) made some small adjustments in this list and checked the most used timezones
 * like Central/Mountain/Pacific/Atlantic and it looks good.
 */
export const IANA_TIMEZONE_NAME_TO_COMMON_NAME_MAP: Record<string, string> = {
  'America/Adak': 'Hawaii-Aleutian Time',
  'America/Anchorage': 'Alaska Time',
  'America/Anguilla': 'Atlantic Time',
  'America/Antigua': 'Atlantic Time',
  'America/Araguaina': 'Brasilia Time',
  'America/Argentina/Buenos_Aires': 'Argentina Time',
  'America/Argentina/Catamarca': 'Argentina Time',
  'America/Argentina/Cordoba': 'Argentina Time',
  'America/Argentina/Jujuy': 'Argentina Time',
  'America/Argentina/La_Rioja': 'Argentina Time',
  'America/Argentina/Mendoza': 'Argentina Time',
  'America/Argentina/Rio_Gallegos': 'Argentina Time',
  'America/Argentina/Salta': 'Argentina Time',
  'America/Argentina/San_Juan': 'Argentina Time',
  'America/Argentina/San_Luis': 'Argentina Time',
  'America/Argentina/Tucuman': 'Argentina Time',
  'America/Argentina/Ushuaia': 'Argentina Time',
  'America/Aruba': 'Atlantic Time',
  'America/Asuncion': 'Paraguay Time',
  'America/Atikokan': 'Eastern Time',
  'America/Bahia': 'Brasilia Time',
  'America/Bahia_Banderas': 'Central Time',
  'America/Barbados': 'Atlantic Time',
  'America/Belem': 'Brasilia Time',
  'America/Belize': 'Central Time',
  'America/Blanc-Sablon': 'Atlantic Time',
  'America/Boa_Vista': 'Amazon Time',
  'America/Bogota': 'Colombia Time',
  'America/Boise': 'Mountain Time',
  'America/Cambridge_Bay': 'Mountain Time',
  'America/Campo_Grande': 'Amazon Time',
  'America/Cancun': 'Eastern Time',
  'America/Caracas': 'Venezuela Time',
  'America/Cayenne': 'French Guiana Time',
  'America/Cayman': 'Eastern Time',
  'America/Chicago': 'Central Time',
  'America/Chihuahua': 'Mexican Pacific Time',
  'America/Costa_Rica': 'Central Time',
  'America/Creston': 'Mountain Time',
  'America/Cuiaba': 'Amazon Time',
  'America/Curacao': 'Atlantic Time',
  'America/Danmarkshavn': 'Greenwich Mean Time',
  'America/Dawson': 'Pacific Time',
  'America/Dawson_Creek': 'Mountain Time',
  'America/Denver': 'Mountain Time',
  'America/Detroit': 'Eastern Time',
  'America/Dominica': 'Atlantic Time',
  'America/Edmonton': 'Mountain Time',
  'America/Eirunepe': 'Acre Time',
  'America/El_Salvador': 'Central Time',
  'America/Fort_Nelson': 'Mountain Time',
  'America/Fortaleza': 'Brasilia Time',
  'America/Glace_Bay': 'Atlantic Time',
  'America/Godthab': 'Western Greenland Time',
  'America/Goose_Bay': 'Atlantic Time',
  'America/Grand_Turk': 'Eastern Time',
  'America/Grenada': 'Atlantic Time',
  'America/Guadeloupe': 'Atlantic Time',
  'America/Guatemala': 'Central Time',
  'America/Guayaquil': 'Ecuador Time',
  'America/Guyana': 'Guyana Time',
  'America/Halifax': 'Atlantic Time',
  'America/Havana': 'Cuba Time',
  'America/Hermosillo': 'Mexican Pacific Time',
  'America/Indiana/Indianapolis': 'Eastern Time',
  'America/Indiana/Knox': 'Central Time',
  'America/Indiana/Marengo': 'Eastern Time',
  'America/Indiana/Petersburg': 'Eastern Time',
  'America/Indiana/Tell_City': 'Central Time',
  'America/Indiana/Vevay': 'Eastern Time',
  'America/Indiana/Vincennes': 'Eastern Time',
  'America/Indiana/Winamac': 'Eastern Time',
  'America/Inuvik': 'Mountain Time',
  'America/Iqaluit': 'Eastern Time',
  'America/Jamaica': 'Eastern Time',
  'America/Juneau': 'Alaska Time',
  'America/Kentucky/Louisville': 'Eastern Time',
  'America/Kentucky/Monticello': 'Eastern Time',
  'America/Kralendijk': 'Atlantic Time',
  'America/La_Paz': 'Bolivia Time',
  'America/Lima': 'Peru Time',
  'America/Los_Angeles': 'Pacific Time',
  'America/Lower_Princes': 'Atlantic Time',
  'America/Maceio': 'Brasilia Time',
  'America/Managua': 'Central Time',
  'America/Manaus': 'Amazon Time',
  'America/Marigot': 'Atlantic Time',
  'America/Martinique': 'Atlantic Time',
  'America/Matamoros': 'Central Time',
  'America/Mazatlan': 'Mexican Pacific Time',
  'America/Menominee': 'Central Time',
  'America/Merida': 'Central Time',
  'America/Metlakatla': 'Alaska Time',
  'America/Mexico_City': 'Central Time',
  'America/Miquelon': 'Pierre & Miquelon Time',
  'America/Moncton': 'Atlantic Time',
  'America/Monterrey': 'Central Time',
  'America/Montevideo': 'Uruguay Time',
  'America/Montserrat': 'Atlantic Time',
  'America/Nassau': 'Eastern Time',
  'America/New_York': 'Eastern Time',
  'America/Nipigon': 'Eastern Time',
  'America/Nome': 'Alaska Time',
  'America/Noronha': 'Fernando de Noronha Time',
  'America/North_Dakota/Beulah': 'Central Time',
  'America/North_Dakota/Center': 'Central Time',
  'America/North_Dakota/New_Salem': 'Central Time',
  'America/Nuuk': 'West Greenland Time',
  'America/Ojinaga': 'Central Time',
  'America/Panama': 'Eastern Time',
  'America/Pangnirtung': 'Eastern Time',
  'America/Paramaribo': 'Suriname Time',
  'America/Phoenix': 'Mountain Time',
  'America/Port-au-Prince': 'Eastern Time',
  'America/Port_of_Spain': 'Atlantic Time',
  'America/Porto_Velho': 'Amazon Time',
  'America/Puerto_Rico': 'Atlantic Time',
  'America/Punta_Arenas': 'Magallanes Time',
  'America/Rainy_River': 'Central Time',
  'America/Rankin_Inlet': 'Central Time',
  'America/Recife': 'Brasilia Time',
  'America/Regina': 'Central Time',
  'America/Resolute': 'Central Time',
  'America/Rio_Branco': 'Acre Time',
  'America/Santarem': 'Brasilia Time',
  'America/Santiago': 'Chile Time',
  'America/Santo_Domingo': 'Atlantic Time',
  'America/Sao_Paulo': 'Brasilia Time',
  'America/Scoresbysund': 'Eastern Greenland Time',
  'America/Sitka': 'Alaska Time',
  'America/St_Barthelemy': 'Atlantic Time',
  'America/St_Johns': 'Newfoundland Time',
  'America/St_Kitts': 'Atlantic Time',
  'America/St_Lucia': 'Atlantic Time',
  'America/St_Thomas': 'Atlantic Time',
  'America/St_Vincent': 'Atlantic Time',
  'America/Swift_Current': 'Central Time',
  'America/Tegucigalpa': 'Central Time',
  'America/Thule': 'Atlantic Time',
  'America/Thunder_Bay': 'Eastern Time',
  'America/Tijuana': 'Pacific Time',
  'America/Toronto': 'Eastern Time',
  'America/Tortola': 'Atlantic Time',
  'America/Vancouver': 'Pacific Time',
  'America/Winnipeg': 'Central Time',
  'America/Yakutat': 'Alaska Time',
  'America/Yellowknife': 'Mountain Time',

  'America/Argentina/ComodRivadavia': 'Argentina Time',
  'America/Atka': 'Hawaii-Aleutian Time',
  'America/Buenos_Aires': 'Argentina Time',
  'America/Catamarca': 'Argentina Time',
  'America/Ciudad_Juarez': 'Mountain Time',
  'America/Coral_Harbour': 'Eastern Time',
  'America/Cordoba': 'Argentina Time',
  'America/Ensenada': 'Pacific Time',
  'America/Fort_Wayne': 'Eastern Time',
  'America/Indianapolis': 'Eastern Time',
  'America/Jujuy': 'Argentina Time',
  'America/Knox_IN': 'Eastern Time',
  'America/Louisville': 'Eastern Time',
  'America/Mendoza': 'Argentina Time',
  'America/Montreal': 'Eastern Time',
  'America/Porto_Acre': 'Acre Time',
  'America/Rosario': 'Argentina Time',
  'America/Santa_Isabel': 'Atlantic Time',
  'America/Shiprock': 'Mountain Time',
  'America/Virgin': 'Atlantic Time',
  'America/Whitehorse': 'Mountain Time',
}
