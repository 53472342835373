import { ReactElement, ReactNode, useState, useCallback, UIEvent } from 'react'

import scss from './index.module.scss'

const BladeContent = ({ children }: { children: ReactNode }): ReactElement => {
  const [displayTopFader, setDisplayTopFader] = useState(false)

  const onScroll = useCallback(
    (e: UIEvent<HTMLDivElement>) => {
      // Display the top fader once user started to scroll
      if (!displayTopFader && e.currentTarget.scrollTop > 0)
        setDisplayTopFader(true)
      // Hide the top fader once the scroll is on very top position again
      if (displayTopFader && e.currentTarget.scrollTop === 0)
        setDisplayTopFader(false)
    },
    [displayTopFader, setDisplayTopFader]
  )

  return (
    <div className={scss.bladeContentWrapper}>
      {displayTopFader && (
        <div className={scss.topFader} data-testid='blade-top-fader' />
      )}
      <div
        className={scss.bladeContent}
        onScroll={onScroll}
        data-testid='blade-scrollable-content'
      >
        {children}
      </div>
    </div>
  )
}

export default BladeContent
