// libraries
import { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { ColumnBodyOptions } from 'primereact/column'

// components
import { IssueGeojson } from 'types/issue'
import { IconButton } from 'components/common'

const Template = ({
  rowData,
  options: { field },
}: {
  rowData: IssueGeojson
  options: ColumnBodyOptions
}): ReactElement => {
  const navigate = useNavigate()

  const { workflowViewUrl } = rowData

  const workflowName = _.get(rowData, field)

  return (
    <>
      {workflowName}
      {workflowViewUrl && (
        <IconButton
          icon='ArrowRightIcon'
          onClick={() => navigate(workflowViewUrl)}
        />
      )}
    </>
  )
}

const WorkflowTemplate = (
  rowData: unknown,
  options: ColumnBodyOptions
): ReactElement => {
  return <Template rowData={rowData} options={options} />
}

export default WorkflowTemplate
