import { CSSProperties, ReactElement } from 'react'

import { StyledField } from 'components/common/Form'

import ButtonGroup, {
  ButtonOption,
  SelectedButtonGroupValue,
} from 'components/common/ButtonGroup'

type RadioToggleButtonGroupProps = {
  labelClassName?: string
  style?: CSSProperties
  description?: string
  required?: boolean
  groupOptionStyle?: boolean
  name: string
  input: {
    value: string
    onChange: (selectedValue: SelectedButtonGroupValue) => void
    checked: boolean
    name: string
  }
  label: string
  toggleLabel: string
  inline: boolean
  defaultValue?: string
  testId?: string
  disabled?: boolean
  readOnly?: boolean
  options: ButtonOption[]
  size: 'small' | 'medium' | 'large'
}

const RadioToggleButtonGroup = ({
  labelClassName,
  input,
  style,
  description,
  required,
  groupOptionStyle,
  label,
  inline = true,
  disabled,
  options = [],
  defaultValue,
  size = 'medium',
}: RadioToggleButtonGroupProps): ReactElement => {
  const { value, onChange, name } = input
  return (
    <StyledField
      name={name}
      groupOptionStyle={groupOptionStyle}
      label={label}
      required={required}
      description={description}
      style={style}
      labelClassName={labelClassName}
      inline={inline}
    >
      <ButtonGroup
        selectedValue={value || defaultValue}
        buttonOptions={options}
        onChange={onChange}
        isDisabled={disabled}
        size={size}
      />
    </StyledField>
  )
}

export default RadioToggleButtonGroup
