// constants
import { ENTITIES, GALLERY_LIST_FILTER_TYPES } from 'constants/common'

export const PAGE_OPTIONS = {
  entity: ENTITIES.formList,
  entityLabel: 'List',
  pageName: 'Form Designer',
  listConditions: {
    sortField: 'audit.updatedTime',
    ascOrder: false,
  },
  customizedFiltersKeys: [
    // ...DEFAULT_LIST_FILTERS,
    GALLERY_LIST_FILTER_TYPES.isFavorite,
  ],
}
