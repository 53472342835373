import React from 'react'
import PropTypes from 'prop-types'
import scss from './index.module.scss'
import MainContentLayout from '../MainContentLayout'

const FullPageLayout = ({ children }) => (
  <div className={scss.app}>
    <MainContentLayout>{children}</MainContentLayout>
  </div>
)

FullPageLayout.propTypes = {
  children: PropTypes.node,
}

FullPageLayout.defaultProps = {
  children: undefined,
}

export default FullPageLayout
