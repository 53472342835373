import { FC, useMemo } from 'react'
import isURL from 'validator/lib/isURL'

// components
import { Tooltip } from 'components/common'

// constants
import { TOOLTIP_PLACEMENT } from 'constants/settings'
import { THEMES } from 'constants/colour'
import { EXTERNAL_URL_LABEL } from 'constants/common'

import type { IconType } from 'react-icons'

// utils
import { useStaticResourceStateValue } from 'contexts'
import log from 'helpers/log'
import ExternalLinkPopOver from './ExternalLinkPopover'

type ExternalLinkProps = {
  link: string
  label?: string
  iconName?: string
  isImage?: boolean
  className?: string
  labelClassName?: string
  iconSize?: number
  hideTooltip?: boolean
}

const ICON_SIZE = 15

const ExternalLink: FC<ExternalLinkProps> = ({
  link,
  label = EXTERNAL_URL_LABEL,
  iconName = 'GoLinkExternal',
  isImage = false,
  className = '',
  labelClassName = '',
  iconSize = ICON_SIZE,
  hideTooltip = false,
}) => {
  const { Icons } = useStaticResourceStateValue()

  const Icon = iconName ? (Icons as Record<string, IconType>)[iconName] : null

  const iconProps = {
    width: iconSize,
    height: iconSize,
    size: iconSize,
    icon: iconName,
    className: 'me-1',
  }

  const isValidUrl = useMemo(() => {
    const result = isURL(link)
    if (!result) {
      log.error('Invalid external url: ', { label, link })
    }
    return result
  }, [label, link])

  const renderContent = () =>
    isImage ? (
      <img src={iconName} alt='Icon' {...iconProps} />
    ) : Icon ? (
      <Icon {...iconProps} />
    ) : undefined

  return (
    <Tooltip
      placement={TOOLTIP_PLACEMENT.bottom}
      trigger={['hover']}
      theme={THEMES.light}
      overlayClassName={THEMES.light}
      showArrow={false}
      {...(isValidUrl && {
        overlay: <ExternalLinkPopOver externalUrl={link} />,
      })}
      {...(hideTooltip && { visible: false })}
    >
      {isValidUrl ? (
        <a
          href={link}
          className={`d-inline-flex align-items-center ${className}`}
          target='_blank'
          rel='noopener noreferrer nofollow'
        >
          {renderContent()}
          <span className={`semiBold smallText ${labelClassName}`}>
            {label}
          </span>
        </a>
      ) : (
        renderContent()
      )}
    </Tooltip>
  )
}

export default ExternalLink
