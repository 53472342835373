import React from 'react'
import PropTypes from 'prop-types'

// components
import { Button } from 'components/common/'
import scss from './index.module.scss'

const Cover = ({ message, onClick, buttonContent }) => {
  return (
    <div className={`d-flex text-center text-white ${scss.container}`}>
      <div className='cover-container d-flex w-100 h-100 mx-auto flex-column justify-content-center align-items-center '>
        <div className={`${scss.main}`}>
          <div className='d-flex align-items-center text-start'>
            <img
              src='/assets/logo/sensorup-logo-icon.svg'
              alt='SensorUp Logo'
              width='1'
              height='1'
            />
            <div className='ms-3'>
              {message}
              {buttonContent && onClick && (
                <Button className='mt-3' onClick={onClick}>
                  {buttonContent}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Cover.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClick: PropTypes.func,
  buttonContent: PropTypes.string,
}

Cover.defaultProps = {
  message: '',
  onClick: undefined,
  buttonContent: undefined,
}
export default Cover
