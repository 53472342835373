// libraries
import { ReactElement, FunctionComponent, SVGProps } from 'react'
import styled from '@emotion/styled'

// types
import type { IconType } from 'react-icons'

type StyledHeaderProps = {
  isActive: boolean
  isDisabled: boolean
  size: number
}

const StyledHeader = styled.div<StyledHeaderProps>`
  color: ${props =>
    props.isDisabled
      ? props.theme['secondary-light-600']
      : props.isActive
      ? props.theme.primary
      : props.theme['secondary-light-800']};

  svg {
    display: block;
    width: ${props => props.size}px;
    margin: auto auto 6px;
  }

  svg .a {
    fill: ${props =>
      props.isActive
        ? props.theme.primary
        : props.isDisabled
        ? props.theme['secondary-light-600']
        : props.theme['secondary-light-800']};
  }

  svg .st0,
  svg .st1 {
    stroke: ${props =>
      props.isActive
        ? props.theme.primary
        : props.isDisabled
        ? props.theme['secondary-light-600']
        : props.theme['secondary-light-800']};
  }

  &:hover {
    color: ${props =>
      props.isDisabled
        ? props.theme['secondary-light-600']
        : props.theme.primary};

    svg .a {
      fill: ${props =>
        props.isDisabled
          ? props.theme['secondary-light-600']
          : props.theme.primary};
    }

    svg .st0,
    svg .st1 {
      stroke: ${props =>
        props.isDisabled
          ? props.theme['secondary-light-600']
          : props.theme.primary};
    }
  }
`

const BladeTabHeader = ({
  icon,
  iconSize = 16,
  label,
  isDisabled,
  isActive,
}: {
  icon?: IconType | FunctionComponent<SVGProps<SVGSVGElement>>
  iconSize?: number
  label: string
  isDisabled: boolean
  isActive: boolean
}): ReactElement => {
  const Icon = icon

  return (
    <StyledHeader isDisabled={isDisabled} isActive={isActive} size={iconSize}>
      {icon && <Icon size={iconSize} />}
      {label}
    </StyledHeader>
  )
}

export default BladeTabHeader
