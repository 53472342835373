import { ReactElement, useState, useCallback } from 'react'
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  UniqueIdentifier,
  DragOverlay,
  DragStartEvent,
} from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'

// components
import { ListGroup } from 'components/common'

/** Wraps a list in the necessary @dnd-kit contexts */
const SortableList = ({
  items,
  handleDragEnd,
  renderActiveItem,
  renderList,
}: {
  items: (unknown & { id: UniqueIdentifier })[]
  handleDragEnd: (event: DragEndEvent) => void
  renderActiveItem: (props: {
    activeId: UniqueIdentifier
  }) => ReactElement | null
  renderList: (props: { activeId?: UniqueIdentifier | null }) => ReactElement[]
}): ReactElement => {
  // Storing ID of an element which currently dragging
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null)
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // This is important to keep working onClick events inside the draggable element
        distance: 8,
      },
    })
  )

  const onDragStart = useCallback(
    (event: DragStartEvent) => {
      setActiveId(event.active.id)
    },
    [setActiveId]
  )

  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      handleDragEnd(event)
      setActiveId(null)
    },
    [setActiveId, handleDragEnd]
  )

  return (
    <ListGroup>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
      >
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          {renderList({ activeId })}
        </SortableContext>

        <DragOverlay>{activeId && renderActiveItem({ activeId })}</DragOverlay>
      </DndContext>
    </ListGroup>
  )
}

export default SortableList
