import _ from 'lodash'
import { CompositeLayer } from '@deck.gl/core/typed'
import { IconLayer } from '@deck.gl/layers/typed'
import { getMultipleLabelsTextLayerSpec } from 'components/map/layers/deckLayers/textLayerHelper'
import { getMapDeckLayerFilterProps } from 'helpers/map'

export default class LabeledIconLayer extends CompositeLayer {
  renderLayers() {
    const {
      id,
      data,
      iconAtlas,
      bgMapping,
      getBgColor,
      getIconColor,
      iconMapping,
      getAngle,
      getIconSize,
      getIcon,
      pickable,
      autoHighlight,
      bgIconHighlightedObjectIndex,
      getPosition,
      updateTriggers,
      propertyLabels,
      timezone,
      currentZoom,
      visible,
    } = this.props

    const filterProps = getMapDeckLayerFilterProps(this.props)

    const sharedProps = {
      data,
      iconAtlas,
      getPosition,
      getIcon,
      pickable,
      ...filterProps,
    }

    const bgColour = _.isFunction(getBgColor) ? getBgColor() : getBgColor
    const isBgNotVisible = (bgColour[3] * 255) / 100 < 50
    const isIconHighlighIndexValid = Number.isInteger(
      bgIconHighlightedObjectIndex
    )

    return [
      // the bg icons
      new IconLayer(
        this.getSubLayerProps({
          ...sharedProps,
          // `getSubLayerProps` will concat the parent layer id with this id
          id: `${id}-bg`,
          iconMapping: bgMapping,
          getSize: getIconSize * 2.2,
          getColor: getBgColor,
          getAngle,
          autoHighlight,
          ...(isIconHighlighIndexValid && {
            highlightedObjectIndex: bgIconHighlightedObjectIndex,
          }),
          updateTriggers: {
            getPosition: updateTriggers.getPosition,
            getIcon: updateTriggers.getIcon,
            getSize: updateTriggers.getIconSize,
            getColor: updateTriggers.getBgColor,
          },
        })
      ),

      // the icon
      new IconLayer(
        this.getSubLayerProps({
          ...sharedProps,
          // `getSubLayerProps` will concat the parent layer id with this id
          id: `${id}-icon`,
          iconMapping,
          getSize: getIconSize,
          getColor: getIconColor,
          autoHighlight: !!isBgNotVisible,
          ...(isIconHighlighIndexValid &&
            !!isBgNotVisible && {
              highlightedObjectIndex: bgIconHighlightedObjectIndex,
            }),
          updateTriggers: {
            getPosition: updateTriggers.getPosition,
            getIcon: updateTriggers.getIcon,
            getSize: updateTriggers.getIconSize,
            getColor: updateTriggers.getIconColor,
          },
        })
      ),

      // the labels
      ...getMultipleLabelsTextLayerSpec({
        ...filterProps,
        propertyLabels,
        timezone,
        data,
        getPosition,
        currentZoom,
        visible,
      }),
    ]
  }
}

LabeledIconLayer.layerName = 'LabeledIconLayer'

LabeledIconLayer.defaultProps = {
  // Shared accessors
  getPosition: { type: 'accessor', value: d => d.geometry.coordinates },
  // Icon properties
  iconAtlas: null,
  iconMapping: { type: 'object', value: {}, async: true },
  // Icon accessors
  getIcon: { type: 'accessor', value: x => x.icon },
  getIconSize: { type: 'accessor', value: 20 },
  getIconColor: { type: 'accessor', value: [0, 0, 0, 255] },

  // Bg properties
  bgMapping: { type: 'object', value: {}, async: true },
  // Bg Icon accessors
  getBgColor: { type: 'accessor', value: [255, 255, 255, 255] },
  getAngle: { type: 'accessor', value: 0 },
  pickable: true,
  autoHighlight: true,
}
