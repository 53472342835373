import { ReactElement } from 'react'

import { AVATAR_SIZES, DEFAULT_USER } from 'constants/user'
import Avatar from 'components/common/Avatar'

const UserPlaceholder = ({ text }: { text: string }): ReactElement => (
  <div className='d-flex align-items-center'>
    <Avatar user={DEFAULT_USER} size={AVATAR_SIZES.small} useDefaultAvatar />
    <span className='ms-2'>{text}</span>
  </div>
)

export default UserPlaceholder
