import { MAP_STYLE_TYPES } from 'constants/map'
import {
  useConfigStateValue,
  initialBrandingState,
} from 'contexts/ConfigContext'
import _ from 'lodash'
import { useMemo } from 'react'

import type { Branding } from 'types/common'

const useBranding = (): Branding => {
  const { branding } = useConfigStateValue() || {}
  const brandingState = branding || initialBrandingState
  const { mapboxStyles } = brandingState

  const defaultSiteMapStyle = useMemo(
    () =>
      _.find(mapboxStyles, { defaultSiteMapStyle: true })?.value ||
      MAP_STYLE_TYPES.satellite,
    [mapboxStyles]
  )

  return { ...brandingState, defaultSiteMapStyle }
}

export default useBranding
