import keymirror from 'keymirror'

/**
 * maximum batch interval in milliseconds
 */
export const MAX_BATCH_INTERVAL_TIME = 30 * 1000

/**
 * max batch rows at a time
 */
export const MAX_BATCH_NUM_ROWS = 1000

/**
 * max retry attempts after the failed non-live request to the backend
 */
export const MAX_NON_LIVE_DATA_REQUEST_RETRY_ATTEMPTS = 3

/**
 * max retry attempts after the failed live data request to the backend
 */
export const MAX_LIVE_REQUEST_RETRY_ATTEMPTS = 86400

/**
 * the number of milliseconds to delay
 */
export const RETRY_DELAY_BETWEEN_ATTEMPTS = 5000

/**
 * unipipe dataset type
 */
export const DATASET_TYPES = keymirror({
  historical: null,
  live: null,
  liveplus: null,
  replay: null,
  feature: null,
})

/**
 * unipipe dataset source
 */
export const DATASET_SOURCE = keymirror({
  mqtt: null,
  s3: null,
  kinesis: null,
  dynamo: null,
})

export const LIVE_PLUS_DATASET_TYPES = [
  DATASET_TYPES.live,
  DATASET_TYPES.liveplus,
  DATASET_TYPES.replay,
]

export const TIME_PROPERTY_KEY = 'time'
export const NAME_PROPERTY_KEY = 'name'

export const UP_STREAM_MODULE_HEARTBEAT = {
  module: 'multiplexer',
  params: {
    mode: 'live',
    dataSource: {
      source: 'inline',
      rows: [],
    },
    postProcess: [
      {
        module: 'schedule',
        params: {
          cron: '*/30 * * * * *',
        },
      },
      {
        module: 'jsonataRow',
        params: {
          expression: "$ ~> | properties | { 'name': '[heartbeat]' } |",
        },
      },
    ],
  },
}

export const USE_LOCAL_CACHE_KEY = 'useLocalCache'

export const USE_LOCAL_CACHE_DEFAULT_VALUE = false

export const DMS_DATA_TYPES = keymirror({
  geojsonRows: null,
  lastKnownLocations: null,
})
