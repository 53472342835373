import { ReactElement, useEffect } from 'react'
import { useToggle } from 'react-use'

// constants
import { DEFAULT_HIDE_VALUES_OUTSIDE_OF_VALUE_RANGE } from 'constants/widget'

// components
import { Toggle } from 'components/common'

type Payload = { [key: string]: boolean }

type HideValueToggleProps = {
  hideValue: boolean
  onChangeValueKey?: string
  onChange: (payload: Payload) => void
  label?: string
  isValid?: boolean
}

const HideValueToggle = ({
  hideValue,
  onChange,
  onChangeValueKey = 'hideValuesOutsideOfValueRange',
  label = 'Hide data series outside of range',
  isValid = true,
}: HideValueToggleProps): ReactElement => {
  const [hideValuesOutsideOfRange, toggleHideValuesOutsideOfRange] =
    useToggle(hideValue)

  useEffect(() => {
    if (!isValid) {
      const newValue = DEFAULT_HIDE_VALUES_OUTSIDE_OF_VALUE_RANGE
      onChange({
        [onChangeValueKey]: newValue,
      })
      toggleHideValuesOutsideOfRange(newValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  return isValid ? (
    <Toggle
      label={label}
      checked={hideValuesOutsideOfRange}
      onToggle={() => {
        const newValue = !hideValuesOutsideOfRange
        toggleHideValuesOutsideOfRange(newValue)
        onChange({
          [onChangeValueKey]: newValue,
        })
      }}
    />
  ) : (
    <></>
  )
}

HideValueToggle.defaultProps = {
  onChangeValueKey: 'hideValuesOutsideOfValueRange',
  label: 'Hide data series outside of range',
  isValid: true,
}

export default HideValueToggle
