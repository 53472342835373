import { ReactElement } from 'react'

// constants
import { LAYER_VIS_CONFIGS } from 'components/map/layers/deckLayers/layerFactory'

// components
import { ColourArray, ThemeType } from 'types/common'
import ColourDot from '../ColourDot'
import LegendTitle from '../LegendTitle'

const SimpleColourLegend = ({
  name,
  fillColour = LAYER_VIS_CONFIGS.fillColour.defaultValue,
  displayDot = true,
  ...rest
}: {
  id: string
  canToggleVisibility: boolean
  canToggleClustering: boolean
  isVisible: boolean
  enableClustering: boolean
  name: string
  displayDot: boolean
  fillColour: ColourArray
  theme: ThemeType
}): ReactElement => (
  <LegendTitle title={name} {...rest}>
    {displayDot ? <ColourDot fillColour={fillColour} /> : <></>}
  </LegendTitle>
)

export default SimpleColourLegend
