// libraries
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useMount } from 'react-use'

// constants
import { LINE_CHART_TYPES } from 'constants/widget'

// utils
import {
  useAssetWidgetConfigs,
  useBaseAssetWidgetPanel,
} from 'components/assets/assetsProfile/hooks'

import { AssetWidgetPropTypes } from 'propTypes'
import { validNumericProperties } from 'helpers/filter'
import { useUpdateWidgetSettings } from 'hooks'

// components
import {
  LineWidgetConfig,
  LINE_CHART_TABS,
} from 'components/map/layers/widgets/WidgetSettingTabs/LineWidgetTab'

const AssetLineWidgetPanel = ({ widget }) => {
  const {
    enableRelationship,
    relatedAssetRelationshipId,
    renderAssetToggleAndRelatedMultiSelect,
    propertyOptions,
  } = useBaseAssetWidgetPanel({ widget, onlyEnableRelatedAssets: true })

  const { settings } = widget

  const { updateWidgetConfigsSettings, updateAssetWidgetConfigs } =
    useAssetWidgetConfigs(widget)

  const isFeaturesDisabled = useMemo(
    () => _.isEmpty(validNumericProperties(propertyOptions)),
    [propertyOptions]
  )

  const {
    type = isFeaturesDisabled
      ? LINE_CHART_TYPES.group
      : LINE_CHART_TYPES.features,
  } = settings

  useMount(() => {
    if (!settings.type) {
      updateWidgetConfigsSettings({ type })
    }
  })

  const { onSelect, selectedIndex } = useUpdateWidgetSettings({
    settings,
    type,
    tabs: LINE_CHART_TABS,
    updateWidgetSettings: updateAssetWidgetConfigs,
  })

  return (
    <>
      {renderAssetToggleAndRelatedMultiSelect()}
      {enableRelationship && relatedAssetRelationshipId && (
        <LineWidgetConfig
          propertyOptions={propertyOptions}
          settings={settings}
          onChange={updateWidgetConfigsSettings}
          onSelect={onSelect}
          selectedIndex={selectedIndex}
          isFeaturesDisabled={isFeaturesDisabled}
        />
      )}
    </>
  )
}

AssetLineWidgetPanel.propTypes = {
  widget: PropTypes.shape(AssetWidgetPropTypes).isRequired,
}

export default AssetLineWidgetPanel
