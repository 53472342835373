import _ from 'lodash'
import { useCallback, useEffect } from 'react'

// utils
import { useMapStateValue } from 'contexts'
import { throttledWrapper, updateListItem } from 'helpers/utils'
import { getDataIdentityAndTimePropertyValue } from 'helpers/unipipe'
import { isAssetLayer } from 'helpers/map'

/**
 * Responsible for generating suggestions for searching based on all layers'
 * filtered data
 */
const useMapSuggestions = ({
  layers,
  fitFeatureBound,
  updateOverlays,
  pickedDatasetsMetadata,
}) => {
  const { setMapSuggestions, searchFeatureSelected } = useMapStateValue()

  /**
   * Generate suggestions for searching based on all filteredData
   */
  const updateMapSuggestions = useCallback(
    (layer, filteredData) => {
      const { id, name, dataset } = layer
      const identityProperty = _.get(pickedDatasetsMetadata, [
        dataset,
        'identityProperty',
      ])
      const features = filteredData.map((data, idx) => ({
        ...getDataIdentityAndTimePropertyValue(data, identityProperty),
        ...(isAssetLayer(layer) && { name: data.displayName }),
        index: idx,
        layerId: id,
        geometry: data.geometry,
      }))

      setMapSuggestions(oldSuggestions => {
        const layerData = {
          id,
          name,
          features,
        }
        return updateListItem(id, layerData, oldSuggestions, true)
      })
    },
    [pickedDatasetsMetadata, setMapSuggestions]
  )

  /**
   * Highlight the selected feature
   */
  useEffect(() => {
    if (searchFeatureSelected) {
      const { geometry, flyTo } = searchFeatureSelected
      if (flyTo) {
        fitFeatureBound(geometry, false)
      }

      throttledWrapper(updateOverlays)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFeatureSelected])

  useEffect(() => {
    if (_.isEmpty(layers)) {
      setMapSuggestions([])
    }
  }, [layers, setMapSuggestions])

  return { updateMapSuggestions }
}

export default useMapSuggestions
