// libraries
import { ReactElement } from 'react'
import styled from '@emotion/styled'
import _ from 'lodash'

// constants
import {
  REPEAT_DAILY,
  REPEAT_EVERY_TYPE,
  REPEAT_EVERY_TYPE_DEFAULTS,
  REPEAT_FIELD_NAMES,
  REPEAT_INTERVAL_INPUT_MAX,
  REPEAT_MONTHLY,
  REPEAT_WEEKLY,
  REPEAT_YEARLY,
} from 'components/workflow/Canvas/panels/PropertyPanel/common/DateTimePicker/constants'
import { TOOLTIP_PLACEMENT } from 'constants/settings'

// components
import { Dropdown, IconButton, NumericInput } from 'components/common'

// style
import scss from './index.module.scss'

const RepeatTypeWrapper = styled.div`
  float: left;
  width: 65px;
  margin-top: 2px;
  margin-left: 8px;
`

type RepeatEveryType = {
  dayRepeatEvery?: number
  monthRepeatEvery?: number
  yearRepeatEvery?: number
  repeatType?: string
}
type RepeatProps = {
  repeatType: keyof typeof REPEAT_FIELD_NAMES
  onChange: (v: RepeatEveryType) => void
  repeatEvery: string
  disabled?: boolean
  isDisabled: boolean
}

const REPEAT_OPTIONS = [
  { label: 'Year', value: REPEAT_YEARLY },
  { label: 'Month', value: REPEAT_MONTHLY },
  { label: 'Week', value: REPEAT_WEEKLY },
  { label: 'Day', value: REPEAT_DAILY },
]
const Repeat = ({
  repeatType = '1',
  onChange,
  repeatEvery,
  disabled = false,
  isDisabled,
}: RepeatProps): ReactElement => {
  const handleRepeatRateChange = (rate: string) => {
    onChange({
      [REPEAT_FIELD_NAMES[repeatType] as string]: Math.round(Number(rate)),
    })
  }

  const handleRepeatTypeChange = (value: string) => {
    onChange(REPEAT_EVERY_TYPE_DEFAULTS[value])
  }

  return (
    <>
      <div className='row g-3 my-0'>
        <div className='col-sm-2 custom-col'>
          <IconButton
            icon='MdCached'
            size={16}
            disabled
            style={{ padding: 0, color: '#222529' }}
          />
        </div>
        <div className='col-sm-10 custom-col-timer'>
          <div className='row g-0'>
            <div className='col'>
              <span className='boldSmallText'>Repeats every</span>
            </div>
            <div className='col'>
              <div className='d-flex align-items-center'>
                <NumericInput
                  className='form-control me-0'
                  value={repeatEvery || '1'}
                  onChange={handleRepeatRateChange}
                  disabled={disabled || isDisabled}
                  min={1}
                  step={1}
                  max={REPEAT_INTERVAL_INPUT_MAX[repeatType] as number}
                />
                <RepeatTypeWrapper>
                  {isDisabled ? (
                    <div className={scss.label}>
                      {REPEAT_EVERY_TYPE[repeatType]}
                    </div>
                  ) : (
                    <Dropdown
                      toggleComponent={() => {
                        return (
                          <div className={`${scss.label} cursor-pointer`}>
                            {REPEAT_EVERY_TYPE[repeatType]}
                          </div>
                        )
                      }}
                      options={_.map(REPEAT_OPTIONS, ({ value, ...rest }) => ({
                        ...rest,
                        value,
                        onClick: () => handleRepeatTypeChange(value),
                      }))}
                      placement={TOOLTIP_PLACEMENT.right}
                    />
                  )}
                </RepeatTypeWrapper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
Repeat.defaultProps = {
  disabled: false,
}
export default Repeat
