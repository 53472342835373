import { CSSProperties, ReactElement } from 'react'
import _ from 'lodash'
import { Field } from 'react-final-form'
import CreatableSelect from 'react-select/creatable'

// utils
import { composeValidators, validatePhoneNumber } from 'helpers/validators'
import { useStateValue } from 'contexts'
import { getUserOptionLabel } from 'components/common/UsersPicker'
import useSelectStyles from 'components/common/MultiSelect/useSelectStyles'
import { getMultiSelectCommonProps } from 'components/common/MultiSelect'

// components
import { StyledField } from 'components/common/Form'

// types
import type { WorkflowFormWidgetSendSms } from 'types/workflow'
import type { ValidationErrors } from 'final-form'
import type { Payload } from 'types/common'

import Input from '../Input'

const normalizePhone = (value: string): string | undefined => {
  if (!value) return undefined

  const onlyNumbers = value.replace(/[^\d]/g, '')
  const tenDigits = onlyNumbers.slice(0, 10)
  return `+1${tenDigits}`
}

const strippedPhoneString = (str: string) =>
  _.replace(str, '+1', '').replace(/\D/g, '')

const isValidatedPhoneNumber = (str: string) => {
  return !validatePhoneNumber(strippedPhoneString(str))
}

export const formatPhone = (value: string): string | undefined => {
  if (!value || !_.isString(value)) return undefined

  const strippedValue = strippedPhoneString(value)
  if (!strippedValue) return value
  const onlyNumbers = strippedValue.replace(/[^\d]/g, '')
  const areaCode = onlyNumbers.slice(0, 3)
  const prefix = onlyNumbers.slice(3, 6)
  const lineNo = onlyNumbers.slice(6, 10)

  if (onlyNumbers.length <= 3) {
    return areaCode
  }

  if (onlyNumbers.length <= 6) {
    return `(${areaCode}) ${prefix}`
  }

  return `(${areaCode}) ${prefix}-${lineNo}`
}

export type UserPhoneNumberOption = WorkflowFormWidgetSendSms['phoneNumber']

const PhoneSelect = ({
  input,
  label,
  placeholder,
  readOnly,
  required = false,
  groupOptionStyle = false,
  className,
}: {
  input: {
    name: string
    value: UserPhoneNumberOption
    onChange: (v: UserPhoneNumberOption | null) => void
  }
  label: string
  placeholder: string
  readOnly: boolean
  className: string
  required: boolean
  groupOptionStyle: boolean
}): ReactElement => {
  const {
    selectors: {
      userSelectors: { userPhoneNumberOptions },
    },
  } = useStateValue()

  const { customStyles } = useSelectStyles({ withBorder: true })

  const { name, onChange, value } = input

  return (
    <StyledField
      name={name}
      groupOptionStyle={groupOptionStyle}
      label={label}
      required={required}
    >
      <CreatableSelect
        className={`customSelect ${className}`}
        classNamePrefix='select'
        isClearable
        isMulti={false}
        onChange={(newValue: UserPhoneNumberOption): void => {
          const {
            value: newPhoneNumber,
            label: newUserNameOrPhoneNumber,
            name: newUserName,
          } = newValue || {}

          let newPhoneOption = newValue
          if (newPhoneNumber === newUserNameOrPhoneNumber && !newUserName) {
            newPhoneOption = {
              label: newUserNameOrPhoneNumber,
              value: normalizePhone(newPhoneNumber),
            }
          }

          onChange(!newPhoneNumber ? null : newPhoneOption)
        }}
        onInputChange={(val: string) => {
          return formatPhone(val)
        }}
        placeholder={placeholder}
        value={value}
        isDisabled={readOnly}
        options={userPhoneNumberOptions}
        formatOptionLabel={getUserOptionLabel()}
        isSearchable
        isValidNewOption={inputValue => isValidatedPhoneNumber(inputValue)}
        {...getMultiSelectCommonProps({
          isMulti: false,
          className,
          value,
          customStyles,
        })}
      />
    </StyledField>
  )
}

const PhoneField = ({
  validate,
  withSelectOption = false,
  required = false,
  ...rest
}: {
  labelClassName?: string
  validate?: (values: Payload) => ValidationErrors
  withSelectOption?: boolean
  name?: string
  label?: string
  required?: boolean
  style: CSSProperties
  className?: string
  addOn: JSX.Element
}): ReactElement => {
  return (
    <Field
      label='Phone Number'
      placeholder='(999) 999-9999'
      component={withSelectOption ? PhoneSelect : Input}
      {...(!withSelectOption && {
        type: 'tel',
        format: formatPhone,
        parse: normalizePhone,
        validate: composeValidators(validate, value =>
          validatePhoneNumber(value, { required })
        ),
      })}
      required={required}
      {...rest}
    />
  )
}

PhoneField.defaultProps = {
  validate: _.noop,
}

export default PhoneField
