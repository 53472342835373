// libraries
import {
  ReactElement,
  useState,
  useMemo,
  useCallback,
  useRef,
  PropsWithChildren,
} from 'react'
import { MdArrowForward } from 'react-icons/md'
import isEqual from 'fast-deep-equal'
import { useMount, useClickAway } from 'react-use'
import keymirror from 'keymirror'
import _ from 'lodash'

// constants
import { DEFAULT_RELATIVE_TIME, DATE_UNIT_TYPES } from 'constants/datetime'
import { TIME_RANGE_MODES } from 'constants/filter'
import { THEMES } from 'constants/colour'

// utils
import { normalizeTimeToUtcStr } from 'components/common/DateTimePicker/utils'
import {
  getUtcDateTimeString,
  getPreviousUtcDate,
  isDateTimeRangeValid,
  isTimeValidWithIsoFormat,
} from 'helpers/datetime'
import { switchcaseF } from 'helpers/utils'

// types
import type { ThemeType } from 'types/common'
import type { UtcISOString, DatetimePickerTime, Timezone } from 'types/datetime'

// components
import { IconButton } from 'components/common'
import DatetimeAnchor from './DatetimeAnchor'
import DatetimeContent from './DatetimeContent'
import QuickMenu from './QuickMenu'

// style
import scss from './index.module.scss'

const TIME_OPTIONS_TYPES = keymirror({
  quickMenu: null,
  start: null,
  end: null,
})

type TimeOptionsType = keyof typeof TIME_OPTIONS_TYPES

type TimeProps = {
  startTime?: DatetimePickerTime
  endTime?: DatetimePickerTime
}

type DatetimePickerProp = PropsWithChildren<
  TimeProps & {
    onChange: ({
      startTime,
      endTime,
    }: {
      startTime: UtcISOString
      endTime: UtcISOString
    }) => void
    timezone: Timezone
    className: string
    optionContainerClassName: string
    theme?: ThemeType
    autoApply?: boolean
    keepQuickSelectOpen?: boolean
  }
>

const DateTimePicker = ({
  startTime = {
    mode: TIME_RANGE_MODES.relativeTime,
    value: DEFAULT_RELATIVE_TIME,
  },
  endTime = { mode: TIME_RANGE_MODES.now, value: TIME_RANGE_MODES.now },
  onChange,
  timezone,
  className,
  theme = THEMES.light,
  optionContainerClassName,
  autoApply = true,
  children,
  keepQuickSelectOpen = false,
}: DatetimePickerProp): ReactElement => {
  const [start, setStart] = useState(startTime)
  const [end, setEnd] = useState(endTime)

  const [timeOptionType, setTimeOptionType] = useState<
    TimeOptionsType | undefined
  >(keepQuickSelectOpen ? TIME_OPTIONS_TYPES.quickMenu : undefined)

  const ref = useRef(null)

  const normalizedStart = useMemo(() => normalizeTimeToUtcStr(start), [start])
  const normalizedEnd = useMemo(() => normalizeTimeToUtcStr(end), [end])
  const isRangeValid = useMemo(
    () => isDateTimeRangeValid(normalizedStart, normalizedEnd),
    [normalizedStart, normalizedEnd]
  )

  const hideTimeOption = useCallback(setTimeOptionType, [setTimeOptionType])

  const onChangeTimeRange = useCallback(
    (newStart: DatetimePickerTime, newEnd: DatetimePickerTime) => {
      const normalizedStartTime = normalizeTimeToUtcStr(newStart)
      const normalizedEndTime = normalizeTimeToUtcStr(newEnd)

      if (isDateTimeRangeValid(normalizedStartTime, normalizedEndTime)) {
        onChange({
          startTime: normalizedStartTime,
          endTime: normalizedEndTime,
        })
      }
    },
    [onChange]
  )

  const updateTimeRange = useCallback(
    (newStartTime: DatetimePickerTime, newEndTime: DatetimePickerTime) => {
      if (!isEqual(start, newStartTime)) {
        setStart(newStartTime)
      }
      if (!isEqual(end, newEndTime)) {
        setEnd(newEndTime)
      }
      onChangeTimeRange(newStartTime, newEndTime)
    },
    [start, end, onChangeTimeRange]
  )

  const onQuickSelectChange = useCallback(
    (
      value: {
        startTime: DatetimePickerTime
        endTime: DatetimePickerTime
      },
      shouldClose: boolean
    ) => {
      const { startTime: newStartTime, endTime: newEndTime } = value
      updateTimeRange(newStartTime, newEndTime)
      if (shouldClose) {
        hideTimeOption(undefined)
      }
    },
    [updateTimeRange, hideTimeOption]
  )

  useClickAway(ref, () => {
    hideTimeOption(undefined)
  })

  const onStartChange = useCallback(
    (value: DatetimePickerTime) => {
      setStart(value)
      onChangeTimeRange(value, end)
    },
    [onChangeTimeRange, end]
  )

  const onEndChange = useCallback(
    (value: DatetimePickerTime) => {
      setEnd(value)
      onChangeTimeRange(start, value)
    },
    [onChangeTimeRange, start]
  )

  const renderTimeOptions = useCallback(
    (type?: TimeOptionsType) => {
      const sharedProps = { timezone }
      return switchcaseF({
        [TIME_OPTIONS_TYPES.quickMenu]: () => (
          <QuickMenu
            {...sharedProps}
            start={start}
            onChange={onQuickSelectChange}
          />
        ),
        [TIME_OPTIONS_TYPES.start]: () => (
          <DatetimeContent
            {...sharedProps}
            label='StartDate'
            onChange={onStartChange}
            value={start}
            selectedNow={false}
          />
        ),
        [TIME_OPTIONS_TYPES.end]: () => (
          <DatetimeContent
            {...sharedProps}
            label='EndDate'
            onChange={onEndChange}
            value={end}
            selectedNow
          />
        ),
      })(() => <></>)(type)
    },
    [timezone, start, end, onQuickSelectChange, onStartChange, onEndChange]
  )

  const selectTimeOption = useCallback(
    (type: TimeOptionsType) =>
      setTimeOptionType(type === timeOptionType ? undefined : type),
    [timeOptionType]
  )
  const isLightTheme = useMemo(() => theme === THEMES.light, [theme])

  const containerCss = useMemo(
    () => ({
      backgroundColor: isLightTheme ? '#fff' : '#ffffff12',
    }),
    [isLightTheme]
  )

  const hasTimeChanged = useMemo(
    () =>
      (!_.isEqual(normalizedStart, normalizeTimeToUtcStr(startTime)) ||
        !_.isEqual(normalizedEnd, normalizeTimeToUtcStr(endTime))) &&
      isRangeValid,
    [normalizedStart, startTime, normalizedEnd, endTime, isRangeValid]
  )

  return (
    <>
      <div
        className={`d-flex ${scss.container} ${className}`}
        style={containerCss}
      >
        <IconButton
          icon='GoCalendar'
          onClick={() => selectTimeOption(TIME_OPTIONS_TYPES.quickMenu)}
        />
        <div className='d-flex flex-grow-1 align-items-center'>
          <DatetimeAnchor
            value={start}
            toggleActive={() => selectTimeOption(TIME_OPTIONS_TYPES.start)}
            isRangeValid={isRangeValid}
            timezone={timezone}
            theme={theme}
          />
          <MdArrowForward />
          <DatetimeAnchor
            value={end}
            toggleActive={() => selectTimeOption(TIME_OPTIONS_TYPES.end)}
            isRangeValid={isRangeValid}
            timezone={timezone}
            theme={theme}
          />
        </div>
        {!autoApply && hasTimeChanged && children}
      </div>
      <div className={optionContainerClassName} ref={ref}>
        {renderTimeOptions(timeOptionType)}
      </div>
    </>
  )
}

const isDatetimeObjValid = (datetime?: DatetimePickerTime): boolean => {
  const { mode, value } = datetime || {}
  if (!datetime || !mode || !value) return false

  return !!(mode === TIME_RANGE_MODES.absoluteTime
    ? isTimeValidWithIsoFormat(value)
    : value)
}

const DateTimePickerContainer = (props: DatetimePickerProp): ReactElement => {
  const { startTime, endTime, onChange, keepQuickSelectOpen = false } = props

  const [timeProps, setTimeProps] = useState<TimeProps>()
  const [shouldQuickSelectOpen, settQuickSelectOpen] = useState<boolean>(false)

  useMount(() => {
    const isStartTimeValid = isDatetimeObjValid(startTime)
    const newStart = isStartTimeValid
      ? startTime
      : {
          value: getPreviousUtcDate({
            interval: 15,
            intervalUnit: DATE_UNIT_TYPES.minutes,
          })?.toISOString(),
          mode: TIME_RANGE_MODES.absoluteTime,
        }

    const isEndTimeValid = isDatetimeObjValid(endTime)
    const newEnd = isEndTimeValid
      ? endTime
      : {
          value: getUtcDateTimeString(),
          mode: TIME_RANGE_MODES.absoluteTime,
        }

    setTimeProps({ startTime: newStart, endTime: newEnd })

    if (!isStartTimeValid || !isEndTimeValid) {
      onChange({
        startTime: normalizeTimeToUtcStr(newStart),
        endTime: normalizeTimeToUtcStr(newEnd),
      })
      settQuickSelectOpen(keepQuickSelectOpen)
    }
  })

  return timeProps ? (
    <DateTimePicker
      {...props}
      {...timeProps}
      keepQuickSelectOpen={shouldQuickSelectOpen}
    />
  ) : (
    <></>
  )
}

export default DateTimePickerContainer
