// libraries
import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

// constants
import { NON_VISIBLE } from 'constants/common'
import { PROPERTY_VARIABLE_TYPES } from 'constants/filter'

// utils
import {
  useAssetWidgetConfigs,
  useBaseAssetWidgetPanel,
} from 'components/assets/assetsProfile/hooks'

import { getAssetProfilePropertiesList } from 'helpers/asset'

// components
import {
  PropertiesList,
  PropertyPicker,
  HideValueToggle,
  TitleWithSort,
  MultiSelect,
  TitleWithTooltip,
} from 'components/common'

const PROPERTY_IDENTIFIER = 'name'

const MAX_PROPERTIES_LENGTH = 6

const AssetPropertiesTableWidgetPanel = ({ widget }) => {
  const {
    enableRelationship,
    renderAssetToggleAndRelatedMultiSelect,
    propertiesMetadata,
    isAssetRelationshipMany,
    propertyOptions,
  } = useBaseAssetWidgetPanel({ widget })

  const {
    settings: {
      properties: sortedPickedProperties,
      summaryProperties,
      hideInvalidValues = false,
      enableSort = false,
      sortByProperty,
      isAscendingOrder,
      tableStyle = 'striped',
      singlePropertyModeEnabled = false,
    },
  } = widget

  const { updateWidgetConfigsSettings } = useAssetWidgetConfigs(widget)

  const onPropertiesListChange = useCallback(
    items => {
      const filteredProperties = _(items)
        .reject(NON_VISIBLE)
        .map(PROPERTY_IDENTIFIER)
        .value()
      updateWidgetConfigsSettings({ properties: filteredProperties })
    },
    [updateWidgetConfigsSettings]
  )

  const propertiesList = useMemo(
    () =>
      getAssetProfilePropertiesList({
        propertiesMetadata,
        identifier: PROPERTY_IDENTIFIER,
        properties: sortedPickedProperties,
        onlyArrayProperties: singlePropertyModeEnabled,
      }),
    [propertiesMetadata, sortedPickedProperties, singlePropertyModeEnabled]
  )

  const renderPropertiesList = () => {
    return (
      !_.isEmpty(propertiesList) && (
        <PropertiesList
          title='Arrange Properties'
          properties={propertiesList}
          onChange={onPropertiesListChange}
          identity={PROPERTY_IDENTIFIER}
          searchIdentifier='displayName'
          singlePropertyModeEnabled={singlePropertyModeEnabled}
        />
      )
    )
  }

  return (
    <>
      <div className='groupOption'>
        <div className='groupOptionTitle'>
          <TitleWithTooltip title='Table Style' />
        </div>
        <div className='groupOptionContent'>
          <MultiSelect
            value={tableStyle}
            options={[
              { label: 'Striped', value: 'striped' },
              { label: 'line', value: 'bordered' },
              { label: 'None', value: 'borderless' },
            ]}
            onChange={options => {
              updateWidgetConfigsSettings({
                tableStyle: options.value,
              })
            }}
            isMulti={false}
            isClearable={false}
          />
        </div>
      </div>
      <div className='groupOption'>
        {renderAssetToggleAndRelatedMultiSelect()}
      </div>
      {enableRelationship && isAssetRelationshipMany && (
        <div className='groupOption'>
          <div className='groupOptionTitle'>
            <TitleWithTooltip
              title={`Summary Properties (max: ${MAX_PROPERTIES_LENGTH})`}
            />
          </div>
          <div className='groupOptionContent'>
            <PropertyPicker
              property={summaryProperties}
              onChange={val => {
                updateWidgetConfigsSettings({
                  summaryProperties: val,
                })
              }}
              propertyOptions={propertyOptions}
              isMulti
              isClearable
              useOptionValueOnly
              maxOptionsLength={MAX_PROPERTIES_LENGTH}
            />
          </div>
          <TitleWithSort
            title='Sort by'
            displaySort
            enableSort={enableSort}
            isAscendingOrder={isAscendingOrder}
            enableSortKey='enableSort'
            onChange={updateWidgetConfigsSettings}
            className='mt-3'
          />
          <div className='groupOptionContent'>
            <PropertyPicker
              property={sortByProperty}
              onChange={val => {
                updateWidgetConfigsSettings({
                  sortByProperty: val,
                })
              }}
              propertyOptions={propertyOptions}
              isMulti={false}
              isClearable
              useOptionValueOnly
              propertyTypes={[
                PROPERTY_VARIABLE_TYPES.number,
                PROPERTY_VARIABLE_TYPES.string,
                PROPERTY_VARIABLE_TYPES.boolean,
              ]}
            />
          </div>
        </div>
      )}

      <div className='mt-3'>
        <HideValueToggle
          label='Hide empty values'
          hideValue={hideInvalidValues}
          onChange={updateWidgetConfigsSettings}
          onChangeValueKey='hideInvalidValues'
        />
      </div>

      <div className='mt-3'>
        <HideValueToggle
          label='Use a specific property as a datasource'
          hideValue={singlePropertyModeEnabled}
          onChange={updateWidgetConfigsSettings}
          onChangeValueKey='singlePropertyModeEnabled'
        />
      </div>

      {renderPropertiesList()}
    </>
  )
}

AssetPropertiesTableWidgetPanel.propTypes = {
  widget: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    settings: PropTypes.shape({
      assetProfileId: PropTypes.string.isRequired,
      relatedAssetRelationshipId: PropTypes.string,
      properties: PropTypes.arrayOf(PropTypes.string),
      summaryProperties: PropTypes.arrayOf(PropTypes.string),
      hideInvalidValues: PropTypes.bool,
      sortByProperty: PropTypes.string,
      enableSort: PropTypes.bool,
      isAscendingOrder: PropTypes.bool,
      singlePropertyModeEnabled: PropTypes.bool,
      tableStyle: PropTypes.string,
    }).isRequired,
  }).isRequired,
}

export default AssetPropertiesTableWidgetPanel
