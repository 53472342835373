import { ReactElement, useRef, useEffect, useState, FocusEvent } from 'react'
import { Field, useField } from 'react-final-form'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { Input } from 'components/common/Form'

import scss from './index.module.scss'
import { EditorPageHeaderProps } from '.'

const FadeWrapper = styled.div<{ showFade?: boolean }>`
  position: relative;
  max-width: calc(50% - 50px);
  overflow: hidden;

  ${({ showFade }) =>
    showFade
      ? css`
          &:after {
            content: '';
            position: absolute;
            width: 30px;
            right: 0;
            top: 0;
            bottom: 0;
            background: linear-gradient(
              90deg,
              rgba(0, 0, 0, 0) 0%,
              rgba(255, 255, 255, 1) 75%
            );
          }
        `
      : ''}
`

const Subtitle = ({
  descriptionName,
  descriptionPlaceholder,
  isDisabled,
}: Pick<
  EditorPageHeaderProps,
  'descriptionName' | 'descriptionPlaceholder' | 'isDisabled'
>): ReactElement => {
  const inputRef = useRef<HTMLInputElement>()
  const [showFade, setShowFade] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const fieldValue = useField(descriptionName).input.value

  useEffect(() => {
    const { offsetWidth = 0, scrollWidth = 0 } = inputRef.current || {}
    const isOverflowing = scrollWidth > offsetWidth
    if (isOverflowing !== showFade) setShowFade(isOverflowing)
  }, [fieldValue, showFade])

  return (
    <FadeWrapper showFade={!isFocused && showFade}>
      <Field
        name={descriptionName}
        {...(!isDisabled && { placeholder: descriptionPlaceholder })}
        className={`${scss.input} subNav`}
        autoComplete='off'
        disabled={isDisabled}
        data-testid='editor-subTitle'
      >
        {props => {
          const { input } = props
          const inputProps = {
            ...input,
            onFocus: (e: FocusEvent<HTMLInputElement>) => {
              input.onFocus(e)
              setIsFocused(true)
            },
            onBlur: (e: FocusEvent<HTMLInputElement>) => {
              input.onBlur(e)
              setIsFocused(false)
            },
          }
          return <Input {...props} input={inputProps} ref={inputRef} />
        }}
      </Field>
    </FadeWrapper>
  )
}

export default Subtitle
