import _ from 'lodash'
import moment from 'moment-timezone'
import { PROPERTY_TIME } from 'constants/common'

export const pomIsExpired = ({
  row,
  observedPropertyName,
  datasetMetadata,
} = {}) => {
  const hintTimeDeltaThresholdSeconds = _.get(
    datasetMetadata,
    'hints.pom.time.deltaThresholdSeconds'
  )
  const pomField = _.get(datasetMetadata, 'hints.pom.time.property', 'pomTime')
  const time = _.get(row, PROPERTY_TIME)
  const pomTime = _.get(row, ['properties', pomField, observedPropertyName])

  return (
    time &&
    pomTime &&
    hintTimeDeltaThresholdSeconds &&
    moment(time).diff(pomTime, 'seconds') > hintTimeDeltaThresholdSeconds
  )
}

export const pomTimeInfo = ({
  row,
  observedPropertyName,
  datasetMetadata,
} = {}) => {
  const pomField = _.get(datasetMetadata, 'hints.pom.time.property', 'pomTime')
  const time = _.get(row, PROPERTY_TIME)
  const pomTime = _.get(row, ['properties', pomField, observedPropertyName])
  const deltaTime = moment(pomTime).diff(time)

  return pomTime
    ? {
        deltaTime,
        deltaTimeHumanized: moment.duration(deltaTime).humanize(),
      }
    : null
}

export const pomLastInfo = ({
  row,
  observedPropertyName,
  datasetMetadata,
} = {}) => {
  const pomField = _.get(datasetMetadata, 'hints.pom.last.property', 'pomLast')

  const hintLastDeltaDirection = _.get(
    datasetMetadata,
    'hints.pom.last.deltaDirection'
  )
  const currentValue = _.get(row, ['properties', observedPropertyName])
  const pomLastValue = _.get(row, [
    'properties',
    pomField,
    observedPropertyName,
  ])

  if (hintLastDeltaDirection) {
    let lastDeltaDirection = 'same'
    if (currentValue < pomLastValue) {
      lastDeltaDirection = 'down'
    } else if (currentValue > pomLastValue) {
      lastDeltaDirection = 'up'
    }
    return {
      lastDeltaDirection,
      lastDeltaDirectionSuffix: { down: ' ↓', up: ' ↑' }[lastDeltaDirection],
    }
  }
  return null
}

export const pomColorInfo = ({
  row,
  observedPropertyName,
  datasetMetadata,
} = {}) => {
  const pomField = _.get(
    datasetMetadata,
    'hints.pom.color.property',
    'pomColor'
  )

  const hintPomColorEnabled = _.get(datasetMetadata, 'hints.pom.color')
  const pomColorValue = _.get(row, [
    'properties',
    pomField,
    observedPropertyName,
  ])
  if (hintPomColorEnabled) {
    return {
      color: pomColorValue,
    }
  }
  return null
}

export const pomBackgroundColorInfo = ({
  row,
  observedPropertyName,
  datasetMetadata,
} = {}) => {
  const pomField = _.get(
    datasetMetadata,
    'hints.pom.backgroundColor.property',
    'pomBackgroundColor'
  )

  const hintPomBackgroundColorEnabled = _.get(
    datasetMetadata,
    'hints.pom.backgroundColor'
  )
  const pomBackgroundColorValue = _.get(row, [
    'properties',
    pomField,
    observedPropertyName,
  ])
  if (hintPomBackgroundColorEnabled) {
    return {
      backgroundColor: pomBackgroundColorValue,
    }
  }
  return null
}

export const pomTagInfo = ({
  row,
  observedPropertyName,
  datasetMetadata,
} = {}) => {
  const pomField = _.get(datasetMetadata, 'hints.pom.tag.property', 'pomTag')

  const hintPomTagEnabled = _.get(datasetMetadata, 'hints.pom.tag')
  const pomTagValue = _.get(row, ['properties', pomField, observedPropertyName])
  if (hintPomTagEnabled) {
    return {
      tag: pomTagValue,
    }
  }
  return null
}
