import { useMemo } from 'react'
import { useLocation, Location } from 'react-router-dom'
import DOMPurify from 'dompurify'
import _ from 'lodash'

// https://github.dev/remix-run/react-router/blob/f3d3e05ec00c6950720930beaf74fecbaf9dc5b6/packages/react-router/lib/components.tsx#L333
const getSanitizedLocation = (
  location: Pick<Location, 'pathname' | 'search' | 'hash' | 'key'>
): Pick<Location, 'pathname' | 'search' | 'hash' | 'key'> =>
  _.mapValues(location, value => (value ? DOMPurify.sanitize(value) : value))

/** Removes any HTML from Location to prevent cross-site scripting due to user-provided value */
const useSanitizedLocation = (): Location => {
  const location = useLocation()
  return useMemo(() => {
    const { state = null, ...restLocation } = location
    return { state, ...getSanitizedLocation(restLocation) }
  }, [location])
}

export default useSanitizedLocation
