import { useRef, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment-timezone'

// constants
import {
  DEFAULT_CHART_TOOLTIP_OPTIONS,
  ECHARTS_AXIS_TYPE,
  DEFAULT_WIDGET_COLOUR_PALETTE,
  DEFAULT_DARK_THEME_CHART_TEXT_COLOUR,
  DEFAULT_DARK_THEME_CHART_SECONDARY_COLOUR,
  DEFAULT_LIGHT_THEME_CHART_TEXT_COLOUR,
  DEFAULT_LIGHT_THEME_CHART_SECONDARY_COLOUR,
} from 'constants/widget'
import { THEMES } from 'constants/colour'
import { getTimeFormatter } from 'constants/datetime'

// utils
import { getWidgetGrid } from 'helpers/widget'
import { displayValue, getRangeMinMaxObj } from 'helpers/utils'
import { isLightTheme } from 'helpers/colour'
import { useEcharts } from 'hooks'

const singleSeries = { type: 'bar' }

const getPropertyLabel = (propertyOptions, name): string =>
  _.get(
    _.find(propertyOptions, {
      value: name,
    }),
    'label'
  ) || _.capitalize(name)

export const get2dArrayDatasetTooltip = ({
  params,
  aggregationType,
  yAxisValuePropertyName,
  timezone,
  propertyOptions,
}) => {
  const { data } = params
  const result = `${_.capitalize(aggregationType)} ${getPropertyLabel(
    propertyOptions,
    yAxisValuePropertyName
  )}: ${displayValue(data[1])}`
  const tooltip = [displayValue(data[0], timezone), result]
  return tooltip.join('<br />')
}

export const getObjectDatasetTooltip = ({
  params,
  xAxisLabelPropertyName,
  yAxisValuePropertyName,
  xAxisSplitPropertyName,
  aggregationType,
  propertyOptions,
}) => {
  const { name, data, seriesName, dimensionNames, encode } = params

  const group = `${getPropertyLabel(
    propertyOptions,
    xAxisLabelPropertyName
  )}: ${name}`
  const splitBy = `${getPropertyLabel(
    propertyOptions,
    xAxisSplitPropertyName
  )}: ${seriesName}`
  const aggregation = `${_.capitalize(aggregationType)} ${getPropertyLabel(
    propertyOptions,
    yAxisValuePropertyName
  )}: ${displayValue(data[dimensionNames[encode.y[0]]])}`

  const tooltip = [group, splitBy, aggregation]
  return tooltip.join('<br />')
}

const BarWidget = ({
  dataset,
  colour,
  period,
  xAxisType,
  yAxisRange,
  xAxisLabelPropertyName,
  yAxisValuePropertyName,
  xAxisSplitPropertyName,
  aggregationType,
  title: widgetTitle,
  timezone,
  stack,
  customStyle,
  grid: widgetGrid,
  size,
  theme,
  showXAxisLabel = true,
  propertyOptions,
  ...rest
}) => {
  const lightTheme = useMemo(() => isLightTheme(theme), [theme])
  // https://echarts.apache.org/en/tutorial.html#Dataset
  // 1. 2dArrayDataset
  // 2. objectArrayDataset

  // profile bar - getDatasetFeaturesDataForEcharts - 2dArrayDataset - xAxis: time
  // {
  //   dimensions: ['time', 'speed'],
  //   source: [
  //     ['2019-12-05T22:43:44.000Z', 26],
  //     ...
  //   ]
  // }
  // widget feature bar - getSortedFeaturesValuesByProperty - 2dArrayDataset - xAxis: category
  // {
  //   dimensions: ['name', 'speed'],
  //   source: [
  //     ['Route 148 (7993)', 26],
  //     ...
  //   ]
  // }
  // widget group bar - getGroupedValuesByTimeBucket - 2dArrayDataset - xAxis: category
  // {
  //   dimensions: ['time', 'speed'],
  //   source: [['2019-12-01T00:00:00.000Z', 31.5]]
  // }
  // widget group bar - getGroupedValuesByProperty - 2dArrayDataset - xAxis: category
  // {
  //   dimensions: ['routeId', 'speed'],
  //   source: [
  //     ['47', 18],
  //     ...
  //   ]
  // }
  // widget group bar - getGroupedValuesByTimeBucketSplitByProperty - objectArrayDataset - xAxis: category
  // {
  //   dimensions: ['time', 'NW', 'SE'],
  //   source: [
  //     { time: '2019-12-05T22:00:00.000Z', NW: 28 },
  //      ...
  //   ]
  // }
  // widget group bar - getGroupedValuesByPropertySplitByProperty - objectArrayDataset - xAxis: category
  // {
  //   dimensions: ['routeId', 'NW', 'SE'],
  //   source: [
  //     { routeId: '148', NW: 26, SE: 41 },
  //     ...
  //   ]
  // }

  const is2dArrayDataset = useMemo(() => {
    return dataset.source.length > 0 && _.isArray(dataset.source[0])
  }, [dataset])

  const series = useMemo(() => {
    const seriesNum = is2dArrayDataset ? 1 : dataset.dimensions.length - 1
    return Array(seriesNum).fill({
      ...singleSeries,
      ...(stack && { stack }),
      showBackground: !!lightTheme,
    })
  }, [is2dArrayDataset, dataset.dimensions.length, stack, lightTheme])

  const chartRef = useRef()

  const yAxisMinMax = useMemo(() => getRangeMinMaxObj(yAxisRange), [yAxisRange])

  const getOptions = useCallback(
    ({ expanded } = {}) => {
      const visualMap = {
        ...yAxisMinMax,
        show: !!expanded,
        dimension: 1,
        orient: 'horizontal',
        top: 0,
        left: 'center',
        textStyle: { color: '#fff' },
      }

      const title = expanded && {
        show: false,
        text: widgetTitle,
        textStyle: {
          color: lightTheme
            ? DEFAULT_LIGHT_THEME_CHART_TEXT_COLOUR
            : DEFAULT_DARK_THEME_CHART_TEXT_COLOUR,
        },
        left: 'center',
      }

      const legend = expanded && {
        type: 'scroll',
        inactiveColor: '#777',
        pageIconColor: lightTheme ? '#000' : '#fff',
        pageIconInactiveColor: lightTheme ? '#000' : '#fff',
        textStyle: {
          color: lightTheme ? '#000' : '#fff',
        },
        pageTextStyle: {
          color: lightTheme ? '#000' : '#fff',
        },
      }

      const grid = getWidgetGrid({ widgetGrid, expanded })

      const sharedAxisOptions = {
        axisLine: {
          lineStyle: {
            color: lightTheme
              ? DEFAULT_LIGHT_THEME_CHART_SECONDARY_COLOUR
              : DEFAULT_DARK_THEME_CHART_SECONDARY_COLOUR,
          },
        },
        splitLine: {
          show: false,
        },
      }

      const xAxis = {
        ...sharedAxisOptions,
        type: xAxisType,
        axisLabel: {
          show: showXAxisLabel,
          color: lightTheme
            ? DEFAULT_LIGHT_THEME_CHART_TEXT_COLOUR
            : DEFAULT_DARK_THEME_CHART_TEXT_COLOUR,
          ...(xAxisType !== ECHARTS_AXIS_TYPE.time && {
            formatter: value => {
              return period
                ? moment.utc(value).format(getTimeFormatter(period))
                : _.truncate(value, { length: 6 })
            },
          }),
          ...(expanded && { rotate: 50, interval: 0 }),
        },
        axisTick: {
          show: false,
        },
      }

      const yAxis = {
        ...sharedAxisOptions,
        ...yAxisMinMax,
        axisLabel: {
          color: lightTheme
            ? DEFAULT_LIGHT_THEME_CHART_TEXT_COLOUR
            : DEFAULT_DARK_THEME_CHART_TEXT_COLOUR,
        },
        splitNumber: expanded ? 5 : 1,
      }

      const tooltip = {
        ...(!expanded && {
          // tooltip will be fixed on the right if mouse hovering on the left,
          // and on the left if hovering on the right.
          position: (pos, params, dom, rect, tooltipSize) => {
            const obj = { top: 5 }
            obj[['left', 'right'][+(pos[0] < tooltipSize.viewSize[0] / 2)]] = 5
            return obj
          },
        }),
        formatter: params => {
          return is2dArrayDataset
            ? get2dArrayDatasetTooltip({
                params,
                aggregationType,
                yAxisValuePropertyName,
                timezone,
                propertyOptions,
              })
            : getObjectDatasetTooltip({
                params,
                xAxisLabelPropertyName,
                yAxisValuePropertyName,
                xAxisSplitPropertyName,
                aggregationType,
                propertyOptions,
              })
        },
        ...DEFAULT_CHART_TOOLTIP_OPTIONS,
      }

      return {
        // https://echarts.apache.org/en/option.html#useUTC
        useUTC: !timezone,
        title,
        legend,
        grid,
        xAxis,
        yAxis,
        // visualMap
        ...(is2dArrayDataset && !colour ? { visualMap } : {}),
        tooltip,
        dataset,
        color: colour,
        series,
        animation: false,
      }
    },
    [
      yAxisMinMax,
      widgetTitle,
      lightTheme,
      widgetGrid,
      xAxisType,
      showXAxisLabel,
      timezone,
      is2dArrayDataset,
      colour,
      dataset,
      series,
      period,
      aggregationType,
      yAxisValuePropertyName,
      propertyOptions,
      xAxisLabelPropertyName,
      xAxisSplitPropertyName,
    ]
  )

  const { style } = useEcharts({
    ...rest,
    chartRef,
    getOptions,
    customStyle,
    size,
  })

  return <div ref={chartRef} style={style} />
}

BarWidget.propTypes = {
  dataset: PropTypes.shape({
    dimensions: PropTypes.array,
    source: PropTypes.array,
  }).isRequired,
  colour: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  period: PropTypes.string,
  yAxisRange: PropTypes.arrayOf(PropTypes.number),
  yAxisValuePropertyName: PropTypes.string,
  xAxisLabelPropertyName: PropTypes.string,
  xAxisSplitPropertyName: PropTypes.string,
  aggregationType: PropTypes.string,
  title: PropTypes.string,
  xAxisType: PropTypes.string,
  stack: PropTypes.string,
  customStyle: PropTypes.shape({}),
  size: PropTypes.string,
  timezone: PropTypes.string,
  grid: PropTypes.shape({}),
  theme: PropTypes.oneOf([THEMES.dark, THEMES.light]),
  showXAxisLabel: PropTypes.bool,
  propertyOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

BarWidget.defaultProps = {
  colour: DEFAULT_WIDGET_COLOUR_PALETTE,
  period: '',
  yAxisRange: [],
  yAxisValuePropertyName: '',
  xAxisLabelPropertyName: undefined,
  xAxisSplitPropertyName: undefined,
  title: '',
  xAxisType: ECHARTS_AXIS_TYPE.category,
  stack: undefined,
  customStyle: undefined,
  size: 'regular',
  aggregationType: undefined,
  timezone: undefined,
  grid: undefined,
  theme: THEMES.dark,
  showXAxisLabel: true,
}

const BarWidgetContainer = props => {
  const { dataset } = props
  return _.isEmpty(dataset) ? <></> : <BarWidget {...props} />
}

BarWidgetContainer.propTypes = {
  dataset: PropTypes.shape({}).isRequired,
}

export default BarWidgetContainer
