// libraries
import { useState, useEffect, Dispatch } from 'react'
import _ from 'lodash'

import type { IssueGeojson } from 'types/issue'

export const useCurrentActiveIssue = (
  issues?: IssueGeojson[]
): [
  currentActiveIssue: IssueGeojson | undefined,
  setCurrentActiveIssue: Dispatch<IssueGeojson | undefined>
] => {
  const [currentActiveIssue, setCurrentActiveIssue] = useState<IssueGeojson>()

  const { id: currentActiveIssueId } = currentActiveIssue || {}

  useEffect(() => {
    const updateCurrentActiveIssue = () => {
      if (!currentActiveIssue) return
      const newCurrentActiveIssue = _.find(issues, {
        id: currentActiveIssueId,
      })
      setCurrentActiveIssue(oldCurrentActiveIssue =>
        _.isEqual(oldCurrentActiveIssue, newCurrentActiveIssue)
          ? oldCurrentActiveIssue
          : newCurrentActiveIssue
      )
    }

    updateCurrentActiveIssue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issues])

  return [currentActiveIssue, setCurrentActiveIssue]
}

export default useCurrentActiveIssue
