import { ReactElement } from 'react'
import { Field } from 'react-final-form'

import { StyledField } from 'components/common/Form'
import { NumericInput } from 'components/common'

const NumericField = ({
  name,
  label,
  description,
  disabled,
  required,
  containerClassName = '',
}: {
  name: string
  label?: string
  description?: string
  disabled?: boolean
  required?: boolean
  containerClassName?: string
}): ReactElement => {
  return (
    <Field name={name}>
      {({ input: { onChange, value } }) => (
        <StyledField
          name={name}
          label={label}
          description={description}
          required={required}
          containerClassName={containerClassName}
        >
          <NumericInput
            value={value}
            onChange={onChange}
            className='form-control text-start'
            disabled={disabled}
            allowEmpty
          />
        </StyledField>
      )}
    </Field>
  )
}

export default NumericField
