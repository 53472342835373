import { CompositeLayer } from '@deck.gl/core/typed'
import { ScatterplotLayer } from '@deck.gl/layers/typed'
import { getMultipleLabelsTextLayerSpec } from 'components/map/layers/deckLayers/textLayerHelper'
import { getMapDeckLayerFilterProps } from 'helpers/map'

export default class MultipleLabelsScatterplotLayer extends CompositeLayer {
  renderLayers() {
    const {
      id,
      data,
      visible,
      radiusScale,
      opacity,
      radiusMinPixels,
      radiusMaxPixels,
      pickable,
      autoHighlight,
      highlightedObjectIndex,
      getPosition,
      getRadius,
      getFillColor,
      updateTriggers,
      propertyLabels,
      currentZoom,
      timezone,
    } = this.props

    const filterProps = getMapDeckLayerFilterProps(this.props)

    return [
      // point
      new ScatterplotLayer(
        this.getSubLayerProps({
          ...filterProps,
          // `getSubLayerProps` will concat the parent layer id with this id
          id: `${id}-point`,
          data,
          visible,

          radiusScale,
          opacity,
          radiusMinPixels,
          radiusMaxPixels,

          getPosition,
          getRadius,
          getFillColor,

          pickable,
          autoHighlight,
          highlightedObjectIndex,

          updateTriggers,
        })
      ),
      // the labels
      ...getMultipleLabelsTextLayerSpec({
        ...filterProps,
        propertyLabels,
        timezone,
        data,
        getPosition,
        currentZoom,
        visible,
      }),
    ]
  }
}

MultipleLabelsScatterplotLayer.layerName = ' MultipleLabelsScatterplotLayer'

MultipleLabelsScatterplotLayer.defaultProps = {
  // points
  getPosition: { type: 'accessor', value: d => d.geometry.coordinates },
  radiusScale: { type: 'number', min: 0, value: 1 },
  radiusMinPixels: { type: 'number', min: 0, value: 0 }, //  min point radius in pixels
  radiusMaxPixels: { type: 'number', min: 0, value: Number.MAX_SAFE_INTEGER }, // max point radius in pixels
  getRadius: { type: 'accessor', value: 1 },
  getFillColor: { type: 'accessor', value: [0, 0, 0, 255] },
  pickable: true,
  autoHighlight: true,
}
