import {
  createContext,
  useContext,
  useReducer,
  ReactElement,
  Dispatch,
  SetStateAction,
} from 'react'

import { initialState, reducer } from './reducers'
import { useActions } from './actions'
import { useSelectors } from './selectors'

type State = {
  state: typeof initialState
  dispatch: Dispatch<SetStateAction<typeof initialState>>
  actions: ReturnType<typeof useActions>
  selectors: ReturnType<typeof useSelectors>
}

export const StateContext = createContext<State>({} as State)

export const StateProvider = ({
  children,
}: {
  children: ReactElement
}): ReactElement => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const actions = useActions(state, dispatch)
  const selectors = useSelectors(state)

  return (
    <StateContext.Provider value={{ state, dispatch, actions, selectors }}>
      {children}
    </StateContext.Provider>
  )
}

export const useStateValue = (): State => useContext(StateContext)
