import { ReactElement } from 'react'
import _ from 'lodash'
import { FieldProps } from '@rjsf/utils'

import { FORM_BUILDER_WIDGET_PROPERTY_KEYS } from 'constants/formBuilder'
import { HeadingSizes } from 'types/formBuilder'
import scss from './index.module.scss'
import { StyledHeading, StyledSubHeading } from './style'

export interface TitleFieldProps extends Partial<FieldProps> {
  title: string
}

const TitleField = ({
  schema,
  uiSchema,
}: Partial<FieldProps>): ReactElement => {
  const { title, description } = schema
  const size = _.get(uiSchema, [
    'ui:options',
    FORM_BUILDER_WIDGET_PROPERTY_KEYS.size,
  ]) as HeadingSizes

  return (
    <>
      <div className={scss.container}>
        <StyledHeading size={size}>{title}</StyledHeading>
        <StyledSubHeading ize={size}>{description}</StyledSubHeading>
        <hr className={scss.hr} />
      </div>
    </>
  )
}

export default TitleField
