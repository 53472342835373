// libraries
import { ReactElement, useMemo, useState } from 'react'
import { useFeatureFlag } from 'hooks'

// utils
import type { PageHeaderTabsProps } from 'components/common/PageHeader'

// constants
import { FEATURES } from 'constants/settings'
import { ENTITIES, PAGE_HEADER_TAB_POSITION } from 'constants/common'
import { FORM_GALLERY_VIEWS, FormGalleryView } from './constants'

// components
import { Forms, FormLists } from './tabs'

export type FormGalleryPassProps = {
  selectedView: FormGalleryView
  setSelectedView: (v: FormGalleryView) => void
} & PageHeaderTabsProps

const FormGallery = (): ReactElement => {
  const { [FEATURES.JSON_FORM_LIST_BUILDER]: listEnabled } = useFeatureFlag()

  const [selectedView, setSelectedView] = useState<FormGalleryView>(
    FORM_GALLERY_VIEWS.form
  )

  const FORM_GALLERY_TABS = useMemo(
    () => [
      { value: ENTITIES.form, label: 'Forms' },
      ...(listEnabled ? [{ value: ENTITIES.formList, label: 'Lists' }] : []),
    ],
    [listEnabled]
  )

  const props =
    FORM_GALLERY_TABS.length === 1
      ? { onTabSelected: setSelectedView, selectedTab: selectedView }
      : ({
          tabs: FORM_GALLERY_TABS,
          tabsPosition: PAGE_HEADER_TAB_POSITION.left,
          onTabSelected: setSelectedView,
          selectedTab: selectedView,
        } as FormGalleryPassProps)

  const Gallery = {
    [FORM_GALLERY_VIEWS.form]: Forms,
    [FORM_GALLERY_VIEWS.formList]: FormLists,
  }[selectedView || FORM_GALLERY_VIEWS.form]

  return <Gallery {...props} />
}

export default FormGallery
