// libraries
import { useRef, useState, useCallback, useMemo, ReactElement } from 'react'
import _ from 'lodash'
import { useGetSet, useMount, useUpdateEffect } from 'react-use'
import SplitPane from 'react-split-pane'
import { ViewType } from '@sensorup/react-images'
import { Timeline } from 'vis-timeline/peer'

// components
import { Map } from 'components/common'
import { ModalCarousel } from 'components/common/Image/ModalCarousel'

// utils
import { useMapStateValue } from 'contexts'
import { useTimezone } from 'hooks'
import {
  updateTimelineOptions,
  getTimelineMomentOption,
} from 'components/common/Timeline/HistoricalTimeline/utils'
import { useMapViewState } from 'components/map/hooks'
import {
  getTooltip,
  getJourneyDeckLayer,
  getGroupSettings,
  getDefaultTimeline,
  getTimelineIdLookup,
  getVisTimelineGroups,
  sortFilteredDataBetween,
  checkJourneyDataLength,
  updateJourneyTimelineItems,
  updateJourneyTimelineOptions,
  getJournalLayerAdvancedColourStyle,
} from 'components/map/controls/MapPopup/Journey/utils'

// constants
import { LAYER_VIS_CONFIGS } from 'components/map/layers/deckLayers/layerFactory'
import { PROPERTY_VARIABLE_FORMATS } from 'constants/filter'

import type { MapLayer } from 'types/map'
import type { Payload, DatasetMetadata, Colours } from 'types/common'
import type { Feature } from 'geojson'

// styles
import 'vis-timeline/dist/vis-timeline-graph2d.css'
import './timeline.scss'
import scss from './index.module.scss'

type JourneyProps = {
  layer: MapLayer
  popupData: Payload
  datasetMetadata: DatasetMetadata
}

const Journey = ({
  layer,
  popupData,
  datasetMetadata,
}: JourneyProps): ReactElement => {
  const journeyTimeline = useRef<HTMLDivElement>()

  const { timezone } = useTimezone()
  const [getTimelineInstance, setTimelineInstance] = useGetSet<Timeline>(null)

  const { map, mapRef, mapCanvasRef } = useMapStateValue()
  const { selectedDateTimeRange } = map
  const { identityProperty } = datasetMetadata

  const [deckLayers, setDeckLayers] = useState<[]>([])
  const [timelineIdLookup, setTimelineIdLookup] = useState<{
    [key: string]: Feature
  }>({})

  const [viewerIsOpen, setViewerIsOpen] = useState<boolean>(false)
  const [images, setImages] = useState<ViewType[]>([])

  const { viewState, onViewStateChange, fitFeatureBound, fitFeaturesBound } =
    useMapViewState({
      mapRef,
      mapCanvasRef,
    })

  const sortedFilteredData = useMemo(() => {
    return sortFilteredDataBetween(popupData, selectedDateTimeRange)
  }, [popupData, selectedDateTimeRange])

  const hasVisTimelineGroups = useMemo(
    () => !!getVisTimelineGroups(datasetMetadata),
    [datasetMetadata]
  )

  const groupsData = useMemo(() => {
    return getGroupSettings(datasetMetadata)
  }, [datasetMetadata])

  const hasStyleHints = useMemo(() => {
    return _.has(sortedFilteredData, '0.properties.hints.style')
  }, [sortedFilteredData])

  const advancedColourStyle = useMemo(() => {
    return getJournalLayerAdvancedColourStyle({
      layer,
      groupsData,
      hasStyleHints,
      hasVisTimelineGroups,
    })
  }, [hasStyleHints, hasVisTimelineGroups, groupsData, layer])

  const imagesProperties = useMemo(
    () =>
      _.filter(datasetMetadata?.properties, {
        format: PROPERTY_VARIABLE_FORMATS.image,
      }),
    [datasetMetadata?.properties]
  )

  const updateJourneyTimeline = useCallback(() => {
    const timelineInstance = getTimelineInstance()
    checkJourneyDataLength(sortedFilteredData)

    const newTimelineIdLookup = getTimelineIdLookup(sortedFilteredData)
    setTimelineIdLookup(newTimelineIdLookup)

    updateJourneyTimelineOptions(
      timelineInstance,
      groupsData,
      sortedFilteredData
    )
    updateJourneyTimelineItems(
      timelineInstance,
      groupsData,
      sortedFilteredData,
      imagesProperties
    )
  }, [getTimelineInstance, sortedFilteredData, groupsData, imagesProperties])

  const multipleItemsSelectHandler = useCallback(
    data => {
      const { type } = layer
      const filteredFeatures = _.values(_.pick(timelineIdLookup, data.items))
      const { radius = LAYER_VIS_CONFIGS.radius.defaultValue } = layer.style[
        type
      ] as {
        colourType: string
        fillColour: Colours
        radius: number
      }

      const allFeaturesLayerStyle = {
        ...advancedColourStyle,
        opacity: 50,
        radius: radius * 0.5,
      }

      const allFeaturesDeckLayer = getJourneyDeckLayer(
        layer,
        allFeaturesLayerStyle,
        sortedFilteredData,
        identityProperty
      )

      const filteredFeaturesLayerStyle = {
        ...advancedColourStyle,
        opacity: 100,
        radius: radius * 1,
      }

      const filteredFeaturesDeckLayer = getJourneyDeckLayer(
        layer,
        filteredFeaturesLayerStyle,
        filteredFeatures,
        identityProperty
      )
      return [filteredFeaturesDeckLayer, allFeaturesDeckLayer]
    },
    [
      advancedColourStyle,
      identityProperty,
      layer,
      sortedFilteredData,
      timelineIdLookup,
    ]
  )

  const allItemsSelectHandler = useCallback(() => {
    return [
      getJourneyDeckLayer(
        layer,
        advancedColourStyle,
        sortedFilteredData,
        identityProperty
      ),
    ]
  }, [advancedColourStyle, identityProperty, layer, sortedFilteredData])

  const timelineSelect = useCallback(
    data => {
      const newDeckLayers = data.items.length
        ? multipleItemsSelectHandler(data)
        : allItemsSelectHandler()
      setDeckLayers(newDeckLayers)

      if (data.items.length) {
        const filteredFeature = _(timelineIdLookup)
          .pick(data.items)
          .values()
          .first()

        const newImages = _(imagesProperties)
          .map(property => {
            const { properties } = filteredFeature || {}
            const value = properties && properties[property.key]
            return (
              value && {
                source: value,
                caption: properties?.name,
              }
            )
          })
          .compact()
          .value()

        setImages(newImages)

        if (_.isEmpty(newImages)) return
        setViewerIsOpen(true)
      } else {
        setViewerIsOpen(false)
      }
    },
    [
      multipleItemsSelectHandler,
      allItemsSelectHandler,
      timelineIdLookup,
      imagesProperties,
    ]
  )

  const timelineDoubleClick = useCallback(
    data => {
      const feature = timelineIdLookup[data.item]
      if (!feature) return
      fitFeatureBound(feature)
    },
    [timelineIdLookup, fitFeatureBound]
  )

  useMount(() => {
    const initMap = () => {
      const deckLayer = getJourneyDeckLayer(
        layer,
        advancedColourStyle,
        sortedFilteredData,
        identityProperty
      )
      setDeckLayers([deckLayer])
      fitFeaturesBound(popupData, false)
    }

    const initTimeline = () => {
      const newTimeline = getDefaultTimeline(journeyTimeline, timezone)
      setTimelineInstance(newTimeline)
    }

    initMap()
    initTimeline()
    updateJourneyTimeline()
  })

  useUpdateEffect(() => {
    const timelineInstance = getTimelineInstance()
    if (!timelineInstance) return

    updateTimelineOptions(timelineInstance, {
      moment: getTimelineMomentOption(timezone),
    })
  }, [timezone])

  useUpdateEffect(() => {
    const timelineInstance = getTimelineInstance()
    if (!timelineInstance) return

    timelineInstance.on('select', timelineSelect)
    timelineInstance.on('doubleClick', timelineDoubleClick)
  }, [timelineIdLookup])

  const closeLightBox = () => {
    setViewerIsOpen(false)
  }

  return (
    <>
      <div className={scss.container}>
        <SplitPane split='horizontal' defaultSize='50%'>
          <Map
            map={map}
            layersList={deckLayers}
            viewState={viewState}
            onViewStateChange={onViewStateChange}
            mapCanvasRef={mapCanvasRef}
            getTooltip={getTooltip(identityProperty)}
          />
          <div
            className={scss.timeline}
            ref={journeyTimeline}
            id='journeyTimeline'
          />
        </SplitPane>
      </div>
      <ModalCarousel
        viewerIsOpen={viewerIsOpen}
        closeLightBox={closeLightBox}
        currentImage={0}
        imageResources={images}
      />
    </>
  )
}

export default Journey
