import keymirror from 'keymirror'
import { ErrorCode } from 'react-dropzone'

import { getReadableBytes } from 'helpers/utils'

export const FILE_UPLOAD_STATUS = keymirror({
  UPLOADING: null,
  SUCCESS: null,
  ERROR: null,
})

/** 30 mb in Bytes */
export const VIDEO_FILE_SIZE_LIMIT = 31457280

export const FILE_UPLOAD_ERROR_MESSAGES: Record<
  string,
  (data: { file: File; maxSize?: number }) => string
> = {
  [ErrorCode.FileTooLarge]: ({ file, maxSize }) =>
    `${file.name} – File is larger than ${getReadableBytes(maxSize!)}`,
}

export const VIDEO_ELEMENT_LOADED_STATE_CODE = 4
