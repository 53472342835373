import { ReactElement, FocusEventHandler, ChangeEvent } from 'react'
import { WidgetProps } from '@rjsf/utils'

import { getWidgetInputDefaultClassNames } from 'helpers/formBuilder'

import WidgetWrapper from 'components/common/JsonForm/WidgetWrapper'

const TextareaWidget = ({
  id,
  name,
  className,
  placeholder,
  required,
  readonly,
  autofocus,
  disabled,
  label,
  value,
  onChange,
  onBlur,
  onFocus,
  options,
  schema,
  uiSchema,
  registry,
  rawErrors,
  formContext: { isPreview },
}: WidgetProps): ReactElement => {
  const handleChange = ({
    target: { value: newValue },
  }: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(newValue === '' ? options.emptyValue : newValue)
  }

  const handleBlur: FocusEventHandler<HTMLTextAreaElement> = ({
    target: { value: newValue },
  }) => onBlur(id, newValue)

  const handleFocus: FocusEventHandler<HTMLTextAreaElement> = ({
    target: { value: newValue },
  }) => onFocus(id, newValue)

  const { schemaUtils } = registry
  const displayLabel =
    schemaUtils.getDisplayLabel(schema, uiSchema) && (!!label || !!schema.title)

  return (
    <WidgetWrapper
      label={displayLabel ? label || schema.title : undefined}
      description={schema.description}
      required={required}
      name={name}
      rawErrors={rawErrors}
      isLarge={isPreview}
    >
      <textarea
        id={id}
        name={name}
        value={value}
        className={`${getWidgetInputDefaultClassNames(isPreview)} ${className}`}
        placeholder={placeholder || schema.placeholder}
        readOnly={readonly}
        required={required}
        disabled={disabled}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autofocus}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
    </WidgetWrapper>
  )
}

export default TextareaWidget
