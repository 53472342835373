import { useMemo, ReactElement } from 'react'

// utils
import { colourArrToRgbaBgStyle } from 'helpers/colour'

import { ColourArray } from 'types/common'
import scss from './index.module.scss'

const ColourBar = ({
  fillColour,
}: {
  fillColour: ColourArray
}): ReactElement => {
  const colourSelected = useMemo(
    () => colourArrToRgbaBgStyle(fillColour),
    [fillColour]
  )

  return <div className={scss.bar} style={colourSelected} />
}

export default ColourBar
