import {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
  ReactElement,
  CSSProperties,
} from 'react'
import _ from 'lodash'

// utils
import { uniqueStringId, sanitizeString } from 'helpers/utils'

import type { Payload } from 'types/common'

const TextInput = ({
  name = 'input-id',
  value,
  className,
  disabled = false,
  autoFocus = false,
  placeholder,
  onChange = _.noop,
  type = 'text',
  field: TypeComponent = 'input',
  required = false,
  testId,
  datalistValues = [],
  changeOnBlur = true,
  style,
  onPaste,
}: {
  name?: string
  testId?: string
  value?: string | null
  disabled?: boolean
  className?: string
  onChange?: (v: string) => void
  autoFocus?: boolean
  placeholder?: string
  field?: string
  type?: string
  required?: boolean
  datalistValues?: Payload[]
  style?: CSSProperties
  changeOnBlur?: boolean
}): ReactElement => {
  const inputRef = useRef(null)
  const [text, setText] = useState(value)
  const [isEditing, setIsEditing] = useState(false)

  const handleFocus = useCallback(() => {
    setIsEditing(true)
    setText(inputRef.current.value)
  }, [])

  const handleKeypress = useCallback(e => {
    if (e.key === 'Enter') {
      inputRef.current.blur()
    }
  }, [])

  const handleBlur = useCallback(() => {
    if (isEditing) {
      setIsEditing(false)
      const cleanText = sanitizeString(text)
      inputRef.current.value = cleanText

      onChange(cleanText)
    }
  }, [text, isEditing, onChange])

  useEffect(() => {
    if (_.isNil(value) || isEditing) return

    if (inputRef.current.value !== value) {
      inputRef.current.value = value
    }
  }, [isEditing, value])

  useEffect(() => {
    if (value === '') {
      setText('')
      inputRef.current.value = ''
    }
  }, [value])

  const datalistOptions = useMemo(
    () =>
      datalistValues?.map(datalistValue => (
        <option key={datalistValue.key} value={datalistValue.key} />
      )),
    [datalistValues]
  )

  const [datalistUuid] = useState(() => uniqueStringId())

  const datalist = useMemo(
    () =>
      datalistOptions?.length ? (
        <datalist id={datalistUuid}>{datalistOptions}</datalist>
      ) : undefined,
    [datalistOptions, datalistUuid]
  )

  return (
    <>
      <TypeComponent
        {...(changeOnBlur
          ? { onBlur: handleBlur }
          : {
              onChange: e => {
                const cleanText = sanitizeString(e.target.value)
                onChange(cleanText)
              },
            })}
        ref={inputRef}
        type={type}
        name={name}
        autoFocus={autoFocus}
        disabled={disabled}
        className={className}
        autoComplete='off'
        onKeyPress={handleKeypress}
        onInput={handleFocus}
        onClick={handleFocus}
        placeholder={placeholder}
        required={required}
        data-testid={testId}
        list={datalist ? datalistUuid : undefined}
        style={style}
        onPaste={onPaste}
      />
      {datalist}
    </>
  )
}

export default TextInput
