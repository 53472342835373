// libraries
import { useMemo, useCallback, ReactElement } from 'react'
import { useRecoilValue } from 'recoil'
import { useToggle } from 'react-use'
import _ from 'lodash'

// constants
import { ASSET_PROFILE_RELATIONSHIP_TYPES } from 'constants/assets'

// utils
import { currentAssetProfileDataState } from 'recoilStore/assetsStore'
import { useAssetWidgetConfigs } from 'components/assets/assetsProfile/hooks'
import { sanitizeString } from 'helpers/utils'

// components
import { Toggle, MultiSelect } from 'components/common'

import type { PropertiesMetadata } from 'types/common'
import type {
  Widget,
  AssetProfileRelationship,
  AssetProfile,
} from 'types/asset'

const getRelationshipOption = ({
  relationship,
  profile,
  assetProfile,
  type,
}: AssetProfileRelationship) => {
  const { properties, label } = assetProfile
  return {
    label,
    value: relationship,
    profile,
    properties,
    type,
  }
}

export const getPropertyOptions = (
  properties: PropertiesMetadata,
  reject = {}
): PropertiesMetadata => {
  return _(properties)
    .reject(reject)
    .map(data => {
      const { name = '', displayName } = data
      return {
        ...data,
        value: name,
        label: sanitizeString(displayName) || name,
      }
    })
    .value()
}

const getRelatedAssetProperties = ({
  assetProfileData,
  relatedAssetRelationshipId,
}: {
  assetProfileData: AssetProfile
  relatedAssetRelationshipId?: string
}) => {
  const { relationships } = assetProfileData
  const { assetProfile } =
    _.find(relationships, {
      relationship: relatedAssetRelationshipId,
    }) || {}
  return assetProfile?.properties
}

const useBaseAssetWidgetPanel = ({
  widget,
  onlyEnableRelatedAssets,
}: {
  widget: Widget
  onlyEnableRelatedAssets?: boolean
}): {
  propertyOptions: PropertiesMetadata
  renderAssetToggleAndRelatedMultiSelect: () => ReactElement
  relatedAssetRelationshipId?: string
  isAssetRelationshipMany: boolean
  enableRelationship: boolean
  propertiesMetadata: PropertiesMetadata
} => {
  const assetProfileData = useRecoilValue(currentAssetProfileDataState)
  const { settings } = widget
  const { label } = assetProfileData

  const {
    relatedAssetRelationshipId,
    assetProfileId,
    relatedAssetRelationshipType = ASSET_PROFILE_RELATIONSHIP_TYPES.MANY,
  } = settings

  const [enableRelationship, toggleRelationship] = useToggle(
    !!(relatedAssetRelationshipId || onlyEnableRelatedAssets)
  )

  const { updateWidgetConfigsSettings } = useAssetWidgetConfigs(widget)

  const relationshipOptions = useMemo(
    () => _.map(assetProfileData.relationships, getRelationshipOption),
    [assetProfileData.relationships]
  )

  const propertiesMetadata = useMemo(() => {
    if (!assetProfileData) return []

    const metadata = enableRelationship
      ? getRelatedAssetProperties({
          assetProfileData,
          relatedAssetRelationshipId,
        })
      : assetProfileData.properties

    return _.map(metadata, data => {
      const { displayName, name } = data
      return { ...data, displayName: displayName || name }
    })
  }, [assetProfileData, relatedAssetRelationshipId, enableRelationship])

  const propertyOptions = useMemo(() => {
    return getPropertyOptions(propertiesMetadata, { format: 'image' })
  }, [propertiesMetadata])

  const isAssetRelationshipMany = useMemo(
    () =>
      relatedAssetRelationshipType === ASSET_PROFILE_RELATIONSHIP_TYPES.MANY,
    [relatedAssetRelationshipType]
  )

  const renderAssetToggleAndRelatedMultiSelect = useCallback(
    () => (
      <>
        <div className='d-flex justify-content-between align-items-center groupOptionTitle'>
          <div>Arrange Properties</div>
          <Toggle
            label={enableRelationship ? 'Related Asset' : `${label}`}
            disabled={onlyEnableRelatedAssets}
            checked={enableRelationship}
            onToggle={() => {
              const isDeepMerge = false
              const payload = {
                relatedAssetRelationshipId: undefined,
                relatedAssetProfileId: undefined,
                properties: [],
                assetProfileId,
              }
              updateWidgetConfigsSettings(payload, isDeepMerge)
              toggleRelationship()
            }}
          />
        </div>
        {enableRelationship &&
          (_.isEmpty(relationshipOptions) ? (
            <span className='message'>There is no related asset. </span>
          ) : (
            <div className='groupOptionContent'>
              <MultiSelect
                value={relatedAssetRelationshipId}
                options={relationshipOptions}
                onChange={option => {
                  const { value, profile, type } = option
                  updateWidgetConfigsSettings({
                    relatedAssetRelationshipId: value,
                    relatedAssetProfileId: profile,
                    relatedAssetRelationshipType: type,
                  })
                }}
                isMulti={false}
                isClearable={false}
                placeholder='Please select the sub-asset'
              />
            </div>
          ))}
      </>
    ),
    [
      enableRelationship,
      label,
      onlyEnableRelatedAssets,
      relationshipOptions,
      relatedAssetRelationshipId,
      assetProfileId,
      updateWidgetConfigsSettings,
      toggleRelationship,
    ]
  )

  return {
    propertyOptions,
    renderAssetToggleAndRelatedMultiSelect,
    relatedAssetRelationshipId,
    isAssetRelationshipMany,
    enableRelationship,
    propertiesMetadata,
  }
}

export default useBaseAssetWidgetPanel
