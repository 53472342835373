import * as zoid from 'zoid/dist/zoid.frameworks'
import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { useBranding } from 'hooks'
import scss from './index.module.scss'

const lookupMap = {}

const ZoidDatasetPlugin = ({ tag, url, data, contextParams, pluginParams }) => {
  const branding = useBranding()
  const hostParams = { branding }
  let ZoidDatasetPluginCreate = lookupMap[tag]
  if (ZoidDatasetPluginCreate == null) {
    ZoidDatasetPluginCreate = zoid.create({
      tag,
      url,
      dimensions: {
        width: '100%',
        height: '100%',
      },
      props: {
        retrieveGeojsonData: {
          type: 'function',
          required: true,
        },
        contextParams: {
          type: 'object',
        },
        pluginParams: {
          type: 'object',
        },
        hostParams: {
          type: 'object',
        },
      },
    })
    lookupMap[tag] = ZoidDatasetPluginCreate
  }
  const ZoidDatasetPluginCreateWidget = ZoidDatasetPluginCreate.driver(
    'react',
    {
      React,
      ReactDOM,
    }
  )

  const retrieveGeojsonData = () => {
    return data
  }

  return (
    <div
      className={`d-flex text-center text-white ${scss.container} ${scss.divWidthHeightOverrideParent}`}
    >
      <div className='d-flex w-100 h-100 mx-auto flex-column justify-content-center align-items-center '>
        <ZoidDatasetPluginCreateWidget
          retrieveGeojsonData={retrieveGeojsonData}
          contextParams={contextParams}
          pluginParams={pluginParams}
          hostParams={hostParams}
        />
      </div>
    </div>
  )
}

ZoidDatasetPlugin.propTypes = {
  tag: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  contextParams: PropTypes.shape({}),
  pluginParams: PropTypes.shape({}),
}

export default ZoidDatasetPlugin
