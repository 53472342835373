// libraries
import { useMemo, ReactElement, lazy, Suspense, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import * as Sentry from '@sentry/react'
import { RecoilRoot } from 'recoil'
import { ThemeProvider, Theme } from '@emotion/react'
import { withLDProvider, useLDClient } from 'launchdarkly-react-client-sdk'
import _ from 'lodash'
import { ApolloProvider } from '@apollo/client'

// constants
import { APP_NAMES } from 'constants/common'

// utils
import {
  StateProvider,
  ConfigProvider,
  AbilityProvider,
  AuthenticationProvider,
  useAuthStateValue,
  StaticResourceProvider,
  useConfigStateValue,
} from 'contexts'
import { getBrandingColours } from 'helpers/colour'
import { useBranding, useCurrentUser } from 'hooks'
import { lazyWithRetry } from 'routers/utils'
import { useInitApolloClient } from 'apollo/hooks'

// components
import { AppError, Loading } from 'components/common'
import HotToaster from 'components/common/Toast/HotToaster'

// styles
import 'mapbox-gl/dist/mapbox-gl.css'
import variables from 'SUExplorer.module.scss'

const MissionControlStudio = lazy(() =>
  lazyWithRetry(() => import('app/MissionControlStudio'))
)

const MissionControlMethaneSolution = lazy(() =>
  lazyWithRetry(() => import('app/MissionControlMethaneSolution'))
)

const SUExplorer = () => {
  const { appName } = useAuthStateValue()
  const ldClient = useLDClient()
  const { currentUser } = useCurrentUser()
  const { logging } = useConfigStateValue() || {}

  // now that the user has authenticated update with user information
  useEffect(() => {
    ldClient?.identify({
      key: 'user',
      kind: 'user',
      id: currentUser.id,
      environment: logging.environment,
      role: currentUser.role,
      email: currentUser.email,
      group: currentUser.group,
    })
  }, [
    ldClient,
    logging.environment,
    currentUser.id,
    currentUser.role,
    currentUser.email,
    currentUser.group,
  ])

  const App =
    appName === APP_NAMES.methane
      ? MissionControlMethaneSolution
      : MissionControlStudio

  const sensorUpLogo = (
    <a
      href='https://sensorup.com'
      target='_blank'
      rel='noreferrer'
      className={variables.sensorUpLogo}
    >
      <img src='/assets/logo/SensorUp-Powered-by-Logo.svg' alt='SensorUp' />
    </a>
  )

  return (
    <Suspense fallback={<Loading />}>
      <App />
      <HotToaster />
      {sensorUpLogo}
    </Suspense>
  )
}

const ThemedSuExplorer = (): ReactElement => {
  const { title, colour = {} } = useBranding()
  const apolloClient = useInitApolloClient()

  const adjustedColour = useMemo(() => {
    const { primary } = colour || {}

    return {
      ...colour,
      ...getBrandingColours(primary),
      ..._.omit(variables, 'app'),
    } as Theme
  }, [colour])

  return (
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={adjustedColour}>
        <AuthenticationProvider>
          <>
            <Helmet>
              <title>{title || 'SensorUp Mission Control'}</title>
            </Helmet>
            <SUExplorer />
          </>
        </AuthenticationProvider>
        <style>
          {`:root {
              --primary-100: ${adjustedColour['primary-100']};
              --primary-500: ${adjustedColour['primary-500']};
              --secondary-light-500: ${adjustedColour['secondary-light-500']};
              --secondary-light-600: ${adjustedColour['secondary-light-600']};
            }`}
        </style>
      </ThemeProvider>
    </ApolloProvider>
  )
}

const FeatureFlaggedSuExplorer = (): ReactElement => {
  const { logging } = useConfigStateValue() || {}
  /* Use this once config.json is setup with LaunchDarkly client ID
  const {
    launchDarkly: { client_id: launchDarklyClientId },
  } = useConfigStateValue()
  */

  const App = useMemo(() => {
    const ldConfig = {
      // temporary client ID
      clientSideID: '655bdf52c3a8bb12577b6773',
      context: {
        kind: 'user',
        key: 'user',
        environment: logging.environment,
      },
    }
    return withLDProvider(ldConfig)(ThemedSuExplorer)
  }, [logging.environment])

  return <App />
}
const SUExplorerContainer = (): ReactElement => {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => <AppError message={error.toString()} />}
    >
      <RecoilRoot>
        <StaticResourceProvider>
          <ConfigProvider>
            <StateProvider>
              <AbilityProvider>
                <FeatureFlaggedSuExplorer />
              </AbilityProvider>
            </StateProvider>
          </ConfigProvider>
        </StaticResourceProvider>
      </RecoilRoot>
    </Sentry.ErrorBoundary>
  )
}

export default SUExplorerContainer
