// libraries
import { ReactElement } from 'react'
import { Field } from 'react-final-form'

// components
import { Input } from 'components/common/Form'

const EmailInputField = ({
  ...props
}: React.InputHTMLAttributes<HTMLInputElement>): ReactElement => (
  <Field
    id='loginEmail'
    labelClassName='form-label'
    component={Input}
    name='username'
    type='email'
    label='Email Address'
    placeholder='yourname@companyname.com '
    required
    data-testid='email'
    parse={value => value && (value as string).toLowerCase()}
    {...props}
  />
)

export default EmailInputField
