import { EDITOR_PROPERTIES_REGEXP } from 'constants/strings'

/**
 * Filters HTML from "illegal" properties.
 *
 * Explanation:
 * Each property should be added using the dropdown, then it will be wrapped into <span>.
 * So every property wrapped into {} instead of <span>
 * is an "illegal" property which we want to remove from the resulting string,
 * so fronted/backend will not crash after _.template(...) usage
 */
const filterUndefinedProperties = (
  html: string
): { filteredHtml: string; undefProperties: string[] } => {
  // Match {something}
  const matchedProperties = html.match(EDITOR_PROPERTIES_REGEXP)
  let filteredHtml = html
  const undefProperties: string[] = []

  matchedProperties?.forEach(propertyString => {
    // Replace '{' and '}'
    const propertyKey = propertyString.replaceAll(/{|}/g, '')
    filteredHtml = filteredHtml.replace(propertyString, '')
    undefProperties.push(propertyKey)
  })

  return { filteredHtml, undefProperties }
}

export default filterUndefinedProperties
