// libraries
import { ReactElement, useEffect, MouseEvent } from 'react'
import { useToggle } from 'react-use'

// utils
import { stopEventDefaultAndPropagation } from 'helpers/utils'

// constants
import { TOOLTIP_PLACEMENT } from 'constants/settings'

import * as Icons from 'components/icons'

import scss from './index.module.scss'
import { Tooltip } from '../Tooltip'

const Star = ({
  size,
  isActive,
  onChange,
  disabled,
  className,
}: {
  isActive: boolean
  onChange?: (v: boolean) => void
  className?: string
  size: number
  disabled: boolean
}): ReactElement => {
  const [isButtonActive, toggleButtonActive] = useToggle(isActive)

  useEffect(() => {
    toggleButtonActive(isActive)
  }, [toggleButtonActive, isActive])

  const buttonStyle = {
    cursor: disabled ? 'not-allowed' : 'pointer',
  }

  const handleButtonToggle = (e: MouseEvent) => {
    if (onChange) {
      stopEventDefaultAndPropagation(e)
      toggleButtonActive(!isButtonActive)
      onChange(!isButtonActive)
    }
  }

  const iconProps = {
    width: size,
    height: size,
  }

  return (
    <Tooltip
      placement={TOOLTIP_PLACEMENT.top}
      trigger={['hover']}
      overlay={<span>{isButtonActive ? 'Unfavorite' : 'Favorite'}</span>}
    >
      <button
        type='button'
        className={`${scss.svgButton} ${className}`}
        disabled={disabled}
        {...(onChange && { onClick: handleButtonToggle })}
        style={buttonStyle}
      >
        {isButtonActive ? (
          <Icons.StarFilledIcon {...iconProps} />
        ) : (
          <Icons.StarOutlineIcon {...iconProps} />
        )}
      </button>
    </Tooltip>
  )
}

Star.defaultProps = {
  isActive: false,
  className: '',
  size: 16,
  disabled: false,
}

export default Star
