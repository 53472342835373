// libraries
import { useState, ReactElement } from 'react'

// utils
import { MapProvider } from 'contexts'
import { getNewMap } from 'helpers/map'
import { useBranding } from 'hooks'

const SiteMapProvider = ({
  children,
}: {
  children?: ReactElement
}): ReactElement => {
  const { defaultSiteMapStyle } = useBranding()

  const [map] = useState(() =>
    getNewMap({
      style: defaultSiteMapStyle,
    })
  )

  return <MapProvider initialMap={map}>{children}</MapProvider>
}

export default SiteMapProvider
