// libraries
import { GeoJsonLayer } from '@deck.gl/layers/typed'

// utils
import { getPolygonLayerProps } from 'components/map/layers/deckLayers/polygonLayer'

// constants
import { LAYER_TYPES } from 'constants/map'

// components
import Layer from '../baseLayer'

export const upPolygonVisConfigs = {
  colourRange: 'colourRange',
  opacity: 'opacity',
  colourClasses: 'colourClasses',
  elevationScale: 'elevationScale',
  valueRangeForHeight: 'valueRangeForHeight',
  extruded: 'extruded',
  colourPropertyValue: 'colourPropertyValue',
  heightPropertyValue: 'heightPropertyValue',
  isStroked: 'isStroked',
}

export default class UpPolygonLayer extends Layer {
  constructor(props) {
    super(props)

    this.registerVisConfig(upPolygonVisConfigs, props.style[this.type])
  }

  get type() {
    return LAYER_TYPES.upPolygon
  }

  renderLayer = ({
    layerData,
    profileHandler,
    selectedDateTimeRange,
    highlightedObjectIndex,
  }) => {
    const firstDataTime = this.getTimeFromFirstData(layerData)

    const profileProps = this.getProfileConfig(profileHandler)

    const timeFilterProps = this.getTimeFilterProps(
      selectedDateTimeRange,
      firstDataTime
    )
    const basicProps = this.getLayerBasicProps({ layerData })
    const layerProps = getPolygonLayerProps({
      config: this.config,
      basicProps,
      profileProps,
      timeFilterProps,
      highlightedObjectIndex,
    })

    return [new GeoJsonLayer(layerProps)]
  }
}
