import _ from 'lodash'
import { useMemo, ReactElement, useCallback } from 'react'

import { ASSET_PROFILE_MEDIA_TYPES } from 'constants/assets'

// components
import BuilderGridLayoutItem from 'components/assets/assetsProfile/Builder/Layout/Item'
import * as Widgets from 'components/assets/assetsProfile/widgets'

// utils
import { getSortedWidgets } from 'helpers/assetProfile'
import { reportErrors } from 'helpers/log'

import type { Widget, WidgetLayout } from 'types/asset'
import type { ThemeType } from 'types/common'

const useAssetWidgetList = ({
  assetId,
  widgetsConfigs,
  mediaType,
  theme,
  onWidgetLoad = _.noop,
}: {
  assetId: string
  widgetsConfigs?: Widget[]
  mediaType: string
  theme: ThemeType
  onWidgetLoad?: (id: string, widgetLayout: WidgetLayout) => void
}): (ReactElement | null)[] => {
  const isPrintable = useMemo(
    () => mediaType === ASSET_PROFILE_MEDIA_TYPES.PRINTABLE,
    [mediaType]
  )

  const getWidgets = useCallback(
    (configs?: Widget[]) =>
      _.map(configs, config => {
        const { layout, widget, id, settings, name } = config
        const { w } = layout

        const Component = _.get(Widgets, widget)
        if (!Component) {
          reportErrors(`Invalid asset widget found-${widget}`)
          return null
        }

        const dataGrid = { ...layout, i: id, static: true }

        return (
          <div
            key={id}
            className={isPrintable ? `col-${w}` : ''}
            {...(!isPrintable && { 'data-grid': dataGrid })}
          >
            <BuilderGridLayoutItem {...config} isPrintable={isPrintable}>
              <Component
                assetId={assetId}
                settings={settings}
                name={name}
                theme={theme}
                mediaType={mediaType}
                dataGrid={dataGrid}
                onWidgetLoad={(widgetLayout: WidgetLayout) => {
                  onWidgetLoad(id, widgetLayout)
                }}
              />
            </BuilderGridLayoutItem>
          </div>
        )
      }),
    [assetId, isPrintable, mediaType, onWidgetLoad, theme]
  )

  return useMemo(() => {
    if (_.isEmpty(widgetsConfigs)) return []

    if (isPrintable) {
      const sortedWidgets = getSortedWidgets(widgetsConfigs)
      return _(sortedWidgets)
        .groupBy('layout.y')
        .flatMap((wConfigs, yValue) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`y${yValue}`} className='row my-2'>
              {getWidgets(_.sortBy(wConfigs, 'layout.x'))}
            </div>
          )
        })
        .value()
    }

    return getWidgets(widgetsConfigs)
  }, [getWidgets, isPrintable, widgetsConfigs])
}
export default useAssetWidgetList
