// libraries
import Moment from 'moment-timezone'
import { extendMoment } from 'moment-range'
import _ from 'lodash'

// utils
import { displayTime } from 'helpers/utils'
import {
  getFutureUtcDate,
  getPreviousUtcDate,
  getValidDatetimeUnit,
  getUtcDateTimeString,
  getSnappedDateMoment,
  getValidDateTimeUnitOptionsAndUnit,
} from 'helpers/datetime'

// constants
import { DATE_UNIT_TYPES, getTimeFormatter } from 'constants/datetime'
import {
  DEFAULT_GLOBAL_TIME_FILTER_ID,
  DEFAULT_DATA_AVAILABLE_BACKGROUND_ID,
} from 'constants/filter'
import { CHANGE_RATIO, TIMELINE_REPLAY_INTERVAL } from './constants'

const moment = extendMoment(Moment)

export const updateTimelineOptions = (timelineInstance, options) => {
  if (!timelineInstance || _.isEmpty(options)) return

  timelineInstance.setOptions(options)
}

export const getActiveItem = (start, end) => {
  return {
    id: DEFAULT_GLOBAL_TIME_FILTER_ID,
    start: moment.utc(start),
    end: moment.utc(end),
    type: 'range',
    editable: true,
  }
}

export const selectActiveItem = (timelineInstance, shouldFocus) => {
  if (!timelineInstance) return

  timelineInstance.setSelection([DEFAULT_GLOBAL_TIME_FILTER_ID], {
    focus: shouldFocus,
  })
}

export const focusBackgroundItem = timelineInstance => {
  if (!timelineInstance) return

  timelineInstance.focus(DEFAULT_DATA_AVAILABLE_BACKGROUND_ID)
}

export const generateBackgroundItem = ({
  start,
  end,
  timezone,
  timeFormat,
  hasData,
  backgroundDataTimeRange = {},
}) => {
  const backgroundStart = moment.utc(backgroundDataTimeRange.start || start)
  const backgroundEnd = moment.utc(backgroundDataTimeRange.end || end)
  const validTimeFormat =
    timeFormat || getTimeFormatter(DATE_UNIT_TYPES.seconds)

  const sharedTimeProps = {
    timezone,
    timeFormat: validTimeFormat,
  }

  const displayBackgroundStart = displayTime({
    ...sharedTimeProps,
    datetime: backgroundStart,
  })

  const displayBackgroundEnd = displayTime({
    ...sharedTimeProps,
    datetime: backgroundEnd,
  })

  const content = hasData
    ? `${displayBackgroundStart} - ${displayBackgroundEnd}`
    : 'No data downloaded for the requested time range'

  return {
    id: DEFAULT_DATA_AVAILABLE_BACKGROUND_ID,
    content,
    hasData,
    start: backgroundStart,
    end: backgroundEnd,
    type: 'background',
  }
}

export const getSelectedRange = (dataRange, userSavedRange = {}) => {
  // decide whether to keep the user selected time range
  // if the data updated and range is still overlapped with data
  // keep using the previous saved/selected time range
  // if not, forgot about the user previous selected range
  const userSettingDataTimeRange = moment.range(
    moment.utc(dataRange.startTime),
    moment.utc(dataRange.endTime)
  )

  const { start, end } = userSavedRange

  if (!start || !end) return {}

  const userSelectedDateTimeRange = moment.range(
    moment.utc(start),
    moment.utc(end)
  )

  const isOverlapped = userSettingDataTimeRange.overlaps(
    userSelectedDateTimeRange
  )
  return isOverlapped ? userSavedRange : {}
}

export const getTimelineDefaultOptions = props => {
  return {
    width: '100%',
    height: '90px',
    moveable: true,
    margin: {
      item: 0,
      axis: 0,
    },
    type: 'range',
    showCurrentTime: false,
    editable: {
      add: false, // add new items by double tapping
      updateTime: true, // drag items horizontally
      updateGroup: false, // drag items from one group to another
      remove: false, // delete an item by tapping the delete button top right
      overrideItems: true, // allow these options to override item.editable
    },
    selectable: true,
    loadingScreenTemplate: () => 'Loading...',
    ...props,
  }
}

export const snap = (timezone, intervalUnit) => datetime => {
  const unit = getValidDatetimeUnit(intervalUnit)
  return getSnappedDateMoment({
    baseDatetime: datetime,
    unit,
    timezone,
  }).valueOf()
}

export const onMoving = (unit, updateTimeRange) => (item, callback) => {
  const validUnit = getValidDatetimeUnit(unit)
  const { start, end } = item
  item.end = moment.utc(end).isSameOrBefore(start)
    ? getFutureUtcDate({
        interval: TIMELINE_REPLAY_INTERVAL,
        intervalUnit: validUnit,
        baseDatetime: start,
      })
    : end
  item.start = moment.utc(item.start).isSameOrAfter(end)
    ? getPreviousUtcDate({
        interval: TIMELINE_REPLAY_INTERVAL,
        intervalUnit: validUnit,
        baseDatetime: end,
      })
    : start
  updateTimeRange(item, unit)

  callback(item)
}

export const getTimelineMomentOption = timezone => date => {
  return timezone ? moment(date).tz(timezone) : moment.utc(date)
}

export const getStartAndEndForSelectedRange = (
  timeRange,
  currentSelectedDateTimeRange
) => {
  const { startTime, endTime } = timeRange

  const { validOptions, unit } = getValidDateTimeUnitOptionsAndUnit(
    startTime,
    endTime
  )

  // find the selected range
  const selectedRange = getSelectedRange(
    timeRange,
    currentSelectedDateTimeRange
  )

  // if there is no valid selected range
  // generate a range for user
  const newStart = _.get(selectedRange, 'start')
    ? getUtcDateTimeString(selectedRange.start)
    : getUtcDateTimeString(startTime)

  const newEnd = _.get(selectedRange, 'end')
    ? getUtcDateTimeString(selectedRange.end)
    : getFutureUtcDate({
        interval: CHANGE_RATIO,
        intervalUnit: unit,
        baseDatetime: startTime,
      })?.toISOString()

  return { newStart, newEnd, validOptions }
}

export const updateTimelineItemById = id => (timelineInstance, options) => {
  if (
    !id ||
    !timelineInstance ||
    !timelineInstance.itemsData ||
    _.isEmpty(options)
  )
    return

  const itemData = timelineInstance.itemsData.get(id)
  if (!itemData) return

  _.forEach(options, (value, key) => {
    itemData[key] = value
  })
  timelineInstance.itemsData.update(itemData)
}

export const updateSelectedItem = updateTimelineItemById(
  DEFAULT_GLOBAL_TIME_FILTER_ID
)

export const updateBackgroundItem = updateTimelineItemById(
  DEFAULT_DATA_AVAILABLE_BACKGROUND_ID
)

export const getTimelineItemById = id => timelineInstance => {
  if (!id || !timelineInstance) return undefined

  return timelineInstance.itemsData.get(id)
}

export const getSelectedItem = getTimelineItemById(
  DEFAULT_GLOBAL_TIME_FILTER_ID
)

export const getBackgroundItem = getTimelineItemById(
  DEFAULT_DATA_AVAILABLE_BACKGROUND_ID
)
