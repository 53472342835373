import { ReactElement, PropsWithChildren } from 'react'

// components
import { ErrorMessage } from 'components/common'

const ListErrorHandler = ({
  error,
  children,
}: PropsWithChildren<{
  error?: Error
}>): ReactElement => (error ? <ErrorMessage error={error} /> : <>{children}</>)

export default ListErrorHandler
