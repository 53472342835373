// libraries
import { useEffect, useState } from 'react'
import isEqual from 'fast-deep-equal'
import { useUnmount } from 'react-use'

// contexts
import { useStateValue } from 'contexts'

// utils
import { displayTime } from 'helpers/utils'
import { useTimezone } from 'hooks'
import {
  dismissAllToasts,
  makeToast,
} from 'components/common/Toast/helpers/utils'

// constants
import { MESSAGE_TYPES } from 'constants/message'

import type { ToastProps } from 'components/common/Toast/type'

const UnipipeToast = (): null => {
  const {
    selectors: {
      unipipeSelectors: { latestToast },
    },
  } = useStateValue()
  const { timezone } = useTimezone()

  const [toastState, setToastState] = useState(null)
  const [toastId, setToastId] = useState('')

  useUnmount(() => {
    dismissAllToasts()
  })

  useEffect(() => {
    if (!isEqual(toastState, latestToast)) {
      setToastState(latestToast)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestToast])

  useEffect(() => {
    if (!toastState) return

    const { render, type, timestamp } = toastState
    if (!render) return

    const time = displayTime({
      datetime: timestamp,
      timezone,
    })

    const newToastProps: ToastProps = {
      title: `${time}: ${render}`,
      type: type || MESSAGE_TYPES.info,
      toastOptions: {
        id: toastId || '',
        duration: type === MESSAGE_TYPES.error ? 0 : 5000,
      },
    }

    setToastId(makeToast(newToastProps))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toastState])
  return null
}

export default UnipipeToast
