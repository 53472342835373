// libraries
import { ReactElement } from 'react'

// utils
import {
  useAssetProfile,
  useAssetWidgetList,
} from 'components/assets/assetsProfile/hooks'

// constants
import {
  ASSET_PROFILE_GRID_LAYOUT_PROPS,
  ASSET_PROFILE_MEDIA_TYPES,
} from 'constants/assets'
import { THEMES } from 'constants/colour'

// components
import { Loading, GridLayout } from 'components/common'
import NoAvailableWidget from 'components/widget/common/NoAvailableWidget'

import type { Widget } from 'types/asset'

import scss from './index.module.scss'

const MapPopupAssetProfile = ({
  assetId,
  profileConfigs,
}: {
  assetId: string
  profileConfigs?: Widget[]
}): ReactElement => {
  const children = useAssetWidgetList({
    assetId,
    theme: THEMES.dark,
    widgetsConfigs: profileConfigs,
    mediaType: ASSET_PROFILE_MEDIA_TYPES.WEB,
  })

  return (
    <div className={scss.container}>
      <GridLayout
        {...ASSET_PROFILE_GRID_LAYOUT_PROPS}
        className={scss.layout}
        isDraggable={false}
        isResizable={false}
      >
        {children}
      </GridLayout>
    </div>
  )
}

const MapPopupAssetProfileContainer = (props: {
  profile: string
  assetId: string
}): ReactElement => {
  const { profile } = props

  const { loading, error, value } = useAssetProfile(profile)

  return loading ? (
    <Loading />
  ) : error ? (
    <NoAvailableWidget content='Something went wrong' />
  ) : (
    <MapPopupAssetProfile {...props} profileConfigs={value} />
  )
}

export default MapPopupAssetProfileContainer
