import { ReactElement, FocusEventHandler } from 'react'
import { FieldRenderProps } from 'react-final-form'

// components
import { StyledField } from 'components/common/Form'
import { TimePicker } from 'components/common/DateTime'

import type { DateTimeValue } from 'components/common/DateTime/DateTimePicker'
import type { TimePickerProps } from 'components/common/DateTime/TimePicker'

type FormTimePickerProps = Omit<TimePickerProps, 'onChange'> &
  FieldRenderProps<string, HTMLElement> & {
    input: {
      name: string
      value: string & DateTimeValue
      onChange: (v: string & DateTimeValue) => void
      onBlur: FocusEventHandler<HTMLInputElement>
      onFocus: FocusEventHandler<HTMLInputElement>
    }
  }

const TimeInput = ({
  label,
  input,
  description,
  required,
  ...rest
}: FormTimePickerProps): ReactElement => {
  const { onChange, value, name } = input

  return (
    <StyledField
      name={name}
      label={label}
      required={required}
      description={description}
    >
      <TimePicker {...rest} utcTime={value} onChange={onChange} />
    </StyledField>
  )
}

export default TimeInput
