import {
  memo,
  useMemo,
  createRef,
  useEffect,
  ReactElement,
  useRef,
} from 'react'
import _ from 'lodash'
import { useSet } from 'react-use'
import { useLocation } from 'react-router-dom'

// utils
import {
  useAssetProfile,
  useAssetWidgetList,
} from 'components/assets/assetsProfile/hooks'
import { useSanitizedParams } from 'hooks'

// constants
import {
  ASSET_PROFILE_MEDIA_TYPES,
  PRINT_PDF,
  ASSET_PROFILE_WIDGET_CONFIGS,
  ASSET_PROFILE_WIDGET_CATEGORIES,
} from 'constants/assets'
import { THEMES } from 'constants/colour'

// components
import { Loading } from 'components/common'
import NoAvailableWidget from 'components/widget/common/NoAvailableWidget'

import type { Widget } from 'types/asset'

// style
import scss from './index.module.scss'

const MEDIA_TYPE = ASSET_PROFILE_MEDIA_TYPES.PRINTABLE

const openPrintDialog = () => {
  window.print()
}

const assetProfileWidgetConfigsKeyByName = _.keyBy(
  ASSET_PROFILE_WIDGET_CONFIGS,
  'widgetName'
)

export const Page = memo(
  ({
    assetId,
    profileConfigs,
    printFlag = false,
  }: {
    assetId: string
    profileConfigs?: Widget[]
    printFlag?: boolean
  }): ReactElement => {
    const [loadedWidgets, loadedWidgetsAction] = useSet<string>(new Set([]))
    const isPrintDialogTriggeredRef = useRef<boolean>(false)

    const nonLayoutWidgets = useMemo(
      () =>
        _.reject(profileConfigs, config => {
          return (
            assetProfileWidgetConfigsKeyByName[config.widget]?.category ===
            ASSET_PROFILE_WIDGET_CATEGORIES.layout
          )
        }),
      [profileConfigs]
    )

    useEffect(() => {
      if (!isPrintDialogTriggeredRef.current && !printFlag) return

      if (nonLayoutWidgets.length === loadedWidgets.size) {
        isPrintDialogTriggeredRef.current = true
        _.delay(openPrintDialog, 2000)
      }
    }, [loadedWidgets, printFlag, nonLayoutWidgets])

    const onWidgetLoad = (id: string) => {
      loadedWidgetsAction.add(id)
    }

    const children = useAssetWidgetList({
      assetId,
      widgetsConfigs: profileConfigs,
      mediaType: MEDIA_TYPE,
      theme: THEMES.light,
      onWidgetLoad,
    })

    const ref = createRef<HTMLDivElement>()

    return (
      <div id='report' className={scss.container} ref={ref}>
        {children}
      </div>
    )
  }
)

const AssetReport = (): ReactElement => {
  const { id: profile, assetId } = useSanitizedParams()

  const { search } = useLocation()

  const printFlag = useMemo(() => search === PRINT_PDF, [search])

  const { loading, error, value } = useAssetProfile(profile)

  if (loading) return <Loading />
  return error || !assetId ? (
    <NoAvailableWidget content='Something went wrong' />
  ) : (
    <Page assetId={assetId} profileConfigs={value} printFlag={printFlag} />
  )
}

export default AssetReport
