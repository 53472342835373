// libraries
import { ReactElement, useState, useCallback } from 'react'

// constants
import { MODAL_SIZES } from 'constants/settings'
import { ENTITY_NAME, ENTITY_TYPE_TO_ENTITY } from 'constants/common'

// utils
import { reportErrors } from 'helpers/log'
import { useAuthStateValue } from 'contexts'
import { useAbility } from 'hooks'

// components
import {
  UsersPickerWithContext,
  Modal,
  MultiSelect,
  ModalFooter,
} from 'components/common'

import type { EntityGroup, GalleryItem, Owner } from 'types/entity'
import type { UserOption } from 'types/user'
import type { ToggleFn } from 'types/common'

export type TransferOwner = Owner

const TransferModal = ({
  subject,
  isLoading = false,
  isShowing,
  toggle,
  transferWithOwner,
}: {
  subject: GalleryItem
  isLoading?: boolean
  isShowing?: boolean
  toggle: ToggleFn
  transferWithOwner: (v: TransferOwner) => void
}): ReactElement => {
  const { name, id, type } = subject

  const entityName = ENTITY_NAME[ENTITY_TYPE_TO_ENTITY[type]]

  const { currentUser, userGroupsOptions } = useAuthStateValue()

  const [selectedOwner, setSelectedOwner] = useState<TransferOwner>({
    group: currentUser.group,
    user: currentUser,
  })

  const { group: selectedGroup, user } = selectedOwner || {}

  const { username: currentUsername } = user || {}

  const onChange = useCallback(() => {
    if (!selectedGroup || !currentUsername) {
      reportErrors(
        `[TransferModal] Cannot transfer the subject due to an owner ${JSON.stringify(
          selectedOwner
        )}`
      )
      return
    }
    transferWithOwner(selectedOwner)
  }, [currentUsername, selectedGroup, selectedOwner, transferWithOwner])

  const { canUpdate } = useAbility(subject)

  return (
    <Modal
      key={`transfer-${id}`}
      isShowing={isShowing}
      hide={toggle}
      size={MODAL_SIZES.small}
      title={`Transfer ${entityName}`}
      icon='IconTransfer'
    >
      <>
        <div className='py-4 px-3'>
          <div>{`Choose a new owner for this ${entityName}:`}</div>
          <div className='text-start w-100' data-testid='owner-selector'>
            <div className='largeText mb-3'>{name}</div>
            {userGroupsOptions.length > 1 && (
              <>
                <div className='form-label'>Group</div>
                <MultiSelect
                  className='my-2'
                  value={selectedGroup}
                  options={userGroupsOptions}
                  onChange={(group: EntityGroup) =>
                    setSelectedOwner((old: TransferOwner) => ({
                      ...old,
                      group,
                    }))
                  }
                  placeholder='Select a group'
                  isMulti={false}
                  isClearable={false}
                  useOptionValueOnly
                  withBorder
                />
              </>
            )}
            <div className='form-label'>User</div>
            <UsersPickerWithContext
              isMulti={false}
              value={currentUsername}
              placeholder='Transfer ownership to'
              onChange={(newUser: UserOption | undefined) => {
                setSelectedOwner((old: TransferOwner) => ({
                  ...old,
                  user: newUser,
                }))
              }}
              filterPayload={{ group: selectedGroup }}
              includeUsername={currentUser.username}
              removeInvalidValues
              withBorder
              className='my-2'
            />
          </div>
        </div>

        <ModalFooter
          onCancel={toggle}
          canSubmit={canUpdate}
          submitContent='transfer'
          onSubmit={onChange}
          isLoading={isLoading}
          disabled={!selectedGroup || !currentUsername}
        />
      </>
    </Modal>
  )
}

export default TransferModal
