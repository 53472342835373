import { ReactElement } from 'react'
import { Field } from 'react-final-form'
import _ from 'lodash'

import { ConditionalField, ConditionalResetField } from 'components/common/Form'
import type { ConditionalFieldProps } from 'components/common/Form/ConditionalField'

/** Using this component a field can be hidden and cleaned up by a condition */
const ConditionalFieldWrapper = ({
  conditionalProps,
  fieldToRender: FieldToRender,
  name,
  nodeId,
  resetField,
  valueForReset,
  ...rest
}: {
  conditionalProps: ConditionalFieldProps
  fieldToRender: (props: Record<string, unknown>) => ReactElement
  name: string
  nodeId: string
  // If 'true', the value will be reset once the field is hidden
  resetField?: boolean
  // Reset to this value once the field is hidden
  valueForReset?: unknown
} & Record<string, unknown>): ReactElement => {
  const { watch, condition = _.identity } = conditionalProps
  const watchFieldName = `${nodeId}.schema.${watch}`

  const fieldProps = {
    ...rest,
    name,
    nodeId,
  }

  return (
    <>
      <ConditionalField {...conditionalProps} watch={watchFieldName}>
        {/* Avoid rendering Field inside Field */}
        {FieldToRender ? (
          <FieldToRender {...fieldProps} />
        ) : (
          <Field {...fieldProps} />
        )}
      </ConditionalField>

      {resetField && (
        <ConditionalResetField
          watch={watchFieldName}
          condition={value => !condition(value)}
          reset={name}
          valueForReset={valueForReset}
        />
      )}
    </>
  )
}

export default ConditionalFieldWrapper
