import { useCallback } from 'react'
import _ from 'lodash'

import { useCurrentUser } from 'hooks'

import { USER_PREFERENCES } from 'constants/settings'

type ListEntity = { id: string; isFavorite: boolean }

/**
 * Returns 'onFavorite' handler that can be used
 * to add/remove an item to/from favorites
 * (which is stored in currentUser.preferences[preferenceId])
 */
const useOnFavorite = <T>(
  preferenceId: keyof typeof USER_PREFERENCES
): ((item: T & ListEntity) => Promise<void>) => {
  const {
    currentUser: { preferences },
    updateCurrentUserPreference,
  } = useCurrentUser()

  const onFavorite = useCallback(
    async ({ id, isFavorite }: T & ListEntity) => {
      const favoriteIds = (preferences[preferenceId] as string[]) || []

      // Push or pull an item to/from the list of favorites
      const newFavoriteIds = isFavorite
        ? _.difference(favoriteIds, [id])
        : [...favoriteIds, id]

      const payload = {
        preference: preferenceId,
        value: newFavoriteIds,
      }

      updateCurrentUserPreference(payload, preferenceId)
    },
    [preferences, preferenceId, updateCurrentUserPreference]
  )

  return onFavorite
}

export default useOnFavorite
