// libraries
import React, { useState, useCallback, useEffect, useMemo } from 'react'
import _ from 'lodash'
import { useInterval, useMountedState } from 'react-use'
import { MdPauseCircleFilled } from 'react-icons/md'
import { map } from 'awaity/esm'
import { Tooltip } from 'components/common/Tooltip'
import { useRecoilValue } from 'recoil'

// constants
import { TOOLTIP_PLACEMENT } from 'constants/settings'

// utils
import { isDevEnvironment } from 'helpers/utils'
import { useMapEditor } from 'components/map/hooks'

// contexts
import { useStateValue } from 'contexts'
import {
  assetsDataState,
  assetsDataLoadingState,
  assetsProfilesOptionsSelector,
} from 'recoilStore/assetsStore'

// style
import scss from './index.module.scss'

const REFRESH_INTERVAL = 1000

type Props = {
  datasetServices: {
    service: Object,
  },
}

const getRowsPerSecond = (rowsLoaded, elapsedSeconds) =>
  elapsedSeconds === 0 ? 0 : Math.round(rowsLoaded / elapsedSeconds)

const MapDataServiceDiagnostics = ({ datasetServices }: Props) => {
  const isMounted = useMountedState()
  const isMapInViewMode = !useMapEditor()
  const assetsDataLoading = useRecoilValue(assetsDataLoadingState)
  const assetsData = useRecoilValue(assetsDataState)
  const assetsProfilesOptions = useRecoilValue(assetsProfilesOptionsSelector)

  const {
    state: {
      unipipeState: { activeDatasetServiceIdentifies },
    },
    actions: {
      unipipeActions: { abortUnipipeDatasetServices },
    },
  } = useStateValue()

  const [elapsedSeconds, setElapsedSeconds] = useState(1)
  const [interval, setInterval] = useState(null)
  const [diagnostics, setDiagnostics] = useState([])

  const centerStyle = useMemo(() => {
    return {
      marginLeft: isMapInViewMode ? '70px' : '380px',
      width: isMapInViewMode ? 'calc(100% -70px)' : 'calc(100% - 340px - 70px)',
    }
  }, [isMapInViewMode])

  useInterval(() => {
    setElapsedSeconds(elapsedSeconds + 1)
  }, interval)

  useEffect(() => {
    const generateDiagnostics = async () => {
      const result = await map(
        datasetServices,
        async ({ service, datasetName, datasetServiceIdentifier }) => {
          const rowsLoaded = (service && (await service.rowsLoaded)) || 0

          if (rowsLoaded > 0 && !interval) {
            // start the timer when there are rows loaded and the timer is not started
            if (isMounted()) {
              setInterval(REFRESH_INTERVAL)
            }
          }
          const rowsPerSecond = getRowsPerSecond(rowsLoaded, elapsedSeconds)
          return {
            identifier: datasetServiceIdentifier,
            displayName: datasetName,
            rowsLoaded,
            rowsPerSecond: _.isNaN(rowsPerSecond) ? 0 : rowsPerSecond,
          }
        }
      )
      if (isMounted()) {
        setDiagnostics(result)
      }
    }
    generateDiagnostics()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasetServices, elapsedSeconds, interval, isMounted()])

  const cancelDatasetRequest = useCallback(
    name => {
      const newActiveDatasets = _.without(activeDatasetServiceIdentifies, name)
      const keepDatasetServiceData = true
      abortUnipipeDatasetServices(newActiveDatasets, keepDatasetServiceData)
    },
    [activeDatasetServiceIdentifies, abortUnipipeDatasetServices]
  )

  const renderUnipipeDiagnostic = useCallback(
    ({ identifier, displayName, rowsLoaded, rowsPerSecond }) => (
      <tr key={identifier} className={scss.message}>
        <th scope='row'>
          <span className={scss.label}>{displayName}</span>
        </th>
        <td>
          <div className={scss.highlight}>{`${rowsLoaded}`}</div>
          <div>Rows Loaded</div>
        </td>
        <td>
          <div className={scss.highlight}>{`${rowsPerSecond}`}</div>
          <div>Rows / sec</div>
        </td>
        <td>
          <Tooltip
            placement={TOOLTIP_PLACEMENT.right}
            trigger={['hover']}
            overlay={`Cancel the ${displayName} Request`}
          >
            <button
              type='button'
              className={scss.cancelButton}
              onClick={() => {
                cancelDatasetRequest(identifier)
              }}
            >
              <MdPauseCircleFilled size={16} className={scss.icon} />
            </button>
          </Tooltip>
        </td>
      </tr>
    ),
    [cancelDatasetRequest]
  )

  const renderAssetDiagnostic = useCallback(
    ({ data, loading }, key) => {
      if (!loading) return undefined

      const rowsLoaded = data.length
      const rowsPerSecond = getRowsPerSecond(rowsLoaded, elapsedSeconds)

      const assetLabel = _.get(
        _.find(assetsProfilesOptions, { value: key }),
        'label'
      )

      return (
        <tr key={key} className={scss.message}>
          <th scope='row'>
            <span className={scss.label}>Asset: {assetLabel || key}</span>
          </th>
          <td>
            <div className={scss.highlight}>{`${rowsLoaded}`}</div>
            <div>Rows Loaded</div>
          </td>
          <td>
            <div className={scss.highlight}>{`${rowsPerSecond}`}</div>
            <div>Rows / sec</div>
          </td>
          <td colSpan='1'>
            <div className={scss.progressContainer}>
              <div className={`${scss.progressBar} ${scss.stripes}`} />
            </div>
          </td>
        </tr>
      )
    },
    [elapsedSeconds, assetsProfilesOptions]
  )

  const renderUnipipeDiagnostics = useCallback(() => {
    const unipipeDiagnostics = _.map(diagnostics, renderUnipipeDiagnostic)
    const assetDiagnostics = assetsDataLoading
      ? _.map(assetsData, renderAssetDiagnostic)
      : []
    if (isMounted() && !interval && assetDiagnostics.length >= 0) {
      setInterval(REFRESH_INTERVAL)
    }
    return [...unipipeDiagnostics, ...assetDiagnostics]
  }, [
    diagnostics,
    renderUnipipeDiagnostic,
    assetsDataLoading,
    assetsData,
    renderAssetDiagnostic,
    isMounted,
    interval,
  ])
  const hasDatasetServices = useMemo(
    () => !_.isEmpty(datasetServices),
    [datasetServices]
  )

  return (
    <div style={centerStyle} data-testid='fetchOverlay'>
      <div className={scss.msgLarge}>WE ARE RETRIEVING YOUR DATA…</div>
      {hasDatasetServices && (
        <div className={scss.msgSmall}>
          Cancel when you have enough data to start exploring…
        </div>
      )}
      {isDevEnvironment() && (
        <div className={scss.highlight}>{`${elapsedSeconds}`} sec</div>
      )}
      <table className={`table table-borderless ${scss.datasetsList}`}>
        <tbody>{renderUnipipeDiagnostics()}</tbody>
      </table>
      {hasDatasetServices && (
        <Tooltip
          placement={TOOLTIP_PLACEMENT.right}
          trigger={['hover']}
          overlay='Cancel All Requests'
        >
          <button
            type='button'
            className={scss.cancelButton}
            onClick={() => abortUnipipeDatasetServices([], true)}
          >
            <MdPauseCircleFilled size={48} className={scss.icon} />
          </button>
        </Tooltip>
      )}
    </div>
  )
}

export default MapDataServiceDiagnostics
