// libraries
import { ReactElement } from 'react'

// component
import { Toggle } from 'components/common'

// contexts
import { useMapStateValue } from 'contexts'

import scss from './index.module.scss'

export const SpatialFilterToggle = ({
  checked,
  onToggle,
  className,
}: {
  checked: boolean
  onToggle: (v: boolean) => void
  className?: string
  labelClassName?: string
}): ReactElement => {
  return (
    <div className={className}>
      <Toggle
        checked={checked}
        onToggle={onToggle}
        label='Filter as I move the map'
        labelClassName={scss.label}
      />
    </div>
  )
}

const MapFilterToggle = (): ReactElement => {
  const {
    isSpatialFilterEnabled,
    toggleSpatialFilterEnabled,
    updateMapConfigs,
  } = useMapStateValue()
  return (
    <SpatialFilterToggle
      checked={isSpatialFilterEnabled}
      onToggle={() => {
        const spatialFilterEnabled = !isSpatialFilterEnabled
        updateMapConfigs({ spatialFilterEnabled })
        toggleSpatialFilterEnabled(spatialFilterEnabled)
      }}
    />
  )
}

export default MapFilterToggle
