// libraries
import styled from '@emotion/styled'
import { css, Theme } from '@emotion/react'

// IMPORTANT: Keep imports like this here to avoid errors on build
// components
import VisibilityToggle from 'components/common/Toggle/VisibilityToggle'
import IconButton from 'components/common/IconButton'

type Props = {
  isActive: boolean
  isLightTheme?: boolean
}

const getStyles = ({
  isActive,
  isLightTheme,
  theme,
}: Props & { theme: Theme }) => {
  const color = css`
    color: ${isLightTheme ? theme.primary : '#1791EA'};
  `
  const activeStyle = css`
    opacity: 1 !important;
    ${color}
  `
  return css`
    ${isActive ? activeStyle : ''}
    &:hover {
      ${color}
    }
  `
}

export const StyledClusteringToggle = styled(IconButton)<Props>(getStyles)

export const StyledVisibilityToggle = styled(VisibilityToggle)<Props>(getStyles)

type TitleWrapperProps = {
  isInvisible?: boolean
}

export const TitleWrapper = styled.div<TitleWrapperProps>`
  min-width: 0;
  opacity: ${({ isInvisible }) => (isInvisible ? '0.4' : '1')};
`
