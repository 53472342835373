import styled from '@emotion/styled'

type StyledElementProps = {
  theme?: Record<string, string>
}

export const StyledWarningIconDiv = styled.div<StyledElementProps>`
  position: absolute;
  top: -18px;
  right: -14px;
  padding: 6px;
  line-height: 0px;
  color: #fff;
  cursor: default;
  background-color: #e30000;
  border-radius: 50%;
`
