// libraries
import { useEffect, useMemo } from 'react'
import _ from 'lodash'

// utils
import { useStateValue } from 'contexts'
import {
  getCatalogItem,
  getDatasetOptionByDatasetNameAndCatalogId,
} from 'helpers/unipipe'
import { getGroupedOptions } from 'helpers/utils'
import type { DatasetMetadata, PropertiesMetadata, Options } from 'types/common'
/**
 * Responsible for managing non-time property options list and getting the map
 * eligible dataset option by the dataset name
 */
const useMapDatasetOption = ({
  dataset,
  catalogId,
  datasetGroup,
  fetchDynamicMetadata = false,
}: {
  dataset: string
  catalogId?: string
  datasetGroup?: string
  fetchDynamicMetadata?: boolean
}): {
  datasetOption: DatasetMetadata
  propertyOptions: PropertiesMetadata
  datasetGroupOptions: Options
  groupedDatasetsOptionsByTimeliness: Record<string, DatasetMetadata>
} => {
  const {
    actions: {
      unipipeActions: { updateDynamicUnipipeCatalogItem },
    },
    selectors: {
      unipipeSelectors: {
        datasetGroupOptions,
        mapEligibleDatasetOptions,
        catalog,
      },
    },
  } = useStateValue()

  const catalogItem = useMemo(
    () => getCatalogItem(catalog, dataset, catalogId as string),
    [catalog, catalogId, dataset]
  )

  useEffect(() => {
    const updateMetadata = async () => {
      if (fetchDynamicMetadata && _.get(catalogItem, 'dynamicMetadata')) {
        await updateDynamicUnipipeCatalogItem(catalogItem)
      }
    }

    updateMetadata()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogItem, fetchDynamicMetadata])

  const datasetOption = useMemo(() => {
    return (getDatasetOptionByDatasetNameAndCatalogId(
      mapEligibleDatasetOptions,
      dataset,
      catalogId as string
    ) || {}) as DatasetMetadata
  }, [mapEligibleDatasetOptions, dataset, catalogId])

  const propertyOptions = useMemo(
    () => datasetOption.properties || [],
    [datasetOption]
  )

  const groupedDatasetsOptionsByTimeliness = useMemo(() => {
    const group = datasetGroup || _.get(datasetOption, 'group')

    const filteredDatasetOptions = group
      ? _.filter(mapEligibleDatasetOptions, { group })
      : mapEligibleDatasetOptions

    return getGroupedOptions(filteredDatasetOptions, 'timeliness') as Record<
      string,
      DatasetMetadata
    >
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapEligibleDatasetOptions, datasetGroup])

  return {
    datasetOption,
    propertyOptions,
    datasetGroupOptions,
    groupedDatasetsOptionsByTimeliness,
  }
}

export default useMapDatasetOption
