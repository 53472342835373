// libraries
import {
  useCallback,
  useMemo,
  Dispatch,
  SetStateAction,
  ReactNode,
  ReactElement,
} from 'react'
import _ from 'lodash'
import { Ripple } from 'primereact/ripple'

import type { Item } from 'types/common'

// styles
import scss from './index.module.scss'

type GroupHeaderTemplateProps = {
  data: Item & Record<string, string>
  dataKey: string
  allColumnsCount: number
  expandedRows: Item[]
  setExpandedRows: Dispatch<SetStateAction<Item[]>>
  children: ReactNode
}

const GroupHeaderTemplate = ({
  data,
  dataKey,
  allColumnsCount,
  expandedRows,
  setExpandedRows,
  children,
}: GroupHeaderTemplateProps): ReactElement => {
  const expandedRowIndex = useMemo(
    () => _.findIndex(expandedRows, [dataKey, data[dataKey]]),
    [expandedRows, data, dataKey]
  )
  const isExpanded = expandedRowIndex !== -1
  const iconClassName = `pi ${
    isExpanded ? 'pi-chevron-down' : 'pi-chevron-right'
  }`

  const onGroupToggle = useCallback(() => {
    // Collapse
    if (isExpanded) {
      setExpandedRows(expandedRows.filter((__, i) => i !== expandedRowIndex))
    } else {
      // Expand
      setExpandedRows([...expandedRows, data])
    }
  }, [data, expandedRows, setExpandedRows, expandedRowIndex, isExpanded])

  return (
    <td
      className={scss.rowGroupHeader}
      colSpan={allColumnsCount}
      // Unlike in the default 'rowGroupHeader', make the whole row clickable
      // For the reference: 'createGroupHeader'
      // https://github.com/primefaces/primereact/blob/master/components/lib/datatable/TableBody.js#L820
      onClick={onGroupToggle}
    >
      <button type='button' className='p-row-toggler p-link me-1'>
        <span className={iconClassName} aria-hidden='true' />
        <Ripple />
      </button>
      <div className='p-rowgroup-header-name d-flex align-items-center'>
        {children}
      </div>
    </td>
  )
}

export default GroupHeaderTemplate
