import useCancellablePromises, {
  createCancellablePromise,
  delay,
} from './useCancellablePromises'

/**
 * If you have an element which has onClick and onDoubleClick handlers at the same time,
 * you may need to you this hook.
 * https://medium.com/trabe/prevent-click-events-on-double-click-with-react-with-and-without-hooks-6bf3697abc40
 */
const useClickPreventionOnDoubleClick = (
  onClick: () => void,
  onDoubleClick: () => void
): [handleClick: () => void, handleDoubleClick: () => void] => {
  const api = useCancellablePromises()

  const handleClick = () => {
    api.clearPendingPromises()
    const waitForClick = createCancellablePromise(delay(250))
    api.appendPendingPromise(waitForClick)

    return waitForClick.promise
      .then(() => {
        api.removePendingPromise(waitForClick)
        onClick()
      })
      .catch(errorInfo => {
        api.removePendingPromise(waitForClick)
        if (!errorInfo.isCanceled) {
          throw errorInfo.error
        }
      })
  }

  const handleDoubleClick = () => {
    api.clearPendingPromises()
    onDoubleClick()
  }

  return [handleClick, handleDoubleClick]
}

export default useClickPreventionOnDoubleClick
