// libraries
import _ from 'lodash'
import { useMount } from 'react-use'

import { useStateValue } from 'contexts'
import { QueryParams } from 'types/services'

const useAssetFetchList = (queryParams: QueryParams = {}): void => {
  const {
    state: {
      ancillaryDataState: { ancillaryDataList },
    },
    actions: {
      ancillaryDataActions: { getAncillaryDataList },
    },
  } = useStateValue()

  useMount(() => {
    if (_.isEmpty(ancillaryDataList)) {
      getAncillaryDataList(queryParams)
    }
  })
}

export default useAssetFetchList
