import { ReactElement } from 'react'
import { FieldInputProps } from 'react-final-form'

import { Checkbox } from 'components/common'

import scss from './index.module.scss'

const CheckboxItem = ({
  input,
  className = '',
  containerClassName = '',
  labelClassName = '',
  ...rest
}: {
  id?: string
  label?: string
  description?: string
  className?: string
  containerClassName?: string
  labelClassName?: string
  required?: boolean
  disabled?: boolean
  testId?: string
  input: FieldInputProps<boolean>
}): ReactElement => {
  const { name, onChange, onBlur, onFocus, checked } = input

  return (
    <Checkbox
      name={name}
      checked={checked}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      className={`${scss.checkbox} ${className}`}
      containerClassName={containerClassName}
      labelClassName={`${scss.label} ${labelClassName}`}
      {...rest}
    />
  )
}

export default CheckboxItem
