import { useMemo, CSSProperties, ReactElement, useState } from 'react'
import { useRecoilValue } from 'recoil'
import _ from 'lodash'

// utils
import { assetsProfilesOptionsSelector } from 'recoilStore/assetsStore'
import { getPropertyOptions } from 'helpers/workflow'

// components
import { StyledField, FieldLabel } from 'components/common/Form'
import { Button, MultiSelect } from 'components/common/'
import { getSelectedValue } from 'components/common/Form/FormSelect'
import { getPropertyOptionLabel } from 'components/common/PropertyPicker'
import type {
  Options,
  InputValue,
  Value,
  Payload,
  PropertiesMetadata,
} from 'types/common'
import type { AssetProfileId } from 'types/asset'
import type { AssetProfileOption } from 'types/workflow'

import { BUTTON_VARIANTS, BUTTON_SIZES } from 'components/common/Button'
import scss from './index.module.scss'

type currentAssetProfileOption = Options & {
  propertyOptions: PropertiesMetadata
}

const getSelectedAssetProfile = ({
  selectedValue,
  assetProfile,
  assetProfileOptions,
}: {
  selectedValue: string
  assetProfile: AssetProfileId
  assetProfileOptions: AssetProfileOption[]
}): string => {
  const [, , relationship] = selectedValue?.split('.') || []
  const { relationships } =
    _.find(assetProfileOptions, { value: assetProfile }) || {}

  return _.find(relationships, { relationship })?.profile || assetProfile
}

const AssetProfilePropertyPickerGroup = ({
  lockedAssetProfileId,
  currentAssetProfileOptions,
  filterPayload,
  value: inputValue,
  assetProfile,
  assetProfileOptions,
  ...rest
}: {
  lockedAssetProfileId?: string
  currentAssetProfileOptions: currentAssetProfileOption[]
  filterPayload: string[]
  value: InputValue
  assetProfile: AssetProfileId
  assetProfileOptions: AssetProfileOption[]
  isDisabled: boolean
  onChange: (v: Options) => void
}) => {
  const { isDisabled, onChange } = rest

  const selectedValue = useMemo(
    () => getSelectedValue(inputValue),
    [inputValue]
  )

  const [selectedAssetProfile, setSelectedAssetProfile] = useState(
    () =>
      lockedAssetProfileId ||
      getSelectedAssetProfile({
        selectedValue: selectedValue as Value,
        assetProfile,
        assetProfileOptions,
      })
  )

  const propertyOptions = useMemo(() => {
    const newOptions = _.get(
      _.find(currentAssetProfileOptions, {
        value: selectedAssetProfile,
      }),
      'propertyOptions'
    )
    return getPropertyOptions(newOptions, filterPayload)
  }, [currentAssetProfileOptions, filterPayload, selectedAssetProfile])

  return (
    <>
      {lockedAssetProfileId ? (
        <div className='d-flex align-items-center justify-content-between'>
          <FieldLabel
            label={_.get(
              _.find(currentAssetProfileOptions, {
                value: selectedAssetProfile,
              }),
              'label',
              selectedAssetProfile
            )}
          />
          {!isDisabled && (
            <div className='d-flex'>
              <Button
                className='p-0 me-2'
                variant={BUTTON_VARIANTS.link}
                onClick={() => onChange(propertyOptions)}
                disabled={isDisabled}
                size={BUTTON_SIZES.small}
              >
                Select All
              </Button>
              <Button
                className='p-0'
                variant={BUTTON_VARIANTS.link}
                onClick={() => onChange([])}
                disabled={isDisabled}
                size={BUTTON_SIZES.small}
              >
                Remove All
              </Button>
            </div>
          )}
        </div>
      ) : (
        <MultiSelect
          value={selectedAssetProfile}
          options={currentAssetProfileOptions}
          onChange={({ value }: { value: AssetProfileId }) => {
            setSelectedAssetProfile(value)
          }}
          isMulti={false}
          isClearable={false}
          withBorder
          isDisabled={isDisabled}
          className={scss.select}
        />
      )}
      <MultiSelect
        {...rest}
        value={selectedValue}
        options={propertyOptions}
        className='mb-2'
        formatOptionLabel={getPropertyOptionLabel}
      />
    </>
  )
}

const getPropertiesArrayGroupedByAssetProfileId = ({
  properties,
  assetProfile,
  assetProfileOptions,
}: {
  properties: string[] | Options
  assetProfile: AssetProfileId
  assetProfileOptions: AssetProfileOption[]
}): Payload => {
  const optionsGroupedByAssetProfileId = _.groupBy(
    _.map(properties, property => {
      const profileId = getSelectedAssetProfile({
        selectedValue: _.isObject(property) ? property.value : property,
        assetProfile,
        assetProfileOptions,
      })
      return { properties: property, profileId }
    }),
    'profileId'
  )
  return _.mapValues(optionsGroupedByAssetProfileId, options =>
    _.map(options, 'properties')
  )
}

const MultiAssetProfilePropertyPickerGroup = ({
  onChange,
  ...rest
}: {
  onChange: (v: string[]) => void
  lockedAssetProfileId?: string
  currentAssetProfileOptions: currentAssetProfileOption[]
  filterPayload: string[]
  value: string[] | Options
  assetProfile: AssetProfileId
  assetProfileOptions: AssetProfileOption[]
  useOptionValueOnly: boolean
}) => {
  const {
    currentAssetProfileOptions,
    value,
    assetProfile,
    assetProfileOptions,
    useOptionValueOnly,
  } = rest

  const [selectedGroupedProperties, setSelectedGroupedProperties] = useState(
    () =>
      getPropertiesArrayGroupedByAssetProfileId({
        properties: value,
        assetProfile,
        assetProfileOptions,
      })
  )

  const firstRelated = useMemo(
    () =>
      _.get(
        _.find(
          currentAssetProfileOptions,
          ({ value: currentProfileId }) => currentProfileId !== assetProfile
        ),
        'value'
      ),
    [assetProfile, currentAssetProfileOptions]
  )

  return _.map(currentAssetProfileOptions, ({ value: profileId }) => {
    return (
      <div key={profileId}>
        {firstRelated === profileId && (
          <>
            <hr />
            <FieldLabel
              label='Related Properties'
              labelClassName='defaultText mb-3'
            />
          </>
        )}
        <AssetProfilePropertyPickerGroup
          {...rest}
          lockedAssetProfileId={profileId}
          value={selectedGroupedProperties?.[profileId]}
          onChange={(newSelectedProperties: string[]) => {
            let newProperties
            setSelectedGroupedProperties(oldProperties => {
              newProperties = {
                ...oldProperties,
                [profileId]: newSelectedProperties,
              }
              return newProperties
            })
            const flatProperties = _.flatMap(newProperties)
            onChange(
              useOptionValueOnly
                ? _.map(flatProperties, 'value')
                : flatProperties
            )
          }}
        />
      </div>
    )
  })
}

const WorkflowPropertyPicker = ({
  input,
  label,
  required = false,
  description,
  groupOptionStyle = false,
  style,
  checkable,
  onChecked,
  labelClassName = 'form-label',
  isMulti = false,
  assetProfile,
  assetProfileOptions: currentAssetProfileOptions,
  filterPayload,
  options,
  useOptionValueOnly = true,
  labelContainerClassName,
  ...rest
}: {
  options: Options
  input: {
    name: string
    value: InputValue
    onChange: (v: InputValue) => void
    onBlur: (v: InputValue) => void
    onFocus: (v: InputValue) => void
  }
  label: string
  required: boolean
  description: string
  useOptionValueOnly?: boolean
  groupOptionStyle: boolean
  checkable: boolean
  onChecked: () => void
  labelClassName: string
  onCreatableOptionsChange: () => void
  convertStringType: string
  style: CSSProperties
  isMulti: boolean
  assetProfile: AssetProfileId
  assetProfileOptions: AssetProfileOption[]
  filterPayload: string[]
  labelContainerClassName?: string
}): ReactElement => {
  const { value: inputValue, name } = input

  const assetProfileOptions = useRecoilValue(assetsProfilesOptionsSelector)

  const nonAssetProfilePropertyOptions = useMemo(
    () => getPropertyOptions(options, filterPayload),
    [filterPayload, options]
  )

  const sharedProps = {
    ...rest,
    ...input,
    useOptionValueOnly,
    isMulti,
    withBorder: true,
  }

  const assetProfileSharedProps = {
    ...sharedProps,
    filterPayload,
    assetProfile,
    assetProfileOptions,
    currentAssetProfileOptions,
  }

  return (
    <StyledField
      name={name}
      groupOptionStyle={groupOptionStyle}
      label={label}
      required={required}
      description={description}
      style={style}
      checkable={checkable}
      onChecked={onChecked}
      labelClassName={labelClassName}
      labelContainerClassName={labelContainerClassName}
    >
      {_.isNil(assetProfile) ? (
        <MultiSelect
          {...sharedProps}
          formatOptionLabel={getPropertyOptionLabel}
          options={nonAssetProfilePropertyOptions}
          value={getSelectedValue(inputValue)}
        />
      ) : isMulti ? (
        <MultiAssetProfilePropertyPickerGroup {...assetProfileSharedProps} />
      ) : (
        <AssetProfilePropertyPickerGroup {...assetProfileSharedProps} />
      )}
    </StyledField>
  )
}

export default WorkflowPropertyPicker
