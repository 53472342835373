export const DEFAULT_ALIGNMENT_VALUE = 'left'
export const DEFAULT_ALIGNMENT_ICON = 'GrTextAlignLeft'

export const ALIGNMENTS_DATA = [
  {
    icon: 'GrTextAlignLeft',
    key: 'left',
    value: 'left',
  },
  {
    icon: 'GrTextAlignRight',
    key: 'right',
    value: 'right',
  },
  {
    icon: 'GrTextAlignCenter',
    key: 'center',
    value: 'center',
  },
  {
    icon: 'GrTextAlignFull',
    key: 'justify',
    value: 'justify',
  },
]

export const ICON_SIZE = 18

export const HEADERS_MENU = [
  {
    label: <h1>H1</h1>,
    value: 1,
  },
  {
    label: <h2>H2</h2>,
    value: 2,
  },
  {
    label: <h3>H3</h3>,
    value: 3,
  },
  {
    label: <h4>H4</h4>,
    value: 4,
  },
  {
    label: <h5>H5</h5>,
    value: 5,
  },
  {
    label: <h6>H6</h6>,
    value: 6,
  },
]

export const LIST_TYPES_DATA: {
  icon: string
  handlerName: 'toggleOrderedList' | 'toggleBulletList'
  key: string
  value: string
}[] = [
  {
    icon: 'AiOutlineOrderedList',
    handlerName: 'toggleOrderedList',
    key: 'orderedList',
    value: 'orderedList',
  },
  {
    icon: 'AiOutlineUnorderedList',
    handlerName: 'toggleBulletList',
    key: 'bulletList',
    value: 'bulletList',
  },
]

export const DEFAULT_LIST_TYPE_ICON = 'AiOutlineOrderedList'
