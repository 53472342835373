import type { Dispatch } from 'react'
import type { Payload } from 'types/common'

export const SET_FFMPEG_INSTANCE = 'SET_FFMPEG_INSTANCE'

const UiActions = ({
  dispatch,
}: {
  dispatch: Dispatch<{ type: string } & Payload>
}) => ({
  setFfmpegInstance: ffmpegInstance => {
    dispatch({
      type: SET_FFMPEG_INSTANCE,
      ffmpegInstance,
    })
  },
})

export default UiActions
