import { ReactElement } from 'react'

import { EmptySearchResultsImg } from 'svg'

import scss from './index.module.scss'

const EmptySearch = (): ReactElement => (
  <div className={scss.container}>
    <EmptySearchResultsImg />
    <h5 className={scss.title}>No results found</h5>
  </div>
)

export default EmptySearch
