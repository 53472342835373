// libraries
import _ from 'lodash'
import parse from 'wellknown'
import { feature } from '@turf/helpers'
import union from '@turf/union'

// constants
import { DATASET_TYPES } from 'constants/unipipe'
import {
  GEOMETRY_TYPES,
  LAYER_TYPES,
  DEFAULT_MAP_LAYER_NAME,
  MAP_TEMPORAL_SETTING_KEY,
  POLYGONS_WITH_BBOX_KEY,
  BASE_LAYER_TYPES,
} from 'constants/map'
import {
  LAYER_PROFILE_TYPES,
  DEFAULT_PROFILE_PROPERTIES_SETTING,
} from 'constants/profile'
import {
  FILTER_CONDITIONS,
  NOT_ALLOWED_FILTER_PROPERTY_FORMAT,
} from 'constants/filter'
import { LAYER_VIS_CONFIGS } from 'components/map/layers/deckLayers/layerFactory'

import { LAYER_DEFAULT_STYLE as upGeojsonLayerStyle } from 'components/map/layers/deckLayers/upGeojsonLayer/utils'
import { LAYER_DEFAULT_STYLE as pointLayerStyle } from 'components/map/layers/deckLayers/pointLayer/utils'
import { LAYER_DEFAULT_STYLE as polygonLayerStyle } from 'components/map/layers/deckLayers/polygonLayer/utils'
import { LAYER_DEFAULT_STYLE as arcLayerStyle } from 'components/map/layers/deckLayers/arcLayer/utils'
import { LAYER_DEFAULT_STYLE as iconLayerStyle } from 'components/map/layers/deckLayers/iconLayer/utils'

import { ENTITIES } from 'constants/common'

// utils
import { switchcase, getNewEntityId } from 'helpers/utils'
import {
  getDatasetServiceData,
  getDatasetServiceIdentifier,
  getDatasetIdentifier,
} from 'helpers/unipipe'
import { getFeaturesViewport } from 'helpers/mapUtils'

import type { Viewport } from 'types/map'

export * from 'helpers/layerStyle'
export * from 'helpers/layerProfile'
export * from 'helpers/layerFilter'
export * from 'helpers/layerDatetime'

export const getNewLayerId = () => getNewEntityId(ENTITIES.layer)

export const getNewLayerFilterId = () => getNewEntityId('filter')

export const getNewWidgetId = () => getNewEntityId('widget')

const getLayerDefaultStyle = type => {
  return switchcase({
    [LAYER_TYPES.point]: pointLayerStyle,
    [LAYER_TYPES.upGeojson]: upGeojsonLayerStyle,
    [LAYER_TYPES.icon]: iconLayerStyle,
    [LAYER_TYPES.arc]: arcLayerStyle,
    [LAYER_TYPES.polygon]: polygonLayerStyle,
  })()(type)
}

export const getNewLayer = ({
  id = getNewLayerId(),
  type = LAYER_TYPES.point,
} = {}) => {
  const style = { [type]: getLayerDefaultStyle(type) }

  return {
    id,
    name: DEFAULT_MAP_LAYER_NAME,
    widgets: [],
    profile: {
      title: '',
      properties: [],
    },
    type,
    style,
    isVisible: true,
    filterCondition: FILTER_CONDITIONS.and,
    filters: [],
    dataset: '',
    specParams: {},
  }
}

export const getNewAssetLayer = ({ id = getNewLayerId() } = {}) => {
  const type = LAYER_TYPES.upGeojson
  return {
    id,
    style: {
      [type]: {
        ...upGeojsonLayerStyle,
      },
    },
    name: DEFAULT_MAP_LAYER_NAME,
    isVisible: true,
    type,
    widgets: [],
    profile: { type: LAYER_PROFILE_TYPES.asset },
    baseType: BASE_LAYER_TYPES.asset,
  }
}

export const getNewTileLayer = ({ id = getNewLayerId(), type } = {}) => {
  const style = {}
  if (type === LAYER_TYPES.tile) {
    style[type] = {
      radius: LAYER_VIS_CONFIGS.radius.defaultValue,
      fillColour: LAYER_VIS_CONFIGS.tileFillColour.defaultValue,
      lineColour: LAYER_VIS_CONFIGS.tileLineColour.defaultValue,
    }
  } else if (type === LAYER_TYPES.tile3D) {
    style[type] = {
      pointSize: LAYER_VIS_CONFIGS.pointSize.defaultValue,
      opacity: LAYER_VIS_CONFIGS.opacity.defaultValue,
    }
  }
  return {
    id,
    type,
    style,
    name: DEFAULT_MAP_LAYER_NAME,
    isVisible: true,
  }
}

const getLayerBoundCollection = (catalog, mapLayers) => {
  return _(mapLayers)
    .map(({ catalogId, dataset }) => {
      const datasetIdentifier = getDatasetIdentifier(catalogId, dataset)
      const fitGeometry = _.get(catalog, [
        `${datasetIdentifier}`,
        'hints',
        'fitGeometry',
      ])
      return fitGeometry && feature(parse(fitGeometry))
    })
    .compact()
    .value()
}

const unionCollection = boundCollection => {
  return boundCollection.reduce(
    (bounds, bound) => union(bounds, bound),
    boundCollection[0]
  )
}

/**
 * Combine all bounds of all layers
 * @param {Object} catalog - datasets list
 * @param {Array} mapLayers - map layers list
 * @param {Object} windowSize - the dimensions of map container window {width, height}.
 *
 * @return {Object|null} valid map viewport or null
 */
export const getLayersViewport = (catalog, mapLayers, windowSize): Viewport => {
  const boundCollection = getLayerBoundCollection(catalog, mapLayers)
  if (_.isEmpty(boundCollection)) return null
  // https://github.com/Turfjs/turf/tree/master/packages/turf-union
  // Takes two (Multi)Polygon(s) and returns a combined polygon. If the input polygons are not contiguous, this function returns a MultiPolygon feature.
  const unionizedCollection = unionCollection(boundCollection)
  return getFeaturesViewport(unionizedCollection, windowSize)
}

export const getLayersValidDatasets = (layers, type) =>
  _(layers).filter(['timeliness', type]).map('dataset').compact().value()

export const getLayersDatasets = layers =>
  _(layers).map('dataset').compact().value()

export const getLayersValidLiveDatasets = layers =>
  getLayersValidDatasets(layers, DATASET_TYPES.live)

export const getLayersValidHistoricalDatasets = layers =>
  getLayersValidDatasets(layers, DATASET_TYPES.historical)

const GEOTYPE_LAYER_TYPES_MAPPING = {
  [GEOMETRY_TYPES.Polygon]: LAYER_TYPES.upPolygon,
  [GEOMETRY_TYPES.MultiPolygon]: LAYER_TYPES.upPolygon,
  [GEOMETRY_TYPES.Point]: LAYER_TYPES.point,
}

export const getLayerTypeByGeometryType = geometryType =>
  switchcase(GEOTYPE_LAYER_TYPES_MAPPING)(LAYER_TYPES.upGeojson)(geometryType)

/**
 * Get layer data based on the timeliness
 * @param {Object} layer
 * @param {Object} datasetServices
 *
 * @return {Array} layer data
 */
export const getLayerDataFromDatasetServices = (layer, datasetServices) => {
  const { timeliness } = layer
  const datasetServiceIdentifier = getDatasetServiceIdentifier(layer)
  return getDatasetServiceData(
    datasetServiceIdentifier,
    datasetServices,
    timeliness
  )
}

export const cloneWidget =
  ({ nameSuffix = '', ...rest }) =>
  widget => {
    if (!widget) return undefined

    return {
      ..._.cloneDeep(widget),
      name: `${widget.name}${nameSuffix}`,
      id: getNewWidgetId(),
      ...rest,
    }
  }

const cloneWidgets = ({ layerId, widgets, nameSuffix = '' }) =>
  _(widgets).map(cloneWidget({ layerId, nameSuffix })).compact().value()

export const cloneMapLayer =
  (nameSuffix = '') =>
  layer => {
    if (!layer) return undefined
    const id = getNewLayerId()
    const { name, widgets, filters, ...restLayer } = layer
    const clonedWidgets = cloneWidgets({ layerId: id, widgets, nameSuffix })
    const clonedFilters = _.map(filters, filter => ({
      ...filter,
      id: getNewLayerFilterId(),
    }))
    return {
      ..._.cloneDeep(restLayer),
      filters: clonedFilters,
      name: `${name}${nameSuffix}`,
      widgets: clonedWidgets,
      id,
    }
  }

export const getLayerPolygonForAggregation = (layer = {}) => {
  return _.get(layer, [
    'style',
    layer.type,
    MAP_TEMPORAL_SETTING_KEY,
    POLYGONS_WITH_BBOX_KEY,
  ])
}

export const getSimplifiedLayerProperties = (list, omitFields = []) => {
  const { isVisible: defaultIsVisible, widgetType: defaultWidgetType } =
    DEFAULT_PROFILE_PROPERTIES_SETTING

  return _.map(list, property => {
    const { widgetRange, widgetType, isVisible, range, ...rest } = property
    const widgetProps = {
      ...(widgetType && widgetType !== defaultWidgetType && { widgetType }),
      ...((widgetRange || range) && { range: widgetRange || range }),
    }
    return _.omit(
      {
        ...widgetProps,
        ...(!_.isNil(isVisible) &&
          isVisible !== defaultIsVisible && { isVisible }),
        ..._.omit(rest, [
          'id',
          'key',
          'label',
          'type',
          'terms',
          'termsCompleteness',
          'format',
          'name',
          'displayName',
        ]),
      },
      omitFields
    )
  })
}

export const isNonAllowedFilterPropertyFormat =
  (nonAllowedFormatsSet = NOT_ALLOWED_FILTER_PROPERTY_FORMAT) =>
  property =>
    _.includes([...nonAllowedFormatsSet], property?.format)

export const isAssetLayer = (layer = {}) => {
  return (
    layer.type === BASE_LAYER_TYPES.asset ||
    layer.baseType === BASE_LAYER_TYPES.asset
  )
}

export const getAssetLayerProfileId = (layer = {}) => {
  return _.get(layer, 'profile.assetProfileId')
}

export const getLayerProfileTitle = (layer = {}) =>
  _.get(layer, 'profile.title')

export const getMapDeckLayerFilterProps = (
  props,
  fields = ['filterRange', 'filterEnabled', 'getFilterValue', 'extensions']
) => _.pick(props, fields)
