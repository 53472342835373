import { atom, selector } from 'recoil'
import _ from 'lodash'

import { transformProperties } from 'helpers/utils'

import type { AssetProfile, AssetProfileViewConfigs } from 'types/asset'
import type { MapLayer } from 'types/map'
import type { AssetProfileOption } from 'types/workflow'

export const assetProfileWidgetsConfigsState = atom({
  key: 'assetProfileWidgetsConfigsState',
  default: {} as AssetProfileViewConfigs,
})

export const assetMapStylingConfigsState = atom({
  key: 'assetMapStylingConfigsState',
  default: {} as Partial<MapLayer>,
})

export const selectedAssetProfileWidgetIdState = atom({
  key: 'selectedAssetProfileWidgetIdState',
  default: '',
})

export const currentAssetProfileDataState = atom({
  key: 'currentAssetProfileDataState',
  default: {} as AssetProfile,
})

export const currentAssetGeometryType = atom({
  key: 'currentAssetGeometryType',
  default: '',
})

export const allAssetProfilesState = atom({
  key: 'allAssetProfilesState',
  default: [] as AssetProfile[],
})

export type AllAssetProfileOptions = Pick<AssetProfile, 'value' | 'label'>[]

export const allAssetProfileOptionsState = atom({
  key: 'allAssetProfileOptionsState',
  default: [] as AllAssetProfileOptions,
})

export const assetsProfilesOptionsSelector = selector({
  key: 'assetsProfilesOptionsSelector',
  get: ({ get }) => {
    const list = get(allAssetProfilesState)
    return _.map(list, ({ properties, ...rest }) => {
      return {
        ...rest,
        properties: transformProperties(properties),
      }
    }) as AssetProfileOption[]
  },
})

export const assetsProfilesKeyByProfileSelector = selector({
  key: 'assetsProfilesKeyByProfileSelector',
  get: ({ get }) => {
    const list = get(assetsProfilesOptionsSelector)
    return _.keyBy(list, 'value')
  },
})

export const assetsDataLoadingState = atom({
  key: 'assetsDataLoadingState',
  default: false,
})

export const assetsDataState = atom({
  key: 'assetsDataState',
  default: {},
})

export const assetsIssuesState = atom({
  key: 'assetsIssueState',
  default: {},
})

export const assetsDataByIdSelector = selector({
  key: 'assetsDataByIdSelector',
  get: ({ get }) => get(assetsDataState),
  set: (
    { set, get },
    { profileId, assets = [], assetIssues = [], loading = true }
  ) => {
    const currentAssetData = get(assetsDataState)
    const currentAssetsIssues = get(assetsIssuesState)
    const newAssetsData = {
      ...currentAssetData,
      [profileId]: {
        loading,
        data: [...(currentAssetData[profileId]?.data || []), ...assets],
      },
    }

    set(assetsDataState, newAssetsData)

    const assetsDataLoading = _(newAssetsData).map('loading').includes(true)
    set(assetsIssuesState, {
      ...currentAssetsIssues,
      [profileId]: [...(currentAssetsIssues[profileId] || []), ...assetIssues],
    })
    set(assetsDataLoadingState, assetsDataLoading)
  },
})

export const assetsOptionsByProfileIdSelector = selector({
  key: 'assetsOptionsByProfileIdSelector',
  get: ({ get }) => {
    const assetsData = get(assetsDataState)

    return _.mapValues(assetsData, ({ data }) =>
      _(data)
        .map(({ id, displayName }) => ({
          value: id,
          label: displayName || id,
        }))
        .sortBy('label')
        .value()
    )
  },
})
