import { ReactElement } from 'react'

// Components
import { IconButton } from 'components/common'

const VisibilityToggle = ({
  isVisible,
  onChange,
  className,
  size = 13,
}: {
  isVisible: boolean
  onChange: (e: React.MouseEvent<HTMLButtonElement>) => void
  className?: string
  size?: number
}): ReactElement => (
  <IconButton
    icon={isVisible ? 'MdVisibility' : 'MdVisibilityOff'}
    size={size}
    onClick={onChange}
    className={className}
    label={isVisible ? 'Hide' : 'Show'}
  />
)

export default VisibilityToggle
