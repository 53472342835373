import _ from 'lodash'
import * as d3 from 'd3'

// utils
import {
  getPropertyValueByName,
  getPropertyScale,
  getPropertyColour,
  getPropertyKeyByName,
} from 'helpers/map'
import { isNumericType } from 'helpers/filter'
import { getColourState } from 'components/map/layers/deckLayers/utils'
import { switchcaseF, isCountAggregation } from 'helpers/utils'
import { getDefaultLayerStyle } from 'helpers/layerStyle'
import { getIdentityPropertyValue } from 'helpers/unipipe'

// constants
import { LAYER_VIS_CONFIGS } from 'components/map/layers/deckLayers/layerFactory'
import {
  AGGREGATION_TYPES,
  TEXT_AGGREGATION_TYPES,
} from 'constants/aggregation'

export const LAYER_DEFAULT_STYLE = {
  arePointsAggregated: false,
  fillColour: LAYER_VIS_CONFIGS.fillColour.defaultValue,
  colourType: LAYER_VIS_CONFIGS.colourType.defaultValue,
  colourProperty: LAYER_VIS_CONFIGS.colourProperty.defaultValue,
  colourClasses: LAYER_VIS_CONFIGS.colourClasses.defaultValue,
  range: LAYER_VIS_CONFIGS.propertyValueRange.defaultValue,
  radius: LAYER_VIS_CONFIGS.radius.defaultValue,
  radiusRange: LAYER_VIS_CONFIGS.radiusRange.defaultValue,
  propertyValueRange: LAYER_VIS_CONFIGS.propertyValueRange.defaultValue,
  radiusProperty: LAYER_VIS_CONFIGS.radiusProperty.defaultValue,
  aggregationProperty: LAYER_VIS_CONFIGS.aggregationProperty.defaultValue,
  aggregationType: LAYER_VIS_CONFIGS.aggregationForColour.defaultValue,
}

const countRollupFn = ({ data }) => {
  const value = data.length

  return {
    geometry: data[0].geometry,
    properties: { count: value },
  }
}

const numericRollupFn = ({
  data,
  aggregationType,
  aggregationPropertyName,
}) => {
  const value = isCountAggregation(aggregationType)
    ? data.length
    : d3[aggregationType](data, d =>
        getPropertyValueByName(d, aggregationPropertyName)
      )

  return {
    geometry: data[0].geometry,
    properties: { [aggregationPropertyName]: value },
  }
}

const textRollupFn = ({ data, aggregationType, aggregationPropertyName }) => {
  const value = switchcaseF({
    [TEXT_AGGREGATION_TYPES.first]: () =>
      getPropertyValueByName(data[0], aggregationPropertyName),
    [TEXT_AGGREGATION_TYPES.last]: () =>
      getPropertyValueByName(data[data.length - 1], aggregationPropertyName),
    [TEXT_AGGREGATION_TYPES.list]: () =>
      _.map(data, getPropertyKeyByName(aggregationPropertyName)).join(),
  })(() => getPropertyValueByName(data[0], aggregationPropertyName))(
    aggregationType
  )

  return {
    geometry: data[0].geometry,
    properties: { [aggregationPropertyName]: value },
  }
}

const getAggregatedData = ({
  layerData,
  aggregationType,
  aggregationProperty,
  identityProperty,
}) => {
  let rollupFn
  let sharedProps

  if (aggregationProperty?.value) {
    const { value: aggregationPropertyName, type } = aggregationProperty
    sharedProps = {
      aggregationType:
        aggregationType ||
        (isNumericType(type)
          ? AGGREGATION_TYPES.count
          : TEXT_AGGREGATION_TYPES.list),
      aggregationPropertyName,
    }
    rollupFn = isNumericType(type) ? numericRollupFn : textRollupFn
  } else {
    rollupFn = countRollupFn
  }

  const rollup = d3
    .nest()
    .key(d => getIdentityPropertyValue(d, identityProperty))
    .rollup(data => rollupFn({ ...sharedProps, data }))
    .entries(layerData)

  const geojsonRows = rollup.map(({ key, value }) => {
    const { geometry, properties } = value
    return {
      type: 'Feature',
      geometry,
      properties: { ...properties, name: key },
    }
  })

  return geojsonRows
}

export const getPointLayerData = ({
  layer,
  filteredDataState,
  identityProperty,
}) => {
  const { cachedMapLayerData, filteredDataWithoutTimeFilter } =
    filteredDataState

  if (cachedMapLayerData) return cachedMapLayerData
  if (!filteredDataWithoutTimeFilter) return []

  const { type, style } = layer
  const {
    fillColour,
    colourType,
    colourProperty,
    colourClasses,
    range,
    radiusRange,
    propertyValueRange,
    radiusProperty,
    aggregationType,
    aggregationProperty,
    arePointsAggregated,
  } = getDefaultLayerStyle(style[type], LAYER_DEFAULT_STYLE)

  const geojsonData = arePointsAggregated
    ? getAggregatedData({
        layerData: filteredDataWithoutTimeFilter,
        aggregationType,
        aggregationProperty,
        identityProperty,
      })
    : filteredDataWithoutTimeFilter

  const {
    isSimpleColour: isSimpleFillColour,
    classification: colourClassification,
    crossFilterDimension: colourCategoryDimension,
  } = getColourState(
    geojsonData,
    fillColour,
    colourType,
    colourClasses,
    colourProperty,
    range
  )

  const getRadiusScale = getPropertyScale(
    geojsonData,
    radiusProperty,
    radiusRange,
    propertyValueRange
  )

  const getFillColourFn = getPropertyColour(
    isSimpleFillColour,
    colourProperty,
    colourClassification,
    colourCategoryDimension
  )

  if (isSimpleFillColour && !radiusProperty) {
    return geojsonData
  }

  return geojsonData.reduce((acc, geojson) => {
    const fColour = getFillColourFn(geojson)

    const radiusVal = getRadiusScale(
      getPropertyValueByName(geojson, radiusProperty)
    )

    if (isSimpleFillColour || fColour) {
      acc.push({
        ...geojson,
        radius: radiusVal,
        fillColour: fColour,
      })
    }

    return acc
  }, [])
}
