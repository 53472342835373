// libraries
import React from 'react'
import PropTypes from 'prop-types'

import scss from './index.module.scss'

const iconStyleProps = {
  className: 'stackIcon fillIcon',
  size: 16,
}

const selectedStyle = {
  color: '#000',
}

const TypePicker = ({ value, specs, onChange, className }) => {
  return (
    <div className={className || ''}>
      <ul className={scss.iconList}>
        {specs.map(({ name, Icon, type }) => {
          return (
            <li
              key={name}
              className={scss.iconListItem}
              onClick={() => onChange(type)}
              style={value === type ? selectedStyle : {}}
            >
              <Icon {...iconStyleProps} />
              {name}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

TypePicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  specs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      Icon: PropTypes.any,
      type: PropTypes.string,
    })
  ).isRequired,
}

TypePicker.defaultProps = {
  value: undefined,
  className: '',
}

export default TypePicker
