// libraries
import _ from 'lodash'

// utils
import {
  DEFAULT_REALTIME_RANGE,
  ECHARTS_AXIS_TYPE,
  DEFAULT_WIDGET_COLOUR,
  DEFAULT_WIDGET_SCATTER_SYMBOL,
} from 'constants/widget'
import { getAxisType, getDatasetSourceForScatter } from 'helpers/widget'
import { colourArrToRgbaStr } from 'helpers/colour'
import {
  isTimePropertyFromPropertyOptionAndValue,
  isNumericType,
} from 'helpers/filter'
import { DATE_UNIT_TYPES } from 'constants/datetime'

export const getWidgetScatterOptions = ({
  widgetData,
  widgetSetting,
  title,
  timezone,
  propertyOptions,
  visibleProperties,
  selectedDateTimeRange,
}) => {
  const {
    colour = DEFAULT_WIDGET_COLOUR,
    isSingleColour = true,
    symbolSize = DEFAULT_WIDGET_SCATTER_SYMBOL,
    xAxisPropertyName,
    xAxisPropertyType,
    xAxisRange,
    yAxisPropertyName,
    yAxisPropertyType,
    yAxisRange,
    isXAxisTimeRangeEnabled,
    xAxisIntervalRange = DEFAULT_REALTIME_RANGE,
    xAxisIntervalUnit = DATE_UNIT_TYPES.minutes,
    isYAxisTimeRangeEnabled,
    yAxisIntervalRange = DEFAULT_REALTIME_RANGE,
    yAxisIntervalUnit = DATE_UNIT_TYPES.minutes,
    hideValuesOutsideOfXAxisRange = false,
    hideValuesOutsideOfYAxisRange = false,
    hideInvalidValuesOnXAxis = false,
    hideInvalidValuesOnYAxis = false,
  } = widgetSetting

  if (!yAxisPropertyName || !xAxisPropertyName) return undefined
  const widgetColour = isSingleColour ? colourArrToRgbaStr(colour) : null

  const xAxisType = isTimePropertyFromPropertyOptionAndValue(
    xAxisPropertyName,
    propertyOptions
  )
    ? ECHARTS_AXIS_TYPE.time
    : getAxisType(xAxisPropertyType)

  const yAxisType = isTimePropertyFromPropertyOptionAndValue(
    yAxisPropertyName,
    propertyOptions
  )
    ? ECHARTS_AXIS_TYPE.time
    : getAxisType(yAxisPropertyType)

  const geojsonRows = _.map(widgetData, data => {
    return {
      properties: visibleProperties
        ? _.pick(data.properties, [...visibleProperties])
        : data.properties,
    }
  })

  const dataset =
    getDatasetSourceForScatter({
      geojsonRows,
      isXAxisTimeRangeEnabled,
      xAxisIntervalRange,
      xAxisIntervalUnit,
      isYAxisTimeRangeEnabled,
      yAxisIntervalRange,
      yAxisIntervalUnit,
      xAxisPropertyName,
      yAxisPropertyName,
      timezone,
      ...(hideValuesOutsideOfXAxisRange &&
        isNumericType(xAxisPropertyType) && { xAxisRange }),
      ...(hideValuesOutsideOfYAxisRange &&
        isNumericType(yAxisPropertyType) && { yAxisRange }),
      hideInvalidValuesOnXAxis,
      hideInvalidValuesOnYAxis,
    }) || null

  return {
    dataset,
    symbolSize,
    xAxisRange,
    xAxisPropertyName,
    xAxisType,
    yAxisRange,
    yAxisPropertyName,
    yAxisType,
    colour: widgetColour,
    timezone,
    title,
    selectedDateTimeRange,
  }
}

const renderWidgetScatter = ({
  widgetSetting,
  calculateWidgetData,
  updateWidgetProps,
  ...rest
}) => {
  if (_.isEmpty(widgetSetting)) return

  const options = getWidgetScatterOptions({ widgetSetting, ...rest }) || {}
  const { dataset, ...restOptions } = options
  const value = dataset && { dataset }
  updateWidgetProps(value, calculateWidgetData, restOptions)
}

export default renderWidgetScatter
