import { ReactElement, useMemo, useEffect, useRef } from 'react'
import _ from 'lodash'

// components
import Button, { BUTTON_SIZES, BUTTON_VARIANTS } from 'components/common/Button'
import { Tooltip } from 'components/common'

// constants
import { TOOLTIP_PLACEMENT } from 'constants/settings'
import { VIDEO_ELEMENT_LOADED_STATE_CODE } from 'constants/fileUpload'

// types
import type {
  DataCollectionDeletableFormMedia,
  DataCollectionFormMediaView,
} from 'types/issue'

import scss from './index.module.scss'

const IssueVideoList = ({
  videoResources,
  fieldValue,
  itemsPerRow = 1,
  containerClassName = '',
  toggleDelete,
  onLoad,
}: {
  videoResources?: DataCollectionFormMediaView[]
  fieldValue?: DataCollectionDeletableFormMedia[]
  itemsPerRow?: number
  containerClassName?: string
  toggleDelete?: (mediaKey: string, isDeleted: boolean) => void
  onLoad?: () => void
}): ReactElement | null => {
  const videosContainerRef = useRef<null | HTMLDivElement>(null)
  const isLoadedRef = useRef<boolean>(false)

  const fieldVideoResources = useMemo(() => {
    // Transform to { [mediaKey]: value }
    const mediaKeyToValueMap =
      fieldValue?.reduce<Record<string, DataCollectionDeletableFormMedia>>(
        (acc, videoData) => ({ ...acc, [videoData.mediaKey]: videoData }),
        {}
      ) || {}

    // Make and array combining video source data and field values
    return (
      videoResources?.reduce<
        (DataCollectionDeletableFormMedia & DataCollectionFormMediaView)[]
      >((acc, videoResource) => {
        const valueByMediaKey = mediaKeyToValueMap[videoResource.mediaKey]
        if (valueByMediaKey) {
          return [...acc, { ...videoResource, ...valueByMediaKey }]
        }

        return acc
      }, []) || []
    )
  }, [videoResources, fieldValue])

  useEffect(() => {
    if (!isLoadedRef.current && videosContainerRef.current) {
      // Looking for <video> elements
      const videoElements = [
        ...videosContainerRef.current.getElementsByTagName('video'),
      ]

      if (videoElements.length === 0) return

      // Start polling
      const timer = setInterval(() => {
        // When all videos are  loaded...
        if (
          videoElements.every(
            elem => elem.readyState === VIDEO_ELEMENT_LOADED_STATE_CODE
          )
        ) {
          // ...trigger the callback
          onLoad?.()
          // Set the ref to prevent further calls
          isLoadedRef.current = true
          clearInterval(timer)
        }
      }, 1000)
    }
  }, [onLoad])

  const videoRows = useMemo(
    () => _.chunk(fieldVideoResources, itemsPerRow),
    [fieldVideoResources, itemsPerRow]
  )

  return fieldVideoResources.length > 0 ? (
    <div
      ref={videosContainerRef}
      className={`container py-2 px-0 ${containerClassName}`}
    >
      {videoRows.map((videosRow, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className='row' key={index}>
          {videosRow.map(({ source, mediaKey, isDeleted }) => (
            <div key={mediaKey} className={`col-${12 / itemsPerRow}`}>
              <div
                className={`${scss.videoWrapper} ${
                  isDeleted ? scss.deleted : ''
                }`}
              >
                {toggleDelete && (
                  <div className={scss.controls}>
                    <Tooltip
                      placement={TOOLTIP_PLACEMENT.top}
                      overlay={<span>{isDeleted ? 'Restore' : 'Delete'}</span>}
                    >
                      <Button
                        size={BUTTON_SIZES.small}
                        variant={BUTTON_VARIANTS.secondary}
                        iconSize={14}
                        onClick={() => toggleDelete(mediaKey, !isDeleted)}
                        icon={isDeleted ? 'UndoIcon' : 'IconTrash'}
                      />
                    </Tooltip>
                  </div>
                )}
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <video className={scss.video} controls>
                  <source src={source} type='video/mp4' />
                </video>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  ) : null
}

export default IssueVideoList
