import _ from 'lodash'
import { ENTITIES } from 'constants/common'
import { getQueryFields } from 'helpers/graphql'

import {
  getGraphql,
  mutateEntity,
  getEntityQuery,
  MutateEntity,
} from 'services/api/utils'

import type { AuthConfig, Payload } from 'types/common'
import type { AuthSession } from 'types/auth'

const domain = ENTITIES.session
const queryDomain = `Auth`

export const deserializeAuthSession = (
  authSession: AuthSession
): AuthSession => {
  const { challengeParam, ...rest } = authSession || {}
  const { userAttributes } = challengeParam || {}

  if (!_.isString(userAttributes)) return authSession

  const parsedUserAttributes = JSON.parse(userAttributes)
  const {
    family_name: lastName,
    given_name: firstName,
    phone_number: phoneNumber,
    ...restAttributes
  } = parsedUserAttributes

  return {
    ...rest,
    challengeParam: {
      ...challengeParam,
      userAttributes: {
        ...restAttributes,
        lastName,
        firstName,
        phoneNumber,
      },
    },
  }
}

const getAuthSessionFields = getQueryFields({
  username: true,
  preferredMFA: true,
  attributes: {
    lastName: {
      __aliasFor: 'family_name',
    },
    firstName: {
      __aliasFor: 'given_name',
    },
    phoneNumber: {
      __aliasFor: 'phone_number',
    },
    email: true,
  },
  challengeName: true,
  challengeParam: true,
  authenticated: true,
})

const getAuthTOTPMutationFields = getQueryFields({
  qrCode: true,
  qrImage: true,
})

const mutateAuth =
  (props: Omit<MutateEntity, 'queryDomain'>) => (args?: Payload) =>
    mutateEntity<AuthSession>({
      queryDomain,
      withIdentifier: false,
      responsePath: [domain],
      responseFields: {
        [domain]: getAuthSessionFields(),
      },
      postProcessFn: deserializeAuthSession,
      keepUserErrorMessage: true,
      ...props,
    })(null, args)

export const getSession = getGraphql({
  getQueryFn: getEntityQuery({
    identifier: null,
    queryName: 'session',
    getFieldsFn: getAuthSessionFields,
  }),
  fnName: 'session',
  queryDisplayName: 'GetAuthSession',
  ignoreError: true,
  postProcessFn: deserializeAuthSession,
})

const getAuthCredentialsFields = getQueryFields({
  accessKeyId: true,
  secretAccessKey: true,
  sessionToken: true,
  expiration: true,
})

export const getCredentials = getGraphql({
  getQueryFn: getEntityQuery({
    identifier: null,
    queryName: 'credentials',
    getFieldsFn: getAuthCredentialsFields,
  }),
  fnName: 'credentials',
  queryDisplayName: 'getAuthCredentials',
})

export const signIn = mutateAuth({
  fnName: 'signIn',
  variableFormat: 'AuthSignInInput!',
})

export const federatedSignIn = mutateAuth({
  fnName: 'federatedSignIn',
  variableFormat: 'AuthFederatedSignInInput!',
})

export const signOut = mutateAuth({
  fnName: 'signOut',
  argsKey: null,
})

export const forgotPassword = mutateAuth({
  fnName: 'forgotPassword',
  variableFormat: 'AuthForgotPasswordInput',
})

export const forgotPasswordSubmit = mutateAuth({
  fnName: 'forgotPasswordSubmit',
  variableFormat: 'AuthForgotPasswordSubmitInput',
})

export const completeNewPassword = mutateAuth({
  fnName: 'completeNewPassword',
  variableFormat: 'AuthCompleteNewPasswordInput',
})

export const confirmSignIn = mutateAuth({
  fnName: 'confirmSignIn',
  variableFormat: 'AuthConfirmSignInInput',
  responseFields: {
    [domain]: getAuthSessionFields(),
    deviceKey: true,
    deviceGroupKey: true,
    devicePassword: true,
  },
  responsePath: undefined,
})

export const verifyAndSetTOTP = mutateAuth({
  fnName: 'verifyTOTPToken',
  variableFormat: 'AuthVerifyTOTPTokenInput',
})

export const forgetDevices = mutateAuth({
  fnName: 'forgetDevices',
  variableFormat: 'AuthForgetSpecificDevicesInput',
})

export const verifyAndSetSMS = mutateAuth({
  fnName: 'verifySMSCode',
  variableFormat: 'AuthVerifySMSCodeInput',
})

export const generateSMSCode = mutateAuth({
  fnName: 'generateSMSCode',
  argsKey: null,
})

export const globalSignOut = mutateAuth({
  fnName: 'globalSignOut',
  argsKey: null,
})

export const adminSetUserMFAPreference = mutateAuth({
  fnName: 'adminSetUserMFAPreference',
  variableFormat: 'AuthAdminSetUserMFAPreferenceInput',
})

export const getTOTPCode = mutateAuth({
  fnName: 'setupTOTP',
  argsKey: null,
  responsePath: [],
  responseFields: getAuthTOTPMutationFields(),
})

const getAuthConfigFields = getQueryFields({
  auth: {
    loginEnabled: true,
    ssoEnabled: true,
    ssoUrl: true,
  },
})

export const getAuthConfig = getGraphql<AuthConfig>({
  getQueryFn: getEntityQuery({
    identifier: null,
    queryName: 'config',
    getFieldsFn: getAuthConfigFields,
  }),
  fnName: 'config',
  queryDisplayName: 'getAuthConfig',
})
