import { ReactElement } from 'react'
import { Field, useField } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'

import { Toggle, ConditionalField } from 'components/common/Form'
import { NumericField } from 'components/formBuilder/Panel/components'

import scss from './index.module.scss'

type PropertySpec = {
  label: string
  description?: string
  inline?: boolean
  disabled?: boolean
  required?: boolean
}

type RangeLimitsProperties = {
  visible: PropertySpec
  minimum: PropertySpec
  maximum: PropertySpec
}

const RangeLimits = ({
  name,
  properties,
  disabled,
}: {
  name: string
  properties: RangeLimitsProperties
  disabled?: boolean
}): ReactElement => {
  const { visible, minimum, maximum } = properties
  const visibleFieldName = `${name}.visible`
  const minFieldName = `${name}.minimum`
  const maxFieldName = `${name}.maximum`

  const minFieldInput = useField(minFieldName).input
  const maxFieldInput = useField(maxFieldName).input

  return (
    <>
      <Field
        component={Toggle}
        name={visibleFieldName}
        {...visible}
        disabled={visible.disabled || disabled}
      />
      {/* Show these field if 'visible === true' */}
      <ConditionalField watch={visibleFieldName}>
        <div className='d-flex align-items-start gap-3'>
          <div className={scss.rangeInputWrapper}>
            <NumericField
              name={minFieldName}
              {...minimum}
              disabled={disabled}
            />
          </div>
          <div className={scss.rangeInputWrapper}>
            <NumericField
              name={maxFieldName}
              {...maximum}
              disabled={disabled}
            />
          </div>
        </div>
      </ConditionalField>

      <OnChange name={visibleFieldName}>
        {value => {
          if (!value) {
            // Cleanup the fields if they are no longer visible
            minFieldInput.onChange(undefined)
            maxFieldInput.onChange(undefined)
          }
        }}
      </OnChange>
    </>
  )
}

export default RangeLimits
