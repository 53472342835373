// libraries
import { ReactNode, ReactElement, CSSProperties, forwardRef } from 'react'
import _ from 'lodash'
import styled from '@emotion/styled'

// types
import { TooltipPlacement } from 'types/common'

// components
import { Tooltip } from 'components/common'
import { useStaticResourceStateValue } from 'contexts'

type ButtonContainerProps = {
  outline?: boolean
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const ButtonContainer = styled.button<ButtonContainerProps>`
  color: #7a7a7a;
  cursor: ${props =>
    props.disabled || _.isNil(props.onClick)
      ? 'default'
      : 'pointer'} !important;
  &:hover {
    color: ${props =>
      props.theme.primary ? `${props.theme.primary}` : '#7a7a7a'};
    fill: ${props =>
      props.theme.primary ? `${props.theme.primary}` : '#7a7a7a'};
  }
  border: ${props =>
    props.outline ? `1px solid ${props.theme.primary}` : 'none'};
`

export type IconButtonProps = {
  icon?: string
  label?: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  size?: number
  width?: number
  height?: number
  tooltipPlacement?: TooltipPlacement
  disabled?: boolean
  style?: CSSProperties
  children?: ReactNode
  text?: string
  className?: string
  testId?: string
  outline?: boolean
  key?: string
}

const IconButton = forwardRef(
  (
    {
      icon = 'MdBuild',
      label,
      onClick,
      size = 10,
      tooltipPlacement = 'top',
      disabled = false,
      style,
      className = '',
      children,
      width,
      height,
      text,
      outline = false,
      ...rest
    }: IconButtonProps,
    ref
  ): ReactElement => {
    const { Icons } = useStaticResourceStateValue()
    const Icon = Icons[icon] || Icons.MdBuild

    const btn = (
      <ButtonContainer
        {...(!disabled &&
          !_.isNil(onClick) && {
            onClick: e => {
              onClick(e)
            },
          })}
        disabled={disabled}
        outline={outline}
        type='button'
        className={`svgButton ${className}`}
        style={style}
        data-testid={rest.testId}
        ref={ref}
        {...rest}
      >
        {children || (
          <>
            <Icon
              {...(size && { size })}
              {...(width && { width })}
              {...(height && { height })}
            />
            {text ? ` ${text}` : ''}
          </>
        )}
      </ButtonContainer>
    )

    return label ? (
      <Tooltip
        placement={tooltipPlacement}
        trigger={['hover']}
        overlay={<span>{label}</span>}
      >
        {btn}
      </Tooltip>
    ) : (
      btn
    )
  }
)

export default IconButton
