import originalFetch from 'isomorphic-fetch'
import { getFetchParams, getForceUseSuSession } from 'services/utils'
import _ from 'lodash'

export const transformMetadata = metadata => {
  const { properties, ...rest } = metadata
  const transformedProperties = _.reduce(
    properties,
    (acc, cur) => {
      const { name } = cur
      return { ...acc, [name]: _.pickBy(cur) }
    },
    {}
  )
  return { ...rest, properties: transformedProperties }
}

class MetadataService {
  constructor({ upBaseUrl, originalMetadata, credentials } = {}) {
    this.upBaseUrl = upBaseUrl
    this.originalMetadata = transformMetadata(originalMetadata)
    this.credentials = credentials
    this.forceUseSuSession = getForceUseSuSession(originalMetadata)
  }

  fetchDynamicMetadata = async () => {
    const fetchParams = getFetchParams({
      method: 'post',
      body: JSON.stringify(this.originalMetadata),
      credentials: this.credentials,
      forceUseSuSession: this.forceUseSuSession,
    })
    const response = await originalFetch(
      `${this.upBaseUrl}/dynamicMetadata`,
      fetchParams
    )
    return response.ok ? response.json() : undefined
  }
}

export default MetadataService
