import { ReactElement, useMemo } from 'react'

import ButtonGroup, {
  ButtonGroupOnChange,
  ButtonOption,
} from 'components/common/ButtonGroup'

import { MFATypes } from 'types/user'
import { MFA_OPTIONS } from 'constants/common'

import scss from './index.module.scss'

const MFAOptionsSwitch = ({
  selectedMFA,
  onChange,
}: {
  selectedMFA?: MFATypes
  onChange: ButtonGroupOnChange
}): ReactElement => {
  const buttonOptions = useMemo(
    (): ButtonOption[] =>
      MFA_OPTIONS.map(({ label, ...props }) => ({
        ...props,
        children: label,
        className: scss.customBtnStyles,
      })),
    []
  )

  return (
    <ButtonGroup
      className={scss.btnGroup}
      buttonOptions={buttonOptions}
      selectedValue={selectedMFA}
      onChange={onChange}
      showCheckmark
    />
  )
}

export default MFAOptionsSwitch
