// libraries
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useMount } from 'react-use'

// constants
import { PROPERTY_VARIABLE_TYPES } from 'constants/filter'
import { BAR_CHART_TYPES } from 'constants/widget'

// utils
import {
  useAssetWidgetConfigs,
  useBaseAssetWidgetPanel,
} from 'components/assets/assetsProfile/hooks'

import { AssetWidgetPropTypes } from 'propTypes'
import { validNumericProperties } from 'helpers/filter'
import { useUpdateWidgetSettings } from 'hooks'

// components
import {
  BarWidgetConfig,
  BAR_CHART_TABS,
} from 'components/map/layers/widgets/WidgetSettingTabs/BarWidgetTab'
import {
  PropertyPicker,
  MinMaxRange,
  TitleWithTooltip,
} from 'components/common'

const AssetBarWidgetPanel = ({ widget }) => {
  const {
    enableRelationship,
    relatedAssetRelationshipId,
    renderAssetToggleAndRelatedMultiSelect,
    propertyOptions,
    isAssetRelationshipMany,
  } = useBaseAssetWidgetPanel({ widget })

  const { settings } = widget

  const { updateWidgetConfigsSettings, updateAssetWidgetConfigs } =
    useAssetWidgetConfigs(widget)

  const isFeaturesBarDisabled = useMemo(
    () => _.isEmpty(validNumericProperties(propertyOptions)),
    [propertyOptions]
  )

  const {
    type = isFeaturesBarDisabled
      ? BAR_CHART_TYPES.group
      : BAR_CHART_TYPES.features,
    yAxisRange = [],
    xAxisPropertyName,
    yAxisPropertyName,
  } = settings

  useMount(() => {
    if (!settings.type) {
      updateWidgetConfigsSettings({ type })
    }
  })

  const { onSelect, selectedIndex } = useUpdateWidgetSettings({
    settings,
    type,
    tabs: BAR_CHART_TABS,
    updateWidgetSettings: updateAssetWidgetConfigs,
  })

  const renderStylingOptionsForSingleBar = () => (
    <>
      <div className='groupOption'>
        <div className='groupOptionContent'>
          <TitleWithTooltip title='x-Axis label' />
          <PropertyPicker
            property={xAxisPropertyName}
            onChange={val => {
              updateWidgetConfigsSettings({
                property: val,
              })
            }}
            placeholder='Pick a string or numeric property'
            propertyTypes={[
              PROPERTY_VARIABLE_TYPES.string,
              PROPERTY_VARIABLE_TYPES.number,
            ]}
            propertyOptions={propertyOptions}
            isMulti={false}
            isClearable={false}
            preSelect
            useOptionValueOnly
          />
        </div>
      </div>
      <div className='groupOption'>
        <div className='groupOptionContent'>
          <TitleWithTooltip title='Y-Axis Value' tooltip='The value to plot' />
          <PropertyPicker
            property={yAxisPropertyName}
            onChange={val => {
              updateWidgetConfigsSettings({
                yAxisPropertyName: val,
              })
            }}
            propertyTypes={[PROPERTY_VARIABLE_TYPES.number]}
            propertyOptions={propertyOptions}
            isMulti={false}
            isClearable={false}
            placeholder='Pick a numeric property'
          />
          {yAxisPropertyName && (
            <MinMaxRange
              minValue={yAxisRange[0]}
              maxValue={yAxisRange[1]}
              isClearable
              onChange={val => updateWidgetConfigsSettings({ yAxisRange: val })}
            />
          )}
        </div>
      </div>
    </>
  )

  return (
    <>
      {renderAssetToggleAndRelatedMultiSelect()}
      {enableRelationship ? (
        relatedAssetRelationshipId ? (
          isAssetRelationshipMany ? (
            <BarWidgetConfig
              propertyOptions={propertyOptions}
              settings={settings}
              onChange={updateWidgetConfigsSettings}
              onSelect={onSelect}
              selectedIndex={selectedIndex}
              isFeaturesBarDisabled={isFeaturesBarDisabled}
            />
          ) : (
            renderStylingOptionsForSingleBar()
          )
        ) : (
          <></>
        )
      ) : (
        renderStylingOptionsForSingleBar()
      )}
    </>
  )
}

AssetBarWidgetPanel.propTypes = {
  widget: PropTypes.shape(AssetWidgetPropTypes).isRequired,
}

export default AssetBarWidgetPanel
