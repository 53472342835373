// libraries
import { ReactElement, useState, FormEvent, useMemo, useEffect } from 'react'
import styled from '@emotion/styled'
import _ from 'lodash'
import { useBoolean, useInterval, useRequest } from 'ahooks'
import { v4 as uuidv4 } from 'uuid'
import to from 'await-to-js'

// constants
import { UNIFIED_PIPELINE_FILE_UPLOADER_STATUS } from 'components/common/UnifiedPipelineFileUploader/constants'
import { BUTTON_VARIANTS, BUTTON_SIZES } from 'components/common/Button'
import { DATE_TIME_COMPARISON_EDITOR_PRECISION } from 'constants/workflow'

// utils
import { useAuthStateValue } from 'contexts'
import { useConfigStateValue } from 'contexts/ConfigContext'
import AuthService from 'services/authentication'
import { getNormalizedFilename } from 'helpers/utils'
import log from 'helpers/log'
import { getFetchParams } from 'services/utils'
import {
  getUploadPayload,
  getDownloadPayload,
  getSheetTabData,
} from 'components/common/UnifiedPipelineFileUploader/utils'
import { useTimezone } from 'hooks'

// types
import type {
  Payload,
  SpecificationParameters,
  FileTemplate,
  UploaderAccept,
} from 'types/common'
import type { User } from 'types/user'

// components
import { DateRangePicker } from 'components/common/DateTime'
import {
  SpecificationParametersList,
  Button,
  IconButton,
  VerticalDivider,
  FileUploader,
} from 'components/common'
import SectionHeader from './SectionHeader'
import SubmitButton from './SubmitButton'

import scss from './index.module.scss'
import { InputLabel } from '../SpecificationParametersList'

const DEFAULT_INTERVAL_MS = 1 * 1000

const POLLING_DELAY_S = 0

const POLLING_INTERVAL_MS = 2 * 1000

const POLLING_TIMEOUT_S = 10

export const Section = styled.div<{ isDisabled?: boolean }>`
  padding-right: 25px;
  padding-bottom: 30px;
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.isDisabled ? 0.6 : 1)};
`

const getFetchOptions = async (body: Payload): Promise<RequestInit> => {
  const credentials = await AuthService.getCredentials()
  return getFetchParams({
    method: 'POST',
    body: JSON.stringify(body),
    credentials,
  })
}

export type Settings = Payload

const UnifiedPipelineFileUploader = ({
  onExport = _.noop,
  isSingleFile = true,
  keepWaitingForResponseFile = true,
  specificationParameters,
  defaultSettings,
  bucket,
  path,
  accept,
  acceptFileName,
  template,
  newRunMessage,
  submittingMessage,
  uploadMessage,
}: {
  onExport?: (v: string) => void
  maxFiles?: number
  keepWaitingForResponseFile?: boolean
  specificationParameters?: SpecificationParameters
  defaultSettings?: Payload
  bucket: string
  path: string
  isSingleFile?: boolean
  accept: UploaderAccept
  acceptFileName: string
  template?: FileTemplate
  newRunMessage: string
  submittingMessage: string
  uploadMessage?: string
}): ReactElement => {
  const { timezone } = useTimezone()
  const { currentUser } = useAuthStateValue()
  const { email, username }: User = currentUser

  const [
    isSubmitting,
    { setTrue: setSubmittingTrue, setFalse: setSubmittingFalse },
  ] = useBoolean(false)

  const [seconds, setSeconds] = useState(0)
  const [interval, setInterval] = useState<number | undefined>()
  const [fileStatus, setFileStatus] = useState<
    { status: boolean; notes?: string } | undefined
  >(undefined)
  const [downloadBase64File, setDownloadedBase64File] = useState<string>()
  const [importedFiles, setImportedFiles] = useState<File[]>([])
  const [settings, setSettings] = useState<Settings>(defaultSettings)

  const [uniqFileName, setUniqFileName] = useState<string>()
  const [pollingFetchOption, setPollingFetchOption] = useState<
    RequestInit | undefined
  >()

  const {
    baseUrl,
    logging: { environment },
  } = useConfigStateValue()

  const isFileEmpty = useMemo(
    () => _.isEmpty(importedFiles) || !_.first(importedFiles),
    [importedFiles]
  )

  const endpoint = `${baseUrl}up/retrieve`

  const resetRequest = () => {
    setSubmittingFalse()
    setSeconds(0)
  }

  const getProcessedResult = async () => {
    if (!keepWaitingForResponseFile || !uniqFileName || downloadBase64File)
      return

    const body = getDownloadPayload({
      environment,
      fileName: uniqFileName,
      bucket,
    })
    let fetchOptions = pollingFetchOption
    if (!pollingFetchOption) {
      fetchOptions = await getFetchOptions(body)
      setPollingFetchOption(fetchOptions)
    }

    const result = await fetch(endpoint, fetchOptions)
      .then(r => (r.status === 200 ? r.json() : undefined))
      .then((r: { base64Data: string }) => {
        const { base64Data } = r || {}
        if (!base64Data) {
          log.debug('The file is not ready')
        }
        return base64Data
      })

    if (result) {
      log.debug('Got the file')
      const data = getSheetTabData(result)
      const { status, notes } = _.first(data)
      const isSuccess =
        status === UNIFIED_PIPELINE_FILE_UPLOADER_STATUS.completed
      setFileStatus({ status: isSuccess, notes })
      resetRequest()
      setDownloadedBase64File(result)
      onExport(result)
    }
  }

  const { runAsync, cancel: cancelGetProcessedResult } = useRequest(
    getProcessedResult,
    {
      pollingInterval: POLLING_INTERVAL_MS,
      pollingWhenHidden: false,
      manual: true,
    }
  )

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setDownloadedBase64File(undefined)

    if (isFileEmpty) return

    if (!settings && specificationParameters) return

    const file = importedFiles[0]
    if (!file) return

    const folderName = `${Date.now()}_${uuidv4()}`

    const fileName = `${folderName}_${getNormalizedFilename(file.name)}`

    if (keepWaitingForResponseFile) {
      setUniqFileName(fileName)
    }
    setSubmittingTrue()

    const body = await getUploadPayload({
      settings: {
        ...settings,
        email,
        username,
        originalFilename: isSingleFile ? importedFiles[0].name : folderName,
        name: currentUser.name,
        timezone,
      },
      uploadFiles: isSingleFile ? [importedFiles[0]] : importedFiles,
      environment,
      ...(isSingleFile
        ? {
            fileName,
          }
        : {
            folderName,
          }),
      timezone,
      bucket,
      path,
    })

    const fetchOptions = await getFetchOptions(body)

    const [err, response] = await to(fetch(endpoint, fetchOptions))
    if (err || !response?.ok) {
      log.debug(body)
      log.error(err)
      log.error(response)
      setFileStatus({ status: false, notes: 'Something went wrong' })
      setSubmittingFalse()
    } else if (keepWaitingForResponseFile) {
      log.info(
        `File submitted${
          POLLING_DELAY_S
            ? `, will fetch the result after ${POLLING_DELAY_S} seconds`
            : ''
        } `
      )
      setTimeout(() => {
        runAsync()
      }, POLLING_DELAY_S * 1000)
    } else {
      setFileStatus({ status: true })
    }
  }

  useEffect(() => {
    if (downloadBase64File) {
      cancelGetProcessedResult()
      setPollingFetchOption(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadBase64File])

  useEffect(() => {
    const newInterval = isSubmitting ? DEFAULT_INTERVAL_MS : undefined
    setInterval(newInterval)
  }, [isSubmitting])

  useInterval(() => {
    if (!keepWaitingForResponseFile) return

    setSeconds((s: number) => s + 1)
    if (seconds > POLLING_TIMEOUT_S) {
      log.info('Timeout, cancel request')
      cancelGetProcessedResult()
      setInterval(undefined)
      resetRequest()
      // setFileSubmitted()
      if (!downloadBase64File) {
        setFileStatus({ status: true })
      }
    }
  }, interval)

  const hasFileUploadError =
    fileStatus && !fileStatus.status && !!fileStatus.notes

  const hasValidSettings = _.isEmpty(specificationParameters)
    ? true
    : !_.isEmpty(settings)

  const isSubmitDisabled =
    !hasValidSettings || isFileEmpty || hasFileUploadError
  const isSettingsDisabled = isFileEmpty || isSubmitting
  const renderUploader = () => (
    <form onSubmit={handleSubmit} className={scss.container}>
      <Section isDisabled={isSubmitting}>
        <SectionHeader number={1} title='Upload' />
        <FileUploader
          accept={accept}
          placeholderText={`Drag and drop the ${acceptFileName} file`}
          isSingleFile={isSingleFile}
          template={template}
          onChange={v => {
            setFileStatus(undefined)
            setImportedFiles(v)
            setSettings(defaultSettings)

            if (_.isEmpty(v)) {
              resetRequest()
            }
          }}
          files={importedFiles}
          disabled={isSubmitting}
          {...(hasFileUploadError && {
            errorMessage: fileStatus.notes,
          })}
          uploadMessage={uploadMessage}
        />
      </Section>
      {!_.isEmpty(specificationParameters) && (
        <Section isDisabled={isSettingsDisabled}>
          <SectionHeader
            className={scss.settingsHeader}
            number={2}
            title='Settings'
          >
            <VerticalDivider height={13} className='me-1 ms-3' />
            <Button
              variant={BUTTON_VARIANTS.link}
              size={BUTTON_SIZES.small}
              disabled={isSettingsDisabled}
              className='pb-1'
              onClick={() => setSettings(defaultSettings)}
            >
              Reset
            </Button>
          </SectionHeader>
          <div className={scss.parametersListWrapper}>
            <SpecificationParametersList
              disabled={isFileEmpty}
              specificationParameters={specificationParameters}
              specParams={settings}
              onChange={(newPayload: Payload) => {
                setSettings(oldFormDataValue => ({
                  ...oldFormDataValue,
                  ...newPayload,
                }))
              }}
              labelClassName='semiBold smallText'
              rowClassName='mb-5'
            />
            <div className='mt-5'>
              <InputLabel
                title='Date range'
                description='Optimization will run faster with smaller date range'
                className='semiBold smallText'
              />
              <div className={scss.dateRange}>
                <DateRangePicker
                  startDate={settings?.startDate}
                  endDate={settings?.endDate}
                  selectedTimeType={DATE_TIME_COMPARISON_EDITOR_PRECISION.DAY}
                  isDisabled={isFileEmpty || isSubmitting}
                  timezone={timezone}
                  showTimeSelect={false}
                  onChange={(newPayload: Payload) => {
                    setSettings(oldFormDataValue => ({
                      ...oldFormDataValue,
                      ..._.pick(newPayload, ['startDate', 'endDate']),
                    }))
                  }}
                  isClearable
                  disabledKeyboardNavigation
                />
              </div>
            </div>
          </div>
        </Section>
      )}

      <hr />
      <SubmitButton
        disabled={isSubmitDisabled}
        submitting={isSubmitting}
        submittingMessage={submittingMessage}
      />
    </form>
  )

  const renderResponseResult = () => {
    return (
      <>
        <div className={scss.container}>
          <IconButton
            icon='AiOutlineCheck'
            size={26}
            className='text-success'
          />
          <span className='ms-1 largeText semiBold'>
            Once the processing is complete we’ll email you
          </span>
        </div>

        <Button
          variant={BUTTON_VARIANTS.secondary}
          onClick={() => {
            setFileStatus(undefined)
            setImportedFiles([])
            resetRequest()
            setSettings(defaultSettings)
          }}
          className='mt-5'
        >
          {newRunMessage}
        </Button>
      </>
    )
  }

  return <>{fileStatus?.status ? renderResponseResult() : renderUploader()}</>
}

export default UnifiedPipelineFileUploader
