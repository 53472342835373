import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { useToggle } from 'react-use'
import moment from 'moment-timezone'
import { FiDownloadCloud } from 'react-icons/fi'
import PropTypes from 'prop-types'
import { Timeline } from 'vis-timeline/peer'
import _ from 'lodash'

// constants
import { CHANGE_RATIO } from 'components/common/Timeline/HistoricalTimeline/constants'
import { TIME_RANGE_MODES } from 'constants/filter'
import { THEMES } from 'constants/colour'

// utils
import {
  getFutureUtcDate,
  getTimeRangeString,
  isDatetimeInRange,
  getValidDatetimeUnit,
  getDurationAsIntervalUnit,
  getSnappedDateMoment,
} from 'helpers/datetime'

// components
import { DateTimePicker } from 'components/common/'
import IntervalSetting from '../IntervalSetting'

import ReplaySpeedSetting from '../ReplaySpeedSetting'
import ReplayStatusSetting from '../ReplayStatusSetting'

import scss from '../../index.module.scss'

const TimelineControls = ({
  timelineState,
  timezone,
  timezoneAbbr,
  updateTimelineState,
  updateTimeline,
  updateTimelineItem,
  layers,
  updateMapLayers,
  updateMapConfigs,
  instance,
  shouldDisplayTimeline,
}) => {
  const {
    intervalUnit,
    userSettingDataTimeRange,
    intervalNumber,
    selectedDateTimeRange,
  } = timelineState

  const [isReplaying, toggleReplay] = useToggle(false)
  const [globalDateTimeRange, setGlobalDateTimeRange] = useState(
    userSettingDataTimeRange
  )

  useEffect(() => {
    setGlobalDateTimeRange(userSettingDataTimeRange)
  }, [userSettingDataTimeRange])

  const handleReplay = useCallback(() => {
    if (!instance) return

    const validUnit = getValidDatetimeUnit(intervalUnit)
    const validInterval =
      intervalNumber ||
      getDurationAsIntervalUnit(
        selectedDateTimeRange.start,
        selectedDateTimeRange.end,
        validUnit
      )

    const calcNewTime = t =>
      getFutureUtcDate({
        interval: validInterval * CHANGE_RATIO,
        intervalUnit: validUnit,
        baseDatetime: t,
      })

    const rangeStart = calcNewTime(selectedDateTimeRange.start)

    const isTimeInRange = isDatetimeInRange(
      rangeStart,
      userSettingDataTimeRange
    )

    const start = isTimeInRange
      ? rangeStart
      : moment.utc(userSettingDataTimeRange.start)

    const snappedStart = getSnappedDateMoment({
      baseDatetime: start,
      unit: validUnit,
      timezone,
    })

    const end = getFutureUtcDate({
      interval: validInterval,
      intervalUnit: validUnit,
      baseDatetime: snappedStart,
    })

    updateTimelineItem({
      start: snappedStart,
      end,
      backgroundDataTimeRange: userSettingDataTimeRange,
      shouldFocusSelectedRange: false,
    })
  }, [
    instance,
    intervalUnit,
    intervalNumber,
    selectedDateTimeRange.start,
    selectedDateTimeRange.end,
    userSettingDataTimeRange,
    updateTimelineItem,
    timezone,
  ])

  const applyGlobalTimeFilter = useCallback(() => {
    // overwrite all layers userSettingDataTimeRange if applicable
    const newLayers = layers.map(layer => {
      const { specParams } = layer
      if (_.isEmpty(specParams)) return layer
      return {
        ...layer,
        specParams: {
          ...specParams,
          startTime: globalDateTimeRange.start,
          endTime: globalDateTimeRange.end,
        },
        dateTimeRange: globalDateTimeRange,
      }
    })

    updateMapLayers(newLayers)
  }, [layers, updateMapLayers, globalDateTimeRange])

  const datetimeRangeKey = useMemo(
    () => getTimeRangeString(userSettingDataTimeRange),
    [userSettingDataTimeRange]
  )

  return (
    <div
      className={`d-flex justify-content-between align-items-center ${scss.toolbox}`}
    >
      <div className='d-flex align-items-center'>
        <DateTimePicker
          key={datetimeRangeKey}
          timezone={timezone}
          className={scss.timePickerContainer}
          theme={THEMES.dark}
          optionContainerClassName={scss.timePickerOptionContainer}
          startTime={{
            value: userSettingDataTimeRange.start,
            mode: TIME_RANGE_MODES.absoluteTime,
          }}
          endTime={{
            value: userSettingDataTimeRange.end,
            mode: TIME_RANGE_MODES.absoluteTime,
          }}
          onChange={({ startTime, endTime }) => {
            setGlobalDateTimeRange({ start: startTime, end: endTime })
          }}
          autoApply={false}
        >
          <button
            className={scss.reload}
            type='button'
            onClick={applyGlobalTimeFilter}
          >
            <FiDownloadCloud /> Reload data
          </button>
        </DateTimePicker>
      </div>
      {shouldDisplayTimeline && (
        <>
          <IntervalSetting
            timezone={timezone}
            timezoneAbbr={timezoneAbbr}
            intervalNumber={intervalNumber}
            selectedDateTimeRange={selectedDateTimeRange}
            intervalUnit={intervalUnit}
            userSettingDataTimeRange={userSettingDataTimeRange}
            updateTimelineState={updateTimelineState}
            updateTimelineItem={updateTimelineItem}
            updateTimeline={updateTimeline}
            updateMapConfigs={updateMapConfigs}
          />
          <div className='d-flex align-items-center'>
            <ReplaySpeedSetting
              handleReplay={handleReplay}
              isReplaying={isReplaying}
            />
            <ReplayStatusSetting
              isReplaying={isReplaying}
              toggleReplay={toggleReplay}
              dataTimeRange={userSettingDataTimeRange}
              selectedDateTimeRange={selectedDateTimeRange}
              intervalNumber={intervalNumber}
              intervalUnit={intervalUnit}
              updateTimelineItem={updateTimelineItem}
              instance={instance}
            />
          </div>
        </>
      )}
    </div>
  )
}

TimelineControls.propTypes = {
  timelineState: PropTypes.shape({
    intervalUnit: PropTypes.string,
    userSettingDataTimeRange: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
    }).isRequired,
    intervalNumber: PropTypes.number,
    selectedDateTimeRange: PropTypes.shape({
      start: PropTypes.any,
      end: PropTypes.any,
    }).isRequired,
  }).isRequired,
  timezone: PropTypes.string,
  timezoneAbbr: PropTypes.string,
  updateTimelineState: PropTypes.func.isRequired,
  updateTimeline: PropTypes.func.isRequired,
  updateTimelineItem: PropTypes.func.isRequired,
  updateMapConfigs: PropTypes.func.isRequired,
  instance: PropTypes.instanceOf(Timeline),
  layers: PropTypes.arrayOf(PropTypes.shape({})),
  updateMapLayers: PropTypes.func.isRequired,
  shouldDisplayTimeline: PropTypes.bool,
}

TimelineControls.defaultProps = {
  layers: [],
  timezone: undefined,
  timezoneAbbr: undefined,
  instance: undefined,
  shouldDisplayTimeline: false,
}

export default TimelineControls
