// libraries
import { createSelector } from 'reselect'

import { disableInvalidProperties } from 'helpers/utils'

const workflowState = state => state.workflowState

const getPropertiesOptions = createSelector(workflowState, ({ datasource }) =>
  datasource ? disableInvalidProperties(datasource.properties) : []
)

const workflowSelectors = state => ({
  propertiesOptions: getPropertiesOptions(state),
})

export default workflowSelectors
