// libraries
import { ReactElement } from 'react'
import _ from 'lodash'
import { ColumnBodyOptions } from 'primereact/column'

import { UserPlaceholder, UsersPickerWithContext } from 'components/common'

const Template = ({
  rowData,
  options: { field },
}: {
  rowData: unknown
  options: ColumnBodyOptions
}): ReactElement => {
  const cellValue = _.get(rowData, field)

  return (
    <UsersPickerWithContext
      bgColour='transparent'
      value={cellValue}
      readOnly
      isMulti={false}
      placeholder={<UserPlaceholder text='Unknown' />}
    />
  )
}

const UserTemplate = (
  rowData: unknown,
  options: ColumnBodyOptions
): ReactElement => {
  return <Template rowData={rowData} options={options} />
}

export default UserTemplate
