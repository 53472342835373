import { ReactElement, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit, { StarterKitOptions } from '@tiptap/starter-kit'
import Mention from '@tiptap/extension-mention'
import { Color } from '@tiptap/extension-color'
import TextStyle from '@tiptap/extension-text-style'
import Highlight from '@tiptap/extension-highlight'
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import Table from '@tiptap/extension-table'
import TableRow from '@tiptap/extension-table-row'
import TableHeader from '@tiptap/extension-table-header'
import TableCell from '@tiptap/extension-table-cell'
import Link from '@tiptap/extension-link'

import type { PropertiesMetadata } from 'types/common'
import { SUPPORTED_FILTER_PROPERTY_TYPES } from 'constants/filter'
import { THEMES } from 'constants/colour'

import EditorToolbar from './EditorToolbar'
import { getSuggestionPluginConfig } from './suggestion'
import {
  prepareHtmlForEditor,
  prepareHtmlForSave,
  getPlainText,
  filterUndefinedProperties,
} from './utils'

import scss from './index.module.scss'

const PLAIN_TEXT_CONFIG = {
  bold: false,
  bulletList: false,
  heading: false,
  italic: false,
  listItem: false,
  orderedList: false,
  hardBreak: false,
}

const getUndefinedPropsMessage = (undefProperties: string[]) =>
  `${undefProperties.join(', ')} ${
    undefProperties.length > 1
      ? 'are not valid properties'
      : 'is not a valid property'
  }`

const prepareExtensions = ({
  plainText,
  supportedOptions,
  hasNoOptions,
}: {
  plainText: boolean
  supportedOptions: PropertiesMetadata
  hasNoOptions: boolean
}) => [
  StarterKit.configure(
    // Disable some features on plainText mode
    plainText ? (PLAIN_TEXT_CONFIG as StarterKitOptions) : {}
  ),
  ...(plainText
    ? []
    : [
        TextStyle,
        Color,
        Highlight.configure({ multicolor: true }),
        Underline,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        TableCell,
        TableHeader,
        TableRow,
        Table.configure({
          resizable: true,
        }),
        Link.configure({
          autolink: true,
          HTMLAttributes: {
            rel: 'noopener noreferrer nofollow',
            target: '_blank',
          },
        }),
      ]),
  // Disable mentions extension if there are no options
  ...(!hasNoOptions
    ? [
        Mention.configure({
          HTMLAttributes: {
            class: scss.mention,
          },
          suggestion: getSuggestionPluginConfig({
            options: supportedOptions,
            triggerChar: '{',
          }),
          renderLabel({ node }) {
            return `${node.attrs.label ?? node.attrs.id}`
          },
        }),
      ]
    : []),
]

export type EditorProps = {
  value: string
  onChange: (html: string) => void
  popoverSuggestions?: PropertiesMetadata
  suggestions?: PropertiesMetadata
  className?: string
  message?: string
  readOnly?: boolean
  plainText?: boolean
  propPrefix?: string
  grayOutIfReadOnly?: boolean
  theme?: keyof typeof THEMES
}

/**
 * @param props.value - the editor's content
 * @param props.onChange - called when user make changes
 * @param props.popoverSuggestions - suggestions that should be shown in the popover dropdown
 * @param props.suggestions - all suggestions that can be used for the editor
 * This array is used to properly display existing selected items inside the editor's HTML.
 * @param props.className - className which will be added to the 'editable' <div>
 * @param props.message - message that should be displayed under the editor
 * @param props.readOnly - in readOnly mode user can't edit the content
 * @param props.plainText - in the plainText mode the editor will return a text without any HTML
 * @param props.propPrefix - prefix for properties, {[prefix]propName}
 * @param props.grayOutIfReadOnly - gray out the content if readOnly === true
 * @param props.theme - if dark, changes the editor background color. Used to show the user how text will look at the dark background
 */
const RichTextEditor = ({
  value,
  onChange,
  popoverSuggestions,
  suggestions,
  className = '',
  message = '',
  readOnly = false,
  plainText = false,
  propPrefix = 'properties.',
  grayOutIfReadOnly = false,
  theme = THEMES.light,
}: EditorProps): ReactElement => {
  const [errorMessage, setErrorMessage] = useState('')

  const content = useMemo(
    () => prepareHtmlForEditor(value, suggestions, propPrefix),
    [value, suggestions, propPrefix]
  )
  const supportedOptions = useMemo(
    () =>
      // Filter options that cannot be displayed as text (eg objects)
      popoverSuggestions
        ?.filter(({ type }) => SUPPORTED_FILTER_PROPERTY_TYPES.includes(type))
        .map(item => ({ ...item, id: item.value })) || [],
    [popoverSuggestions]
  )

  const hasNoOptions = _.isEmpty(supportedOptions)

  const editor = useEditor(
    {
      editable: !readOnly,
      editorProps: {
        attributes: {
          class: `${scss.proseMirror} ${className}`,
        },
      },
      extensions: prepareExtensions({
        plainText,
        supportedOptions,
        hasNoOptions,
      }),
      onUpdate: ({ editor: editorArg }) => {
        const html = editorArg.getHTML()
        const { filteredHtml, undefProperties } =
          filterUndefinedProperties(html)

        const result = plainText
          ? getPlainText(filteredHtml, propPrefix)
          : prepareHtmlForSave(filteredHtml, propPrefix)

        if (filteredHtml !== html) {
          // If some undefined properties were stripped, update the content
          editorArg.commands.setContent(filteredHtml)
          setErrorMessage(getUndefinedPropsMessage(undefProperties))
        } else {
          setErrorMessage('')
        }

        onChange(result)
      },
      content,
    },
    // Reconfigure editor in case if there were no options before
    [hasNoOptions, supportedOptions]
  )

  useEffect(() => {
    // Toggle the 'editable' state if 'readOnly' is changing
    editor?.setEditable(!readOnly)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readOnly])

  if (!editor) return <></>

  return (
    <div>
      <div
        className={`${scss.editorWrapper} ${
          grayOutIfReadOnly && readOnly ? scss.readOnlyGrayedOut : ''
        }`}
      >
        {!plainText && (
          <EditorToolbar
            editor={editor}
            readOnly={readOnly}
            suggestions={supportedOptions}
          />
        )}
        <EditorContent
          editor={editor}
          className={_.compact([
            scss.editor,
            theme === THEMES.dark && scss.dark,
          ]).join(' ')}
        />
      </div>

      {!hasNoOptions && (
        <div className={scss.tip}>
          <span className={scss.tipHighlighted}>PRO TIP:</span>{' '}
          {'You can use { to begin lookup'}
        </div>
      )}
      {message && <div className={scss.message}>{message}</div>}
      {errorMessage && <div className={scss.errorMessage}>{errorMessage}</div>}
    </div>
  )
}

export default RichTextEditor
