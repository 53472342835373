import { ReactElement, useState } from 'react'
import _ from 'lodash'
import { Field } from 'react-final-form'
import { FieldSubscription } from 'final-form'
import { OnChange } from 'react-final-form-listeners'
import { useMount } from 'react-use'

const ConditionalResetField = ({
  watch,
  condition = _.constant(true),
  reset,
  valuePath,
  valueForReset,
  subscription,
}: {
  // The field to test the condition against
  watch: string
  // The condition to test the new value against
  condition?: (value: unknown) => boolean
  // The field to reset
  reset: string
  // In case the value is in the object
  valuePath?: string
  // Will be sent on reset
  valueForReset?: unknown
  subscription?: FieldSubscription
}): ReactElement => {
  const [lastWatchId, setLastWatchId] = useState<string>()
  useMount(() => {
    setLastWatchId(watch)
  })

  return (
    <Field name={reset} subscription={subscription}>
      {({ input, meta }) => {
        return (
          <OnChange name={watch}>
            {(value, previous) => {
              setLastWatchId(watch)
              const currValue = valuePath ? value?.[valuePath] : value
              const prevValue = valuePath ? previous?.[valuePath] : previous
              if (
                condition(value) &&
                prevValue !== currValue &&
                lastWatchId === watch
              ) {
                input.onChange(
                  !_.isUndefined(valueForReset) ? valueForReset : meta.initial
                )
              }
            }}
          </OnChange>
        )
      }}
    </Field>
  )
}

ConditionalResetField.defaultProps = {
  // Subscribe to a value by default so we can compare it
  subscription: { value: true },
}

export default ConditionalResetField
