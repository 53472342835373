import { useMemo, ReactElement } from 'react'
import _ from 'lodash'

// constants
import { PAGE_FILTER_PLACEHOLDER } from 'constants/common'

// components
import { MultiSelect } from 'components/common'

// types
import type { FilterSpec } from 'types/filter'
import type { MultiSelectProp } from 'components/common/MultiSelect'

import scss from '../index.module.scss'

import { SelectDropdown } from '../FilterForm'
import FilterTrigger from '../FilterTrigger'
import { getCommonSelectProps } from '../utils'
import useFiltersDropdown from '../hooks/useFiltersDropdown'

const FilterDropdown = ({
  value,
  options,
  onChange,
  title,
  isMulti,
  isClearable,
  formatOptionLabel,
  useOptionValueOnly,
  ...rest
}: FilterSpec & MultiSelectProp & { title?: string }): ReactElement => {
  const { isOpen, toggleOpen, ref } = useFiltersDropdown(value)

  const selectedOptions = useMemo(() => {
    const validValue = isMulti ? value : [value]
    return _(validValue)
      .map(v => _.find(options, ['value', v]))
      .compact()
      .value()
  }, [isMulti, value, options])

  const labels = useMemo(
    () =>
      _.isEmpty(selectedOptions)
        ? PAGE_FILTER_PLACEHOLDER
        : _.map(selectedOptions, 'label').join(', '),
    [selectedOptions]
  )

  return (
    <div className='d-flex align-items-center flex-wrap'>
      <SelectDropdown
        isOpen={isOpen}
        onClose={toggleOpen}
        ref={ref}
        target={
          <FilterTrigger
            title={title}
            labels={labels}
            toggleDropdown={toggleOpen}
          />
        }
      >
        <div className={scss.dropdown}>
          <MultiSelect
            {...rest}
            {...getCommonSelectProps({
              value,
              isMulti,
              useOptionValueOnly,
              onChange,
              toggleOpen,
              formatOptionLabel,
            })}
            menuIsOpen
            options={options}
          />
        </div>
      </SelectDropdown>
    </div>
  )
}

FilterDropdown.defaultProps = {
  value: [],
  options: [],
  isMulti: true,
  isClearable: true,
  formatOptionLabel: undefined,
  useOptionValueOnly: undefined,
}

export default FilterDropdown
