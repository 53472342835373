// libraries
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

// utils
import { useAssetWidgetConfigs } from 'components/assets/assetsProfile/hooks'
import { AssetWidgetPropTypes } from 'propTypes'
import {
  useIssueFilterBuilder,
  ASSET_ISSUE_FILTERS_KEYS,
} from 'components/issue/hooks/useIssuesFilters'

// components
import { HideValueToggle } from 'components/common'

const AssetIssuesListPanel = ({ widget }) => {
  const {
    settings: { issueFilters = {}, hideInvalidValues },
  } = widget

  const { filterValues, renderIssuesFiltersBuilder } = useIssueFilterBuilder({
    defaultFilters: issueFilters,
    vertical: true,
    allowedFilters: ASSET_ISSUE_FILTERS_KEYS,
  })

  const { updateWidgetConfigsSettings } = useAssetWidgetConfigs(widget)

  useEffect(() => {
    updateWidgetConfigsSettings({ issueFilters: filterValues })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues])

  return (
    <>
      <div className='mt-3'>
        <HideValueToggle
          label='Hide empty values'
          hideValue={hideInvalidValues}
          onChange={updateWidgetConfigsSettings}
          onChangeValueKey='hideInvalidValues'
        />
      </div>
      <div className='groupOptionTitle mt-3'>Issue filter settings</div>
      {renderIssuesFiltersBuilder()}
    </>
  )
}

AssetIssuesListPanel.propTypes = {
  widget: PropTypes.shape(AssetWidgetPropTypes).isRequired,
}

export default AssetIssuesListPanel
