import { ReactElement, useCallback } from 'react'
import { Editor } from '@tiptap/react'

// types
import type { PropertiesMetadata, PropertyMetadata } from 'types/common'
import type { CustomDropdownElementProps } from 'components/common/Dropdown'
import type { MultiSelectProp } from 'components/common/MultiSelect'

// utils
import { getPropertyOptionLabel } from 'components/common/PropertyPicker'

// constants
import { TOOLTIP_PLACEMENT } from 'constants/settings'

import { Dropdown, MultiSelect } from 'components/common'

import ToolbarButton from '../ToolbarButton'
import { ICON_SIZE } from '../constants'

import scss from './index.module.scss'

const SuggestionDropdown = ({
  editor,
  readOnly,
  suggestions,
}: {
  editor: Editor
  readOnly?: boolean
  suggestions: PropertiesMetadata
}): ReactElement => {
  const renderPropsListToggle = useCallback(
    () => (
      <ToolbarButton
        tooltip='Add property'
        icon='RiBracesLine'
        size={ICON_SIZE}
        disabled={readOnly}
      />
    ),
    [readOnly]
  )

  const onInsertProperty = useCallback(
    (selectedItem: PropertyMetadata) => {
      // Pasting the item into the current selection
      // For the reference:
      // https://github.com/ueberdosis/tiptap/blob/main/packages/extension-mention/src/mention.ts#L29
      const range = {
        from: editor.state.selection.from,
        to: editor.state.selection.to,
      }
      const { nodeAfter } = editor.view.state.selection.$to
      const overrideSpace = nodeAfter?.text?.startsWith(' ')

      if (overrideSpace) {
        range.to += 1
      }

      editor
        .chain()
        .focus()
        .insertContentAt(range, [
          {
            type: 'mention',
            attrs: selectedItem,
          },
          {
            type: 'text',
            text: ' ',
          },
        ])
        .run()

      window.getSelection()?.collapseToEnd()
    },
    [editor]
  )

  const renderDropdown = useCallback(
    ({ isVisible, toggleListVisible }: CustomDropdownElementProps) =>
      // Re-render dropdown to reset the inner state
      isVisible ? (
        <MultiSelect
          value={[]}
          className={scss.dropdown}
          onChange={selectedItem => {
            onInsertProperty(selectedItem as PropertyMetadata)
            toggleListVisible(false)
          }}
          options={suggestions}
          formatOptionLabel={
            getPropertyOptionLabel as MultiSelectProp['formatOptionLabel']
          }
          placeholder='Search...'
          autoFocus
          isSearchable
          menuIsOpen
          dropdownOnly
          isDisabled={readOnly}
          isMulti={false}
          isClearable={false}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          backspaceRemovesValue={false}
          tabSelectsValue={false}
        />
      ) : (
        <></>
      ),
    [onInsertProperty, suggestions, readOnly]
  )

  return (
    <Dropdown
      placement={TOOLTIP_PLACEMENT.bottom}
      trigger='click'
      options={suggestions}
      isDisabled={readOnly}
      toggleComponent={renderPropsListToggle}
      dropdownComponent={renderDropdown}
    />
  )
}

export default SuggestionDropdown
