import { ReactElement } from 'react'

// constants
import { USER_EDIT_MODE } from 'constants/user'
import { BUTTON_VARIANTS } from 'components/common/Button'

// components
import { Button } from 'components/common'

import type { UserSettingMode, MFATypes } from 'types/user'

// styles
import scss from '../index.module.scss'

const TwoStepAuthenticationCard = ({
  mfaPreferred,
  authTypeInfo,
  authTypeTitle,
  mfaType,
  mode,
  cardIcon,
  onModeChange,
  bordered,
}: {
  mfaPreferred?: MFATypes
  authTypeInfo: string
  authTypeTitle: string
  mfaType: string
  mode: UserSettingMode
  cardIcon: unknown
  onModeChange: (v: UserSettingMode) => void
  bordered?: boolean
}): ReactElement => {
  return (
    <div className='col-sm'>
      {mfaPreferred === mfaType && (
        <div className={scss.defaultBtn}>Default</div>
      )}

      <div
        className={`text-center ${scss.twoStepAuthCard} ${
          bordered ? scss.textContainer : ''
        }`}
      >
        {cardIcon}
        <div className={scss.authTypeTitle}>{authTypeTitle}</div>
        <div className={scss.authTypeInfo}>
          {mfaPreferred === mfaType ? authTypeInfo : 'Not setup'}
        </div>
        {mfaPreferred === mfaType && (
          <div className='d-flex justify-content-center'>
            <div className='p-0'>
              <Button
                variant={BUTTON_VARIANTS.link}
                onClick={() => onModeChange(USER_EDIT_MODE.resetMfaConfirm)}
              >
                Remove
              </Button>
            </div>
            <div className='p-0'>
              <Button
                variant={BUTTON_VARIANTS.link}
                onClick={() => onModeChange(mode)}
              >
                Update
              </Button>
            </div>
          </div>
        )}

        {(mfaPreferred !== mfaType || !mfaPreferred) && (
          <div className='d-flex justify-content-center'>
            <div className='p-0'>
              <Button
                variant={BUTTON_VARIANTS.link}
                onClick={() => onModeChange(mode)}
              >
                Set Up
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default TwoStepAuthenticationCard
