// libraries
import { FC, useCallback } from 'react'
import { toast } from 'react-hot-toast'

// constants
import { DEFAULT_ICONS } from 'components/common/Message/constants'

// components
import { MessageCloseButton } from 'components/common/Message'
import MessageContent from 'components/common/Message/MessageContent'

// styles
import { ToastContainer } from 'components/common/Toast/styles'

import type { ToastContentProps } from 'components/common/Toast/type'

const Toast: FC<ToastContentProps> = ({
  id,
  visible,
  type,
  icon = DEFAULT_ICONS[type],
  optionalExtraInfo,
  title,
  description,
  buttons,
  outline,
}) => {
  const messageContentProps = {
    icon,
    type,
    optionalExtraInfo,
    title,
    description,
    buttons,
  }

  const toastProps = {
    type,
    visible,
    outline,
  }

  const dismissToast = useCallback(() => toast.dismiss(id), [id])

  return (
    <ToastContainer {...toastProps}>
      <MessageContent {...messageContentProps} />
      <MessageCloseButton onClick={dismissToast} />
    </ToastContainer>
  )
}

export default Toast
