import { BACKEND_ENTITY_TYPES, ENTITIES } from './common'

export const ACTIONS = ['manage', 'create', 'read', 'update', 'delete']

export const SUBJECT_TYPES = {
  all: 'all',
  [BACKEND_ENTITY_TYPES.map]: 'Map',
  [BACKEND_ENTITY_TYPES.asset]: 'Asset',
  [BACKEND_ENTITY_TYPES.user]: 'User',
  [BACKEND_ENTITY_TYPES.rule]: 'Rule',
  login: 'Login',
  pipe: 'Pipe',
  group: 'Group',
  assetProfile: 'AssetProfile',
  catalog: 'Catalog',
  [BACKEND_ENTITY_TYPES.site]: 'Site',
  issue: 'Issue',
  [ENTITIES.form]: 'Form',
}
