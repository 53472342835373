import { PropsWithChildren, ReactElement } from 'react'
import _ from 'lodash'
import { Field } from 'react-final-form'

export type ConditionalFieldProps = {
  // The field to conditionally render based on
  watch: string
  render?: () => ReactElement | void
  // The condition to evaluate against the watched input value
  condition?: (value: unknown) => boolean
}

const ConditionalField = ({
  watch,
  children,
  render = _.noop,
  condition = _.identity,
}: PropsWithChildren<ConditionalFieldProps>): ReactElement => {
  return (
    <Field name={watch} subscription={{ value: true }}>
      {({ input: { value } }) => {
        return condition(value) ? render() || children : null
      }}
    </Field>
  )
}

export default ConditionalField
