// libraries
import { GeoJsonLayer } from '@deck.gl/layers/typed'

// constants
import { LAYER_VIS_CONFIGS } from 'components/map/layers/deckLayers/layerFactory'
import { LAYER_TYPES } from 'constants/map'

// utils
import { getClusteringLayer } from 'components/map/layers/deckLayers/utils'

// components
import Layer from '../baseLayer'

export const geojsonVisConfigs = {
  radius: 'radius',
  radiusProperty: 'radiusProperty',
  lineWidth: 'lineWidth',
  lineWidthProperty: 'lineWidthProperty',
  fillColourType: 'colourType',
  lineColour: 'fillColour',
  lineColourType: 'colourType',
  opacity: 'opacity',
  isFilled: 'isFilled',
  isStroked: 'isStroked',
  elevationScale: 'elevationScale',
  hasStyleHints: 'hasStyleHints',
  heightPropertyValue: 'heightPropertyValue',
  enableClustering: 'enableClustering',
}

export default class GeojsonLayer extends Layer {
  constructor(props) {
    super(props)

    this.registerVisConfig(
      geojsonVisConfigs,
      props.style[props.type || this.type]
    )
  }

  get type() {
    return LAYER_TYPES.upGeojson
  }

  renderLayer = ({
    layerData,
    profileHandler,
    highlightedObjectIndex,
    selectedDateTimeRange,
  }) => {
    const {
      visConfig: {
        opacity,
        radius,
        radiusProperty,
        lineWidth,
        isFilled,
        isStroked,
        elevationScale,
        fillColourType,
        lineColour,
        lineColourType,
        lineWidthProperty,
        heightPropertyValue,
        enableClustering,
      },
      fillColour,
    } = this.config

    const [pointRadiusMinPixels, pointRadiusMaxPixels] =
      LAYER_VIS_CONFIGS.radius.pixelRange

    const isExtruded = elevationScale !== 0

    const basicProps = this.getLayerBasicProps({ layerData })

    const stylingProps = {
      pointRadiusMinPixels: radiusProperty ? pointRadiusMinPixels : radius,
      pointRadiusMaxPixels: radiusProperty ? pointRadiusMaxPixels : radius,
      opacity: this.getOpacity(opacity),
      stroked: isStroked,
      filled: isFilled,
      extruded: isExtruded,
      elevationScale,
      pointRadiusScale: radius,
      lineWidthScale: lineWidth,
      autoHighlight: true,
      ...this.getHighlightedObjectIndex(highlightedObjectIndex),
    }

    const profileProps = this.getProfileConfig(profileHandler)

    const isSimpleFillColour = this.isSimpleColourType(fillColourType)

    const isSimpleLineColour = this.isSimpleColourType(lineColourType)

    const dataAccessors = {
      getPointRadius: radiusProperty ? d => d.radius : radius,
      getLineWidth: lineWidthProperty ? d => d.lineWidth : lineWidth,
      getFillColor: isSimpleFillColour ? fillColour : d => d.fillColour,
      getLineColor: isSimpleLineColour ? lineColour : d => d.lineColour,
      getElevation: heightPropertyValue ? d => d.elevation : elevationScale,
    }

    const firstDataTime = this.getTimeFromFirstData(layerData)

    const props = {
      ...basicProps,
      ...stylingProps,
      ...profileProps,
      ...dataAccessors,
      ...this.getTimeFilterProps(selectedDateTimeRange, firstDataTime),
    }
    return [
      enableClustering
        ? getClusteringLayer(
            props,
            this.identityProperty,
            this.profileTitle,
            isSimpleFillColour,
            fillColour
          )
        : new GeoJsonLayer(props),
    ]
  }
}
