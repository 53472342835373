import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { BUTTON_VARIANTS } from 'components/common/Button'
import { Button, NumericInput } from 'components/common'
import { isRangeValid } from 'helpers/utils'

const MinMaxRange = ({
  minValue,
  maxValue,
  defaultMinValue,
  defaultMaxValue,
  onChange,
  label,
  className,
  isClearable,
}) => {
  const [min, setMin] = useState(minValue)
  const [max, setMax] = useState(maxValue)

  const handleRangeChange = useCallback(() => {
    onChange([undefined, undefined])
    setMin(undefined)
    setMax(undefined)
  }, [onChange])

  useEffect(() => {
    if ((min !== minValue || max !== maxValue) && isRangeValid([min, max])) {
      onChange([min, max])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [min, max])

  const sharedProps = {
    className: 'form-control',
    min: defaultMinValue,
    max: defaultMaxValue,
  }

  return (
    <div className={`${className} d-flex`}>
      <div className={isClearable ? 'col-sm-8' : 'col-sm-12'}>
        <div className='row g-3'>
          <div className='col-6'>
            {label && <div className='groupOptionLabel'>Min</div>}
            <NumericInput
              {...sharedProps}
              value={min}
              onChange={setMin}
              testId='min-value'
            />
          </div>
          <div className='col-6'>
            {label && <div className='groupOptionLabel'>Max</div>}
            <NumericInput
              {...sharedProps}
              value={max}
              onChange={setMax}
              testId='max-value'
            />
          </div>
        </div>
      </div>
      {isClearable && (!_.isNil(min) || !_.isNil(max)) && (
        <div className='col-sm-4 align-self-end text-end'>
          <Button variant={BUTTON_VARIANTS.link} onClick={handleRangeChange}>
            Clear
          </Button>
        </div>
      )}
    </div>
  )
}

MinMaxRange.propTypes = {
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  defaultMinValue: PropTypes.number,
  defaultMaxValue: PropTypes.number,
  label: PropTypes.bool,
  className: PropTypes.string,
  isClearable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

MinMaxRange.defaultProps = {
  minValue: null,
  maxValue: null,
  defaultMinValue: -Infinity,
  defaultMaxValue: Infinity,
  label: true,
  isClearable: true,
  className: 'row g-3',
}

export default MinMaxRange
