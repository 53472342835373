import { ReactElement } from 'react'

// components
import { Accordion } from 'components/common'

import type { AccordionProps } from '../Accordion/types'
import scss from './index.module.scss'

const AccordionTableStyled = ({
  content,
  ...props
}: AccordionProps): ReactElement => (
  <Accordion
    titleClassName={scss.accordionTitle}
    bodyClassName={scss.accordionBody}
    collapseDivClassName={scss.collapseDiv}
    chevronIconClassName={scss.chevronIcon}
    content={<div className={scss.accordionContent}>{content}</div>}
    chevronLeft={false}
    flush
    {...props}
  />
)

export default AccordionTableStyled
