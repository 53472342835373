// libraries
import { useMemo, useCallback, ReactElement } from 'react'
import _ from 'lodash'

// constants
import { NON_VISIBLE, IMAGE_GALLERY_TYPES } from 'constants/common'

// utils
import {
  useAssetWidgetConfigs,
  useBaseAssetWidgetPanel,
} from 'components/assets/assetsProfile/hooks'
import {
  getAssetProfilePropertiesList,
  getImagesProperties,
} from 'helpers/asset'

// components
import { PropertiesList, PropertyPicker, TypePicker } from 'components/common'
import {
  BsFillGrid1X2Fill,
  MdViewCarousel,
  MdViewColumn,
} from 'components/icons'

import type { Widget } from 'types/asset'

const PROPERTY_IDENTIFIER = 'name'

const IMAGE_GALLERY_TYPE_SPECS = [
  { name: 'Grid', Icon: BsFillGrid1X2Fill, type: IMAGE_GALLERY_TYPES.grid },
  {
    name: 'Carousel',
    Icon: MdViewCarousel,
    type: IMAGE_GALLERY_TYPES.slider,
  },
  {
    name: 'Single Line',
    Icon: MdViewColumn,
    type: IMAGE_GALLERY_TYPES.column,
  },
]

const AssetImageGalleryPanel = ({
  widget,
}: {
  widget: Widget
}): ReactElement => {
  const {
    renderAssetToggleAndRelatedMultiSelect,
    propertiesMetadata,
    propertyOptions,
  } = useBaseAssetWidgetPanel({ widget })

  const {
    settings: {
      properties: sortedPickedProperties,
      titleProperty,
      subtitleProperty,
      type = IMAGE_GALLERY_TYPES.grid,
    },
  } = widget

  const { updateWidgetConfigsSettings } = useAssetWidgetConfigs(widget)

  const onPropertiesListChange = useCallback(
    (items: { isVisible: boolean }[]) => {
      const filteredProperties = _(items)
        .reject(NON_VISIBLE)
        .map(PROPERTY_IDENTIFIER)
        .value()
      updateWidgetConfigsSettings({ properties: filteredProperties })
    },
    [updateWidgetConfigsSettings]
  )

  const imagesMetadata = useMemo(
    () => getImagesProperties({ propertiesMetadata }),
    [propertiesMetadata]
  )

  const propertiesList = useMemo(
    () =>
      getAssetProfilePropertiesList({
        propertiesMetadata: imagesMetadata,
        identifier: PROPERTY_IDENTIFIER,
        properties: sortedPickedProperties,
      }),
    [imagesMetadata, sortedPickedProperties]
  )

  return (
    <>
      <div className='groupOption'>
        <div className='groupOptionTitle'>Gallery Type</div>
        <TypePicker
          value={type}
          specs={IMAGE_GALLERY_TYPE_SPECS}
          onChange={val => {
            updateWidgetConfigsSettings({
              type: val,
            })
          }}
        />
      </div>
      {renderAssetToggleAndRelatedMultiSelect()}
      {_.isEmpty(propertiesList) ? (
        <span className='message'>
          Sorry, there is no image properties in this asset profile.
        </span>
      ) : (
        <div className='groupOption'>
          <div className='groupOptionTitle'>Title Property</div>
          <div className='groupOptionContent'>
            <PropertyPicker
              property={titleProperty}
              onChange={(val: string) => {
                updateWidgetConfigsSettings({
                  titleProperty: val,
                })
              }}
              propertyOptions={propertyOptions}
              isMulti={false}
              isClearable
              useOptionValueOnly
            />
          </div>
          <div className='groupOption'>
            <div className='groupOptionTitle'>Subtitle Property</div>
            <div className='groupOptionContent'>
              <PropertyPicker
                property={subtitleProperty}
                onChange={(val: string) => {
                  updateWidgetConfigsSettings({
                    subtitleProperty: val,
                  })
                }}
                propertyOptions={propertyOptions}
                isMulti={false}
                isClearable
                useOptionValueOnly
              />
            </div>
          </div>
          <div className='groupOption'>
            <PropertiesList
              title=''
              properties={propertiesList}
              onChange={onPropertiesListChange}
              identifier={PROPERTY_IDENTIFIER}
              searchIdentifier='displayName'
            />
          </div>
        </div>
      )}
    </>
  )
}

export default AssetImageGalleryPanel
