import { ReactElement, useRef, useState, useEffect } from 'react'
import { useToggle } from 'react-use'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { FieldProps } from '@rjsf/utils'

import type { JSONFormSchema } from 'types/formBuilder'

import Button, { BUTTON_SIZES, BUTTON_VARIANTS } from 'components/common/Button'

import scss from './index.module.scss'

const ShowMoreBtn = styled(Button)<{ isExpanded?: boolean }>`
  padding: 0;
  font-size: 11px;
  line-height: 18px;
  font-weight: 600;

  ${({ isExpanded }) =>
    isExpanded
      ? css`
          display: inline;
          position: static;
          padding-left: 8px;
        `
      : css`
          display: block;
          position: absolute;
          bottom: 0;
          right: 0;
          padding-left: 24px;
          background: linear-gradient(
            90deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(250, 250, 250, 1) 35%
          );
        `};
`

const TitleField = ({
  title,
  uiSchema,
  schema,
}: Partial<FieldProps<unknown, JSONFormSchema>>): ReactElement => {
  const descriptionRef = useRef<HTMLDivElement>(null)
  const [displayExpander, setDisplayExpander] = useState(false)
  const [isExpanded, toggleIsExpanded] = useToggle(false)

  const formTitle = (uiSchema && uiSchema['ui:title']) || title
  const { description } = schema || {}

  // Re-calculate on description change
  useEffect(() => {
    const { offsetHeight = 0, scrollHeight = 0 } = descriptionRef.current || {}
    const isOverflowing = !!description && scrollHeight > offsetHeight
    if (isOverflowing !== displayExpander) setDisplayExpander(isOverflowing)
  }, [description, displayExpander])

  return (
    <div className={`px-3 ${scss.header}`}>
      <h1 className={scss.title}>{formTitle}</h1>

      {!!description && (
        <div
          className={`${scss.description} ${
            isExpanded ? '' : scss.descriptionTruncate
          }`}
          ref={descriptionRef}
        >
          {description}
          {displayExpander && (
            <ShowMoreBtn
              variant={BUTTON_VARIANTS.link}
              size={BUTTON_SIZES.small}
              isExpanded={isExpanded}
              onClick={() => toggleIsExpanded()}
            >
              {isExpanded ? 'Less' : 'More'}
            </ShowMoreBtn>
          )}
        </div>
      )}
    </div>
  )
}

export default TitleField
