// libraries
import { useMemo, ReactElement } from 'react'
import { MdAccessTime } from 'react-icons/md'

// utils
import { displayTime } from 'helpers/utils'

// constants
import { getTimeFormatter } from 'constants/datetime'

import scss from '../../index.module.scss'

type Props = {
  selectedDateTimeRange: { start: string; end: string }
  intervalUnit: string
  timezone?: string
}

const TimeDisplay = ({
  selectedDateTimeRange,
  intervalUnit,
  timezone,
}: Props): ReactElement => {
  const { start, end } = selectedDateTimeRange
  const timeFormat = useMemo(
    () => getTimeFormatter(intervalUnit),
    [intervalUnit]
  )

  const startDisplayTime = useMemo(
    () =>
      displayTime({
        datetime: start,
        timezone,
        timeFormat,
      }),
    [start, timeFormat, timezone]
  )

  const endDisplayTime = useMemo(
    () =>
      displayTime({
        datetime: end,
        timezone,
        timeFormat,
      }),
    [end, timeFormat, timezone]
  )

  return (
    <div>
      <MdAccessTime style={{ color: 'white' }} />
      <span className={scss.dateTimeText}>
        <span className='ms-1'>{startDisplayTime}</span> -{' '}
        <span className='ms-1'>{endDisplayTime}</span>
      </span>
    </div>
  )
}

export default TimeDisplay
