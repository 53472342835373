// libraries
import { useState, useMemo, useCallback, ReactElement } from 'react'
import { SketchPicker, ColorResult } from 'react-color'

// utils
import { colourArrToRgbaObj, colourArrToRgbaBgStyle } from 'helpers/colour'

// constants
import { LAYER_VIS_CONFIGS } from 'components/map/layers/deckLayers/layerFactory'

import type { ColourArray } from 'types/common'

import './index.scss'

type ColourPickerSimpleProp = {
  colour?: ColourArray
  onChange: (newColour: ColourArray) => void
  className?: string
  popoverClassName?: string
  swatchClassName?: string
}

const ColourPickerSimple = ({
  colour = LAYER_VIS_CONFIGS.fillColour.defaultValue,
  onChange,
  className = '',
  popoverClassName = '',
  swatchClassName = '',
}: ColourPickerSimpleProp): ReactElement => {
  const [displayColourPicker, setDisplayColorPicker] = useState(false)

  const handleColourPickerClick = useCallback(() => {
    setDisplayColorPicker(!displayColourPicker)
  }, [displayColourPicker])

  const handleColourChange = useCallback(
    (c: ColorResult) => {
      const { r, g, b, a = 1 } = c.rgb
      onChange([r, g, b, a * 255])
    },
    [onChange]
  )

  const color = useMemo(() => colourArrToRgbaObj(colour), [colour])

  const colourSelected = useMemo(() => colourArrToRgbaBgStyle(colour), [colour])

  return (
    <div className={`colourPicker ${className}`}>
      <div
        className={`colourSwatch ${swatchClassName}`}
        onClick={handleColourPickerClick}
      >
        <div
          className='colourDisplay'
          data-testid='display-colour'
          style={colourSelected}
        />
      </div>
      {displayColourPicker ? (
        <div className={`colourPopover ${popoverClassName}`}>
          <div
            className='colourCover'
            onClick={() => setDisplayColorPicker(false)}
          />
          <SketchPicker color={color} onChangeComplete={handleColourChange} />
        </div>
      ) : null}
    </div>
  )
}

export default ColourPickerSimple
