// libraries
import {
  useState,
  useEffect,
  useMemo,
  useCallback,
  memo,
  ReactElement,
} from 'react'
import _ from 'lodash'
import update from 'immutability-helper'
import isEqual from 'fast-deep-equal'
import { useUpdateEffect } from 'react-use'

// constants
import { PROPERTY_VARIABLE_TYPES } from 'constants/filter'

// components
import {
  Toggle,
  TitleWithTooltip,
  NumericInput,
  TextInput,
  ColourPickerSimple,
  MultiSelect,
} from 'components/common'

// utils
import {
  isColourClassesValid,
  getColourClassifications,
  mapColourToRange,
  mapColourToCategory,
  colourArrToRgbaBgStyle,
} from 'helpers/colour'
import { isNumericType } from 'helpers/filter'

import type {
  ColourArray,
  ColourProperty,
  ColourClasses,
  Range,
  Options,
  Colours,
  CategoryColourClass,
  NumericColourClass,
  ColourClass,
} from 'types/common'

// style
import scss from './index.module.scss'

type ColourClassificationProp = {
  colours: Colours
  colourClasses: ColourClasses
  range?: Range
  onChange: (newColourClasses: ColourClasses) => void
  editable?: boolean
  colourProperty?: ColourProperty
  propertyOptions: Options
  expand?: boolean
}

const ColourClassification = ({
  colours,
  colourClasses,
  range,
  onChange,
  editable = true,
  propertyOptions,
  colourProperty = { type: PROPERTY_VARIABLE_TYPES.number },
  expand = true,
}: ColourClassificationProp): ReactElement => {
  const [colourClassesRange, setColourClassRange] = useState(range)

  const { type: colourPropertyType, key: colourPropertyKey } = colourProperty

  useEffect(() => {
    if (isEqual(range, colourClassesRange)) return
    setColourClassRange(range)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range])

  const [colourRamp, setColourRamp] = useState<Colours>(colours)
  useEffect(() => {
    if (isEqual(colourRamp, colours)) return
    setColourRamp(colours)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colours])

  const [isActive, setIsActive] = useState(expand)
  const isNumericProperty = useMemo(
    () => isNumericType(colourPropertyType),
    [colourPropertyType]
  )

  // classes(colourClasses) structure
  // [{ colour: [240, 249, 232], range:[ 0,2] },...,{ colour: [8, 104, 172], [8,10] }];
  // [{ colour: [240, 249, 232], category: 'firefighter'},...,{ colour: [8, 104, 172], category: 'policeman'}];
  const [classes, setClasses] = useState<ColourClasses>()

  const updateColourClassification = useCallback(
    (newClasses: ColourClasses) => {
      if (!isEqual(newClasses, classes)) {
        setClasses(newClasses)
        onChange(newClasses)
      }
    },
    [classes, onChange]
  )

  // save the classes information from input when blur
  const onInputBlur = useCallback(
    (val: string | number, index: number, key = '') => {
      if (!classes) return

      const newClasses = _.cloneDeep(classes)
      // If number, when user update classes
      // 1. make sure first input and last input are read only and will be the min&max as default
      // 2. make sure the previous max equals to next min
      // 3. make sure the next min equals to previous max
      // 4. make sure in the same line, min is less than max
      if (isNumericProperty) {
        let newNumber = Number(val)
        const lastIndex = colourRamp.length - 1
        if (key === 'max' && index < lastIndex) {
          const nextIndex = index + 1
          const min = newClasses[index].range[0]
          const max = newClasses[nextIndex].range[1]
          newNumber = _.clamp(newNumber, min, max)
          newClasses[nextIndex].range[0] = newNumber
          newClasses[index].range[1] = newNumber
        } else if (key === 'min' && index !== 0) {
          const previousIndex = index - 1
          const min = newClasses[previousIndex].range[0]
          const max = newClasses[index].range[1]
          newNumber = _.clamp(newNumber, min, max)
          newClasses[previousIndex].range[1] = newNumber
          newClasses[index].range[0] = newNumber
        }
      } else {
        newClasses[index].category = val
      }
      updateColourClassification(newClasses)
    },
    [classes, colourRamp, isNumericProperty, updateColourClassification]
  )

  const colourOverwrite = useCallback(
    (colour: ColourArray, i: number) => {
      if (!classes) return

      const newClasses = update(classes, { [i]: { $merge: { colour } } })
      updateColourClassification(newClasses)
    },
    [classes, updateColourClassification]
  )

  const renderNumberRow = useCallback(
    (row: NumericColourClass, index: number) => {
      const isMinInputDisabled = index === 0
      const isMaxInputDisabled = index === colourRamp.length - 1
      const { range: [min, max] = [] } = row
      return (
        <>
          <td>
            <NumericInput
              value={min}
              disabled={isMinInputDisabled}
              onChange={val => onInputBlur(val, index, 'min')}
              className='form-control'
              testId='classes-value'
            />
          </td>
          <td>
            <NumericInput
              value={max}
              disabled={isMaxInputDisabled}
              onChange={val => onInputBlur(val, index, 'max')}
              className='form-control'
              testId='classes-value'
            />
          </td>
        </>
      )
    },
    [colourRamp.length, onInputBlur]
  )

  const renderCategoryRow = useCallback(
    (
      row: CategoryColourClass,
      index: number,
      propertyOptionTermsOptions: Options
    ) => {
      const { category } = row
      const commonProps = {
        testId: 'classes-value',
        value: category,
        onChange: (value: string) => onInputBlur(value, index, 'category'),
      }

      return (
        <td>
          {_.isEmpty(propertyOptionTermsOptions) ? (
            <TextInput {...commonProps} className='form-control' />
          ) : (
            <MultiSelect
              {...commonProps}
              options={propertyOptionTermsOptions}
              isMulti={false}
              isClearable
              useOptionValueOnly
              placeholder='Select a property'
            />
          )}
        </td>
      )
    },
    [onInputBlur]
  )

  const ClassesTableRow = memo(
    ({
      row,
      index,
    }: {
      row: NumericColourClass | CategoryColourClass
      index: number
    }) => {
      // Disable the first min input and last max input in the class table
      // (when property is numeric) to make sure the numbers are consistent
      // with the min and max read from other components
      const { colour } = row
      const style = useMemo(() => colourArrToRgbaBgStyle(colour), [colour])

      const propertyOptionTerms = useMemo(
        () =>
          _.get(
            _.find(propertyOptions, { value: colourPropertyKey }),
            'terms'
          ) || [],
        []
      )

      const propertyOptionTermsOptions = useMemo(
        () =>
          _.map(propertyOptionTerms, ({ key: value, displayName }) => ({
            value,
            label: displayName || value,
          })),
        [propertyOptionTerms]
      )

      return (
        <tr>
          <td>
            {editable ? (
              <ColourPickerSimple
                className={scss.colourPicker}
                swatchClassName={scss.colourSwatch}
                colour={colour}
                onChange={value => colourOverwrite(value, index)}
              />
            ) : (
              <div className={`${scss.cursor} colourSwatch`}>
                <div
                  className={`${scss.cursor} colourDisplay`}
                  style={style}
                  data-testid='display-colour'
                />
              </div>
            )}
          </td>
          {isNumericProperty
            ? renderNumberRow(row as NumericColourClass, index)
            : renderCategoryRow(
                row as CategoryColourClass,
                index,
                propertyOptionTermsOptions
              )}
        </tr>
      )
    }
  )

  const renderColourClass = (colourClass: ColourClass, index: number) => (
    <ClassesTableRow
      key={`${colourClass.colour}-${index}`}
      row={colourClass}
      index={index}
    />
  )

  const ColourTable = memo(
    ({ colourClassesTable }: { colourClassesTable: ColourClasses }) => (
      <div className='groupOptionContent'>
        <table className={`${scss.table} table table-borderless table-sm`}>
          <thead>
            <tr>
              <th width='33.333%'>Colour</th>
              {isNumericProperty ? (
                <>
                  <th width='33.333%'>Min</th>
                  <th width='33.333%'>Max</th>
                </>
              ) : (
                <th>Category</th>
              )}
            </tr>
          </thead>
          <tbody>
            {colourClassesTable && colourClassesTable.map(renderColourClass)}
          </tbody>
        </table>
      </div>
    )
  )

  //
  /**
   * When user change range for numerical property,
   * re-calculate the colour classification
   */
  useUpdateEffect(() => {
    const onColourClassesRange = () => {
      if (isNumericProperty) {
        const newClasses = getColourClassifications(
          isNumericProperty,
          colourRamp,
          colourClassesRange
        )

        updateColourClassification(newClasses)
      }
    }

    onColourClassesRange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colourClassesRange, isNumericProperty])

  /**
   * When user change colour, update the colours
   * but keep the text for a better user experience
   */
  useUpdateEffect(() => {
    const onColourRampChange = () => {
      let newClasses
      if (isNumericProperty) {
        const hasValidClassesAndColoursLengthIsSame =
          isColourClassesValid(classes) && colourRamp.length === classes?.length
        if (hasValidClassesAndColoursLengthIsSame) {
          newClasses = mapColourToRange(colourRamp, classes)
        } else {
          const classesColours = _.map(colourClasses, 'colour')
          const hasValidExistingColourClasses =
            isEqual(classesColours, colourRamp) &&
            isColourClassesValid(colourClasses)

          newClasses = hasValidExistingColourClasses
            ? colourClasses
            : getColourClassifications(
                isNumericProperty,
                colourRamp,
                colourClassesRange
              )
        }
      } else {
        newClasses = mapColourToCategory(colourRamp, classes)
      }

      updateColourClassification(newClasses)
    }
    onColourRampChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colourRamp, isNumericProperty])

  // 1. if user have the saved colour classes information, use that information as initial state.
  // 2. if not (new layer), check whether that's for string property or numeric property
  // 2.1 if string, render empty text input for category name
  // 2.2 if number, auto calculate class classes
  useEffect(() => {
    const onColourClassesChange = () => {
      const newClasses = isNumericProperty
        ? isColourClassesValid(colourClasses)
          ? colourClasses
          : getColourClassifications(true, colourRamp, colourClassesRange)
        : _.isEmpty(colourClasses)
        ? getColourClassifications(false, colourRamp, colourClassesRange)
        : colourClasses

      updateColourClassification(newClasses)
    }

    onColourClassesChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colourClasses, isNumericProperty])

  const renderColourTable = useCallback(
    () => <ColourTable colourClassesTable={classes} />,
    [ColourTable, classes]
  )

  return (
    <div className='groupOption' data-testid='colour-classification'>
      <div className='groupOptionTitle d-flex justify-content-between align-items-center'>
        <TitleWithTooltip
          title='Colour Classification'
          tooltip='min ≤ range < max'
        />
        <div>
          <Toggle checked={isActive} onToggle={() => setIsActive(!isActive)} />
        </div>
      </div>
      {isActive && renderColourTable()}
    </div>
  )
}

export default ColourClassification
