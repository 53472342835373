// libraries
import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

// constants
import { MODAL_SIZES } from 'constants/settings'

// components
import { ModalFooter, Modal, MdPreviewer } from 'components/common'

// utils
import { confirmCurrentUserConsent } from 'services/api/user'
import { showError } from 'helpers/message'
import log, { reportException } from 'helpers/log'

import scss from './index.module.scss'

const EndUserConsentModal = ({
  userConsents,
  isShowing,
  hide,
  onDisagree,
  onAllConsentsConfirmed,
}) => {
  const [isConfirming, setIsConfirming] = useState(false)
  const [unConfirmedConsents, setUnConfirmedConsents] = useState(userConsents)

  const [currentConsent, setCurrentConsent] = useState(() =>
    _.first(unConfirmedConsents)
  )

  const onAgree = useCallback(async () => {
    const { subject } = currentConsent
    setIsConfirming(true)
    const { error, user } = await confirmCurrentUserConsent(subject)
    setIsConfirming(false)
    const confirmedMessage = `Confirmed the ${subject} consent`
    if (error || _.isNull(user)) {
      showError(`${confirmedMessage} failed`)
      return
    }
    log.info(confirmedMessage)

    const newConsents = _.drop(unConfirmedConsents)
    setUnConfirmedConsents(newConsents)
    if (_.isEmpty(newConsents)) {
      onAllConsentsConfirmed()
    } else {
      log.info(`${newConsents.length} consent(s) to be confirmed.`)
      setCurrentConsent(_.first(newConsents))
    }
  }, [currentConsent, onAllConsentsConfirmed, unConfirmedConsents])

  const onConsentLoadingError = useCallback(
    e => {
      const { contentUrl, subject } = currentConsent
      const errorMessage = `Loading consent-${subject}(${contentUrl}) failed: ${e.message}, will sign out `
      reportException(errorMessage)
      onDisagree()
    },
    [currentConsent, onDisagree]
  )

  return (
    <Modal
      key='endUserConsents'
      isShowing={isShowing}
      hide={hide}
      size={MODAL_SIZES.regular}
      scrollable
      closeable={false}
    >
      <div className={scss.content}>
        <MdPreviewer
          url={currentConsent.contentUrl}
          onError={onConsentLoadingError}
        />
      </div>
      <ModalFooter
        onCancel={() => {
          log.info(`Disagree the ${currentConsent.subject} consent`)
          setIsConfirming(true)
          onDisagree()
        }}
        cancelContent='Disagree'
        submitContent='Agree'
        onClick={onAgree}
        isLoading={isConfirming}
      />
    </Modal>
  )
}

EndUserConsentModal.propTypes = {
  userConsents: PropTypes.arrayOf(
    PropTypes.shape({
      subject: PropTypes.string.isRequired,
      contentUrl: PropTypes.string.isRequired,
    })
  ).isRequired,
  isShowing: PropTypes.bool,
  hide: PropTypes.func.isRequired,
  onDisagree: PropTypes.func.isRequired,
  onAllConsentsConfirmed: PropTypes.func.isRequired,
}

EndUserConsentModal.defaultProps = {
  isShowing: false,
}

export default EndUserConsentModal
