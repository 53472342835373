import _ from 'lodash'

// utils
import { showError } from 'helpers/message'
import { isValueInRange, isCountAggregation } from 'helpers/utils'
import {
  getAggregatedValue,
  assignPointsToPolygon,
} from 'components/map/layers/deckLayers/dataAggregation'
import { getColourFromClassificationByValue } from 'helpers/colour'
import log from 'helpers/log'
import { getLayerPolygonForAggregation } from 'helpers/map'
import { getDefaultLayerStyle } from 'helpers/layerStyle'

// constants
import { LAYER_VIS_CONFIGS } from 'components/map/layers/deckLayers/layerFactory'
import { PROPERTY_VARIABLE_TYPES } from 'constants/filter'

export const LAYER_DEFAULT_STYLE = {
  polygon: { assetPolygonId: undefined },
  // colour
  colourRange: LAYER_VIS_CONFIGS.colourRange.defaultValue,
  valueRangeForColour: [],
  aggregationForColour: LAYER_VIS_CONFIGS.aggregationForColour.defaultValue,
  // elevation
  elevationScale: LAYER_VIS_CONFIGS.elevationScale.defaultValue,
  valueRangeForHeight: [],
  aggregationForHeight: LAYER_VIS_CONFIGS.aggregationForHeight.defaultValue,
}

const colourPropertyValue = 'colorValue'
const heightPropertyValue = 'elevation'

export const aggregatePolygonValues = (
  polygons,
  aggregationForColour,
  aggregationForHeight
) => {
  const defaultNoDataValue = 0
  return _.reduce(
    polygons,
    (acc, polygon) => {
      if (polygon && _.get(polygon, 'type')) {
        const { features } = polygon
        if (_.isEmpty(features)) {
          if (isCountAggregation(aggregationForColour.type)) {
            polygon[colourPropertyValue] = defaultNoDataValue
          }

          if (isCountAggregation(aggregationForHeight.type)) {
            polygon[heightPropertyValue] = defaultNoDataValue
          }
        } else {
          polygon[colourPropertyValue] = getAggregatedValue(
            features,
            aggregationForColour
          )
          polygon[heightPropertyValue] = getAggregatedValue(
            features,
            aggregationForHeight
          )
        }
      }

      return _.isNumber(polygon[colourPropertyValue]) ||
        _.isNumber(polygon[heightPropertyValue])
        ? [...acc, polygon]
        : acc
    },
    []
  )
}

const getValidPolygons = (
  aggregatedPolygons,
  classification,
  valueRangeForHeight
) => {
  const validPolygons = _.reduce(
    aggregatedPolygons,
    (acc, polygon) => {
      const { [colourPropertyValue]: colorValue, elevation } = polygon

      const fillColour = getColourFromClassificationByValue(
        colorValue,
        classification,
        PROPERTY_VARIABLE_TYPES.number
      )

      if (fillColour && isValueInRange(elevation, valueRangeForHeight)) {
        acc.push({
          ...polygon,
          fillColour,
          colorValue,
          elevation,
        })
      }

      return acc
    },
    []
  )

  return validPolygons
}

export const getPolygonLayerData = ({ layer, filteredDataState }) => {
  const {
    applySpatialFilter,
    filteredDataWithoutTimeFilter,
    dataClassification,
  } = filteredDataState

  if (!filteredDataWithoutTimeFilter) return []

  const { type, style } = layer
  const { aggregationForColour, valueRangeForHeight, aggregationForHeight } =
    getDefaultLayerStyle(style[type], LAYER_DEFAULT_STYLE)

  let data
  try {
    const polygonsWithBbox = getLayerPolygonForAggregation(layer)

    if (_.isEmpty(polygonsWithBbox)) {
      return []
    }

    // eslint-disable-next-line no-console
    console.time(
      `Processing: polygon aggregation with correct colours ${filteredDataWithoutTimeFilter.length} points`
    )

    const aggregatedPolygons = assignPointsToPolygon(
      polygonsWithBbox,
      applySpatialFilter
    )

    const polygonsWithAggregatedValues = aggregatePolygonValues(
      aggregatedPolygons,
      aggregationForColour,
      aggregationForHeight
    )

    data = getValidPolygons(
      polygonsWithAggregatedValues,
      dataClassification,
      valueRangeForHeight
    )

    // eslint-disable-next-line no-console
    console.timeEnd(
      `Processing: polygon aggregation with correct colours ${filteredDataWithoutTimeFilter.length} points`
    )

    log.info(
      `Processing is finished, displaying ${data.length} polygons on the map`
    )

    return data
  } catch (error) {
    const msg = 'Error occurred while processing polygon aggregation'
    showError(msg)
    log.error(msg, error)
    return []
  }
}
