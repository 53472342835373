import { ReactElement, useCallback } from 'react'
import type { WidgetProps } from '@rjsf/utils'
import { FFmpeg } from '@ffmpeg/ffmpeg'
import { toBlobURL } from '@ffmpeg/util'
import { useAsync } from 'react-use'

// components
import IssueVideoList from 'components/issue/common/IssueVideoList'
import WidgetWrapper from 'components/common/JsonForm/WidgetWrapper'
import { FileUploader } from 'components/common'

// utils
import { useStateValue } from 'contexts'

// constants
import { FILE_MIME_TYPE_EXTENSIONS_MAPPING } from 'constants/common'
import { VIDEO_FILE_SIZE_LIMIT } from 'constants/fileUpload'

const VideoWidget = ({
  name,
  schema,
  value = [],
  readonly,
  required,
  formContext,
  rawErrors,
  onChange,
  disabled,
}: WidgetProps): ReactElement => {
  const { title, maxItems = 1 } = schema || {}
  const {
    videoResources,
    isPreview,
    fileUploadingState,
    setMediaToUploadByField,
  } = formContext || {}

  const {
    state: {
      uiState: { ffmpegInstance: existingFfmpegInstance },
    },
    actions: {
      uiActions: { setFfmpegInstance },
    },
  } = useStateValue()

  useAsync(async () => {
    if (!existingFfmpegInstance) {
      const ffmpegInstance = new FFmpeg()

      ffmpegInstance.on('log', ({ message }) => {
        // eslint-disable-next-line no-console
        console.log(`ffmpeg: ${message}`)
      })

      await ffmpegInstance.load({
        coreURL: await toBlobURL(
          '/assets/scripts/ffmpeg.wasm.core@0.12.6/ffmpeg-core.js',
          'text/javascript'
        ),
        wasmURL: await toBlobURL(
          '/assets/scripts/ffmpeg.wasm.core@0.12.6/ffmpeg-core.wasm',
          'application/wasm'
        ),
      })

      setFfmpegInstance(ffmpegInstance)
    }
  }, [])

  const onUpload = useCallback(
    (files: File[]) => {
      setMediaToUploadByField?.(state => ({ ...state, [name]: files }))
    },
    [name, setMediaToUploadByField]
  )

  const toggleDelete = useCallback(
    (mediaKey: string, isDeleted: boolean) =>
      onChange(
        value.map(item => {
          if (item.mediaKey === mediaKey) return { ...item, isDeleted }
          return item
        })
      ),
    [value, onChange]
  )

  return (
    <WidgetWrapper
      name={name}
      label={title}
      required={required}
      rawErrors={rawErrors}
      isLarge={isPreview}
    >
      <IssueVideoList
        videoResources={videoResources}
        fieldValue={value}
        itemsPerRow={2}
        toggleDelete={toggleDelete}
      />

      <FileUploader
        disabled={disabled || readonly}
        accept={FILE_MIME_TYPE_EXTENSIONS_MAPPING.video}
        placeholderText='Drop videos here'
        isSingleFile={false}
        onChange={onUpload}
        fileUploadingState={fileUploadingState}
        maxFiles={maxItems}
        // ! Apr 22, 2024 – since we're transcoding videos on a client,
        // setting a size limit
        maxSize={VIDEO_FILE_SIZE_LIMIT}
        displayClickHereToUpload
      />
    </WidgetWrapper>
  )
}

export default VideoWidget
