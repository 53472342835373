import { RefObject, useRef } from 'react'
import _ from 'lodash'
import { useToggle, useClickAway, useMount } from 'react-use'

import type { ToggleFn } from 'types/common'

const useFiltersDropdown = (
  initialValue: unknown,
  initialIsDropdownOpen?: boolean
): {
  isOpen: boolean
  toggleOpen: ToggleFn
  ref: RefObject<HTMLDivElement>
} => {
  const [isOpen, toggleOpen] = useToggle(false)
  const ref = useRef<HTMLDivElement>(null)

  useClickAway(ref, () => {
    if (isOpen) {
      toggleOpen(false)
    }
  })

  useMount(() => {
    if (
      _.isNil(initialValue) ||
      (_.isObject(initialValue) && _.isEmpty(initialValue))
    ) {
      if (initialIsDropdownOpen === undefined) {
        toggleOpen(true)
      } else {
        toggleOpen(initialIsDropdownOpen)
      }
    }
  })
  return { isOpen, toggleOpen, ref }
}

export default useFiltersDropdown
