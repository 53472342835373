import { ReactElement, useMemo } from 'react'
import _ from 'lodash'

// constants
import { PROPERTY_TABLE_COLUMNS } from 'constants/assets'
import { WIDGET_TYPES } from 'constants/widget'
import { NO_DATA_PLACEHOLDER } from 'constants/common'

// components
import { NoAvailableWidget } from 'components/widget'
import Table from 'components/assets/assetsProfile/widgets/common/Table'

import type { JSONFormBody } from 'types/issue'

import { SinglePageTableProps, renderPropertiesItem } from '.'

const PropertiesTable = ({
  properties,
  propertiesMetadata,
  visibleProperties,
  timezone,
  hideInvalidValues,
  jsonFormBody,
  tableStyle,
  theme,
}: Pick<
  SinglePageTableProps,
  | 'properties'
  | 'propertiesMetadata'
  | 'visibleProperties'
  | 'timezone'
  | 'hideInvalidValues'
  | 'tableStyle'
  | 'theme'
> & { jsonFormBody?: JSONFormBody }): ReactElement => {
  const data = useMemo(
    () =>
      renderPropertiesItem({
        properties,
        timezone,
        propertiesMetadata,
        visibleProperties,
        hideInvalidValues,
        jsonFormBody,
      }),
    [
      hideInvalidValues,
      properties,
      propertiesMetadata,
      timezone,
      visibleProperties,
      jsonFormBody,
    ]
  )

  return _.isEmpty(data) ? (
    <NoAvailableWidget
      widgetType={WIDGET_TYPES.table}
      content={NO_DATA_PLACEHOLDER}
    />
  ) : (
    <Table
      data={data}
      columns={PROPERTY_TABLE_COLUMNS}
      theme={theme}
      withHeader={false}
      tableStyle={tableStyle}
    />
  )
}

export default PropertiesTable
