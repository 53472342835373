import { useMemo, ReactElement } from 'react'
import _ from 'lodash'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

// constants
import { THEMES } from 'constants/colour'
import { DEFAULT_ASSET_WIDGET_DIVIDER_STYLE } from 'constants/assets'

// utils
import { colourArrToRgbaStr } from 'helpers/colour'

import type { AssetBaseWidget } from 'types/asset'
import type { DividerWidgetSettings } from '../Panel'

const StyledHr = styled.hr<{
  borderWidth: number
  borderColor: string
  opacity?: number
  isPageBreak?: boolean
}>`
  border: ${({ borderWidth, borderColor }) =>
    `${borderWidth}px solid ${borderColor}`};
  width: 100%;
  margin: 0;
  opacity: ${({ opacity }) => opacity ?? 1};

  ${({ isPageBreak }) =>
    isPageBreak
      ? css`
          page-break-after: always;
        `
      : ''};
`

const AssetTextWidget = ({
  settings,
  theme,
}: AssetBaseWidget & {
  settings: DividerWidgetSettings
}): ReactElement => {
  const isLightTheme = useMemo(() => theme === THEMES.light, [theme])
  const { colour, height, opacity, isPageBreak } = settings

  const color = useMemo(() => {
    if (_.isEmpty(colour)) return isLightTheme ? 'black' : 'white'

    return colourArrToRgbaStr(colour)
  }, [colour, isLightTheme])

  return (
    <div className='d-flex align-items-center justify-content-center h-100'>
      <StyledHr
        borderWidth={height ?? DEFAULT_ASSET_WIDGET_DIVIDER_STYLE.height}
        borderColor={color}
        opacity={opacity}
        isPageBreak={isPageBreak}
      />
    </div>
  )
}

AssetTextWidget.defaultProps = {
  theme: THEMES.dark,
}

export default AssetTextWidget
