import { ReactElement, MouseEvent, KeyboardEvent, FocusEvent } from 'react'
import { ADDITIONAL_PROPERTY_FLAG } from '@rjsf/utils'
import { JSONSchema7 } from 'json-schema'
import { IconButton } from 'components/common'

import { getWidgetInputDefaultClassNames } from 'helpers/formBuilder'

import WidgetWrapper from 'components/common/JsonForm/WidgetWrapper'

type WrapIfAdditionalProps = {
  children: React.ReactElement
  classNames: string
  disabled: boolean
  id: string
  label: string
  onDropPropertyClick: (index: string) => (event?: MouseEvent) => void
  onKeyChange: (index: string) => (event?: KeyboardEvent) => void
  readonly: boolean
  required: boolean
  schema: JSONSchema7
}

const WrapIfAdditional = ({
  children,
  disabled,
  id,
  label,
  onDropPropertyClick,
  onKeyChange,
  readonly,
  required,
  schema,
}: WrapIfAdditionalProps): ReactElement => {
  const keyLabel = `${label} Key`
  const additional = Object.prototype.hasOwnProperty.call(
    schema,
    ADDITIONAL_PROPERTY_FLAG
  )

  const btnStyle = {
    flex: 1,
    paddingLeft: 6,
    paddingRight: 6,
    fontWeight: 'bold',
  }

  if (!additional) {
    return <>{children}</>
  }

  const handleBlur = ({ target }: FocusEvent<HTMLInputElement>) =>
    onKeyChange(target.value)

  return (
    <div className='row align-items-center gx-4' key={`${id}-key`}>
      <div className='col-5'>
        <WidgetWrapper label={keyLabel} required={required}>
          <input
            className={getWidgetInputDefaultClassNames()}
            defaultValue={label}
            disabled={disabled || readonly}
            id={`${id}-key`}
            name={`${id}-key`}
            onBlur={!readonly ? handleBlur : undefined}
            type='text'
          />
        </WidgetWrapper>
      </div>
      <div className='col-5'>{children}</div>
      <div className='col-2 align-items-center'>
        <IconButton
          icon='MdClose'
          style={btnStyle}
          disabled={disabled || readonly}
          onClick={onDropPropertyClick(label)}
        />
      </div>
    </div>
  )
}

export default WrapIfAdditional
