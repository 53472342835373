import { ReactElement, useMemo } from 'react'
import { useUpdateEffect } from 'react-use'
import { Field, useField } from 'react-final-form'

// components
import * as FormComponents from 'components/common/Form'

// utils
import {
  getEnumOptions,
  getValueWhenSwitchingBetweenSingleSelectAndMultiSelect,
  getFieldName,
} from 'helpers/formBuilder'

const DefaultDropdown = ({
  nodeId,
  disabled,
  isMulti,
  ...rest
}: {
  isMulti?: boolean
  disabled: boolean
  nodeId: string
}): ReactElement => {
  const isMultiValue = useField(getFieldName(nodeId, 'isMulti')).input.value
  const select = useField(getFieldName(nodeId, 'select')).input.value
  const defaultInput = useField(getFieldName(nodeId, 'default')).input

  useUpdateEffect(() => {
    defaultInput.onChange(
      getValueWhenSwitchingBetweenSingleSelectAndMultiSelect(
        defaultInput.value,
        isMultiValue
      )
    )
  }, [isMultiValue])

  const options = useMemo(() => getEnumOptions(select), [select])

  return (
    <Field
      {...rest}
      isDisabled={disabled}
      component={FormComponents.FormSelect}
      options={options}
      useOptionValueOnly
      isMulti={isMulti || isMultiValue}
      withBorder
      removeInvalidValues
    />
  )
}

export default DefaultDropdown
