import { ReactElement } from 'react'
import { OptionProps } from 'react-select'
import _ from 'lodash'

import type { MultiSelectProp } from 'components/common/MultiSelect'

import scss from './index.module.scss'

const FilterCustomOption =
  (formatOptionLabel: MultiSelectProp['formatOptionLabel']) =>
  (props: OptionProps): ReactElement => {
    const {
      data,
      getStyles,
      label,
      isSelected,
      innerRef,
      innerProps,
      isMulti,
    } = props

    return (
      <div
        ref={innerRef}
        style={getStyles('option', props)}
        className='d-flex align-items-center'
        {...innerProps}
      >
        {isMulti && (
          <input type='checkbox' checked={isSelected} onChange={_.noop} />
        )}
        <div className={scss.label}>
          {formatOptionLabel ? formatOptionLabel(data) : label}
        </div>
      </div>
    )
  }

export default FilterCustomOption
