// @flow

// libraries
import React, { useCallback } from 'react'
import { MdPlayArrow, MdPause, MdReplay } from 'react-icons/md'
import moment from 'moment-timezone'

// utils
import {
  getFutureUtcDate,
  getValidDatetimeUnit,
  getDurationAsIntervalUnit,
} from 'helpers/datetime'
import { focusBackgroundItem } from 'components/common/Timeline/HistoricalTimeline/utils'

// scss
import scss from '../../index.module.scss'

type Props = {
  isReplaying: boolean,
  toggleReplay: (isReplaying: boolean) => void,
  dataTimeRange: { start: string, end: string },
  selectedDateTimeRange: { start: string, end: string },
  intervalNumber: number,
  intervalUnit: string,
  updateTimelineItem: ({
    start: string,
    end: string,
    backgroundDataTimeRange: { start: string, end: string },
    hasData: boolean,
    shouldFocusSelectedRange: boolean,
  }) => void,
  instance: Object,
}

const ReplayStatusSetting = ({
  isReplaying,
  toggleReplay,
  dataTimeRange,
  intervalNumber,
  intervalUnit,
  updateTimelineItem,
  selectedDateTimeRange,
  instance,
}: Props) => {
  const handleReplayReset = useCallback(() => {
    const validUnit = getValidDatetimeUnit(intervalUnit)
    const validInterval =
      intervalNumber ||
      getDurationAsIntervalUnit(
        selectedDateTimeRange.start,
        selectedDateTimeRange.end,
        validUnit
      )
    const start = moment.utc(dataTimeRange.start)
    const end = getFutureUtcDate({
      interval: validInterval,
      intervalUnit: validUnit,
      baseDatetime: start,
    }).toISOString()

    toggleReplay(false)
    updateTimelineItem({ start, end, backgroundDataTimeRange: dataTimeRange })
    focusBackgroundItem(instance)
  }, [
    dataTimeRange,
    instance,
    intervalNumber,
    intervalUnit,
    selectedDateTimeRange,
    toggleReplay,
    updateTimelineItem,
  ])

  return (
    <div className={scss.replayButtons}>
      <button
        className={scss.svgButton}
        onClick={() => toggleReplay(!isReplaying)}
        type='button'
      >
        {isReplaying ? <MdPause /> : <MdPlayArrow />}
      </button>
      <button
        className={scss.svgButton}
        onClick={handleReplayReset}
        type='button'
      >
        <MdReplay />
      </button>
    </div>
  )
}

export default ReplayStatusSetting
