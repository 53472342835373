// libraries
import { ReactElement } from 'react'
import _ from 'lodash'
import { ColumnBodyOptions } from 'primereact/column'

// components
import IssueSeverityPicker from 'components/issue/IssueItemDetail/IssueSeverityPicker'
import { useCurrentUser } from 'hooks'

const Template = ({
  rowData,
  options: { field },
}: {
  rowData: unknown
  options: ColumnBodyOptions
}): ReactElement => {
  const { issueSeverityOptions } = useCurrentUser()
  const readOnly = true
  const severity = _.get(rowData, field)
  return (
    <IssueSeverityPicker
      bgColour='transparent'
      value={severity}
      options={issueSeverityOptions}
      readOnly={readOnly}
    />
  )
}

const SeverityPickerTemplate = (
  rowData: unknown,
  options: ColumnBodyOptions
): ReactElement => {
  return <Template rowData={rowData} options={options} />
}

export default SeverityPickerTemplate
