import _ from 'lodash'
import { useMemo, useState } from 'react'

import type { ListConditions } from 'types/common'

import { useAuthStateValue } from 'contexts'

/** Get configuration preserved in the user record and create a state */
const useListConditions = ({
  defaultListConditions,
  entity,
  transformReceivedSettings,
}: {
  defaultListConditions: ListConditions
  entity?: string
  transformReceivedSettings?: (
    settings: Record<string, unknown>
  ) => Record<string, unknown>
}): [ListConditions, React.Dispatch<React.SetStateAction<ListConditions>>] => {
  const { currentUser } = useAuthStateValue()
  const { galleryOptions } = currentUser.preferences

  const transformedSettings = useMemo(() => {
    if (!entity) return {}
    const settings = _.get(galleryOptions, entity) || {}
    return transformReceivedSettings
      ? transformReceivedSettings(settings)
      : settings
  }, [entity, galleryOptions, transformReceivedSettings])

  return useState({
    ...defaultListConditions,
    ...transformedSettings,
  })
}

export default useListConditions
