import { ReactElement } from 'react'

// constants
import { MAP_STYLE_SIZES } from 'constants/common'

// component
import { MapStyleControl } from 'components/common'
import MapStylePicker from './MapStylePicker'

const MapBaseMapStyleSwitcher = ({
  className,
}: {
  className: string
}): ReactElement => {
  return (
    <MapStyleControl className={className}>
      <MapStylePicker isGrid={false} size={MAP_STYLE_SIZES.small} />
    </MapStyleControl>
  )
}

MapBaseMapStyleSwitcher.defaultProps = {
  className: '',
}

export default MapBaseMapStyleSwitcher
