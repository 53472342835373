import { ReactElement } from 'react'
import { FieldRenderProps } from 'react-final-form'
import _ from 'lodash'

// types
import type {
  DateTimeType,
  DateTimeValue,
} from 'components/common/DateTime/DateTimePicker'
import type { FieldWrapperProps } from 'components/common/Form/FieldWrapper'

// utils
import { useTimezone } from 'hooks'

// components
import { StyledField } from 'components/common/Form'
import { DateTimePicker } from 'components/common/DateTime'

import scss from './index.module.scss'

type FormDatetimePickerProps = FieldRenderProps<string, HTMLElement> & {
  minDate?: string
  maxDate?: string
  isDisabled?: boolean
  showTimeSelect?: boolean
  groupOptionStyle?: boolean
  timeFormat?: string
  placeholder?: string
  isClearable?: string
  useValueOnly?: boolean
  selectedTimeType?: DateTimeType
  inputClassName?: string
  input: {
    onChange: (v: string & DateTimeValue) => void
    value: string & DateTimeValue
    name: string
  }
} & Pick<
    FieldWrapperProps,
    | 'label'
    | 'description'
    | 'style'
    | 'containerClassName'
    | 'preserveSpaceForError'
    | 'onChecked'
    | 'checkable'
    | 'required'
  >

const FormDatetimePicker = ({
  label,
  style,
  input,
  onChecked,
  description,
  required = false,
  checkable = false,
  groupOptionStyle = false,
  useValueOnly = true,
  selectedTimeType,
  inputClassName = '',
  containerClassName,
  preserveSpaceForError,
  ...rest
}: FormDatetimePickerProps): ReactElement => {
  const { onChange, value, name } = input

  const { timezone } = useTimezone()

  return (
    <StyledField
      name={name}
      groupOptionStyle={groupOptionStyle}
      label={label}
      required={required}
      description={description}
      style={style}
      containerClassName={containerClassName}
      preserveSpaceForError={preserveSpaceForError}
      checkable={checkable}
      onChecked={onChecked}
    >
      <DateTimePicker
        {...rest}
        selectedTime={useValueOnly && !_.isObject(value) ? value : value.value}
        selectedTimeType={
          useValueOnly && !_.isObject(value) ? selectedTimeType : value.type
        }
        timezone={timezone}
        onChange={props => {
          onChange(useValueOnly ? props.value : props)
        }}
        inputClassName={`form-control ${scss.input} d-flex justify-content-between align-items-center ${inputClassName}`}
      />
    </StyledField>
  )
}

FormDatetimePicker.defaultProps = {
  required: false,
  checkable: false,
  isDisabled: false,
  showTimeSelect: false,
  groupOptionStyle: false,
}

export default FormDatetimePicker
