// libraries
import _ from 'lodash'
import update from 'immutability-helper'
import { Timeline } from 'vis-timeline/peer'
import { DataSet } from 'vis-data/peer'

// constants
import { PROPERTY_TIME } from 'constants/common'
import { LAYER_TYPES } from 'constants/map'
import { COLOUR_TYPES } from 'constants/colour'
import { PROPERTY_VARIABLE_TYPES } from 'constants/filter'

// utils
import { getLayerData } from 'components/map/hooks/useMapData'
import { stringToRGBColour } from 'helpers/colour'
import {
  updateTimelineOptions,
  getTimelineMomentOption,
} from 'components/common/Timeline/HistoricalTimeline/utils'
import { showInfo } from 'helpers/message'
import log from 'helpers/log'
import { switchcase } from 'helpers/utils'
import { getDeckLayerClass } from 'components/map/layers/deckLayers'
import { isTimeBetween } from 'helpers/filter'

import type { Payload } from 'types/common'

const TIMELINE_ITEMS_MAXIMUMS = 1000

export const getTooltip =
  (identityProperty = 'name') =>
  ({ object }) => {
    if (!object) return undefined

    return `${_.escape(
      _.get(object, ['properties', identityProperty])
    )}\n${_.map(
      object.properties,
      (value, key) =>
        `${_.escape(key)}:  ${_.escape(
          _.isNil(value)
            ? '(none)'
            : _.isNumber(value)
            ? _.round(value, 3)
            : _.truncate(value, { length: 50 })
        )}\n`
    ).join('')}`
  }

const getJourneyDeckLayerSpecification = (layer, data, identityProperty) => {
  const filteredDataState = { filteredDataWithoutTimeFilter: data }
  const layerData = getLayerData({ layer, filteredDataState, identityProperty })
  const newLayer = getDeckLayerClass({ mapLayer: layer })
  return newLayer.renderLayer({
    layerData,
  })
}

export const getUpdateLayerStyle = (layer = {}, payload: Payload) => {
  const { type } = layer
  if (!type) return layer

  return update(layer, {
    style: {
      [type]: {
        $merge: payload,
      },
    },
  })
}

// TODO: refactor this function by using the CrossFilter
export const sortFilteredDataBetween = (
  data,
  dateTimeRange,
  maximumItems = TIMELINE_ITEMS_MAXIMUMS
) => {
  const { start, end } = dateTimeRange
  return _(data)
    .sortBy(PROPERTY_TIME)
    .filter(d => {
      const time = _.get(d, PROPERTY_TIME)
      return isTimeBetween(time, start, end)
    })
    .slice(0, maximumItems)
    .value()
}

export const hasVisTimelineHints = datasetMetadata =>
  _.has(datasetMetadata, 'hints.visTimeline')

export const getVisTimelineGroups = datasetMetadata =>
  _.get(datasetMetadata, 'hints.visTimeline.groups')

const getVisTimelineGroupsSettings = visTimelineGroups => {
  return _.map(visTimelineGroups, ({ code, content }, index) => ({
    id: index + 1,
    code,
    content,
    colour: stringToRGBColour(code || content),
  }))
}

export const getGroupSettings = datasetMetadata => {
  const visTimelineGroups = getVisTimelineGroups(datasetMetadata)
  return visTimelineGroups
    ? getVisTimelineGroupsSettings(visTimelineGroups)
    : [
        {
          id: 0,
          code: 'default',
          content: '',
          colour: stringToRGBColour('default'),
        },
      ]
}

const getJourneyLayerAdvancedColourStyleKeys = type => {
  return switchcase({
    [LAYER_TYPES.upGeojson]: {
      colourClassesKey: 'fillColourClasses',
      colourPropertyKey: 'fillColourProperty',
      fillColourTypeKey: 'fillColourType',
    },
  })({
    colourClassesKey: 'colourClasses',
    colourPropertyKey: 'colourProperty',
    fillColourTypeKey: 'colourType',
  })(type)
}

export const getJournalLayerAdvancedColourStyle = ({
  layer,
  groupsData,
  hasStyleHints,
  hasVisTimelineGroups,
}) => {
  if (!hasVisTimelineGroups) return {}
  const { type } = layer
  const fillColourClasses = _.map(groupsData, ({ colour, code }) => {
    return { colour, category: code }
  })

  const { colourClassesKey, colourPropertyKey, fillColourTypeKey } =
    getJourneyLayerAdvancedColourStyleKeys(type)

  return {
    [colourClassesKey]: hasStyleHints ? null : fillColourClasses,
    [colourPropertyKey]: hasStyleHints
      ? {}
      : {
          key: 'hints.visTimelineItem.groupCode' || 'name',
          type: PROPERTY_VARIABLE_TYPES.string,
        },
    [fillColourTypeKey]: COLOUR_TYPES.advanced,
  }
}

export const getJourneyDeckLayer = (layer, style, data, identityProperty) => {
  const featuresLayer = getUpdateLayerStyle(layer, style)
  return getJourneyDeckLayerSpecification(featuresLayer, data, identityProperty)
}

export const getDefaultTimeline = (timelineRef, timezone) => {
  const timelineData = []
  const timelineOptions = {
    height: '100%',
    showCurrentTime: false,
    moment: getTimelineMomentOption(timezone),
  }
  return new Timeline(timelineRef.current, timelineData, timelineOptions)
}

export const getTimelineIdLookup = data => {
  let timelineId = 0
  return _.reduce(
    data,
    (acc, row) => {
      timelineId += 1
      row.properties._timelineId = timelineId
      return { ...acc, [row.properties._timelineId]: row }
    },
    {}
  )
}

export const updateJourneyTimelineOptions = (
  timelineInstance,
  groupsData,
  sortedFilteredData
) => {
  const timelineGroups = new DataSet()
  timelineGroups.clear()
  timelineGroups.add(groupsData)
  timelineInstance.setGroups(timelineGroups)

  const options = {
    start: (first =>
      _.defaultTo(first.properties.startTime, first.properties.time))(
      _.first(sortedFilteredData)
    ),
    end: (last => _.defaultTo(last.properties.endTime, last.properties.time))(
      _.last(sortedFilteredData)
    ),
  }
  updateTimelineOptions(timelineInstance, options)
}

const WIDTH = 960 / 4
const HEIGHT = 480 / 4
const IMAGE_NUMBER = 1

export const updateJourneyTimelineItems = (
  timelineInstance,
  groupsData,
  sortedFilteredData,
  imagesProperties
) => {
  const groupsLookup = groupsData.reduce((acc, group) => {
    acc[group.code] = group.id
    return acc
  }, {})

  const getTimelineItemContent = row => {
    const label = _.defaultTo(row.properties.label, row.properties.name)
    const images = _(imagesProperties)
      .map(property => {
        const value = row.properties[property.key]
        return (
          value &&
          `<div><img src="${value}" width=${WIDTH} height=${HEIGHT}></div>`
        )
      })
      .compact()
      .value()
    const imageContent = _.take(images, IMAGE_NUMBER).join('')
    const moreImages =
      images.length > 1
        ? `<div>and ${images.length - IMAGE_NUMBER} more images</div>`
        : ''

    return `<div>${label}</div>${imageContent}${moreImages}`
  }

  const timelineItems = new DataSet()
  const timelineData = _.map(sortedFilteredData, row => {
    const content = getTimelineItemContent(row)
    return {
      id: row.properties._timelineId,
      group:
        groupsLookup[
          _.get(row, 'properties.hints.visTimelineItem.groupCode', 'default')
        ],
      content,
      start: _.defaultTo(row.properties.startTime, row.properties.time),
      end: row.properties.endTime,
    }
  })
  timelineItems.add(timelineData)
  timelineInstance.setItems(timelineItems)
}

export const checkJourneyDataLength = data => {
  if (data.length > TIMELINE_ITEMS_MAXIMUMS) {
    const message = `Data might be incomplete because data exceeds maximum of
      ${TIMELINE_ITEMS_MAXIMUMS} rows`
    showInfo(message)
    log.info(message)
  }
}
