// libraries
import _ from 'lodash'

// constants
import {
  DEFAULT_AGGREGATION_KEY_TYPE,
  AGGREGATION_TYPES,
} from 'constants/aggregation'
import { DATE_UNIT_TYPES } from 'constants/datetime'

// utils
import {
  DEFAULT_HIDE_VALUES_OUTSIDE_OF_VALUE_RANGE,
  DEFAULT_REALTIME_RANGE,
  LINE_CHART_TYPES,
  DEFAULT_WIDGET_COLOUR,
} from 'constants/widget'
import {
  getMemoizedWidgetColour,
  getSeriesFeaturesDataForEcharts,
  getSeriesGroupDataForEcharts,
} from 'helpers/widget'
import log from 'helpers/log'

const renderWidgetFeaturesLine = async ({
  style,
  widgetData,
  timezone,
  xAxisPropertyPath,
  selectedDateTimeRange,
}) => {
  const {
    yAxisPropertyName,
    yAxisRange,
    colour = DEFAULT_WIDGET_COLOUR,
    isSingleColour = true,
    isXAxisTimeRangeEnabled,
    xAxisIntervalRange = DEFAULT_REALTIME_RANGE,
    xAxisIntervalUnit = DATE_UNIT_TYPES.minutes,
    hideValuesOutsideOfValueRange = DEFAULT_HIDE_VALUES_OUTSIDE_OF_VALUE_RANGE,
  } = style || {}

  if (!yAxisPropertyName) return {}
  const series = await getSeriesFeaturesDataForEcharts({
    ...(hideValuesOutsideOfValueRange && { yAxisRange }),
    geojsonRows: widgetData,
    type: 'line',
    yAxisPropertyName,
    isXAxisTimeRangeEnabled,
    xAxisIntervalRange,
    xAxisIntervalUnit,
    timezone,
    xAxisPropertyPath,
  })

  if (_.isEmpty(series)) return {}
  const options = {
    colour: getMemoizedWidgetColour({ isSingleColour, colour, series }),
    yAxisRange,
    yAxisPropertyName,
    timezone,
    selectedDateTimeRange,
  }

  return { series, options }
}

const renderWidgetGroupLine = async ({
  style,
  widgetData,
  timezone,
  selectedDateTimeRange,
}) => {
  const {
    aggregation = DEFAULT_AGGREGATION_KEY_TYPE,
    intervalUnit = DATE_UNIT_TYPES.hours,
    valueRange,
    colour = DEFAULT_WIDGET_COLOUR,
    isSingleColour = true,
    isXAxisTimeRangeEnabled,
    xAxisIntervalRange = DEFAULT_REALTIME_RANGE,
    xAxisIntervalUnit = DATE_UNIT_TYPES.minutes,
    hideValuesOutsideOfValueRange = DEFAULT_HIDE_VALUES_OUTSIDE_OF_VALUE_RANGE,
  } = style || {}

  const { type: aggregationType, key: yAxisPropertyName } = aggregation

  const series = await getSeriesGroupDataForEcharts({
    ...(hideValuesOutsideOfValueRange && { yAxisRange: valueRange }),
    geojsonRows: widgetData,
    type: 'line',
    period: intervalUnit,
    yAxisPropertyName,
    aggregationType,
    isXAxisTimeRangeEnabled,
    xAxisIntervalRange,
    xAxisIntervalUnit,
    timezone,
  })

  if (_.isEmpty(series)) return {}

  const options = {
    colour: getMemoizedWidgetColour({ isSingleColour, colour, series }),
    yAxisRange: valueRange,
    aggregationType,
    yAxisPropertyName: _.isEmpty(yAxisPropertyName)
      ? AGGREGATION_TYPES.count
      : `${aggregationType} ${yAxisPropertyName}`,
    timezone,
    period: intervalUnit,
    selectedDateTimeRange,
  }

  return { series, options }
}

export const getWidgetLineOptions = props => {
  const { lineType } = props
  return lineType === LINE_CHART_TYPES.features
    ? renderWidgetFeaturesLine(props)
    : renderWidgetGroupLine(props)
}

export const renderWidgetLine = ({
  widgetData,
  widgetSetting,
  calculateWidgetData,
  dataMerge,
  updateWidgetProps,
  title,
  timezone,
  selectedDateTimeRange,
}) => {
  const { type: lineType = LINE_CHART_TYPES.features } = widgetSetting
  const style = widgetSetting[lineType] || {}

  const props = {
    style,
    widgetData,
    dataMerge,
    title,
    timezone,
    lineType,
    selectedDateTimeRange,
  }

  const lineWidget = getWidgetLineOptions(props)

  lineWidget
    .then(({ series, options }) => {
      const value = series ? { series } : null
      updateWidgetProps(value, calculateWidgetData, options)
    })
    .catch(e => log.error('There was a problem rendering the line widget', e))
}

export default renderWidgetLine
