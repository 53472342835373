// libraries
import { useRef, ReactElement } from 'react'
import _ from 'lodash'
import { useToggle, useWindowSize } from 'react-use'
import ReactDOM from 'react-dom'
import { FiLayers } from 'react-icons/fi'

// utils
import { useMapStateValue } from 'contexts'
import type { ThemeType } from 'types/common'

// constants
import { MAP_STYLE_SIZES } from 'constants/common'
import { THEMES } from 'constants/colour'

// components
import { MapLayer } from 'types/map'
import MapLegend from 'components/map/controls/MapLegend'
import { MapStylePicker } from 'components/map/controls'
import {
  StyledPanel,
  MAP_LAYERS_CONTROL_PLACEMENT,
  MapLayersControlPlacement,
  StyledMapStyleBg,
  PanelTriggerButton,
  StyledDivider,
} from './styles'
import scss from './index.module.scss'

const LayersPanel = ({
  layers,
  placement,
  position,
  canToggleVisibility,
  canToggleClustering,
  mapTheme,
}: {
  layers: MapLayer[]
  placement: MapLayersControlPlacement
  position: {
    x: number
    y: number
    windowHeight: number
  }
  canToggleVisibility: boolean
  canToggleClustering: boolean
  mapTheme: ThemeType
}): ReactElement | null => {
  const { x, y } = position
  const isLightTheme = mapTheme === THEMES.light
  const textColourClassName = isLightTheme ? 'defaultText' : 'text-light'

  return x > 0 && y > 0
    ? ReactDOM.createPortal(
        <StyledPanel
          className={scss.container}
          placement={placement}
          isLightTheme={isLightTheme}
          position={position}
        >
          {!_.isEmpty(layers) ? (
            <>
              <div className={`pillValue ${textColourClassName} ${scss.title}`}>
                Map layers
              </div>
              <StyledDivider isLightTheme={isLightTheme} />
              <MapLegend
                layers={layers}
                canToggleVisibility={canToggleVisibility}
                canToggleClustering={canToggleClustering}
                mapTheme={mapTheme}
              />
            </>
          ) : null}
          <StyledMapStyleBg isLightTheme={isLightTheme}>
            <div className={`pillValue ${textColourClassName} ${scss.title}`}>
              Map Style
            </div>
            <div className={scss.mapStylePicker}>
              <MapStylePicker
                isGrid
                size={MAP_STYLE_SIZES.default}
                expandable
              />
            </div>
          </StyledMapStyleBg>
        </StyledPanel>,
        document.body
      )
    : null
}

type MapLayersControlType = {
  placement: MapLayersControlPlacement
  buttonClassName?: string
  iconSize?: number
  canToggleVisibility: boolean
  canToggleClustering: boolean
}

const MapLayersControl = ({
  layers,
  placement = MAP_LAYERS_CONTROL_PLACEMENT.topRight,
  buttonClassName,
  iconSize = 16,
  canToggleVisibility = false,
  canToggleClustering = false,
  mapTheme = THEMES.light,
}: {
  layers: MapLayer[]
  mapTheme: ThemeType
} & MapLayersControlType): ReactElement => {
  const [showPanel, togglePanel] = useToggle(false)

  const iconRef = useRef<HTMLButtonElement>(null)
  const { current } = iconRef
  const { height: windowHeight, width: windowWidth } = useWindowSize()

  const { x = 0, y = 0 } = current ? current.getBoundingClientRect() : {}

  const position = { x, y, windowHeight, windowWidth }

  return (
    <>
      <PanelTriggerButton
        type='button'
        className={buttonClassName}
        onClick={() => togglePanel()}
        ref={iconRef}
        mapTheme={mapTheme}
        active={showPanel}
      >
        <FiLayers size={iconSize} />
      </PanelTriggerButton>

      {showPanel && (
        <LayersPanel
          layers={layers}
          placement={placement}
          position={position}
          canToggleVisibility={canToggleVisibility}
          canToggleClustering={canToggleClustering}
          mapTheme={mapTheme}
        />
      )}
    </>
  )
}

const MapLayersControlContainer = (
  props: MapLayersControlType
): ReactElement => {
  const { layersWithExternalConfig: layers, theme } = useMapStateValue()

  return <MapLayersControl {...props} layers={layers} mapTheme={theme} />
}

export default MapLayersControlContainer
