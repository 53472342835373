import _ from 'lodash'

import type { FilterSpec, Filters } from 'types/filter'
import type { MultiSelectProp } from 'components/common/MultiSelect'
import type { ToggleFn } from 'types/common'

import { FilterCustomOption } from './FilterDropdown/components'

export const getFilterKeysByPredicate = (
  filtersSpecs: FilterSpec[],
  predicate: Record<string, unknown>
): string[] => _(filtersSpecs).filter(predicate).map('key').value()

export const getValidFilters = (filters: Filters): Filters =>
  _.omitBy(filters, v => _.isNil(v) || (_.isArray(v) && _.isEmpty(v)))

export const getCommonSelectProps = ({
  value,
  isMulti,
  useOptionValueOnly,
  onChange,
  toggleOpen,
  formatOptionLabel,
}: MultiSelectProp & { toggleOpen: ToggleFn }): Partial<MultiSelectProp> => ({
  value: isMulti ? value : _.first(value),
  isMulti,
  useOptionValueOnly,
  placeholder: 'Search...',
  autoFocus: true,
  dropdownOnly: true,
  backspaceRemovesValue: false,
  controlShouldRenderValue: false,
  hideSelectedOptions: false,
  isClearable: false,
  tabSelectsValue: false,
  onChange: option => {
    onChange(option)
    if (!isMulti) {
      toggleOpen()
    }
  },
  components: { Option: FilterCustomOption(formatOptionLabel) },
})
