// libraries
import { FC, ReactElement } from 'react'

// components
import { Badge } from 'components/common'
import { BADGE_TYPES } from 'constants/common'

import type { AccordionContentProps } from './types'

// styles
import scss from './index.module.scss'

const AccordionContent: FC<AccordionContentProps> = ({
  title,
  subtitle,
  content,
  badge,
}): ReactElement => (
  <div className={scss.container}>
    <div className={scss.header}>
      <div className='d-flex align-items-center justify-content-between'>
        <div className={scss.headerText}>
          {title && <div className={scss.title}>{title}</div>}
          {subtitle && <div className={scss.subtitle}>{subtitle}</div>}
        </div>
        {badge && (
          <div className={scss.badge}>
            <Badge
              content={badge}
              type={BADGE_TYPES.default}
              className='ms-2'
            />
          </div>
        )}
      </div>
    </div>
    {content && <div className={scss.content}>{content}</div>}
  </div>
)

export default AccordionContent
