import { PropsWithChildren, ReactElement, useMemo } from 'react'
import useBranding from 'hooks/useBranding'
import _ from 'lodash'

import { Button } from 'components/common/'
import { THEMES } from 'constants/colour'
import { ThemeType } from 'types/common'
import scss from './index.module.scss'

const light = {
  background: 'ffffff80',
}

const dark = {
  background: '#08101dbf',
}

type LoadingProps = PropsWithChildren<{
  theme?: ThemeType
  spinner?: boolean
  className?: string
  containerClassName?: string
  onClick?: () => void
  buttonContent?: ReactElement | string
}>

const Loading = ({
  theme = THEMES.light,
  spinner = true,
  children,
  className,
  containerClassName,
  onClick,
  buttonContent,
}: LoadingProps): ReactElement => {
  const branding = useBranding()

  const spinColour = useMemo(() => {
    const mainColour = _.get(branding, 'colour.primary', '#1791EA')
    return {
      borderTop: `10px solid ${mainColour}`,
    }
  }, [branding])

  const divStyle = useMemo(
    () => (theme === THEMES.light ? light : dark),
    [theme]
  )

  return (
    <div
      data-testid='loading'
      className={`${scss.loadingContainer} loading ${containerClassName}`}
      style={divStyle}
    >
      {spinner && (
        <>
          <div className={`${scss.loader} ${className}`} style={spinColour} />
          {_.isFunction(onClick) && (
            <div className='d-flex justify-content-center my-2'>
              <Button onClick={onClick} testId='Pause Loading'>
                {buttonContent}
              </Button>
            </div>
          )}
        </>
      )}
      {children && <div className={scss.content}>{children}</div>}
    </div>
  )
}

export default Loading
