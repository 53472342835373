// libraries
import _ from 'lodash'

// constants
import { DEFAULT_WIDGET_COLOUR } from 'constants/widget'
import { DEFAULT_AGGREGATION_KEY_TYPE } from 'constants/aggregation'

// utils
import {
  getSimpleAggregatedResultValueLabel,
  getGaugeValueRange,
} from 'helpers/widget'

const renderWidgetGauge = ({
  widgetData,
  widgetSetting,
  calculateWidgetData,
  updateWidgetProps,
  title,
}) => {
  const {
    colour = DEFAULT_WIDGET_COLOUR,
    aggregation = DEFAULT_AGGREGATION_KEY_TYPE,
    overridePropertyTitle,
    valueRange,
  } = widgetSetting

  const { value, label, widgetColour } = getSimpleAggregatedResultValueLabel({
    geojsonRows: widgetData,
    aggregation,
    overridePropertyTitle,
    colour,
  })

  const yAxisRange = getGaugeValueRange(valueRange, value)

  const options = _.isNil(value)
    ? {}
    : { value, label, yAxisRange, colour: widgetColour, title }

  updateWidgetProps(options, calculateWidgetData, {})
}

export default renderWidgetGauge
