import { ReactElement, useMemo, useCallback } from 'react'
import { Editor } from '@tiptap/react'

import { Dropdown, TextColourPicker } from 'components/common'

import type { PropertiesMetadata } from 'types/common'
import { TOOLTIP_PLACEMENT } from 'constants/settings'
import ToolbarButton from './ToolbarButton'
import SuggestionDropdown from './SuggestionDropdown'
import { ICON_SIZE } from './constants'
import {
  getHeadersMenuData,
  getTextAlignMenuData,
  getActiveTextAlignmentState,
  getListTypesMenuData,
  getActiveListTypeState,
  getActiveHeadingState,
} from './utils'
import scss from './index.module.scss'

const EditorToolbar = ({
  editor,
  readOnly,
  suggestions,
}: {
  editor: Editor
  readOnly?: boolean
  suggestions: PropertiesMetadata
}): ReactElement => {
  const headerOptions = useMemo(() => getHeadersMenuData(editor), [editor])
  const alignOptions = useMemo(() => getTextAlignMenuData(editor), [editor])
  const listTypesOptions = useMemo(() => getListTypesMenuData(editor), [editor])

  const { isHeadingActive, headingLvl } = getActiveHeadingState(editor)
  const { isActive: isAlignActive, icon: alignmentIcon } =
    getActiveTextAlignmentState(editor)
  const { isActive: isListActive, icon: listIcon } =
    getActiveListTypeState(editor)

  // Important to keep these buttons memoized to make the tooltip's trigger work
  const renderHeaderDropdownToggle = useCallback(
    () => (
      <ToolbarButton
        tooltip='Heading'
        isActive={isHeadingActive}
        disabled={readOnly}
        isHeading
      >
        H{headingLvl}
      </ToolbarButton>
    ),
    [isHeadingActive, headingLvl, readOnly]
  )

  const renderTextAlignDropdownToggle = useCallback(
    () => (
      <ToolbarButton
        tooltip='Text align'
        isActive={isAlignActive}
        icon={alignmentIcon}
        size={ICON_SIZE}
        disabled={readOnly}
      />
    ),
    [isAlignActive, alignmentIcon, readOnly]
  )

  const renderListTypeToggle = useCallback(
    () => (
      <ToolbarButton
        tooltip='List style'
        isActive={isListActive}
        icon={listIcon}
        size={ICON_SIZE}
        disabled={readOnly}
      />
    ),
    [isListActive, listIcon, readOnly]
  )

  if (!editor) {
    return <></>
  }

  return (
    <div className={scss.toolbarContainer}>
      <Dropdown
        placement={TOOLTIP_PLACEMENT.bottom}
        trigger='click'
        options={headerOptions}
        className={scss.headerOptionsList}
        isDisabled={readOnly}
        toggleComponent={renderHeaderDropdownToggle}
      />

      <ToolbarButton
        tooltip='Bold'
        isActive={editor.isActive('bold')}
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run() || readOnly}
        icon='AiOutlineBold'
        size={ICON_SIZE}
      />
      <ToolbarButton
        tooltip='Italic'
        isActive={editor.isActive('italic')}
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={
          !editor.can().chain().focus().toggleItalic().run() || readOnly
        }
        icon='AiOutlineItalic'
        size={ICON_SIZE}
      />
      <ToolbarButton
        tooltip='Underline'
        isActive={editor.isActive('underline')}
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        disabled={
          !editor.can().chain().focus().toggleUnderline().run() || readOnly
        }
        icon='AiOutlineUnderline'
        size={ICON_SIZE}
      />

      <Dropdown
        placement={TOOLTIP_PLACEMENT.bottom}
        trigger='click'
        options={listTypesOptions}
        className={scss.headerOptionsList}
        isDisabled={readOnly}
        toggleComponent={renderListTypeToggle}
      />

      <Dropdown
        placement={TOOLTIP_PLACEMENT.bottom}
        trigger='click'
        options={alignOptions}
        className={scss.headerOptionsList}
        isDisabled={readOnly}
        toggleComponent={renderTextAlignDropdownToggle}
      />

      <TextColourPicker
        color={editor.getAttributes('textStyle').color}
        onChange={val => editor.chain().focus().setColor(val).run()}
        disabled={readOnly}
      >
        <ToolbarButton
          tooltip='Text colour'
          color={editor.getAttributes('textStyle').color}
          icon='AiOutlineFontColors'
          size={ICON_SIZE}
          disabled={readOnly}
        />
      </TextColourPicker>

      <TextColourPicker
        color={editor.getAttributes('highlight').color}
        onChange={val =>
          editor.chain().focus().toggleHighlight({ color: val }).run()
        }
        disabled={readOnly}
      >
        <ToolbarButton
          tooltip='Text highlight'
          color={editor.getAttributes('highlight').color}
          icon='AiOutlineBgColors'
          size={ICON_SIZE}
          disabled={readOnly}
        />
      </TextColourPicker>

      <SuggestionDropdown
        editor={editor}
        readOnly={readOnly}
        suggestions={suggestions}
      />

      <ToolbarButton
        tooltip='Remove formatting'
        onClick={() => {
          editor.chain().focus().clearNodes().run()
          editor.chain().focus().unsetAllMarks().run()
          editor.chain().focus().unsetColor().run()
          editor.chain().focus().unsetHighlight().run()
          editor.chain().focus().unsetTextAlign().run()
        }}
        disabled={readOnly}
        icon='MdFormatClear'
        size={ICON_SIZE}
      />

      <div className={scss.undoRedoWrapper}>
        <ToolbarButton
          tooltip='Undo'
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run() || readOnly}
          icon='MdOutlineUndo'
          size={ICON_SIZE}
        />
        <ToolbarButton
          tooltip='Redo'
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run() || readOnly}
          icon='MdRedo'
          size={ICON_SIZE}
        />
      </div>
    </div>
  )
}

export default EditorToolbar
