import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { MultiSelect } from 'components/common'
import _ from 'lodash'
import { useAudio, useToggle } from 'react-use'
import { MdPlayCircleFilled, MdPauseCircleFilled } from 'react-icons/md'
import { Tooltip } from 'components/common/Tooltip'
import { PLACEMENT } from 'constants/common'

import scss from './index.module.scss'

const soundTracks = [
  { value: 'alarm1', label: 'Alarm 1', src: '/sound/alarm_sound.mp3' },
  { value: 'alarm2', label: 'Alarm 2', src: '/sound/alarm_sound2.mp3' },
]

export const getSound = value => _.find(soundTracks, ['value', value])

const SoundPicker = ({ value, onChange, disabled }) => {
  const [sound, setSound] = useState(() => getSound(value) || {})
  const [playAlarm, togglePlayAlarm] = useToggle(false)

  // audio sound control
  const [audio, audioState, controls] = useAudio({
    src: sound && sound.src,
    autoPlay: false,
    loop: true,
  })

  const soundChange = newOption => {
    setSound(newOption || {})
    onChange(newOption ? newOption.value : '')
  }

  useEffect(() => {
    if (playAlarm) {
      if (audioState.paused || audioState.muted) {
        controls.play()
      }
    } else {
      controls.pause()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playAlarm, controls])

  return (
    <div className='d-flex justify-content-between'>
      <MultiSelect
        placeholder='select a alarm sound'
        value={sound.value}
        options={soundTracks}
        onChange={soundChange}
        isMulti={false}
        isClearable
        isDisabled={disabled}
      />
      {audio}
      {!_.isEmpty(sound) && (
        <>
          <Tooltip
            placement={PLACEMENT.top}
            overlay={<span>{playAlarm ? 'Pause' : 'Play & listen!'}</span>}
          >
            <button
              type='button'
              className={scss.control}
              onClick={() => togglePlayAlarm(!playAlarm)}
            >
              {playAlarm ? (
                <MdPauseCircleFilled size={20} />
              ) : (
                <MdPlayCircleFilled size={20} />
              )}
            </button>
          </Tooltip>
        </>
      )}
    </div>
  )
}

SoundPicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

SoundPicker.defaultProps = {
  value: '',
  disabled: false,
}

export default SoundPicker
