// libraries
import React, { useMemo, useEffect } from 'react'
import _ from 'lodash'

// components
import HistoricalTimeline from 'components/common/Timeline/HistoricalTimeline'
import LiveTimeline from 'components/common/Timeline/LiveTimeline'

// icon
import { MdRestore } from 'react-icons/md'

// utils
import { hasDataset, hasLiveDataset, hasHistoricalDataset } from 'helpers/map'

// contexts
import { useMapStateValue } from 'contexts'

// scss
import scss from './index.module.scss'

export const shouldHistoricalTimelineAvailable = layers => {
  const visibleLayers = _.filter(layers, 'isVisible')
  return !hasLiveDataset(visibleLayers) && hasHistoricalDataset(visibleLayers)
}

const Timeline = () => {
  const {
    map: { layers },
    maxWidgetSelected,
    isTimelineExpanded,
    toggleIsTimelineExpanded,
  } = useMapStateValue()

  const couldHistoricalTimelineAvailable = useMemo(
    () => shouldHistoricalTimelineAvailable(layers),
    [layers]
  )

  // rgba(5, 27, 50, 0.9) is $widget-dark-bg from src/style/_colors.scss
  const timelineStyle = useMemo(
    () => ({
      backgroundColor:
        maxWidgetSelected && maxWidgetSelected.widgetProps
          ? 'transparent'
          : 'rgba(5, 27, 50, 0.9)',
      width: 'calc(100% - 88px)',
    }),
    [maxWidgetSelected]
  )

  useEffect(() => {
    toggleIsTimelineExpanded(couldHistoricalTimelineAvailable)
  }, [couldHistoricalTimelineAvailable, toggleIsTimelineExpanded])

  return (
    hasDataset(layers) && (
      <>
        <button
          type='button'
          className={scss.expandButton}
          onClick={() => toggleIsTimelineExpanded(!isTimelineExpanded)}
        >
          <MdRestore />
        </button>
        {isTimelineExpanded && (
          <div
            id='timeline-container'
            className={scss.timeline}
            style={timelineStyle}
          >
            {couldHistoricalTimelineAvailable ? (
              <HistoricalTimeline />
            ) : (
              <LiveTimeline />
            )}
          </div>
        )}
      </>
    )
  )
}

const TimelineContainer = () => {
  const {
    map: { layers },
  } = useMapStateValue()
  return hasDataset(layers) ? <Timeline /> : null
}

export default TimelineContainer
