import { SetStateAction, Dispatch, useEffect, useMemo } from 'react'
import type { DataCollectionFormMediaView } from 'types/issue'

export const useMediaCount = ({
  setMediaCount,
  videoItems,
  imageItems,
}: {
  setMediaCount: Dispatch<SetStateAction<number>>
  videoItems: DataCollectionFormMediaView[]
  imageItems: DataCollectionFormMediaView[]
}) => {
  const overallMediaCount = useMemo(
    () => (videoItems?.length || 0) + (imageItems?.length || 0),
    [videoItems, imageItems]
  )

  useEffect(() => {
    if (overallMediaCount > 0) {
      setMediaCount(overallMediaCount)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overallMediaCount])

  return { overallMediaCount }
}
