// libraries
import { ReactElement } from 'react'
import _ from 'lodash'

// components
import { Button } from 'components/common'
import * as Icons from 'components/icons'
import { Title, Subtitle } from 'components/login/common'
import scss from './index.module.scss'

const { CheckIcon } = Icons

const SuccessMessage = ({
  title,
  subtitle,
  nextStep,
  onNextStep,
  icon = 'CheckIcon',
}: {
  title: string
  subtitle?: string
  nextStep?: string
  onNextStep?: () => void
  icon?: string
}): ReactElement => {
  const Icon = _.get(Icons, icon, CheckIcon)
  return (
    <>
      <div className={scss.check}>
        <Icon />
      </div>
      <Title>{title}</Title>
      <Subtitle className={scss.subtitle}>{subtitle}</Subtitle>
      {nextStep && (
        <Button block className={`${scss.nextStep} mx-0`} onClick={onNextStep}>
          {nextStep}
        </Button>
      )}
    </>
  )
}

export default SuccessMessage
