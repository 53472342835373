import { ReactElement } from 'react'

import type { FormList } from 'types/formBuilder'
import type { CardActions } from 'components/common/List/Card/CardOptions'

import { BADGE_TYPES } from 'constants/common'

import { Badge } from 'components/common'

import FormListItem from '../FormListItem'

const FormListsSection = ({
  title,
  list,
  itemActions,
  selectedFormListId,
  setSelectedFormListId,
}: {
  title: string
  list: FormList[]
  itemActions: CardActions<FormList>
  selectedFormListId?: string
  setSelectedFormListId: (id?: string) => void
}): ReactElement => (
  <>
    <h6 className='d-flex align-items-center mb-3 defaultBoldText'>
      {title}
      <Badge
        content={list.length}
        type={BADGE_TYPES.infoGrey}
        className='ms-2'
      />
    </h6>
    <ul className='p-0'>
      {list.map(item => (
        <FormListItem
          key={item.id}
          item={item}
          itemActions={itemActions}
          isActive={selectedFormListId === item.id}
          setSelectedFormListId={setSelectedFormListId}
        />
      ))}
    </ul>
  </>
)

export default FormListsSection
