// libraries
import { useAsync } from 'react-use'

// utils
import { getAssetProfileById } from 'services/api/asset'
import { getAssetViewConfigs } from 'helpers/asset'
import { getConfigs } from 'components/assets/assetsProfile/Builder/Layout'

import type { AsyncState } from 'types/common'
import type { Widget } from 'types/asset'

const useAssetProfile = (
  profile?: string
): AsyncState<Widget[] | undefined> => {
  return useAsync(async () => {
    if (!profile) return undefined

    const response = await getAssetProfileById(profile)
    const { viewConfigurations } = response
    const { profileConfigs } = getAssetViewConfigs(viewConfigurations)
    return getConfigs(profileConfigs)
  }, [profile])
}

export default useAssetProfile
