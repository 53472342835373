// libraries
import _ from 'lodash'
import { useCallback } from 'react'
import { getPolygonsArray } from 'components/map/layers/deckLayers/dataAggregation'
import { generatePolygonBbox } from 'helpers/map'
import { useStateValue } from 'contexts'
import type { Feature } from 'geojson'

const useAncillaryData = (): {
  getPolygonsWithBbox: (ancillaryDataId: string) => Promise<Feature[]>
} => {
  const {
    actions: {
      ancillaryDataActions: { getAncillaryDataContent },
    },
  } = useStateValue()

  const getPolygonsWithBbox = useCallback(
    async ancillaryDataId => {
      const rawPolygon = await getAncillaryDataContent(ancillaryDataId)
      const polygonsArray = getPolygonsArray(
        _.get(rawPolygon, 'assetContent', {})
      )
      return generatePolygonBbox(polygonsArray)
    },
    [getAncillaryDataContent]
  )

  return { getPolygonsWithBbox }
}

export default useAncillaryData
