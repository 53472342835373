import { ReactElement } from 'react'
import _ from 'lodash'
import { NavLink } from 'react-router-dom'

// types
import type { NavLinkSpec } from 'types/route'

// utils
import { useStaticResourceStateValue } from 'contexts'

import scss from './index.module.scss'

const NavItem = ({
  name,
  path,
  icon,
  iconSize = 19,
  enabled = true,
  badgeCount,
}: NavLinkSpec): ReactElement | null => {
  const { Icons } = useStaticResourceStateValue()

  if (!enabled) return null

  const Icon = _.get(Icons, icon) || Icons.AiFillAppstore
  const displayBadge = _.isNumber(badgeCount)

  return (
    <NavLink
      data-testid={name}
      to={path}
      className={({ isActive }) =>
        `${scss.navLink} ${isActive ? scss.active : ''}`
      }
    >
      <div className={scss.sidebarIcon}>
        <div className={scss.iconWrapper}>
          <Icon size={iconSize} color='#fff' />
          {displayBadge && <div className={scss.badge}>{badgeCount}</div>}
        </div>
        <div
          data-testid={`${name}_Label`}
          className={`${scss.sidebarLabel} navText`}
        >
          {name}
        </div>
      </div>
    </NavLink>
  )
}

export default NavItem
