import { ReactElement, useMemo, memo } from 'react'
import _ from 'lodash'
import { useRecoilValue } from 'recoil'

// utils
import {
  getPrettifiedDataCollectionStateData,
  getDataCollectionStateData,
} from 'components/issue/hooks/utils'
import {
  getStateDataCollectionData,
  isSubTaskStateData,
} from 'components/assets/assetsProfile/widgets/AssetIssuesList/Widget/Web'
import { useTimezone } from 'hooks'
import {
  issueTaskDataCollectionXFormSpecificationList,
  IssueTaskDataCollectionForms,
} from 'recoilStore/issuesStore'
import { getDataCollectionFormReference } from 'helpers/issue'

// constants
import { PROPERTY_TABLE_COLUMNS } from 'constants/assets'
import { THEMES } from 'constants/colour'

// types
import {
  IssueStateData,
  DataCollectionStateData,
  IssueStateDataCollectionData,
  IssueGeojson,
} from 'types/issue'

// components
import Table from 'components/assets/assetsProfile/widgets/common/Table'
import {
  StyledTitle,
  StyledSubtitle,
  StyledSection,
} from 'components/assets/assetsProfile/widgets/common/style'

type AssetIssuesListWidgetPrintableProps = {
  issues: IssueGeojson[]
  hideInvalidValues: boolean
  issueTaskDataCollectionFormDefinition: IssueTaskDataCollectionForms
}

type FormResult = {
  id: string
  title: string
}

type SubTask = FormResult & IssueStateDataCollectionData

type IssueSubTaskFormResult = FormResult & {
  subTasks?: SubTask[]
}

type IssueFormResult = FormResult & IssueStateDataCollectionData

type IssueData = (IssueFormResult | IssueSubTaskFormResult)[]

type IssueTableListTypes = {
  issueData: IssueData
}

type IssueTableProps = {
  issueStateData: IssueFormResult | IssueSubTaskFormResult
}

const IssueTable = ({ issueStateData }: IssueTableProps): ReactElement => {
  const data = useMemo(
    () => getStateDataCollectionData(issueStateData as IssueStateData),
    [issueStateData]
  )

  return _.isEmpty(data) ? (
    <StyledSubtitle>No information collected</StyledSubtitle>
  ) : (
    <Table data={data} columns={PROPERTY_TABLE_COLUMNS} theme={THEMES.light} />
  )
}

const IssueTableList = memo(
  ({ issueData }: IssueTableListTypes): ReactElement => {
    return (
      <>
        {_.map(issueData, issueStateData => {
          const { subTasks } = issueStateData as IssueSubTaskFormResult
          return (
            <StyledSection key={issueStateData.id}>
              <StyledTitle>Issue: {issueStateData.title}</StyledTitle>
              {_.isUndefined(subTasks) ? (
                <IssueTable issueStateData={issueStateData} />
              ) : (
                _.map(subTasks, subTaskData => {
                  return (
                    <div key={subTaskData.id}>
                      <StyledSubtitle>
                        Subtask: {subTaskData.title}
                      </StyledSubtitle>
                      <IssueTable issueStateData={subTaskData} />
                    </div>
                  )
                })
              )}
              <hr />
            </StyledSection>
          )
        })}
      </>
    )
  }
)

const AssetIssuesListWidgetPrintable = ({
  issues,
  hideInvalidValues,
  issueTaskDataCollectionFormDefinition,
  ...rest
}: AssetIssuesListWidgetPrintableProps): ReactElement => {
  const formsSpecificationParameters = useRecoilValue(
    issueTaskDataCollectionXFormSpecificationList
  )

  const { timezone } = useTimezone()

  const issueData = useMemo(() => {
    return _.map(issues, issue => {
      const {
        statesData,
        properties: { title },
        taskType,
        id,
      } = issue

      const dataCollectionFormReference = getDataCollectionFormReference(issue)

      const issueStateData = getPrettifiedDataCollectionStateData({
        dataCollectionStateData: getDataCollectionStateData({
          dataCollectionFormReference,
          issueId: id,
          statesData: statesData as DataCollectionStateData[],
          taskType,
        }) as IssueStateDataCollectionData[],
        formsSpecificationParameters,
        timezone,
        hideInvalidValues,
        issueTaskDataCollectionFormsKeyByFormReference:
          issueTaskDataCollectionFormDefinition,
      })

      const commonProps = { id, title }
      if (isSubTaskStateData(issueStateData)) {
        return {
          ...commonProps,
          subTasks: issueStateData as SubTask[],
        } as IssueSubTaskFormResult
      }

      return {
        ...commonProps,
        ...(issueStateData[0] as IssueStateDataCollectionData),
      } as IssueFormResult
    })
  }, [
    formsSpecificationParameters,
    hideInvalidValues,
    issueTaskDataCollectionFormDefinition,
    issues,
    timezone,
  ])

  return <IssueTableList {...rest} issueData={issueData} />
}

export default AssetIssuesListWidgetPrintable
