import { ReactElement, useMemo } from 'react'
import _ from 'lodash'

// constants
import { THEMES } from 'constants/colour'

import { stopEventDefaultAndPropagation } from 'helpers/utils'

// components
import { IconButton, Tooltip } from 'components/common'
import DropdownBody from './DropdownBody'

import type { CustomDropdownElementProps, DropdownProps } from '.'

import scss from './index.module.scss'

const ControllableDropdown = ({
  isVisible,
  toggleListVisible,
  selectedValue,
  size,
  className,
  placement,
  icon,
  children,
  toggleComponent: ToggleComponent,
  directlyRenderToggle,
  containerClassName,
  overlayClassName = '',
  onChange,
  visibilityChangeCallback,
  options = [],
  trigger = 'click',
  theme = THEMES.light,
  testId = 'dropdown-button',
  isDisabled = false,
  align,
  title,
  iconClassName,
  dropdownComponent: DropdownComponent,
}: DropdownProps & CustomDropdownElementProps): ReactElement => {
  const isLightTheme = useMemo(() => theme === THEMES.light, [theme])

  const customToggle = ToggleComponent ? (
    <ToggleComponent
      isVisible={isVisible}
      toggleListVisible={toggleListVisible}
    />
  ) : (
    <></>
  )

  return (
    <Tooltip
      theme={theme}
      visible={!isDisabled && isVisible}
      onVisibleChange={nextIsVisible => {
        toggleListVisible(nextIsVisible)
        if (visibilityChangeCallback) visibilityChangeCallback(nextIsVisible)
      }}
      trigger={trigger}
      placement={placement}
      overlayClassName={`${theme} ${overlayClassName} tooltipDropdown`}
      align={align}
      overlay={
        DropdownComponent ? (
          <DropdownComponent
            isVisible={isVisible}
            toggleListVisible={toggleListVisible}
          />
        ) : (
          children || (
            <>
              {title && <div className={`bold ${scss.title}`}>{title}</div>}
              <DropdownBody
                selectedValue={selectedValue}
                className={className}
                data-testid='dropdown-options'
                onChange={onChange}
                toggleListVisible={toggleListVisible}
                isLightTheme={isLightTheme}
                options={options}
              />
            </>
          )
        )
      }
      showArrow={false}
      onClick={e => stopEventDefaultAndPropagation(e)}
    >
      {ToggleComponent && directlyRenderToggle ? (
        customToggle
      ) : (
        <div data-testid='dropdown-button' className={containerClassName}>
          {ToggleComponent ? (
            customToggle
          ) : (
            <IconButton
              testId={testId}
              icon={icon}
              size={size}
              onClick={_.noop}
              className={iconClassName}
            />
          )}
        </div>
      )}
    </Tooltip>
  )
}

export default ControllableDropdown
