import { ReactElement } from 'react'

// constants
import {
  TIME_ZONES_OPTIONS_WITH_UTC,
  UTC_TIMEZONE_OPTION,
} from 'constants/datetime'

// components
import { MultiSelect } from 'components/common'
import { StyledField } from 'components/common/Form'

const TimezoneSelect = ({
  label,
  placeholder,
  input: { name, value, onChange },
  className,
  readOnly,
  required,
}: {
  label?: string
  placeholder?: string
  input: {
    name: string
    value?: string
    onChange: (v: string) => void
  }
  className?: string
  readOnly?: boolean
  required?: boolean
}): ReactElement => {
  // Always default to UTC
  const selectedTimezone = value || UTC_TIMEZONE_OPTION.value

  return (
    <StyledField name={name} label={label} required={required}>
      <MultiSelect
        className={className}
        name={name}
        placeholder={placeholder}
        value={selectedTimezone}
        options={TIME_ZONES_OPTIONS_WITH_UTC}
        onChange={onChange}
        isMulti={false}
        isClearable={false}
        isDisabled={readOnly}
        useOptionValueOnly
        withBorder
      />
    </StyledField>
  )
}

export default TimezoneSelect
