import {
  ReactElement,
  useCallback,
  useMemo,
  useState,
  Dispatch,
  useRef,
  useLayoutEffect,
} from 'react'
import _ from 'lodash'

// constants
import { ASSET_PROFILE_MEDIA_TYPES } from 'constants/assets'
import { issueTaskDataCollectionFormDefinitionState } from 'recoilStore/issuesStore'

// utils
import { switchcaseF } from 'helpers/utils'
import { getIssueGeojsonRows, isListAllowedIssue } from 'helpers/issue'
import { useFilteredIssues } from 'components/issue/hooks/useIssuesFilters'
import { useCurrentUser } from 'hooks'

// types
import type { Issue, IssueGeojson } from 'types/issue'
import type { ID, ThemeType } from 'types/common'
import type { Filters } from 'types/filter'
import type { AssetBaseWidget, OnAssetWidgetLoad } from 'types/asset'

// components
import { BaseWidgetWithAssetState } from 'components/assets/assetsProfile/widgets/BaseWidget'
import { useRecoilValue } from 'recoil'
import AssetIssuesListWidgetWeb, { Header } from './Web'
import AssetIssuesListWidgetPrintable from './Printable'

type AssetIssueListProps = {
  assetId: ID
  issues: Issue[]
  issueFilters?: Filters
  theme?: ThemeType
  mediaType?: string
  setHeader: Dispatch<Header>
  onWidgetLoad: OnAssetWidgetLoad
  hideInvalidValues?: boolean
}

const AssetIssueList = ({
  issues,
  issueFilters,
  theme,
  mediaType,
  setHeader,
  onWidgetLoad,
  hideInvalidValues = false,
}: AssetIssueListProps): ReactElement => {
  const { issueAssigneesOptions, issueSeverityOptions } = useCurrentUser()
  const issueTaskDataCollectionFormDefinition = useRecoilValue(
    issueTaskDataCollectionFormDefinitionState
  )

  const listAllowedIssues = useMemo(() => {
    const allIssues = getIssueGeojsonRows({
      issues,
      issueAssigneesOptions,
      issueSeverityOptions,
    }) as IssueGeojson[]
    return _.filter(allIssues, ({ properties }) =>
      isListAllowedIssue(properties)
    )
  }, [issueAssigneesOptions, issueSeverityOptions, issues])

  const filteredAndSortedIssues = useFilteredIssues({
    issues: listAllowedIssues,
    filters: issueFilters,
  })

  const tableRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (onWidgetLoad) {
      onWidgetLoad()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableRef?.current])

  const commonProps = {
    issues: filteredAndSortedIssues,
    hideInvalidValues,
    issueTaskDataCollectionFormDefinition,
  }

  return switchcaseF({
    [ASSET_PROFILE_MEDIA_TYPES.PRINTABLE]: () => (
      <div ref={tableRef}>
        <AssetIssuesListWidgetPrintable {...commonProps} />
      </div>
    ),
  })(() => (
    <AssetIssuesListWidgetWeb
      {...commonProps}
      theme={theme}
      setHeader={setHeader}
    />
  ))(mediaType)
}

const AssetIssuesListWidget = ({
  assetId,
  settings,
  theme,
  mediaType,
  name,
  onWidgetLoad,
  ...rest
}: AssetBaseWidget): ReactElement => {
  const [header, setHeader] = useState<Header>(name)
  const { issueFilters, hideInvalidValues } = settings

  const isPrintable = useMemo(
    () => mediaType === ASSET_PROFILE_MEDIA_TYPES.PRINTABLE,
    [mediaType]
  )

  const render = useCallback(
    ({ value: { issueReferences } }) => {
      const issues = _.map(issueReferences, 'issue')
      const props = {
        assetId,
        issues,
        theme,
        issueFilters,
        setHeader,
        mediaType,
        onWidgetLoad,
        hideInvalidValues,
      }
      return <AssetIssueList {...props} />
    },
    [assetId, theme, issueFilters, mediaType, onWidgetLoad, hideInvalidValues]
  )

  return (
    <BaseWidgetWithAssetState
      assetId={assetId}
      settings={settings}
      render={render}
      className='position-relative h-100'
      withIssues
      theme={theme}
      name={header || name}
      forceScroll={isPrintable}
      isPrintable={isPrintable}
      issuePickFields={
        isPrintable
          ? [
              'statesData',
              'id',
              'type',
              'assignee',
              'status',
              'severity',
              'title',
              'lastModifiedAt',
              'statesParameter',
              'trigger',
            ]
          : []
      }
    />
  )
}

export default AssetIssuesListWidget
