import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useAsync } from 'react-use'
import { AsyncState } from 'react-use/lib/useAsyncFn'

import { getAssetByProfileId } from 'services/api/asset'
import {
  assetsDataByIdSelector,
  assetsOptionsByProfileIdSelector,
} from 'recoilStore/assetsStore'

const useAssetDataByProfileId = (
  assetProfileId: string
): {
  assetsData: Record<string, unknown>
  assetQueryState: AsyncState<unknown>
} => {
  const setAssetsData = useSetRecoilState(assetsDataByIdSelector)
  const assetsData: Record<string, unknown> = useRecoilValue(
    assetsOptionsByProfileIdSelector
  )
  const assetQueryState = useAsync(async () => {
    if (!assetProfileId) return undefined

    const currentAssetsData = assetsData?.[assetProfileId]
    if (currentAssetsData) return currentAssetsData

    const data = await getAssetByProfileId({
      profileId: assetProfileId,
    })

    setAssetsData({ ...data, profileId: assetProfileId })
    return data
  }, [assetProfileId])

  return { assetsData, assetQueryState }
}

export default useAssetDataByProfileId
