// libraries
import { useCallback } from 'react'
import { useSetRecoilState } from 'recoil'
import _ from 'lodash'

// constants
import { FEATURES } from 'constants/settings'

// utils
import { listAssetProfiles } from 'services/api/asset'
import {
  allAssetProfilesState,
  allAssetProfileOptionsState,
} from 'recoilStore/assetsStore'
import { useFeatureFlag } from 'hooks'

import type { AssetProfile } from 'types/asset'

const useAssetsProfiles = ({
  optionOnly = false,
  pageSize,
  withViewConfigurations,
}: Partial<{
  optionOnly: boolean
  pageSize: number
  withViewConfigurations: boolean
}> = {}): {
  fetchAssetSupportData: () => Promise<{ assetProfiles?: AssetProfile[] }>
} => {
  const { [FEATURES.ASSET]: assetsEnabled } = useFeatureFlag()

  const setAllAssetProfilesState = useSetRecoilState(allAssetProfilesState)
  const setAllAssetProfileOptions = useSetRecoilState(
    allAssetProfileOptionsState
  )

  const fetchAssetSupportData = useCallback(async () => {
    if (!assetsEnabled) return {}

    const payload = optionOnly
      ? {
          pickFields: ['value', 'label'],
          queryParams: { first: 100 },
        }
      : {
          ...(pageSize && { queryParams: { first: pageSize } }),
          ...(withViewConfigurations && {
            omitFields: [],
          }),
        }

    const { data: assetProfiles } = await listAssetProfiles(payload)

    const sortedAssetProfiles = _.sortBy(assetProfiles, ['label'])
    if (optionOnly) {
      setAllAssetProfileOptions(sortedAssetProfiles)
    } else {
      setAllAssetProfilesState(sortedAssetProfiles)
    }
    return { assetProfiles }
  }, [
    assetsEnabled,
    optionOnly,
    pageSize,
    setAllAssetProfileOptions,
    setAllAssetProfilesState,
    withViewConfigurations,
  ])

  return {
    fetchAssetSupportData,
  }
}

export default useAssetsProfiles
