import { ReactElement } from 'react'
import { Field } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'

export type WhenFieldChangesProps = {
  fieldToWatch: string
  fieldToSet: string
  callOnChange: (args: {
    currValue: unknown
    prevValue: unknown
    onChange: (value: unknown) => void
  }) => void
}

/** "Update field A when field B is changed" */
const WhenFieldChanges = ({
  fieldToWatch,
  fieldToSet,
  callOnChange,
}: WhenFieldChangesProps): ReactElement => (
  // No subscription. We only use Field to get to the change function
  <Field name={fieldToSet} subscription={{}}>
    {({ input: { onChange } }) => (
      <OnChange name={fieldToWatch}>
        {(currValue, prevValue) =>
          callOnChange({ currValue, prevValue, onChange })
        }
      </OnChange>
    )}
  </Field>
)

export default WhenFieldChanges
