import { useMemo, useEffect, ReactElement } from 'react'
import _ from 'lodash'
import { useRecoilState } from 'recoil'

// constants
import { ASSET_PROFILE_MEDIA_TYPES, ASSET_MAP_STYLE } from 'constants/assets'
import { THEMES } from 'constants/colour'
import { selectedAssetProfileWidgetIdState } from 'recoilStore/assetsStore'

// utils
import { switchcase } from 'helpers/utils'

import type {
  AssetProfileViewConfigs,
  Widget,
  Asset,
  AssetBuilderTab,
} from 'types/asset'
import type { MapLayerStyle } from 'types/map'

// components
import ButtonGroup, { ButtonGroupOnChange } from 'components/common/ButtonGroup'
import AssetProfilePanel from 'components/assets/assetsProfile/Builder/Layout/Panel'
import PreviewMap from 'components/assets/assetsProfile/Builder/Map'

import BuilderWebLayout from './Web'
import BuilderPrintableLayout from './Printable'

import scss from './index.module.scss'

export const getConfigs = (
  configs: AssetProfileViewConfigs,
  mediaType: string = ASSET_PROFILE_MEDIA_TYPES.WEB
): Widget[] => {
  return _.get(configs, [mediaType, 'widgets'], [])
}

const mediaTypeButtonOptions = [
  { children: 'Dashboard', value: ASSET_PROFILE_MEDIA_TYPES.WEB },
  { children: 'Map', value: ASSET_MAP_STYLE },
  { children: 'Printable', value: ASSET_PROFILE_MEDIA_TYPES.PRINTABLE },
]

const BuilderLayout = ({
  selectedEditingView,
  setSelectedEditingView,
  ...rest
}: {
  configs: AssetProfileViewConfigs
  assetId?: string
  assets: Asset[]
  mapStyle: MapLayerStyle
  selectedEditingView: AssetBuilderTab
  setSelectedEditingView: (selected: AssetBuilderTab) => void
}): ReactElement => {
  const { configs, assetId } = rest

  const [selectedWidget, setSelectedWidget] = useRecoilState(
    selectedAssetProfileWidgetIdState
  )

  const widgetsConfigs = useMemo(() => getConfigs(configs), [configs])

  const Layout = useMemo(() => {
    return switchcase({
      [ASSET_PROFILE_MEDIA_TYPES.WEB]: BuilderWebLayout,
      [ASSET_PROFILE_MEDIA_TYPES.PRINTABLE]: BuilderPrintableLayout,
      [ASSET_MAP_STYLE]: PreviewMap,
    })()(selectedEditingView)
  }, [selectedEditingView])

  useEffect(() => {
    setSelectedWidget('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEditingView])

  const isCurrentWeb = useMemo(
    () => selectedEditingView === ASSET_PROFILE_MEDIA_TYPES.WEB,
    [selectedEditingView]
  )

  const theme = useMemo(() => {
    return assetId && isCurrentWeb ? THEMES.dark : THEMES.light
  }, [assetId, isCurrentWeb])

  const isLightTheme = useMemo(() => theme === THEMES.light, [theme])

  return (
    <div
      className='position-relative flex-grow-1'
      style={useMemo(
        () => ({
          backgroundColor: isLightTheme ? '#f6f8f9' : '#051b32',
        }),
        [isLightTheme]
      )}
    >
      <div className={scss.buttonsGroup}>
        <ButtonGroup
          selectedValue={selectedEditingView}
          buttonOptions={mediaTypeButtonOptions}
          onChange={setSelectedEditingView as ButtonGroupOnChange}
        />
      </div>
      {selectedEditingView === ASSET_MAP_STYLE ? (
        Layout && <Layout {...rest} configs={widgetsConfigs} />
      ) : (
        <div className='d-flex flex-grow-1'>
          <div
            className={`flex-grow-1 ${scss.builder}`}
            style={{
              marginTop: '30px',
            }}
          >
            {Layout && <Layout {...rest} configs={widgetsConfigs} />}
          </div>
          {isCurrentWeb && (
            <AssetProfilePanel
              theme={theme}
              configs={widgetsConfigs}
              selected={selectedWidget}
              setSelected={setSelectedWidget}
              mediaType={selectedEditingView}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default BuilderLayout
