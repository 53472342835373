import Layer from './baseLayer'
import { getAggregatedValue } from './dataAggregation'

export default class AggregationLayer extends Layer {
  get isAggregated() {
    return true
  }

  get noneLayerDataAffectingProps() {
    return [
      ...super.noneLayerDataAffectingProps,
      'enable3d',
      'colorRange',
      'colorScale',
      'colorDomain',
      'sizeRange',
      'sizeScale',
      'sizeDomain',
      'percentile',
      'coverage',
      'elevationPercentile',
      'elevationScale',
    ]
  }

  getAggregatedValue = getAggregatedValue
}
