// libraries
import { PropsWithChildren, ReactElement, ReactNode } from 'react'

// style
import scss from './index.module.scss'

type WidgetBaseProps = PropsWithChildren<{
  title: string
  element: ReactNode
}>
const WidgetBase = ({
  title,
  children,
  element,
}: WidgetBaseProps): ReactElement => {
  return (
    <>
      <div className={`${scss.container} d-flex flex-column`}>
        <div className={`${scss.header} d-flex justify-content-between`}>
          <h5 className={scss.title}>{title}</h5>
          {element}
        </div>
        <div className='flex-fill'>{children}</div>
      </div>
    </>
  )
}

WidgetBase.defaultProps = {
  element: undefined,
}

export default WidgetBase
