// libraries
import { ReactElement } from 'react'
import _ from 'lodash'

// components
import { Badge, MultiSelect } from 'components/common'

type IssueSeverityOption = {
  value: number
  label: string
  colour: string
  readOnly: boolean
}

type IssueSeverityOptions = IssueSeverityOption[]

const getSeverityOptionLabel = ({ label, colour }: IssueSeverityOption) => {
  return <Badge content={label} backgroundColour={colour} />
}

const IssueSeverityPicker = (props: IssueSeverityOptions): ReactElement => {
  return (
    <MultiSelect
      {...props}
      isMulti={false}
      formatOptionLabel={getSeverityOptionLabel}
      isClearable={false}
    />
  )
}

IssueSeverityPicker.defaultProps = {
  value: undefined, // medium
  onChange: _.noop,
  options: [],
}

export default IssueSeverityPicker
