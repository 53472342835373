import { ReactElement } from 'react'
import { FieldProps, getDisplayLabel } from '@rjsf/utils'

import {
  isJsonFormWidgetHidden,
  isJsonFormWidgetInline,
  isJsonFormWidgetReadonly,
} from 'helpers/formBuilder'

// components
import WidgetWrapper from 'components/common/JsonForm/WidgetWrapper'

import NumberInputFieldContent from './NumberInputFieldContent'

const NumberInputField = (fieldProps: FieldProps): ReactElement => {
  const {
    name,
    required,
    label,
    schema,
    uiSchema,
    registry,
    rawErrors,
    formContext: { isPreview },
  } = fieldProps

  const { rootSchema } = registry
  const displayLabel = getDisplayLabel(schema, uiSchema, rootSchema)

  const isReadonly = isJsonFormWidgetReadonly(uiSchema)
  const isHidden = isJsonFormWidgetHidden(uiSchema)
  const isInline = !isReadonly && isJsonFormWidgetInline(uiSchema)
  const hasError = rawErrors?.length > 0

  return isHidden ? (
    <></>
  ) : (
    <WidgetWrapper
      label={displayLabel ? label || schema.title : undefined}
      description={schema.description}
      required={required}
      name={name}
      rawErrors={rawErrors}
      inline={isInline}
      isLarge={isPreview}
    >
      <NumberInputFieldContent {...fieldProps} hasError={hasError} />
    </WidgetWrapper>
  )
}

export default NumberInputField
