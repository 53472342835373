import { useParams, Params } from 'react-router-dom'
import DOMPurify from 'dompurify'
import _ from 'lodash'

export const getSanitizedParams = (
  params: Params<string>
): Record<string, string | undefined> => {
  return _.mapValues(params, value =>
    value ? DOMPurify.sanitize(value) : value
  )
}

const useSanitizedParams = (): Record<string, string | undefined> => {
  const params = useParams()
  return getSanitizedParams(params)
}

export default useSanitizedParams
