import { LAST_UPDATED_TIME_FIELD } from 'app/MissionControlMethaneSolution/constants/detection'
import type { ListConditions } from 'types/common'

const useBackendSortEnabled = ({
  listConditions,
  settingsBackendSortEnabled,
}: {
  listConditions: ListConditions
  settingsBackendSortEnabled: boolean
}) => {
  const { sortField } = listConditions || {}
  const isSortedByLastUpdated = sortField === LAST_UPDATED_TIME_FIELD

  // The exception – if table is sorted by 'Last updated', keep the client sort,
  // so the list can be immediately re-sorted if 'lastUpdatedTime' was updated by any user action
  return !isSortedByLastUpdated && settingsBackendSortEnabled
}

export default useBackendSortEnabled
