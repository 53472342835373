import { ReactElement, FocusEventHandler } from 'react'
import { WidgetProps, schemaRequiresTrueValue } from '@rjsf/utils'
import _ from 'lodash'

import WidgetWrapper from 'components/common/JsonForm/WidgetWrapper'
import { Toggle } from 'components/common'

const ToggleWidget = ({
  schema,
  id,
  name,
  value,
  disabled,
  readonly,
  label,
  required,
  rawErrors,
  onChange,
  onBlur,
  onFocus,
  formContext: { isPreview },
}: WidgetProps): ReactElement => {
  const requiredBySchema = schemaRequiresTrueValue(schema)

  const handleBlur: FocusEventHandler<HTMLInputElement> = ({
    target: { checked },
  }) => onBlur(id, checked)

  const handleFocus: FocusEventHandler<HTMLInputElement> = ({
    target: { checked },
  }) => onFocus(id, checked)

  return (
    <WidgetWrapper
      name={name}
      label={label}
      required={required || requiredBySchema}
      rawErrors={rawErrors}
      isLarge={isPreview}
      inline
    >
      <Toggle
        id={id}
        checked={_.isUndefined(value) ? false : Boolean(value)}
        disabled={disabled || readonly}
        labelPlacement='left'
        onToggle={onChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
    </WidgetWrapper>
  )
}

export default ToggleWidget
