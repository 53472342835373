import styled from '@emotion/styled'
import { css } from '@emotion/react'

import {
  BUTTON_BORDER_RADIUS,
  BUTTON_VARIANTS,
} from 'components/common/Button/constants'
import Button, { ButtonProps } from 'components/common/Button'

export const StyledButton = styled(Button)<ButtonProps>`
  flex: 1 1 auto;
  border-radius: 0;

  &:first-of-type {
    border-top-left-radius: ${BUTTON_BORDER_RADIUS};
    border-bottom-left-radius: ${BUTTON_BORDER_RADIUS};
  }
  &:not(:last-child) {
    border-right: transparent;
  }
  &:last-child {
    border-top-right-radius: ${BUTTON_BORDER_RADIUS};
    border-bottom-right-radius: ${BUTTON_BORDER_RADIUS};
  }

  ${({ variant, theme }) =>
    variant === BUTTON_VARIANTS.primary
      ? css`
          // Same as for a 'secondary' btn
          box-shadow: 0px 1px 3px #0000000d;
          border-left-color: ${theme['primary-600']};
        `
      : ''}
`
