// libraries
import React, { memo, useState, useEffect } from 'react'
import moment from 'moment-timezone'
import _ from 'lodash'
import { useNetworkState } from 'react-use'
import PropTypes from 'prop-types'

// utils
import { useStateValue } from 'contexts'
import { MESSAGE_TYPES } from 'constants/message'

// style
import scss from './index.module.scss'

// Everything connected and is working (blue dot) with Live
const LiveStatus = () => {
  return (
    <li className='d-flex align-items-center'>
      <span className={scss.text}>Online</span>
      <span className={`${scss.dot} ${scss.live}`} />
    </li>
  )
}

/**
 * One or multiple layers failed (orange dot)
 * with the name of failed layers/datasets
 */
const PartiallyLiveStatus = memo(({ status }) => {
  const failedDatasets = _.filter(status, ['type', MESSAGE_TYPES.error]) || {}
  return (
    failedDatasets &&
    failedDatasets.map(item => (
      <li key={item.dataset} className='d-flex align-items-center'>
        <span className={scss.text}>{item.dataset} offline</span>
        <span className={`${scss.dot} ${scss.warning}`} />
      </li>
    ))
  )
})

PartiallyLiveStatus.propTypes = {
  status: PropTypes.arrayOf(PropTypes.shape({ type: PropTypes.string }))
    .isRequired,
}

// All failed (red dot) with last known updated time
const OfflineStatus = memo(({ status }) => {
  const lastUpdated = _.orderBy(status, ['timestamp'], ['desc'])[0] || {}
  const { timestamp } = lastUpdated
  const time = timestamp && moment(timestamp).format('LTS')

  return (
    <li className='d-flex align-items-center'>
      {time && <span>Last Updated: {time}</span>}
      <span className={`${scss.dot} ${scss.offline}`} />
    </li>
  )
})

OfflineStatus.propTypes = {
  status: PropTypes.arrayOf(PropTypes.shape({ timestamp: PropTypes.string }))
    .isRequired,
}

const StatusBar = () => {
  const { online } = useNetworkState()
  const {
    selectors: { unipipeSelectors },
  } = useStateValue()
  const { toasts } = unipipeSelectors

  const [status, setStatus] = useState()
  const [datasetsNum, setDatasetsNum] = useState(0)
  const [failedDatasetNum, setFailedDatasetNum] = useState(0)

  useEffect(() => {
    const groupByDataset = _.groupBy(toasts, 'dataset')

    const datasetsLatestStatus = _.map(
      groupByDataset,
      values => _.orderBy(values, ['timestamp'], ['desc'])[0]
    )

    setDatasetsNum(datasetsLatestStatus.length)
    setFailedDatasetNum(
      _.filter(datasetsLatestStatus, ['type', MESSAGE_TYPES.error]).length
    )
    setStatus(datasetsLatestStatus)
  }, [toasts])

  const bar =
    !online || (datasetsNum > 0 && failedDatasetNum === datasetsNum) ? (
      <OfflineStatus status={status} />
    ) : failedDatasetNum === 0 ? (
      <LiveStatus />
    ) : (
      <PartiallyLiveStatus status={status} />
    )

  return (
    <div className={scss.statusBar}>
      <ul>{bar}</ul>
    </div>
  )
}

export default StatusBar
