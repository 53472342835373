// libraries
import toast from 'react-hot-toast'

// components
import Toast from 'components/common/Toast'

// constants
import { TYPE_NOTIFICATION_CLOSE_TIME } from '../constants'

import type { CustomToastOptions, ToastProps } from '../type'

/**
 * A function that creates a custom toast using given options.
 * @param {ToastProps} options - The options for the toast to be created.
 * @returns {string} - The id of the toast created.
 */

export const makeToast = (options: ToastProps): string => {
  if (!options) throw new Error('Options cannot be null or undefined')

  const { toastOptions, ...toastProps } = options

  const customToastOptions: CustomToastOptions = {
    ...toastOptions,
    duration:
      toastOptions?.duration ?? TYPE_NOTIFICATION_CLOSE_TIME[options.type],
    toastCustomType: options.type,
  }

  return toast.custom(
    ({ id, visible }) => <Toast id={id} visible={visible} {...toastProps} />,
    customToastOptions
  )
}

/**
 *  A function that dismisses a toast with the given id.
 *  @param {string} id - The id of the toast to be dismissed.
 */

export const dismissToast = (id: string): void => {
  if (!id) throw new Error('Id cannot be null or undefined')

  toast.dismiss(id)
}

export const dismissAllToasts = (): void => {
  toast.dismiss()
}
