import { useCallback, ReactElement } from 'react'

// components
import * as Widgets from 'components/assets/assetsProfile/widgets'

// constants
import { ASSET_PROFILE_WIDGET_CONFIGS } from 'constants/assets'
import { THEMES } from 'constants/colour'

// utils
import log, { reportErrors } from 'helpers/log'

import type { Widget } from 'types/asset'

// components
import BasePanel from '../BasePanel'

const AssetProfilePanelContent = ({
  selectedWidget,
  theme = THEMES.dark,
  mediaType,
}: {
  selectedWidget: Widget
  theme?: string
  mediaType: string
}): ReactElement => {
  const { widget = '', id } = selectedWidget

  const { widgetName } = ASSET_PROFILE_WIDGET_CONFIGS[widget] || {}

  const renderWidgetPanel = useCallback(() => {
    const props = {
      key: id,
      widget: selectedWidget,
      theme,
      mediaType,
    }

    const widgetPanelName = `${widgetName}Panel`
    const WidgetPanel = Widgets[widgetPanelName]

    let ProfileWidgetPanel = WidgetPanel
    if (!WidgetPanel) {
      const message = `Asset widget panel not found`
      reportErrors(`${widget}: ${message}.`, selectedWidget)
      log.error({ Widgets })
      ProfileWidgetPanel = () => message
    }

    return (
      <BasePanel widget={selectedWidget}>
        <ProfileWidgetPanel {...props} />
      </BasePanel>
    )
  }, [id, selectedWidget, theme, mediaType, widgetName, widget])

  return <>{widgetName && renderWidgetPanel()}</>
}

export default AssetProfilePanelContent
