// libraries
import { ReactElement } from 'react'

// constants
import { MODAL_SIZES } from 'constants/settings'

// components
import { Modal } from 'components/common'
import AncillaryDataEditItem, {
  AncillaryDataEditItemProps,
} from 'components/ancillaryData/EditItem'

import scss from './index.module.scss'

const AncillaryDataModal = ({
  ancillaryData,
  ...rest
}: AncillaryDataEditItemProps): ReactElement => {
  const { isShowing, onCancel } = rest
  return (
    <Modal
      key={ancillaryData?.id}
      isShowing={isShowing}
      hide={onCancel}
      size={MODAL_SIZES.large}
      bodyClassName={scss.container}
      scrollable
    >
      <AncillaryDataEditItem {...rest} ancillaryData={ancillaryData} />
    </Modal>
  )
}

export default AncillaryDataModal
