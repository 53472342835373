// libraries
import { useMemo, ReactElement, ReactNode } from 'react'
import { useLocation } from 'react-router-dom'

// constants
import { PRINT_PDF } from 'constants/assets'

// styles
import scss from './index.module.scss'

const FullPageLayout = ({
  children,
}: {
  children: ReactNode
}): ReactElement => {
  const location = useLocation()
  const { search } = location
  const printFlag = useMemo(() => {
    return search === PRINT_PDF
  }, [search])

  return <div className={printFlag ? scss.appPrint : scss.app}>{children}</div>
}

export default FullPageLayout
