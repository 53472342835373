import { ReactElement, useMemo, useCallback } from 'react'

// types
import type { UtcISOString } from 'types/datetime'
import type { Option } from 'types/common'

// constants
import { DATE_UNIT_TYPES } from 'constants/datetime'

// components
import { MultiSelect } from 'components/common'
import { DateRangePicker } from 'components/common/DateTime'

// helpers
import { getFutureUtcDate, isValidISODatetimeString } from 'helpers/datetime'
import { useTimezone } from 'hooks'

import scss from './index.module.scss'
import { FilterCustomOption } from '../FilterDropdown/components'

const Control = () => <></>

export type DateTimeFilterBodyProps = {
  value?: [string?, string?]
  onTimeChange: (newTimeValue: [string, string]) => void
  formatOptionLabel?: (v: unknown) => string
  options: Option[]
  maxDaysRange?: number
}

const DateTimeFilterBody = ({
  value,
  onTimeChange,
  formatOptionLabel,
  options,
  maxDaysRange = 31,
}: DateTimeFilterBodyProps): ReactElement => {
  const { timezone } = useTimezone()

  const [startTime, endTime] = value || []

  const isTimeRelative = useMemo(
    () => !isValidISODatetimeString(startTime),
    [startTime]
  )

  const getMaxDate = useCallback(
    (minDate: UtcISOString) => {
      if (maxDaysRange === Infinity) {
        return undefined
      }
      return getFutureUtcDate({
        interval: maxDaysRange,
        intervalUnit: DATE_UNIT_TYPES.days,
        baseDatetime: minDate,
      })?.toISOString()
    },
    [maxDaysRange]
  )

  return (
    <>
      <DateRangePicker
        startDate={isTimeRelative ? undefined : startTime}
        endDate={isTimeRelative ? undefined : endTime}
        onChange={v => {
          const { startDate, endDate } = v || {}
          const newTimeValue = [startDate, endDate]
          onTimeChange(newTimeValue)
        }}
        timezone={timezone}
        className={scss.timePicker}
        getMaxDate={getMaxDate}
        inputText='Custom Range'
      />
      <MultiSelect
        autoFocus
        isSearchable={false}
        components={{
          Option: FilterCustomOption(formatOptionLabel),
          Control,
        }}
        backspaceRemovesValue={false}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        menuIsOpen
        isClearable={false}
        useOptionValueOnly
        onChange={(option: string) => {
          onTimeChange([option])
        }}
        options={options}
        tabSelectsValue={false}
        value={isTimeRelative ? startTime : undefined}
        isMulti={false}
        zIndex={1}
      />
    </>
  )
}

export default DateTimeFilterBody
