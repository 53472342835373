// libraries
import { FC, ReactElement, useEffect, useState } from 'react'

// components
import { CloseIcon } from 'components/icons'
import MessageContent from 'components/common/Message/MessageContent'

// constants
import {
  DEFAULT_ICONS,
  ANIMATION_DURATION,
} from 'components/common/Message/constants'

import type {
  MessageCloseButtonProps,
  MessageProps,
} from 'components/common/Message/type'

// styles
import {
  MessageClose,
  MessageContainer,
} from 'components/common/Message/styles'

export const MessageCloseButton: FC<MessageCloseButtonProps> = ({
  onClick,
}): ReactElement => (
  <MessageClose type='button' onClick={onClick}>
    <CloseIcon width={12} height={12} />
  </MessageClose>
)

const Message: FC<MessageProps> = ({
  id,
  visible = true,
  type,
  icon = DEFAULT_ICONS[type],
  optionalExtraInfo,
  title,
  description,
  buttons,
  outline = true,
  onClose,
  className,
}) => {
  const [visibleMessage, setVisibleMessage] = useState<boolean>(visible)
  const [isAnimatingOut, setIsAnimatingOut] = useState<boolean>(false)

  const handleAlertClose = () => {
    setIsAnimatingOut(true)
  }

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>
    if (isAnimatingOut) {
      timer = setTimeout(() => {
        if (onClose && id) {
          onClose(id)
        }
      }, ANIMATION_DURATION)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [isAnimatingOut, id, onClose])

  return (
    <>
      {visibleMessage && (
        <MessageContainer
          type={type}
          className={className}
          visible={!isAnimatingOut}
          outline={outline}
          onAnimationEnd={() => isAnimatingOut && setVisibleMessage(false)}
        >
          <MessageContent
            icon={icon}
            type={type}
            optionalExtraInfo={optionalExtraInfo}
            title={title}
            description={description}
            buttons={buttons}
          />
          {onClose && <MessageCloseButton onClick={handleAlertClose} />}
        </MessageContainer>
      )}
    </>
  )
}

export default Message
