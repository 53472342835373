// libraries
import React from 'react'
import PropTypes from 'prop-types'

// utils
import {
  useAssetWidgetConfigs,
  useBaseAssetWidgetPanel,
} from 'components/assets/assetsProfile/hooks'

import { AssetWidgetPropTypes } from 'propTypes'

// components
import { ScatterWidgetConfig } from 'components/map/layers/widgets/WidgetSettingTabs/ScatterWidgetTab'

const AssetScatterWidgetPanel = ({ widget }) => {
  const {
    relatedAssetRelationshipId,
    renderAssetToggleAndRelatedMultiSelect,
    propertyOptions,
  } = useBaseAssetWidgetPanel({ widget, onlyEnableRelatedAssets: true })

  const { settings } = widget

  const { updateWidgetConfigsSettings } = useAssetWidgetConfigs(widget)

  return (
    <>
      {renderAssetToggleAndRelatedMultiSelect()}
      {relatedAssetRelationshipId && (
        <ScatterWidgetConfig
          propertyOptions={propertyOptions}
          settings={settings}
          onChange={updateWidgetConfigsSettings}
          isLayerDatasetLive={false}
        />
      )}
    </>
  )
}

AssetScatterWidgetPanel.propTypes = {
  widget: PropTypes.shape(AssetWidgetPropTypes).isRequired,
}

export default AssetScatterWidgetPanel
