// libraries
import _ from 'lodash'
import update from 'immutability-helper'
import log from 'helpers/log'
import { findUserByName, getUsersOptionsByGroup } from 'helpers/user'

export const USER_SET_LIST = 'USER_SET_LIST'
export const USERS_PAGE_SET_LIST = 'USERS_PAGE_SET_LIST'

const updateUsers = (users, user) => {
  const { username, id } = user
  const index = _.findIndex(users, ['id', username || id])
  if (index < 0) return undefined

  return update(users, {
    [index]: { $set: user },
  })
}

const updateUsersList = dispatch => usersList => {
  dispatch({
    type: USER_SET_LIST,
    usersList,
  })
}

const updateUsersPageList = dispatch => usersPageList => {
  dispatch({
    type: USERS_PAGE_SET_LIST,
    usersPageList,
  })
}

const userActions = ({ state, dispatch }) => {
  return {
    updateUsersList: updateUsersList(dispatch),
    updateUsersPageList: updateUsersPageList(dispatch),
    updateUser: user => {
      if (!user) {
        log.warn('The user to be updated is invalid')
        return
      }

      const { usersList, usersPageList } = state

      const users = updateUsers(usersList, user)
      if (users) {
        updateUsersList(dispatch)(users)
      }

      const filteredUsers = updateUsers(usersPageList, user)
      if (filteredUsers) {
        updateUsersPageList(dispatch)(filteredUsers)
      }
    },
    findUserByName: username => {
      const { usersList } = state
      return findUserByName(usersList, username)
    },

    getUserOptions: (excludeUsername, group) => {
      const { usersList } = state

      const groupUsersOptions = getUsersOptionsByGroup(usersList, group)
      return _.reject(groupUsersOptions, ['value', excludeUsername])
    },
  }
}

export default userActions
