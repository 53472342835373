import React, { useEffect, useCallback } from 'react'
import { useToggle, useAsyncFn } from 'react-use'
import { map } from 'awaity/esm'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import PropTypes from 'prop-types'

// utils
import { useTimezone } from 'hooks'
import { getLayerFilteredData } from 'helpers/filter'
import { getDatasetOptionByDatasetNameAndCatalogId } from 'helpers/unipipe'
import { isSelectedWidgetActive } from 'helpers/widget'
import { useStateValue, useMapStateValue } from 'contexts'

// components
import { ZoidDatasetPlugin, IconButton } from 'components/common'

import scss from './index.module.scss'

const MapDataTabs = ({ id, data }) => (
  <Tabs key={id} selectedTabClassName={scss.selectedTab} forceRenderTabPanel>
    <TabList>
      {data.map(({ layer }) => (
        <Tab className={scss.tab} key={`tab-${layer.id}`}>
          {layer.name}
        </Tab>
      ))}
    </TabList>
    {data.map(props => (
      // eslint-disable-next-line react/prop-types
      <TabPanel key={`tabPanel-${props.id}-${props.tag}`}>
        <ZoidDatasetPlugin {...props} />
      </TabPanel>
    ))}
  </Tabs>
)

MapDataTabs.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})),
}

MapDataTabs.defaultProps = {
  data: [],
}

const MapLayerDatasetWidget = ({
  url,
  tag,
  iconCode,
  pluginParams = {},
  widgetId,
  title,
  layers,
  size,
  iconClassName,
}) => {
  const [isShowing, toggleIsShowing] = useToggle(false)

  const {
    state: {
      unipipeState: { loading },
    },
    selectors: {
      unipipeSelectors: { mapEligibleDatasetOptions },
    },
  } = useStateValue()

  const { timezone } = useTimezone()

  const {
    map: { name: mapName },
    getLayersFilteredData,
    viewportBounds,
    isSpatialFilterEnabled,
    setMaxWidgetSelected,
    maxWidgetSelected,
  } = useMapStateValue()

  const togglePlugin = useCallback(
    (data, keepPlugin) => {
      const getDatasetInfo = ({ dataset, catalogId }) => {
        const datasetOption = getDatasetOptionByDatasetNameAndCatalogId(
          mapEligibleDatasetOptions,
          dataset,
          catalogId
        )
        return datasetOption
      }
      const getPluginConfig = () => ({
        Component: MapDataTabs,
        widgetProps: {
          data: data.map(original => ({
            ...original,
            url,
            tag,
            pluginParams,
            contextParams: {
              datasetInfo: getDatasetInfo(original.layer),
              timezone,
            },
          })),
          id: widgetId,
          title: mapName,
        },
      })

      setMaxWidgetSelected(oldMaxWidgetSelected => {
        return !keepPlugin &&
          isSelectedWidgetActive(widgetId, oldMaxWidgetSelected)
          ? null
          : getPluginConfig()
      })
    },
    [
      setMaxWidgetSelected,
      widgetId,
      mapName,
      url,
      tag,
      pluginParams,
      timezone,
      mapEligibleDatasetOptions,
    ]
  )

  const [dataState, getPlugin] = useAsyncFn(
    async keepPlugin => {
      if (loading) return undefined

      const layersFilteredData = getLayersFilteredData()

      const getTableData = async layer => {
        const layerData = getLayerFilteredData({
          layer,
          layersFilteredData,
          bbox: viewportBounds,
          isSpatialFilterEnabled,
        })

        return layerData
      }

      const getTableId = id => `table-${id}`

      const result = await map(layers, async layer => {
        const data = await getTableData(layer)
        const id = getTableId(layer.id)
        return {
          id,
          title,
          data,
          layer,
        }
      })

      togglePlugin(result, keepPlugin)

      return result
    },
    [loading, layers, timezone, viewportBounds]
  )

  useEffect(() => {
    if (!isShowing || !isSelectedWidgetActive(widgetId, maxWidgetSelected))
      return

    getPlugin(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getLayersFilteredData()])

  const isLoading = loading || dataState.loading
  return (
    <IconButton
      className={`${iconClassName} ${isLoading ? 'fa-spin' : ''}`}
      icon={isLoading ? 'FaSpinner' : iconCode}
      size={size}
      {...(!isLoading && {
        onClick: () => {
          const shouldShowTable = !isShowing || !maxWidgetSelected
          if (shouldShowTable) {
            getPlugin()
          } else {
            setMaxWidgetSelected(null)
          }
          toggleIsShowing(shouldShowTable)
        },
      })}
    />
  )
}

MapLayerDatasetWidget.propTypes = {
  url: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
  iconCode: PropTypes.string.isRequired,
  pluginParams: PropTypes.shape({}),
  widgetId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  layers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      dataset: PropTypes.string,
    })
  ).isRequired,
  size: PropTypes.number,
  iconClassName: PropTypes.string,
}

MapLayerDatasetWidget.defaultProps = {
  size: 16,
  iconClassName: undefined,
}

const MapLayerDatasetPlugin = props => {
  const {
    map: { id: mapId, name: mapName, layers },
  } = useMapStateValue()
  return (
    <MapLayerDatasetWidget
      {...props}
      key={mapId}
      widgetId={mapId}
      title={mapName}
      layers={layers}
    />
  )
}

export default MapLayerDatasetPlugin
