// libraries
import _ from 'lodash'

// constants
import { PROPERTY_TIME } from 'constants/common'

// utils
import {
  getUtcDateTimeString,
  getMaximumTimeRange,
  isDateTimeRangeValid,
  isTimeValidWithIsoFormat,
} from 'helpers/datetime'
import { isHistoricalDataset, getLayerSpecParams } from 'helpers/unipipe'

import type { Payload } from 'types/common'
import type { MapLayer, MapLayerDataState } from 'types/map'
import type { DateTimeRangeObj } from 'types/datetime'

export const getValidSelectedDateTimeRange = ({
  selectedDateTimeRange,
  dateTimeRange,
}: {
  selectedDateTimeRange: Partial<DateTimeRangeObj>
  dateTimeRange: Partial<DateTimeRangeObj>
}): Partial<DateTimeRangeObj> => {
  const timeRange = _.isEmpty(selectedDateTimeRange)
    ? dateTimeRange
    : selectedDateTimeRange
  return timeRange || {}
}

export const getLayerDatetimeRange = (
  layer: MapLayer
): Partial<DateTimeRangeObj> => {
  if (!layer) return {}
  const { startTime, endTime } = getLayerSpecParams(layer)
  return isDateTimeRangeValid(startTime, endTime)
    ? { start: startTime, end: endTime }
    : {}
}

export const getLayerSelectedDatetimeRange = (
  layer: MapLayer,
  selectedDateTimeRange: DateTimeRangeObj
): Partial<DateTimeRangeObj> => {
  const dateTimeRange = getLayerDatetimeRange(layer)
  const { timeliness } = layer
  const { start, end } = isHistoricalDataset(timeliness)
    ? getValidSelectedDateTimeRange({
        selectedDateTimeRange,
        dateTimeRange,
      })
    : {}

  const startTime = getUtcDateTimeString(start)
  const endTime = getUtcDateTimeString(end)

  return { start: startTime, end: endTime }
}

export const getLayersAvailableDatetimeRange = (
  layers: MapLayer[]
): Partial<{ startTime: string; endTime: string }> =>
  _.reduce(
    layers,
    (acc, layer) => {
      const { start, end } = layer.dateTimeRange || {}
      return start && end
        ? _.isEmpty(acc)
          ? { startTime: start, endTime: end }
          : getMaximumTimeRange(acc, { startTime: start, endTime: end })
        : acc
    },
    {}
  )

export const getLayersUserSettingDatetimeRange = (
  layers: MapLayer[]
): Partial<{ startTime: string; endTime: string }> =>
  _.reduce(
    layers,
    (acc, layer) => {
      const { startTime, endTime } = layer.specParams || {}
      return isTimeValidWithIsoFormat(startTime) &&
        isTimeValidWithIsoFormat(endTime)
        ? _.isEmpty(acc)
          ? { startTime, endTime }
          : getMaximumTimeRange(acc, { startTime, endTime })
        : acc
    },
    {}
  )

export const updateLayerAvailableDataDatetimeRange = (
  mapLayers: MapLayer[],
  layersData: Payload<MapLayerDataState>
): MapLayer[] => {
  return mapLayers.map(layer => {
    const { id, timeliness } = layer
    const layerData = layersData[id]
    const dataWithoutTimeFilter = _.get(
      layerData,
      'filteredDataWithoutTimeFilter'
    )
    if (_.isEmpty(dataWithoutTimeFilter) || !isHistoricalDataset(timeliness))
      return { ...layer, dateTimeRange: {} }

    const timeKey = PROPERTY_TIME
    const sortedByTime = _.sortBy(dataWithoutTimeFilter, [timeKey])
    const firstRowTime = _.get(_.first(sortedByTime), timeKey)
    const lastRowTime = _.get(_.last(sortedByTime), timeKey)
    if (!firstRowTime || !lastRowTime) return { ...layer, dateTimeRange: {} }

    const start = getUtcDateTimeString(firstRowTime)
    const end = getUtcDateTimeString(lastRowTime)
    return { ...layer, dateTimeRange: { start, end } }
  })
}
