// libraries
import { ReactElement } from 'react'

// constants
import { WIDGET_TYPES } from 'constants/widget'

// components
import WidgetIcon from 'components/widget/common/WidgetIcon'

// style
import { MapLayerProfileWidgetType } from 'types/map'
import scss from './index.module.scss'

const NoAvailableWidget = ({
  widgetType,
  content,
}: {
  widgetType: MapLayerProfileWidgetType
  content: string
}): ReactElement => {
  return (
    <div className={scss.center}>
      <WidgetIcon widgetType={WIDGET_TYPES[widgetType]} className={scss.icon} />
      <div className={scss.text}>{content}</div>
    </div>
  )
}

NoAvailableWidget.defaultProps = {
  widgetType: WIDGET_TYPES.list,
  content: undefined,
}

export default NoAvailableWidget
