import { MAP_SET_LIST } from 'contexts/actions/map'
import { switchcaseF } from 'helpers/utils'

export const mapState = {
  mapsList: [],
}

const mapReducer = (state, action) => {
  return switchcaseF({
    [MAP_SET_LIST]: () => ({
      ...state,
      mapsList: action.mapsList,
    }),
  })(() => state)(action.type)
}

export default mapReducer
