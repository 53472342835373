import { APP_POSSIBLE_ERROR_MESSAGES } from './constants'

export const handleErrorSideEffects = ({
  message,
  refreshPage,
}: {
  message: string
  refreshPage: () => void
}) => {
  switch (true) {
    // If a session is expired, automatically refresh the page
    case message.includes(APP_POSSIBLE_ERROR_MESSAGES.EXPIRED_SESSION): {
      refreshPage()
      break
    }
    default:
      break
  }
}
