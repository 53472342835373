// libraries
import { useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

// constants
import { DEFAULT_AGGREGATION_KEY_TYPE } from 'constants/aggregation'
import { DEFAULT_WIDGET_COLOUR } from 'constants/widget'
import { THEMES } from 'constants/colour'
import { ASSET_PROFILE_MEDIA_TYPES } from 'constants/assets'

// utils
import {
  useAssetWidgetConfigs,
  useBaseAssetWidgetPanel,
} from 'components/assets/assetsProfile/hooks'
import { getObjectHash } from 'helpers/utils'

// components
import {
  DataAggregation,
  ColourPickerSimple,
  TextInput,
  RichTextEditor,
} from 'components/common'

const AssetTextWidgetPanel = ({
  widget,
  enableAggregationRange,
  mediaType,
}) => {
  const {
    enableRelationship,
    renderAssetToggleAndRelatedMultiSelect,
    isAssetRelationshipMany,
    propertyOptions,
  } = useBaseAssetWidgetPanel({ widget })

  const {
    id,
    settings: {
      textTemplate = '',
      colour = DEFAULT_WIDGET_COLOUR,
      overridePropertyTitle,
      relatedAssetAggregation = DEFAULT_AGGREGATION_KEY_TYPE,
      relatedAssetAggregationValueRange = [],
    },
  } = widget

  const { updateWidgetConfigsSettings } = useAssetWidgetConfigs(widget)

  const aggregationConfigs = {
    relatedAssetAggregation,
    relatedAssetAggregationValueRange,
  }

  const editorId = useMemo(
    () => `${id}-textTemplate-${getObjectHash(propertyOptions)}`,
    [id, propertyOptions]
  )

  const renderTextTemplate = () => (
    <div className='groupOption'>
      <div className='groupOptionTitle'>Text template</div>
      <div className='mt-2'>
        <RichTextEditor
          key={editorId}
          id={editorId}
          name='textTemplate'
          value={textTemplate}
          onChange={val => {
            if (val === textTemplate) return
            updateWidgetConfigsSettings({
              textTemplate: val,
            })
          }}
          popoverSuggestions={propertyOptions}
          suggestions={propertyOptions}
          theme={
            mediaType === ASSET_PROFILE_MEDIA_TYPES.WEB
              ? THEMES.dark
              : THEMES.light
          }
        />
      </div>
    </div>
  )

  return (
    <>
      {renderAssetToggleAndRelatedMultiSelect()}
      {enableRelationship ? (
        _.isEmpty(propertyOptions) ? (
          <></>
        ) : isAssetRelationshipMany ? (
          <>
            <div className='groupOption'>
              <div className='groupOptionTitle'>Override Property Title</div>
              <div className='groupOptionContent mb-2'>
                <TextInput
                  className='form-control'
                  value={overridePropertyTitle}
                  placeholder='e.g. Speed (Km/h)'
                  onChange={val =>
                    updateWidgetConfigsSettings({ overridePropertyTitle: val })
                  }
                />
              </div>
            </div>
            <div className='groupOption'>
              <div className='groupOptionContent'>
                <DataAggregation
                  {...(enableAggregationRange ? {} : { hideMinMax: true })}
                  className='mt-0'
                  tooltip='Aggregation'
                  propertyOptions={propertyOptions}
                  valueRangeIdentifier='relatedAssetAggregationValueRange'
                  aggregationIdentifier='relatedAssetAggregation'
                  aggregationConfigs={aggregationConfigs}
                  onAggregationChangeHandler={(p, val) => {
                    updateWidgetConfigsSettings({ [p]: val })
                  }}
                />
              </div>
            </div>
            <div className='groupOption'>
              <div className='groupOptionTitle'>Select colour</div>
              <div className='groupOptionContent'>
                <ColourPickerSimple
                  colour={colour}
                  onChange={val => updateWidgetConfigsSettings({ colour: val })}
                />
              </div>
            </div>
          </>
        ) : (
          renderTextTemplate()
        )
      ) : (
        renderTextTemplate()
      )}
    </>
  )
}

AssetTextWidgetPanel.propTypes = {
  widget: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    settings: PropTypes.shape({
      textTemplate: PropTypes.string,
      colour: PropTypes.arrayOf(PropTypes.number),
      assetProfileId: PropTypes.string.isRequired,
      relatedAssetRelationshipId: PropTypes.string,
      property: PropTypes.string,
      overridePropertyTitle: PropTypes.string,
      relatedAssetAggregation: PropTypes.shape({}),
      relatedAssetAggregationValueRange: PropTypes.array,
    }).isRequired,
  }).isRequired,
  enableAggregationRange: PropTypes.bool,
  mediaType: PropTypes.string.isRequired,
}

AssetTextWidgetPanel.defaultProps = {
  enableAggregationRange: false,
}

export default AssetTextWidgetPanel
