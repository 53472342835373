import _ from 'lodash'
import { useState, useCallback } from 'react'
import { useGetSet } from 'react-use'

const useMapSelectedFeaturesState = () => {
  /**
   * The feature that found from searched bar
   */
  const [searchFeatureSelected, setSearchFeatureSelected] = useState()

  /**
   * Features from all layers for autosuggestion
   */
  const [getMapSuggestions, setMapSuggestions] = useGetSet([])

  /**
   * Feature from click event
   */
  const [getMapClickedFeatures, setMapClickedFeatures] = useGetSet([])

  const getHighlightedObjectIndexFromSelectedSearchFeature = useCallback(
    ({ layerData, layerId }) => {
      const idKey = 'layerId'
      // highlightedObjectIndex: null allow the deck.gl to auto highlight
      // highlightedObjectIndex: >=0 when we want the deckgl to highlight feature
      // highlightedObjectIndex: -1 allow deck.gl to cancel the highlight (that was controlled by us)
      const highlightedObjectIndex = searchFeatureSelected
        ? searchFeatureSelected[idKey] === layerId
          ? _.findIndex(layerData, data => {
              const { name, time } = data.properties || {}
              return (
                name === searchFeatureSelected.name &&
                time === searchFeatureSelected.time
              )
            })
          : -1
        : null

      if (highlightedObjectIndex === -1 && _.isEmpty(searchFeatureSelected)) {
        setSearchFeatureSelected(null)
      }
      return highlightedObjectIndex
    },
    [searchFeatureSelected, setSearchFeatureSelected]
  )

  const getHighlightedAssetIndexFromSelectedSearchFeature = useCallback(
    ({ layerData }) => {
      return searchFeatureSelected
        ? _.findIndex(layerData, { id: searchFeatureSelected?.assetId })
        : -1
    },
    [searchFeatureSelected]
  )

  return {
    searchFeatureSelected,
    setSearchFeatureSelected,
    getMapClickedFeatures,
    setMapClickedFeatures,
    getMapSuggestions,
    setMapSuggestions,
    getHighlightedObjectIndexFromSelectedSearchFeature,
    getHighlightedAssetIndexFromSelectedSearchFeature,
  }
}

export default useMapSelectedFeaturesState
