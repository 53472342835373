// libraries
import _ from 'lodash'

// utils
import { getArgs, getQueryFields } from 'helpers/graphql'
import {
  listEntitiesGraphql,
  getEntitiesQuery,
  MutateEntity,
  mutateEntity,
} from 'services/api/utils'
import { stringToBoolean } from 'helpers/utils'

import {
  SiteReference,
  Equipment,
  CamAssetReference,
  CamAsset,
} from 'app/MissionControlMethaneSolution/types/graphql'
import type {
  DetectionData,
  DetectionSite,
} from 'app/MissionControlMethaneSolution/types/detection'
import type { QueryParams, SelectedFields } from 'types/services'

import {
  deserializeEmissionObservation,
  getEmissionObservationFields,
} from './emissionObservation'

const DOMAIN = 'detection'
const queryDomain = `${DOMAIN}s`

export const deserializeSite = (
  site?: SiteReference['site'] | CamAsset,
  asset?: CamAssetReference['asset']
): DetectionSite => {
  if (asset) {
    const { assetReference, geometryJson, properties } = asset
    return {
      assetReference,
      properties: {
        ...properties,
        // currently all properties are VARCHAR
        // https://sensorup-inc.slack.com/archives/C06QCP80K50/p1712088554998899?thread_ts=1712088429.473259&cid=C06QCP80K50
        isDAC: stringToBoolean(properties.isDAC),
      },
      name: properties?.name ?? '',
      ...(geometryJson && {
        observations: [{ type: 'Feature', properties, geometry: geometryJson }],
      }),
      isAssetSite: true,
      id: properties?.id,
    }
  }

  const { properties, observations, outlier } = site ?? {}
  return {
    ...site,
    observations: _.map(observations, d => ({
      ...d,
      featureType: d.properties.entityType,
    })),
    isOutlier: outlier as boolean,
    name: properties?.name ?? '',
    properties: {
      ...properties,
      isDAC: true,
    },
  }
}

const mutateDetection =
  ({
    pickFields,
    omitFields,
    ...restProps
  }: Omit<MutateEntity, 'queryDomain'> & SelectedFields) =>
  ({
    id,
    fieldsWithArguments,
    ...rest
  }: { id?: string; fieldsWithArguments?: QueryParams } & QueryParams) =>
    mutateEntity<DetectionData>({
      queryDomain,
      responseFields: {
        [DOMAIN]: getEmissionObservationFields({ pickFields, omitFields }),
      },
      responsePath: [DOMAIN],
      withIdentifier: false,
      ignoreError: true,
      postProcessFn: deserializeEmissionObservation,
      ...restProps,
    })(id, rest, fieldsWithArguments) as Promise<{
      data: DetectionData
      error?: string
    }>

export const createDetection = mutateDetection({
  fnName: 'createDetection',
  variableFormat: 'CreateDetectionInput!',
  pickFields: ['id', 'shortId', 'camAssetReference', 'wip', 'closed'],
})

export const resolveDetection = mutateDetection({
  fnName: 'resolveDetection',
  responseFields: {},
  variableFormat: 'ResolveDetectionInput!',
})

export const getMediaUploadUrlsQuery = (
  files: File[]
): Record<string, unknown> => {
  const mediaKeys = _.map(files, 'name')
  return {
    [DOMAIN]: {
      mediaUploadUrls: {
        ...getArgs({ mediaKeys }),
      },
    },
  }
}

const siteEquipmentQueryDomain = 'equipment'
const siteEquipmentQueryName = 'bySite'
export const getSiteEquipments = listEntitiesGraphql<Equipment>({
  queryDomain: siteEquipmentQueryDomain,
  getQueryFn: getEntitiesQuery({
    queryDomain: siteEquipmentQueryDomain,
    getFieldsFn: getQueryFields({
      id: true,
      description: true,
      components: {
        equipmentComponentName: true,
      },
    }),
    variables: {
      siteId: 'ID!',
    },
    queryName: siteEquipmentQueryName,
  }),
  queryName: siteEquipmentQueryName,
})
