// libraries
import { useCallback } from 'react'
import to from 'await-to-js'
import { useNavigate } from 'react-router-dom'

// constants
import { MESSAGE_STATUS } from 'constants/message'
import { OPERATION_OPTIONS } from 'constants/common'

// utils
import { getRouteUrlWithValues } from 'helpers/utils'
import { showCrudResponseMessage } from 'helpers/message'

import type { Entity } from 'types/entity'
import type { ActionType, MessageStatus, Payload } from 'types/common'
import type { TransferOwner } from 'components/common/Modal/TransferModal'

type EntityName = string

type handleResponseProps<T> = {
  item?: T
  toastId?: string
  status: MessageStatus
  error?: Error | null
  message?: string
  actionType: ActionType
  entity: EntityName
}

type UpdateFn<T> = (v: T, p: Payload) => Promise<T>

export type OnListItemChange<T> = (type: string, payload?: Partial<T>) => void

const useListItemActions = <T extends Entity>({
  entity,
  onChange,
}: {
  entity: EntityName
  onChange?: OnListItemChange<T>
}): {
  fetchItem: (
    id: string,
    fetchFn: (entityId: string) => Promise<T>
  ) => Promise<T | undefined>
  createItem: (
    item: T,
    createFn: (itemToCreate: T, toastId?: string) => Promise<T>
  ) => Promise<T | undefined>
  updateItem: ({
    item,
    payload,
    updateFn,
    message,
    status = MESSAGE_STATUS.updated,
  }: {
    item: T
    payload: Payload
    updateFn: UpdateFn<T>
    message: string
    status?: MessageStatus
  }) => Promise<T | undefined>
  deleteItem: (
    item: T,
    deleteFn: (id: string, toastId?: string) => Promise<T>
  ) => Promise<Error | null>
  cloneItem: (
    item: T,
    cloneFn: (itemToClone: T, toastId?: string) => Promise<T>
  ) => Promise<T | undefined>
  shareItem: (
    item: T,
    unShare: boolean,
    shareFn: UpdateFn<T>
  ) => Promise<T | undefined>
  editItem: (
    redirectUrl: string,
    payload: Payload,
    getUrlOnly?: boolean,
    navigateState?: Payload
  ) => void
  transferItem: (
    item: T,
    owner: TransferOwner,
    transferFn: UpdateFn<T>
  ) => Promise<T | undefined>
} => {
  const navigate = useNavigate()

  const handleResponse = useCallback(
    ({ item, error, actionType, ...rest }: handleResponseProps<T>): void => {
      showCrudResponseMessage({
        ...rest,
        error,
        subject: item,
      })
      if (!error && onChange) {
        onChange(actionType, item)
      }
    },
    [onChange]
  )

  const fetchItem = useCallback(
    async (id: string, fetchFn: (entityId: string) => Promise<T>) => {
      const [error, item] = await to(fetchFn(id))
      showCrudResponseMessage({
        error,
        entity,
        subject: item,
        status: MESSAGE_STATUS.fetched,
      })
      return item
    },
    [entity]
  )

  const cloneItem = useCallback(
    async (
      item: T,
      cloneFn: (itemToClone: T, toastId?: string) => Promise<T>
    ): Promise<T | undefined> => {
      const [error, clonedItem] = await to(cloneFn(item))
      handleResponse({
        error,
        entity,
        item: clonedItem,
        status: MESSAGE_STATUS.cloned,
        actionType: OPERATION_OPTIONS.clone,
      })
      return clonedItem
    },
    [entity, handleResponse]
  )

  const createItem = useCallback(
    async (
      item: T,
      createFn: (itemToCreate: T, toastId?: string) => Promise<T>
    ): Promise<T | undefined> => {
      const [error, createdItem] = await to(createFn(item))
      handleResponse({
        error,
        entity,
        item: createdItem,
        status: MESSAGE_STATUS.created,
        actionType: OPERATION_OPTIONS.create,
      })
      return createdItem
    },
    [entity, handleResponse]
  )

  const deleteItem = useCallback(
    async (
      item: T,
      deleteFn: (id: string, toastId?: string) => Promise<T>
    ): Promise<Error | null> => {
      const [error] = await to(deleteFn(item.id))
      handleResponse({
        entity,
        item,
        error,
        status: MESSAGE_STATUS.deleted,
        actionType: OPERATION_OPTIONS.delete,
      })
      return error
    },
    [entity, handleResponse]
  )

  const updateItem = useCallback(
    async ({
      item,
      payload,
      updateFn,
      message,
      status = MESSAGE_STATUS.updated,
    }: {
      item: T
      payload: Payload
      updateFn: UpdateFn<T>
      message: string
      status?: MessageStatus
    }) => {
      const [error, newItem] = await to(updateFn(item, payload))

      handleResponse({
        entity,
        item: newItem,
        error,
        status,
        message,
        actionType: OPERATION_OPTIONS.update,
      })
      return newItem
    },
    [entity, handleResponse]
  )

  const shareItem = useCallback(
    (item: T, unShare: boolean, shareFn: UpdateFn<T>) => {
      const payload = {
        isPrivate: unShare,
      }
      const { name } = item
      const message = `${name} ${entity} is ${
        unShare ? 'now private' : 'shared with everyone'
      }.`
      return updateItem({ item, payload, updateFn: shareFn, message })
    },
    [entity, updateItem]
  )

  const transferItem = useCallback(
    (
      item: T,
      owner: TransferOwner,
      transferFn: UpdateFn<T>
    ): Promise<T | undefined> => {
      const { group, user } = owner
      const {
        username: newUserName,
        name: userDisplayName,
        group: userGroup,
      } = user || {}
      const payload = {
        group: group || userGroup,
        username: newUserName,
      }

      const { name } = item || {}
      const message = `${name} ${entity} successfully transferred to ${userDisplayName}.`

      return updateItem({ item, payload, updateFn: transferFn, message })
    },
    [entity, updateItem]
  )

  const editItem = useCallback(
    (
      redirectUrl: string,
      payload: Payload,
      getUrlOnly = false,
      navigateState?: Payload
    ) => {
      const routerUrl = getRouteUrlWithValues(redirectUrl, payload)

      if (!getUrlOnly) {
        navigate(routerUrl, navigateState)
      }
      return routerUrl
    },
    [navigate]
  )

  return {
    fetchItem,
    createItem,
    updateItem,
    deleteItem,
    cloneItem,
    shareItem,
    editItem,
    transferItem,
  }
}

export default useListItemActions
