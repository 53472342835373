import styled from '@emotion/styled'
import { THEMES } from 'constants/colour'

export const StyledTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
`

export const StyledSubtitle = styled.div`
  margin-top: 4px;
  font-size: 14px;
  font-weight: 400;
  color: ${props => (props.theme === THEMES.dark ? 'white' : 'black')};
`

export const StyledSection = styled.div`
  padding: 0 10px;
`
