import React, { forwardRef, useState, useMemo, useCallback } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

// utils
import { isNumericType } from 'helpers/filter'

// constants
import { PROPERTY_VARIABLE_TYPES } from 'constants/filter'
import { DEFAULT_CHART_Y_AXIS_RANGE } from 'constants/widget'

// components
import {
  ListGroupItem,
  PropertiesListSwitchItemDialog,
  IconButton,
} from 'components/common'
import WidgetIcon from 'components/widget/common/WidgetIcon'

import scss from './index.module.scss'

const Item = forwardRef(
  (
    {
      item,
      onChange,
      withWidgetSelect,
      searchIdentifier,
      reservedFields,
      identifier,
      sortableProps,
      listeners,
      disableSort,
    },
    ref
  ) => {
    const {
      isVisible = true,
      name,
      widgetType,
      type,
      range,
      [searchIdentifier]: label,
    } = item || {}

    const [showRegularItem, setShowRegularItem] = useState(true)
    const [showSwitchItemDialog, setShowSwitchItemDialog] = useState(false)

    const onSwitchItemHandler = useCallback(() => {
      if (isNumericType(type)) {
        setShowSwitchItemDialog(true)
      }
    }, [type])

    const isReservedField = useMemo(
      () => _.includes(reservedFields, item[identifier]),
      [identifier, item, reservedFields]
    )

    const withSelect = useMemo(
      () => withWidgetSelect && isNumericType(type),
      [type, withWidgetSelect]
    )

    return (
      <>
        {showRegularItem && (
          <ListGroupItem
            borderLeft
            active={isVisible}
            ref={ref}
            {...sortableProps}
          >
            <div className='d-flex justify-content-between align-items-center w-100'>
              <div className='d-flex align-items-center'>
                <IconButton
                  {...listeners}
                  icon='HandleIcon'
                  style={{ cursor: disableSort ? 'not-allowed' : 'move' }}
                />
                <span
                  className={`flex-grow-1 ms-2 singleLineTruncate text-break ${
                    scss.label
                  } ${label ? '' : 'text-capitalize'}`}
                  style={{ maxWidth: withSelect ? '170px' : '200px' }}
                >
                  {label || name}
                </span>
              </div>
              <div>
                {withSelect && (
                  <IconButton
                    className={scss.widget}
                    disabled={type !== PROPERTY_VARIABLE_TYPES.number}
                    onClick={onSwitchItemHandler}
                  >
                    <WidgetIcon
                      widgetType={widgetType}
                      className={scss.widgetIcon}
                    />
                  </IconButton>
                )}
                {!isReservedField && (
                  <IconButton
                    icon={isVisible ? 'EyeIcon' : 'HideIcon'}
                    {...(!isReservedField && {
                      onClick: () => onChange({ isVisible: !isVisible }),
                    })}
                  />
                )}
              </div>
            </div>
          </ListGroupItem>
        )}
        {withWidgetSelect && (
          <PropertiesListSwitchItemDialog
            widgetType={widgetType}
            showSwitchItemDialog={showSwitchItemDialog}
            setShowSwitchItemDialog={setShowSwitchItemDialog}
            setShowRegularItem={setShowRegularItem}
            onSwitchItem={newWidgetType => {
              onChange({ widgetType: newWidgetType })
            }}
            range={range || DEFAULT_CHART_Y_AXIS_RANGE}
            onWidgetRangeChange={v => {
              onChange({ range: v })
            }}
          />
        )}
      </>
    )
  }
)

Item.propTypes = {
  item: PropTypes.shape({
    isVisible: PropTypes.bool,
    name: PropTypes.string,
    widgetType: PropTypes.string,
    type: PropTypes.string,
    range: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  onChange: PropTypes.func,
  withWidgetSelect: PropTypes.bool,
  searchIdentifier: PropTypes.string,
  reservedFields: PropTypes.arrayOf(PropTypes.string),
  identifier: PropTypes.string,
  sortableProps: PropTypes.shape({}),
  listeners: PropTypes.shape({}),
  disableSort: PropTypes.bool,
}

Item.defaultProps = {
  onChange: _.noop,
  withWidgetSelect: false,
  identifier: 'name',
  searchIdentifier: 'label',
  disableSort: false,
  reservedFields: [],
  sortableProps: undefined,
  listeners: undefined,
}

export default Item
