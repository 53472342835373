import { ReactElement } from 'react'
import _ from 'lodash'

// utils
import { isJsonFormWidgetHidden } from 'helpers/formBuilder'
import type { WidgetProps } from '@rjsf/utils'
import type { DataCollectionFormMedium } from 'types/graphql'

// components
import { SignatureMarkIcon } from 'components/icons'
import scss from './index.module.scss'
import WidgetWrapper from '../WidgetWrapper'
import DisplaySignature from './Signature'

export const getImageUrlFromDataCollectionFormMedia = (
  imageResources: DataCollectionFormMedium[],
  mediaKey: string
): string | undefined =>
  _.get(_.find(imageResources, { mediaKey }), 'downloadUrl')

const SignatureWidget = ({
  name,
  schema,
  uiSchema,
  value,
  required,
  formContext,
  rawErrors,
}: WidgetProps): ReactElement | null => {
  const { timestamp, mediaKey } = value || {}
  const { imageResources, isPreview } = formContext || {}

  const isHidden = isJsonFormWidgetHidden(uiSchema)

  const signatureImageUrl = getImageUrlFromDataCollectionFormMedia(
    imageResources,
    mediaKey
  )

  return isHidden ? null : (
    <WidgetWrapper
      label={schema.title}
      description={schema.description}
      required={required}
      name={name}
      rawErrors={rawErrors}
      isLarge={isPreview}
    >
      {signatureImageUrl ? (
        <DisplaySignature imageUrl={signatureImageUrl} timestamp={timestamp} />
      ) : (
        <div className={scss.placeholderContainer}>
          <div className={scss.placeholder}>
            <SignatureMarkIcon className={scss.mark} />
            <div className='text-secondary'>Signature preview</div>
          </div>
        </div>
      )}
    </WidgetWrapper>
  )
}

export default SignatureWidget
