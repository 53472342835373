import { ReactElement, useCallback, useMemo } from 'react'
import { WidgetProps } from '@rjsf/utils'

import type { DateTimeFieldFormat } from 'types/formBuilder'
import type { DateTimeValue } from 'components/common/DateTime/DateTimePicker'

import { DATE_TIME_FIELD_TYPES } from 'constants/formBuilder'
import { DATE_TIME_COMPARISON_EDITOR_PRECISION } from 'constants/workflow'
import {
  DATE_UNIT_TYPES,
  DEFAULT_DATE_TIME_FORMAT,
  MONTH_DAY_YEAR_FORMAT,
  YEAR_MONTH_DAY_FORMAT,
} from 'constants/datetime'

import { useTimezone } from 'hooks'
import { getSnappedDatetimeStr } from 'helpers/datetime'
import { displayTime } from 'helpers/utils'
import { getWidgetInputDefaultClassNames } from 'helpers/formBuilder'

import { DateTimePicker } from 'components/common/DateTime'

const getPlaceholder = (format: DateTimeFieldFormat): string =>
  `Select ${
    format === DATE_TIME_FIELD_TYPES['date-time'] ? 'date and time' : 'date'
  }`

type DatePickerWidgetWrapperProps = Pick<
  WidgetProps,
  'id' | 'value' | 'disabled' | 'readonly' | 'onChange' | 'onBlur'
> & {
  format: DateTimeFieldFormat
  start?: string
  end?: string
  isLarge?: boolean
}

const DatePickerWidgetWrapper = ({
  id,
  value,
  disabled,
  readonly,
  start,
  end,
  format,
  isLarge,
  onChange,
  onBlur,
}: DatePickerWidgetWrapperProps): ReactElement => {
  const { timezone } = useTimezone()
  const isDateTimeWidget = format === DATE_TIME_FIELD_TYPES['date-time']
  const isDateWidget = format === DATE_TIME_FIELD_TYPES.date

  const handleChange = useCallback(
    (v: DateTimeValue) => {
      const newValue = v.value
      if (newValue && isDateWidget) {
        return onChange(
          // Transform to a format which is acceptable by JsonSchema (eg 2018-11-13)
          displayTime({
            datetime: newValue,
            timeFormat: YEAR_MONTH_DAY_FORMAT,
            displayZoneAbbr: false,
          })
        )
      }
      // On cleanup, transform 'null' to 'undefined' so it will work properly with JsonSchema
      return onChange(newValue === null ? undefined : newValue)
    },
    [onChange, isDateWidget]
  )

  const handleBlur = useCallback(() => onBlur(id, undefined), [id, onBlur])

  const dateValue = useMemo(
    () =>
      !!value && isDateWidget
        ? // Transform to a format which is acceptable by DatePicker (ISOString)
          getSnappedDatetimeStr({
            baseDatetime: value,
            unit: DATE_UNIT_TYPES.minutes,
          })
        : value,
    [value, isDateWidget]
  )

  return (
    <DateTimePicker
      selectedTime={dateValue}
      selectedTimeType={
        isDateTimeWidget
          ? DATE_TIME_COMPARISON_EDITOR_PRECISION.MINUTE
          : DATE_TIME_COMPARISON_EDITOR_PRECISION.DAY
      }
      minDate={start}
      maxDate={end}
      showTimeSelect={isDateTimeWidget}
      timezone={timezone}
      timeFormat={
        isDateTimeWidget ? DEFAULT_DATE_TIME_FORMAT : MONTH_DAY_YEAR_FORMAT
      }
      isDisabled={disabled || readonly}
      addonIcon='DatePickerIcon'
      placeholder={getPlaceholder(format)}
      highlightPlaceholder={false}
      defaultToCurrent={false}
      onChange={handleChange}
      onBlur={handleBlur}
      inputClassName={`${getWidgetInputDefaultClassNames(
        isLarge
      )} d-flex justify-content-between align-items-center`}
    />
  )
}

export default DatePickerWidgetWrapper
