export const rawColoursOptions = [
  {
    label: ['#F7FCB9', '#31A354'],
    value: [
      [247, 252, 185],
      [49, 163, 84],
    ],
  },
  {
    label: ['#EDF8B1', '#2C7FB8'],
    value: [
      [237, 248, 177],
      [44, 127, 184],
    ],
  },
  {
    label: ['#E0F3DB', '#43A2CA'],
    value: [
      [224, 243, 219],
      [67, 162, 202],
    ],
  },
  {
    label: ['#E5F5F9', '#2CA25F'],
    value: [
      [229, 245, 249],
      [44, 162, 95],
    ],
  },
  {
    label: ['#ECE2F0', '#1C9099'],
    value: [
      [236, 226, 240],
      [28, 144, 153],
    ],
  },
  {
    label: ['#ECE7F2', '#2B8CBE'],
    value: [
      [236, 231, 242],
      [43, 140, 190],
    ],
  },
  {
    label: ['#E0ECF4', '#8856A7'],
    value: [
      [224, 236, 244],
      [136, 86, 167],
    ],
  },
  {
    label: ['#FDE0DD', '#C51B8A'],
    value: [
      [253, 224, 221],
      [197, 27, 138],
    ],
  },
  {
    label: ['#E7E1EF', '#DD1C77'],
    value: [
      [231, 225, 239],
      [221, 28, 119],
    ],
  },
  {
    label: ['#FEE8C8', '#E34A33'],
    value: [
      [254, 232, 200],
      [227, 74, 51],
    ],
  },
  {
    label: ['#FFEDA0', '#F03B20'],
    value: [
      [255, 237, 160],
      [240, 59, 32],
    ],
  },
  {
    label: ['#FFF7BC', '#D95F0E'],
    value: [
      [255, 247, 188],
      [217, 95, 14],
    ],
  },
  {
    label: ['#EFEDF5', '#756BB1'],
    value: [
      [239, 237, 245],
      [117, 107, 177],
    ],
  },
  {
    label: ['#DEEBF7', '#3182BD'],
    value: [
      [222, 235, 247],
      [49, 130, 189],
    ],
  },
  {
    label: ['#E5F5E0', '#31A354'],
    value: [
      [229, 245, 224],
      [49, 163, 84],
    ],
  },
  {
    label: ['#FEE6CE', '#E6550D'],
    value: [
      [254, 230, 206],
      [230, 85, 13],
    ],
  },
  {
    label: ['#FEE0D2', '#DE2D26'],
    value: [
      [254, 224, 210],
      [222, 45, 38],
    ],
  },
  {
    label: ['#F0F0F0', '#636363'],
    value: [
      [240, 240, 240],
      [99, 99, 99],
    ],
  },
  {
    label: ['#F1A340', '#998EC3'],
    value: [
      [241, 163, 64],
      [153, 142, 195],
    ],
  },
  {
    label: ['#D8B365', '#5AB4AC'],
    value: [
      [216, 179, 101],
      [90, 180, 172],
    ],
  },
  {
    label: ['#AF8DC3', '#7FBF7B'],
    value: [
      [175, 141, 195],
      [127, 191, 123],
    ],
  },
  {
    label: ['#E9A3C9', '#A1D76A'],
    value: [
      [233, 163, 201],
      [161, 215, 106],
    ],
  },
  {
    label: ['#EF8A62', '#67A9CF'],
    value: [
      [239, 138, 98],
      [103, 169, 207],
    ],
  },
  {
    label: ['#EF8A62', '#999999'],
    value: [
      [239, 138, 98],
      [153, 153, 153],
    ],
  },
  {
    label: ['#FC8D59', '#91BFDB'],
    value: [
      [252, 141, 89],
      [145, 191, 219],
    ],
  },
  {
    label: ['#FC8D59', '#99D594'],
    value: [
      [252, 141, 89],
      [153, 213, 148],
    ],
  },
  {
    label: ['#FC8D59', '#91CF60'],
    value: [
      [252, 141, 89],
      [145, 207, 96],
    ],
  },
  {
    label: ['#7FC97F', '#FDC086'],
    value: [
      [127, 201, 127],
      [253, 192, 134],
    ],
  },
  {
    label: ['#1B9E77', '#7570B3'],
    value: [
      [27, 158, 119],
      [117, 112, 179],
    ],
  },
  {
    label: ['#A6CEE3', '#B2DF8A'],
    value: [
      [166, 206, 227],
      [178, 223, 138],
    ],
  },
  {
    label: ['#FBB4AE', '#CCEBC5'],
    value: [
      [251, 180, 174],
      [204, 235, 197],
    ],
  },
  {
    label: ['#B3E2CD', '#CBD5E8'],
    value: [
      [179, 226, 205],
      [203, 213, 232],
    ],
  },
  {
    label: ['#E41A1C', '#4DAF4A'],
    value: [
      [228, 26, 28],
      [77, 175, 74],
    ],
  },
  {
    label: ['#66C2A5', '#8DA0CB'],
    value: [
      [102, 194, 165],
      [141, 160, 203],
    ],
  },
  {
    label: ['#8DD3C7', '#BEBADA'],
    value: [
      [141, 211, 199],
      [190, 186, 218],
    ],
  },
  {
    label: ['#f7fcb9', '#addd8e', '#31a354'],
    value: [
      [247, 252, 185],
      [173, 221, 142],
      [49, 163, 84],
    ],
  },
  {
    label: ['#ffffcc', '#c2e699', '#78c679', '#238443'],
    value: [
      [255, 255, 204],
      [194, 230, 153],
      [120, 198, 121],
      [35, 132, 67],
    ],
  },
  {
    label: ['#ffffcc', '#c2e699', '#78c679', '#31a354', '#006837'],
    value: [
      [255, 255, 204],
      [194, 230, 153],
      [120, 198, 121],
      [49, 163, 84],
      [0, 104, 55],
    ],
  },
  {
    label: ['#ffffcc', '#d9f0a3', '#addd8e', '#78c679', '#31a354', '#006837'],
    value: [
      [255, 255, 204],
      [217, 240, 163],
      [173, 221, 142],
      [120, 198, 121],
      [49, 163, 84],
      [0, 104, 55],
    ],
  },
  {
    label: [
      '#ffffcc',
      '#d9f0a3',
      '#addd8e',
      '#78c679',
      '#41ab5d',
      '#238443',
      '#005a32',
    ],
    value: [
      [255, 255, 204],
      [217, 240, 163],
      [173, 221, 142],
      [120, 198, 121],
      [65, 171, 93],
      [35, 132, 67],
      [0, 90, 50],
    ],
  },
  {
    label: [
      '#ffffe5',
      '#f7fcb9',
      '#d9f0a3',
      '#addd8e',
      '#78c679',
      '#41ab5d',
      '#238443',
      '#005a32',
    ],
    value: [
      [255, 255, 229],
      [247, 252, 185],
      [217, 240, 163],
      [173, 221, 142],
      [120, 198, 121],
      [65, 171, 93],
      [35, 132, 67],
      [0, 90, 50],
    ],
  },
  {
    label: [
      '#ffffe5',
      '#f7fcb9',
      '#d9f0a3',
      '#addd8e',
      '#78c679',
      '#41ab5d',
      '#238443',
      '#006837',
      '#004529',
    ],
    value: [
      [255, 255, 229],
      [247, 252, 185],
      [217, 240, 163],
      [173, 221, 142],
      [120, 198, 121],
      [65, 171, 93],
      [35, 132, 67],
      [0, 104, 55],
      [0, 69, 41],
    ],
  },
  {
    label: ['#edf8b1', '#7fcdbb', '#2c7fb8'],
    value: [
      [237, 248, 177],
      [127, 205, 187],
      [44, 127, 184],
    ],
  },
  {
    label: ['#ffffcc', '#a1dab4', '#41b6c4', '#225ea8'],
    value: [
      [255, 255, 204],
      [161, 218, 180],
      [65, 182, 196],
      [34, 94, 168],
    ],
  },
  {
    label: ['#ffffcc', '#a1dab4', '#41b6c4', '#2c7fb8', '#253494'],
    value: [
      [255, 255, 204],
      [161, 218, 180],
      [65, 182, 196],
      [44, 127, 184],
      [37, 52, 148],
    ],
  },
  {
    label: ['#ffffcc', '#c7e9b4', '#7fcdbb', '#41b6c4', '#2c7fb8', '#253494'],
    value: [
      [255, 255, 204],
      [199, 233, 180],
      [127, 205, 187],
      [65, 182, 196],
      [44, 127, 184],
      [37, 52, 148],
    ],
  },
  {
    label: [
      '#ffffcc',
      '#c7e9b4',
      '#7fcdbb',
      '#41b6c4',
      '#1d91c0',
      '#225ea8',
      '#0c2c84',
    ],
    value: [
      [255, 255, 204],
      [199, 233, 180],
      [127, 205, 187],
      [65, 182, 196],
      [29, 145, 192],
      [34, 94, 168],
      [12, 44, 132],
    ],
  },
  {
    label: [
      '#ffffd9',
      '#edf8b1',
      '#c7e9b4',
      '#7fcdbb',
      '#41b6c4',
      '#1d91c0',
      '#225ea8',
      '#0c2c84',
    ],
    value: [
      [255, 255, 217],
      [237, 248, 177],
      [199, 233, 180],
      [127, 205, 187],
      [65, 182, 196],
      [29, 145, 192],
      [34, 94, 168],
      [12, 44, 132],
    ],
  },
  {
    label: [
      '#ffffd9',
      '#edf8b1',
      '#c7e9b4',
      '#7fcdbb',
      '#41b6c4',
      '#1d91c0',
      '#225ea8',
      '#253494',
      '#081d58',
    ],
    value: [
      [255, 255, 217],
      [237, 248, 177],
      [199, 233, 180],
      [127, 205, 187],
      [65, 182, 196],
      [29, 145, 192],
      [34, 94, 168],
      [37, 52, 148],
      [8, 29, 88],
    ],
  },
  {
    label: ['#e0f3db', '#a8ddb5', '#43a2ca'],
    value: [
      [224, 243, 219],
      [168, 221, 181],
      [67, 162, 202],
    ],
  },
  {
    label: ['#f0f9e8', '#bae4bc', '#7bccc4', '#2b8cbe'],
    value: [
      [240, 249, 232],
      [186, 228, 188],
      [123, 204, 196],
      [43, 140, 190],
    ],
  },
  {
    label: ['#f0f9e8', '#bae4bc', '#7bccc4', '#43a2ca', '#0868ac'],
    value: [
      [240, 249, 232],
      [186, 228, 188],
      [123, 204, 196],
      [67, 162, 202],
      [8, 104, 172],
    ],
  },
  {
    label: ['#f0f9e8', '#ccebc5', '#a8ddb5', '#7bccc4', '#43a2ca', '#0868ac'],
    value: [
      [240, 249, 232],
      [204, 235, 197],
      [168, 221, 181],
      [123, 204, 196],
      [67, 162, 202],
      [8, 104, 172],
    ],
  },
  {
    label: [
      '#f0f9e8',
      '#ccebc5',
      '#a8ddb5',
      '#7bccc4',
      '#4eb3d3',
      '#2b8cbe',
      '#08589e',
    ],
    value: [
      [240, 249, 232],
      [204, 235, 197],
      [168, 221, 181],
      [123, 204, 196],
      [78, 179, 211],
      [43, 140, 190],
      [8, 88, 158],
    ],
  },
  {
    label: [
      '#f7fcf0',
      '#e0f3db',
      '#ccebc5',
      '#a8ddb5',
      '#7bccc4',
      '#4eb3d3',
      '#2b8cbe',
      '#08589e',
    ],
    value: [
      [247, 252, 240],
      [224, 243, 219],
      [204, 235, 197],
      [168, 221, 181],
      [123, 204, 196],
      [78, 179, 211],
      [43, 140, 190],
      [8, 88, 158],
    ],
  },
  {
    label: [
      '#f7fcf0',
      '#e0f3db',
      '#ccebc5',
      '#a8ddb5',
      '#7bccc4',
      '#4eb3d3',
      '#2b8cbe',
      '#0868ac',
      '#084081',
    ],
    value: [
      [247, 252, 240],
      [224, 243, 219],
      [204, 235, 197],
      [168, 221, 181],
      [123, 204, 196],
      [78, 179, 211],
      [43, 140, 190],
      [8, 104, 172],
      [8, 64, 129],
    ],
  },
  {
    label: ['#e5f5f9', '#99d8c9', '#2ca25f'],
    value: [
      [229, 245, 249],
      [153, 216, 201],
      [44, 162, 95],
    ],
  },
  {
    label: ['#edf8fb', '#b2e2e2', '#66c2a4', '#238b45'],
    value: [
      [237, 248, 251],
      [178, 226, 226],
      [102, 194, 164],
      [35, 139, 69],
    ],
  },
  {
    label: ['#edf8fb', '#b2e2e2', '#66c2a4', '#2ca25f', '#006d2c'],
    value: [
      [237, 248, 251],
      [178, 226, 226],
      [102, 194, 164],
      [44, 162, 95],
      [0, 109, 44],
    ],
  },
  {
    label: ['#edf8fb', '#ccece6', '#99d8c9', '#66c2a4', '#2ca25f', '#006d2c'],
    value: [
      [237, 248, 251],
      [204, 236, 230],
      [153, 216, 201],
      [102, 194, 164],
      [44, 162, 95],
      [0, 109, 44],
    ],
  },
  {
    label: [
      '#edf8fb',
      '#ccece6',
      '#99d8c9',
      '#66c2a4',
      '#41ae76',
      '#238b45',
      '#005824',
    ],
    value: [
      [237, 248, 251],
      [204, 236, 230],
      [153, 216, 201],
      [102, 194, 164],
      [65, 174, 118],
      [35, 139, 69],
      [0, 88, 36],
    ],
  },
  {
    label: [
      '#f7fcfd',
      '#e5f5f9',
      '#ccece6',
      '#99d8c9',
      '#66c2a4',
      '#41ae76',
      '#238b45',
      '#005824',
    ],
    value: [
      [247, 252, 253],
      [229, 245, 249],
      [204, 236, 230],
      [153, 216, 201],
      [102, 194, 164],
      [65, 174, 118],
      [35, 139, 69],
      [0, 88, 36],
    ],
  },
  {
    label: [
      '#f7fcfd',
      '#e5f5f9',
      '#ccece6',
      '#99d8c9',
      '#66c2a4',
      '#41ae76',
      '#238b45',
      '#006d2c',
      '#00441b',
    ],
    value: [
      [247, 252, 253],
      [229, 245, 249],
      [204, 236, 230],
      [153, 216, 201],
      [102, 194, 164],
      [65, 174, 118],
      [35, 139, 69],
      [0, 109, 44],
      [0, 68, 27],
    ],
  },
  {
    label: ['#ece2f0', '#a6bddb', '#1c9099'],
    value: [
      [236, 226, 240],
      [166, 189, 219],
      [28, 144, 153],
    ],
  },
  {
    label: ['#f6eff7', '#bdc9e1', '#67a9cf', '#02818a'],
    value: [
      [246, 239, 247],
      [189, 201, 225],
      [103, 169, 207],
      [2, 129, 138],
    ],
  },
  {
    label: ['#f6eff7', '#bdc9e1', '#67a9cf', '#1c9099', '#016c59'],
    value: [
      [246, 239, 247],
      [189, 201, 225],
      [103, 169, 207],
      [28, 144, 153],
      [1, 108, 89],
    ],
  },
  {
    label: ['#f6eff7', '#d0d1e6', '#a6bddb', '#67a9cf', '#1c9099', '#016c59'],
    value: [
      [246, 239, 247],
      [208, 209, 230],
      [166, 189, 219],
      [103, 169, 207],
      [28, 144, 153],
      [1, 108, 89],
    ],
  },
  {
    label: [
      '#f6eff7',
      '#d0d1e6',
      '#a6bddb',
      '#67a9cf',
      '#3690c0',
      '#02818a',
      '#016450',
    ],
    value: [
      [246, 239, 247],
      [208, 209, 230],
      [166, 189, 219],
      [103, 169, 207],
      [54, 144, 192],
      [2, 129, 138],
      [1, 100, 80],
    ],
  },
  {
    label: [
      '#fff7fb',
      '#ece2f0',
      '#d0d1e6',
      '#a6bddb',
      '#67a9cf',
      '#3690c0',
      '#02818a',
      '#016450',
    ],
    value: [
      [255, 247, 251],
      [236, 226, 240],
      [208, 209, 230],
      [166, 189, 219],
      [103, 169, 207],
      [54, 144, 192],
      [2, 129, 138],
      [1, 100, 80],
    ],
  },
  {
    label: [
      '#fff7fb',
      '#ece2f0',
      '#d0d1e6',
      '#a6bddb',
      '#67a9cf',
      '#3690c0',
      '#02818a',
      '#016c59',
      '#014636',
    ],
    value: [
      [255, 247, 251],
      [236, 226, 240],
      [208, 209, 230],
      [166, 189, 219],
      [103, 169, 207],
      [54, 144, 192],
      [2, 129, 138],
      [1, 108, 89],
      [1, 70, 54],
    ],
  },
  {
    label: ['#ece7f2', '#a6bddb', '#2b8cbe'],
    value: [
      [236, 231, 242],
      [166, 189, 219],
      [43, 140, 190],
    ],
  },
  {
    label: ['#f1eef6', '#bdc9e1', '#74a9cf', '#0570b0'],
    value: [
      [241, 238, 246],
      [189, 201, 225],
      [116, 169, 207],
      [5, 112, 176],
    ],
  },
  {
    label: ['#f1eef6', '#bdc9e1', '#74a9cf', '#2b8cbe', '#045a8d'],
    value: [
      [241, 238, 246],
      [189, 201, 225],
      [116, 169, 207],
      [43, 140, 190],
      [4, 90, 141],
    ],
  },
  {
    label: ['#f1eef6', '#d0d1e6', '#a6bddb', '#74a9cf', '#2b8cbe', '#045a8d'],
    value: [
      [241, 238, 246],
      [208, 209, 230],
      [166, 189, 219],
      [116, 169, 207],
      [43, 140, 190],
      [4, 90, 141],
    ],
  },
  {
    label: [
      '#f1eef6',
      '#d0d1e6',
      '#a6bddb',
      '#74a9cf',
      '#3690c0',
      '#0570b0',
      '#034e7b',
    ],
    value: [
      [241, 238, 246],
      [208, 209, 230],
      [166, 189, 219],
      [116, 169, 207],
      [54, 144, 192],
      [5, 112, 176],
      [3, 78, 123],
    ],
  },
  {
    label: [
      '#fff7fb',
      '#ece7f2',
      '#d0d1e6',
      '#a6bddb',
      '#74a9cf',
      '#3690c0',
      '#0570b0',
      '#034e7b',
    ],
    value: [
      [255, 247, 251],
      [236, 231, 242],
      [208, 209, 230],
      [166, 189, 219],
      [116, 169, 207],
      [54, 144, 192],
      [5, 112, 176],
      [3, 78, 123],
    ],
  },
  {
    label: [
      '#fff7fb',
      '#ece7f2',
      '#d0d1e6',
      '#a6bddb',
      '#74a9cf',
      '#3690c0',
      '#0570b0',
      '#045a8d',
      '#023858',
    ],
    value: [
      [255, 247, 251],
      [236, 231, 242],
      [208, 209, 230],
      [166, 189, 219],
      [116, 169, 207],
      [54, 144, 192],
      [5, 112, 176],
      [4, 90, 141],
      [2, 56, 88],
    ],
  },
  {
    label: ['#e0ecf4', '#9ebcda', '#8856a7'],
    value: [
      [224, 236, 244],
      [158, 188, 218],
      [136, 86, 167],
    ],
  },
  {
    label: ['#edf8fb', '#b3cde3', '#8c96c6', '#88419d'],
    value: [
      [237, 248, 251],
      [179, 205, 227],
      [140, 150, 198],
      [136, 65, 157],
    ],
  },
  {
    label: ['#edf8fb', '#b3cde3', '#8c96c6', '#8856a7', '#810f7c'],
    value: [
      [237, 248, 251],
      [179, 205, 227],
      [140, 150, 198],
      [136, 86, 167],
      [129, 15, 124],
    ],
  },
  {
    label: ['#edf8fb', '#bfd3e6', '#9ebcda', '#8c96c6', '#8856a7', '#810f7c'],
    value: [
      [237, 248, 251],
      [191, 211, 230],
      [158, 188, 218],
      [140, 150, 198],
      [136, 86, 167],
      [129, 15, 124],
    ],
  },
  {
    label: [
      '#edf8fb',
      '#bfd3e6',
      '#9ebcda',
      '#8c96c6',
      '#8c6bb1',
      '#88419d',
      '#6e016b',
    ],
    value: [
      [237, 248, 251],
      [191, 211, 230],
      [158, 188, 218],
      [140, 150, 198],
      [140, 107, 177],
      [136, 65, 157],
      [110, 1, 107],
    ],
  },
  {
    label: [
      '#f7fcfd',
      '#e0ecf4',
      '#bfd3e6',
      '#9ebcda',
      '#8c96c6',
      '#8c6bb1',
      '#88419d',
      '#6e016b',
    ],
    value: [
      [247, 252, 253],
      [224, 236, 244],
      [191, 211, 230],
      [158, 188, 218],
      [140, 150, 198],
      [140, 107, 177],
      [136, 65, 157],
      [110, 1, 107],
    ],
  },
  {
    label: [
      '#f7fcfd',
      '#e0ecf4',
      '#bfd3e6',
      '#9ebcda',
      '#8c96c6',
      '#8c6bb1',
      '#88419d',
      '#810f7c',
      '#4d004b',
    ],
    value: [
      [247, 252, 253],
      [224, 236, 244],
      [191, 211, 230],
      [158, 188, 218],
      [140, 150, 198],
      [140, 107, 177],
      [136, 65, 157],
      [129, 15, 124],
      [77, 0, 75],
    ],
  },
  {
    label: ['#fde0dd', '#fa9fb5', '#c51b8a'],
    value: [
      [253, 224, 221],
      [250, 159, 181],
      [197, 27, 138],
    ],
  },
  {
    label: ['#feebe2', '#fbb4b9', '#f768a1', '#ae017e'],
    value: [
      [254, 235, 226],
      [251, 180, 185],
      [247, 104, 161],
      [174, 1, 126],
    ],
  },
  {
    label: ['#feebe2', '#fbb4b9', '#f768a1', '#c51b8a', '#7a0177'],
    value: [
      [254, 235, 226],
      [251, 180, 185],
      [247, 104, 161],
      [197, 27, 138],
      [122, 1, 119],
    ],
  },
  {
    label: ['#feebe2', '#fcc5c0', '#fa9fb5', '#f768a1', '#c51b8a', '#7a0177'],
    value: [
      [254, 235, 226],
      [252, 197, 192],
      [250, 159, 181],
      [247, 104, 161],
      [197, 27, 138],
      [122, 1, 119],
    ],
  },
  {
    label: [
      '#feebe2',
      '#fcc5c0',
      '#fa9fb5',
      '#f768a1',
      '#dd3497',
      '#ae017e',
      '#7a0177',
    ],
    value: [
      [254, 235, 226],
      [252, 197, 192],
      [250, 159, 181],
      [247, 104, 161],
      [221, 52, 151],
      [174, 1, 126],
      [122, 1, 119],
    ],
  },
  {
    label: [
      '#fff7f3',
      '#fde0dd',
      '#fcc5c0',
      '#fa9fb5',
      '#f768a1',
      '#dd3497',
      '#ae017e',
      '#7a0177',
    ],
    value: [
      [255, 247, 243],
      [253, 224, 221],
      [252, 197, 192],
      [250, 159, 181],
      [247, 104, 161],
      [221, 52, 151],
      [174, 1, 126],
      [122, 1, 119],
    ],
  },
  {
    label: [
      '#fff7f3',
      '#fde0dd',
      '#fcc5c0',
      '#fa9fb5',
      '#f768a1',
      '#dd3497',
      '#ae017e',
      '#7a0177',
      '#49006a',
    ],
    value: [
      [255, 247, 243],
      [253, 224, 221],
      [252, 197, 192],
      [250, 159, 181],
      [247, 104, 161],
      [221, 52, 151],
      [174, 1, 126],
      [122, 1, 119],
      [73, 0, 106],
    ],
  },
  {
    label: ['#e7e1ef', '#c994c7', '#dd1c77'],
    value: [
      [231, 225, 239],
      [201, 148, 199],
      [221, 28, 119],
    ],
  },
  {
    label: ['#f1eef6', '#d7b5d8', '#df65b0', '#ce1256'],
    value: [
      [241, 238, 246],
      [215, 181, 216],
      [223, 101, 176],
      [206, 18, 86],
    ],
  },
  {
    label: ['#f1eef6', '#d7b5d8', '#df65b0', '#dd1c77', '#980043'],
    value: [
      [241, 238, 246],
      [215, 181, 216],
      [223, 101, 176],
      [221, 28, 119],
      [152, 0, 67],
    ],
  },
  {
    label: ['#f1eef6', '#d4b9da', '#c994c7', '#df65b0', '#dd1c77', '#980043'],
    value: [
      [241, 238, 246],
      [212, 185, 218],
      [201, 148, 199],
      [223, 101, 176],
      [221, 28, 119],
      [152, 0, 67],
    ],
  },
  {
    label: [
      '#f1eef6',
      '#d4b9da',
      '#c994c7',
      '#df65b0',
      '#e7298a',
      '#ce1256',
      '#91003f',
    ],
    value: [
      [241, 238, 246],
      [212, 185, 218],
      [201, 148, 199],
      [223, 101, 176],
      [231, 41, 138],
      [206, 18, 86],
      [145, 0, 63],
    ],
  },
  {
    label: [
      '#f7f4f9',
      '#e7e1ef',
      '#d4b9da',
      '#c994c7',
      '#df65b0',
      '#e7298a',
      '#ce1256',
      '#91003f',
    ],
    value: [
      [247, 244, 249],
      [231, 225, 239],
      [212, 185, 218],
      [201, 148, 199],
      [223, 101, 176],
      [231, 41, 138],
      [206, 18, 86],
      [145, 0, 63],
    ],
  },
  {
    label: [
      '#f7f4f9',
      '#e7e1ef',
      '#d4b9da',
      '#c994c7',
      '#df65b0',
      '#e7298a',
      '#ce1256',
      '#980043',
      '#67001f',
    ],
    value: [
      [247, 244, 249],
      [231, 225, 239],
      [212, 185, 218],
      [201, 148, 199],
      [223, 101, 176],
      [231, 41, 138],
      [206, 18, 86],
      [152, 0, 67],
      [103, 0, 31],
    ],
  },
  {
    label: ['#fee8c8', '#fdbb84', '#e34a33'],
    value: [
      [254, 232, 200],
      [253, 187, 132],
      [227, 74, 51],
    ],
  },
  {
    label: ['#fef0d9', '#fdcc8a', '#fc8d59', '#d7301f'],
    value: [
      [254, 240, 217],
      [253, 204, 138],
      [252, 141, 89],
      [215, 48, 31],
    ],
  },
  {
    label: ['#fef0d9', '#fdcc8a', '#fc8d59', '#e34a33', '#b30000'],
    value: [
      [254, 240, 217],
      [253, 204, 138],
      [252, 141, 89],
      [227, 74, 51],
      [179, 0, 0],
    ],
  },
  {
    label: ['#fef0d9', '#fdd49e', '#fdbb84', '#fc8d59', '#e34a33', '#b30000'],
    value: [
      [254, 240, 217],
      [253, 212, 158],
      [253, 187, 132],
      [252, 141, 89],
      [227, 74, 51],
      [179, 0, 0],
    ],
  },
  {
    label: [
      '#fef0d9',
      '#fdd49e',
      '#fdbb84',
      '#fc8d59',
      '#ef6548',
      '#d7301f',
      '#990000',
    ],
    value: [
      [254, 240, 217],
      [253, 212, 158],
      [253, 187, 132],
      [252, 141, 89],
      [239, 101, 72],
      [215, 48, 31],
      [153, 0, 0],
    ],
  },
  {
    label: [
      '#fff7ec',
      '#fee8c8',
      '#fdd49e',
      '#fdbb84',
      '#fc8d59',
      '#ef6548',
      '#d7301f',
      '#990000',
    ],
    value: [
      [255, 247, 236],
      [254, 232, 200],
      [253, 212, 158],
      [253, 187, 132],
      [252, 141, 89],
      [239, 101, 72],
      [215, 48, 31],
      [153, 0, 0],
    ],
  },
  {
    label: [
      '#fff7ec',
      '#fee8c8',
      '#fdd49e',
      '#fdbb84',
      '#fc8d59',
      '#ef6548',
      '#d7301f',
      '#b30000',
      '#7f0000',
    ],
    value: [
      [255, 247, 236],
      [254, 232, 200],
      [253, 212, 158],
      [253, 187, 132],
      [252, 141, 89],
      [239, 101, 72],
      [215, 48, 31],
      [179, 0, 0],
      [127, 0, 0],
    ],
  },
  {
    label: ['#ffeda0', '#feb24c', '#f03b20'],
    value: [
      [255, 237, 160],
      [254, 178, 76],
      [240, 59, 32],
    ],
  },
  {
    label: ['#ffffb2', '#fecc5c', '#fd8d3c', '#e31a1c'],
    value: [
      [255, 255, 178],
      [254, 204, 92],
      [253, 141, 60],
      [227, 26, 28],
    ],
  },
  {
    label: ['#ffffb2', '#fecc5c', '#fd8d3c', '#f03b20', '#bd0026'],
    value: [
      [255, 255, 178],
      [254, 204, 92],
      [253, 141, 60],
      [240, 59, 32],
      [189, 0, 38],
    ],
  },
  {
    label: ['#ffffb2', '#fed976', '#feb24c', '#fd8d3c', '#f03b20', '#bd0026'],
    value: [
      [255, 255, 178],
      [254, 217, 118],
      [254, 178, 76],
      [253, 141, 60],
      [240, 59, 32],
      [189, 0, 38],
    ],
  },
  {
    label: [
      '#ffffb2',
      '#fed976',
      '#feb24c',
      '#fd8d3c',
      '#fc4e2a',
      '#e31a1c',
      '#b10026',
    ],
    value: [
      [255, 255, 178],
      [254, 217, 118],
      [254, 178, 76],
      [253, 141, 60],
      [252, 78, 42],
      [227, 26, 28],
      [177, 0, 38],
    ],
  },
  {
    label: [
      '#ffffcc',
      '#ffeda0',
      '#fed976',
      '#feb24c',
      '#fd8d3c',
      '#fc4e2a',
      '#e31a1c',
      '#b10026',
    ],
    value: [
      [255, 255, 204],
      [255, 237, 160],
      [254, 217, 118],
      [254, 178, 76],
      [253, 141, 60],
      [252, 78, 42],
      [227, 26, 28],
      [177, 0, 38],
    ],
  },
  {
    label: [
      '#ffffcc',
      '#ffeda0',
      '#fed976',
      '#feb24c',
      '#fd8d3c',
      '#fc4e2a',
      '#e31a1c',
      '#bd0026',
      '#800026',
    ],
    value: [
      [255, 255, 204],
      [255, 237, 160],
      [254, 217, 118],
      [254, 178, 76],
      [253, 141, 60],
      [252, 78, 42],
      [227, 26, 28],
      [189, 0, 38],
      [128, 0, 38],
    ],
  },
  {
    label: ['#fff7bc', '#fec44f', '#d95f0e'],
    value: [
      [255, 247, 188],
      [254, 196, 79],
      [217, 95, 14],
    ],
  },
  {
    label: ['#ffffd4', '#fed98e', '#fe9929', '#cc4c02'],
    value: [
      [255, 255, 212],
      [254, 217, 142],
      [254, 153, 41],
      [204, 76, 2],
    ],
  },
  {
    label: ['#ffffd4', '#fed98e', '#fe9929', '#d95f0e', '#993404'],
    value: [
      [255, 255, 212],
      [254, 217, 142],
      [254, 153, 41],
      [217, 95, 14],
      [153, 52, 4],
    ],
  },
  {
    label: ['#ffffd4', '#fee391', '#fec44f', '#fe9929', '#d95f0e', '#993404'],
    value: [
      [255, 255, 212],
      [254, 227, 145],
      [254, 196, 79],
      [254, 153, 41],
      [217, 95, 14],
      [153, 52, 4],
    ],
  },
  {
    label: [
      '#ffffd4',
      '#fee391',
      '#fec44f',
      '#fe9929',
      '#ec7014',
      '#cc4c02',
      '#8c2d04',
    ],
    value: [
      [255, 255, 212],
      [254, 227, 145],
      [254, 196, 79],
      [254, 153, 41],
      [236, 112, 20],
      [204, 76, 2],
      [140, 45, 4],
    ],
  },
  {
    label: [
      '#ffffe5',
      '#fff7bc',
      '#fee391',
      '#fec44f',
      '#fe9929',
      '#ec7014',
      '#cc4c02',
      '#8c2d04',
    ],
    value: [
      [255, 255, 229],
      [255, 247, 188],
      [254, 227, 145],
      [254, 196, 79],
      [254, 153, 41],
      [236, 112, 20],
      [204, 76, 2],
      [140, 45, 4],
    ],
  },
  {
    label: [
      '#ffffe5',
      '#fff7bc',
      '#fee391',
      '#fec44f',
      '#fe9929',
      '#ec7014',
      '#cc4c02',
      '#993404',
      '#662506',
    ],
    value: [
      [255, 255, 229],
      [255, 247, 188],
      [254, 227, 145],
      [254, 196, 79],
      [254, 153, 41],
      [236, 112, 20],
      [204, 76, 2],
      [153, 52, 4],
      [102, 37, 6],
    ],
  },
  {
    label: ['#efedf5', '#bcbddc', '#756bb1'],
    value: [
      [239, 237, 245],
      [188, 189, 220],
      [117, 107, 177],
    ],
  },
  {
    label: ['#f2f0f7', '#cbc9e2', '#9e9ac8', '#6a51a3'],
    value: [
      [242, 240, 247],
      [203, 201, 226],
      [158, 154, 200],
      [106, 81, 163],
    ],
  },
  {
    label: ['#f2f0f7', '#cbc9e2', '#9e9ac8', '#756bb1', '#54278f'],
    value: [
      [242, 240, 247],
      [203, 201, 226],
      [158, 154, 200],
      [117, 107, 177],
      [84, 39, 143],
    ],
  },
  {
    label: ['#f2f0f7', '#dadaeb', '#bcbddc', '#9e9ac8', '#756bb1', '#54278f'],
    value: [
      [242, 240, 247],
      [218, 218, 235],
      [188, 189, 220],
      [158, 154, 200],
      [117, 107, 177],
      [84, 39, 143],
    ],
  },
  {
    label: [
      '#f2f0f7',
      '#dadaeb',
      '#bcbddc',
      '#9e9ac8',
      '#807dba',
      '#6a51a3',
      '#4a1486',
    ],
    value: [
      [242, 240, 247],
      [218, 218, 235],
      [188, 189, 220],
      [158, 154, 200],
      [128, 125, 186],
      [106, 81, 163],
      [74, 20, 134],
    ],
  },
  {
    label: [
      '#fcfbfd',
      '#efedf5',
      '#dadaeb',
      '#bcbddc',
      '#9e9ac8',
      '#807dba',
      '#6a51a3',
      '#4a1486',
    ],
    value: [
      [252, 251, 253],
      [239, 237, 245],
      [218, 218, 235],
      [188, 189, 220],
      [158, 154, 200],
      [128, 125, 186],
      [106, 81, 163],
      [74, 20, 134],
    ],
  },
  {
    label: [
      '#fcfbfd',
      '#efedf5',
      '#dadaeb',
      '#bcbddc',
      '#9e9ac8',
      '#807dba',
      '#6a51a3',
      '#54278f',
      '#3f007d',
    ],
    value: [
      [252, 251, 253],
      [239, 237, 245],
      [218, 218, 235],
      [188, 189, 220],
      [158, 154, 200],
      [128, 125, 186],
      [106, 81, 163],
      [84, 39, 143],
      [63, 0, 125],
    ],
  },
  {
    label: ['#deebf7', '#9ecae1', '#3182bd'],
    value: [
      [222, 235, 247],
      [158, 202, 225],
      [49, 130, 189],
    ],
  },
  {
    label: ['#eff3ff', '#bdd7e7', '#6baed6', '#2171b5'],
    value: [
      [239, 243, 255],
      [189, 215, 231],
      [107, 174, 214],
      [33, 113, 181],
    ],
  },
  {
    label: ['#eff3ff', '#bdd7e7', '#6baed6', '#3182bd', '#08519c'],
    value: [
      [239, 243, 255],
      [189, 215, 231],
      [107, 174, 214],
      [49, 130, 189],
      [8, 81, 156],
    ],
  },
  {
    label: ['#eff3ff', '#c6dbef', '#9ecae1', '#6baed6', '#3182bd', '#08519c'],
    value: [
      [239, 243, 255],
      [198, 219, 239],
      [158, 202, 225],
      [107, 174, 214],
      [49, 130, 189],
      [8, 81, 156],
    ],
  },
  {
    label: [
      '#eff3ff',
      '#c6dbef',
      '#9ecae1',
      '#6baed6',
      '#4292c6',
      '#2171b5',
      '#084594',
    ],
    value: [
      [239, 243, 255],
      [198, 219, 239],
      [158, 202, 225],
      [107, 174, 214],
      [66, 146, 198],
      [33, 113, 181],
      [8, 69, 148],
    ],
  },
  {
    label: [
      '#f7fbff',
      '#deebf7',
      '#c6dbef',
      '#9ecae1',
      '#6baed6',
      '#4292c6',
      '#2171b5',
      '#084594',
    ],
    value: [
      [247, 251, 255],
      [222, 235, 247],
      [198, 219, 239],
      [158, 202, 225],
      [107, 174, 214],
      [66, 146, 198],
      [33, 113, 181],
      [8, 69, 148],
    ],
  },
  {
    label: [
      '#f7fbff',
      '#deebf7',
      '#c6dbef',
      '#9ecae1',
      '#6baed6',
      '#4292c6',
      '#2171b5',
      '#08519c',
      '#08306b',
    ],
    value: [
      [247, 251, 255],
      [222, 235, 247],
      [198, 219, 239],
      [158, 202, 225],
      [107, 174, 214],
      [66, 146, 198],
      [33, 113, 181],
      [8, 81, 156],
      [8, 48, 107],
    ],
  },
  {
    label: ['#e5f5e0', '#a1d99b', '#31a354'],
    value: [
      [229, 245, 224],
      [161, 217, 155],
      [49, 163, 84],
    ],
  },
  {
    label: ['#edf8e9', '#bae4b3', '#74c476', '#238b45'],
    value: [
      [237, 248, 233],
      [186, 228, 179],
      [116, 196, 118],
      [35, 139, 69],
    ],
  },
  {
    label: ['#edf8e9', '#bae4b3', '#74c476', '#31a354', '#006d2c'],
    value: [
      [237, 248, 233],
      [186, 228, 179],
      [116, 196, 118],
      [49, 163, 84],
      [0, 109, 44],
    ],
  },
  {
    label: ['#edf8e9', '#c7e9c0', '#a1d99b', '#74c476', '#31a354', '#006d2c'],
    value: [
      [237, 248, 233],
      [199, 233, 192],
      [161, 217, 155],
      [116, 196, 118],
      [49, 163, 84],
      [0, 109, 44],
    ],
  },
  {
    label: [
      '#edf8e9',
      '#c7e9c0',
      '#a1d99b',
      '#74c476',
      '#41ab5d',
      '#238b45',
      '#005a32',
    ],
    value: [
      [237, 248, 233],
      [199, 233, 192],
      [161, 217, 155],
      [116, 196, 118],
      [65, 171, 93],
      [35, 139, 69],
      [0, 90, 50],
    ],
  },
  {
    label: [
      '#f7fcf5',
      '#e5f5e0',
      '#c7e9c0',
      '#a1d99b',
      '#74c476',
      '#41ab5d',
      '#238b45',
      '#005a32',
    ],
    value: [
      [247, 252, 245],
      [229, 245, 224],
      [199, 233, 192],
      [161, 217, 155],
      [116, 196, 118],
      [65, 171, 93],
      [35, 139, 69],
      [0, 90, 50],
    ],
  },
  {
    label: [
      '#f7fcf5',
      '#e5f5e0',
      '#c7e9c0',
      '#a1d99b',
      '#74c476',
      '#41ab5d',
      '#238b45',
      '#006d2c',
      '#00441b',
    ],
    value: [
      [247, 252, 245],
      [229, 245, 224],
      [199, 233, 192],
      [161, 217, 155],
      [116, 196, 118],
      [65, 171, 93],
      [35, 139, 69],
      [0, 109, 44],
      [0, 68, 27],
    ],
  },
  {
    label: ['#fee6ce', '#fdae6b', '#e6550d'],
    value: [
      [254, 230, 206],
      [253, 174, 107],
      [230, 85, 13],
    ],
  },
  {
    label: ['#feedde', '#fdbe85', '#fd8d3c', '#d94701'],
    value: [
      [254, 237, 222],
      [253, 190, 133],
      [253, 141, 60],
      [217, 71, 1],
    ],
  },
  {
    label: ['#feedde', '#fdbe85', '#fd8d3c', '#e6550d', '#a63603'],
    value: [
      [254, 237, 222],
      [253, 190, 133],
      [253, 141, 60],
      [230, 85, 13],
      [166, 54, 3],
    ],
  },
  {
    label: ['#feedde', '#fdd0a2', '#fdae6b', '#fd8d3c', '#e6550d', '#a63603'],
    value: [
      [254, 237, 222],
      [253, 208, 162],
      [253, 174, 107],
      [253, 141, 60],
      [230, 85, 13],
      [166, 54, 3],
    ],
  },
  {
    label: [
      '#feedde',
      '#fdd0a2',
      '#fdae6b',
      '#fd8d3c',
      '#f16913',
      '#d94801',
      '#8c2d04',
    ],
    value: [
      [254, 237, 222],
      [253, 208, 162],
      [253, 174, 107],
      [253, 141, 60],
      [241, 105, 19],
      [217, 72, 1],
      [140, 45, 4],
    ],
  },
  {
    label: [
      '#fff5eb',
      '#fee6ce',
      '#fdd0a2',
      '#fdae6b',
      '#fd8d3c',
      '#f16913',
      '#d94801',
      '#8c2d04',
    ],
    value: [
      [255, 245, 235],
      [254, 230, 206],
      [253, 208, 162],
      [253, 174, 107],
      [253, 141, 60],
      [241, 105, 19],
      [217, 72, 1],
      [140, 45, 4],
    ],
  },
  {
    label: [
      '#fff5eb',
      '#fee6ce',
      '#fdd0a2',
      '#fdae6b',
      '#fd8d3c',
      '#f16913',
      '#d94801',
      '#a63603',
      '#7f2704',
    ],
    value: [
      [255, 245, 235],
      [254, 230, 206],
      [253, 208, 162],
      [253, 174, 107],
      [253, 141, 60],
      [241, 105, 19],
      [217, 72, 1],
      [166, 54, 3],
      [127, 39, 4],
    ],
  },
  {
    label: ['#fee0d2', '#fc9272', '#de2d26'],
    value: [
      [254, 224, 210],
      [252, 146, 114],
      [222, 45, 38],
    ],
  },
  {
    label: ['#fee5d9', '#fcae91', '#fb6a4a', '#cb181d'],
    value: [
      [254, 229, 217],
      [252, 174, 145],
      [251, 106, 74],
      [203, 24, 29],
    ],
  },
  {
    label: ['#fee5d9', '#fcae91', '#fb6a4a', '#de2d26', '#a50f15'],
    value: [
      [254, 229, 217],
      [252, 174, 145],
      [251, 106, 74],
      [222, 45, 38],
      [165, 15, 21],
    ],
  },
  {
    label: ['#fee5d9', '#fcbba1', '#fc9272', '#fb6a4a', '#de2d26', '#a50f15'],
    value: [
      [254, 229, 217],
      [252, 187, 161],
      [252, 146, 114],
      [251, 106, 74],
      [222, 45, 38],
      [165, 15, 21],
    ],
  },
  {
    label: [
      '#fee5d9',
      '#fcbba1',
      '#fc9272',
      '#fb6a4a',
      '#ef3b2c',
      '#cb181d',
      '#99000d',
    ],
    value: [
      [254, 229, 217],
      [252, 187, 161],
      [252, 146, 114],
      [251, 106, 74],
      [239, 59, 44],
      [203, 24, 29],
      [153, 0, 13],
    ],
  },
  {
    label: [
      '#fff5f0',
      '#fee0d2',
      '#fcbba1',
      '#fc9272',
      '#fb6a4a',
      '#ef3b2c',
      '#cb181d',
      '#99000d',
    ],
    value: [
      [255, 245, 240],
      [254, 224, 210],
      [252, 187, 161],
      [252, 146, 114],
      [251, 106, 74],
      [239, 59, 44],
      [203, 24, 29],
      [153, 0, 13],
    ],
  },
  {
    label: [
      '#fff5f0',
      '#fee0d2',
      '#fcbba1',
      '#fc9272',
      '#fb6a4a',
      '#ef3b2c',
      '#cb181d',
      '#a50f15',
      '#67000d',
    ],
    value: [
      [255, 245, 240],
      [254, 224, 210],
      [252, 187, 161],
      [252, 146, 114],
      [251, 106, 74],
      [239, 59, 44],
      [203, 24, 29],
      [165, 15, 21],
      [103, 0, 13],
    ],
  },
  {
    label: ['#f0f0f0', '#bdbdbd', '#636363'],
    value: [
      [240, 240, 240],
      [189, 189, 189],
      [99, 99, 99],
    ],
  },
  {
    label: ['#f7f7f7', '#cccccc', '#969696', '#525252'],
    value: [
      [247, 247, 247],
      [204, 204, 204],
      [150, 150, 150],
      [82, 82, 82],
    ],
  },
  {
    label: ['#f7f7f7', '#cccccc', '#969696', '#636363', '#252525'],
    value: [
      [247, 247, 247],
      [204, 204, 204],
      [150, 150, 150],
      [99, 99, 99],
      [37, 37, 37],
    ],
  },
  {
    label: ['#f7f7f7', '#d9d9d9', '#bdbdbd', '#969696', '#636363', '#252525'],
    value: [
      [247, 247, 247],
      [217, 217, 217],
      [189, 189, 189],
      [150, 150, 150],
      [99, 99, 99],
      [37, 37, 37],
    ],
  },
  {
    label: [
      '#f7f7f7',
      '#d9d9d9',
      '#bdbdbd',
      '#969696',
      '#737373',
      '#525252',
      '#252525',
    ],
    value: [
      [247, 247, 247],
      [217, 217, 217],
      [189, 189, 189],
      [150, 150, 150],
      [115, 115, 115],
      [82, 82, 82],
      [37, 37, 37],
    ],
  },
  {
    label: [
      '#ffffff',
      '#f0f0f0',
      '#d9d9d9',
      '#bdbdbd',
      '#969696',
      '#737373',
      '#525252',
      '#252525',
    ],
    value: [
      [255, 255, 255],
      [240, 240, 240],
      [217, 217, 217],
      [189, 189, 189],
      [150, 150, 150],
      [115, 115, 115],
      [82, 82, 82],
      [37, 37, 37],
    ],
  },
  {
    label: [
      '#ffffff',
      '#f0f0f0',
      '#d9d9d9',
      '#bdbdbd',
      '#969696',
      '#737373',
      '#525252',
      '#252525',
      '#000000',
    ],
    value: [
      [255, 255, 255],
      [240, 240, 240],
      [217, 217, 217],
      [189, 189, 189],
      [150, 150, 150],
      [115, 115, 115],
      [82, 82, 82],
      [37, 37, 37],
      [0, 0, 0],
    ],
  },
  {
    label: ['#f1a340', '#f7f7f7', '#998ec3'],
    value: [
      [241, 163, 64],
      [247, 247, 247],
      [153, 142, 195],
    ],
  },
  {
    label: ['#e66101', '#fdb863', '#b2abd2', '#5e3c99'],
    value: [
      [230, 97, 1],
      [253, 184, 99],
      [178, 171, 210],
      [94, 60, 153],
    ],
  },
  {
    label: ['#e66101', '#fdb863', '#f7f7f7', '#b2abd2', '#5e3c99'],
    value: [
      [230, 97, 1],
      [253, 184, 99],
      [247, 247, 247],
      [178, 171, 210],
      [94, 60, 153],
    ],
  },
  {
    label: ['#b35806', '#f1a340', '#fee0b6', '#d8daeb', '#998ec3', '#542788'],
    value: [
      [179, 88, 6],
      [241, 163, 64],
      [254, 224, 182],
      [216, 218, 235],
      [153, 142, 195],
      [84, 39, 136],
    ],
  },
  {
    label: [
      '#b35806',
      '#f1a340',
      '#fee0b6',
      '#f7f7f7',
      '#d8daeb',
      '#998ec3',
      '#542788',
    ],
    value: [
      [179, 88, 6],
      [241, 163, 64],
      [254, 224, 182],
      [247, 247, 247],
      [216, 218, 235],
      [153, 142, 195],
      [84, 39, 136],
    ],
  },
  {
    label: [
      '#b35806',
      '#e08214',
      '#fdb863',
      '#fee0b6',
      '#d8daeb',
      '#b2abd2',
      '#8073ac',
      '#542788',
    ],
    value: [
      [179, 88, 6],
      [224, 130, 20],
      [253, 184, 99],
      [254, 224, 182],
      [216, 218, 235],
      [178, 171, 210],
      [128, 115, 172],
      [84, 39, 136],
    ],
  },
  {
    label: [
      '#b35806',
      '#e08214',
      '#fdb863',
      '#fee0b6',
      '#f7f7f7',
      '#d8daeb',
      '#b2abd2',
      '#8073ac',
      '#542788',
    ],
    value: [
      [179, 88, 6],
      [224, 130, 20],
      [253, 184, 99],
      [254, 224, 182],
      [247, 247, 247],
      [216, 218, 235],
      [178, 171, 210],
      [128, 115, 172],
      [84, 39, 136],
    ],
  },
  {
    label: [
      '#7f3b08',
      '#b35806',
      '#e08214',
      '#fdb863',
      '#fee0b6',
      '#d8daeb',
      '#b2abd2',
      '#8073ac',
      '#542788',
      '#2d004b',
    ],
    value: [
      [127, 59, 8],
      [179, 88, 6],
      [224, 130, 20],
      [253, 184, 99],
      [254, 224, 182],
      [216, 218, 235],
      [178, 171, 210],
      [128, 115, 172],
      [84, 39, 136],
      [45, 0, 75],
    ],
  },
  {
    label: [
      '#7f3b08',
      '#b35806',
      '#e08214',
      '#fdb863',
      '#fee0b6',
      '#f7f7f7',
      '#d8daeb',
      '#b2abd2',
      '#8073ac',
      '#542788',
      '#2d004b',
    ],
    value: [
      [127, 59, 8],
      [179, 88, 6],
      [224, 130, 20],
      [253, 184, 99],
      [254, 224, 182],
      [247, 247, 247],
      [216, 218, 235],
      [178, 171, 210],
      [128, 115, 172],
      [84, 39, 136],
      [45, 0, 75],
    ],
  },
  {
    label: ['#d8b365', '#f5f5f5', '#5ab4ac'],
    value: [
      [216, 179, 101],
      [245, 245, 245],
      [90, 180, 172],
    ],
  },
  {
    label: ['#a6611a', '#dfc27d', '#80cdc1', '#018571'],
    value: [
      [166, 97, 26],
      [223, 194, 125],
      [128, 205, 193],
      [1, 133, 113],
    ],
  },
  {
    label: ['#a6611a', '#dfc27d', '#f5f5f5', '#80cdc1', '#018571'],
    value: [
      [166, 97, 26],
      [223, 194, 125],
      [245, 245, 245],
      [128, 205, 193],
      [1, 133, 113],
    ],
  },
  {
    label: ['#8c510a', '#d8b365', '#f6e8c3', '#c7eae5', '#5ab4ac', '#01665e'],
    value: [
      [140, 81, 10],
      [216, 179, 101],
      [246, 232, 195],
      [199, 234, 229],
      [90, 180, 172],
      [1, 102, 94],
    ],
  },
  {
    label: [
      '#8c510a',
      '#d8b365',
      '#f6e8c3',
      '#f5f5f5',
      '#c7eae5',
      '#5ab4ac',
      '#01665e',
    ],
    value: [
      [140, 81, 10],
      [216, 179, 101],
      [246, 232, 195],
      [245, 245, 245],
      [199, 234, 229],
      [90, 180, 172],
      [1, 102, 94],
    ],
  },
  {
    label: [
      '#8c510a',
      '#bf812d',
      '#dfc27d',
      '#f6e8c3',
      '#c7eae5',
      '#80cdc1',
      '#35978f',
      '#01665e',
    ],
    value: [
      [140, 81, 10],
      [191, 129, 45],
      [223, 194, 125],
      [246, 232, 195],
      [199, 234, 229],
      [128, 205, 193],
      [53, 151, 143],
      [1, 102, 94],
    ],
  },
  {
    label: [
      '#8c510a',
      '#bf812d',
      '#dfc27d',
      '#f6e8c3',
      '#f5f5f5',
      '#c7eae5',
      '#80cdc1',
      '#35978f',
      '#01665e',
    ],
    value: [
      [140, 81, 10],
      [191, 129, 45],
      [223, 194, 125],
      [246, 232, 195],
      [245, 245, 245],
      [199, 234, 229],
      [128, 205, 193],
      [53, 151, 143],
      [1, 102, 94],
    ],
  },
  {
    label: [
      '#543005',
      '#8c510a',
      '#bf812d',
      '#dfc27d',
      '#f6e8c3',
      '#c7eae5',
      '#80cdc1',
      '#35978f',
      '#01665e',
      '#003c30',
    ],
    value: [
      [84, 48, 5],
      [140, 81, 10],
      [191, 129, 45],
      [223, 194, 125],
      [246, 232, 195],
      [199, 234, 229],
      [128, 205, 193],
      [53, 151, 143],
      [1, 102, 94],
      [0, 60, 48],
    ],
  },
  {
    label: [
      '#543005',
      '#8c510a',
      '#bf812d',
      '#dfc27d',
      '#f6e8c3',
      '#f5f5f5',
      '#c7eae5',
      '#80cdc1',
      '#35978f',
      '#01665e',
      '#003c30',
    ],
    value: [
      [84, 48, 5],
      [140, 81, 10],
      [191, 129, 45],
      [223, 194, 125],
      [246, 232, 195],
      [245, 245, 245],
      [199, 234, 229],
      [128, 205, 193],
      [53, 151, 143],
      [1, 102, 94],
      [0, 60, 48],
    ],
  },
  {
    label: ['#af8dc3', '#f7f7f7', '#7fbf7b'],
    value: [
      [175, 141, 195],
      [247, 247, 247],
      [127, 191, 123],
    ],
  },
  {
    label: ['#7b3294', '#c2a5cf', '#a6dba0', '#008837'],
    value: [
      [123, 50, 148],
      [194, 165, 207],
      [166, 219, 160],
      [0, 136, 55],
    ],
  },
  {
    label: ['#7b3294', '#c2a5cf', '#f7f7f7', '#a6dba0', '#008837'],
    value: [
      [123, 50, 148],
      [194, 165, 207],
      [247, 247, 247],
      [166, 219, 160],
      [0, 136, 55],
    ],
  },
  {
    label: ['#762a83', '#af8dc3', '#e7d4e8', '#d9f0d3', '#7fbf7b', '#1b7837'],
    value: [
      [118, 42, 131],
      [175, 141, 195],
      [231, 212, 232],
      [217, 240, 211],
      [127, 191, 123],
      [27, 120, 55],
    ],
  },
  {
    label: [
      '#762a83',
      '#af8dc3',
      '#e7d4e8',
      '#f7f7f7',
      '#d9f0d3',
      '#7fbf7b',
      '#1b7837',
    ],
    value: [
      [118, 42, 131],
      [175, 141, 195],
      [231, 212, 232],
      [247, 247, 247],
      [217, 240, 211],
      [127, 191, 123],
      [27, 120, 55],
    ],
  },
  {
    label: [
      '#762a83',
      '#9970ab',
      '#c2a5cf',
      '#e7d4e8',
      '#d9f0d3',
      '#a6dba0',
      '#5aae61',
      '#1b7837',
    ],
    value: [
      [118, 42, 131],
      [153, 112, 171],
      [194, 165, 207],
      [231, 212, 232],
      [217, 240, 211],
      [166, 219, 160],
      [90, 174, 97],
      [27, 120, 55],
    ],
  },
  {
    label: [
      '#762a83',
      '#9970ab',
      '#c2a5cf',
      '#e7d4e8',
      '#f7f7f7',
      '#d9f0d3',
      '#a6dba0',
      '#5aae61',
      '#1b7837',
    ],
    value: [
      [118, 42, 131],
      [153, 112, 171],
      [194, 165, 207],
      [231, 212, 232],
      [247, 247, 247],
      [217, 240, 211],
      [166, 219, 160],
      [90, 174, 97],
      [27, 120, 55],
    ],
  },
  {
    label: [
      '#40004b',
      '#762a83',
      '#9970ab',
      '#c2a5cf',
      '#e7d4e8',
      '#d9f0d3',
      '#a6dba0',
      '#5aae61',
      '#1b7837',
      '#00441b',
    ],
    value: [
      [64, 0, 75],
      [118, 42, 131],
      [153, 112, 171],
      [194, 165, 207],
      [231, 212, 232],
      [217, 240, 211],
      [166, 219, 160],
      [90, 174, 97],
      [27, 120, 55],
      [0, 68, 27],
    ],
  },
  {
    label: [
      '#40004b',
      '#762a83',
      '#9970ab',
      '#c2a5cf',
      '#e7d4e8',
      '#f7f7f7',
      '#d9f0d3',
      '#a6dba0',
      '#5aae61',
      '#1b7837',
      '#00441b',
    ],
    value: [
      [64, 0, 75],
      [118, 42, 131],
      [153, 112, 171],
      [194, 165, 207],
      [231, 212, 232],
      [247, 247, 247],
      [217, 240, 211],
      [166, 219, 160],
      [90, 174, 97],
      [27, 120, 55],
      [0, 68, 27],
    ],
  },
  {
    label: ['#e9a3c9', '#f7f7f7', '#a1d76a'],
    value: [
      [233, 163, 201],
      [247, 247, 247],
      [161, 215, 106],
    ],
  },
  {
    label: ['#d01c8b', '#f1b6da', '#b8e186', '#4dac26'],
    value: [
      [208, 28, 139],
      [241, 182, 218],
      [184, 225, 134],
      [77, 172, 38],
    ],
  },
  {
    label: ['#d01c8b', '#f1b6da', '#f7f7f7', '#b8e186', '#4dac26'],
    value: [
      [208, 28, 139],
      [241, 182, 218],
      [247, 247, 247],
      [184, 225, 134],
      [77, 172, 38],
    ],
  },
  {
    label: ['#c51b7d', '#e9a3c9', '#fde0ef', '#e6f5d0', '#a1d76a', '#4d9221'],
    value: [
      [197, 27, 125],
      [233, 163, 201],
      [253, 224, 239],
      [230, 245, 208],
      [161, 215, 106],
      [77, 146, 33],
    ],
  },
  {
    label: [
      '#c51b7d',
      '#e9a3c9',
      '#fde0ef',
      '#f7f7f7',
      '#e6f5d0',
      '#a1d76a',
      '#4d9221',
    ],
    value: [
      [197, 27, 125],
      [233, 163, 201],
      [253, 224, 239],
      [247, 247, 247],
      [230, 245, 208],
      [161, 215, 106],
      [77, 146, 33],
    ],
  },
  {
    label: [
      '#c51b7d',
      '#de77ae',
      '#f1b6da',
      '#fde0ef',
      '#e6f5d0',
      '#b8e186',
      '#7fbc41',
      '#4d9221',
    ],
    value: [
      [197, 27, 125],
      [222, 119, 174],
      [241, 182, 218],
      [253, 224, 239],
      [230, 245, 208],
      [184, 225, 134],
      [127, 188, 65],
      [77, 146, 33],
    ],
  },
  {
    label: [
      '#c51b7d',
      '#de77ae',
      '#f1b6da',
      '#fde0ef',
      '#f7f7f7',
      '#e6f5d0',
      '#b8e186',
      '#7fbc41',
      '#4d9221',
    ],
    value: [
      [197, 27, 125],
      [222, 119, 174],
      [241, 182, 218],
      [253, 224, 239],
      [247, 247, 247],
      [230, 245, 208],
      [184, 225, 134],
      [127, 188, 65],
      [77, 146, 33],
    ],
  },
  {
    label: [
      '#8e0152',
      '#c51b7d',
      '#de77ae',
      '#f1b6da',
      '#fde0ef',
      '#e6f5d0',
      '#b8e186',
      '#7fbc41',
      '#4d9221',
      '#276419',
    ],
    value: [
      [142, 1, 82],
      [197, 27, 125],
      [222, 119, 174],
      [241, 182, 218],
      [253, 224, 239],
      [230, 245, 208],
      [184, 225, 134],
      [127, 188, 65],
      [77, 146, 33],
      [39, 100, 25],
    ],
  },
  {
    label: [
      '#8e0152',
      '#c51b7d',
      '#de77ae',
      '#f1b6da',
      '#fde0ef',
      '#f7f7f7',
      '#e6f5d0',
      '#b8e186',
      '#7fbc41',
      '#4d9221',
      '#276419',
    ],
    value: [
      [142, 1, 82],
      [197, 27, 125],
      [222, 119, 174],
      [241, 182, 218],
      [253, 224, 239],
      [247, 247, 247],
      [230, 245, 208],
      [184, 225, 134],
      [127, 188, 65],
      [77, 146, 33],
      [39, 100, 25],
    ],
  },
  {
    label: ['#ef8a62', '#f7f7f7', '#67a9cf'],
    value: [
      [239, 138, 98],
      [247, 247, 247],
      [103, 169, 207],
    ],
  },
  {
    label: ['#ca0020', '#f4a582', '#92c5de', '#0571b0'],
    value: [
      [202, 0, 32],
      [244, 165, 130],
      [146, 197, 222],
      [5, 113, 176],
    ],
  },
  {
    label: ['#ca0020', '#f4a582', '#f7f7f7', '#92c5de', '#0571b0'],
    value: [
      [202, 0, 32],
      [244, 165, 130],
      [247, 247, 247],
      [146, 197, 222],
      [5, 113, 176],
    ],
  },
  {
    label: ['#b2182b', '#ef8a62', '#fddbc7', '#d1e5f0', '#67a9cf', '#2166ac'],
    value: [
      [178, 24, 43],
      [239, 138, 98],
      [253, 219, 199],
      [209, 229, 240],
      [103, 169, 207],
      [33, 102, 172],
    ],
  },
  {
    label: [
      '#b2182b',
      '#ef8a62',
      '#fddbc7',
      '#f7f7f7',
      '#d1e5f0',
      '#67a9cf',
      '#2166ac',
    ],
    value: [
      [178, 24, 43],
      [239, 138, 98],
      [253, 219, 199],
      [247, 247, 247],
      [209, 229, 240],
      [103, 169, 207],
      [33, 102, 172],
    ],
  },
  {
    label: [
      '#b2182b',
      '#d6604d',
      '#f4a582',
      '#fddbc7',
      '#d1e5f0',
      '#92c5de',
      '#4393c3',
      '#2166ac',
    ],
    value: [
      [178, 24, 43],
      [214, 96, 77],
      [244, 165, 130],
      [253, 219, 199],
      [209, 229, 240],
      [146, 197, 222],
      [67, 147, 195],
      [33, 102, 172],
    ],
  },
  {
    label: [
      '#b2182b',
      '#d6604d',
      '#f4a582',
      '#fddbc7',
      '#f7f7f7',
      '#d1e5f0',
      '#92c5de',
      '#4393c3',
      '#2166ac',
    ],
    value: [
      [178, 24, 43],
      [214, 96, 77],
      [244, 165, 130],
      [253, 219, 199],
      [247, 247, 247],
      [209, 229, 240],
      [146, 197, 222],
      [67, 147, 195],
      [33, 102, 172],
    ],
  },
  {
    label: [
      '#67001f',
      '#b2182b',
      '#d6604d',
      '#f4a582',
      '#fddbc7',
      '#d1e5f0',
      '#92c5de',
      '#4393c3',
      '#2166ac',
      '#053061',
    ],
    value: [
      [103, 0, 31],
      [178, 24, 43],
      [214, 96, 77],
      [244, 165, 130],
      [253, 219, 199],
      [209, 229, 240],
      [146, 197, 222],
      [67, 147, 195],
      [33, 102, 172],
      [5, 48, 97],
    ],
  },
  {
    label: [
      '#67001f',
      '#b2182b',
      '#d6604d',
      '#f4a582',
      '#fddbc7',
      '#f7f7f7',
      '#d1e5f0',
      '#92c5de',
      '#4393c3',
      '#2166ac',
      '#053061',
    ],
    value: [
      [103, 0, 31],
      [178, 24, 43],
      [214, 96, 77],
      [244, 165, 130],
      [253, 219, 199],
      [247, 247, 247],
      [209, 229, 240],
      [146, 197, 222],
      [67, 147, 195],
      [33, 102, 172],
      [5, 48, 97],
    ],
  },
  {
    label: ['#ef8a62', '#ffffff', '#999999'],
    value: [
      [239, 138, 98],
      [255, 255, 255],
      [153, 153, 153],
    ],
  },
  {
    label: ['#ca0020', '#f4a582', '#bababa', '#404040'],
    value: [
      [202, 0, 32],
      [244, 165, 130],
      [186, 186, 186],
      [64, 64, 64],
    ],
  },
  {
    label: ['#ca0020', '#f4a582', '#ffffff', '#bababa', '#404040'],
    value: [
      [202, 0, 32],
      [244, 165, 130],
      [255, 255, 255],
      [186, 186, 186],
      [64, 64, 64],
    ],
  },
  {
    label: ['#b2182b', '#ef8a62', '#fddbc7', '#e0e0e0', '#999999', '#4d4d4d'],
    value: [
      [178, 24, 43],
      [239, 138, 98],
      [253, 219, 199],
      [224, 224, 224],
      [153, 153, 153],
      [77, 77, 77],
    ],
  },
  {
    label: [
      '#b2182b',
      '#ef8a62',
      '#fddbc7',
      '#ffffff',
      '#e0e0e0',
      '#999999',
      '#4d4d4d',
    ],
    value: [
      [178, 24, 43],
      [239, 138, 98],
      [253, 219, 199],
      [255, 255, 255],
      [224, 224, 224],
      [153, 153, 153],
      [77, 77, 77],
    ],
  },
  {
    label: [
      '#b2182b',
      '#d6604d',
      '#f4a582',
      '#fddbc7',
      '#e0e0e0',
      '#bababa',
      '#878787',
      '#4d4d4d',
    ],
    value: [
      [178, 24, 43],
      [214, 96, 77],
      [244, 165, 130],
      [253, 219, 199],
      [224, 224, 224],
      [186, 186, 186],
      [135, 135, 135],
      [77, 77, 77],
    ],
  },
  {
    label: [
      '#b2182b',
      '#d6604d',
      '#f4a582',
      '#fddbc7',
      '#ffffff',
      '#e0e0e0',
      '#bababa',
      '#878787',
      '#4d4d4d',
    ],
    value: [
      [178, 24, 43],
      [214, 96, 77],
      [244, 165, 130],
      [253, 219, 199],
      [255, 255, 255],
      [224, 224, 224],
      [186, 186, 186],
      [135, 135, 135],
      [77, 77, 77],
    ],
  },
  {
    label: [
      '#67001f',
      '#b2182b',
      '#d6604d',
      '#f4a582',
      '#fddbc7',
      '#e0e0e0',
      '#bababa',
      '#878787',
      '#4d4d4d',
      '#1a1a1a',
    ],
    value: [
      [103, 0, 31],
      [178, 24, 43],
      [214, 96, 77],
      [244, 165, 130],
      [253, 219, 199],
      [224, 224, 224],
      [186, 186, 186],
      [135, 135, 135],
      [77, 77, 77],
      [26, 26, 26],
    ],
  },
  {
    label: [
      '#67001f',
      '#b2182b',
      '#d6604d',
      '#f4a582',
      '#fddbc7',
      '#ffffff',
      '#e0e0e0',
      '#bababa',
      '#878787',
      '#4d4d4d',
      '#1a1a1a',
    ],
    value: [
      [103, 0, 31],
      [178, 24, 43],
      [214, 96, 77],
      [244, 165, 130],
      [253, 219, 199],
      [255, 255, 255],
      [224, 224, 224],
      [186, 186, 186],
      [135, 135, 135],
      [77, 77, 77],
      [26, 26, 26],
    ],
  },
  {
    label: ['#fc8d59', '#ffffbf', '#91bfdb'],
    value: [
      [252, 141, 89],
      [255, 255, 191],
      [145, 191, 219],
    ],
  },
  {
    label: ['#d7191c', '#fdae61', '#abd9e9', '#2c7bb6'],
    value: [
      [215, 25, 28],
      [253, 174, 97],
      [171, 217, 233],
      [44, 123, 182],
    ],
  },
  {
    label: ['#d7191c', '#fdae61', '#ffffbf', '#abd9e9', '#2c7bb6'],
    value: [
      [215, 25, 28],
      [253, 174, 97],
      [255, 255, 191],
      [171, 217, 233],
      [44, 123, 182],
    ],
  },
  {
    label: ['#d73027', '#fc8d59', '#fee090', '#e0f3f8', '#91bfdb', '#4575b4'],
    value: [
      [215, 48, 39],
      [252, 141, 89],
      [254, 224, 144],
      [224, 243, 248],
      [145, 191, 219],
      [69, 117, 180],
    ],
  },
  {
    label: [
      '#d73027',
      '#fc8d59',
      '#fee090',
      '#ffffbf',
      '#e0f3f8',
      '#91bfdb',
      '#4575b4',
    ],
    value: [
      [215, 48, 39],
      [252, 141, 89],
      [254, 224, 144],
      [255, 255, 191],
      [224, 243, 248],
      [145, 191, 219],
      [69, 117, 180],
    ],
  },
  {
    label: [
      '#d73027',
      '#f46d43',
      '#fdae61',
      '#fee090',
      '#e0f3f8',
      '#abd9e9',
      '#74add1',
      '#4575b4',
    ],
    value: [
      [215, 48, 39],
      [244, 109, 67],
      [253, 174, 97],
      [254, 224, 144],
      [224, 243, 248],
      [171, 217, 233],
      [116, 173, 209],
      [69, 117, 180],
    ],
  },
  {
    label: [
      '#d73027',
      '#f46d43',
      '#fdae61',
      '#fee090',
      '#ffffbf',
      '#e0f3f8',
      '#abd9e9',
      '#74add1',
      '#4575b4',
    ],
    value: [
      [215, 48, 39],
      [244, 109, 67],
      [253, 174, 97],
      [254, 224, 144],
      [255, 255, 191],
      [224, 243, 248],
      [171, 217, 233],
      [116, 173, 209],
      [69, 117, 180],
    ],
  },
  {
    label: [
      '#a50026',
      '#d73027',
      '#f46d43',
      '#fdae61',
      '#fee090',
      '#e0f3f8',
      '#abd9e9',
      '#74add1',
      '#4575b4',
      '#313695',
    ],
    value: [
      [165, 0, 38],
      [215, 48, 39],
      [244, 109, 67],
      [253, 174, 97],
      [254, 224, 144],
      [224, 243, 248],
      [171, 217, 233],
      [116, 173, 209],
      [69, 117, 180],
      [49, 54, 149],
    ],
  },
  {
    label: [
      '#a50026',
      '#d73027',
      '#f46d43',
      '#fdae61',
      '#fee090',
      '#ffffbf',
      '#e0f3f8',
      '#abd9e9',
      '#74add1',
      '#4575b4',
      '#313695',
    ],
    value: [
      [165, 0, 38],
      [215, 48, 39],
      [244, 109, 67],
      [253, 174, 97],
      [254, 224, 144],
      [255, 255, 191],
      [224, 243, 248],
      [171, 217, 233],
      [116, 173, 209],
      [69, 117, 180],
      [49, 54, 149],
    ],
  },
  {
    label: ['#fc8d59', '#ffffbf', '#99d594'],
    value: [
      [252, 141, 89],
      [255, 255, 191],
      [153, 213, 148],
    ],
  },
  {
    label: ['#d7191c', '#fdae61', '#abdda4', '#2b83ba'],
    value: [
      [215, 25, 28],
      [253, 174, 97],
      [171, 221, 164],
      [43, 131, 186],
    ],
  },
  {
    label: ['#d7191c', '#fdae61', '#ffffbf', '#abdda4', '#2b83ba'],
    value: [
      [215, 25, 28],
      [253, 174, 97],
      [255, 255, 191],
      [171, 221, 164],
      [43, 131, 186],
    ],
  },
  {
    label: ['#d53e4f', '#fc8d59', '#fee08b', '#e6f598', '#99d594', '#3288bd'],
    value: [
      [213, 62, 79],
      [252, 141, 89],
      [254, 224, 139],
      [230, 245, 152],
      [153, 213, 148],
      [50, 136, 189],
    ],
  },
  {
    label: [
      '#d53e4f',
      '#fc8d59',
      '#fee08b',
      '#ffffbf',
      '#e6f598',
      '#99d594',
      '#3288bd',
    ],
    value: [
      [213, 62, 79],
      [252, 141, 89],
      [254, 224, 139],
      [255, 255, 191],
      [230, 245, 152],
      [153, 213, 148],
      [50, 136, 189],
    ],
  },
  {
    label: [
      '#d53e4f',
      '#f46d43',
      '#fdae61',
      '#fee08b',
      '#e6f598',
      '#abdda4',
      '#66c2a5',
      '#3288bd',
    ],
    value: [
      [213, 62, 79],
      [244, 109, 67],
      [253, 174, 97],
      [254, 224, 139],
      [230, 245, 152],
      [171, 221, 164],
      [102, 194, 165],
      [50, 136, 189],
    ],
  },
  {
    label: [
      '#d53e4f',
      '#f46d43',
      '#fdae61',
      '#fee08b',
      '#ffffbf',
      '#e6f598',
      '#abdda4',
      '#66c2a5',
      '#3288bd',
    ],
    value: [
      [213, 62, 79],
      [244, 109, 67],
      [253, 174, 97],
      [254, 224, 139],
      [255, 255, 191],
      [230, 245, 152],
      [171, 221, 164],
      [102, 194, 165],
      [50, 136, 189],
    ],
  },
  {
    label: [
      '#9e0142',
      '#d53e4f',
      '#f46d43',
      '#fdae61',
      '#fee08b',
      '#e6f598',
      '#abdda4',
      '#66c2a5',
      '#3288bd',
      '#5e4fa2',
    ],
    value: [
      [158, 1, 66],
      [213, 62, 79],
      [244, 109, 67],
      [253, 174, 97],
      [254, 224, 139],
      [230, 245, 152],
      [171, 221, 164],
      [102, 194, 165],
      [50, 136, 189],
      [94, 79, 162],
    ],
  },
  {
    label: [
      '#9e0142',
      '#d53e4f',
      '#f46d43',
      '#fdae61',
      '#fee08b',
      '#ffffbf',
      '#e6f598',
      '#abdda4',
      '#66c2a5',
      '#3288bd',
      '#5e4fa2',
    ],
    value: [
      [158, 1, 66],
      [213, 62, 79],
      [244, 109, 67],
      [253, 174, 97],
      [254, 224, 139],
      [255, 255, 191],
      [230, 245, 152],
      [171, 221, 164],
      [102, 194, 165],
      [50, 136, 189],
      [94, 79, 162],
    ],
  },
  {
    label: ['#fc8d59', '#ffffbf', '#91cf60'],
    value: [
      [252, 141, 89],
      [255, 255, 191],
      [145, 207, 96],
    ],
  },
  {
    label: ['#d7191c', '#fdae61', '#a6d96a', '#1a9641'],
    value: [
      [215, 25, 28],
      [253, 174, 97],
      [166, 217, 106],
      [26, 150, 65],
    ],
  },
  {
    label: ['#d7191c', '#fdae61', '#ffffbf', '#a6d96a', '#1a9641'],
    value: [
      [215, 25, 28],
      [253, 174, 97],
      [255, 255, 191],
      [166, 217, 106],
      [26, 150, 65],
    ],
  },
  {
    label: ['#d73027', '#fc8d59', '#fee08b', '#d9ef8b', '#91cf60', '#1a9850'],
    value: [
      [215, 48, 39],
      [252, 141, 89],
      [254, 224, 139],
      [217, 239, 139],
      [145, 207, 96],
      [26, 152, 80],
    ],
  },
  {
    label: [
      '#d73027',
      '#fc8d59',
      '#fee08b',
      '#ffffbf',
      '#d9ef8b',
      '#91cf60',
      '#1a9850',
    ],
    value: [
      [215, 48, 39],
      [252, 141, 89],
      [254, 224, 139],
      [255, 255, 191],
      [217, 239, 139],
      [145, 207, 96],
      [26, 152, 80],
    ],
  },
  {
    label: [
      '#d73027',
      '#f46d43',
      '#fdae61',
      '#fee08b',
      '#d9ef8b',
      '#a6d96a',
      '#66bd63',
      '#1a9850',
    ],
    value: [
      [215, 48, 39],
      [244, 109, 67],
      [253, 174, 97],
      [254, 224, 139],
      [217, 239, 139],
      [166, 217, 106],
      [102, 189, 99],
      [26, 152, 80],
    ],
  },
  {
    label: [
      '#d73027',
      '#f46d43',
      '#fdae61',
      '#fee08b',
      '#ffffbf',
      '#d9ef8b',
      '#a6d96a',
      '#66bd63',
      '#1a9850',
    ],
    value: [
      [215, 48, 39],
      [244, 109, 67],
      [253, 174, 97],
      [254, 224, 139],
      [255, 255, 191],
      [217, 239, 139],
      [166, 217, 106],
      [102, 189, 99],
      [26, 152, 80],
    ],
  },
  {
    label: [
      '#a50026',
      '#d73027',
      '#f46d43',
      '#fdae61',
      '#fee08b',
      '#d9ef8b',
      '#a6d96a',
      '#66bd63',
      '#1a9850',
      '#006837',
    ],
    value: [
      [165, 0, 38],
      [215, 48, 39],
      [244, 109, 67],
      [253, 174, 97],
      [254, 224, 139],
      [217, 239, 139],
      [166, 217, 106],
      [102, 189, 99],
      [26, 152, 80],
      [0, 104, 55],
    ],
  },
  {
    label: [
      '#a50026',
      '#d73027',
      '#f46d43',
      '#fdae61',
      '#fee08b',
      '#ffffbf',
      '#d9ef8b',
      '#a6d96a',
      '#66bd63',
      '#1a9850',
      '#006837',
    ],
    value: [
      [165, 0, 38],
      [215, 48, 39],
      [244, 109, 67],
      [253, 174, 97],
      [254, 224, 139],
      [255, 255, 191],
      [217, 239, 139],
      [166, 217, 106],
      [102, 189, 99],
      [26, 152, 80],
      [0, 104, 55],
    ],
  },
  {
    label: ['#7fc97f', '#beaed4', '#fdc086'],
    value: [
      [127, 201, 127],
      [190, 174, 212],
      [253, 192, 134],
    ],
  },
  {
    label: ['#7fc97f', '#beaed4', '#fdc086', '#ffff99'],
    value: [
      [127, 201, 127],
      [190, 174, 212],
      [253, 192, 134],
      [255, 255, 153],
    ],
  },
  {
    label: ['#7fc97f', '#beaed4', '#fdc086', '#ffff99', '#386cb0'],
    value: [
      [127, 201, 127],
      [190, 174, 212],
      [253, 192, 134],
      [255, 255, 153],
      [56, 108, 176],
    ],
  },
  {
    label: ['#7fc97f', '#beaed4', '#fdc086', '#ffff99', '#386cb0', '#f0027f'],
    value: [
      [127, 201, 127],
      [190, 174, 212],
      [253, 192, 134],
      [255, 255, 153],
      [56, 108, 176],
      [240, 2, 127],
    ],
  },
  {
    label: [
      '#7fc97f',
      '#beaed4',
      '#fdc086',
      '#ffff99',
      '#386cb0',
      '#f0027f',
      '#bf5b17',
    ],
    value: [
      [127, 201, 127],
      [190, 174, 212],
      [253, 192, 134],
      [255, 255, 153],
      [56, 108, 176],
      [240, 2, 127],
      [191, 91, 23],
    ],
  },
  {
    label: [
      '#7fc97f',
      '#beaed4',
      '#fdc086',
      '#ffff99',
      '#386cb0',
      '#f0027f',
      '#bf5b17',
      '#666666',
    ],
    value: [
      [127, 201, 127],
      [190, 174, 212],
      [253, 192, 134],
      [255, 255, 153],
      [56, 108, 176],
      [240, 2, 127],
      [191, 91, 23],
      [102, 102, 102],
    ],
  },
  {
    label: ['#1b9e77', '#d95f02', '#7570b3'],
    value: [
      [27, 158, 119],
      [217, 95, 2],
      [117, 112, 179],
    ],
  },
  {
    label: ['#1b9e77', '#d95f02', '#7570b3', '#e7298a'],
    value: [
      [27, 158, 119],
      [217, 95, 2],
      [117, 112, 179],
      [231, 41, 138],
    ],
  },
  {
    label: ['#1b9e77', '#d95f02', '#7570b3', '#e7298a', '#66a61e'],
    value: [
      [27, 158, 119],
      [217, 95, 2],
      [117, 112, 179],
      [231, 41, 138],
      [102, 166, 30],
    ],
  },
  {
    label: ['#1b9e77', '#d95f02', '#7570b3', '#e7298a', '#66a61e', '#e6ab02'],
    value: [
      [27, 158, 119],
      [217, 95, 2],
      [117, 112, 179],
      [231, 41, 138],
      [102, 166, 30],
      [230, 171, 2],
    ],
  },
  {
    label: [
      '#1b9e77',
      '#d95f02',
      '#7570b3',
      '#e7298a',
      '#66a61e',
      '#e6ab02',
      '#a6761d',
    ],
    value: [
      [27, 158, 119],
      [217, 95, 2],
      [117, 112, 179],
      [231, 41, 138],
      [102, 166, 30],
      [230, 171, 2],
      [166, 118, 29],
    ],
  },
  {
    label: [
      '#1b9e77',
      '#d95f02',
      '#7570b3',
      '#e7298a',
      '#66a61e',
      '#e6ab02',
      '#a6761d',
      '#666666',
    ],
    value: [
      [27, 158, 119],
      [217, 95, 2],
      [117, 112, 179],
      [231, 41, 138],
      [102, 166, 30],
      [230, 171, 2],
      [166, 118, 29],
      [102, 102, 102],
    ],
  },
  {
    label: ['#a6cee3', '#1f78b4', '#b2df8a'],
    value: [
      [166, 206, 227],
      [31, 120, 180],
      [178, 223, 138],
    ],
  },
  {
    label: ['#a6cee3', '#1f78b4', '#b2df8a', '#33a02c'],
    value: [
      [166, 206, 227],
      [31, 120, 180],
      [178, 223, 138],
      [51, 160, 44],
    ],
  },
  {
    label: ['#a6cee3', '#1f78b4', '#b2df8a', '#33a02c', '#fb9a99'],
    value: [
      [166, 206, 227],
      [31, 120, 180],
      [178, 223, 138],
      [51, 160, 44],
      [251, 154, 153],
    ],
  },
  {
    label: ['#a6cee3', '#1f78b4', '#b2df8a', '#33a02c', '#fb9a99', '#e31a1c'],
    value: [
      [166, 206, 227],
      [31, 120, 180],
      [178, 223, 138],
      [51, 160, 44],
      [251, 154, 153],
      [227, 26, 28],
    ],
  },
  {
    label: [
      '#a6cee3',
      '#1f78b4',
      '#b2df8a',
      '#33a02c',
      '#fb9a99',
      '#e31a1c',
      '#fdbf6f',
    ],
    value: [
      [166, 206, 227],
      [31, 120, 180],
      [178, 223, 138],
      [51, 160, 44],
      [251, 154, 153],
      [227, 26, 28],
      [253, 191, 111],
    ],
  },
  {
    label: [
      '#a6cee3',
      '#1f78b4',
      '#b2df8a',
      '#33a02c',
      '#fb9a99',
      '#e31a1c',
      '#fdbf6f',
      '#ff7f00',
    ],
    value: [
      [166, 206, 227],
      [31, 120, 180],
      [178, 223, 138],
      [51, 160, 44],
      [251, 154, 153],
      [227, 26, 28],
      [253, 191, 111],
      [255, 127, 0],
    ],
  },
  {
    label: [
      '#a6cee3',
      '#1f78b4',
      '#b2df8a',
      '#33a02c',
      '#fb9a99',
      '#e31a1c',
      '#fdbf6f',
      '#ff7f00',
      '#cab2d6',
    ],
    value: [
      [166, 206, 227],
      [31, 120, 180],
      [178, 223, 138],
      [51, 160, 44],
      [251, 154, 153],
      [227, 26, 28],
      [253, 191, 111],
      [255, 127, 0],
      [202, 178, 214],
    ],
  },
  {
    label: [
      '#a6cee3',
      '#1f78b4',
      '#b2df8a',
      '#33a02c',
      '#fb9a99',
      '#e31a1c',
      '#fdbf6f',
      '#ff7f00',
      '#cab2d6',
      '#6a3d9a',
    ],
    value: [
      [166, 206, 227],
      [31, 120, 180],
      [178, 223, 138],
      [51, 160, 44],
      [251, 154, 153],
      [227, 26, 28],
      [253, 191, 111],
      [255, 127, 0],
      [202, 178, 214],
      [106, 61, 154],
    ],
  },
  {
    label: [
      '#a6cee3',
      '#1f78b4',
      '#b2df8a',
      '#33a02c',
      '#fb9a99',
      '#e31a1c',
      '#fdbf6f',
      '#ff7f00',
      '#cab2d6',
      '#6a3d9a',
      '#ffff99',
    ],
    value: [
      [166, 206, 227],
      [31, 120, 180],
      [178, 223, 138],
      [51, 160, 44],
      [251, 154, 153],
      [227, 26, 28],
      [253, 191, 111],
      [255, 127, 0],
      [202, 178, 214],
      [106, 61, 154],
      [255, 255, 153],
    ],
  },
  {
    label: [
      '#a6cee3',
      '#1f78b4',
      '#b2df8a',
      '#33a02c',
      '#fb9a99',
      '#e31a1c',
      '#fdbf6f',
      '#ff7f00',
      '#cab2d6',
      '#6a3d9a',
      '#ffff99',
      '#b15928',
    ],
    value: [
      [166, 206, 227],
      [31, 120, 180],
      [178, 223, 138],
      [51, 160, 44],
      [251, 154, 153],
      [227, 26, 28],
      [253, 191, 111],
      [255, 127, 0],
      [202, 178, 214],
      [106, 61, 154],
      [255, 255, 153],
      [177, 89, 40],
    ],
  },
  {
    label: ['#fbb4ae', '#b3cde3', '#ccebc5'],
    value: [
      [251, 180, 174],
      [179, 205, 227],
      [204, 235, 197],
    ],
  },
  {
    label: ['#fbb4ae', '#b3cde3', '#ccebc5', '#decbe4'],
    value: [
      [251, 180, 174],
      [179, 205, 227],
      [204, 235, 197],
      [222, 203, 228],
    ],
  },
  {
    label: ['#fbb4ae', '#b3cde3', '#ccebc5', '#decbe4', '#fed9a6'],
    value: [
      [251, 180, 174],
      [179, 205, 227],
      [204, 235, 197],
      [222, 203, 228],
      [254, 217, 166],
    ],
  },
  {
    label: ['#fbb4ae', '#b3cde3', '#ccebc5', '#decbe4', '#fed9a6', '#ffffcc'],
    value: [
      [251, 180, 174],
      [179, 205, 227],
      [204, 235, 197],
      [222, 203, 228],
      [254, 217, 166],
      [255, 255, 204],
    ],
  },
  {
    label: [
      '#fbb4ae',
      '#b3cde3',
      '#ccebc5',
      '#decbe4',
      '#fed9a6',
      '#ffffcc',
      '#e5d8bd',
    ],
    value: [
      [251, 180, 174],
      [179, 205, 227],
      [204, 235, 197],
      [222, 203, 228],
      [254, 217, 166],
      [255, 255, 204],
      [229, 216, 189],
    ],
  },
  {
    label: [
      '#fbb4ae',
      '#b3cde3',
      '#ccebc5',
      '#decbe4',
      '#fed9a6',
      '#ffffcc',
      '#e5d8bd',
      '#fddaec',
    ],
    value: [
      [251, 180, 174],
      [179, 205, 227],
      [204, 235, 197],
      [222, 203, 228],
      [254, 217, 166],
      [255, 255, 204],
      [229, 216, 189],
      [253, 218, 236],
    ],
  },
  {
    label: [
      '#fbb4ae',
      '#b3cde3',
      '#ccebc5',
      '#decbe4',
      '#fed9a6',
      '#ffffcc',
      '#e5d8bd',
      '#fddaec',
      '#f2f2f2',
    ],
    value: [
      [251, 180, 174],
      [179, 205, 227],
      [204, 235, 197],
      [222, 203, 228],
      [254, 217, 166],
      [255, 255, 204],
      [229, 216, 189],
      [253, 218, 236],
      [242, 242, 242],
    ],
  },
  {
    label: ['#b3e2cd', '#fdcdac', '#cbd5e8'],
    value: [
      [179, 226, 205],
      [253, 205, 172],
      [203, 213, 232],
    ],
  },
  {
    label: ['#b3e2cd', '#fdcdac', '#cbd5e8', '#f4cae4'],
    value: [
      [179, 226, 205],
      [253, 205, 172],
      [203, 213, 232],
      [244, 202, 228],
    ],
  },
  {
    label: ['#b3e2cd', '#fdcdac', '#cbd5e8', '#f4cae4', '#e6f5c9'],
    value: [
      [179, 226, 205],
      [253, 205, 172],
      [203, 213, 232],
      [244, 202, 228],
      [230, 245, 201],
    ],
  },
  {
    label: ['#b3e2cd', '#fdcdac', '#cbd5e8', '#f4cae4', '#e6f5c9', '#fff2ae'],
    value: [
      [179, 226, 205],
      [253, 205, 172],
      [203, 213, 232],
      [244, 202, 228],
      [230, 245, 201],
      [255, 242, 174],
    ],
  },
  {
    label: [
      '#b3e2cd',
      '#fdcdac',
      '#cbd5e8',
      '#f4cae4',
      '#e6f5c9',
      '#fff2ae',
      '#f1e2cc',
    ],
    value: [
      [179, 226, 205],
      [253, 205, 172],
      [203, 213, 232],
      [244, 202, 228],
      [230, 245, 201],
      [255, 242, 174],
      [241, 226, 204],
    ],
  },
  {
    label: [
      '#b3e2cd',
      '#fdcdac',
      '#cbd5e8',
      '#f4cae4',
      '#e6f5c9',
      '#fff2ae',
      '#f1e2cc',
      '#cccccc',
    ],
    value: [
      [179, 226, 205],
      [253, 205, 172],
      [203, 213, 232],
      [244, 202, 228],
      [230, 245, 201],
      [255, 242, 174],
      [241, 226, 204],
      [204, 204, 204],
    ],
  },
  {
    label: ['#e41a1c', '#377eb8', '#4daf4a'],
    value: [
      [228, 26, 28],
      [55, 126, 184],
      [77, 175, 74],
    ],
  },
  {
    label: ['#e41a1c', '#377eb8', '#4daf4a', '#984ea3'],
    value: [
      [228, 26, 28],
      [55, 126, 184],
      [77, 175, 74],
      [152, 78, 163],
    ],
  },
  {
    label: ['#e41a1c', '#377eb8', '#4daf4a', '#984ea3', '#ff7f00'],
    value: [
      [228, 26, 28],
      [55, 126, 184],
      [77, 175, 74],
      [152, 78, 163],
      [255, 127, 0],
    ],
  },
  {
    label: ['#e41a1c', '#377eb8', '#4daf4a', '#984ea3', '#ff7f00', '#ffff33'],
    value: [
      [228, 26, 28],
      [55, 126, 184],
      [77, 175, 74],
      [152, 78, 163],
      [255, 127, 0],
      [255, 255, 51],
    ],
  },
  {
    label: [
      '#e41a1c',
      '#377eb8',
      '#4daf4a',
      '#984ea3',
      '#ff7f00',
      '#ffff33',
      '#a65628',
    ],
    value: [
      [228, 26, 28],
      [55, 126, 184],
      [77, 175, 74],
      [152, 78, 163],
      [255, 127, 0],
      [255, 255, 51],
      [166, 86, 40],
    ],
  },
  {
    label: [
      '#e41a1c',
      '#377eb8',
      '#4daf4a',
      '#984ea3',
      '#ff7f00',
      '#ffff33',
      '#a65628',
      '#f781bf',
    ],
    value: [
      [228, 26, 28],
      [55, 126, 184],
      [77, 175, 74],
      [152, 78, 163],
      [255, 127, 0],
      [255, 255, 51],
      [166, 86, 40],
      [247, 129, 191],
    ],
  },
  {
    label: [
      '#e41a1c',
      '#377eb8',
      '#4daf4a',
      '#984ea3',
      '#ff7f00',
      '#ffff33',
      '#a65628',
      '#f781bf',
      '#999999',
    ],
    value: [
      [228, 26, 28],
      [55, 126, 184],
      [77, 175, 74],
      [152, 78, 163],
      [255, 127, 0],
      [255, 255, 51],
      [166, 86, 40],
      [247, 129, 191],
      [153, 153, 153],
    ],
  },
  {
    label: ['#66c2a5', '#fc8d62', '#8da0cb'],
    value: [
      [102, 194, 165],
      [252, 141, 98],
      [141, 160, 203],
    ],
  },
  {
    label: ['#66c2a5', '#fc8d62', '#8da0cb', '#e78ac3'],
    value: [
      [102, 194, 165],
      [252, 141, 98],
      [141, 160, 203],
      [231, 138, 195],
    ],
  },
  {
    label: ['#66c2a5', '#fc8d62', '#8da0cb', '#e78ac3', '#a6d854'],
    value: [
      [102, 194, 165],
      [252, 141, 98],
      [141, 160, 203],
      [231, 138, 195],
      [166, 216, 84],
    ],
  },
  {
    label: ['#66c2a5', '#fc8d62', '#8da0cb', '#e78ac3', '#a6d854', '#ffd92f'],
    value: [
      [102, 194, 165],
      [252, 141, 98],
      [141, 160, 203],
      [231, 138, 195],
      [166, 216, 84],
      [255, 217, 47],
    ],
  },
  {
    label: [
      '#66c2a5',
      '#fc8d62',
      '#8da0cb',
      '#e78ac3',
      '#a6d854',
      '#ffd92f',
      '#e5c494',
    ],
    value: [
      [102, 194, 165],
      [252, 141, 98],
      [141, 160, 203],
      [231, 138, 195],
      [166, 216, 84],
      [255, 217, 47],
      [229, 196, 148],
    ],
  },
  {
    label: [
      '#66c2a5',
      '#fc8d62',
      '#8da0cb',
      '#e78ac3',
      '#a6d854',
      '#ffd92f',
      '#e5c494',
      '#b3b3b3',
    ],
    value: [
      [102, 194, 165],
      [252, 141, 98],
      [141, 160, 203],
      [231, 138, 195],
      [166, 216, 84],
      [255, 217, 47],
      [229, 196, 148],
      [179, 179, 179],
    ],
  },
  {
    label: ['#8dd3c7', '#ffffb3', '#bebada'],
    value: [
      [141, 211, 199],
      [255, 255, 179],
      [190, 186, 218],
    ],
  },
  {
    label: ['#8dd3c7', '#ffffb3', '#bebada', '#fb8072'],
    value: [
      [141, 211, 199],
      [255, 255, 179],
      [190, 186, 218],
      [251, 128, 114],
    ],
  },
  {
    label: ['#8dd3c7', '#ffffb3', '#bebada', '#fb8072', '#80b1d3'],
    value: [
      [141, 211, 199],
      [255, 255, 179],
      [190, 186, 218],
      [251, 128, 114],
      [128, 177, 211],
    ],
  },
  {
    label: ['#8dd3c7', '#ffffb3', '#bebada', '#fb8072', '#80b1d3', '#fdb462'],
    value: [
      [141, 211, 199],
      [255, 255, 179],
      [190, 186, 218],
      [251, 128, 114],
      [128, 177, 211],
      [253, 180, 98],
    ],
  },
  {
    label: [
      '#8dd3c7',
      '#ffffb3',
      '#bebada',
      '#fb8072',
      '#80b1d3',
      '#fdb462',
      '#b3de69',
    ],
    value: [
      [141, 211, 199],
      [255, 255, 179],
      [190, 186, 218],
      [251, 128, 114],
      [128, 177, 211],
      [253, 180, 98],
      [179, 222, 105],
    ],
  },
  {
    label: [
      '#8dd3c7',
      '#ffffb3',
      '#bebada',
      '#fb8072',
      '#80b1d3',
      '#fdb462',
      '#b3de69',
      '#fccde5',
    ],
    value: [
      [141, 211, 199],
      [255, 255, 179],
      [190, 186, 218],
      [251, 128, 114],
      [128, 177, 211],
      [253, 180, 98],
      [179, 222, 105],
      [252, 205, 229],
    ],
  },
  {
    label: [
      '#8dd3c7',
      '#ffffb3',
      '#bebada',
      '#fb8072',
      '#80b1d3',
      '#fdb462',
      '#b3de69',
      '#fccde5',
      '#d9d9d9',
    ],
    value: [
      [141, 211, 199],
      [255, 255, 179],
      [190, 186, 218],
      [251, 128, 114],
      [128, 177, 211],
      [253, 180, 98],
      [179, 222, 105],
      [252, 205, 229],
      [217, 217, 217],
    ],
  },
  {
    label: [
      '#8dd3c7',
      '#ffffb3',
      '#bebada',
      '#fb8072',
      '#80b1d3',
      '#fdb462',
      '#b3de69',
      '#fccde5',
      '#d9d9d9',
      '#bc80bd',
    ],
    value: [
      [141, 211, 199],
      [255, 255, 179],
      [190, 186, 218],
      [251, 128, 114],
      [128, 177, 211],
      [253, 180, 98],
      [179, 222, 105],
      [252, 205, 229],
      [217, 217, 217],
      [188, 128, 189],
    ],
  },
  {
    label: [
      '#8dd3c7',
      '#ffffb3',
      '#bebada',
      '#fb8072',
      '#80b1d3',
      '#fdb462',
      '#b3de69',
      '#fccde5',
      '#d9d9d9',
      '#bc80bd',
      '#ccebc5',
    ],
    value: [
      [141, 211, 199],
      [255, 255, 179],
      [190, 186, 218],
      [251, 128, 114],
      [128, 177, 211],
      [253, 180, 98],
      [179, 222, 105],
      [252, 205, 229],
      [217, 217, 217],
      [188, 128, 189],
      [204, 235, 197],
    ],
  },
  {
    label: [
      '#8dd3c7',
      '#ffffb3',
      '#bebada',
      '#fb8072',
      '#80b1d3',
      '#fdb462',
      '#b3de69',
      '#fccde5',
      '#d9d9d9',
      '#bc80bd',
      '#ccebc5',
      '#ffed6f',
    ],
    value: [
      [141, 211, 199],
      [255, 255, 179],
      [190, 186, 218],
      [251, 128, 114],
      [128, 177, 211],
      [253, 180, 98],
      [179, 222, 105],
      [252, 205, 229],
      [217, 217, 217],
      [188, 128, 189],
      [204, 235, 197],
      [255, 237, 111],
    ],
  },
]

export const rawReversedColoursOptions = [
  {
    label: ['#31a354', '#f7fcb9'],
    value: [
      [49, 163, 84],
      [247, 252, 185],
    ],
  },
  {
    label: ['#2c7fb8', '#edf8b1'],
    value: [
      [44, 127, 184],
      [237, 248, 177],
    ],
  },
  {
    label: ['#43a2ca', '#e0f3db'],
    value: [
      [67, 162, 202],
      [224, 243, 219],
    ],
  },
  {
    label: ['#2ca25f', '#e5f5f9'],
    value: [
      [44, 162, 95],
      [229, 245, 249],
    ],
  },
  {
    label: ['#1c9099', '#ece2f0'],
    value: [
      [28, 144, 153],
      [236, 226, 240],
    ],
  },
  {
    label: ['#2b8cbe', '#ece7f2'],
    value: [
      [43, 140, 190],
      [236, 231, 242],
    ],
  },
  {
    label: ['#8856a7', '#e0ecf4'],
    value: [
      [136, 86, 167],
      [224, 236, 244],
    ],
  },
  {
    label: ['#c51b8a', '#fde0dd'],
    value: [
      [197, 27, 138],
      [253, 224, 221],
    ],
  },
  {
    label: ['#dd1c77', '#e7e1ef'],
    value: [
      [221, 28, 119],
      [231, 225, 239],
    ],
  },
  {
    label: ['#e34a33', '#fee8c8'],
    value: [
      [227, 74, 51],
      [254, 232, 200],
    ],
  },
  {
    label: ['#f03b20', '#ffeda0'],
    value: [
      [240, 59, 32],
      [255, 237, 160],
    ],
  },
  {
    label: ['#d95f0e', '#fff7bc'],
    value: [
      [217, 95, 14],
      [255, 247, 188],
    ],
  },
  {
    label: ['#756bb1', '#efedf5'],
    value: [
      [117, 107, 177],
      [239, 237, 245],
    ],
  },
  {
    label: ['#3182bd', '#deebf7'],
    value: [
      [49, 130, 189],
      [222, 235, 247],
    ],
  },
  {
    label: ['#31a354', '#e5f5e0'],
    value: [
      [49, 163, 84],
      [229, 245, 224],
    ],
  },
  {
    label: ['#e6550d', '#fee6ce'],
    value: [
      [230, 85, 13],
      [254, 230, 206],
    ],
  },
  {
    label: ['#de2d26', '#fee0d2'],
    value: [
      [222, 45, 38],
      [254, 224, 210],
    ],
  },
  {
    label: ['#636363', '#f0f0f0'],
    value: [
      [99, 99, 99],
      [240, 240, 240],
    ],
  },
  {
    label: ['#998ec3', '#f1a340'],
    value: [
      [153, 142, 195],
      [241, 163, 64],
    ],
  },
  {
    label: ['#5ab4ac', '#d8b365'],
    value: [
      [90, 180, 172],
      [216, 179, 101],
    ],
  },
  {
    label: ['#7fbf7b', '#af8dc3'],
    value: [
      [127, 191, 123],
      [175, 141, 195],
    ],
  },
  {
    label: ['#a1d76a', '#e9a3c9'],
    value: [
      [161, 215, 106],
      [233, 163, 201],
    ],
  },
  {
    label: ['#67a9cf', '#ef8a62'],
    value: [
      [103, 169, 207],
      [239, 138, 98],
    ],
  },
  {
    label: ['#999999', '#ef8a62'],
    value: [
      [153, 153, 153],
      [239, 138, 98],
    ],
  },
  {
    label: ['#91bfdb', '#fc8d59'],
    value: [
      [145, 191, 219],
      [252, 141, 89],
    ],
  },
  {
    label: ['#99d594', '#fc8d59'],
    value: [
      [153, 213, 148],
      [252, 141, 89],
    ],
  },
  {
    label: ['#91cf60', '#fc8d59'],
    value: [
      [145, 207, 96],
      [252, 141, 89],
    ],
  },
  {
    label: ['#fdc086', '#7fc97f'],
    value: [
      [253, 192, 134],
      [127, 201, 127],
    ],
  },
  {
    label: ['#7570b3', '#1b9e77'],
    value: [
      [117, 112, 179],
      [27, 158, 119],
    ],
  },
  {
    label: ['#b2df8a', '#a6cee3'],
    value: [
      [178, 223, 138],
      [166, 206, 227],
    ],
  },
  {
    label: ['#ccebc5', '#fbb4ae'],
    value: [
      [204, 235, 197],
      [251, 180, 174],
    ],
  },
  {
    label: ['#cbd5e8', '#b3e2cd'],
    value: [
      [203, 213, 232],
      [179, 226, 205],
    ],
  },
  {
    label: ['#4daf4a', '#e41a1c'],
    value: [
      [77, 175, 74],
      [228, 26, 28],
    ],
  },
  {
    label: ['#8da0cb', '#66c2a5'],
    value: [
      [141, 160, 203],
      [102, 194, 165],
    ],
  },
  {
    label: ['#bebada', '#8dd3c7'],
    value: [
      [190, 186, 218],
      [141, 211, 199],
    ],
  },
  {
    label: ['#F7FCB9', '#31A354'],
    value: [
      [247, 252, 185],
      [49, 163, 84],
    ],
  },
  {
    label: ['#EDF8B1', '#2C7FB8'],
    value: [
      [237, 248, 177],
      [44, 127, 184],
    ],
  },
  {
    label: ['#E0F3DB', '#43A2CA'],
    value: [
      [224, 243, 219],
      [67, 162, 202],
    ],
  },
  {
    label: ['#E5F5F9', '#2CA25F'],
    value: [
      [229, 245, 249],
      [44, 162, 95],
    ],
  },
  {
    label: ['#ECE2F0', '#1C9099'],
    value: [
      [236, 226, 240],
      [28, 144, 153],
    ],
  },
  {
    label: ['#ECE7F2', '#2B8CBE'],
    value: [
      [236, 231, 242],
      [43, 140, 190],
    ],
  },
  {
    label: ['#E0ECF4', '#8856A7'],
    value: [
      [224, 236, 244],
      [136, 86, 167],
    ],
  },
  {
    label: ['#FDE0DD', '#C51B8A'],
    value: [
      [253, 224, 221],
      [197, 27, 138],
    ],
  },
  {
    label: ['#E7E1EF', '#DD1C77'],
    value: [
      [231, 225, 239],
      [221, 28, 119],
    ],
  },
  {
    label: ['#FEE8C8', '#E34A33'],
    value: [
      [254, 232, 200],
      [227, 74, 51],
    ],
  },
  {
    label: ['#FFEDA0', '#F03B20'],
    value: [
      [255, 237, 160],
      [240, 59, 32],
    ],
  },
  {
    label: ['#FFF7BC', '#D95F0E'],
    value: [
      [255, 247, 188],
      [217, 95, 14],
    ],
  },
  {
    label: ['#EFEDF5', '#756BB1'],
    value: [
      [239, 237, 245],
      [117, 107, 177],
    ],
  },
  {
    label: ['#DEEBF7', '#3182BD'],
    value: [
      [222, 235, 247],
      [49, 130, 189],
    ],
  },
  {
    label: ['#E5F5E0', '#31A354'],
    value: [
      [229, 245, 224],
      [49, 163, 84],
    ],
  },
  {
    label: ['#FEE6CE', '#E6550D'],
    value: [
      [254, 230, 206],
      [230, 85, 13],
    ],
  },
  {
    label: ['#FEE0D2', '#DE2D26'],
    value: [
      [254, 224, 210],
      [222, 45, 38],
    ],
  },
  {
    label: ['#F0F0F0', '#636363'],
    value: [
      [240, 240, 240],
      [99, 99, 99],
    ],
  },
  {
    label: ['#F1A340', '#998EC3'],
    value: [
      [241, 163, 64],
      [153, 142, 195],
    ],
  },
  {
    label: ['#D8B365', '#5AB4AC'],
    value: [
      [216, 179, 101],
      [90, 180, 172],
    ],
  },
  {
    label: ['#AF8DC3', '#7FBF7B'],
    value: [
      [175, 141, 195],
      [127, 191, 123],
    ],
  },
  {
    label: ['#E9A3C9', '#A1D76A'],
    value: [
      [233, 163, 201],
      [161, 215, 106],
    ],
  },
  {
    label: ['#EF8A62', '#67A9CF'],
    value: [
      [239, 138, 98],
      [103, 169, 207],
    ],
  },
  {
    label: ['#EF8A62', '#999999'],
    value: [
      [239, 138, 98],
      [153, 153, 153],
    ],
  },
  {
    label: ['#FC8D59', '#91BFDB'],
    value: [
      [252, 141, 89],
      [145, 191, 219],
    ],
  },
  {
    label: ['#FC8D59', '#99D594'],
    value: [
      [252, 141, 89],
      [153, 213, 148],
    ],
  },
  {
    label: ['#FC8D59', '#91CF60'],
    value: [
      [252, 141, 89],
      [145, 207, 96],
    ],
  },
  {
    label: ['#7FC97F', '#FDC086'],
    value: [
      [127, 201, 127],
      [253, 192, 134],
    ],
  },
  {
    label: ['#1B9E77', '#7570B3'],
    value: [
      [27, 158, 119],
      [117, 112, 179],
    ],
  },
  {
    label: ['#A6CEE3', '#B2DF8A'],
    value: [
      [166, 206, 227],
      [178, 223, 138],
    ],
  },
  {
    label: ['#FBB4AE', '#CCEBC5'],
    value: [
      [251, 180, 174],
      [204, 235, 197],
    ],
  },
  {
    label: ['#B3E2CD', '#CBD5E8'],
    value: [
      [179, 226, 205],
      [203, 213, 232],
    ],
  },
  {
    label: ['#E41A1C', '#4DAF4A'],
    value: [
      [228, 26, 28],
      [77, 175, 74],
    ],
  },
  {
    label: ['#66C2A5', '#8DA0CB'],
    value: [
      [102, 194, 165],
      [141, 160, 203],
    ],
  },
  {
    label: ['#8DD3C7', '#BEBADA'],
    value: [
      [141, 211, 199],
      [190, 186, 218],
    ],
  },
  {
    label: ['#31a354', '#addd8e', '#f7fcb9'],
    value: [
      [49, 163, 84],
      [173, 221, 142],
      [247, 252, 185],
    ],
  },
  {
    label: ['#238443', '#78c679', '#c2e699', '#ffffcc'],
    value: [
      [35, 132, 67],
      [120, 198, 121],
      [194, 230, 153],
      [255, 255, 204],
    ],
  },
  {
    label: ['#006837', '#31a354', '#78c679', '#c2e699', '#ffffcc'],
    value: [
      [0, 104, 55],
      [49, 163, 84],
      [120, 198, 121],
      [194, 230, 153],
      [255, 255, 204],
    ],
  },
  {
    label: ['#006837', '#31a354', '#78c679', '#addd8e', '#d9f0a3', '#ffffcc'],
    value: [
      [0, 104, 55],
      [49, 163, 84],
      [120, 198, 121],
      [173, 221, 142],
      [217, 240, 163],
      [255, 255, 204],
    ],
  },
  {
    label: [
      '#005a32',
      '#238443',
      '#41ab5d',
      '#78c679',
      '#addd8e',
      '#d9f0a3',
      '#ffffcc',
    ],
    value: [
      [0, 90, 50],
      [35, 132, 67],
      [65, 171, 93],
      [120, 198, 121],
      [173, 221, 142],
      [217, 240, 163],
      [255, 255, 204],
    ],
  },
  {
    label: [
      '#005a32',
      '#238443',
      '#41ab5d',
      '#78c679',
      '#addd8e',
      '#d9f0a3',
      '#f7fcb9',
      '#ffffe5',
    ],
    value: [
      [0, 90, 50],
      [35, 132, 67],
      [65, 171, 93],
      [120, 198, 121],
      [173, 221, 142],
      [217, 240, 163],
      [247, 252, 185],
      [255, 255, 229],
    ],
  },
  {
    label: [
      '#004529',
      '#006837',
      '#238443',
      '#41ab5d',
      '#78c679',
      '#addd8e',
      '#d9f0a3',
      '#f7fcb9',
      '#ffffe5',
    ],
    value: [
      [0, 69, 41],
      [0, 104, 55],
      [35, 132, 67],
      [65, 171, 93],
      [120, 198, 121],
      [173, 221, 142],
      [217, 240, 163],
      [247, 252, 185],
      [255, 255, 229],
    ],
  },
  {
    label: ['#2c7fb8', '#7fcdbb', '#edf8b1'],
    value: [
      [44, 127, 184],
      [127, 205, 187],
      [237, 248, 177],
    ],
  },
  {
    label: ['#225ea8', '#41b6c4', '#a1dab4', '#ffffcc'],
    value: [
      [34, 94, 168],
      [65, 182, 196],
      [161, 218, 180],
      [255, 255, 204],
    ],
  },
  {
    label: ['#253494', '#2c7fb8', '#41b6c4', '#a1dab4', '#ffffcc'],
    value: [
      [37, 52, 148],
      [44, 127, 184],
      [65, 182, 196],
      [161, 218, 180],
      [255, 255, 204],
    ],
  },
  {
    label: ['#253494', '#2c7fb8', '#41b6c4', '#7fcdbb', '#c7e9b4', '#ffffcc'],
    value: [
      [37, 52, 148],
      [44, 127, 184],
      [65, 182, 196],
      [127, 205, 187],
      [199, 233, 180],
      [255, 255, 204],
    ],
  },
  {
    label: [
      '#0c2c84',
      '#225ea8',
      '#1d91c0',
      '#41b6c4',
      '#7fcdbb',
      '#c7e9b4',
      '#ffffcc',
    ],
    value: [
      [12, 44, 132],
      [34, 94, 168],
      [29, 145, 192],
      [65, 182, 196],
      [127, 205, 187],
      [199, 233, 180],
      [255, 255, 204],
    ],
  },
  {
    label: [
      '#0c2c84',
      '#225ea8',
      '#1d91c0',
      '#41b6c4',
      '#7fcdbb',
      '#c7e9b4',
      '#edf8b1',
      '#ffffd9',
    ],
    value: [
      [12, 44, 132],
      [34, 94, 168],
      [29, 145, 192],
      [65, 182, 196],
      [127, 205, 187],
      [199, 233, 180],
      [237, 248, 177],
      [255, 255, 217],
    ],
  },
  {
    label: [
      '#081d58',
      '#253494',
      '#225ea8',
      '#1d91c0',
      '#41b6c4',
      '#7fcdbb',
      '#c7e9b4',
      '#edf8b1',
      '#ffffd9',
    ],
    value: [
      [8, 29, 88],
      [37, 52, 148],
      [34, 94, 168],
      [29, 145, 192],
      [65, 182, 196],
      [127, 205, 187],
      [199, 233, 180],
      [237, 248, 177],
      [255, 255, 217],
    ],
  },
  {
    label: ['#43a2ca', '#a8ddb5', '#e0f3db'],
    value: [
      [67, 162, 202],
      [168, 221, 181],
      [224, 243, 219],
    ],
  },
  {
    label: ['#2b8cbe', '#7bccc4', '#bae4bc', '#f0f9e8'],
    value: [
      [43, 140, 190],
      [123, 204, 196],
      [186, 228, 188],
      [240, 249, 232],
    ],
  },
  {
    label: ['#0868ac', '#43a2ca', '#7bccc4', '#bae4bc', '#f0f9e8'],
    value: [
      [8, 104, 172],
      [67, 162, 202],
      [123, 204, 196],
      [186, 228, 188],
      [240, 249, 232],
    ],
  },
  {
    label: ['#0868ac', '#43a2ca', '#7bccc4', '#a8ddb5', '#ccebc5', '#f0f9e8'],
    value: [
      [8, 104, 172],
      [67, 162, 202],
      [123, 204, 196],
      [168, 221, 181],
      [204, 235, 197],
      [240, 249, 232],
    ],
  },
  {
    label: [
      '#08589e',
      '#2b8cbe',
      '#4eb3d3',
      '#7bccc4',
      '#a8ddb5',
      '#ccebc5',
      '#f0f9e8',
    ],
    value: [
      [8, 88, 158],
      [43, 140, 190],
      [78, 179, 211],
      [123, 204, 196],
      [168, 221, 181],
      [204, 235, 197],
      [240, 249, 232],
    ],
  },
  {
    label: [
      '#08589e',
      '#2b8cbe',
      '#4eb3d3',
      '#7bccc4',
      '#a8ddb5',
      '#ccebc5',
      '#e0f3db',
      '#f7fcf0',
    ],
    value: [
      [8, 88, 158],
      [43, 140, 190],
      [78, 179, 211],
      [123, 204, 196],
      [168, 221, 181],
      [204, 235, 197],
      [224, 243, 219],
      [247, 252, 240],
    ],
  },
  {
    label: [
      '#084081',
      '#0868ac',
      '#2b8cbe',
      '#4eb3d3',
      '#7bccc4',
      '#a8ddb5',
      '#ccebc5',
      '#e0f3db',
      '#f7fcf0',
    ],
    value: [
      [8, 64, 129],
      [8, 104, 172],
      [43, 140, 190],
      [78, 179, 211],
      [123, 204, 196],
      [168, 221, 181],
      [204, 235, 197],
      [224, 243, 219],
      [247, 252, 240],
    ],
  },
  {
    label: ['#2ca25f', '#99d8c9', '#e5f5f9'],
    value: [
      [44, 162, 95],
      [153, 216, 201],
      [229, 245, 249],
    ],
  },
  {
    label: ['#238b45', '#66c2a4', '#b2e2e2', '#edf8fb'],
    value: [
      [35, 139, 69],
      [102, 194, 164],
      [178, 226, 226],
      [237, 248, 251],
    ],
  },
  {
    label: ['#006d2c', '#2ca25f', '#66c2a4', '#b2e2e2', '#edf8fb'],
    value: [
      [0, 109, 44],
      [44, 162, 95],
      [102, 194, 164],
      [178, 226, 226],
      [237, 248, 251],
    ],
  },
  {
    label: ['#006d2c', '#2ca25f', '#66c2a4', '#99d8c9', '#ccece6', '#edf8fb'],
    value: [
      [0, 109, 44],
      [44, 162, 95],
      [102, 194, 164],
      [153, 216, 201],
      [204, 236, 230],
      [237, 248, 251],
    ],
  },
  {
    label: [
      '#005824',
      '#238b45',
      '#41ae76',
      '#66c2a4',
      '#99d8c9',
      '#ccece6',
      '#edf8fb',
    ],
    value: [
      [0, 88, 36],
      [35, 139, 69],
      [65, 174, 118],
      [102, 194, 164],
      [153, 216, 201],
      [204, 236, 230],
      [237, 248, 251],
    ],
  },
  {
    label: [
      '#005824',
      '#238b45',
      '#41ae76',
      '#66c2a4',
      '#99d8c9',
      '#ccece6',
      '#e5f5f9',
      '#f7fcfd',
    ],
    value: [
      [0, 88, 36],
      [35, 139, 69],
      [65, 174, 118],
      [102, 194, 164],
      [153, 216, 201],
      [204, 236, 230],
      [229, 245, 249],
      [247, 252, 253],
    ],
  },
  {
    label: [
      '#00441b',
      '#006d2c',
      '#238b45',
      '#41ae76',
      '#66c2a4',
      '#99d8c9',
      '#ccece6',
      '#e5f5f9',
      '#f7fcfd',
    ],
    value: [
      [0, 68, 27],
      [0, 109, 44],
      [35, 139, 69],
      [65, 174, 118],
      [102, 194, 164],
      [153, 216, 201],
      [204, 236, 230],
      [229, 245, 249],
      [247, 252, 253],
    ],
  },
  {
    label: ['#1c9099', '#a6bddb', '#ece2f0'],
    value: [
      [28, 144, 153],
      [166, 189, 219],
      [236, 226, 240],
    ],
  },
  {
    label: ['#02818a', '#67a9cf', '#bdc9e1', '#f6eff7'],
    value: [
      [2, 129, 138],
      [103, 169, 207],
      [189, 201, 225],
      [246, 239, 247],
    ],
  },
  {
    label: ['#016c59', '#1c9099', '#67a9cf', '#bdc9e1', '#f6eff7'],
    value: [
      [1, 108, 89],
      [28, 144, 153],
      [103, 169, 207],
      [189, 201, 225],
      [246, 239, 247],
    ],
  },
  {
    label: ['#016c59', '#1c9099', '#67a9cf', '#a6bddb', '#d0d1e6', '#f6eff7'],
    value: [
      [1, 108, 89],
      [28, 144, 153],
      [103, 169, 207],
      [166, 189, 219],
      [208, 209, 230],
      [246, 239, 247],
    ],
  },
  {
    label: [
      '#016450',
      '#02818a',
      '#3690c0',
      '#67a9cf',
      '#a6bddb',
      '#d0d1e6',
      '#f6eff7',
    ],
    value: [
      [1, 100, 80],
      [2, 129, 138],
      [54, 144, 192],
      [103, 169, 207],
      [166, 189, 219],
      [208, 209, 230],
      [246, 239, 247],
    ],
  },
  {
    label: [
      '#016450',
      '#02818a',
      '#3690c0',
      '#67a9cf',
      '#a6bddb',
      '#d0d1e6',
      '#ece2f0',
      '#fff7fb',
    ],
    value: [
      [1, 100, 80],
      [2, 129, 138],
      [54, 144, 192],
      [103, 169, 207],
      [166, 189, 219],
      [208, 209, 230],
      [236, 226, 240],
      [255, 247, 251],
    ],
  },
  {
    label: [
      '#014636',
      '#016c59',
      '#02818a',
      '#3690c0',
      '#67a9cf',
      '#a6bddb',
      '#d0d1e6',
      '#ece2f0',
      '#fff7fb',
    ],
    value: [
      [1, 70, 54],
      [1, 108, 89],
      [2, 129, 138],
      [54, 144, 192],
      [103, 169, 207],
      [166, 189, 219],
      [208, 209, 230],
      [236, 226, 240],
      [255, 247, 251],
    ],
  },
  {
    label: ['#2b8cbe', '#a6bddb', '#ece7f2'],
    value: [
      [43, 140, 190],
      [166, 189, 219],
      [236, 231, 242],
    ],
  },
  {
    label: ['#0570b0', '#74a9cf', '#bdc9e1', '#f1eef6'],
    value: [
      [5, 112, 176],
      [116, 169, 207],
      [189, 201, 225],
      [241, 238, 246],
    ],
  },
  {
    label: ['#045a8d', '#2b8cbe', '#74a9cf', '#bdc9e1', '#f1eef6'],
    value: [
      [4, 90, 141],
      [43, 140, 190],
      [116, 169, 207],
      [189, 201, 225],
      [241, 238, 246],
    ],
  },
  {
    label: ['#045a8d', '#2b8cbe', '#74a9cf', '#a6bddb', '#d0d1e6', '#f1eef6'],
    value: [
      [4, 90, 141],
      [43, 140, 190],
      [116, 169, 207],
      [166, 189, 219],
      [208, 209, 230],
      [241, 238, 246],
    ],
  },
  {
    label: [
      '#034e7b',
      '#0570b0',
      '#3690c0',
      '#74a9cf',
      '#a6bddb',
      '#d0d1e6',
      '#f1eef6',
    ],
    value: [
      [3, 78, 123],
      [5, 112, 176],
      [54, 144, 192],
      [116, 169, 207],
      [166, 189, 219],
      [208, 209, 230],
      [241, 238, 246],
    ],
  },
  {
    label: [
      '#034e7b',
      '#0570b0',
      '#3690c0',
      '#74a9cf',
      '#a6bddb',
      '#d0d1e6',
      '#ece7f2',
      '#fff7fb',
    ],
    value: [
      [3, 78, 123],
      [5, 112, 176],
      [54, 144, 192],
      [116, 169, 207],
      [166, 189, 219],
      [208, 209, 230],
      [236, 231, 242],
      [255, 247, 251],
    ],
  },
  {
    label: [
      '#023858',
      '#045a8d',
      '#0570b0',
      '#3690c0',
      '#74a9cf',
      '#a6bddb',
      '#d0d1e6',
      '#ece7f2',
      '#fff7fb',
    ],
    value: [
      [2, 56, 88],
      [4, 90, 141],
      [5, 112, 176],
      [54, 144, 192],
      [116, 169, 207],
      [166, 189, 219],
      [208, 209, 230],
      [236, 231, 242],
      [255, 247, 251],
    ],
  },
  {
    label: ['#8856a7', '#9ebcda', '#e0ecf4'],
    value: [
      [136, 86, 167],
      [158, 188, 218],
      [224, 236, 244],
    ],
  },
  {
    label: ['#88419d', '#8c96c6', '#b3cde3', '#edf8fb'],
    value: [
      [136, 65, 157],
      [140, 150, 198],
      [179, 205, 227],
      [237, 248, 251],
    ],
  },
  {
    label: ['#810f7c', '#8856a7', '#8c96c6', '#b3cde3', '#edf8fb'],
    value: [
      [129, 15, 124],
      [136, 86, 167],
      [140, 150, 198],
      [179, 205, 227],
      [237, 248, 251],
    ],
  },
  {
    label: ['#810f7c', '#8856a7', '#8c96c6', '#9ebcda', '#bfd3e6', '#edf8fb'],
    value: [
      [129, 15, 124],
      [136, 86, 167],
      [140, 150, 198],
      [158, 188, 218],
      [191, 211, 230],
      [237, 248, 251],
    ],
  },
  {
    label: [
      '#6e016b',
      '#88419d',
      '#8c6bb1',
      '#8c96c6',
      '#9ebcda',
      '#bfd3e6',
      '#edf8fb',
    ],
    value: [
      [110, 1, 107],
      [136, 65, 157],
      [140, 107, 177],
      [140, 150, 198],
      [158, 188, 218],
      [191, 211, 230],
      [237, 248, 251],
    ],
  },
  {
    label: [
      '#6e016b',
      '#88419d',
      '#8c6bb1',
      '#8c96c6',
      '#9ebcda',
      '#bfd3e6',
      '#e0ecf4',
      '#f7fcfd',
    ],
    value: [
      [110, 1, 107],
      [136, 65, 157],
      [140, 107, 177],
      [140, 150, 198],
      [158, 188, 218],
      [191, 211, 230],
      [224, 236, 244],
      [247, 252, 253],
    ],
  },
  {
    label: [
      '#4d004b',
      '#810f7c',
      '#88419d',
      '#8c6bb1',
      '#8c96c6',
      '#9ebcda',
      '#bfd3e6',
      '#e0ecf4',
      '#f7fcfd',
    ],
    value: [
      [77, 0, 75],
      [129, 15, 124],
      [136, 65, 157],
      [140, 107, 177],
      [140, 150, 198],
      [158, 188, 218],
      [191, 211, 230],
      [224, 236, 244],
      [247, 252, 253],
    ],
  },
  {
    label: ['#c51b8a', '#fa9fb5', '#fde0dd'],
    value: [
      [197, 27, 138],
      [250, 159, 181],
      [253, 224, 221],
    ],
  },
  {
    label: ['#ae017e', '#f768a1', '#fbb4b9', '#feebe2'],
    value: [
      [174, 1, 126],
      [247, 104, 161],
      [251, 180, 185],
      [254, 235, 226],
    ],
  },
  {
    label: ['#7a0177', '#c51b8a', '#f768a1', '#fbb4b9', '#feebe2'],
    value: [
      [122, 1, 119],
      [197, 27, 138],
      [247, 104, 161],
      [251, 180, 185],
      [254, 235, 226],
    ],
  },
  {
    label: ['#7a0177', '#c51b8a', '#f768a1', '#fa9fb5', '#fcc5c0', '#feebe2'],
    value: [
      [122, 1, 119],
      [197, 27, 138],
      [247, 104, 161],
      [250, 159, 181],
      [252, 197, 192],
      [254, 235, 226],
    ],
  },
  {
    label: [
      '#7a0177',
      '#ae017e',
      '#dd3497',
      '#f768a1',
      '#fa9fb5',
      '#fcc5c0',
      '#feebe2',
    ],
    value: [
      [122, 1, 119],
      [174, 1, 126],
      [221, 52, 151],
      [247, 104, 161],
      [250, 159, 181],
      [252, 197, 192],
      [254, 235, 226],
    ],
  },
  {
    label: [
      '#7a0177',
      '#ae017e',
      '#dd3497',
      '#f768a1',
      '#fa9fb5',
      '#fcc5c0',
      '#fde0dd',
      '#fff7f3',
    ],
    value: [
      [122, 1, 119],
      [174, 1, 126],
      [221, 52, 151],
      [247, 104, 161],
      [250, 159, 181],
      [252, 197, 192],
      [253, 224, 221],
      [255, 247, 243],
    ],
  },
  {
    label: [
      '#49006a',
      '#7a0177',
      '#ae017e',
      '#dd3497',
      '#f768a1',
      '#fa9fb5',
      '#fcc5c0',
      '#fde0dd',
      '#fff7f3',
    ],
    value: [
      [73, 0, 106],
      [122, 1, 119],
      [174, 1, 126],
      [221, 52, 151],
      [247, 104, 161],
      [250, 159, 181],
      [252, 197, 192],
      [253, 224, 221],
      [255, 247, 243],
    ],
  },
  {
    label: ['#dd1c77', '#c994c7', '#e7e1ef'],
    value: [
      [221, 28, 119],
      [201, 148, 199],
      [231, 225, 239],
    ],
  },
  {
    label: ['#ce1256', '#df65b0', '#d7b5d8', '#f1eef6'],
    value: [
      [206, 18, 86],
      [223, 101, 176],
      [215, 181, 216],
      [241, 238, 246],
    ],
  },
  {
    label: ['#980043', '#dd1c77', '#df65b0', '#d7b5d8', '#f1eef6'],
    value: [
      [152, 0, 67],
      [221, 28, 119],
      [223, 101, 176],
      [215, 181, 216],
      [241, 238, 246],
    ],
  },
  {
    label: ['#980043', '#dd1c77', '#df65b0', '#c994c7', '#d4b9da', '#f1eef6'],
    value: [
      [152, 0, 67],
      [221, 28, 119],
      [223, 101, 176],
      [201, 148, 199],
      [212, 185, 218],
      [241, 238, 246],
    ],
  },
  {
    label: [
      '#91003f',
      '#ce1256',
      '#e7298a',
      '#df65b0',
      '#c994c7',
      '#d4b9da',
      '#f1eef6',
    ],
    value: [
      [145, 0, 63],
      [206, 18, 86],
      [231, 41, 138],
      [223, 101, 176],
      [201, 148, 199],
      [212, 185, 218],
      [241, 238, 246],
    ],
  },
  {
    label: [
      '#91003f',
      '#ce1256',
      '#e7298a',
      '#df65b0',
      '#c994c7',
      '#d4b9da',
      '#e7e1ef',
      '#f7f4f9',
    ],
    value: [
      [145, 0, 63],
      [206, 18, 86],
      [231, 41, 138],
      [223, 101, 176],
      [201, 148, 199],
      [212, 185, 218],
      [231, 225, 239],
      [247, 244, 249],
    ],
  },
  {
    label: [
      '#67001f',
      '#980043',
      '#ce1256',
      '#e7298a',
      '#df65b0',
      '#c994c7',
      '#d4b9da',
      '#e7e1ef',
      '#f7f4f9',
    ],
    value: [
      [103, 0, 31],
      [152, 0, 67],
      [206, 18, 86],
      [231, 41, 138],
      [223, 101, 176],
      [201, 148, 199],
      [212, 185, 218],
      [231, 225, 239],
      [247, 244, 249],
    ],
  },
  {
    label: ['#e34a33', '#fdbb84', '#fee8c8'],
    value: [
      [227, 74, 51],
      [253, 187, 132],
      [254, 232, 200],
    ],
  },
  {
    label: ['#d7301f', '#fc8d59', '#fdcc8a', '#fef0d9'],
    value: [
      [215, 48, 31],
      [252, 141, 89],
      [253, 204, 138],
      [254, 240, 217],
    ],
  },
  {
    label: ['#b30000', '#e34a33', '#fc8d59', '#fdcc8a', '#fef0d9'],
    value: [
      [179, 0, 0],
      [227, 74, 51],
      [252, 141, 89],
      [253, 204, 138],
      [254, 240, 217],
    ],
  },
  {
    label: ['#b30000', '#e34a33', '#fc8d59', '#fdbb84', '#fdd49e', '#fef0d9'],
    value: [
      [179, 0, 0],
      [227, 74, 51],
      [252, 141, 89],
      [253, 187, 132],
      [253, 212, 158],
      [254, 240, 217],
    ],
  },
  {
    label: [
      '#990000',
      '#d7301f',
      '#ef6548',
      '#fc8d59',
      '#fdbb84',
      '#fdd49e',
      '#fef0d9',
    ],
    value: [
      [153, 0, 0],
      [215, 48, 31],
      [239, 101, 72],
      [252, 141, 89],
      [253, 187, 132],
      [253, 212, 158],
      [254, 240, 217],
    ],
  },
  {
    label: [
      '#990000',
      '#d7301f',
      '#ef6548',
      '#fc8d59',
      '#fdbb84',
      '#fdd49e',
      '#fee8c8',
      '#fff7ec',
    ],
    value: [
      [153, 0, 0],
      [215, 48, 31],
      [239, 101, 72],
      [252, 141, 89],
      [253, 187, 132],
      [253, 212, 158],
      [254, 232, 200],
      [255, 247, 236],
    ],
  },
  {
    label: [
      '#7f0000',
      '#b30000',
      '#d7301f',
      '#ef6548',
      '#fc8d59',
      '#fdbb84',
      '#fdd49e',
      '#fee8c8',
      '#fff7ec',
    ],
    value: [
      [127, 0, 0],
      [179, 0, 0],
      [215, 48, 31],
      [239, 101, 72],
      [252, 141, 89],
      [253, 187, 132],
      [253, 212, 158],
      [254, 232, 200],
      [255, 247, 236],
    ],
  },
  {
    label: ['#f03b20', '#feb24c', '#ffeda0'],
    value: [
      [240, 59, 32],
      [254, 178, 76],
      [255, 237, 160],
    ],
  },
  {
    label: ['#e31a1c', '#fd8d3c', '#fecc5c', '#ffffb2'],
    value: [
      [227, 26, 28],
      [253, 141, 60],
      [254, 204, 92],
      [255, 255, 178],
    ],
  },
  {
    label: ['#bd0026', '#f03b20', '#fd8d3c', '#fecc5c', '#ffffb2'],
    value: [
      [189, 0, 38],
      [240, 59, 32],
      [253, 141, 60],
      [254, 204, 92],
      [255, 255, 178],
    ],
  },
  {
    label: ['#bd0026', '#f03b20', '#fd8d3c', '#feb24c', '#fed976', '#ffffb2'],
    value: [
      [189, 0, 38],
      [240, 59, 32],
      [253, 141, 60],
      [254, 178, 76],
      [254, 217, 118],
      [255, 255, 178],
    ],
  },
  {
    label: [
      '#b10026',
      '#e31a1c',
      '#fc4e2a',
      '#fd8d3c',
      '#feb24c',
      '#fed976',
      '#ffffb2',
    ],
    value: [
      [177, 0, 38],
      [227, 26, 28],
      [252, 78, 42],
      [253, 141, 60],
      [254, 178, 76],
      [254, 217, 118],
      [255, 255, 178],
    ],
  },
  {
    label: [
      '#b10026',
      '#e31a1c',
      '#fc4e2a',
      '#fd8d3c',
      '#feb24c',
      '#fed976',
      '#ffeda0',
      '#ffffcc',
    ],
    value: [
      [177, 0, 38],
      [227, 26, 28],
      [252, 78, 42],
      [253, 141, 60],
      [254, 178, 76],
      [254, 217, 118],
      [255, 237, 160],
      [255, 255, 204],
    ],
  },
  {
    label: [
      '#800026',
      '#bd0026',
      '#e31a1c',
      '#fc4e2a',
      '#fd8d3c',
      '#feb24c',
      '#fed976',
      '#ffeda0',
      '#ffffcc',
    ],
    value: [
      [128, 0, 38],
      [189, 0, 38],
      [227, 26, 28],
      [252, 78, 42],
      [253, 141, 60],
      [254, 178, 76],
      [254, 217, 118],
      [255, 237, 160],
      [255, 255, 204],
    ],
  },
  {
    label: ['#d95f0e', '#fec44f', '#fff7bc'],
    value: [
      [217, 95, 14],
      [254, 196, 79],
      [255, 247, 188],
    ],
  },
  {
    label: ['#cc4c02', '#fe9929', '#fed98e', '#ffffd4'],
    value: [
      [204, 76, 2],
      [254, 153, 41],
      [254, 217, 142],
      [255, 255, 212],
    ],
  },
  {
    label: ['#993404', '#d95f0e', '#fe9929', '#fed98e', '#ffffd4'],
    value: [
      [153, 52, 4],
      [217, 95, 14],
      [254, 153, 41],
      [254, 217, 142],
      [255, 255, 212],
    ],
  },
  {
    label: ['#993404', '#d95f0e', '#fe9929', '#fec44f', '#fee391', '#ffffd4'],
    value: [
      [153, 52, 4],
      [217, 95, 14],
      [254, 153, 41],
      [254, 196, 79],
      [254, 227, 145],
      [255, 255, 212],
    ],
  },
  {
    label: [
      '#8c2d04',
      '#cc4c02',
      '#ec7014',
      '#fe9929',
      '#fec44f',
      '#fee391',
      '#ffffd4',
    ],
    value: [
      [140, 45, 4],
      [204, 76, 2],
      [236, 112, 20],
      [254, 153, 41],
      [254, 196, 79],
      [254, 227, 145],
      [255, 255, 212],
    ],
  },
  {
    label: [
      '#8c2d04',
      '#cc4c02',
      '#ec7014',
      '#fe9929',
      '#fec44f',
      '#fee391',
      '#fff7bc',
      '#ffffe5',
    ],
    value: [
      [140, 45, 4],
      [204, 76, 2],
      [236, 112, 20],
      [254, 153, 41],
      [254, 196, 79],
      [254, 227, 145],
      [255, 247, 188],
      [255, 255, 229],
    ],
  },
  {
    label: [
      '#662506',
      '#993404',
      '#cc4c02',
      '#ec7014',
      '#fe9929',
      '#fec44f',
      '#fee391',
      '#fff7bc',
      '#ffffe5',
    ],
    value: [
      [102, 37, 6],
      [153, 52, 4],
      [204, 76, 2],
      [236, 112, 20],
      [254, 153, 41],
      [254, 196, 79],
      [254, 227, 145],
      [255, 247, 188],
      [255, 255, 229],
    ],
  },
  {
    label: ['#756bb1', '#bcbddc', '#efedf5'],
    value: [
      [117, 107, 177],
      [188, 189, 220],
      [239, 237, 245],
    ],
  },
  {
    label: ['#6a51a3', '#9e9ac8', '#cbc9e2', '#f2f0f7'],
    value: [
      [106, 81, 163],
      [158, 154, 200],
      [203, 201, 226],
      [242, 240, 247],
    ],
  },
  {
    label: ['#54278f', '#756bb1', '#9e9ac8', '#cbc9e2', '#f2f0f7'],
    value: [
      [84, 39, 143],
      [117, 107, 177],
      [158, 154, 200],
      [203, 201, 226],
      [242, 240, 247],
    ],
  },
  {
    label: ['#54278f', '#756bb1', '#9e9ac8', '#bcbddc', '#dadaeb', '#f2f0f7'],
    value: [
      [84, 39, 143],
      [117, 107, 177],
      [158, 154, 200],
      [188, 189, 220],
      [218, 218, 235],
      [242, 240, 247],
    ],
  },
  {
    label: [
      '#4a1486',
      '#6a51a3',
      '#807dba',
      '#9e9ac8',
      '#bcbddc',
      '#dadaeb',
      '#f2f0f7',
    ],
    value: [
      [74, 20, 134],
      [106, 81, 163],
      [128, 125, 186],
      [158, 154, 200],
      [188, 189, 220],
      [218, 218, 235],
      [242, 240, 247],
    ],
  },
  {
    label: [
      '#4a1486',
      '#6a51a3',
      '#807dba',
      '#9e9ac8',
      '#bcbddc',
      '#dadaeb',
      '#efedf5',
      '#fcfbfd',
    ],
    value: [
      [74, 20, 134],
      [106, 81, 163],
      [128, 125, 186],
      [158, 154, 200],
      [188, 189, 220],
      [218, 218, 235],
      [239, 237, 245],
      [252, 251, 253],
    ],
  },
  {
    label: [
      '#3f007d',
      '#54278f',
      '#6a51a3',
      '#807dba',
      '#9e9ac8',
      '#bcbddc',
      '#dadaeb',
      '#efedf5',
      '#fcfbfd',
    ],
    value: [
      [63, 0, 125],
      [84, 39, 143],
      [106, 81, 163],
      [128, 125, 186],
      [158, 154, 200],
      [188, 189, 220],
      [218, 218, 235],
      [239, 237, 245],
      [252, 251, 253],
    ],
  },
  {
    label: ['#3182bd', '#9ecae1', '#deebf7'],
    value: [
      [49, 130, 189],
      [158, 202, 225],
      [222, 235, 247],
    ],
  },
  {
    label: ['#2171b5', '#6baed6', '#bdd7e7', '#eff3ff'],
    value: [
      [33, 113, 181],
      [107, 174, 214],
      [189, 215, 231],
      [239, 243, 255],
    ],
  },
  {
    label: ['#08519c', '#3182bd', '#6baed6', '#bdd7e7', '#eff3ff'],
    value: [
      [8, 81, 156],
      [49, 130, 189],
      [107, 174, 214],
      [189, 215, 231],
      [239, 243, 255],
    ],
  },
  {
    label: ['#08519c', '#3182bd', '#6baed6', '#9ecae1', '#c6dbef', '#eff3ff'],
    value: [
      [8, 81, 156],
      [49, 130, 189],
      [107, 174, 214],
      [158, 202, 225],
      [198, 219, 239],
      [239, 243, 255],
    ],
  },
  {
    label: [
      '#084594',
      '#2171b5',
      '#4292c6',
      '#6baed6',
      '#9ecae1',
      '#c6dbef',
      '#eff3ff',
    ],
    value: [
      [8, 69, 148],
      [33, 113, 181],
      [66, 146, 198],
      [107, 174, 214],
      [158, 202, 225],
      [198, 219, 239],
      [239, 243, 255],
    ],
  },
  {
    label: [
      '#084594',
      '#2171b5',
      '#4292c6',
      '#6baed6',
      '#9ecae1',
      '#c6dbef',
      '#deebf7',
      '#f7fbff',
    ],
    value: [
      [8, 69, 148],
      [33, 113, 181],
      [66, 146, 198],
      [107, 174, 214],
      [158, 202, 225],
      [198, 219, 239],
      [222, 235, 247],
      [247, 251, 255],
    ],
  },
  {
    label: [
      '#08306b',
      '#08519c',
      '#2171b5',
      '#4292c6',
      '#6baed6',
      '#9ecae1',
      '#c6dbef',
      '#deebf7',
      '#f7fbff',
    ],
    value: [
      [8, 48, 107],
      [8, 81, 156],
      [33, 113, 181],
      [66, 146, 198],
      [107, 174, 214],
      [158, 202, 225],
      [198, 219, 239],
      [222, 235, 247],
      [247, 251, 255],
    ],
  },
  {
    label: ['#31a354', '#a1d99b', '#e5f5e0'],
    value: [
      [49, 163, 84],
      [161, 217, 155],
      [229, 245, 224],
    ],
  },
  {
    label: ['#238b45', '#74c476', '#bae4b3', '#edf8e9'],
    value: [
      [35, 139, 69],
      [116, 196, 118],
      [186, 228, 179],
      [237, 248, 233],
    ],
  },
  {
    label: ['#006d2c', '#31a354', '#74c476', '#bae4b3', '#edf8e9'],
    value: [
      [0, 109, 44],
      [49, 163, 84],
      [116, 196, 118],
      [186, 228, 179],
      [237, 248, 233],
    ],
  },
  {
    label: ['#006d2c', '#31a354', '#74c476', '#a1d99b', '#c7e9c0', '#edf8e9'],
    value: [
      [0, 109, 44],
      [49, 163, 84],
      [116, 196, 118],
      [161, 217, 155],
      [199, 233, 192],
      [237, 248, 233],
    ],
  },
  {
    label: [
      '#005a32',
      '#238b45',
      '#41ab5d',
      '#74c476',
      '#a1d99b',
      '#c7e9c0',
      '#edf8e9',
    ],
    value: [
      [0, 90, 50],
      [35, 139, 69],
      [65, 171, 93],
      [116, 196, 118],
      [161, 217, 155],
      [199, 233, 192],
      [237, 248, 233],
    ],
  },
  {
    label: [
      '#005a32',
      '#238b45',
      '#41ab5d',
      '#74c476',
      '#a1d99b',
      '#c7e9c0',
      '#e5f5e0',
      '#f7fcf5',
    ],
    value: [
      [0, 90, 50],
      [35, 139, 69],
      [65, 171, 93],
      [116, 196, 118],
      [161, 217, 155],
      [199, 233, 192],
      [229, 245, 224],
      [247, 252, 245],
    ],
  },
  {
    label: [
      '#00441b',
      '#006d2c',
      '#238b45',
      '#41ab5d',
      '#74c476',
      '#a1d99b',
      '#c7e9c0',
      '#e5f5e0',
      '#f7fcf5',
    ],
    value: [
      [0, 68, 27],
      [0, 109, 44],
      [35, 139, 69],
      [65, 171, 93],
      [116, 196, 118],
      [161, 217, 155],
      [199, 233, 192],
      [229, 245, 224],
      [247, 252, 245],
    ],
  },
  {
    label: ['#e6550d', '#fdae6b', '#fee6ce'],
    value: [
      [230, 85, 13],
      [253, 174, 107],
      [254, 230, 206],
    ],
  },
  {
    label: ['#d94701', '#fd8d3c', '#fdbe85', '#feedde'],
    value: [
      [217, 71, 1],
      [253, 141, 60],
      [253, 190, 133],
      [254, 237, 222],
    ],
  },
  {
    label: ['#a63603', '#e6550d', '#fd8d3c', '#fdbe85', '#feedde'],
    value: [
      [166, 54, 3],
      [230, 85, 13],
      [253, 141, 60],
      [253, 190, 133],
      [254, 237, 222],
    ],
  },
  {
    label: ['#a63603', '#e6550d', '#fd8d3c', '#fdae6b', '#fdd0a2', '#feedde'],
    value: [
      [166, 54, 3],
      [230, 85, 13],
      [253, 141, 60],
      [253, 174, 107],
      [253, 208, 162],
      [254, 237, 222],
    ],
  },
  {
    label: [
      '#8c2d04',
      '#d94801',
      '#f16913',
      '#fd8d3c',
      '#fdae6b',
      '#fdd0a2',
      '#feedde',
    ],
    value: [
      [140, 45, 4],
      [217, 72, 1],
      [241, 105, 19],
      [253, 141, 60],
      [253, 174, 107],
      [253, 208, 162],
      [254, 237, 222],
    ],
  },
  {
    label: [
      '#8c2d04',
      '#d94801',
      '#f16913',
      '#fd8d3c',
      '#fdae6b',
      '#fdd0a2',
      '#fee6ce',
      '#fff5eb',
    ],
    value: [
      [140, 45, 4],
      [217, 72, 1],
      [241, 105, 19],
      [253, 141, 60],
      [253, 174, 107],
      [253, 208, 162],
      [254, 230, 206],
      [255, 245, 235],
    ],
  },
  {
    label: [
      '#7f2704',
      '#a63603',
      '#d94801',
      '#f16913',
      '#fd8d3c',
      '#fdae6b',
      '#fdd0a2',
      '#fee6ce',
      '#fff5eb',
    ],
    value: [
      [127, 39, 4],
      [166, 54, 3],
      [217, 72, 1],
      [241, 105, 19],
      [253, 141, 60],
      [253, 174, 107],
      [253, 208, 162],
      [254, 230, 206],
      [255, 245, 235],
    ],
  },
  {
    label: ['#de2d26', '#fc9272', '#fee0d2'],
    value: [
      [222, 45, 38],
      [252, 146, 114],
      [254, 224, 210],
    ],
  },
  {
    label: ['#cb181d', '#fb6a4a', '#fcae91', '#fee5d9'],
    value: [
      [203, 24, 29],
      [251, 106, 74],
      [252, 174, 145],
      [254, 229, 217],
    ],
  },
  {
    label: ['#a50f15', '#de2d26', '#fb6a4a', '#fcae91', '#fee5d9'],
    value: [
      [165, 15, 21],
      [222, 45, 38],
      [251, 106, 74],
      [252, 174, 145],
      [254, 229, 217],
    ],
  },
  {
    label: ['#a50f15', '#de2d26', '#fb6a4a', '#fc9272', '#fcbba1', '#fee5d9'],
    value: [
      [165, 15, 21],
      [222, 45, 38],
      [251, 106, 74],
      [252, 146, 114],
      [252, 187, 161],
      [254, 229, 217],
    ],
  },
  {
    label: [
      '#99000d',
      '#cb181d',
      '#ef3b2c',
      '#fb6a4a',
      '#fc9272',
      '#fcbba1',
      '#fee5d9',
    ],
    value: [
      [153, 0, 13],
      [203, 24, 29],
      [239, 59, 44],
      [251, 106, 74],
      [252, 146, 114],
      [252, 187, 161],
      [254, 229, 217],
    ],
  },
  {
    label: [
      '#99000d',
      '#cb181d',
      '#ef3b2c',
      '#fb6a4a',
      '#fc9272',
      '#fcbba1',
      '#fee0d2',
      '#fff5f0',
    ],
    value: [
      [153, 0, 13],
      [203, 24, 29],
      [239, 59, 44],
      [251, 106, 74],
      [252, 146, 114],
      [252, 187, 161],
      [254, 224, 210],
      [255, 245, 240],
    ],
  },
  {
    label: [
      '#67000d',
      '#a50f15',
      '#cb181d',
      '#ef3b2c',
      '#fb6a4a',
      '#fc9272',
      '#fcbba1',
      '#fee0d2',
      '#fff5f0',
    ],
    value: [
      [103, 0, 13],
      [165, 15, 21],
      [203, 24, 29],
      [239, 59, 44],
      [251, 106, 74],
      [252, 146, 114],
      [252, 187, 161],
      [254, 224, 210],
      [255, 245, 240],
    ],
  },
  {
    label: ['#636363', '#bdbdbd', '#f0f0f0'],
    value: [
      [99, 99, 99],
      [189, 189, 189],
      [240, 240, 240],
    ],
  },
  {
    label: ['#525252', '#969696', '#cccccc', '#f7f7f7'],
    value: [
      [82, 82, 82],
      [150, 150, 150],
      [204, 204, 204],
      [247, 247, 247],
    ],
  },
  {
    label: ['#252525', '#636363', '#969696', '#cccccc', '#f7f7f7'],
    value: [
      [37, 37, 37],
      [99, 99, 99],
      [150, 150, 150],
      [204, 204, 204],
      [247, 247, 247],
    ],
  },
  {
    label: ['#252525', '#636363', '#969696', '#bdbdbd', '#d9d9d9', '#f7f7f7'],
    value: [
      [37, 37, 37],
      [99, 99, 99],
      [150, 150, 150],
      [189, 189, 189],
      [217, 217, 217],
      [247, 247, 247],
    ],
  },
  {
    label: [
      '#252525',
      '#525252',
      '#737373',
      '#969696',
      '#bdbdbd',
      '#d9d9d9',
      '#f7f7f7',
    ],
    value: [
      [37, 37, 37],
      [82, 82, 82],
      [115, 115, 115],
      [150, 150, 150],
      [189, 189, 189],
      [217, 217, 217],
      [247, 247, 247],
    ],
  },
  {
    label: [
      '#252525',
      '#525252',
      '#737373',
      '#969696',
      '#bdbdbd',
      '#d9d9d9',
      '#f0f0f0',
      '#ffffff',
    ],
    value: [
      [37, 37, 37],
      [82, 82, 82],
      [115, 115, 115],
      [150, 150, 150],
      [189, 189, 189],
      [217, 217, 217],
      [240, 240, 240],
      [255, 255, 255],
    ],
  },
  {
    label: [
      '#000000',
      '#252525',
      '#525252',
      '#737373',
      '#969696',
      '#bdbdbd',
      '#d9d9d9',
      '#f0f0f0',
      '#ffffff',
    ],
    value: [
      [0, 0, 0],
      [37, 37, 37],
      [82, 82, 82],
      [115, 115, 115],
      [150, 150, 150],
      [189, 189, 189],
      [217, 217, 217],
      [240, 240, 240],
      [255, 255, 255],
    ],
  },
  {
    label: ['#998ec3', '#f7f7f7', '#f1a340'],
    value: [
      [153, 142, 195],
      [247, 247, 247],
      [241, 163, 64],
    ],
  },
  {
    label: ['#5e3c99', '#b2abd2', '#fdb863', '#e66101'],
    value: [
      [94, 60, 153],
      [178, 171, 210],
      [253, 184, 99],
      [230, 97, 1],
    ],
  },
  {
    label: ['#5e3c99', '#b2abd2', '#f7f7f7', '#fdb863', '#e66101'],
    value: [
      [94, 60, 153],
      [178, 171, 210],
      [247, 247, 247],
      [253, 184, 99],
      [230, 97, 1],
    ],
  },
  {
    label: ['#542788', '#998ec3', '#d8daeb', '#fee0b6', '#f1a340', '#b35806'],
    value: [
      [84, 39, 136],
      [153, 142, 195],
      [216, 218, 235],
      [254, 224, 182],
      [241, 163, 64],
      [179, 88, 6],
    ],
  },
  {
    label: [
      '#542788',
      '#998ec3',
      '#d8daeb',
      '#f7f7f7',
      '#fee0b6',
      '#f1a340',
      '#b35806',
    ],
    value: [
      [84, 39, 136],
      [153, 142, 195],
      [216, 218, 235],
      [247, 247, 247],
      [254, 224, 182],
      [241, 163, 64],
      [179, 88, 6],
    ],
  },
  {
    label: [
      '#542788',
      '#8073ac',
      '#b2abd2',
      '#d8daeb',
      '#fee0b6',
      '#fdb863',
      '#e08214',
      '#b35806',
    ],
    value: [
      [84, 39, 136],
      [128, 115, 172],
      [178, 171, 210],
      [216, 218, 235],
      [254, 224, 182],
      [253, 184, 99],
      [224, 130, 20],
      [179, 88, 6],
    ],
  },
  {
    label: [
      '#542788',
      '#8073ac',
      '#b2abd2',
      '#d8daeb',
      '#f7f7f7',
      '#fee0b6',
      '#fdb863',
      '#e08214',
      '#b35806',
    ],
    value: [
      [84, 39, 136],
      [128, 115, 172],
      [178, 171, 210],
      [216, 218, 235],
      [247, 247, 247],
      [254, 224, 182],
      [253, 184, 99],
      [224, 130, 20],
      [179, 88, 6],
    ],
  },
  {
    label: [
      '#2d004b',
      '#542788',
      '#8073ac',
      '#b2abd2',
      '#d8daeb',
      '#fee0b6',
      '#fdb863',
      '#e08214',
      '#b35806',
      '#7f3b08',
    ],
    value: [
      [45, 0, 75],
      [84, 39, 136],
      [128, 115, 172],
      [178, 171, 210],
      [216, 218, 235],
      [254, 224, 182],
      [253, 184, 99],
      [224, 130, 20],
      [179, 88, 6],
      [127, 59, 8],
    ],
  },
  {
    label: [
      '#2d004b',
      '#542788',
      '#8073ac',
      '#b2abd2',
      '#d8daeb',
      '#f7f7f7',
      '#fee0b6',
      '#fdb863',
      '#e08214',
      '#b35806',
      '#7f3b08',
    ],
    value: [
      [45, 0, 75],
      [84, 39, 136],
      [128, 115, 172],
      [178, 171, 210],
      [216, 218, 235],
      [247, 247, 247],
      [254, 224, 182],
      [253, 184, 99],
      [224, 130, 20],
      [179, 88, 6],
      [127, 59, 8],
    ],
  },
  {
    label: ['#5ab4ac', '#f5f5f5', '#d8b365'],
    value: [
      [90, 180, 172],
      [245, 245, 245],
      [216, 179, 101],
    ],
  },
  {
    label: ['#018571', '#80cdc1', '#dfc27d', '#a6611a'],
    value: [
      [1, 133, 113],
      [128, 205, 193],
      [223, 194, 125],
      [166, 97, 26],
    ],
  },
  {
    label: ['#018571', '#80cdc1', '#f5f5f5', '#dfc27d', '#a6611a'],
    value: [
      [1, 133, 113],
      [128, 205, 193],
      [245, 245, 245],
      [223, 194, 125],
      [166, 97, 26],
    ],
  },
  {
    label: ['#01665e', '#5ab4ac', '#c7eae5', '#f6e8c3', '#d8b365', '#8c510a'],
    value: [
      [1, 102, 94],
      [90, 180, 172],
      [199, 234, 229],
      [246, 232, 195],
      [216, 179, 101],
      [140, 81, 10],
    ],
  },
  {
    label: [
      '#01665e',
      '#5ab4ac',
      '#c7eae5',
      '#f5f5f5',
      '#f6e8c3',
      '#d8b365',
      '#8c510a',
    ],
    value: [
      [1, 102, 94],
      [90, 180, 172],
      [199, 234, 229],
      [245, 245, 245],
      [246, 232, 195],
      [216, 179, 101],
      [140, 81, 10],
    ],
  },
  {
    label: [
      '#01665e',
      '#35978f',
      '#80cdc1',
      '#c7eae5',
      '#f6e8c3',
      '#dfc27d',
      '#bf812d',
      '#8c510a',
    ],
    value: [
      [1, 102, 94],
      [53, 151, 143],
      [128, 205, 193],
      [199, 234, 229],
      [246, 232, 195],
      [223, 194, 125],
      [191, 129, 45],
      [140, 81, 10],
    ],
  },
  {
    label: [
      '#01665e',
      '#35978f',
      '#80cdc1',
      '#c7eae5',
      '#f5f5f5',
      '#f6e8c3',
      '#dfc27d',
      '#bf812d',
      '#8c510a',
    ],
    value: [
      [1, 102, 94],
      [53, 151, 143],
      [128, 205, 193],
      [199, 234, 229],
      [245, 245, 245],
      [246, 232, 195],
      [223, 194, 125],
      [191, 129, 45],
      [140, 81, 10],
    ],
  },
  {
    label: [
      '#003c30',
      '#01665e',
      '#35978f',
      '#80cdc1',
      '#c7eae5',
      '#f6e8c3',
      '#dfc27d',
      '#bf812d',
      '#8c510a',
      '#543005',
    ],
    value: [
      [0, 60, 48],
      [1, 102, 94],
      [53, 151, 143],
      [128, 205, 193],
      [199, 234, 229],
      [246, 232, 195],
      [223, 194, 125],
      [191, 129, 45],
      [140, 81, 10],
      [84, 48, 5],
    ],
  },
  {
    label: [
      '#003c30',
      '#01665e',
      '#35978f',
      '#80cdc1',
      '#c7eae5',
      '#f5f5f5',
      '#f6e8c3',
      '#dfc27d',
      '#bf812d',
      '#8c510a',
      '#543005',
    ],
    value: [
      [0, 60, 48],
      [1, 102, 94],
      [53, 151, 143],
      [128, 205, 193],
      [199, 234, 229],
      [245, 245, 245],
      [246, 232, 195],
      [223, 194, 125],
      [191, 129, 45],
      [140, 81, 10],
      [84, 48, 5],
    ],
  },
  {
    label: ['#7fbf7b', '#f7f7f7', '#af8dc3'],
    value: [
      [127, 191, 123],
      [247, 247, 247],
      [175, 141, 195],
    ],
  },
  {
    label: ['#008837', '#a6dba0', '#c2a5cf', '#7b3294'],
    value: [
      [0, 136, 55],
      [166, 219, 160],
      [194, 165, 207],
      [123, 50, 148],
    ],
  },
  {
    label: ['#008837', '#a6dba0', '#f7f7f7', '#c2a5cf', '#7b3294'],
    value: [
      [0, 136, 55],
      [166, 219, 160],
      [247, 247, 247],
      [194, 165, 207],
      [123, 50, 148],
    ],
  },
  {
    label: ['#1b7837', '#7fbf7b', '#d9f0d3', '#e7d4e8', '#af8dc3', '#762a83'],
    value: [
      [27, 120, 55],
      [127, 191, 123],
      [217, 240, 211],
      [231, 212, 232],
      [175, 141, 195],
      [118, 42, 131],
    ],
  },
  {
    label: [
      '#1b7837',
      '#7fbf7b',
      '#d9f0d3',
      '#f7f7f7',
      '#e7d4e8',
      '#af8dc3',
      '#762a83',
    ],
    value: [
      [27, 120, 55],
      [127, 191, 123],
      [217, 240, 211],
      [247, 247, 247],
      [231, 212, 232],
      [175, 141, 195],
      [118, 42, 131],
    ],
  },
  {
    label: [
      '#1b7837',
      '#5aae61',
      '#a6dba0',
      '#d9f0d3',
      '#e7d4e8',
      '#c2a5cf',
      '#9970ab',
      '#762a83',
    ],
    value: [
      [27, 120, 55],
      [90, 174, 97],
      [166, 219, 160],
      [217, 240, 211],
      [231, 212, 232],
      [194, 165, 207],
      [153, 112, 171],
      [118, 42, 131],
    ],
  },
  {
    label: [
      '#1b7837',
      '#5aae61',
      '#a6dba0',
      '#d9f0d3',
      '#f7f7f7',
      '#e7d4e8',
      '#c2a5cf',
      '#9970ab',
      '#762a83',
    ],
    value: [
      [27, 120, 55],
      [90, 174, 97],
      [166, 219, 160],
      [217, 240, 211],
      [247, 247, 247],
      [231, 212, 232],
      [194, 165, 207],
      [153, 112, 171],
      [118, 42, 131],
    ],
  },
  {
    label: [
      '#00441b',
      '#1b7837',
      '#5aae61',
      '#a6dba0',
      '#d9f0d3',
      '#e7d4e8',
      '#c2a5cf',
      '#9970ab',
      '#762a83',
      '#40004b',
    ],
    value: [
      [0, 68, 27],
      [27, 120, 55],
      [90, 174, 97],
      [166, 219, 160],
      [217, 240, 211],
      [231, 212, 232],
      [194, 165, 207],
      [153, 112, 171],
      [118, 42, 131],
      [64, 0, 75],
    ],
  },
  {
    label: [
      '#00441b',
      '#1b7837',
      '#5aae61',
      '#a6dba0',
      '#d9f0d3',
      '#f7f7f7',
      '#e7d4e8',
      '#c2a5cf',
      '#9970ab',
      '#762a83',
      '#40004b',
    ],
    value: [
      [0, 68, 27],
      [27, 120, 55],
      [90, 174, 97],
      [166, 219, 160],
      [217, 240, 211],
      [247, 247, 247],
      [231, 212, 232],
      [194, 165, 207],
      [153, 112, 171],
      [118, 42, 131],
      [64, 0, 75],
    ],
  },
  {
    label: ['#a1d76a', '#f7f7f7', '#e9a3c9'],
    value: [
      [161, 215, 106],
      [247, 247, 247],
      [233, 163, 201],
    ],
  },
  {
    label: ['#4dac26', '#b8e186', '#f1b6da', '#d01c8b'],
    value: [
      [77, 172, 38],
      [184, 225, 134],
      [241, 182, 218],
      [208, 28, 139],
    ],
  },
  {
    label: ['#4dac26', '#b8e186', '#f7f7f7', '#f1b6da', '#d01c8b'],
    value: [
      [77, 172, 38],
      [184, 225, 134],
      [247, 247, 247],
      [241, 182, 218],
      [208, 28, 139],
    ],
  },
  {
    label: ['#4d9221', '#a1d76a', '#e6f5d0', '#fde0ef', '#e9a3c9', '#c51b7d'],
    value: [
      [77, 146, 33],
      [161, 215, 106],
      [230, 245, 208],
      [253, 224, 239],
      [233, 163, 201],
      [197, 27, 125],
    ],
  },
  {
    label: [
      '#4d9221',
      '#a1d76a',
      '#e6f5d0',
      '#f7f7f7',
      '#fde0ef',
      '#e9a3c9',
      '#c51b7d',
    ],
    value: [
      [77, 146, 33],
      [161, 215, 106],
      [230, 245, 208],
      [247, 247, 247],
      [253, 224, 239],
      [233, 163, 201],
      [197, 27, 125],
    ],
  },
  {
    label: [
      '#4d9221',
      '#7fbc41',
      '#b8e186',
      '#e6f5d0',
      '#fde0ef',
      '#f1b6da',
      '#de77ae',
      '#c51b7d',
    ],
    value: [
      [77, 146, 33],
      [127, 188, 65],
      [184, 225, 134],
      [230, 245, 208],
      [253, 224, 239],
      [241, 182, 218],
      [222, 119, 174],
      [197, 27, 125],
    ],
  },
  {
    label: [
      '#4d9221',
      '#7fbc41',
      '#b8e186',
      '#e6f5d0',
      '#f7f7f7',
      '#fde0ef',
      '#f1b6da',
      '#de77ae',
      '#c51b7d',
    ],
    value: [
      [77, 146, 33],
      [127, 188, 65],
      [184, 225, 134],
      [230, 245, 208],
      [247, 247, 247],
      [253, 224, 239],
      [241, 182, 218],
      [222, 119, 174],
      [197, 27, 125],
    ],
  },
  {
    label: [
      '#276419',
      '#4d9221',
      '#7fbc41',
      '#b8e186',
      '#e6f5d0',
      '#fde0ef',
      '#f1b6da',
      '#de77ae',
      '#c51b7d',
      '#8e0152',
    ],
    value: [
      [39, 100, 25],
      [77, 146, 33],
      [127, 188, 65],
      [184, 225, 134],
      [230, 245, 208],
      [253, 224, 239],
      [241, 182, 218],
      [222, 119, 174],
      [197, 27, 125],
      [142, 1, 82],
    ],
  },
  {
    label: [
      '#276419',
      '#4d9221',
      '#7fbc41',
      '#b8e186',
      '#e6f5d0',
      '#f7f7f7',
      '#fde0ef',
      '#f1b6da',
      '#de77ae',
      '#c51b7d',
      '#8e0152',
    ],
    value: [
      [39, 100, 25],
      [77, 146, 33],
      [127, 188, 65],
      [184, 225, 134],
      [230, 245, 208],
      [247, 247, 247],
      [253, 224, 239],
      [241, 182, 218],
      [222, 119, 174],
      [197, 27, 125],
      [142, 1, 82],
    ],
  },
  {
    label: ['#67a9cf', '#f7f7f7', '#ef8a62'],
    value: [
      [103, 169, 207],
      [247, 247, 247],
      [239, 138, 98],
    ],
  },
  {
    label: ['#0571b0', '#92c5de', '#f4a582', '#ca0020'],
    value: [
      [5, 113, 176],
      [146, 197, 222],
      [244, 165, 130],
      [202, 0, 32],
    ],
  },
  {
    label: ['#0571b0', '#92c5de', '#f7f7f7', '#f4a582', '#ca0020'],
    value: [
      [5, 113, 176],
      [146, 197, 222],
      [247, 247, 247],
      [244, 165, 130],
      [202, 0, 32],
    ],
  },
  {
    label: ['#2166ac', '#67a9cf', '#d1e5f0', '#fddbc7', '#ef8a62', '#b2182b'],
    value: [
      [33, 102, 172],
      [103, 169, 207],
      [209, 229, 240],
      [253, 219, 199],
      [239, 138, 98],
      [178, 24, 43],
    ],
  },
  {
    label: [
      '#2166ac',
      '#67a9cf',
      '#d1e5f0',
      '#f7f7f7',
      '#fddbc7',
      '#ef8a62',
      '#b2182b',
    ],
    value: [
      [33, 102, 172],
      [103, 169, 207],
      [209, 229, 240],
      [247, 247, 247],
      [253, 219, 199],
      [239, 138, 98],
      [178, 24, 43],
    ],
  },
  {
    label: [
      '#2166ac',
      '#4393c3',
      '#92c5de',
      '#d1e5f0',
      '#fddbc7',
      '#f4a582',
      '#d6604d',
      '#b2182b',
    ],
    value: [
      [33, 102, 172],
      [67, 147, 195],
      [146, 197, 222],
      [209, 229, 240],
      [253, 219, 199],
      [244, 165, 130],
      [214, 96, 77],
      [178, 24, 43],
    ],
  },
  {
    label: [
      '#2166ac',
      '#4393c3',
      '#92c5de',
      '#d1e5f0',
      '#f7f7f7',
      '#fddbc7',
      '#f4a582',
      '#d6604d',
      '#b2182b',
    ],
    value: [
      [33, 102, 172],
      [67, 147, 195],
      [146, 197, 222],
      [209, 229, 240],
      [247, 247, 247],
      [253, 219, 199],
      [244, 165, 130],
      [214, 96, 77],
      [178, 24, 43],
    ],
  },
  {
    label: [
      '#053061',
      '#2166ac',
      '#4393c3',
      '#92c5de',
      '#d1e5f0',
      '#fddbc7',
      '#f4a582',
      '#d6604d',
      '#b2182b',
      '#67001f',
    ],
    value: [
      [5, 48, 97],
      [33, 102, 172],
      [67, 147, 195],
      [146, 197, 222],
      [209, 229, 240],
      [253, 219, 199],
      [244, 165, 130],
      [214, 96, 77],
      [178, 24, 43],
      [103, 0, 31],
    ],
  },
  {
    label: [
      '#053061',
      '#2166ac',
      '#4393c3',
      '#92c5de',
      '#d1e5f0',
      '#f7f7f7',
      '#fddbc7',
      '#f4a582',
      '#d6604d',
      '#b2182b',
      '#67001f',
    ],
    value: [
      [5, 48, 97],
      [33, 102, 172],
      [67, 147, 195],
      [146, 197, 222],
      [209, 229, 240],
      [247, 247, 247],
      [253, 219, 199],
      [244, 165, 130],
      [214, 96, 77],
      [178, 24, 43],
      [103, 0, 31],
    ],
  },
  {
    label: ['#999999', '#ffffff', '#ef8a62'],
    value: [
      [153, 153, 153],
      [255, 255, 255],
      [239, 138, 98],
    ],
  },
  {
    label: ['#404040', '#bababa', '#f4a582', '#ca0020'],
    value: [
      [64, 64, 64],
      [186, 186, 186],
      [244, 165, 130],
      [202, 0, 32],
    ],
  },
  {
    label: ['#404040', '#bababa', '#ffffff', '#f4a582', '#ca0020'],
    value: [
      [64, 64, 64],
      [186, 186, 186],
      [255, 255, 255],
      [244, 165, 130],
      [202, 0, 32],
    ],
  },
  {
    label: ['#4d4d4d', '#999999', '#e0e0e0', '#fddbc7', '#ef8a62', '#b2182b'],
    value: [
      [77, 77, 77],
      [153, 153, 153],
      [224, 224, 224],
      [253, 219, 199],
      [239, 138, 98],
      [178, 24, 43],
    ],
  },
  {
    label: [
      '#4d4d4d',
      '#999999',
      '#e0e0e0',
      '#ffffff',
      '#fddbc7',
      '#ef8a62',
      '#b2182b',
    ],
    value: [
      [77, 77, 77],
      [153, 153, 153],
      [224, 224, 224],
      [255, 255, 255],
      [253, 219, 199],
      [239, 138, 98],
      [178, 24, 43],
    ],
  },
  {
    label: [
      '#4d4d4d',
      '#878787',
      '#bababa',
      '#e0e0e0',
      '#fddbc7',
      '#f4a582',
      '#d6604d',
      '#b2182b',
    ],
    value: [
      [77, 77, 77],
      [135, 135, 135],
      [186, 186, 186],
      [224, 224, 224],
      [253, 219, 199],
      [244, 165, 130],
      [214, 96, 77],
      [178, 24, 43],
    ],
  },
  {
    label: [
      '#4d4d4d',
      '#878787',
      '#bababa',
      '#e0e0e0',
      '#ffffff',
      '#fddbc7',
      '#f4a582',
      '#d6604d',
      '#b2182b',
    ],
    value: [
      [77, 77, 77],
      [135, 135, 135],
      [186, 186, 186],
      [224, 224, 224],
      [255, 255, 255],
      [253, 219, 199],
      [244, 165, 130],
      [214, 96, 77],
      [178, 24, 43],
    ],
  },
  {
    label: [
      '#1a1a1a',
      '#4d4d4d',
      '#878787',
      '#bababa',
      '#e0e0e0',
      '#fddbc7',
      '#f4a582',
      '#d6604d',
      '#b2182b',
      '#67001f',
    ],
    value: [
      [26, 26, 26],
      [77, 77, 77],
      [135, 135, 135],
      [186, 186, 186],
      [224, 224, 224],
      [253, 219, 199],
      [244, 165, 130],
      [214, 96, 77],
      [178, 24, 43],
      [103, 0, 31],
    ],
  },
  {
    label: [
      '#1a1a1a',
      '#4d4d4d',
      '#878787',
      '#bababa',
      '#e0e0e0',
      '#ffffff',
      '#fddbc7',
      '#f4a582',
      '#d6604d',
      '#b2182b',
      '#67001f',
    ],
    value: [
      [26, 26, 26],
      [77, 77, 77],
      [135, 135, 135],
      [186, 186, 186],
      [224, 224, 224],
      [255, 255, 255],
      [253, 219, 199],
      [244, 165, 130],
      [214, 96, 77],
      [178, 24, 43],
      [103, 0, 31],
    ],
  },
  {
    label: ['#91bfdb', '#ffffbf', '#fc8d59'],
    value: [
      [145, 191, 219],
      [255, 255, 191],
      [252, 141, 89],
    ],
  },
  {
    label: ['#2c7bb6', '#abd9e9', '#fdae61', '#d7191c'],
    value: [
      [44, 123, 182],
      [171, 217, 233],
      [253, 174, 97],
      [215, 25, 28],
    ],
  },
  {
    label: ['#2c7bb6', '#abd9e9', '#ffffbf', '#fdae61', '#d7191c'],
    value: [
      [44, 123, 182],
      [171, 217, 233],
      [255, 255, 191],
      [253, 174, 97],
      [215, 25, 28],
    ],
  },
  {
    label: ['#4575b4', '#91bfdb', '#e0f3f8', '#fee090', '#fc8d59', '#d73027'],
    value: [
      [69, 117, 180],
      [145, 191, 219],
      [224, 243, 248],
      [254, 224, 144],
      [252, 141, 89],
      [215, 48, 39],
    ],
  },
  {
    label: [
      '#4575b4',
      '#91bfdb',
      '#e0f3f8',
      '#ffffbf',
      '#fee090',
      '#fc8d59',
      '#d73027',
    ],
    value: [
      [69, 117, 180],
      [145, 191, 219],
      [224, 243, 248],
      [255, 255, 191],
      [254, 224, 144],
      [252, 141, 89],
      [215, 48, 39],
    ],
  },
  {
    label: [
      '#4575b4',
      '#74add1',
      '#abd9e9',
      '#e0f3f8',
      '#fee090',
      '#fdae61',
      '#f46d43',
      '#d73027',
    ],
    value: [
      [69, 117, 180],
      [116, 173, 209],
      [171, 217, 233],
      [224, 243, 248],
      [254, 224, 144],
      [253, 174, 97],
      [244, 109, 67],
      [215, 48, 39],
    ],
  },
  {
    label: [
      '#4575b4',
      '#74add1',
      '#abd9e9',
      '#e0f3f8',
      '#ffffbf',
      '#fee090',
      '#fdae61',
      '#f46d43',
      '#d73027',
    ],
    value: [
      [69, 117, 180],
      [116, 173, 209],
      [171, 217, 233],
      [224, 243, 248],
      [255, 255, 191],
      [254, 224, 144],
      [253, 174, 97],
      [244, 109, 67],
      [215, 48, 39],
    ],
  },
  {
    label: [
      '#313695',
      '#4575b4',
      '#74add1',
      '#abd9e9',
      '#e0f3f8',
      '#fee090',
      '#fdae61',
      '#f46d43',
      '#d73027',
      '#a50026',
    ],
    value: [
      [49, 54, 149],
      [69, 117, 180],
      [116, 173, 209],
      [171, 217, 233],
      [224, 243, 248],
      [254, 224, 144],
      [253, 174, 97],
      [244, 109, 67],
      [215, 48, 39],
      [165, 0, 38],
    ],
  },
  {
    label: [
      '#313695',
      '#4575b4',
      '#74add1',
      '#abd9e9',
      '#e0f3f8',
      '#ffffbf',
      '#fee090',
      '#fdae61',
      '#f46d43',
      '#d73027',
      '#a50026',
    ],
    value: [
      [49, 54, 149],
      [69, 117, 180],
      [116, 173, 209],
      [171, 217, 233],
      [224, 243, 248],
      [255, 255, 191],
      [254, 224, 144],
      [253, 174, 97],
      [244, 109, 67],
      [215, 48, 39],
      [165, 0, 38],
    ],
  },
  {
    label: ['#99d594', '#ffffbf', '#fc8d59'],
    value: [
      [153, 213, 148],
      [255, 255, 191],
      [252, 141, 89],
    ],
  },
  {
    label: ['#2b83ba', '#abdda4', '#fdae61', '#d7191c'],
    value: [
      [43, 131, 186],
      [171, 221, 164],
      [253, 174, 97],
      [215, 25, 28],
    ],
  },
  {
    label: ['#2b83ba', '#abdda4', '#ffffbf', '#fdae61', '#d7191c'],
    value: [
      [43, 131, 186],
      [171, 221, 164],
      [255, 255, 191],
      [253, 174, 97],
      [215, 25, 28],
    ],
  },
  {
    label: ['#3288bd', '#99d594', '#e6f598', '#fee08b', '#fc8d59', '#d53e4f'],
    value: [
      [50, 136, 189],
      [153, 213, 148],
      [230, 245, 152],
      [254, 224, 139],
      [252, 141, 89],
      [213, 62, 79],
    ],
  },
  {
    label: [
      '#3288bd',
      '#99d594',
      '#e6f598',
      '#ffffbf',
      '#fee08b',
      '#fc8d59',
      '#d53e4f',
    ],
    value: [
      [50, 136, 189],
      [153, 213, 148],
      [230, 245, 152],
      [255, 255, 191],
      [254, 224, 139],
      [252, 141, 89],
      [213, 62, 79],
    ],
  },
  {
    label: [
      '#3288bd',
      '#66c2a5',
      '#abdda4',
      '#e6f598',
      '#fee08b',
      '#fdae61',
      '#f46d43',
      '#d53e4f',
    ],
    value: [
      [50, 136, 189],
      [102, 194, 165],
      [171, 221, 164],
      [230, 245, 152],
      [254, 224, 139],
      [253, 174, 97],
      [244, 109, 67],
      [213, 62, 79],
    ],
  },
  {
    label: [
      '#3288bd',
      '#66c2a5',
      '#abdda4',
      '#e6f598',
      '#ffffbf',
      '#fee08b',
      '#fdae61',
      '#f46d43',
      '#d53e4f',
    ],
    value: [
      [50, 136, 189],
      [102, 194, 165],
      [171, 221, 164],
      [230, 245, 152],
      [255, 255, 191],
      [254, 224, 139],
      [253, 174, 97],
      [244, 109, 67],
      [213, 62, 79],
    ],
  },
  {
    label: [
      '#5e4fa2',
      '#3288bd',
      '#66c2a5',
      '#abdda4',
      '#e6f598',
      '#fee08b',
      '#fdae61',
      '#f46d43',
      '#d53e4f',
      '#9e0142',
    ],
    value: [
      [94, 79, 162],
      [50, 136, 189],
      [102, 194, 165],
      [171, 221, 164],
      [230, 245, 152],
      [254, 224, 139],
      [253, 174, 97],
      [244, 109, 67],
      [213, 62, 79],
      [158, 1, 66],
    ],
  },
  {
    label: [
      '#5e4fa2',
      '#3288bd',
      '#66c2a5',
      '#abdda4',
      '#e6f598',
      '#ffffbf',
      '#fee08b',
      '#fdae61',
      '#f46d43',
      '#d53e4f',
      '#9e0142',
    ],
    value: [
      [94, 79, 162],
      [50, 136, 189],
      [102, 194, 165],
      [171, 221, 164],
      [230, 245, 152],
      [255, 255, 191],
      [254, 224, 139],
      [253, 174, 97],
      [244, 109, 67],
      [213, 62, 79],
      [158, 1, 66],
    ],
  },
  {
    label: ['#91cf60', '#ffffbf', '#fc8d59'],
    value: [
      [145, 207, 96],
      [255, 255, 191],
      [252, 141, 89],
    ],
  },
  {
    label: ['#1a9641', '#a6d96a', '#fdae61', '#d7191c'],
    value: [
      [26, 150, 65],
      [166, 217, 106],
      [253, 174, 97],
      [215, 25, 28],
    ],
  },
  {
    label: ['#1a9641', '#a6d96a', '#ffffbf', '#fdae61', '#d7191c'],
    value: [
      [26, 150, 65],
      [166, 217, 106],
      [255, 255, 191],
      [253, 174, 97],
      [215, 25, 28],
    ],
  },
  {
    label: ['#1a9850', '#91cf60', '#d9ef8b', '#fee08b', '#fc8d59', '#d73027'],
    value: [
      [26, 152, 80],
      [145, 207, 96],
      [217, 239, 139],
      [254, 224, 139],
      [252, 141, 89],
      [215, 48, 39],
    ],
  },
  {
    label: [
      '#1a9850',
      '#91cf60',
      '#d9ef8b',
      '#ffffbf',
      '#fee08b',
      '#fc8d59',
      '#d73027',
    ],
    value: [
      [26, 152, 80],
      [145, 207, 96],
      [217, 239, 139],
      [255, 255, 191],
      [254, 224, 139],
      [252, 141, 89],
      [215, 48, 39],
    ],
  },
  {
    label: [
      '#1a9850',
      '#66bd63',
      '#a6d96a',
      '#d9ef8b',
      '#fee08b',
      '#fdae61',
      '#f46d43',
      '#d73027',
    ],
    value: [
      [26, 152, 80],
      [102, 189, 99],
      [166, 217, 106],
      [217, 239, 139],
      [254, 224, 139],
      [253, 174, 97],
      [244, 109, 67],
      [215, 48, 39],
    ],
  },
  {
    label: [
      '#1a9850',
      '#66bd63',
      '#a6d96a',
      '#d9ef8b',
      '#ffffbf',
      '#fee08b',
      '#fdae61',
      '#f46d43',
      '#d73027',
    ],
    value: [
      [26, 152, 80],
      [102, 189, 99],
      [166, 217, 106],
      [217, 239, 139],
      [255, 255, 191],
      [254, 224, 139],
      [253, 174, 97],
      [244, 109, 67],
      [215, 48, 39],
    ],
  },
  {
    label: [
      '#006837',
      '#1a9850',
      '#66bd63',
      '#a6d96a',
      '#d9ef8b',
      '#fee08b',
      '#fdae61',
      '#f46d43',
      '#d73027',
      '#a50026',
    ],
    value: [
      [0, 104, 55],
      [26, 152, 80],
      [102, 189, 99],
      [166, 217, 106],
      [217, 239, 139],
      [254, 224, 139],
      [253, 174, 97],
      [244, 109, 67],
      [215, 48, 39],
      [165, 0, 38],
    ],
  },
  {
    label: [
      '#006837',
      '#1a9850',
      '#66bd63',
      '#a6d96a',
      '#d9ef8b',
      '#ffffbf',
      '#fee08b',
      '#fdae61',
      '#f46d43',
      '#d73027',
      '#a50026',
    ],
    value: [
      [0, 104, 55],
      [26, 152, 80],
      [102, 189, 99],
      [166, 217, 106],
      [217, 239, 139],
      [255, 255, 191],
      [254, 224, 139],
      [253, 174, 97],
      [244, 109, 67],
      [215, 48, 39],
      [165, 0, 38],
    ],
  },
  {
    label: ['#fdc086', '#beaed4', '#7fc97f'],
    value: [
      [253, 192, 134],
      [190, 174, 212],
      [127, 201, 127],
    ],
  },
  {
    label: ['#ffff99', '#fdc086', '#beaed4', '#7fc97f'],
    value: [
      [255, 255, 153],
      [253, 192, 134],
      [190, 174, 212],
      [127, 201, 127],
    ],
  },
  {
    label: ['#386cb0', '#ffff99', '#fdc086', '#beaed4', '#7fc97f'],
    value: [
      [56, 108, 176],
      [255, 255, 153],
      [253, 192, 134],
      [190, 174, 212],
      [127, 201, 127],
    ],
  },
  {
    label: ['#f0027f', '#386cb0', '#ffff99', '#fdc086', '#beaed4', '#7fc97f'],
    value: [
      [240, 2, 127],
      [56, 108, 176],
      [255, 255, 153],
      [253, 192, 134],
      [190, 174, 212],
      [127, 201, 127],
    ],
  },
  {
    label: [
      '#bf5b17',
      '#f0027f',
      '#386cb0',
      '#ffff99',
      '#fdc086',
      '#beaed4',
      '#7fc97f',
    ],
    value: [
      [191, 91, 23],
      [240, 2, 127],
      [56, 108, 176],
      [255, 255, 153],
      [253, 192, 134],
      [190, 174, 212],
      [127, 201, 127],
    ],
  },
  {
    label: [
      '#666666',
      '#bf5b17',
      '#f0027f',
      '#386cb0',
      '#ffff99',
      '#fdc086',
      '#beaed4',
      '#7fc97f',
    ],
    value: [
      [102, 102, 102],
      [191, 91, 23],
      [240, 2, 127],
      [56, 108, 176],
      [255, 255, 153],
      [253, 192, 134],
      [190, 174, 212],
      [127, 201, 127],
    ],
  },
  {
    label: ['#7570b3', '#d95f02', '#1b9e77'],
    value: [
      [117, 112, 179],
      [217, 95, 2],
      [27, 158, 119],
    ],
  },
  {
    label: ['#e7298a', '#7570b3', '#d95f02', '#1b9e77'],
    value: [
      [231, 41, 138],
      [117, 112, 179],
      [217, 95, 2],
      [27, 158, 119],
    ],
  },
  {
    label: ['#66a61e', '#e7298a', '#7570b3', '#d95f02', '#1b9e77'],
    value: [
      [102, 166, 30],
      [231, 41, 138],
      [117, 112, 179],
      [217, 95, 2],
      [27, 158, 119],
    ],
  },
  {
    label: ['#e6ab02', '#66a61e', '#e7298a', '#7570b3', '#d95f02', '#1b9e77'],
    value: [
      [230, 171, 2],
      [102, 166, 30],
      [231, 41, 138],
      [117, 112, 179],
      [217, 95, 2],
      [27, 158, 119],
    ],
  },
  {
    label: [
      '#a6761d',
      '#e6ab02',
      '#66a61e',
      '#e7298a',
      '#7570b3',
      '#d95f02',
      '#1b9e77',
    ],
    value: [
      [166, 118, 29],
      [230, 171, 2],
      [102, 166, 30],
      [231, 41, 138],
      [117, 112, 179],
      [217, 95, 2],
      [27, 158, 119],
    ],
  },
  {
    label: [
      '#666666',
      '#a6761d',
      '#e6ab02',
      '#66a61e',
      '#e7298a',
      '#7570b3',
      '#d95f02',
      '#1b9e77',
    ],
    value: [
      [102, 102, 102],
      [166, 118, 29],
      [230, 171, 2],
      [102, 166, 30],
      [231, 41, 138],
      [117, 112, 179],
      [217, 95, 2],
      [27, 158, 119],
    ],
  },
  {
    label: ['#b2df8a', '#1f78b4', '#a6cee3'],
    value: [
      [178, 223, 138],
      [31, 120, 180],
      [166, 206, 227],
    ],
  },
  {
    label: ['#33a02c', '#b2df8a', '#1f78b4', '#a6cee3'],
    value: [
      [51, 160, 44],
      [178, 223, 138],
      [31, 120, 180],
      [166, 206, 227],
    ],
  },
  {
    label: ['#fb9a99', '#33a02c', '#b2df8a', '#1f78b4', '#a6cee3'],
    value: [
      [251, 154, 153],
      [51, 160, 44],
      [178, 223, 138],
      [31, 120, 180],
      [166, 206, 227],
    ],
  },
  {
    label: ['#e31a1c', '#fb9a99', '#33a02c', '#b2df8a', '#1f78b4', '#a6cee3'],
    value: [
      [227, 26, 28],
      [251, 154, 153],
      [51, 160, 44],
      [178, 223, 138],
      [31, 120, 180],
      [166, 206, 227],
    ],
  },
  {
    label: [
      '#fdbf6f',
      '#e31a1c',
      '#fb9a99',
      '#33a02c',
      '#b2df8a',
      '#1f78b4',
      '#a6cee3',
    ],
    value: [
      [253, 191, 111],
      [227, 26, 28],
      [251, 154, 153],
      [51, 160, 44],
      [178, 223, 138],
      [31, 120, 180],
      [166, 206, 227],
    ],
  },
  {
    label: [
      '#ff7f00',
      '#fdbf6f',
      '#e31a1c',
      '#fb9a99',
      '#33a02c',
      '#b2df8a',
      '#1f78b4',
      '#a6cee3',
    ],
    value: [
      [255, 127, 0],
      [253, 191, 111],
      [227, 26, 28],
      [251, 154, 153],
      [51, 160, 44],
      [178, 223, 138],
      [31, 120, 180],
      [166, 206, 227],
    ],
  },
  {
    label: [
      '#cab2d6',
      '#ff7f00',
      '#fdbf6f',
      '#e31a1c',
      '#fb9a99',
      '#33a02c',
      '#b2df8a',
      '#1f78b4',
      '#a6cee3',
    ],
    value: [
      [202, 178, 214],
      [255, 127, 0],
      [253, 191, 111],
      [227, 26, 28],
      [251, 154, 153],
      [51, 160, 44],
      [178, 223, 138],
      [31, 120, 180],
      [166, 206, 227],
    ],
  },
  {
    label: [
      '#6a3d9a',
      '#cab2d6',
      '#ff7f00',
      '#fdbf6f',
      '#e31a1c',
      '#fb9a99',
      '#33a02c',
      '#b2df8a',
      '#1f78b4',
      '#a6cee3',
    ],
    value: [
      [106, 61, 154],
      [202, 178, 214],
      [255, 127, 0],
      [253, 191, 111],
      [227, 26, 28],
      [251, 154, 153],
      [51, 160, 44],
      [178, 223, 138],
      [31, 120, 180],
      [166, 206, 227],
    ],
  },
  {
    label: [
      '#ffff99',
      '#6a3d9a',
      '#cab2d6',
      '#ff7f00',
      '#fdbf6f',
      '#e31a1c',
      '#fb9a99',
      '#33a02c',
      '#b2df8a',
      '#1f78b4',
      '#a6cee3',
    ],
    value: [
      [255, 255, 153],
      [106, 61, 154],
      [202, 178, 214],
      [255, 127, 0],
      [253, 191, 111],
      [227, 26, 28],
      [251, 154, 153],
      [51, 160, 44],
      [178, 223, 138],
      [31, 120, 180],
      [166, 206, 227],
    ],
  },
  {
    label: [
      '#b15928',
      '#ffff99',
      '#6a3d9a',
      '#cab2d6',
      '#ff7f00',
      '#fdbf6f',
      '#e31a1c',
      '#fb9a99',
      '#33a02c',
      '#b2df8a',
      '#1f78b4',
      '#a6cee3',
    ],
    value: [
      [177, 89, 40],
      [255, 255, 153],
      [106, 61, 154],
      [202, 178, 214],
      [255, 127, 0],
      [253, 191, 111],
      [227, 26, 28],
      [251, 154, 153],
      [51, 160, 44],
      [178, 223, 138],
      [31, 120, 180],
      [166, 206, 227],
    ],
  },
  {
    label: ['#ccebc5', '#b3cde3', '#fbb4ae'],
    value: [
      [204, 235, 197],
      [179, 205, 227],
      [251, 180, 174],
    ],
  },
  {
    label: ['#decbe4', '#ccebc5', '#b3cde3', '#fbb4ae'],
    value: [
      [222, 203, 228],
      [204, 235, 197],
      [179, 205, 227],
      [251, 180, 174],
    ],
  },
  {
    label: ['#fed9a6', '#decbe4', '#ccebc5', '#b3cde3', '#fbb4ae'],
    value: [
      [254, 217, 166],
      [222, 203, 228],
      [204, 235, 197],
      [179, 205, 227],
      [251, 180, 174],
    ],
  },
  {
    label: ['#ffffcc', '#fed9a6', '#decbe4', '#ccebc5', '#b3cde3', '#fbb4ae'],
    value: [
      [255, 255, 204],
      [254, 217, 166],
      [222, 203, 228],
      [204, 235, 197],
      [179, 205, 227],
      [251, 180, 174],
    ],
  },
  {
    label: [
      '#e5d8bd',
      '#ffffcc',
      '#fed9a6',
      '#decbe4',
      '#ccebc5',
      '#b3cde3',
      '#fbb4ae',
    ],
    value: [
      [229, 216, 189],
      [255, 255, 204],
      [254, 217, 166],
      [222, 203, 228],
      [204, 235, 197],
      [179, 205, 227],
      [251, 180, 174],
    ],
  },
  {
    label: [
      '#fddaec',
      '#e5d8bd',
      '#ffffcc',
      '#fed9a6',
      '#decbe4',
      '#ccebc5',
      '#b3cde3',
      '#fbb4ae',
    ],
    value: [
      [253, 218, 236],
      [229, 216, 189],
      [255, 255, 204],
      [254, 217, 166],
      [222, 203, 228],
      [204, 235, 197],
      [179, 205, 227],
      [251, 180, 174],
    ],
  },
  {
    label: [
      '#f2f2f2',
      '#fddaec',
      '#e5d8bd',
      '#ffffcc',
      '#fed9a6',
      '#decbe4',
      '#ccebc5',
      '#b3cde3',
      '#fbb4ae',
    ],
    value: [
      [242, 242, 242],
      [253, 218, 236],
      [229, 216, 189],
      [255, 255, 204],
      [254, 217, 166],
      [222, 203, 228],
      [204, 235, 197],
      [179, 205, 227],
      [251, 180, 174],
    ],
  },
  {
    label: ['#cbd5e8', '#fdcdac', '#b3e2cd'],
    value: [
      [203, 213, 232],
      [253, 205, 172],
      [179, 226, 205],
    ],
  },
  {
    label: ['#f4cae4', '#cbd5e8', '#fdcdac', '#b3e2cd'],
    value: [
      [244, 202, 228],
      [203, 213, 232],
      [253, 205, 172],
      [179, 226, 205],
    ],
  },
  {
    label: ['#e6f5c9', '#f4cae4', '#cbd5e8', '#fdcdac', '#b3e2cd'],
    value: [
      [230, 245, 201],
      [244, 202, 228],
      [203, 213, 232],
      [253, 205, 172],
      [179, 226, 205],
    ],
  },
  {
    label: ['#fff2ae', '#e6f5c9', '#f4cae4', '#cbd5e8', '#fdcdac', '#b3e2cd'],
    value: [
      [255, 242, 174],
      [230, 245, 201],
      [244, 202, 228],
      [203, 213, 232],
      [253, 205, 172],
      [179, 226, 205],
    ],
  },
  {
    label: [
      '#f1e2cc',
      '#fff2ae',
      '#e6f5c9',
      '#f4cae4',
      '#cbd5e8',
      '#fdcdac',
      '#b3e2cd',
    ],
    value: [
      [241, 226, 204],
      [255, 242, 174],
      [230, 245, 201],
      [244, 202, 228],
      [203, 213, 232],
      [253, 205, 172],
      [179, 226, 205],
    ],
  },
  {
    label: [
      '#cccccc',
      '#f1e2cc',
      '#fff2ae',
      '#e6f5c9',
      '#f4cae4',
      '#cbd5e8',
      '#fdcdac',
      '#b3e2cd',
    ],
    value: [
      [204, 204, 204],
      [241, 226, 204],
      [255, 242, 174],
      [230, 245, 201],
      [244, 202, 228],
      [203, 213, 232],
      [253, 205, 172],
      [179, 226, 205],
    ],
  },
  {
    label: ['#4daf4a', '#377eb8', '#e41a1c'],
    value: [
      [77, 175, 74],
      [55, 126, 184],
      [228, 26, 28],
    ],
  },
  {
    label: ['#984ea3', '#4daf4a', '#377eb8', '#e41a1c'],
    value: [
      [152, 78, 163],
      [77, 175, 74],
      [55, 126, 184],
      [228, 26, 28],
    ],
  },
  {
    label: ['#ff7f00', '#984ea3', '#4daf4a', '#377eb8', '#e41a1c'],
    value: [
      [255, 127, 0],
      [152, 78, 163],
      [77, 175, 74],
      [55, 126, 184],
      [228, 26, 28],
    ],
  },
  {
    label: ['#ffff33', '#ff7f00', '#984ea3', '#4daf4a', '#377eb8', '#e41a1c'],
    value: [
      [255, 255, 51],
      [255, 127, 0],
      [152, 78, 163],
      [77, 175, 74],
      [55, 126, 184],
      [228, 26, 28],
    ],
  },
  {
    label: [
      '#a65628',
      '#ffff33',
      '#ff7f00',
      '#984ea3',
      '#4daf4a',
      '#377eb8',
      '#e41a1c',
    ],
    value: [
      [166, 86, 40],
      [255, 255, 51],
      [255, 127, 0],
      [152, 78, 163],
      [77, 175, 74],
      [55, 126, 184],
      [228, 26, 28],
    ],
  },
  {
    label: [
      '#f781bf',
      '#a65628',
      '#ffff33',
      '#ff7f00',
      '#984ea3',
      '#4daf4a',
      '#377eb8',
      '#e41a1c',
    ],
    value: [
      [247, 129, 191],
      [166, 86, 40],
      [255, 255, 51],
      [255, 127, 0],
      [152, 78, 163],
      [77, 175, 74],
      [55, 126, 184],
      [228, 26, 28],
    ],
  },
  {
    label: [
      '#999999',
      '#f781bf',
      '#a65628',
      '#ffff33',
      '#ff7f00',
      '#984ea3',
      '#4daf4a',
      '#377eb8',
      '#e41a1c',
    ],
    value: [
      [153, 153, 153],
      [247, 129, 191],
      [166, 86, 40],
      [255, 255, 51],
      [255, 127, 0],
      [152, 78, 163],
      [77, 175, 74],
      [55, 126, 184],
      [228, 26, 28],
    ],
  },
  {
    label: ['#8da0cb', '#fc8d62', '#66c2a5'],
    value: [
      [141, 160, 203],
      [252, 141, 98],
      [102, 194, 165],
    ],
  },
  {
    label: ['#e78ac3', '#8da0cb', '#fc8d62', '#66c2a5'],
    value: [
      [231, 138, 195],
      [141, 160, 203],
      [252, 141, 98],
      [102, 194, 165],
    ],
  },
  {
    label: ['#a6d854', '#e78ac3', '#8da0cb', '#fc8d62', '#66c2a5'],
    value: [
      [166, 216, 84],
      [231, 138, 195],
      [141, 160, 203],
      [252, 141, 98],
      [102, 194, 165],
    ],
  },
  {
    label: ['#ffd92f', '#a6d854', '#e78ac3', '#8da0cb', '#fc8d62', '#66c2a5'],
    value: [
      [255, 217, 47],
      [166, 216, 84],
      [231, 138, 195],
      [141, 160, 203],
      [252, 141, 98],
      [102, 194, 165],
    ],
  },
  {
    label: [
      '#e5c494',
      '#ffd92f',
      '#a6d854',
      '#e78ac3',
      '#8da0cb',
      '#fc8d62',
      '#66c2a5',
    ],
    value: [
      [229, 196, 148],
      [255, 217, 47],
      [166, 216, 84],
      [231, 138, 195],
      [141, 160, 203],
      [252, 141, 98],
      [102, 194, 165],
    ],
  },
  {
    label: [
      '#b3b3b3',
      '#e5c494',
      '#ffd92f',
      '#a6d854',
      '#e78ac3',
      '#8da0cb',
      '#fc8d62',
      '#66c2a5',
    ],
    value: [
      [179, 179, 179],
      [229, 196, 148],
      [255, 217, 47],
      [166, 216, 84],
      [231, 138, 195],
      [141, 160, 203],
      [252, 141, 98],
      [102, 194, 165],
    ],
  },
  {
    label: ['#bebada', '#ffffb3', '#8dd3c7'],
    value: [
      [190, 186, 218],
      [255, 255, 179],
      [141, 211, 199],
    ],
  },
  {
    label: ['#fb8072', '#bebada', '#ffffb3', '#8dd3c7'],
    value: [
      [251, 128, 114],
      [190, 186, 218],
      [255, 255, 179],
      [141, 211, 199],
    ],
  },
  {
    label: ['#80b1d3', '#fb8072', '#bebada', '#ffffb3', '#8dd3c7'],
    value: [
      [128, 177, 211],
      [251, 128, 114],
      [190, 186, 218],
      [255, 255, 179],
      [141, 211, 199],
    ],
  },
  {
    label: ['#fdb462', '#80b1d3', '#fb8072', '#bebada', '#ffffb3', '#8dd3c7'],
    value: [
      [253, 180, 98],
      [128, 177, 211],
      [251, 128, 114],
      [190, 186, 218],
      [255, 255, 179],
      [141, 211, 199],
    ],
  },
  {
    label: [
      '#b3de69',
      '#fdb462',
      '#80b1d3',
      '#fb8072',
      '#bebada',
      '#ffffb3',
      '#8dd3c7',
    ],
    value: [
      [179, 222, 105],
      [253, 180, 98],
      [128, 177, 211],
      [251, 128, 114],
      [190, 186, 218],
      [255, 255, 179],
      [141, 211, 199],
    ],
  },
  {
    label: [
      '#fccde5',
      '#b3de69',
      '#fdb462',
      '#80b1d3',
      '#fb8072',
      '#bebada',
      '#ffffb3',
      '#8dd3c7',
    ],
    value: [
      [252, 205, 229],
      [179, 222, 105],
      [253, 180, 98],
      [128, 177, 211],
      [251, 128, 114],
      [190, 186, 218],
      [255, 255, 179],
      [141, 211, 199],
    ],
  },
  {
    label: [
      '#d9d9d9',
      '#fccde5',
      '#b3de69',
      '#fdb462',
      '#80b1d3',
      '#fb8072',
      '#bebada',
      '#ffffb3',
      '#8dd3c7',
    ],
    value: [
      [217, 217, 217],
      [252, 205, 229],
      [179, 222, 105],
      [253, 180, 98],
      [128, 177, 211],
      [251, 128, 114],
      [190, 186, 218],
      [255, 255, 179],
      [141, 211, 199],
    ],
  },
  {
    label: [
      '#bc80bd',
      '#d9d9d9',
      '#fccde5',
      '#b3de69',
      '#fdb462',
      '#80b1d3',
      '#fb8072',
      '#bebada',
      '#ffffb3',
      '#8dd3c7',
    ],
    value: [
      [188, 128, 189],
      [217, 217, 217],
      [252, 205, 229],
      [179, 222, 105],
      [253, 180, 98],
      [128, 177, 211],
      [251, 128, 114],
      [190, 186, 218],
      [255, 255, 179],
      [141, 211, 199],
    ],
  },
  {
    label: [
      '#ccebc5',
      '#bc80bd',
      '#d9d9d9',
      '#fccde5',
      '#b3de69',
      '#fdb462',
      '#80b1d3',
      '#fb8072',
      '#bebada',
      '#ffffb3',
      '#8dd3c7',
    ],
    value: [
      [204, 235, 197],
      [188, 128, 189],
      [217, 217, 217],
      [252, 205, 229],
      [179, 222, 105],
      [253, 180, 98],
      [128, 177, 211],
      [251, 128, 114],
      [190, 186, 218],
      [255, 255, 179],
      [141, 211, 199],
    ],
  },
  {
    label: [
      '#ffed6f',
      '#ccebc5',
      '#bc80bd',
      '#d9d9d9',
      '#fccde5',
      '#b3de69',
      '#fdb462',
      '#80b1d3',
      '#fb8072',
      '#bebada',
      '#ffffb3',
      '#8dd3c7',
    ],
    value: [
      [255, 237, 111],
      [204, 235, 197],
      [188, 128, 189],
      [217, 217, 217],
      [252, 205, 229],
      [179, 222, 105],
      [253, 180, 98],
      [128, 177, 211],
      [251, 128, 114],
      [190, 186, 218],
      [255, 255, 179],
      [141, 211, 199],
    ],
  },
]
