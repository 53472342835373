import { useCallback, ReactElement } from 'react'
import _ from 'lodash'
import { FieldInputProps } from 'react-final-form'

// components
import { UsersPicker, UsersPickerWithContext } from 'components/common/'
import StyledField, {
  StyleFieldProps,
} from 'components/common/Form/StyledField'

import type { Options } from 'types/common'

const FormUsersPicker = ({
  input,
  label,
  required = false,
  isMulti = false,
  options,
  checkable = false,
  onChecked,
  groupOptionStyle = false,
  ...rest
}: {
  input: FieldInputProps<string | string[]>
  label: string
  required?: boolean
  isMulti?: boolean
  options: Options
  checkable?: boolean
  onChecked?: StyleFieldProps['onChecked']
  groupOptionStyle?: boolean
}): ReactElement => {
  const { name, onChange, value } = input

  const onValueChange = useCallback(
    (option: Options<string>) => {
      const optionValue = isMulti ? _.map(option, 'value') : option?.value
      onChange(optionValue)
    },
    [isMulti, onChange]
  )

  const sharedProps = {
    isMulti,
    value,
    onChange: onValueChange,
    ...rest,
  }

  return (
    <StyledField
      name={name}
      label={label}
      required={required}
      groupOptionStyle={groupOptionStyle}
      checkable={checkable}
      onChecked={onChecked}
    >
      {options ? (
        <UsersPicker {...sharedProps} options={options} />
      ) : (
        <UsersPickerWithContext {...sharedProps} />
      )}
    </StyledField>
  )
}

export default FormUsersPicker
