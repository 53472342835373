// libraries
import _ from 'lodash'
import { createSelector } from 'reselect'

const getOption = ancillaryDataList => {
  const { id, assetName, ...rest } = ancillaryDataList
  return {
    ...rest,
    value: id,
    label: assetName,
  }
}

const getAncillaryDataState = state => state.ancillaryDataState

const getAncillaryDataList = createSelector(
  getAncillaryDataState,
  state => state.ancillaryDataList
)

const getAncillaryDataOptions = createSelector(
  getAncillaryDataList,
  ancillaryDataList =>
    _(ancillaryDataList)
      .map(getOption)
      .sortBy(({ label }) => _.upperFirst(label))
      .value()
)

const ancillaryDataSelectors = state => ({
  ancillaryDataOptions: getAncillaryDataOptions(state),
})

export default ancillaryDataSelectors
