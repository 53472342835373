import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import PropTypes from 'prop-types'

import Item from 'components/common/PropertiesList/Item'

const SortableItem = props => {
  const { id, disableSort } = props

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id, disabled: disableSort })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <Item
      ref={setNodeRef}
      sortableProps={{ ...attributes, style }}
      listeners={listeners}
      disableSort={disableSort}
      {...props}
    />
  )
}

SortableItem.propTypes = {
  id: PropTypes.string.isRequired,
  disableSort: PropTypes.bool,
}

SortableItem.defaultProps = {
  disableSort: false,
}

export default SortableItem
