import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { StyledField } from 'components/common/Form'
import scss from './index.module.scss'

export const getInitialChecklist = (list, value, isMulti) => {
  const validInputValue = isMulti ? value : _.first(value)
  if (!list) return []

  return _.map(list, item => {
    return {
      ...item,
      isChecked: _.includes(validInputValue, item.id),
    }
  })
}

export const getInsertedElement = ({ id, isChecked, value, isMulti }) => {
  return isMulti
    ? isChecked
      ? [...value, id]
      : _.without(value, id)
    : isChecked
    ? [id]
    : []
}

const CheckList = ({
  input,
  label,
  isDisabled,
  className,
  required,
  groupOptionStyle,
  isMulti,
  list,
  loading,
  error,
}) => {
  const { name, onChange, value } = input

  const checklists = useMemo(() => {
    return getInitialChecklist(list, value, isMulti)
  }, [list, value, isMulti])

  const handleCheck = useCallback(
    (id, isChecked) => {
      const insertedElement = getInsertedElement({
        id,
        isChecked,
        value,
        isMulti,
      })
      onChange(insertedElement)
    },
    [isMulti, onChange, value]
  )

  return (
    <>
      <StyledField
        name={name}
        groupOptionStyle={groupOptionStyle}
        label={label}
        required={required}
      >
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>Error: {error.message}</div>
        ) : (
          <div>
            {checklists.map(({ id, isChecked, title }) => (
              <div key={id} className={className}>
                <input
                  className={scss.input}
                  checked={isChecked}
                  type='checkbox'
                  onChange={() => handleCheck(id, !isChecked)}
                  disabled={isDisabled}
                />
                <span className='ms-1'>{title}</span>
              </div>
            ))}
          </div>
        )}
      </StyledField>
    </>
  )
}

CheckList.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.bool,
  }).isRequired,
  label: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  className: PropTypes.string,
  required: PropTypes.bool,
  groupOptionStyle: PropTypes.bool,
  isMulti: PropTypes.bool,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })
  ),
  loading: PropTypes.bool,
  error: PropTypes.shape({ message: PropTypes.string }),
}

CheckList.defaultProps = {
  required: false,
  className: '',
  groupOptionStyle: false,
  isMulti: false,
  list: [],
  loading: false,
  error: undefined,
}

export default CheckList
