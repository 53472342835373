// libraries
import { useCallback } from 'react'
import { useLocation } from 'react-router-dom'

// constants
import { URLS } from 'constants/route'
import { ENTITIES } from 'constants/common'

// utils
import { useListItemActions } from 'components/common/List'
import { deleteIssueTaskDataCollectionForm } from 'services/api/dataCollectionForm'

import type { OnListItemChange } from 'components/common/List/hooks/useListItemActions'
import type { CardActions } from 'components/common/List/Card/CardOptions'
import type { DataCollectionForm } from 'types/issue'

const useItemActions = ({
  onChange,
}: {
  onChange: OnListItemChange<DataCollectionForm>
}): CardActions<DataCollectionForm> => {
  const { pathname } = useLocation()

  const { editItem, deleteItem } = useListItemActions({
    onChange,
    entity: ENTITIES.form,
  })

  const onDelete = useCallback(
    async (item: DataCollectionForm) => {
      deleteItem(item, () => deleteIssueTaskDataCollectionForm(item.id))
    },
    [deleteItem]
  )

  const onView =
    (url: string) =>
    ({ id }: DataCollectionForm, getUrlOnly?: boolean) =>
      editItem(url, { id }, getUrlOnly, {
        state: {
          pathname,
        },
      })

  return {
    onDelete,
    onChange,
    onView: onView(URLS.FORM_DESIGNER_VIEW),
    onEdit: onView(URLS.FORM_DESIGNER_EDIT),
  }
}

export default useItemActions
