import _ from 'lodash'
import keymirror from 'keymirror'

// constants
import {
  OPERATORS as FILTER_OPERATORS,
  NUMERIC_OPERATORS,
  STATEFUL_NUMERIC_OPERATORS,
  STRING_OPERATORS,
  BOOLEAN_OPERATORS,
} from 'constants/filter'
import { SITE_FEATURE_TYPES } from 'constants/site'
import { ENTITIES } from 'constants/common'
import {
  DateTimeComparisonEditorLastUnit,
  WorkflowWidgetMonitorEmitWhenType,
} from 'types/graphql'

// utils
import { getOptions } from 'helpers/utils'

import type { IssueFormOption } from 'types/issue'

export const DEFAULT_WIDGET_SIZE = 20
export const GRID_SIZE = 136
export const PROPERTY_PANEL_WIDTH = 310
export const DEFAULT_REPORT_UTC_HOUR = 0
export const DEFAULT_REPORT_UTC_MINUTE = 0

export const CANVAS_NODE_WIDGET_WIDTH = 120
export const CANVAS_NODE_WIDGET_ICON_SIZE = 64
export const CANVAS_NODE_WIDGET_HEIGHT = 84

export const WORKFLOW_WIDGET_CATEGORY = keymirror({
  // "Producer of observations"
  SOURCE: null,
  // "Conditionally stops flow (based on a property in the observation?)"
  FILTER: null,
  // "Produces a side effect. Outflow is currently undefined"
  ACTION: null,
  // "Changes the structure of the observation. For example multiple JSONs to a CSV"
  TRANSFORM: null,
  // "Adds more information to the observation without changing structure"
  ENRICH: null,
  // "Extra visual guides for widget connecting widgets"
  FLOW: null,
})

export const WORKFLOW_WIDGET_DATA_SOURCE_QUERY_TYPES = keymirror({
  LIVE: null,
  SCHEDULED: null,
})

export const WORKFLOW_WIDGET_TYPES = keymirror({
  SOURCE_DATASOURCE: null,
  FILTER_CONDITION: null,
  FILTER_GEOFENCE: null,
  FILTER_MONITOR: null,
  ACTION_OPEN_ISSUE: null,
  ACTION_CLOSE_ISSUE: null,
  ACTION_CHANGE_ISSUE_SEVERITY: null,
  ACTION_SEND_EMAIL: null,
  ACTION_SEND_SMS: null,
  ACTION_SEND_PUSH_NOTIFICATION: null,
  ACTION_DELAY: null,
  TRANSFORM_REPORT_GENERATOR: null,
  FLOW_START: null,
  FLOW_SPLIT: null,
  FLOW_END: null,
})

export const WORKFLOW_WIDGET_COMPARISON_UNION_TYPES = keymirror({
  STRING: null,
  NUMERIC: null,
  NUMERIC_RANGE: null,
  BOOLEAN: null,
  DATETIME: null,
})

export const OPERAND_UNION_TYPE = keymirror({
  PROPERTY: null,
  VALUE: null,
})

export const WORKFLOW_WIDGET_CONDITION_FILTER_STRING_OPERATORS = keymirror({
  CONTAINS: null,
  DOES_NOT_CONTAIN: null,
  EQUALS: null,
  DOES_NOT_EQUAL: null,
})

export const WORKFLOW_WIDGET_CONDITION_FILTER_NUMERIC_OPERATORS = keymirror({
  EQUALS: null,
  DOES_NOT_EQUAL: null,
  IS_GREATER_THAN: null,
  IS_LESS_THAN: null,
  IS_GREATER_THAN_OR_EQUALS: null,
  IS_LESS_THAN_OR_EQUALS: null,
  BECAME_GREATER_THAN: null,
  BECAME_LESS_THAN: null,
})

export const WORKFLOW_WIDGET_CONDITION_FILTER_NUMERIC_RANGE_OPERATORS =
  keymirror({
    IS_BETWEEN: null,
  })

export const WORKFLOW_WIDGET_CONDITION_FILTER_BOOLEAN_OPERATORS = keymirror({
  EQUALS: null,
  DOES_NOT_EQUAL: null,
})

export const GEOFENCE_OPERAND_UNION_TYPE = keymirror({
  PROPERTY: null,
  VALUE: null,
  ANCILLARY_DATA: null,
})

export const WORKFLOW_WIDGET_CONDITION_GEOFENCE_OPERATORS = keymirror({
  INSIDE: null,
  OUTSIDE: null,
  ENTERED: null,
  NOT_ENTERED: null,
  EXITED: null,
  NOT_EXITED: null,
})

export const WORKFLOW_WIDGET_CONDITION_MONITOR_NO_COMPARISON_OPERATOR_REQUIRED =
  keymirror({
    'Always above': null,
    'Always below': null,
  })

export const WORKFLOW_WIDGET_CONDITION_MONITOR_OPERATORS = keymirror({
  Min: null,
  Max: null,
  Average: null,
})

export const WORKFLOW_MONITOR_WIDGET_EMIT_ONLY_WHEN_THE_CONDITION_OPTIONS = [
  {
    value: WorkflowWidgetMonitorEmitWhenType.ComparisonTrue,
    label: 'Is true',
    description:
      'This widget will emit every time the condition of the rolling window is true. This option may emit frequently.',
    isDefault: true,
  },
  {
    value: WorkflowWidgetMonitorEmitWhenType.ComparisonBecomesTrue,
    label: 'Became true',
    description:
      'This widget will only emit when the condition of the rolling window becomes true.',
  },
  {
    value: WorkflowWidgetMonitorEmitWhenType.ComparisonBecomesFalse,
    label: 'Became false',
    description:
      'This widget will only emit when the condition of the rolling window becomes false.',
  },
]

export const WORKFLOW_MONITOR_WIDGET_DEFAULT_EMIT_ONLY_WHEN_THE_CONDITION_VALUE =
  _.get(
    _.find(
      WORKFLOW_MONITOR_WIDGET_EMIT_ONLY_WHEN_THE_CONDITION_OPTIONS,
      'isDefault'
    ),
    'value'
  )

// 5 mins -> 1 year
// https://github.com/SensorUp/su-workflow/blob/6c8fc6d313e61f9dfe494edb42275948e964be60/src/services/workflowService.js#L170-L171)
export const WORKFLOW_WIDGET_CONDITION_MONITOR_DURATION_RANGE_MINS = [5, 525600]

export const WORKFLOW_WIDGET_CONDITION_MONITOR_OPERATORS_OPTIONS = getOptions({
  ...WORKFLOW_WIDGET_CONDITION_MONITOR_NO_COMPARISON_OPERATOR_REQUIRED,
  ...WORKFLOW_WIDGET_CONDITION_MONITOR_OPERATORS,
})

/**
 * The operators which need to be inverted when saving a workflow.
 * The "not" condition will be replaced by "complementComparison: true"
 */
export const WORKFLOW_WIDGET_GEOFENCE_NOT_OPERATORS_TO_INVERT_MAP = {
  [WORKFLOW_WIDGET_CONDITION_GEOFENCE_OPERATORS.NOT_ENTERED]:
    WORKFLOW_WIDGET_CONDITION_GEOFENCE_OPERATORS.ENTERED,
  [WORKFLOW_WIDGET_CONDITION_GEOFENCE_OPERATORS.NOT_EXITED]:
    WORKFLOW_WIDGET_CONDITION_GEOFENCE_OPERATORS.EXITED,
}

export const WORKFLOW_WIDGET_GEOFENCE_NOT_OPERATORS_TO_INVERT_LIST =
  Object.keys(WORKFLOW_WIDGET_GEOFENCE_NOT_OPERATORS_TO_INVERT_MAP)

/** The operators which need to be inverted if "complementComparison === true" (when receiving a workflow) */
export const WORKFLOW_WIDGET_GEOFENCE_INVERTIBLE_OPERATORS_MAP = _.invert(
  WORKFLOW_WIDGET_GEOFENCE_NOT_OPERATORS_TO_INVERT_MAP
)

export const WORKFLOW_WIDGET_GEOFENCE_INVERTIBLE_OPERATORS_LIST = Object.keys(
  WORKFLOW_WIDGET_GEOFENCE_INVERTIBLE_OPERATORS_MAP
)

export const WORKFLOW_WIDGET_CONDITION_GEOFENCE_OPERATORS_OPTIONS = [
  {
    value: 'inside',
    label: 'INSIDE',
    operator: WORKFLOW_WIDGET_CONDITION_GEOFENCE_OPERATORS.INSIDE,
  },
  {
    value: 'outside',
    label: 'OUTSIDE',
    operator: WORKFLOW_WIDGET_CONDITION_GEOFENCE_OPERATORS.OUTSIDE,
  },
  {
    value: 'entered',
    label: 'ENTERED',
    operator: WORKFLOW_WIDGET_CONDITION_GEOFENCE_OPERATORS.ENTERED,
  },
  {
    value: 'exited',
    label: 'EXITED',
    operator: WORKFLOW_WIDGET_CONDITION_GEOFENCE_OPERATORS.EXITED,
  },
  {
    value: 'not_entered',
    label: 'NOT ENTERED',
    operator: WORKFLOW_WIDGET_CONDITION_GEOFENCE_OPERATORS.NOT_ENTERED,
  },
  {
    value: 'not_exited',
    label: 'NOT EXITED',
    operator: WORKFLOW_WIDGET_CONDITION_GEOFENCE_OPERATORS.NOT_EXITED,
  },
]

export const USER_UNION_TYPE = keymirror({
  PROPERTY: null,
  USER_ID: null,
})

export const WORKFLOW_WIDGET_REPORT_FORMATS = keymirror({
  CSV: null,
  PDF: null,
})

export const WORKFLOW_WIDGET_OPEN_ISSUE_FORM_REFERENCE = keymirror({
  multitaskProcedureId: null,
  taskDataCollectionFormId: null,
})

export const WORKFLOW_DIAGRAM_NODE_BASE_TYPES = keymirror({
  action: null,
  transform: null,
  enrich: null,
  // WORKFLOW_WIDGET_CATEGORY.SOURCE
  datasource: null,
  // WORKFLOW_WIDGET_CATEGORY.FILTER
  condition: null,
  // WORKFLOW_WIDGET_CATEGORY.FLOW
  start: null,
  end: null,
  split: null,
})

/**
 * Mapping to the widgets types that diagram engine is using (ui)
 */
export const WORKFLOW_DIAGRAM_NODE_TYPES = {
  ...WORKFLOW_DIAGRAM_NODE_BASE_TYPES,
  [WORKFLOW_WIDGET_TYPES.SOURCE_DATASOURCE]: 'datasource',

  [WORKFLOW_WIDGET_TYPES.FILTER_CONDITION]: 'filter',
  [WORKFLOW_WIDGET_TYPES.FILTER_GEOFENCE]: 'geofence',
  [WORKFLOW_WIDGET_TYPES.FILTER_MONITOR]: 'monitor',

  [WORKFLOW_WIDGET_TYPES.ACTION_SEND_SMS]: 'sms',
  [WORKFLOW_WIDGET_TYPES.ACTION_SEND_EMAIL]: 'email',
  [WORKFLOW_WIDGET_TYPES.ACTION_OPEN_ISSUE]: 'issue',
  [WORKFLOW_WIDGET_TYPES.ACTION_CLOSE_ISSUE]: 'closeIssue',
  [WORKFLOW_WIDGET_TYPES.ACTION_CHANGE_ISSUE_SEVERITY]: 'changeIssueSeverity',
  [WORKFLOW_WIDGET_TYPES.ACTION_SEND_PUSH_NOTIFICATION]: 'pushNotification',
  [WORKFLOW_WIDGET_TYPES.ACTION_DELAY]: 'delay',

  [WORKFLOW_WIDGET_TYPES.TRANSFORM_REPORT_GENERATOR]: 'report',

  [WORKFLOW_WIDGET_TYPES.FLOW_START]: 'start',
  [WORKFLOW_WIDGET_TYPES.FLOW_SPLIT]: 'split',
  [WORKFLOW_WIDGET_TYPES.FLOW_END]: 'end',
}

export const CANVAS_NODE_TYPES = _.values(WORKFLOW_DIAGRAM_NODE_TYPES)

export const REQUIRED_NODE_TYPES = [
  WORKFLOW_DIAGRAM_NODE_TYPES.FLOW_START,
  WORKFLOW_DIAGRAM_NODE_TYPES.SOURCE_DATASOURCE,
  WORKFLOW_DIAGRAM_NODE_TYPES.FLOW_END,
]

// slightly DIFFERENT than map operators
export const OPERATORS = {
  number: [...NUMERIC_OPERATORS, ...STATEFUL_NUMERIC_OPERATORS],
  string: _.reject(STRING_OPERATORS, { value: FILTER_OPERATORS.isSet }),
  boolean: BOOLEAN_OPERATORS,
}

export default {
  WORKFLOW_DIAGRAM_NODE_TYPES,
  FILTER_OPERATORS,
}

export const WORKFLOW_WIDGET_LOG_FILTER_KEYS = keymirror({
  all: null,
  emit: null,
  abort: null,
  halt: null,
  error: null,
})

export const WORKFLOW_WIDGET_LOG_FILTER_OPTIONS = [
  { key: WORKFLOW_WIDGET_LOG_FILTER_KEYS.all, label: 'All' },
  {
    key: WORKFLOW_WIDGET_LOG_FILTER_KEYS.emit,
    label: 'Successful',
  },
  { key: WORKFLOW_WIDGET_LOG_FILTER_KEYS.abort, label: 'Abort' },
  { key: WORKFLOW_WIDGET_LOG_FILTER_KEYS.halt, label: 'Halt' },
  {
    key: WORKFLOW_WIDGET_LOG_FILTER_KEYS.error,
    label: 'Error',
  },
]

export const WORKFLOW_WIDGET_LOG_TIME_FILTER_KEYS = [1, 4, 12, 24]

export const STRING_OPERATORS_MAPPING = {
  [FILTER_OPERATORS.contains]:
    WORKFLOW_WIDGET_CONDITION_FILTER_STRING_OPERATORS.CONTAINS,
  [FILTER_OPERATORS.doesNotContain]:
    WORKFLOW_WIDGET_CONDITION_FILTER_STRING_OPERATORS.DOES_NOT_CONTAIN,
  [FILTER_OPERATORS.equals]:
    WORKFLOW_WIDGET_CONDITION_FILTER_STRING_OPERATORS.EQUALS,
  [FILTER_OPERATORS.doesNotEqual]:
    WORKFLOW_WIDGET_CONDITION_FILTER_STRING_OPERATORS.DOES_NOT_EQUAL,
}

export const NUMERIC_OPERATORS_MAPPING = {
  [FILTER_OPERATORS.equals]:
    WORKFLOW_WIDGET_CONDITION_FILTER_NUMERIC_OPERATORS.EQUALS,
  [FILTER_OPERATORS.doesNotEqual]:
    WORKFLOW_WIDGET_CONDITION_FILTER_NUMERIC_OPERATORS.DOES_NOT_EQUAL,
  [FILTER_OPERATORS.isGreaterThan]:
    WORKFLOW_WIDGET_CONDITION_FILTER_NUMERIC_OPERATORS.IS_GREATER_THAN,
  [FILTER_OPERATORS.isLessThan]:
    WORKFLOW_WIDGET_CONDITION_FILTER_NUMERIC_OPERATORS.IS_LESS_THAN,
  [FILTER_OPERATORS.isGreaterThanOrEquals]:
    WORKFLOW_WIDGET_CONDITION_FILTER_NUMERIC_OPERATORS.IS_GREATER_THAN_OR_EQUALS,
  [FILTER_OPERATORS.isLessThanOrEquals]:
    WORKFLOW_WIDGET_CONDITION_FILTER_NUMERIC_OPERATORS.IS_LESS_THAN_OR_EQUALS,
  [FILTER_OPERATORS.becameGreaterThan]:
    WORKFLOW_WIDGET_CONDITION_FILTER_NUMERIC_OPERATORS.BECAME_GREATER_THAN,
  [FILTER_OPERATORS.becameLessThan]:
    WORKFLOW_WIDGET_CONDITION_FILTER_NUMERIC_OPERATORS.BECAME_LESS_THAN,
}

export const NUMERIC_RANGE_OPERATORS_MAPPING = {
  [FILTER_OPERATORS.isBetween]:
    WORKFLOW_WIDGET_CONDITION_FILTER_NUMERIC_RANGE_OPERATORS.IS_BETWEEN,
}

export const BOOLEAN_VALUE_MAPPING = {
  [FILTER_OPERATORS.isTrue]: true,
  [FILTER_OPERATORS.isFalse]: false,
}

export const WORKFLOW_WIDGET_DATA_COLLECTION_TYPES = keymirror({
  MULTITASK: null,
  TASK: null,
  TASK_JSON_FORM_BODY: null,
})

export const WORKFLOW_GEOFENCE_ENTITY_TYPES = {
  ..._.mapValues(SITE_FEATURE_TYPES, d =>
    d === 'site' ? _.toUpper(d) : `SITE_${_.toUpper(d)}`
  ),
  [ENTITIES.ancillaryData]: 'ANCILLARY_DATA',
}

export const getWorkflowIssueNewDataCollectionFormOption = (
  isNew = false
): IssueFormOption => ({
  label: `${isNew ? 'New' : 'Customized'} Data Collection Form`,
  value: 'workflowIssueNewDataCollectionFormOption',
  inputType: WORKFLOW_WIDGET_DATA_COLLECTION_TYPES.TASK_JSON_FORM_BODY,
})

export const PUSH_NOTIFICATION_USER_UNION_TYPES = keymirror({
  PROPERTY: null,
  USER_ID: null,
})

export const REPORT_GENERATE_FILE_NAME_LIMIT = 255

export const NOTIFICATION_TITLE_LIMIT = 45
export const NOTIFICATION_MESSAGE_LIMIT = 133

export const DATE_TIME_COMPARISON_EDITOR_TYPES = keymirror({
  LAST: null,
  BEFORE: null,
  AFTER: null,
  ON: null,
  BETWEEN: null,
  CURRENT_DAY: null,
  PREVIOUS_DAY: null,
  CURRENT_WEEK: null,
})

export const DATE_TIME_COMPARISON_EDITOR_LAST_VALUE_MIN = 10

export const RELATIVE_TIME_UNIT_OPTIONS = getOptions(
  DateTimeComparisonEditorLastUnit
)

export const DATE_TIME_COMPARISON_EDITOR_PRECISION = keymirror({
  MINUTE: null,
  DAY: null,
})

export const ABSOLUTE_TIME_OPERATORS = [
  DATE_TIME_COMPARISON_EDITOR_TYPES.BETWEEN,
  DATE_TIME_COMPARISON_EDITOR_TYPES.ON,
]

export const RELATIVE_TIME_PRE_DEFINED_OPERATORS = [
  DATE_TIME_COMPARISON_EDITOR_TYPES.CURRENT_DAY,
  DATE_TIME_COMPARISON_EDITOR_TYPES.PREVIOUS_DAY,
  DATE_TIME_COMPARISON_EDITOR_TYPES.CURRENT_WEEK,
]

// previous day

export const RELATIVE_TIME_OPERATORS = [
  DATE_TIME_COMPARISON_EDITOR_TYPES.LAST,
  DATE_TIME_COMPARISON_EDITOR_TYPES.AFTER,
  ...RELATIVE_TIME_PRE_DEFINED_OPERATORS,
]

export const DATE_TIME_DELAY_UNITS = keymirror({
  MINUTE: null,
  HOUR: null,
  DAY: null,
  WEEK: null,
  MONTH: null,
})

export const DATE_TIME_OPERAND_UNION_TYPES = keymirror({
  PROPERTY: null,
  VALUE: null,
  CONSTANT: null,
})

export const DATE_TIME_BUILT_IN_CONSTANTS = keymirror({
  NOW: null,
  BEGINNING_OF_TIME: null,
  END_OF_TIME: null,
})

export const WORKFLOW_PROPERTY_PANEL_FIELD_NAMES = keymirror({
  relative: null,
  property: null,
  operator: null,
  value: null,
  complementComparison: null,
  minimum: null,
  maximum: null,
  monitoringType: null,
  emitWhen: null,
})

export const WORKFLOW_DATASOURCE_MISSING_ERROR =
  'Source is required in the data source node'
