import _ from 'lodash'

// constants
import {
  WORKFLOW_WIDGET_TYPES,
  WORKFLOW_GEOFENCE_ENTITY_TYPES,
} from 'constants/workflow'
import { LAYER_TYPES } from 'constants/map'

// utils
import { showInfo } from 'helpers/message'
import { isPrivateEntity, isStringValueEqual } from 'helpers/utils'

export const convertGeometriesToFeatureCollection = geojsonObj => {
  const type = 'FeatureCollection'
  const features = geojsonObj.geometries.map(geometry => ({
    type: 'Feature',
    properties: geojsonObj.properties || {},
    geometry,
  }))
  return { type, features }
}

export const convertGeometryCollectionToFeatureCollection = (name, geojson) => {
  let newGeojson = {}
  const geojsonObj = JSON.parse(geojson)

  if (geojsonObj.type === 'GeometryCollection') {
    showInfo(
      `The ${name} is GeometryCollection and will be converted to a FeatureCollection`
    )

    newGeojson = convertGeometriesToFeatureCollection(geojsonObj)
  } else if (
    geojsonObj.geometry &&
    geojsonObj.geometry.type === 'GeometryCollection'
  ) {
    showInfo(
      `The ${name} is GeometryCollection and will be converted to a FeatureCollection`
    )

    newGeojson = convertGeometriesToFeatureCollection(geojsonObj.geometry)
  } else {
    newGeojson = geojsonObj
  }
  return JSON.stringify(newGeojson)
}

export const findMyPrivateAncillaryData = (
  findAncillaryData,
  username,
  assetsOption
) => {
  return findAncillaryData.reduce((acc, cur) => {
    const asset = _.find(assetsOption, ['value', cur])
    if (!asset) return acc

    const { username: assetUsername } = asset
    if (isPrivateEntity(asset) && assetUsername === username) {
      acc.push(cur)
    }
    return acc
  }, [])
}

export const findAncillaryDataIdsFromWorkflowPayload = payload => {
  const { widgets } = payload

  return widgets.reduce((acc, cur) => {
    if (isStringValueEqual(cur.type, WORKFLOW_WIDGET_TYPES.FILTER_GEOFENCE)) {
      const { entityType, entityId } =
        _.get(
          cur,
          'geofenceWidgetProperties.geofenceComparison.rightOperandReference'
        ) || {}
      if (entityType === WORKFLOW_GEOFENCE_ENTITY_TYPES.ancillaryData) {
        acc.push(entityId)
      }
    }
    return acc
  }, [])
}

export const findAncillaryDataIdsFromMap = (layers = []): string[] => {
  return _.reduce(
    layers,
    (acc, cur) => {
      const assetPolygonId = _.get(cur.style, [
        LAYER_TYPES.polygon,
        'polygon',
        'assetPolygonId',
      ])
      if (assetPolygonId) {
        acc.push(assetPolygonId)
      }
      return acc
    },
    []
  )
}

export const getShouldBeSharedAncillaryDataIdsFromWorkflow = (
  payload,
  username,
  asserts
) => {
  const findAncillaryData = findAncillaryDataIdsFromWorkflowPayload(payload)
  return findMyPrivateAncillaryData(findAncillaryData, username, asserts)
}

export const getShouldBeSharedAncillaryDataIdsFromMap = (
  layers = [],
  username,
  asserts
) => {
  const findAncillaryData = findAncillaryDataIdsFromMap(layers)
  return findMyPrivateAncillaryData(findAncillaryData, username, asserts)
}
