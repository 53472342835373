// libraries
import _ from 'lodash'

// utils
import { DEFAULT_WIDGET_COLOUR } from 'constants/widget'
import { getSimpleAggregatedResultValueLabel } from 'helpers/widget'
import { DEFAULT_AGGREGATION_KEY_TYPE } from 'constants/aggregation'

const renderWidgetNumeric = ({
  widgetData,
  widgetSetting,
  calculateWidgetData,
  updateWidgetProps,
}) => {
  const {
    colour = DEFAULT_WIDGET_COLOUR,
    aggregation = DEFAULT_AGGREGATION_KEY_TYPE,
    overridePropertyTitle,
  } = widgetSetting

  const { value, label, widgetColour } = getSimpleAggregatedResultValueLabel({
    geojsonRows: widgetData,
    aggregation,
    overridePropertyTitle,
    colour,
  })

  const options = _.isNil(value) ? {} : { value, label, colour: widgetColour }
  updateWidgetProps(options, calculateWidgetData, {})
}

export default renderWidgetNumeric
