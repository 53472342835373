// libraries
import { useRef, useMemo, ReactElement, PropsWithChildren } from 'react'
import { useToggle } from 'react-use'
import { GiEarthAmerica } from 'react-icons/gi'
import ReactDOM from 'react-dom'

// scss
import { StyledMapStyleControl } from './style'
import scss from './index.module.scss'

type MapStyleControlProps = PropsWithChildren<{
  className?: string
}>

type CoordProp = DOMRect | Record<string, undefined>

const MapStyleControl = ({
  className = '',
  children,
}: MapStyleControlProps): ReactElement => {
  const [isStylePickerVisible, toggleStylePickerVisible] = useToggle(false)
  const iconRef = useRef<HTMLDivElement>(null)

  const { current } = iconRef

  const { x, y }: CoordProp = useMemo(() => {
    return current ? current.getBoundingClientRect() : {}
  }, [current])

  return (
    <>
      {isStylePickerVisible && x && y
        ? ReactDOM.createPortal(
            <StyledMapStyleControl
              className={`${scss.container} d-flex align-items-center`}
              x={x}
              y={y}
              onMouseLeave={() => toggleStylePickerVisible(false)}
            >
              {children}
            </StyledMapStyleControl>,
            document.body
          )
        : null}
      <button
        type='button'
        className={`${scss.expandButton} ${className}`}
        onClick={() => toggleStylePickerVisible()}
        onMouseEnter={() => toggleStylePickerVisible(true)}
        ref={iconRef}
      >
        <GiEarthAmerica className={scss.expandIcon} />
      </button>
    </>
  )
}

export default MapStyleControl
