// libraries
import _ from 'lodash'

// constants
import {
  LAYER_TYPES,
  AGGREGATION_LAYER_TYPES,
  DECK_GL_SUPPORTED_FILTER_LAYER_TYPES,
} from 'constants/map'
import { OPERATORS } from 'constants/filter'

// utils
import { isLiveDataset, isHistoricalDataset } from 'helpers/unipipe'
import { isNonAggregatedPointLayer } from 'helpers/layerStyle'
import { getValidSelectedDateTimeRange } from 'helpers/layerDatetime'

export const hasLiveDataset = layers =>
  _.some(layers, ({ timeliness }) => isLiveDataset(timeliness))

export const hasNonLiveDataset = layers =>
  _.every(layers, ({ timeliness }) => !isLiveDataset(timeliness))

export const hasHistoricalDataset = layers =>
  _.some(layers, ({ timeliness }) => isHistoricalDataset(timeliness))

export const hasDataset = layers =>
  !_.isEmpty(_(layers).map('dataset').compact().value())

export const isTileLayer = layer =>
  [LAYER_TYPES.tile, LAYER_TYPES.tile3D].includes(layer.type)

/**
 * Check whether the given layer is aggregated layer or not
 * @param {Layer} layer
 *
 * @return {Boolean}
 */
export const isAggregatedLayer = layer =>
  _.includes(AGGREGATION_LAYER_TYPES, layer?.type)

/**
 * Get the filter option with the minimum time range between the
 * original dateTimeRange and selected dateTimeRange
 * @param {Object} dateTimeRange {start,end}
 * @param {Object} selectedDateTimeRange {start,end}
 *
 * @return {Object} {property,operator,value}
 */
export const generateTimeFilter = value =>
  _.isEmpty(value)
    ? null
    : {
        property: 'time',
        operator: OPERATORS.isTimeBetween,
        value,
      }

export const generateValidSelectedTimeFilter = (
  dateTimeRange,
  selectedDateTimeRange,
  shouldUseGlobalTimeRange = true
) => {
  if (_.isEmpty(dateTimeRange)) return {}

  const value = shouldUseGlobalTimeRange
    ? getValidSelectedDateTimeRange({
        selectedDateTimeRange,
        dateTimeRange,
      })
    : dateTimeRange
  return _.isEmpty(value) ? {} : generateTimeFilter(value)
}

/**
 * Determine whether to use the deck.gl to handle the global selected time
 * filtering based on the layer type,timeliness  Only layers with the
 * historical dataset, which also required startTime and endTime as
 * specification parameters, will use the deck.gl filter extension. Currently,
 * all layer types except the Polygon layer,Hexagon layer time filtering are
 * handled by the deck.gl.For the point layer, it must be a layer with the
 * simple colour styling and is not aggregated
 * @param {Object} layer - the layer to check
 *
 * @return {Boolean}
 */
export const shouldUseDeckglTimeFilter = (layer, isHistoricalData) => {
  const layerType = _.get(layer, 'type')
  if (!layerType) return false

  return (
    isHistoricalData &&
    DECK_GL_SUPPORTED_FILTER_LAYER_TYPES.includes(layerType) &&
    isNonAggregatedPointLayer(layer)
  )
}
