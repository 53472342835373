// libraries
import React from 'react'
import PropTypes from 'prop-types'

// utils
import { getEntityOwnerUsername } from 'helpers/utils'

// contexts
import { useStateValue } from 'contexts'

// scss
import scss from './index.module.scss'

export const ViewStatusMessage = ({ entity, entityLabel, isMine }) => {
  const {
    actions: {
      userActions: { findUserByName },
    },
  } = useStateValue()

  if (!entity) return ''
  const {
    givenName = '',
    familyName = '',
    email = '',
  } = findUserByName(getEntityOwnerUsername(entity)) || {}

  const name = `${givenName} ${familyName}` || email
  return isMine
    ? ''
    : `You are editing ${name}’s ${entityLabel}, any changes will affect all consumers of this ${entityLabel}`
}

const ViewStatusBar = ({ children, style, className }) => {
  return (
    <div style={style} className={`${scss.container} ${className}`}>
      {children}
    </div>
  )
}

ViewStatusBar.propTypes = {
  children: PropTypes.node,
  style: PropTypes.shape({}),
  className: PropTypes.string,
}

ViewStatusBar.defaultProps = {
  children: undefined,
  style: undefined,
  className: undefined,
}

export default ViewStatusBar
