import { useState, ReactElement, useEffect, useCallback, useMemo } from 'react'

// utils
import { useTimezone } from 'hooks'
import { getDateFilterLabel } from 'helpers/filter'

// constants
import { QUICK_DATETIME_PICKER_OPTIONS } from 'constants/datetime'

// components
import { MdKeyboardArrowDown } from 'components/icons'
import DateTimeFilterBody from 'components/common/List/FilterBuilder/DateTimeFilterBody'

import { SelectDropdown } from '../FilterForm'
import useFiltersDropdown from '../hooks/useFiltersDropdown'
import scss from '../index.module.scss'

const FilterTime = ({
  value,
  onChange,
  title = 'Time',
  vertical = false,
  formatOptionLabel,
}: {
  key: string
  value: [string, string]
  onChange: (v?: string[]) => void
  title: string
  vertical: boolean
  formatOptionLabel?: (v: unknown) => string
}): ReactElement => {
  const { isOpen, toggleOpen, ref } = useFiltersDropdown(value)

  const [timeValue, setTimeValue] = useState(value)
  const [startTime, endTime] = timeValue || []

  const { timezone } = useTimezone()

  useEffect(() => {
    setTimeValue(value)
  }, [value])

  const badgeContent = useMemo(
    () =>
      getDateFilterLabel({
        startDate: startTime,
        endDate: endTime,
        timezone,
        options: QUICK_DATETIME_PICKER_OPTIONS,
      }),
    [endTime, startTime, timezone]
  )

  const onTimeChange = useCallback(
    newTimeValue => {
      setTimeValue(newTimeValue)
      onChange(newTimeValue)
    },
    [onChange]
  )

  return (
    <div
      className={`d-flex align-items-center flex-wrap ${scss.anchor} `}
      style={{
        marginRight: vertical ? '0' : '10px',
      }}
    >
      <SelectDropdown
        isOpen={isOpen}
        onClose={toggleOpen}
        ref={ref}
        target={
          <div
            className={`d-flex align-items-center ${scss.button} `}
            type='button'
            onClick={toggleOpen}
          >
            <span>{title}: </span>
            <span className={scss.buttonSelected}>{badgeContent}</span>
            <MdKeyboardArrowDown className='ms-2' size={16} />
          </div>
        }
      >
        <div className={scss.dropdown}>
          <DateTimeFilterBody
            value={value}
            onTimeChange={onTimeChange}
            formatOptionLabel={formatOptionLabel}
            options={QUICK_DATETIME_PICKER_OPTIONS}
          />
        </div>
      </SelectDropdown>
    </div>
  )
}

export default FilterTime
