import { ReactElement } from 'react'
import { Field, useField } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'

import {
  Input,
  Toggle,
  ConditionalField,
  FormSelect,
} from 'components/common/Form'

type PropertySpec = {
  label: string
  description?: string
  required?: boolean
  inline?: boolean
  defaultValue?: string
}

type AddonFieldProperties = {
  visible: PropertySpec
  position: PropertySpec & { options: { label: string; value: string }[] }
  label: PropertySpec
}

const AddonField = ({
  name,
  disabled,
  hidePosition = false,
  properties,
}: {
  name: string
  disabled?: boolean
  hidePosition?: boolean
  properties: AddonFieldProperties
}): ReactElement => {
  const { visible, position, label: labelSpec } = properties
  const visibleFieldName = `${name}.visible`
  const positionFieldName = `${name}.position`
  const labelFieldName = `${name}.label`

  const positionFieldInput = useField(positionFieldName).input
  const labelFieldInput = useField(labelFieldName).input

  return (
    <>
      <Field
        component={Toggle}
        name={visibleFieldName}
        label={visible.label}
        description={visible.description}
        required={visible.required}
        disabled={disabled}
        inline={visible.inline}
      />
      {/* Show these field if 'visible === true' */}
      <ConditionalField watch={visibleFieldName}>
        {!hidePosition && (
          <Field
            name={positionFieldName}
            containerClassName='mb-2'
            component={FormSelect}
            isDisabled={disabled}
            {...position}
          />
        )}
        <Field
          name={labelFieldName}
          component={Input}
          {...labelSpec}
          disabled={disabled}
        />
      </ConditionalField>

      <OnChange name={visibleFieldName}>
        {value => {
          if (!value) {
            // Reset the fields if they are no longer visible
            positionFieldInput.onChange(position.defaultValue)
            labelFieldInput.onChange('')
          }
        }}
      </OnChange>
    </>
  )
}

export default AddonField
