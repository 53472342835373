// libraries
import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useToggle } from 'react-use'
import _ from 'lodash'

// constants
import { DEFAULT_AGGREGATION_OPTIONS } from 'constants/aggregation'
import { PROPERTY_VARIABLE_TYPES } from 'constants/filter'

// components
import {
  TitleWithTooltip,
  MultiSelect,
  PropertyPicker,
  MinMaxRange,
} from 'components/common'

// utils
import { isCountAggregation } from 'helpers/utils'

const getValidKeyBasedOnType = (type, key) => {
  return isCountAggregation(type) ? null : key
}

const DataAggregation = ({
  className,
  tooltip,
  propertyOptions,
  aggregationConfigs,
  onAggregationChangeHandler,
  hideMinMax,
  valueRangeIdentifier,
  aggregationIdentifier,
}) => {
  const {
    [aggregationIdentifier]: { key, type },
    [valueRangeIdentifier]: [minValue, maxValue],
  } = aggregationConfigs

  const [aggregationType, setAggregationType] = useState(type)
  const [aggregationKey, setAggregationKey] = useState(() =>
    getValidKeyBasedOnType(type, key)
  )

  const isCountType = useMemo(
    () => isCountAggregation(aggregationType),
    [aggregationType]
  )

  const [isAggregationKeyDisabled, toggleAggregationKey] = useToggle(false)
  const [showAggregation, toggleAggregation] = useToggle(true)

  useEffect(() => {
    let disabled
    if (isCountType) {
      disabled = true
      setAggregationKey(null)
    } else {
      disabled = false
    }
    toggleAggregationKey(disabled)
  }, [isCountType, toggleAggregationKey])

  useEffect(() => {
    if (aggregationKey !== key || aggregationType !== type) {
      onAggregationChangeHandler(aggregationIdentifier, {
        key: getValidKeyBasedOnType(aggregationType, aggregationKey),
        type: aggregationType,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aggregationKey, aggregationType])

  return (
    <div className={className}>
      {showAggregation ? (
        <>
          <TitleWithTooltip title='Aggregation based on' tooltip={tooltip} />
          <div className='row g-3'>
            <div className='col-4'>
              <MultiSelect
                className='aggregationType'
                value={aggregationType}
                options={DEFAULT_AGGREGATION_OPTIONS}
                onChange={aggType => {
                  setAggregationType(_.get(aggType, 'value', ''))
                }}
                isMulti={false}
                isClearable={false}
              />
            </div>
            <div className='col-8'>
              <PropertyPicker
                className='aggregationKey'
                property={aggregationKey}
                onValidOptionsChange={options => {
                  toggleAggregation(!_.isEmpty(options))
                }}
                propertyTypes={[PROPERTY_VARIABLE_TYPES.number]}
                propertyOptions={propertyOptions}
                onChange={({ value }) => {
                  setAggregationKey(value)
                }}
                isDisabled={isAggregationKeyDisabled}
                isMulti={false}
                isClearable={false}
                preSelect={!isCountType}
              />
            </div>
          </div>
          {!hideMinMax && (
            <MinMaxRange
              className='mt-1'
              minValue={minValue}
              maxValue={maxValue}
              onChange={range => {
                onAggregationChangeHandler(valueRangeIdentifier, range)
              }}
            />
          )}
        </>
      ) : (
        <div className='groupOptionLabel'>
          Note: Aggregation will be based on
          <span className='fw-bold text-uppercase'> count </span>
          because no numerical properties are available for this dataset
        </div>
      )}
    </div>
  )
}

DataAggregation.propTypes = {
  className: PropTypes.string,
  tooltip: PropTypes.string.isRequired,
  propertyOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  aggregationConfigs: PropTypes.shape({}).isRequired,
  onAggregationChangeHandler: PropTypes.func.isRequired,
  hideMinMax: PropTypes.bool,
  valueRangeIdentifier: PropTypes.string,
  aggregationIdentifier: PropTypes.string,
}

DataAggregation.defaultProps = {
  hideMinMax: false,
  className: 'mt-2',
  valueRangeIdentifier: 'valueRange',
  aggregationIdentifier: 'aggregation',
}

export default DataAggregation
