import {
  useContext,
  createContext,
  ReactElement,
  useCallback,
  useState,
  PropsWithChildren,
} from 'react'

import * as Icons from 'components/icons'
import _ from 'lodash'

type IconsList = typeof Icons

export type StaticResourceContextValue = {
  Icons: IconsList
  addStaticResource: (newStaticResources: { Icons: IconsList }) => void
}

export const StaticResourceContext = createContext<StaticResourceContextValue>({
  Icons,
} as StaticResourceContextValue)

export const StaticResourceProvider = ({
  additionalResources,
  children,
}: PropsWithChildren<{
  additionalResources?: IconsList
}>): ReactElement => {
  const [value, setValue] = useState(() =>
    _.merge({}, { Icons }, additionalResources)
  )

  const addStaticResource = useCallback(
    (newStaticResources: { Icons: IconsList }) => {
      setValue(oldValue => {
        return {
          ...oldValue,
          Icons: { ...oldValue.Icons, ...newStaticResources.Icons },
        }
      })
    },
    []
  )

  return (
    <StaticResourceContext.Provider value={{ ...value, addStaticResource }}>
      {children}
    </StaticResourceContext.Provider>
  )
}

export const useStaticResourceStateValue = (): StaticResourceContextValue =>
  useContext(StaticResourceContext)
