import _ from 'lodash'
import { useMemo, useCallback } from 'react'
import queryString from 'query-string'
import { objectToBase64 } from 'helpers/utils'

const queryStringOptions = {
  arrayFormat: 'comma',
  skipNull: true,
  skipEmptyString: true,
}

const useQueryParameters = () => {
  const { location, history } = window

  const parseQueryString = useCallback(str => {
    return queryString.parse(str)
  }, [])

  const stringifyQueryString = useCallback((obj, hash) => {
    const queryStr = queryString.stringify(obj, queryStringOptions)
    return hash ? objectToBase64(queryStr) : queryStr
  }, [])

  const queryParams = useMemo(() => {
    return queryString.parse(
      decodeURIComponent(location.search),
      queryStringOptions
    )
  }, [location.search])

  const updateQueryParams = useCallback(
    (params, hash) => {
      if (_.isEmpty(params)) return

      const { search, origin, pathname } = location
      const basePath = `${origin}${pathname}`
      const oldQueryParams = queryString.parse(search, queryStringOptions)
      const queryStr = stringifyQueryString(
        { ...oldQueryParams, ...params },
        hash
      )

      if (queryStr) {
        history.replaceState(undefined, undefined, `${basePath}?${queryStr}`)
      }
    },
    [history, location, stringifyQueryString]
  )

  return {
    queryParams,
    parseQueryString,
    updateQueryParams,
    stringifyQueryString,
  }
}

export default useQueryParameters
