import keymirror from 'keymirror'

import {
  DEFAULT_MAP_COLOUR,
  DEFAULT_MAP_ICON_BG_COLOUR,
  DEFAULT_MAP_LABEL_COLOUR,
  DEFAULT_MAP_HIGHLIGHT_COLOUR,
  DEFAULT_MAP_COLOUR_RAMP,
  MAPBOX_LIMITS,
} from 'constants/map'

import { DEFAULT_AGGREGATION_KEY_TYPE } from 'constants/aggregation'
import { PROPERTY_VARIABLE_TYPES } from 'constants/filter'
import { COLOUR_TYPES } from 'constants/colour'

export const LAYER_VIS_CONFIG_KEYS = keymirror({
  enableClustering: null,
})

export const LAYER_VIS_CONFIGS = {
  colourType: {
    type: 'object',
    defaultValue: COLOUR_TYPES.simple,
    label: 'Colour Type',
    property: 'colourType',
  },
  fillColour: {
    type: 'array',
    defaultValue: DEFAULT_MAP_COLOUR,
    label: 'Fill Colour',
    property: 'fillColour',
  },
  tileFillColour: {
    type: 'array',
    defaultValue: [140, 170, 180],
    label: 'Tile Fill Colour',
  },
  tileLineColour: {
    type: 'array',
    defaultValue: [192, 192, 192],
    label: 'Tile Line Colour',
  },
  lineColour: {
    type: 'array',
    defaultValue: DEFAULT_MAP_COLOUR,
    label: 'Line Colour',
    property: 'lineColour',
  },
  highlightColour: {
    type: 'array',
    defaultValue: DEFAULT_MAP_HIGHLIGHT_COLOUR,
    label: 'Highlight Colour',
    property: 'highlightColour',
  },
  hexagonRadius: {
    type: 'number',
    defaultValue: 500,
    label: 'Hexagon Radius',
    range: [0, 5000],
    step: 1,
    property: 'hexagonRadius',
  },
  radius: {
    type: 'number',
    defaultValue: 8,
    label: 'Radius',
    isRanged: false,
    pixelRange: [0, 500],
    range: [0, 50],
    step: 0.1,
    property: 'radius',
  },
  pointSize: {
    type: 'number',
    defaultValue: 1,
    label: 'Point Size',
    range: [0, 20],
    step: 0.1,
    property: 'pointSize',
  },
  radiusRange: {
    type: 'number',
    defaultValue: [0, 50],
    label: 'Radius Range',
    range: [0, 500],
    step: 0.1,
    property: 'radiusRange',
  },
  radiusProperty: {
    type: 'string',
    defaultValue: '',
    label: 'radius property',
    property: 'radiusProperty',
  },
  ionAssetId: {
    type: 'string',
    defaultValue: '',
    label: 'ionAssetId',
    property: 'ionAssetId',
  },
  ionToken: {
    type: 'string',
    defaultValue: '',
    label: 'ionToken',
    property: 'ionToken',
  },
  zoomVisibilityRange: {
    type: 'number',
    defaultValue: [MAPBOX_LIMITS.minZoom, MAPBOX_LIMITS.maxZoom],
    label: 'zoomVisibilityRange',
    range: [MAPBOX_LIMITS.minZoom, MAPBOX_LIMITS.maxZoom],
    step: 0.1,
    property: 'zoomVisibilityRange',
  },
  // Value that is multiplied with weight of every object to obtain the final weight.
  intensity: {
    type: 'number',
    defaultValue: 1,
    label: 'Intensity',
    range: [0, 50],
    step: 0.1,
    property: 'intensity',
  },
  // In final rendering weight of pixel determines its opacity, this will help smoothen the boundaries, but at the same time, pixels with low relative weight are hard to spot (due to low alpha value). `enhanceFactor` can be increased to increase the opacity while keeping the hot spots unchanged.
  enhanceFactor: {
    type: 'number',
    defaultValue: 20,
    label: 'Enhance Factor',
    range: [0, 100],
    step: 0.1,
    property: 'enhanceFactor',
  },
  propertyValueRange: {
    type: 'number',
    defaultValue: [],
    label: 'Property Value Range',
    step: 0.1,
    property: 'propertyValueRange',
  },
  lineShadow: {
    type: 'number',
    defaultValue: 1,
    label: 'Line Shadow',
    isRanged: false,
    range: [1, 5],
    step: 1,
    property: 'lineShadow',
  },
  lineWidth: {
    type: 'number',
    defaultValue: 5,
    label: 'Line Width (meters)',
    isRanged: false,
    range: [1, 100],
    pixelRange: [1, 20],
    step: 1,
    property: 'lineWidth',
  },
  lineWidthProperty: {
    type: 'string',
    defaultValue: '',
    label: 'line width property',
    property: 'lineWidthProperty',
  },
  heightProperty: {
    type: 'string',
    defaultValue: '',
    label: 'height property',
    property: 'heightProperty',
  },
  weightProperty: {
    type: 'string',
    defaultValue: '',
    label: 'weight property',
    property: 'weightProperty',
  },
  colourRange: {
    type: 'array',
    defaultValue: DEFAULT_MAP_COLOUR_RAMP,
    label: 'Color range',
    property: 'colorRange',
  },
  colourClasses: {
    type: 'array',
    defaultValue: [],
    label: 'Colour classification',
    property: 'colourClasses',
  },
  fillColourClasses: {
    type: 'array',
    defaultValue: [],
    label: 'Colour classification',
    property: 'colourClasses',
  },
  lineColourClasses: {
    type: 'array',
    defaultValue: [],
    label: 'Colour classification',
    property: 'colourClasses',
  },
  colourProperty: {
    type: 'object',
    defaultValue: {},
    label: 'Colour property config {key,value}',
    property: 'colourProperty',
  },
  lineColourProperty: {
    type: 'object',
    defaultValue: {},
    label: 'Colour property config {key,value}',
    property: 'lineColourProperty',
  },
  fillColourProperty: {
    type: 'object',
    defaultValue: {},
    label: 'Colour property config {key,value}',
    property: 'lineColourProperty',
  },
  colourBreaks: {
    type: 'array',
    defaultValue: [],
    label: 'Colour classification breaks',
    property: 'colourBreaks',
  },
  valueRangeForColour: {
    type: 'array',
    defaultValue: [null, null],
    label: 'Value range for colour',
    property: 'valueRangeForColour',
  },
  aggregationForColour: {
    type: 'object',
    defaultValue: DEFAULT_AGGREGATION_KEY_TYPE,
    label: 'Aggregation key and type for colour',
    isRanged: false,
    property: 'aggregationForColour',
  },
  aggregationProperty: {
    type: 'object',
    defaultValue: { value: '', type: PROPERTY_VARIABLE_TYPES.number },
    label: 'Aggregation Property',
    isRanged: false,
    property: 'aggregationProperty',
  },
  elevationScale: {
    type: 'number',
    defaultValue: 0,
    label: 'Elevation Scale',
    isRanged: false,
    range: [0, 100],
    step: 0.1,
    property: 'elevationScale',
  },
  elevationRange: {
    type: 'number',
    defaultValue: [0, 500],
    label: 'Height Scale',
    isRanged: true,
    range: [0, 1000],
    step: 0.1,
    property: 'sizeRange',
  },
  arcLineWidth: {
    type: 'number',
    defaultValue: 5,
    label: 'Line Width',
    isRanged: false,
    range: [1, 100],
    pixelRange: [1, 20],
    step: 1,
    property: 'lineWidth',
  },
  arcHeight: {
    type: 'number',
    defaultValue: 1,
    label: 'layer height',
    isRanged: false,
    range: [0, 100],
    step: 1,
    property: 'arcHeight',
  },
  aggregationForHeight: {
    type: 'object',
    defaultValue: DEFAULT_AGGREGATION_KEY_TYPE,
    label: 'Aggregation key and type for height',
    isRanged: false,
    property: 'aggregationForHeight',
  },
  opacity: {
    type: 'number',
    defaultValue: 80,
    label: 'Opacity',
    isRanged: false,
    range: [0, 100],
    step: 1,
    property: 'opacity',
  },
  iconPosition: {
    type: 'object',
    defaultValue: { x: 1440, y: 0 },
    label: 'Icon SVG Icon relative position',
    property: 'bgColour',
  },
  bgColour: {
    type: 'array',
    defaultValue: DEFAULT_MAP_ICON_BG_COLOUR,
    label: 'Icon Background Colour',
    property: 'bgColour',
  },
  labelColour: {
    type: 'array',
    defaultValue: DEFAULT_MAP_LABEL_COLOUR,
    label: 'Icon Label Colour',
    property: 'labelColour',
  },
  labelAnchor: {
    type: 'string',
    defaultValue: 'middle',
    label: 'TextAnchor',
    property: 'labelAnchor',
  },
  labelAlignment: {
    type: 'string',
    defaultValue: 'center',
    label: 'labelAlignment',
    property: 'labelAlignment',
  },
  labelOffsetX: {
    type: 'number',
    defaultValue: 0,
    label: 'labelOffsetX',
    isRanged: false,
    range: [-100, 100],
    step: 1,
    property: 'labelOffsetX',
  },
  labelOffsetY: {
    type: 'number',
    defaultValue: 0,
    label: 'labelOffsetY',
    isRanged: false,
    range: [-100, 100],
    step: 1,
    property: 'labelOffsetY',
  },
  tileMinZoom: {
    type: 'number',
    defaultValue: 0,
    label: 'tileMinZoom',
    isRanged: false,
    range: [0, 23],
    step: 1,
    property: 'tileMinZoom',
  },
  tileMaxZoom: {
    type: 'number',
    defaultValue: 24,
    label: 'tileMaxZoom',
    isRanged: false,
    range: [0, 24],
    step: 1,
    property: 'tileMaxZoom',
  },
  headingProperty: {
    type: 'string',
    defaultValue: '',
    label: 'heading property',
    property: 'headingProperty',
  },
  labelProperty: {
    type: 'string',
    defaultValue: '',
    label: 'label property',
    property: 'labelProperty',
  },
  labelSize: {
    type: 'number',
    defaultValue: 24,
    label: 'labelSize',
    range: [0, 128],
    step: 1,
    property: 'labelSize',
  },
  angle: {
    type: 'number',
    defaultValue: 0,
    label: 'icon angle',
    isRanged: false,
    range: [0, 360],
    step: 1,
    property: 'angle',
  },
  'hi-precision': {
    type: 'boolean',
    defaultValue: false,
    label: 'High Precision Rendering',
    property: 'hi-precision',
    description: 'High precision will result in slower performance',
  },
  extruded: {
    type: 'boolean',
    defaultValue: true,
    label: 'Enable Polygon Height',
    property: 'extruded',
  },
  elevationExcluded: {
    type: 'boolean',
    defaultValue: true,
    label: 'Elevation',
    property: 'elevationExcluded',
  },
  [LAYER_VIS_CONFIG_KEYS.enableClustering]: {
    type: 'boolean',
    defaultValue: false,
    label: 'Default cluster setting',
    property: LAYER_VIS_CONFIG_KEYS.enableClustering,
  },
  isFilled: {
    type: 'boolean',
    defaultValue: true,
    label: 'Filled',
    property: 'isFilled',
  },
  isStroked: {
    type: 'boolean',
    defaultValue: false,
    label: 'Stroked',
    property: 'isStroked',
  },
  enable3d: {
    type: 'boolean',
    defaultValue: false,
    label: 'Height',
    property: 'enable3d',
    description: 'Click button at top right of the map to switch to 3d view',
  },
  stroked: {
    type: 'boolean',
    label: 'Stroke',
    defaultValue: true,
    property: 'stroked',
  },
  filled: {
    type: 'boolean',
    label: 'Fill',
    defaultValue: false,
    property: 'filled',
  },
  wireframe: {
    type: 'boolean',
    defaultValue: false,
    label: 'Show Wireframe',
    property: 'wireframe',
  },
  arePointsAggregated: {
    type: 'boolean',
    defaultValue: false,
    label: 'Points Aggregation',
    property: 'Points Aggregation',
  },
  polygon: {
    type: 'object',
    defaultValue: null,
    label: 'polygon object',
    property: 'polygon',
  },
}

export type LayerVisConfig = keyof typeof LAYER_VIS_CONFIGS
