import { ReactElement, useMemo } from 'react'
import _ from 'lodash'

// types
import type { User } from 'types/user'

// constants
import { AVATAR_SIZES } from 'constants/user'
import { TOOLTIP_PLACEMENT } from 'constants/settings'

// components
import { Avatar, Tooltip } from 'components/common'

import scss from './index.module.scss'

const DEFAULT_MAXIMUM_NAME_LENGTH = 26

const truncateName = (name: string, maximumNameLength: number) => {
  const shouldTruncateName = name.length > maximumNameLength
  const displayedName = shouldTruncateName
    ? _.truncate(name, {
        length: maximumNameLength,
      })
    : name

  return { displayedName, shouldTruncateName }
}

const UserAvatarAndName = ({
  user,
  maximumNameLength = DEFAULT_MAXIMUM_NAME_LENGTH,
}: {
  user: User
  maximumNameLength?: number
}): ReactElement => {
  const { name } = user

  const { displayedName, shouldTruncateName } = useMemo(
    () => truncateName(name, maximumNameLength),
    [name, maximumNameLength]
  )

  return (
    <div className='d-flex align-items-center'>
      <Avatar user={user} size={AVATAR_SIZES.xSmall} tooltip={false} />
      <div className={scss.fullName}>
        {shouldTruncateName ? (
          <Tooltip placement={TOOLTIP_PLACEMENT.top} overlay={name}>
            <div>{displayedName}</div>
          </Tooltip>
        ) : (
          displayedName
        )}
      </div>
    </div>
  )
}

export default UserAvatarAndName
