import { ReactElement } from 'react'
import { IconType } from 'react-icons'
import { useTheme } from '@emotion/react'

import { FILE_UPLOAD_STATUS } from 'constants/fileUpload'

import { useStaticResourceStateValue } from 'contexts'

const STATUS_TO_ICON = {
  [FILE_UPLOAD_STATUS.UPLOADING]: 'ButtonLoaderIcon',
  [FILE_UPLOAD_STATUS.ERROR]: 'BiError',
  [FILE_UPLOAD_STATUS.SUCCESS]: 'MdCheck',
}

const ICON_SIZE = 12

const FileUploadingStatusIcon = ({
  uploadingStatus,
}: {
  uploadingStatus: keyof typeof FILE_UPLOAD_STATUS
}): ReactElement => {
  const theme = useTheme()
  const { Icons } = useStaticResourceStateValue()
  const Icon = (Icons as Record<string, IconType>)[
    STATUS_TO_ICON[uploadingStatus]
  ]
  const isLoading = uploadingStatus === FILE_UPLOAD_STATUS.UPLOADING

  const STATUS_TO_COLOUR = {
    [FILE_UPLOAD_STATUS.UPLOADING]: theme.primary,
    [FILE_UPLOAD_STATUS.ERROR]: theme['yellow-600'],
    [FILE_UPLOAD_STATUS.SUCCESS]: theme.success,
  }

  return (
    <Icon
      size={ICON_SIZE}
      width={ICON_SIZE}
      height={ICON_SIZE}
      color={STATUS_TO_COLOUR[uploadingStatus]}
      {...(isLoading && { className: 'fa-spin' })}
    />
  )
}

export default FileUploadingStatusIcon
