// libraries
import {
  ReactElement,
  useState,
  useEffect,
  useMemo,
  useCallback,
  FC,
} from 'react'
import { useMount, useUpdateEffect } from 'react-use'
import _ from 'lodash'
import { useTheme } from '@emotion/react'

// components
import { NumericInput } from 'components/common'
import TooltipSlider from 'components/common/RangeSlider/TooltipSlider'

// util
import { useRangeSliderStyle } from 'hooks'

import type { Payload } from 'types/common'
import type { SliderContainerProps, SliderProps } from '../types'

const getValidRange = (
  val: number,
  valRange: [number, number],
  changeable = false
) => {
  if (!changeable) return valRange

  let newRange = valRange
  const [min, max] = valRange
  if (val > max) {
    newRange = [min, val]
  } else if (val < min) {
    newRange = [val, max]
  }
  return newRange
}

const getPluralUnit = (number: number, unit: string) =>
  `${number === 1 ? unit : `${unit}s`}`

export const Slider: FC<SliderProps> = ({
  value, // current value of slider
  range, // minimum and maximum value of the slider
  onChange,
  defaultValue,
  unit,
  showMarks,
  changeOnBlur,
  makeUnitPlural,
  isLarge,
  showDots,
  hideInput,
  ...rest
}): ReactElement => {
  const [sliderValue, setSliderValue] = useState(value)
  const theme = useTheme()

  const styleOverride = useRangeSliderStyle({
    type: 'slider',
    isLarge,
    showDots,
  })

  useEffect(() => {
    setSliderValue(value)
  }, [value])

  const marks = useMemo(() => {
    if (!showMarks) return {}

    const overrideRangeStyle = (v: number): Payload | undefined => {
      const commonStyle = {
        color: theme.secondary,
        fontWeight: 600,
        fontSize: 12,
        textTransform: 'uppercase',
        width: 'max-content',
      }

      return commonStyle
    }

    // the order is important with the styling, until we found a better way
    // src/components/common/RangeSlider/index.scss
    // .rc-slider-mark-text
    return _.reduce(
      [range[0], range[1]],
      (acc, cur) => {
        const style = overrideRangeStyle(cur)

        return {
          ...acc,
          [cur]: {
            label: unit
              ? `${cur} ${makeUnitPlural ? getPluralUnit(cur, unit) : unit}`
              : cur,
            ...(style && { style }),
          },
        }
      },
      {}
    )
  }, [theme, range, showMarks, unit, makeUnitPlural])

  return _.isNumber(sliderValue) ? (
    <TooltipSlider
      {...styleOverride}
      {...rest}
      min={range[0]}
      max={range[1]}
      unit={unit}
      hideInput={hideInput}
      isLarge={isLarge}
      {...(showMarks && {
        marks,
      })}
      value={sliderValue}
      {...(changeOnBlur
        ? {
            // only change the internal value when dragging the slider
            onChange: setSliderValue,
            // change the value when the dragging action is finished
            onChangeComplete: onChange,
          }
        : {
            onChange,
          })}
    />
  ) : (
    <></>
  )
}

Slider.defaultProps = {
  step: 1,
  sliderSize: 8,
  changeOnBlur: true,
  makeUnitPlural: true,
  isLarge: false,
  hideInput: true,
}

const getValidValue = (
  value: number,
  range = [],
  defaultValue: number
): number => {
  const [min, max] = range
  const newValue = _.isNil(value) ? defaultValue : value
  return _.clamp(newValue, min, max)
}

export const SliderContainer: FC<SliderContainerProps> = ({
  value,
  range,
  onChange,
  changeable,
  sliderSize,
  onBlur,
  onFocus,
  className,
  defaultValue,
  hideInput,
  ...rest
}): ReactElement => {
  const [sliderValue, setSliderValue] = useState<number>()

  const [rangeValue, setRangeValue] = useState(() =>
    getValidRange(value as number, range as [number, number], changeable)
  )

  const rangeProps = useMemo(
    () =>
      !changeable && rangeValue && { min: rangeValue[0], max: rangeValue[1] },
    [changeable, rangeValue]
  )

  const onSliderNumberChange = useCallback(
    (v: number) => {
      let val
      if (changeable) {
        const validRange = getValidRange(v, rangeValue)
        setRangeValue(validRange)
        val = v
      } else {
        val = getValidValue(v, range)
      }
      setSliderValue(val)
      onChange(val)
    },
    [changeable, onChange, range, rangeValue]
  )

  useMount(() => {
    setSliderValue(getValidValue(value as number, range, defaultValue))
  })

  useUpdateEffect(() => {
    setSliderValue(getValidValue(value, range))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useUpdateEffect(() => {
    setRangeValue(getValidRange(value, range, changeable))
  }, [value, range, changeable])

  const slider = (
    <Slider
      {...rest}
      value={sliderValue as number}
      hideInput={hideInput}
      range={rangeValue}
      onBeforeChange={() => {
        onFocus()
      }}
      onChange={val => {
        onChange(val)
        onBlur()
      }}
    />
  )

  return hideInput ? (
    <div className={className}>{slider}</div>
  ) : (
    <div className={`row g-3 ${className} d-flex align-items-center`}>
      <div className={`col-${sliderSize}`}>{slider}</div>
      <div className={`col-${12 - sliderSize} text-center`}>
        <NumericInput
          {...rest}
          {...rangeProps}
          value={sliderValue ?? 0}
          className='form-control rangeSliderInput'
          onChange={onSliderNumberChange}
          testId='slider-number'
        />
      </div>
    </div>
  )
}

SliderContainer.defaultProps = {
  step: 1,
  changeable: false,
  className: '',
  sliderSize: 8,
  onBlur: _.noop,
  onFocus: _.noop,
  disabled: false,
  hideInput: false,
}

export default SliderContainer
