import { ReactElement, MouseEvent } from 'react'

import { IconButton, VerticalDivider } from 'components/common'

const ADDON_ICON_SIZE = 16

const DateTimeInputAddons = ({
  className,
  displayClearIcon,
  addonIcon,
  onClear,
}: {
  className?: string
  displayClearIcon?: boolean
  addonIcon?: string
  onClear?: (e: MouseEvent<HTMLButtonElement>) => void
}): ReactElement => (
  <div className={className}>
    {displayClearIcon && (
      <IconButton
        icon='MdClose'
        size={14}
        className='d-flex p-0 ms-1'
        onClick={onClear}
      />
    )}
    {addonIcon && (
      <>
        <VerticalDivider
          height={18}
          className={displayClearIcon ? 'ms-2' : ''}
        />
        <IconButton
          icon={addonIcon}
          size={ADDON_ICON_SIZE}
          width={ADDON_ICON_SIZE}
          height={ADDON_ICON_SIZE}
          className='d-flex pe-0 ps-1 ms-2'
        />
      </>
    )}
  </div>
)

export default DateTimeInputAddons
