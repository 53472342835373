// libraries
import _ from 'lodash'
import { updateListItem } from 'helpers/utils'
import log from 'helpers/log'

export const WORKFLOW_INITIAL = 'WORKFLOW_INITIAL'
export const WORKFLOW_DESELECT_NODE = 'WORKFLOW_DESELECT_NODE'
export const WORKFLOW_SELECT_NODE = 'WORKFLOW_SELECT_NODE'
export const WORKFLOW_SELECT_DATASOURCE = 'WORKFLOW_SELECT_DATASOURCE'
export const WORKFLOW_DELETE_NODE = 'WORKFLOW_DELETE_NODE'
export const WORKFLOW_SET_LIST = 'WORKFLOW_SET_LIST'
export const WORKFLOW_SET_ISSUE_FORM_OPTIONS = 'WORKFLOW_SET_ISSUE_FORM_OPTIONS'

const getNode = node => {
  const nodeOptions =
    (_.isFunction(node.getOptions) && node.getOptions()) || node
  const requiredNodeOptions = _.pick(nodeOptions, ['id', 'type'])
  return { ...requiredNodeOptions, ..._.get(node, 'config', {}) }
}

const workflowActions = ({ state, dispatch }) => {
  return {
    initialWorkflow: () =>
      dispatch({
        type: WORKFLOW_INITIAL,
      }),
    updateWorkflowsList: workflowsList => {
      dispatch({
        type: WORKFLOW_SET_LIST,
        workflowsList,
      })
    },
    updateWorkflow: workflow => {
      if (!workflow) {
        log.warn('The workflow to be updated is invalid')
        return
      }

      const { workflowsList } = state

      // because there is a redirect action between the list page and update/edit item page, check if the local list is empty or not
      if (_.isEmpty(workflowsList)) return

      const newWorkflowsList = updateListItem(
        workflow.id,
        workflow,
        workflowsList,
        true
      )
      dispatch({
        type: WORKFLOW_SET_LIST,
        workflowsList: newWorkflowsList,
      })
    },
    selectDatasource: datasource =>
      dispatch({
        type: WORKFLOW_SELECT_DATASOURCE,
        datasource,
      }),
    selectNode: node =>
      dispatch({
        type: WORKFLOW_SELECT_NODE,
        selectedNode: getNode(node),
      }),
    deselectNode: () =>
      dispatch({
        type: WORKFLOW_DESELECT_NODE,
        selectedNode: null,
      }),
    deleteNode: node =>
      dispatch({
        type: WORKFLOW_DELETE_NODE,
        selectedNode: getNode(node),
      }),
  }
}

export default workflowActions
