import { ReactElement } from 'react'
import { Field, useField } from 'react-final-form'

// utils
import { getFieldName } from 'helpers/formBuilder'

// components
import * as FormComponents from 'components/common/Form'

const DefaultToggle = ({
  nodeId,
  ...rest
}: {
  nodeId: string
}): ReactElement => {
  const nodeName = getFieldName(nodeId, 'default')
  const { value = false } = useField(`${nodeName}`).input

  return (
    <Field
      {...rest}
      inline
      component={FormComponents.Toggle}
      name={getFieldName(nodeId, 'default')}
      toggleLabel={value ? 'Yes' : 'No'}
    />
  )
}

export default DefaultToggle
