import { ReactElement } from 'react'
import { WidgetProps } from '@rjsf/utils'

import type { DateTimeSchema, DateTimeUiSchema } from 'types/formBuilder'

import { DATE_TIME_FIELD_TYPES } from 'constants/formBuilder'

import WidgetWrapper from 'components/common/JsonForm/WidgetWrapper'

import TimePickerWidgetWrapper from './TimePickerWidgetWrapper'
import DatePickerWidgetWrapper from './DatePickerWidgetWrapper'

const DateTimeWidget = ({
  id,
  name,
  value,
  required,
  label,
  schema,
  uiSchema,
  onChange,
  onBlur,
  formContext: { isPreview },
  registry,
  rawErrors,
  disabled,
}: WidgetProps & {
  schema: DateTimeSchema
  uiSchema: DateTimeUiSchema
}): ReactElement => {
  const { schemaUtils } = registry
  const { format } = schema
  const {
    start,
    end,
    hidden: isHidden,
    readonly,
  } = uiSchema['ui:options'] || {}

  if (isHidden) return <></>

  const isTimeInput = format === DATE_TIME_FIELD_TYPES.time
  const displayLabel =
    schemaUtils.getDisplayLabel(schema, uiSchema) && (!!label || !!schema.title)

  const commonProps = {
    id,
    value,
    disabled,
    readonly,
    onBlur,
    onChange,
    isLarge: isPreview,
  }

  return (
    <WidgetWrapper
      {...(displayLabel && { label: label || schema.title })}
      description={schema.description}
      required={required}
      name={name}
      rawErrors={rawErrors}
      isLarge={isPreview}
    >
      {isTimeInput ? (
        <TimePickerWidgetWrapper {...commonProps} />
      ) : (
        <DatePickerWidgetWrapper
          format={format}
          start={start}
          end={end}
          {...commonProps}
        />
      )}
    </WidgetWrapper>
  )
}

export default DateTimeWidget
