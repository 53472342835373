// libraries
import { ReactElement } from 'react'

// constants
import { ANCILLARY_DATA_GEOJSON_TYPE } from 'constants/ancillaryData'

// utils
import { switchcaseF } from 'helpers/utils'

import AncillaryDataGeofence, { AncillaryDataGeofenceProps } from '../Geofence'

export type AncillaryDataEditItemProps = AncillaryDataGeofenceProps & {
  isShowing?: boolean
}

const AncillaryDataEditItem = ({
  ancillaryData,
  onChange,
  onCancel,
  isEditable,
  isLoading = false,
}: AncillaryDataEditItemProps): ReactElement => {
  const { assetType } = ancillaryData || {}

  const renderGeofence = () => (
    <AncillaryDataGeofence
      ancillaryData={ancillaryData}
      onChange={onChange}
      onCancel={onCancel}
      isEditable={isEditable}
      isLoading={isLoading}
    />
  )

  return switchcaseF({
    [ANCILLARY_DATA_GEOJSON_TYPE.geofence]: renderGeofence,
  })(() => <></>)(assetType)
}

export default AncillaryDataEditItem
