// libraries
import { useMemo, ReactElement } from 'react'
import _ from 'lodash'

// constants
import { AVATAR_SIZES } from 'constants/user'
import { TOOLTIP_PLACEMENT } from 'constants/settings'
import { BADGE_TYPES } from 'constants/common'

// components
import {
  Avatar,
  Star,
  CardOptions,
  Badge,
  Toggle,
  IconButton,
} from 'components/common'
import { Tooltip } from 'components/common/Tooltip'

import type { CardOptionsProps } from 'components/common/List/Card/CardOptions'
import type { ToggleFn } from 'types/common'
import type { GalleryItem } from 'types/entity'

import scss from './index.module.scss'

export type CardFooterProps<T = GalleryItem> = CardOptionsProps<T> & {
  item: T
  isMine?: boolean
  isShared?: boolean
  lockedIconLabel?: string
  enableFavorite?: boolean
  enableAvatar?: boolean
  isSuperAdminRole?: boolean
  badgeText?: string
  isBadgeActive?: boolean
  canUpdate?: boolean
  toggleIsActive?: ToggleFn
  enableMfa?: boolean
}

const iconSize = 16

const CardFooter = ({
  isMine = false,
  isShared = false,
  enableFavorite = false,
  enableAvatar = true,
  lockedIconLabel,
  onFavorite,
  item,
  isSuperAdminRole,
  badgeText,
  isBadgeActive,
  canUpdate,
  toggleIsActive,
  enableMfa,
  ...rest
}: CardFooterProps): ReactElement => {
  const cardOptionsProps = useMemo(
    () => ({
      ...rest,
      isShared,
      subject: item,
      isMine,
    }),
    [isMine, isShared, rest, item]
  )

  const { owner, isActive, isFavorite } = item || {}

  const { user } = owner || {}

  const displayActiveToggle = useMemo(
    () => canUpdate && _.isFunction(toggleIsActive),
    [canUpdate, toggleIsActive]
  )

  return (
    <div
      className={`d-flex justify-content-between align-items-center ${scss.footer}`}
    >
      <div className='d-flex align-items-center'>
        {badgeText ? (
          <div>
            <Badge
              content={badgeText}
              type={isBadgeActive ? BADGE_TYPES.success : BADGE_TYPES.default}
            />
          </div>
        ) : enableAvatar && user ? (
          <>
            <Avatar
              user={user}
              size={AVATAR_SIZES.small}
              className={scss.avatar}
            />
            {isShared && (
              <Tooltip
                placement={TOOLTIP_PLACEMENT.top}
                trigger={['hover']}
                overlay={<span>Shared</span>}
              >
                <IconButton icon='HiUsers' size={iconSize} />
              </Tooltip>
            )}
          </>
        ) : (
          <></>
        )}
        {enableMfa && (
          <Tooltip
            placement={TOOLTIP_PLACEMENT.top}
            trigger={['hover']}
            overlay={<span>2-Step Verification enabled</span>}
          >
            <IconButton icon='MdLockOutline' size={iconSize} />
          </Tooltip>
        )}
      </div>
      <div className='d-flex align-items-center'>
        {displayActiveToggle && (
          <Toggle checked={isActive} onToggle={toggleIsActive} />
        )}
        {enableFavorite && onFavorite && (
          <Star
            size={18}
            isActive={isFavorite}
            onChange={() => onFavorite(item)}
          />
        )}
        <CardOptions {...cardOptionsProps} />
      </div>
    </div>
  )
}

export default CardFooter
