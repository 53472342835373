import { ReactElement } from 'react'

// utils
import { useAssetWidgetConfigs } from 'components/assets/assetsProfile/hooks'
import { DEFAULT_ASSET_WIDGET_DIVIDER_STYLE } from 'constants/assets'

// components
import {
  ColourPickerSimple,
  RangeSlider,
  TitleWithTooltip,
  Toggle,
} from 'components/common'

import type { Widget } from 'types/asset'

export type DividerWidgetSettings = {
  colour?: string
  height?: number
  opacity?: number
  isPageBreak?: boolean
}

const AssetWidgetDividerPanel = ({
  widget,
}: {
  widget: Widget & {
    settings: DividerWidgetSettings
  }
}): ReactElement => {
  const {
    settings: {
      colour = DEFAULT_ASSET_WIDGET_DIVIDER_STYLE.colour,
      height = DEFAULT_ASSET_WIDGET_DIVIDER_STYLE.height,
      opacity = DEFAULT_ASSET_WIDGET_DIVIDER_STYLE.opacity,
      isPageBreak = false,
    },
  } = widget

  const { updateWidgetConfigsSettings } = useAssetWidgetConfigs(widget)

  return (
    <>
      <div className='groupOption'>
        <RangeSlider
          title='Height'
          value={height}
          range={[0, 10]}
          onChange={val => updateWidgetConfigsSettings({ height: val })}
        />
      </div>
      <div className='groupOption'>
        <RangeSlider
          title='Opacity'
          value={opacity}
          step={0.01}
          range={[0, 1]}
          onChange={val => updateWidgetConfigsSettings({ opacity: val })}
        />
      </div>
      <div className='groupOption'>
        <div className='groupOptionTitle'>Select colour</div>
        <div className='groupOptionContent'>
          <ColourPickerSimple
            colour={colour}
            onChange={val => updateWidgetConfigsSettings({ colour: val })}
          />
        </div>
      </div>

      <div className='groupOption'>
        <div className='groupOptionTitle d-flex justify-content-between align-items-center'>
          <TitleWithTooltip title='Page break' />
          <Toggle
            label={isPageBreak ? 'enable' : 'disable'}
            checked={isPageBreak}
            onToggle={(val: boolean) =>
              updateWidgetConfigsSettings({ isPageBreak: val })
            }
          />
        </div>
      </div>
    </>
  )
}

export default AssetWidgetDividerPanel
