// libraries
import { createSelector } from 'reselect'

const getSiteState = state => state.siteState

const getSiteFeatureCategory = createSelector(
  getSiteState,
  state => state.category
)

const getSiteFeatureUserPropertiesKeys = createSelector(
  getSiteState,
  state => state.userPropertiesKeys
)

const siteSelectors = state => ({
  category: getSiteFeatureCategory(state),
  userPropertiesKeys: getSiteFeatureUserPropertiesKeys(state),
})

export default siteSelectors
