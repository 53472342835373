// types
import type { OnListItemChange } from 'components/common/List/hooks/useListItemActions'
import type { CardActions } from 'components/common/List/Card/CardOptions'
import type { FormList } from 'types/formBuilder'

// utils
import { useOnFavorite } from 'hooks'

// constants
import { USER_PREFERENCES } from 'constants/settings'

const useItemActions = ({
  onChange,
  setSelectedFormListId,
}: {
  onChange: OnListItemChange<FormList>
  setSelectedFormListId: (v: string) => void
}): CardActions<FormList> => {
  const onFavorite = useOnFavorite<FormList>(USER_PREFERENCES.favoriteFormLists)

  const onView = ({ id }: FormList) => {
    setSelectedFormListId(id)
  }

  return {
    onChange,
    onView,
    onEdit: onView,
    onFavorite,
  }
}

export default useItemActions
