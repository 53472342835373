import { HeatmapLayer as DeckGlHeatmapLayer } from '@deck.gl/aggregation-layers/typed'
import { LAYER_TYPES } from 'constants/map'

import AggregationLayer from '../aggregationLayer'

export const heatmapVisConfigs = {
  radius: 'radius',
  colourRange: 'colourRange',
  intensity: 'intensity',
  opacity: 'opacity',
  enhanceFactor: 'enhanceFactor',
  weightProperty: 'weightProperty',
}

export default class HeatmapLayer extends AggregationLayer {
  constructor(props) {
    super(props)
    this.registerVisConfig(heatmapVisConfigs, props.style[this.type])
  }

  get type() {
    return LAYER_TYPES.heatmap
  }

  renderLayer = ({ layerData, profileHandler, selectedDateTimeRange }) => {
    const {
      visConfig: {
        radius,
        colourRange,
        opacity,
        intensity,
        enhanceFactor,
        weightProperty,
      },
    } = this.config

    const basicProps = this.getLayerBasicProps({ layerData })

    const stylingProps = {
      opacity: this.getOpacity(opacity),
      colorRange: colourRange,
      radiusPixels: radius,
      intensity,
      enhanceFactor,
      autoHighlight: true,
    }

    const profileProps = this.getProfileConfig(profileHandler)

    const dataAccessors = {
      getPosition: d => d.geometry.coordinates,
      getWeight: d => this.getPropertyValueByName(d, weightProperty) || 1,
      updateTriggers: {
        getWeight: [weightProperty],
      },
    }
    const firstDataTime = this.getTimeFromFirstData(layerData)

    return [
      new DeckGlHeatmapLayer({
        ...basicProps,
        ...stylingProps,
        ...profileProps,
        ...dataAccessors,
        ...this.getTimeFilterProps(selectedDateTimeRange, firstDataTime),
      }),
    ]
  }
}
