// constants
import { LAYER_VIS_CONFIGS } from 'components/map/layers/deckLayers/layerFactory'

// helpers
import {
  getPropertyValueByName,
  getPropertyScale,
  getPropertyColour,
} from 'helpers/map'
import { getDefaultLayerStyle } from 'helpers/layerStyle'
import { isValueInRange } from 'helpers/utils'
import { excludeElevation } from 'helpers/geojson'
import { getColourState } from 'components/map/layers/deckLayers/utils'

export const LAYER_DEFAULT_STYLE = {
  isFilled: true,
  isStroked: false,
  // fill colour setting
  fillColour: LAYER_VIS_CONFIGS.fillColour.defaultValue,
  fillColourType: LAYER_VIS_CONFIGS.colourType.defaultValue,
  fillColourProperty: LAYER_VIS_CONFIGS.fillColourProperty.defaultValue,
  fillColourClasses: LAYER_VIS_CONFIGS.fillColourClasses.defaultValue,
  fillRange: LAYER_VIS_CONFIGS.propertyValueRange.defaultValue,
  // radius setting
  radius: LAYER_VIS_CONFIGS.radius.defaultValue,
  radiusProperty: LAYER_VIS_CONFIGS.radiusProperty.defaultValue,
  // line colour setting
  lineColour: LAYER_VIS_CONFIGS.fillColour.defaultValue,
  lineColourType: LAYER_VIS_CONFIGS.colourType.defaultValue,
  lineColourProperty: LAYER_VIS_CONFIGS.lineColourProperty.defaultValue,
  lineColourClasses: LAYER_VIS_CONFIGS.lineColourClasses.defaultValue,
  lineRange: LAYER_VIS_CONFIGS.propertyValueRange.defaultValue,
  // line width setting
  lineWidth: LAYER_VIS_CONFIGS.lineWidth.defaultValue,
  lineWidthProperty: LAYER_VIS_CONFIGS.lineWidthProperty.defaultValue,
  // height setting
  valueRangeForHeight: LAYER_VIS_CONFIGS.propertyValueRange.defaultValue,
  heightPropertyValue: LAYER_VIS_CONFIGS.heightProperty.defaultValue,
  elevationExcluded: LAYER_VIS_CONFIGS.elevationExcluded.defaultValue,
}

export const getGeoJsonLayerData = ({ layer, filteredDataState }) => {
  const { cachedMapLayerData, filteredDataWithoutTimeFilter } =
    filteredDataState
  if (cachedMapLayerData) return cachedMapLayerData
  if (!filteredDataWithoutTimeFilter) return []

  const { type, style } = layer
  const {
    fillColour,
    fillColourType,
    fillColourProperty,
    fillColourClasses,
    fillRange,
    radius,
    radiusProperty,
    lineColour,
    lineColourType,
    lineColourProperty,
    lineColourClasses,
    lineWidth,
    lineRange,
    lineWidthProperty,
    valueRangeForHeight,
    heightPropertyValue,
    elevationExcluded,
  } = getDefaultLayerStyle(style[type], LAYER_DEFAULT_STYLE)

  const {
    isSimpleColour: isSimpleFillColour,
    classification: fillColourClassification,
    crossFilterDimension: fillColourCategoryDimension,
  } = getColourState(
    filteredDataWithoutTimeFilter,
    fillColour,
    fillColourType,
    fillColourClasses,
    fillColourProperty,
    fillRange
  )

  const {
    isSimpleColour: isSimpleLineColour,
    classification: lineClassification,
    crossFilterDimension: lineColourCategoryDimension,
  } = getColourState(
    filteredDataWithoutTimeFilter,
    lineColour,
    lineColourType,
    lineColourClasses,
    lineColourProperty,
    lineRange
  )

  const getRadiusScale = getPropertyScale(
    filteredDataWithoutTimeFilter,
    radiusProperty
  )

  const getLineWidthScale = getPropertyScale(
    filteredDataWithoutTimeFilter,
    lineWidthProperty
  )

  const getHeightScale = getPropertyScale(
    filteredDataWithoutTimeFilter,
    heightPropertyValue
  )

  const getFillColourFn = getPropertyColour(
    isSimpleFillColour,
    fillColourProperty,
    fillColourClassification,
    fillColourCategoryDimension
  )

  const getLineColourFn = getPropertyColour(
    isSimpleLineColour,
    lineColourProperty,
    lineClassification,
    lineColourCategoryDimension
  )

  if (
    isSimpleFillColour &&
    isSimpleLineColour &&
    !radiusProperty &&
    !lineWidthProperty &&
    !heightPropertyValue &&
    !elevationExcluded
  ) {
    return filteredDataWithoutTimeFilter
  }

  return filteredDataWithoutTimeFilter.reduce((acc, oldGeojson) => {
    const geojson = elevationExcluded
      ? excludeElevation(oldGeojson)
      : oldGeojson

    const fColour = getFillColourFn(geojson)
    const lColour = getLineColourFn(geojson)

    const radiusVal =
      getRadiusScale(getPropertyValueByName(geojson, radiusProperty)) * radius

    const lWidth =
      getLineWidthScale(getPropertyValueByName(geojson, lineWidthProperty)) *
      lineWidth

    const height = getPropertyValueByName(geojson, heightPropertyValue)

    if (
      (isSimpleFillColour || fColour) &&
      (isSimpleLineColour || lColour) &&
      isValueInRange(height, valueRangeForHeight)
    ) {
      acc.push({
        ...geojson,
        radius: radiusVal,
        fillColour: fColour,
        lineColour: lColour,
        lineWidth: lWidth,
        elevation: getHeightScale(height),
      })
    }

    return acc
  }, [])
}
