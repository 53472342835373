// libraries
import _ from 'lodash'
import { ReactElement, useCallback, useState } from 'react'
import { useMount } from 'react-use'

// utils
import { getUtcDateTime } from 'helpers/datetime'
import { switchcaseF } from 'helpers/utils'

import {
  Repeat,
  WeekDaysType,
  RepeatType,
  MonthlyType,
} from 'components/workflow/Canvas/panels/PropertyPanel/common/DateTimePicker/types'
// constants
import {
  REPEAT_DAILY,
  REPEAT_FIELD_NAMES,
  REPEAT_MONTHLY,
  REPEAT_MONTHLY_ON_DAY,
  REPEAT_MONTHLY_ON_WEEK,
  REPEAT_TYPE_FIELDS,
  REPEAT_WEEKLY,
  REPEAT_YEARLY,
  WEEK_DAYS,
  DAYS_OPTIONS,
} from 'components/workflow/Canvas/panels/PropertyPanel/common/DateTimePicker/constants'

// components
import { TimePicker } from 'components/common/DateTime'
import RepeatPattern from './Repeat'
import Day from './Repeat/Day'
import Month from './Repeat/Month'
import Week from './Repeat/Week'
import Year from './Repeat/Year'

type RepeatOptionsProps = {
  repeat: Repeat
  isDisabled: boolean
  onChange: (schedule: Repeat) => void
}

const getDefaultSchedule = (): Repeat => {
  return {
    repeatType: 'REPEAT_MONTHLY',
    monthlyIncrement: 1,
    runAtUtcTime: getUtcDateTime().format('HH:mm'),
    monthlyType: 'REPEAT_MONTHLY_ON_WEEK',
    monthlyOnWeek: 1,
    monthlyOnWeekWeekday: getUtcDateTime()
      .format('dddd')
      .toUpperCase() as WeekDaysType,
  }
}

const getRecommendedSchedule = (repeat: Repeat) => {
  return _.isEmpty(repeat) ? getDefaultSchedule() : repeat
}

const getRepeatMonthlyFields = (monthlyType: MonthlyType | undefined) => {
  return monthlyType === REPEAT_MONTHLY_ON_DAY
    ? REPEAT_TYPE_FIELDS[REPEAT_MONTHLY_ON_DAY]
    : monthlyType === REPEAT_MONTHLY_ON_WEEK
    ? REPEAT_TYPE_FIELDS[REPEAT_MONTHLY_ON_WEEK]
    : []
}

const getFilterRepeatOptionsFields = (
  repeatType: RepeatType,
  repeat: Repeat
) => {
  const { monthlyType } = repeat
  const pickedFields =
    repeatType === REPEAT_MONTHLY
      ? getRepeatMonthlyFields(monthlyType)
      : REPEAT_TYPE_FIELDS[repeatType || REPEAT_MONTHLY_ON_WEEK]
  return _.pick(repeat, pickedFields)
}

const RepeatOptions = ({
  repeat,
  isDisabled,
  onChange,
}: RepeatOptionsProps): ReactElement => {
  useMount(() => {
    if (_.isEmpty(repeat)) {
      onChange(getDefaultSchedule())
    }
  })

  const [repeatOptionsState, setRepeatOptionsState] = useState<Repeat>(() =>
    getRecommendedSchedule(repeat)
  )

  const updateRepeatOptions = useCallback(
    payload => {
      const newScheduleState = { ...repeatOptionsState, ...payload }
      const pickedRepeatOptionsFields = getFilterRepeatOptionsFields(
        newScheduleState.repeatType,
        newScheduleState
      )
      setRepeatOptionsState(pickedRepeatOptionsFields as Repeat)
      onChange(pickedRepeatOptionsFields as Repeat)
    },
    [onChange, repeatOptionsState]
  )

  const renderRepeatOptions = useCallback(() => {
    return switchcaseF({
      [REPEAT_WEEKLY]: () => (
        <Week
          daysOfWeek={repeatOptionsState.weeklyWeekdays?.map(
            day => WEEK_DAYS.indexOf(day) + 1
          )}
          daysOptions={DAYS_OPTIONS}
          onChange={updateRepeatOptions}
          isDisabled={isDisabled}
        />
      ),
      [REPEAT_MONTHLY]: () => (
        <Month
          monthOnDay={repeatOptionsState.monthlyOnDay}
          monthOnWeek={repeatOptionsState.monthlyOnWeek}
          monthOn={repeatOptionsState.monthlyOnWeekWeekday as WeekDaysType}
          monthRepeatPattern={repeatOptionsState.monthlyType}
          onChange={updateRepeatOptions}
          isDisabled={isDisabled}
        />
      ),
      [REPEAT_YEARLY]: () => (
        <Year
          yearOn={repeatOptionsState.yearlyOnDate as string}
          onChange={updateRepeatOptions}
          isDisabled={isDisabled}
        />
      ),
      [REPEAT_DAILY]: () => <Day />,
    })(() => <></>)(repeatOptionsState.repeatType)
  }, [repeatOptionsState, updateRepeatOptions, isDisabled])

  return (
    <div className='p-2'>
      <TimePicker
        utcTime={repeatOptionsState.runAtUtcTime as string}
        onChange={(runAtUtcTime: string) =>
          updateRepeatOptions({ runAtUtcTime })
        }
        isDisabled={isDisabled}
      />
      <RepeatPattern
        repeatType={repeatOptionsState.repeatType}
        onChange={updateRepeatOptions}
        repeatEvery={
          repeatOptionsState[
            REPEAT_FIELD_NAMES[repeatOptionsState.repeatType] as keyof Repeat
          ] as string
        }
        disabled={repeatOptionsState.repeatType === REPEAT_WEEKLY}
        isDisabled={isDisabled}
      />
      {repeatOptionsState.repeatType !== REPEAT_DAILY && (
        <div className='row g-3 my-0' style={{ paddingLeft: 28 }}>
          {renderRepeatOptions()}
        </div>
      )}
    </div>
  )
}

export default RepeatOptions
