// libraries
import moment from 'moment-timezone'
import { ReactElement, useCallback, useMemo } from 'react'

// constants
import { YEARLY_ON_DATE_FORMAT } from 'components/workflow/Canvas/panels/PropertyPanel/common/DateTimePicker/constants'

// components
import { DatePicker } from 'components/workflow/Canvas/panels/PropertyPanel/common/DateTimePicker'

import scss from '../index.module.scss'

type YearOnType = {
  yearlyOnDate: string
}

type YearProps = {
  yearOn: string
  isDisabled: boolean
  onChange: (v: YearOnType) => void
}

const Year = ({ yearOn, isDisabled, onChange }: YearProps): ReactElement => {
  const handleSetDate = useCallback(
    (e: Date) => {
      onChange({ yearlyOnDate: moment(e).format(YEARLY_ON_DATE_FORMAT) })
    },
    [onChange]
  )

  const minDate = useMemo(() => {
    return yearOn
      ? new Date(new Date(yearOn).getFullYear(), 0, 1)
      : new Date(new Date().getFullYear(), 0, 1)
  }, [yearOn])

  const maxDate = useMemo(() => {
    return new Date(new Date(minDate).getFullYear(), 11, 31)
  }, [minDate])

  return (
    <div className='col-sm-12 mt-3'>
      <div className='row my-0'>
        <div className='col-sm-5'>
          <div className={scss.label}>On</div>
        </div>
        <div className='col-sm-7 ps-0'>
          <DatePicker
            yearOn={yearOn}
            handleSetDate={handleSetDate}
            dateFormat='MMM dd'
            minDate={minDate}
            maxDate={maxDate}
            isDisabled={isDisabled}
          />
        </div>
      </div>
    </div>
  )
}

export default Year
