// libraries
import {
  useRef,
  useState,
  useContext,
  useCallback,
  createContext,
  PropsWithChildren,
  ReactElement,
  useMemo,
} from 'react'
import _ from 'lodash'
import {
  useMount,
  useToggle,
  useGetSet,
  useMountedState,
  useSetState,
} from 'react-use'
import to from 'await-to-js'

// constants
import { ACTION_TYPES } from 'constants/user'
import { DEFAULT_MAP_THEME, INITIAL_MAP_STATE } from 'constants/map'

// components
import { Loading } from 'components/common'

import type { Payload, ThemeType, Toggle } from 'types/common'
import type { LayerWidget, Map, MapLayer, MapPickedFeature } from 'types/map'
import type { IHookStateSetAction } from 'react-use/lib/misc/hookState'
import type { DeckGLRef } from '@deck.gl/react/typed'

// utils
import { getNewMap, getBaseMapStyle } from 'helpers/map'
import { updateMap } from 'services/api/map'
import {
  useMapLayerState,
  useMapSelectedFeaturesState,
  useMapIssueState,
} from 'contexts/hooks'
import { useAncillaryData, useBranding } from 'hooks'
import { useMapEditor } from 'components/map/hooks'
import { useStateValue, useAbilityStateValue } from 'contexts'
import {
  isMapSettingChanged,
  omitMapTemporalSettings,
  handleUpdateMapError,
  getLayersWithExternalAncillaryData,
  updateMapApiLayerLegacySettings,
} from './MapContextUtils'

export type MapContextValue = {
  partialUpdateRemoteMapSettings: ({
    id,
    payload,
    forceUpdate,
  }: {
    id: string
    payload: Partial<Map>
    forceUpdate?: boolean
  }) => Promise<void>
  clearMapSelections: () => void
  map: Map
  setMap: React.Dispatch<React.SetStateAction<Partial<Map>>>
  mapRef: React.MutableRefObject<DeckGLRef | undefined>
  mapCanvasRef: React.MutableRefObject<HTMLElement | undefined>
  isMine?: boolean
  layersWithExternalConfig: MapLayer[]
  currentLayerId: string
  addLayer: (newLayer: MapLayer, index?: number) => void
  deleteLayer: (layerId: string) => void
  cloneLayer: (layerId: string) => void
  updateLayerConfigs: (layerId: string, payload: Payload) => void
  setCurrentLayerId: (layerId: string) => void
  updateMapLayers: (layers: MapLayer[], isNew?: boolean) => void
  getPopup: () => MapPickedFeature | undefined
  setPopup: React.Dispatch<IHookStateSetAction<MapPickedFeature | undefined>>
  zoomToMap?: MapLayer[] | null
  setZoomToMap: React.Dispatch<React.SetStateAction<MapLayer[] | null>>
  zoomToLayer: MapLayer | null
  setZoomToLayer: React.Dispatch<React.SetStateAction<MapLayer | null>>
  updateMapConfigs: (payload: Partial<Map>) => void
  isMapViewerMode: boolean
  toggleIsMapViewerMode: Toggle
  isTimelineExpanded: boolean
  toggleIsTimelineExpanded: Toggle
  mapRightPanelExpanded: boolean
  toggleMapRightPanel: Toggle
  isSpatialFilterEnabled: boolean
  toggleSpatialFilterEnabled: Toggle
  isLiveMode: boolean
  toggleIsLiveMode: Toggle
  theme: ThemeType
  maxWidgetSelected?: LayerWidget | null
  setMaxWidgetSelected: (widget?: LayerWidget | null) => void
  viewportBounds?: number[]
  setViewportBounds: React.Dispatch<React.SetStateAction<number[] | undefined>>
}

export const MapContext = createContext<MapContextValue>({} as MapContextValue)

export const MapProvider = ({
  children,
  initialMap,
  isMine = false,
  enableSaveMapToServer = false,
}: PropsWithChildren<{
  initialMap?: Map
  isMine?: boolean
  enableSaveMapToServer?: boolean
}>): ReactElement => {
  const isMapInViewMode = !useMapEditor()

  const {
    selectors: {
      unipipeSelectors: { mapEligibleDatasetOptions },
    },
    actions: {
      mapActions: { updateMap: updateMapAction },
    },
  } = useStateValue()
  const { mapboxStyles, baseMapStyle } = useBranding()

  const { checkAbility } = useAbilityStateValue()

  const { getPolygonsWithBbox } = useAncillaryData()

  const [map, setMap] = useSetState(
    initialMap ||
      getNewMap({ style: baseMapStyle, initialViewState: INITIAL_MAP_STATE })
  )

  const { id: mapId, issueSettings, spatialFilterEnabled } = map

  const [getRemoteMapSetting, setRemoteMapSetting] = useGetSet(null)

  const isMounted = useMountedState()

  /**
   * Zoom to the given layer
   */
  const [zoomToMap, setZoomToMap] = useState<MapLayer[] | null>()

  /**
   * whether it is in live mode (used for timeline and map container)
   */
  const [isLiveMode, toggleIsLiveMode] = useToggle(true)

  /**
   * Expand right blade (overlay)
   */
  const [mapRightPanelExpanded, toggleMapRightPanel] = useToggle(false)

  /**
   * The widget that maximize from right widget blade
   */
  const [maxWidgetSelected, setMaxWidgetSelected] =
    useState<LayerWidget | null>()

  const [isTimelineExpanded, toggleIsTimelineExpanded] = useToggle(false)

  const [isMapViewerMode, toggleIsMapViewerMode] = useToggle(false)

  const [layerInstances, setLayerInstances] = useState({})

  const [viewportBounds, setViewportBounds] = useState<number[]>()

  const [isSpatialFilterEnabled, toggleSpatialFilterEnabled] = useToggle(
    spatialFilterEnabled || false
  )

  const [getPopup, setPopup] = useGetSet<MapPickedFeature | undefined>(
    undefined
  )

  const partialUpdateRemoteMapSettings = useCallback(
    async ({
      id,
      payload,
      forceUpdate,
    }: {
      id: string
      payload: Partial<Map>
      forceUpdate?: boolean
    }) => {
      if (!enableSaveMapToServer || !isMounted() || isMapInViewMode) return

      const validPayload = omitMapTemporalSettings(payload)
      const newMapSettings = { ...map, ...validPayload }
      const canUpdate = checkAbility(ACTION_TYPES.update, newMapSettings)
      if (!canUpdate && !forceUpdate) return
      if (!isMapSettingChanged(newMapSettings, getRemoteMapSetting())) return

      const [mapErr, newRemoteMapSettings] = await to(
        updateMap(id, validPayload)
      )
      if (mapErr) {
        handleUpdateMapError(mapErr)
        return
      }

      if (isMounted()) {
        setRemoteMapSetting(newRemoteMapSettings)
      }

      // add the map to the global context or update the existing map in the global context
      updateMapAction(newRemoteMapSettings)
    },
    [
      enableSaveMapToServer,
      isMounted,
      isMapInViewMode,
      map,
      checkAbility,
      getRemoteMapSetting,
      updateMapAction,
      setRemoteMapSetting,
    ]
  )

  const updateMapConfigs = useCallback(
    (payload: Partial<Map>) => {
      setMap(payload)
      partialUpdateRemoteMapSettings({
        id: mapId,
        payload,
      })
    },
    [mapId, setMap, partialUpdateRemoteMapSettings]
  )

  const layerState = useMapLayerState({
    layers: map.layers,
    updateMapConfigs,
  })

  const issueLayerState = useMapIssueState({
    mapId,
    setMap,
    issueSettings,
    partialUpdateRemoteMapSettings,
  })

  const selectedFeaturesState = useMapSelectedFeaturesState()
  const { setSearchFeatureSelected, setMapClickedFeatures, setMapSuggestions } =
    selectedFeaturesState

  const mapRef = useRef<HTMLElement>()

  const mapCanvasRef = useRef<HTMLElement>()

  const clearMapSelections = useCallback(() => {
    setMaxWidgetSelected(undefined)
    setSearchFeatureSelected(undefined)
    setMapClickedFeatures([])
    setMapSuggestions([])
  }, [setMapClickedFeatures, setMapSuggestions, setSearchFeatureSelected])

  useMount(async () => {
    const initialMapSettings = async () => {
      const layers = updateMapApiLayerLegacySettings(
        map.layers,
        mapEligibleDatasetOptions
      )
      const newLayers = await getLayersWithExternalAncillaryData(
        layers,
        getPolygonsWithBbox
      )
      const payload = { layers: newLayers }
      const isMapStyleExists = !!_.find(mapboxStyles, {
        value: map.style,
      })
      if (!isMapStyleExists) {
        payload.style = getBaseMapStyle(baseMapStyle)
      }

      setMap({ ...initialMap, ...payload })

      await partialUpdateRemoteMapSettings({
        id: mapId,
        payload,
      })
    }

    initialMapSettings()
  })

  // Determine theme of the current map
  const theme = useMemo(() => {
    const currentStyle = mapboxStyles.find(config => config.value === map.style)
    return currentStyle?.theme || DEFAULT_MAP_THEME
  }, [map.style, mapboxStyles])

  return map ? (
    <MapContext.Provider
      value={{
        ...layerState,
        ...selectedFeaturesState,
        ...issueLayerState,
        clearMapSelections,
        map,
        setMap,
        mapRef,
        mapCanvasRef,
        zoomToMap,
        isMine,
        setZoomToMap,
        updateMapConfigs,
        isMapViewerMode,
        toggleIsMapViewerMode,
        maxWidgetSelected,
        setMaxWidgetSelected,
        mapRightPanelExpanded,
        toggleMapRightPanel,
        isTimelineExpanded,
        toggleIsTimelineExpanded,
        isLiveMode,
        toggleIsLiveMode,
        layerInstances,
        setLayerInstances,
        viewportBounds,
        setViewportBounds,
        isSpatialFilterEnabled,
        toggleSpatialFilterEnabled,
        partialUpdateRemoteMapSettings,
        getPopup,
        setPopup,
        theme,
      }}
    >
      {children}
    </MapContext.Provider>
  ) : (
    <Loading />
  )
}

export const useMapStateValue = (): MapContextValue => useContext(MapContext)
