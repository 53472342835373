// libraries
import { PropsWithChildren, ReactElement } from 'react'
import styled from '@emotion/styled'

// types
import type { Entity } from 'types/entity'

// styles
import scss from './index.module.scss'

type StyledCardProps = {
  isMine: boolean
}

const StyledCard = styled.div<StyledCardProps>`
  border: 1px solid #e0e0e0;
  border-bottom: 1px solid;
  ${props => (props.isMine ? props.theme.primary : 'none')};
  box-shadow: 0px 1px 3px #00000014;
  transition: box-shadow 0.2s;

  &:hover {
    border: 1px solid ${props => props.theme.primary};
    box-shadow: 0px 0px 0px 2px ${props => props.theme.primary};
  }
`

const CardContainer = ({
  item,
  isMine = false,
  children,
}: PropsWithChildren<{
  item: Entity
  isMine?: boolean
}>): ReactElement => {
  const { id } = item

  return (
    <div key={id} className='galleryCol'>
      <StyledCard
        isMine={isMine}
        className={`card ${scss.card}`}
        data-testid='card-item'
      >
        {children}
      </StyledCard>
    </div>
  )
}

export default CardContainer
