// libraries
import React, { useCallback } from 'react'
import { useUnmount, useToggle } from 'react-use'
import PropTypes from 'prop-types'

// constants
import { BUTTON_SIZES, BUTTON_VARIANTS } from 'components/common/Button'

// components
import { ActionItemDialog, Button } from 'components/common'
import { IconTrash, UndoIcon } from 'svg'
// styles
import scss from './index.module.scss'

const DeleteItemDialog = ({
  label,
  itemId,
  showDeleteItemDialog,
  setShowDeleteItemDialog,
  setShowRegularItem,
  onDeleteItem,
}) => {
  const [isLoading, toggleLoading] = useToggle(false)

  const onConfirmDeleteItemHandler = useCallback(
    async id => {
      toggleLoading(true)
      await onDeleteItem(id)
    },
    [toggleLoading, onDeleteItem]
  )

  useUnmount(() => {
    setShowRegularItem(true)
    setShowDeleteItemDialog(false)
  })

  return (
    <ActionItemDialog
      showActionItemDialog={showDeleteItemDialog}
      setShowRegularItem={setShowRegularItem}
    >
      <div className='d-flex w-100 justify-content-between align-items-center p-1'>
        <div>
          <span>
            <IconTrash width={14} height={14} />
            <span className={scss.label}>Delete {label}</span>
          </span>
        </div>
        <div>
          <span>
            <button
              className={`btn ${scss.btnLink}`}
              onClick={() => setShowDeleteItemDialog(false)}
              type='button'
            >
              <UndoIcon width={14} height={14} /> <span>Undo</span>
            </button>
            <Button
              className='ms-2'
              variant={BUTTON_VARIANTS.secondary}
              size={BUTTON_SIZES.small}
              onClick={() => onConfirmDeleteItemHandler(itemId)}
              disabled={isLoading}
              isLoading={isLoading}
            >
              Confirm
            </Button>
          </span>
        </div>
      </div>
    </ActionItemDialog>
  )
}

DeleteItemDialog.propTypes = {
  label: PropTypes.string,
  itemId: PropTypes.string.isRequired,
  showDeleteItemDialog: PropTypes.bool.isRequired,
  setShowDeleteItemDialog: PropTypes.func.isRequired,
  setShowRegularItem: PropTypes.func.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
}

DeleteItemDialog.defaultProps = {
  label: '',
}

export default DeleteItemDialog
