import moment from 'moment-timezone'
import _ from 'lodash'

// utils
import { getSnappedDateMoment } from 'helpers/datetime'

import type {
  DetectionData,
  DetectionType,
  EmissionObservationData,
} from 'app/MissionControlMethaneSolution/types/detection'
import type { Timezone, UtcISOString } from 'types/datetime'

// constants
import {
  EMISSION_OBSERVATION_ATTRIBUTION_TYPES,
  EMISSION_OBSERVATION_TYPE_ICONS_MAPPING,
} from 'app/MissionControlMethaneSolution/constants/emissionObservation'
import { DATE_UNIT_TYPES } from 'constants/datetime'

import type { SerfReportingSettings } from 'types/common'
import type { EmissionObservation, IssueReference } from '../types/graphql'

export const getAttributionName = ({
  sourceAttribution,
  site,
  equipmentDescription,
}: Pick<DetectionData, 'sourceAttribution' | 'site'> & {
  equipmentDescription?: string | null
}): string | null | undefined => {
  if (!sourceAttribution) return ''

  const isAttributedToEquipment =
    sourceAttribution.attributionType ===
    EMISSION_OBSERVATION_ATTRIBUTION_TYPES.EQUIPMENT

  return isAttributedToEquipment ? equipmentDescription : site.name
}

export const isVFBEvent = (
  emissionObservation: EmissionObservation
): boolean => {
  if (!emissionObservation) return false

  return (
    !!emissionObservation.ventingEventType ||
    _.startsWith(emissionObservation.shortId, 'VFB')
  )
}

const isMatchingDetectionSource = (
  emissionObservation: EmissionObservationData,
  source: string
): boolean => {
  return !!emissionObservation && emissionObservation.detectionSource === source
}

export const isQubeData = (
  emissionObservation: EmissionObservationData
): boolean => {
  return isMatchingDetectionSource(emissionObservation, 'Qube')
}

export const isKuvaData = (
  emissionObservation: EmissionObservationData
): boolean => {
  return isMatchingDetectionSource(emissionObservation, 'Kuva Systems')
}

export const getOldestEmissionObservationDate = (
  timezone: Timezone
): UtcISOString =>
  getSnappedDateMoment({
    baseDatetime: moment.utc().subtract(2, DATE_UNIT_TYPES.years),
    timezone,
    unit: DATE_UNIT_TYPES.years,
  }).toISOString()

export const findExistingIssueByFormId = ({
  issues,
  formId,
}: {
  issues?: IssueReference[] | null
  formId?: string
}) =>
  (issues || []).find(issueReference => {
    const dataCollectionFormReference = _.get(
      issueReference,
      'issue.statesParameter[0].dataCollectionFormReference'
    )
    return dataCollectionFormReference === formId
  })

export const checkCanSubmitSerpReport = ({
  serfReportingSettings,
  issues,
}: {
  serfReportingSettings?: SerfReportingSettings
  issues?: IssueReference[] | null
}) => {
  const { enabled, formId } = serfReportingSettings || {}

  if (enabled && (issues?.length || 0) > 0) {
    // If there is an existing report, don't allow to create a new one
    const existingReport = findExistingIssueByFormId({ issues, formId })
    return !existingReport
  }

  return enabled
}

export const getEmissionObservationIconByType = (
  emissionObservationType: DetectionType
) =>
  _.get(
    EMISSION_OBSERVATION_TYPE_ICONS_MAPPING,
    emissionObservationType,
    'MethaneUnknownIcon'
  )
