import keymirror from 'keymirror'

export const SIZES = keymirror({
  MEDIUM: null,
  LARGE: null,
})

export const IMAGE_SIZES = {
  LARGE: 250,
  MEDIUM: 115,
}
