import { ReactElement, useCallback, useState, forwardRef } from 'react'

import { InputTextAddon, SwitchUnits } from 'components/common'

import type { Option } from 'types/common'
import type { SwitchUnitsToggleProps } from 'components/common/SwitchUnits/components/SwitchUnitsDefaultToggle'
import { useStaticResourceStateValue } from 'contexts'

import Input, { InputProps } from '../Input'
import scss from './index.module.scss'

export type OnUnitChangeArgs = {
  nextUnit: string
  prevUnit: string
  currentValue: string | number
  onChange: InputProps['input']['onChange']
}

const InputWithUnitDropdown = forwardRef(
  (
    {
      unitOptions,
      defaultUnit,
      onUnitChange,
      ...props
    }: InputProps & {
      unitOptions: Option[]
      defaultUnit: string
      onUnitChange?: (args: OnUnitChangeArgs) => void
    },
    ref
  ): ReactElement => {
    const { input } = props
    const [selectedUnit, setSelectedUnit] = useState(defaultUnit)
    const { Icons } = useStaticResourceStateValue()

    const UnitSwitchToggle = useCallback(
      ({ selectedValue, options }: SwitchUnitsToggleProps) => {
        const selectedValueLabel = options.find(
          option => option.value === selectedValue
        )?.label

        return (
          <>
            <div className={scss.labelContainer}>
              <InputTextAddon
                fontSize='12px'
                label={selectedValueLabel as string}
              />
            </div>
            <Icons.IoChevronDown size={10} />
          </>
        )
      },
      [Icons]
    )

    return (
      <Input
        {...props}
        ref={ref}
        addonNode={
          <SwitchUnits
            containerClassName={scss.addonContainer}
            dropdownClassName={scss.dropdown}
            selectedValue={selectedUnit}
            options={unitOptions}
            onChange={nextUnit => {
              const { onChange, value } = input
              onUnitChange?.({
                nextUnit,
                prevUnit: selectedUnit,
                currentValue: value,
                onChange,
              })
              setSelectedUnit(nextUnit)
            }}
            toggleRenderer={UnitSwitchToggle}
          />
        }
      />
    )
  }
)

export default InputWithUnitDropdown
