import { ReactElement } from 'react'

import type { Asset, AssetRelationshipOne } from 'types/asset'

// components
import DisplayImagesGallery, {
  DisplayImagesGalleryProp,
} from './DisplayImagesGallery'

type DisplayImagesFromRelatedAssetProp = Omit<
  DisplayImagesGalleryProp,
  'properties' | 'propertiesMetadata'
> & {
  value?: Asset & { related: AssetRelationshipOne }
}

const DisplayImagesFromRelatedAsset = ({
  value,
  ...rest
}: DisplayImagesFromRelatedAssetProp): ReactElement => {
  const { properties } = value?.related?.asset || {}
  const { properties: propertiesMetadata = [] } = value?.assetProfile || {}
  return (
    <DisplayImagesGallery
      {...rest}
      properties={properties}
      propertiesMetadata={propertiesMetadata}
    />
  )
}

export default DisplayImagesFromRelatedAsset
