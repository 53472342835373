import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import scss from './index.module.scss'

const EditableInput = ({
  isEditable,
  onTextEdit,
  onClick,
  text,
  className,
  style,
  type: TypeComponent,
  testId,
}) => {
  const inputTextRef = useRef(null)

  const editable = isEditable ? scss.editable : ''
  return onTextEdit && isEditable ? (
    <TypeComponent
      ref={inputTextRef}
      className={`${editable} ${className} ${scss.input}`}
      onClick={onClick}
      defaultValue={text}
      style={style}
      onChange={_.debounce(() => onTextEdit(inputTextRef.current.value), 400)}
      data-testid={testId}
    />
  ) : (
    <TypeComponent
      value={text}
      readOnly
      style={style}
      data-testid={testId}
      className={`${className} ${scss.input}`}
    />
  )
}

EditableInput.propTypes = {
  isEditable: PropTypes.bool,
  onTextEdit: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.shape({}),
  testId: PropTypes.string,
}

EditableInput.defaultProps = {
  isEditable: true,
  text: '',
  className: undefined,
  type: 'textarea',
  style: {},
  testId: undefined,
}

export default EditableInput
